import { Empty } from 'antd'
import React from 'react'

function NoData() {
  return (
    <div className='d-flex w-100 h-100 justify-content-center align-items-center'>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
    </div>
  )
}

export default NoData
