import React, { useState, useEffect } from "react";
import { t } from "i18next";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { Helmet } from "react-helmet";
import "./CampaignDetail.css";
import CampaignDevice from "components/CampaignDevice/CampaignDevice";
import { PlusOutlined } from "@ant-design/icons";
import PrimaryButton from "../../../components/Buttons/PrimaryButton/PrimaryButton";
import CustomBreadCrumb from "../../../components/CustomBreadCrumb/CustomBreadCrumb";
import { Link, useParams } from "react-router-dom";
import { Card } from "antd";
import CampaignDetailChart from "./CampaignDetailChart";
import { Descriptions } from "antd";
import downloadIcon from "../../../images/downloadNew.svg";
import useMessage from "../../../hooks/useMessage";
import centralApi from "services/centralApi";
import {
  capitalizeFirstLetter,
  downloadCsv,
  formatISODateString,
  getInAppBazelInsetValues,
  timestampToFormattedDate,
} from "../../../helperFunctions/common";
import { useDispatch, useSelector } from "react-redux";
import InAppContainer from "../CampaignCreate/components/NotificationContainer/InAppContainer/InAppContainer";
import PushContainer from "../CampaignCreate/components/NotificationContainer/PushContainer/PushContainer";
import WhatsAppContainer from "../CampaignCreate/components/NotificationContainer/WhatsAppContainer/WhatsAppContainer";
import WebPushContainer from "../CampaignCreate/components/NotificationContainer/WebPushContainer/WebPushContainer";
import VariantList from "../CampaignCreate/components/VariantList/VariantList";
import WebPopUpContainer from "../CampaignCreate/components/NotificationContainer/WebPopUpContainer/WebPopUpContainer";
import SMSContainer from "../CampaignCreate/components/NotificationContainer/SMSContainer/SMSContainer";
import { getFeaturesSettings } from "redux/features/app/featuresSettingSlice";
import CustomToolTip from "components/CustomToolTip/CustomToolTip";
import { formatDate } from "helperFunctions/date";
import { API_ENDPOINTS } from "utils/constants";
import { CampaignChannelTypeEnum } from "utils/enums";
import NativeDisplayContainer from "../CampaignCreate/components/NotificationContainer/NativeDisplayContainer/NativeDisplayContainer";
interface CampDevicesDataTypes {
  backgroundColor?: string;
  subjectData?: string;
  bodyHtmlData?: any;
}

type propTypes = {
  pageSize?: 10;
  tableSize?: 5;
};
const CampaignDetail: React.FC<propTypes> = () => {
  interface RootState {
    loginUser: {
      data: {
        api_key: string;
      };
    };
  }
  interface ActiveAppState {
    activeApp: {
      appName: string;
      appId: string;
    };
  }
  interface CampData {
    t?: string;
    nm?: string;
    segmentname?: string;
    c_checker?: string;
    c_maker?: string;
    sd?: string;
    ed?: string;
    conversion?: string;
    cname?: string;
    type?: string;
    ctr?: string;
    receivedTo?: string;
    clickedTo?: string;
    reach?: string;
    Inbox_clicked?: string;
    inbox_read?: string;
    appinbox_et?: string;
    impressionRate?: string;
    variance?: any;
    appInboxreports?: boolean;
    columnStatus?:any;
    viewedTo?:string;
    deliveryRate?:number;
    readRate?:number;
    pushedTo?:number;
    openRate?:string;
    clickRate?:string;
    bounceRate?:string;
    bounced?:number;
    unsubscribeRate?:string;
    unsubscribed?:number;
    opened?:number;
    smsClickRate?:string;
    // Add more properties as needed
  }
  const { id } = useParams();
  const [deviceType, setDeviceType] =
    useState<"Android" | "IOS" | "Mac" | "windows" | any>("Android");
  const [imageLoading, setImageLoading] = useState({
    webPushExpanded: false,
    webPushHeader: false,
  });
  const dispatch: any = useDispatch();

  const loginUser = useSelector((state: RootState) => state.loginUser);
  const { appId } = useSelector((state: ActiveAppState) => state.activeApp);
  const { endDate_C, startDate_C } = useSelector(
    (state: any) => state?.dateRange
  );
  const api_key = loginUser?.data.api_key;
  const [expandedRowKeys, setExpandedRowKeys] = useState(false);
  const [campDataById, setCampDataById] = useState<any>(null); // Change the type to CampData | null
  const [campDataStatusById, setCampDataStatusById] =
    useState<CampData | null>(null); // Change the type to CampData | null
  const { showSuccess,showError } = useMessage();
  const [isPreview, setIsPreview] = useState<boolean>(true);
  const features = useSelector((state: any) => state.featuresSetting.data);
  const featuresSetting = features[0]?.features;
  const [variants, setVariants] = useState<any>([]);
  const [selectedVariant, setSelectedVariant] = useState<any>(null);
  const emailMetricsData = {
    emailMetrics: [
      {
        title: t("sentCapitalLabel"),
        percentage: campDataStatusById?.pushedTo,

      },
        {
          title: t("deliveryRateCapitalLabel"),
          percentage: campDataStatusById?.deliveryRate,
          total: campDataStatusById?.receivedTo,
          status: "Delivered",
        },
      {
        title: t("readCapitalLabel"),
        percentage: campDataStatusById?.viewedTo,

      },
      {
        title: t("openRateCapitalLabel"),
        percentage: campDataStatusById?.openRate,
        total: campDataStatusById?.opened,
        status: "Opened",
      },
      {
        title: t("clickRateCapitalLabel"),
        percentage: campDataStatusById?.clickRate,
        total: campDataStatusById?.clickedTo,
        status: "Clicked",
      },
      {
        title: t("bounceRateCapitalLabel"),
        percentage: campDataStatusById?.bounceRate,
        total: campDataStatusById?.bounced,
        status: "Bounced",
      },
      {
        title: t("unsubscribeRateCapitalLabel"),
        percentage: campDataStatusById?.unsubscribeRate,
        total: campDataStatusById?.unsubscribed,
        status: "Unsubscribed",
      },
    ],
  };
  const smsMetricsData = {
    smsMetrics: [
      {
        title: t("sentCapitalLabel"),
        percentage: campDataStatusById?.pushedTo,

      },
      {
        title: t("deliveredCapitalLabel"),

        percentage: campDataStatusById?.receivedTo,
      },
      
      {
        title: t("clicksCapitalLabel"),
        percentage: campDataStatusById?.clickedTo,
      
      },
      {
        title: t("clickRateCapitalLabel"),
        percentage: campDataStatusById?.smsClickRate,
  
      },
      {
        title: t("conversionCapitalLabel"),
        percentage: campDataStatusById?.conversion,
      
      },
     
    ],
  };
  const whatsAppMetricsData = {
    whatsAppMetrics: [
      
      {
        title: t("sentLabel"),
        percentage: campDataStatusById?.pushedTo,
        
      },
      {
        title: t("deliveryRateCapitalLabel"),
        percentage: campDataStatusById?.deliveryRate,
        total: campDataStatusById?.receivedTo,
        status: "Delivered",
      },
      {
        title: t("readRateCapitalLabel"),
        percentage: campDataStatusById?.readRate,
        total: campDataStatusById?.viewedTo,
        status: "Read",
      },
      {
        title: t("clickThroughRateCapitalLabel"),
        percentage: campDataStatusById?.ctr ,
        total: campDataStatusById?.clickedTo,
        status: "Total clicks",
      },
      
    ],
  };
  async function getCampaignsStatusById() {
    const payload = {
      args: JSON.stringify({
        d: "true",
        ud: endDate_C,
      }),
      app_id: appId,

      api_key: api_key,

      cid: id,
    };

    try {
      const data = await centralApi("GET", API_ENDPOINTS.GET_CAMPAIGN_STATUS_BY_ID, null, payload);
      setCampDataStatusById(data);

    } catch (error) {
      showError(t("somethingWrongLabel"));
    }
  }
  async function getCampaignById() {
    const payload = {
      api_key: api_key,
      app_id: appId,
      args: JSON.stringify({
        d: "true",
        ud: endDate_C,
      }),
      cid: id,
    };

    try {
      const data = await centralApi("GET", API_ENDPOINTS.GET_CAMPAIGN_BY_ID, null, payload);
      setCampDataById(data);
    } catch (error) {
      showError(t("somethingWrongLabel"));
    }
  }
  useEffect(() => {
    getCampaignById();
    getCampaignsStatusById();
    dispatch(
      getFeaturesSettings({
        api_key: api_key,
        app_id: appId,
      })
    );
  }, []);
  const formatName = (type:any) => {
    if (type === CampaignChannelTypeEnum.WHATSAPP) {
      return "WhatsApp";
    }
    else if (type === CampaignChannelTypeEnum.IN_APP) {
      return "In-App";
    }
    return capitalizeFirstLetter(type);
  };
  
  const cardDataHeading = [
    {
      heading: t("typeLabel"),
      description: formatName(campDataStatusById?.type),

      
    },
    {
      heading: t("audienceSegmentLabel"),
      description: capitalizeFirstLetter(campDataStatusById?.segmentname),
    },
    ...(campDataStatusById?.type === CampaignChannelTypeEnum.EMAIL ||
      campDataStatusById?.type === CampaignChannelTypeEnum.WHATSAPP
      ? [
          {
            heading: t("segmentSizeLabel"),
            description: campDataStatusById?.reach,
          },
        ]
      : []),
  ];
  const descriptionDetails = [
    {
      key: "7",
      label: t("startDateLabel"),
      children: formatDate(campDataStatusById?.sd ,'MMMM-DD-YYYY hh:mm A'),
      span: 1,
    },
    {
      key: "7",
      label: t("endDateLabel"),
      children: formatDate(campDataStatusById?.ed , 'MMMM-DD-YYYY hh:mm A'),
      span: 1,
    },
    {
      key: "7",
      label: t("makerLabel"),
      children: campDataById?.c_maker,
      span: 1,
    },
    {
      key: "7",
      label: t("checkerLabel"),
      children: campDataById?.c_checker,
      span: 2,
    },
  ];
  const descriptionDeliveryData = [
    {
      key: "4",
      label: t("reachableAudienceLabel"),
      children: campDataStatusById?.reach,
    },
    ...(campDataStatusById?.columnStatus?.receivedTo 
      ? [
    {
      key: "5",
      label: t("sentLabel"),
      children: campDataStatusById?.receivedTo,
    }, ]:[]),
    ...(campDataStatusById?.columnStatus?.clickedTo 
      ? [
    {
      key: "6",
      label: t("clickedLabel"),
      children: campDataStatusById?.clickedTo,
    },]:[]),
    ...(campDataStatusById?.columnStatus?.conversion 
      ? [
    {
      key: "7",
      label: t("conversionLabel"),
      children: campDataStatusById?.conversion,
    },]:[]),
    ...(campDataStatusById?.columnStatus?.ctr 
      ? [
    {
      key: "8",
      label: t("ctrLabel"),
      children: campDataStatusById?.ctr,
    },]:[]),
  ];
  const blobContainerCDN = campDataById?.data?.blobContainerCDN;
  const template = campDataById?.data?.template;
  const isArray = Array.isArray(template);
  const campDetailsData = isArray ? selectedVariant : template;

  useEffect(() => {
    if (isArray) {
      setVariants(template);
      setSelectedVariant(template[0]);
    }
  }, [template]);
  const handleExpand = () => {
    setExpandedRowKeys((prevExpanded) => !prevExpanded);
  };

  const columnsVariant = [
    {
      title: t("nameLabel"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("reachableAudienceLabel"),
      dataIndex: "reach",
      key: "reach",
    },
    {
      title: t("pushedtoLabel"),
      key: "pushed",
      dataIndex: "pushed",
    },
    {
      title: t("clickedLabel"),
      dataIndex: "clicked",
      key: "clicked",
    },
    {
      title: t("ctrLabel"),
      dataIndex: "ctr",
      key: "ctr",
    },
    {
      title: t("conversionLabel"),
      dataIndex: "conversion",
      key: "conversion",
    },
  ];
  const dataVariant = (campDataStatusById?.variance || []).map(
    (variant: any) => ({
      name: variant?.name || "",
      reach: variant?.p + "%" || "0%",
      clicked: variant?.clickedTo,
      pushed: variant?.pushedTo,
      ctr: variant?.ctr,
      conversion: variant?.conversion,
    })
  );
  const appInboxColumns: any = [
    {
      title: t("reachableAudienceLabel"),
      dataIndex: "reachable",
      key: "reachable",
    },
    {
      title: t("sentLabel"),
      dataIndex: "sent",
      key: "sent",
    },

    {
      title: t("inboxReadLabel"),
      dataIndex: "inbox",
      key: "inbox",
    },

    {
      title: t("appInboxExpiryLabel"),
      dataIndex: "expiry",
      key: "expiry",
    },
  ];
  
  const appInboxData = [
    {
      reachable: campDataStatusById?.reach,
      sent: campDataStatusById?.Inbox_clicked,
      inbox: campDataStatusById?.inbox_read,
      expiry: formatISODateString(campDataStatusById?.appinbox_et),
    },
  ];
  function EmailCard({ title, percentage, total, status }: any) {
    return (
      <div className={`campaign-detail-email-card-container   `}>
        <p className="campaign-detail-email-card-container-title ">{title}</p>
        <div className=" d-flex align-items-baseline gap-2">
          <h5>{percentage} </h5>
          <p className="campaign-detail-email-card-container-sub-title">
            {total}
          </p>
          <p className="campaign-detail-email-card-container-sub-title">
            {status}
          </p>
        </div>
      </div>
    );
  }
  const handleVariantClick = (id: string) => {
    variants.forEach((variant: any) => {
      if (variant.variance_id == id) {
        setSelectedVariant(variant);
      }
    });
  };
  const downloadCsvFile = async() =>{
    const payload = {
      api_key: api_key,
      app_id: appId,
     
      cid:id,
      filename: "campaign",
    };


    try {
      const response = await centralApi("GET", API_ENDPOINTS.DOWNLOAD_CAMPAIGN_BY_ID, null, payload, true);
      downloadCsv(response, "campaign");
      showSuccess(t("downloadedSuccessfullyLabel"));
    } catch (error: any) {
      showError(error.message);
    }
  };
  return (
    <div className="container-fluid p-sm-2 p-2 ">
      <Helmet>
        <title>Appice | Campaign detail</title>
      </Helmet>
      <div className="d-flex justify-content-between align-items-center py-3 flex-wrap">
        <div>
          <CustomBreadCrumb
            items={[
              {
                title: (
                  <Link to="/dashboard/campaign">
                    {t("campaignBreadDetailName")}
                  </Link>
                ),
              },
              {
                title: (
                  <Link to="/dashboard/campaign/1">
                    {t("detailLabel")}
                  </Link>
                ),
              },
            ]}
          />
        </div>
      </div>

      <div className="px-sm-3 px-1 campaign-detail-table-container">
        <div className="mb-3 mt-3 d-flex justify-content-between">
          <h6>
            {t("campaignBreadDetailName")}:{" "}
            {capitalizeFirstLetter(campDataStatusById?.cname)}
          </h6>
          {!featuresSetting?.campaignDetailsCsv && (
            <CustomToolTip title={t("downloadCsvLabel")}>

          <PrimaryButton onClick={downloadCsvFile}>
            <img src={downloadIcon}  alt="download icon"></img>
          </PrimaryButton>
          </CustomToolTip>)}
        </div>

        <div className="d-flex gap-2 mt-3">
          <div
            className={
              campDataStatusById?.type === "WEB_PUSH" ||
              campDataStatusById?.type === "WEB_POPUP"
                ? "col-sm-7"
                : "col-sm-8"
            }
          >
            <div className="d-flex gap-3 mb-3 ">
              {cardDataHeading.map((item, index) => (
                <Card
                  key={index}
                  hoverable
                  className="campaign-detail-table-container-card"
                  style={index === 1 ? { width: "50%" } : {}}
                >
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="campaign-detail-table-container-card-heading">
                        {item?.heading}
                      </p>
                      <p className="campaign-detail-table-container-card-description">
                        {item?.description}
                      </p>
                    </div>
                    <div>
                      {index === 0 && campDataById?.abTypeRange?.length > 0 && (
                        <PrimaryButton
                          onClick={() => {
                            handleExpand();
                          }}
                          style={{
                            paddingLeft: "7px",
                            paddingRight: "7px",
                            fontSize: "10px",
                            marginRight: "10px",
                          }}
                        >
                          <PlusOutlined />
                        </PrimaryButton>
                      )}
                    </div>
                  </div>
                </Card>
              ))}
            </div>

            <Descriptions
              bordered
              layout="vertical"
              size="small"
              column={{ xxl: 5, xl: 5, lg: 5, md: 4, sm: 3, xs: 3 }}
            >
              {descriptionDetails.map((item) => (
                <Descriptions.Item
                  key={item.key}
                  label={item.label}
                  span={item.span ? item.span : 1}
                >
                  {item.children}
                </Descriptions.Item>
              ))}
            </Descriptions>
            {!(
              campDataStatusById?.type === CampaignChannelTypeEnum.EMAIL ||
              campDataStatusById?.type === CampaignChannelTypeEnum.WHATSAPP ||
              campDataStatusById?.type === CampaignChannelTypeEnum.SMS 
            ) && (
              <>
                <div className="mt-3 mb-3">
                  <h6>Delivery</h6>
                </div>
                <Descriptions
                  bordered
                  layout="vertical"
                  size="small"
                  column={{ xxl: 5, xl: 5, lg: 5, md: 4, sm: 3, xs: 3 }}
                >
                  {descriptionDeliveryData.map((item) => (
                    <Descriptions.Item key={item?.key} label={item?.label}>
                      {item.children}
                    </Descriptions.Item>
                  ))}
                </Descriptions>
              </>
            )}

            {expandedRowKeys && (
              <div className="mt-3">
                <h6>{t("variantsLabel")}</h6>

                <CustomTable
                  columns={columnsVariant}
                  dataSource={dataVariant}
                  pagination={false}
                  style={{ overflowX: "hidden" }}
                  scroll={{ x: false }}
                />
              </div>
            )}
            {campDataStatusById?.type === CampaignChannelTypeEnum.EMAIL ? (
              <div className="d-flex flex-wrap gap-3 mb-3 mt-3">
                {emailMetricsData.emailMetrics.map((metric, index) => (
                  <EmailCard
                    key={index}
                    title={metric.title}
                    percentage={metric.percentage}
                    total={metric.total}
                    status={metric.status}
                  />
                ))}
              </div>
            ) : campDataStatusById?.type === CampaignChannelTypeEnum.WHATSAPP ? (
              <div className="d-flex flex-wrap gap-3 mb-3 mt-3">
                {whatsAppMetricsData.whatsAppMetrics.map((metric, index) => (
                  <EmailCard
                    key={index}
                    title={metric.title}
                    percentage={metric.percentage}
                    total={metric.total}
                    status={metric.status}
                  
                  />
                ))}
                
              </div>
            ) :campDataStatusById?.type === CampaignChannelTypeEnum.SMS ? (
              <div className="d-flex flex-wrap gap-3 mb-3 mt-3">
                {smsMetricsData.smsMetrics.map((metric, index) => (
                  <EmailCard
                    key={index}
                    title={metric.title}
                    percentage={metric.percentage}
                 
                  />
                ))}
              </div>): null}
            {campDataStatusById?.appInboxreports &&
              campDataStatusById?.type !== CampaignChannelTypeEnum.EMAIL &&
              campDataStatusById?.type !== CampaignChannelTypeEnum.WHATSAPP &&
              campDataStatusById?.type !== CampaignChannelTypeEnum.SMS && (
                <>
                  <div className="mt-4 mb-3">
                    <h6>{t("appInboxDetailsLabel")}</h6>
                  </div>
                  <CustomTable
                    columns={appInboxColumns}
                    dataSource={appInboxData}
                    pagination={false}
                  />
                </>
              )}
          </div>
          <div
            className={
              campDataStatusById?.type === CampaignChannelTypeEnum.WEB_PUSH ||
              campDataStatusById?.type === CampaignChannelTypeEnum.WEB_POPUP
                ? "col-sm-5"
                : "col-sm-4"
            }
          >
            {Array.isArray(template) && (
              <VariantList
                variants={variants}
                selectedVariant={selectedVariant}
                handleVariantClick={handleVariantClick}
              />
            )}

            {campDataStatusById?.type === "IN-APP" ? (
              <CampaignDevice
                height={550}
                bazel={getInAppBazelInsetValues(campDetailsData?.view_mode)}
              >
                <InAppContainer
                  descriptionText={campDetailsData?.notificationDescription}
                  headerImage={`${blobContainerCDN}${campDetailsData?.iconImage}`}
                  miniScreenImage={`${blobContainerCDN}${campDetailsData?.iconImage}`}
                  fullScreenImage={`${blobContainerCDN}${campDetailsData?.notificationImage}`}
                  textColor={campDetailsData?.descriptionTextClr}
                  background={campDetailsData?.backgroundColor}
                  selectedImage={campDetailsData?.view_mode}
                  actionTitle={campDetailsData?.actionTitle}
                  actionTitleBackground={campDetailsData?.backgroundColor}
                  actionTitleColor={campDetailsData?.submitBtnTextClr}
                  callToAction={campDetailsData?.showAction}
                />
              </CampaignDevice>
            ) : campDataStatusById?.type == "PUSH" ? (
              <CampaignDevice
                setDevice={(value: "IOS" | "Android") => {
                  setDeviceType(value);
                }}
              >
                <PushContainer
                  type={deviceType}
                  headerText={campDetailsData?.notificationHeader}
                  descriptionText={campDetailsData?.notificationDescription}
                  headerImage={`${blobContainerCDN}${campDetailsData?.notificationImage}`}
                  isExpandedImage={campDetailsData?.pushType == "expanded"}
                  expandedImage={`${blobContainerCDN}${campDetailsData?.expandedNotificationImage}`}
                  expandedDescription={
                    campDetailsData?.expandedNotificationDescription
                  }
                  variableInfo={campDetailsData?.variableInfo}
                />
              </CampaignDevice>
            ) : campDataStatusById?.type == CampaignChannelTypeEnum.WEB_PUSH ? (
              <CampaignDevice
                intialDeviceType={deviceType}
                campType="webpush"
                setDevice={(value: typeof deviceType) => setDeviceType(value)}
                isPreview={false}
              >
                <div
                  className={`my-2 h-100 w-100  d-flex  ${
                    isPreview
                      ? deviceType == "Android"
                        ? "justify-content-center"
                        : deviceType == "Mac"
                        ? "justify-content-end align-items-start "
                        : "justify-content-end align-items-end "
                      : "justify-content-center align-items-center"
                  }`}
                >
                  <WebPushContainer
                    isPreview={false}
                    deviceType={deviceType}
                    title={campDetailsData?.title}
                    message={campDetailsData?.message}
                    headerImg={`${blobContainerCDN}${campDetailsData?.icon}`}
                    showExpanded={campDetailsData?.extendedImage}
                    expandedImage={`${blobContainerCDN}${campDetailsData?.extendedImage}`}
                    url={campDetailsData?.url}
                    isLoading={imageLoading}
                  />
                </div>
              </CampaignDevice>
            ) : campDataStatusById?.type == CampaignChannelTypeEnum.WEB_POPUP ? (
              <CampaignDevice campType= {CampaignChannelTypeEnum.WEB_POPUP} >
                <WebPopUpContainer
                  title={campDetailsData?.title}
                  message={campDetailsData?.message}
                  headerImg={campDetailsData?.icon}
                  borderRadius={campDetailsData?.br}
                  messageTextColor={campDetailsData?.dc}
                  headerTextColor={campDetailsData?.hc}
                  backgroundColor={campDetailsData?.bg}
                />
              </CampaignDevice>
            ) : campDataStatusById?.type == CampaignChannelTypeEnum.EMAIL ? (
              <EmailDeviceData
                campDetailsData={campDetailsData}
                campDataById={campDataById}
              />
            ) : campDataStatusById?.type == CampaignChannelTypeEnum.SMS ? (
              <CampaignDevice
                campType="sms"
                bazel={{ top: 109, left: 28, bottom: 244, right: 58 }}
              >
                <SMSContainer message={campDetailsData?.message} />
              </CampaignDevice>
            ) : campDataStatusById?.type == CampaignChannelTypeEnum.WHATSAPP ? (
              <CampaignDevice
                campType={CampaignChannelTypeEnum.WHATSAPP}
                bazel={{ top: 88, left: 30, bottom: 100, right: 32 }}
              >
<WhatsAppContainer
              headerTitle={campDetailsData?.headerTitle}
              description={campDetailsData?.description}
              imageUrl={campDetailsData?.imageUrl}
              videoUrl={campDetailsData?.videoUrl}
              fileUrl={campDetailsData?.fileUrl}
              headerType={campDetailsData?.headerType}
              actions={campDetailsData?.actions}
              variableInfo={campDetailsData?.variableInfo}
              hideUpload={true}
            />              </CampaignDevice>
            ) : campDataStatusById?.type == CampaignChannelTypeEnum.NATIVE ? (
              <CampaignDevice
              setDevice={(value: "IOS" | "Android") => {
                setDeviceType(value);
              }}
            >
              <div className="h-100">
                <NativeDisplayContainer
                  type={deviceType}
                  message={t("nativePhoneLabel")}
                />
              </div>
            </CampaignDevice>
         ):null}
          </div>
        </div>

        <div className="mt-3 mb-3 campaign-detail-chart-container">
          {campDataStatusById?.sd && campDataStatusById?.ed && (
            <CampaignDetailChart
              api_key={api_key}
              app_id={appId}
              cid={id}
              sd={startDate_C}
              ed={endDate_C}
              type={campDataStatusById?.type}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default CampaignDetail;

const EmailDeviceData = ({
  campDetailsData,
  campDataById,
}: {
  campDetailsData: CampDevicesDataTypes;
  campDataById: any;
}) => {
  return (
    <div className=" ms-3 campaign-detail-template">
      {campDetailsData && (
        <div>
          <h5>{campDataById?.data?.template_name}</h5>
          <span>{campDetailsData?.subjectData}</span>
          <div
            dangerouslySetInnerHTML={{
              __html: campDetailsData?.bodyHtmlData,
            }}
          />
        </div>
      )}
    </div>
  );
};
