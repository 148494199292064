export const PAGE_OPTIONS = {
  DATA:[

            {
              value: 10,
              label: 10,
            },
            {
              value: 20,
              label: 20,
            },
            {
              value: 50,
              label: 50,
            },
 ],

 DEFAULT_VALUE : 10 ,
 DEFAULT_SIZE : "middle",

 PAGINATION : {
  current: 1,
  pageSize: 10,
  simple: false,
},

}

export const TABLE_OPTIONS ={
SIZE : "small",
}

export const STRING_BOOLEAN_OPTIONS ={
  TRUE : "true",
  FALSE : "false"
}

export const BOOLEAN_OPTIONS = {
  TRUE : true,
  FALSE : false
}


export const APNS_FILE_TYPE_OPTIONS ={
  P8 : "p8",
  P12 : "p12"
}

export const MODE_OPTIONS ={
  DEV : "dev",
  PROD : "prod"
}


