import closeIcon from "../../../images/_close.svg";
// import documentIcon from "../../../images/document.png";
// import editIcon from "../../../images/_edit.svg";
import approvedIcon from "../../../images/Approved.svg";
import declinedIcon from "../../../images/Declined.svg";
import reviewIcon from "../../../images/Review.svg";
import { useDispatch, useSelector } from "react-redux";
import "./Template.css";
import { t } from "i18next";
import CustomTable from "../../../components/CustomTable/CustomTable";
import PrimaryButton from "../../../components/Buttons/PrimaryButton/PrimaryButton";
import CustomToolTip from "../../../components/CustomToolTip/CustomToolTip";
import Search from "../../../components/Inputs/Search/Search";
import Selector from "../../../components/Selector/Selector";
import React, {  useEffect, useState } from "react";
import SyncModal from "./SyncModal";
import { fetchTemplatesList } from "../../../redux/features/templates_/templatesByTypeSlice";
import useMessage from "../../../hooks/useMessage";
import centralApi from "../../../services/centralApi";
import { getToken } from "../../../services/getToken";
import { STATUS } from "../../../redux/constant";
type SelectedChannel =
  | "SMS"
  | "WHATSAPP"
  | "PUSH"
  | "WEB_PUSH"
  | "IN-APP"
  | "EMAIL"
  | "WEB_POPUP";

const Template: React.FC = () => {
  interface TableItem {
    templateName: string;
  }
  const { showError, showSuccess } = useMessage();
  const [modal, setModal] = useState(false);
  const dispatch: any = useDispatch();
  const { appId } = useSelector((state: any) => state.activeApp);
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const templates = useSelector((state: any) => state.templatesList.data);
  const fetchingStatus = useSelector(
    (state: any) => state.templatesList.status
  );
  const { endDate_C } = useSelector((state: any) => state?.dateRange);
  const [loading, setLoading] = useState(false);
  const [selectedChannel, setSelectedChannel] =
    useState<SelectedChannel>("PUSH");
  const [tableData, setTableData] = useState<TableItem[]>([]);
  
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState<TableItem[]>([]);

  const handleSearch = (value: string) => {
    setSearchText(value);
    const filteredTableData = tableData.filter((item) =>
      item?.templateName.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filteredTableData);
  };
  async function deleteTemplateById(payload: {
    args: string;
    app_id: string;
    api_key: string;
  }) {
    const url: string = `/i/templates/deleteTemplate`;

    try {
      setLoading(true);
      await centralApi("GET", url, null, payload);
      showSuccess(t("templateDeleteMsg"));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError(t("somethingWrongLabel"));
    }
  }
  const handleDeleteTemplate = (id: string) => {
    const payload = {
      args: JSON.stringify({
        isTemplateDeleted: true,
        ud: endDate_C,
        t_id: id,
      }),
      app_id: appId,
      api_key: loginUser?.api_key,
    };
    deleteTemplateById(payload);
    dispatch(
      fetchTemplatesList({
        app_id: appId,
        api_key: loginUser.api_key,
        template_type: selectedChannel,
      })
    );
    getToken(appId, loginUser.api_key);
  };
  useEffect(() => {
    if (loginUser?.api_key) {
      dispatch(
        fetchTemplatesList({
          app_id: appId,
          api_key: loginUser.api_key,
          template_type: selectedChannel,
        })
      );
    }
  }, [loginUser?.api_key, selectedChannel]);
  useEffect(() => {
    if (fetchingStatus === STATUS.LOADING) {
      setLoading(true);
    } else if (fetchingStatus === STATUS.IDLE) {
      setLoading(false);
    } else if (fetchingStatus === STATUS.ERROR) {
      setLoading(false);
    }
  }, [fetchingStatus]);
  const generateTableData = (
    templates: any,
    selectedChannel: SelectedChannel
  ) => {
    return templates.map((item: any) => {
      let header = "";
      switch (selectedChannel) {
        case "PUSH":
          header = item.template.notificationHeader;
          break;
        case "WEB_PUSH":
          header = item.template.title;
          break;
        case "SMS":
          header = item.template?.message;
          break;
        case "EMAIL":
          header = item.template?.subjectData;
          break;
        case "WHATSAPP":
          header = item?.header;
          break;
        default:
          break;
      }
      return {
        templateName: item.template_name,
        header: header,
        lastUpdated: item?.modifiedOn || item.createdOn,
        createdBy: item.createdBy,
        action: generateActionColumn(item._id),
      };
    });
  };
  useEffect(() => {
    if (selectedChannel && templates) {
      const tableData = generateTableData(templates, selectedChannel);
      setTableData(tableData);
    }
  }, [templates, selectedChannel]);
  //generate columns of actions
  const generateActionColumn = (id: string) => (
    <div className="d-flex w-100 justify-content-center py-1">
      
      <CustomToolTip title="Delete">
        <PrimaryButton
          className="action-btn mx-1"
          onClick={() => handleDeleteTemplate(id)}
        >
          <img src={closeIcon} alt={"close_icon"} style={{ width: 16 }} />
        </PrimaryButton>
      </CustomToolTip>
    </div>
  );

  const defaultColumns = [
    {
      title: t("templateNameLabel"),
      dataIndex: "templateName",
      key: "templateName",

      width: 170,
      ellipsis: true,
    },
    {
      title:
        selectedChannel === "SMS"
          ? t("messageLabel") 
          : selectedChannel === "WHATSAPP"
          ? t("previewLabel")
          : t("headerLabel"),
      dataIndex: "header",
      key: "header",
      width: 200,

      ellipsis: true,
    },
    ...(selectedChannel === "WHATSAPP"
      ? [
          {
            title: t("categoryLabel"),
            dataIndex: "category",
            key: "category",
            width: 100,
          },
          {
            title: t("statusLabel") ,
            dataIndex: "status",
            key: "status",
            width: 100,

            render: (preview: string) => {
              return (
                <div className="d-flex ">
                  {preview === "Approved" ? (
                    <img
                      src={approvedIcon}
                      alt={"approved"}
                      className="template-container-table-status-img"
                    />
                  ) : preview == "Declined" ? (
                    <img
                      src={declinedIcon}
                      alt={"declined"}
                      className="template-container-table-status-img"
                    />
                  ) : (
                    <img
                      src={reviewIcon}
                      alt={"review"}
                      className="template-container-table-status-img"
                    />
                  )}
                  <span style={{ marginLeft: "5px" }}>{preview}</span>
                </div>
              );
            },
          },
          {
            title: t("languageLabel"),
            dataIndex: "language",
            key: "language",
            width: 100,
          },
        ]
      : []),

    {
      title: t("lastUpdatedLabel"),
      dataIndex: "lastUpdated",
      key: "lastUpdated",
      width: 90,
    },
    ...(selectedChannel !== "WHATSAPP"
    ? [
        {
          title: t("createdByLabel"),
          dataIndex: "createdBy",
          key: "createdBy",
          width: 120,
          ellipsis: true,
        },
      ]
    : []),
    {
      title: t("actionLabel"),
      dataIndex: "action",
      width: 110,
      className: "text-center",

      render: (action: any) => action,
      key: "action",
    },
  ];
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap my-3">
        <h4 className="fw-semi-bold">{t("messageTemplateLabel")}</h4>
      </div>
      <div className=" template-container">
        <div className="template-body template-container-table p-3 mt-3 ">
          <div className="py-3 m-0 d-flex  justify-content-between w-100">
            <div className="d-flex h-100 gap-2 ">
              <Selector
                placeholder="Select channel"
                onChange={(value) => setSelectedChannel(value)}
                style={{ width: 200 }}
                value={selectedChannel}
                showSearch
                options={[
                  {
                    value: "SMS",
                    label: t("smsLabel") ,
                  },
                  {
                    value: "IN-APP",
                    label: t("inAppLabelSecond"),
                  },
                  {
                    value: "PUSH",
                    label: t("pushNotificationLabel") ,
                  },
                  {
                    value: "WEB_PUSH",
                    label: t("webPushLabel"),
                  },
                  {
                    value: "EMAIL",
                    label: t("emailLabel"),
                  },
                  {
                    value: "WHATSAPP",
                    label: t("whatsappLabel"),
                  },
                ]}
              />
              <Search
                placeholder="Search by template name"
                size="middle"
                className=""
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
            
          </div>

          <CustomTable
            columns={defaultColumns}
            dataSource={searchText ? filteredData : tableData}
            pagination={{ pageSize: 6 }}
            scroll={{ x: selectedChannel == "WHATSAPP" ? 1150 : 800 }}
            loading={loading}
            size="small"
          />
        </div>
        <SyncModal openModal={modal} setModalOpen={setModal} />
      </div>
    </div>
  );
};
export default Template;
