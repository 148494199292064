import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { STATUS } from "../../constant"
import centralApi from "../../../services/centralApi"

export const getCurrentAppKeys = createAsyncThunk(
  "app/currentAppKeys",
  async ({api_key, app_id}:any) => {
    const params = {
      args:JSON.stringify({
        app_id
      }),
      api_key,
      app_id,
    };
    return centralApi(
      "GET",
      "/i/apps/getCurrentAppKeys",
      null,
      params
    ).then(({ result }) => {
      return result
    })
  }
)
const initialState: any = {
  data: [],
  status: STATUS.IDLE,
}
const currentAppKeysSlice = createSlice({
  name: "currentAppKeys",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCurrentAppKeys.pending, (state) => {
      state.status = STATUS.LOADING
    })
    builder.addCase(getCurrentAppKeys.fulfilled, (state, action) => {
      state.status = STATUS.IDLE
      state.data = action.payload
    })
    builder.addCase(getCurrentAppKeys.rejected, (state) => {
      state.status = STATUS.ERROR
    })
  },
  reducers: {},
})
export default currentAppKeysSlice.reducer
