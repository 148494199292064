import { Popconfirm } from "antd";
import "./ConfirmPopUp.css";

function ConfirmPopUp({ title, children, id, onConfirm,description, icon,...props }: any) {
  return (
    <Popconfirm
      title={""}
      okText="Yes"
      {...props}
      description={
        <div className="popup-custom-container m-0 p-0">
          <span className="px-1 mx-1">
            <img width={32} height={37} src={icon}></img>
          </span>
          <span className="d-flex align-items-center flex-column">
            <strong className="w-100" style={{color:"var(--color-text-black)"}}>{title}</strong>
            <span className="w-100" style={{color:"var(--color-text-disabled-strong)"}}>{description}</span>
          </span>
        </div>
      }
      cancelText="NO"
      icon={""}
      onConfirm={() => onConfirm(id)}
    >
      {children}
    </Popconfirm>
  );
}

export default ConfirmPopUp;
