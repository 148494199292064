import React from "react";
import CustomHighChart from "../../../../components/Charts/CustomHighChart/CustomHighChart";
export const PerformanceChartOne = () => {
  const dataSeries1 = [25, 30, 35, 20, 25];
  const dataSeries2 = [21, 13, 24, 15, 16];
  const options = {
    title: {
      text: "",
    },
    xAxis: {
      categories: ["Apr 2", "Apr 4", "Apr 6", "Apr 8", "Apr 10"],
    },
    yAxis: {
      title: {
        text: "Values",
      },
    },
    series: [
      {
        name: "Sent",
        data: dataSeries1,
        type: "spline",
      },
      {
        name: "Impressions",
        data: dataSeries2,
        type: "spline",
      },
    ],
  };
  return (
    <div>
      {" "}
      <CustomHighChart options={options} />
    </div>
  );
};
