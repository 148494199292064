export const formateIntoKorM=(value:number)=>{
  if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + 'M';
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'K';
    } else {
      return value?(Number.isInteger(value)?value?.toString():value?.toFixed(1).toString()):"NA";
    }
}
export const getPercentage=(previousValue:number,currentValue:number)=>{
let status:0 | 1 | -1=0
const epsilon= 1
const difference=(currentValue?currentValue:epsilon)-(previousValue?previousValue:epsilon);
let percentage=((difference/(previousValue?previousValue:epsilon))*100)

if(percentage<0){
  status=-1
}
else if(percentage>0){
  status=1
}
else{
  status=0
}
if(isNaN(percentage)){
  percentage=0
}
return {percentage:Math.abs(percentage).toFixed(1)+"%",status}

}
export const getRandomNumber=()=>{
return Math.random()*1000
}
export function generateNumbersInRange(lt:number, ul:number) {
const numbers = [];

if (lt <= ul) {
  for (let i = lt; i <= ul; i++) {
    numbers.push(i);
  }
}

return numbers;
}