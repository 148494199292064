import { EditOutlined, MoreOutlined, PlusOutlined } from "@ant-design/icons";
import React from "react";
import PrimaryButton from "../../../components/Buttons/PrimaryButton/PrimaryButton";
import { Checkbox, } from "antd";
import addicon from "../../../images/adwords.png";
import fbicon from "../../../images/facebook.png";
import cloudicon from "../../../images/security.png";
import Selector from "../../../components/Selector/Selector";
import FormItem from "antd/es/form/FormItem";
import { audienceItems } from "../../../helperFunctions/audience";
import { useNavigate } from "react-router-dom";
import TextInput from "../../../components/Inputs/TextInput/TextInput";

export default function DemoSync() {
    const navigate=useNavigate()
  return (
    <div className="p-4 mt-2" style={{ background: "white" }}>
      <div className="d-flex justify-content-between">
        <h5>Select sync templates</h5>
        <div className="d-flex gap-3">
          <PrimaryButton type="primary">
            <PlusOutlined />
            <span className="mx-2">Create new sync</span>
          </PrimaryButton>
        </div>
      </div>
      <div className="my-4">
        <p style={{ color: "gray" }}>
          Select one or more sync templates to sync your audience with the
          destination
        </p>
      </div>
      <div>
        <div>
          <div className="row">
            <div className=" col-6  p-3" style={{ background: "#f4f8ff" }}>
              <strong>AVAILABLE SYNC TEMPLATES</strong>
            </div>
            <div className=" col-6 p-3" style={{ background: "#f4f8ff" }}>
              <strong>DESTINATIONS</strong>
            </div>
          </div>
          <div className="row">
            <div className="col-6 ">
              <div className="my-5">
                <Checkbox>Salesforce Marketing Cloud</Checkbox>
              </div>
              <div className="my-5">
                <Checkbox>Facebook Audiences</Checkbox>
              </div>
              <div className="my-5">
                <Checkbox>Google Ads</Checkbox>
              </div>
            </div>
            <div className="col-6">
              <div className="">
                <div className="my-5 w-100 d-flex justify-content-between">
                  <span>
                    <img
                      style={{ height: 24, width: 24 }}
                      src={cloudicon}
                      alt=""
                    />
                    <span className="mx-2">Salesforce Marketing Cloud</span>
                  </span>
                  <div className="">
                    <MoreOutlined /> <EditOutlined />
                  </div>
                </div>
                <div className="my-5">
                  <span>
                    <img
                      style={{ height: 24, width: 24 }}
                      src={fbicon}
                      alt=""
                    />
                    <span className="mx-2">Facebook Audiences</span>
                  </span>
                </div>
                <div className="my-5">
                  <span>
                    <img
                      style={{ height: 24, width: 24 }}
                      src={addicon}
                      alt=""
                    />
                    <span className="mx-2">Google Ads</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-6">

            </div>
            <div className="d-flex col-6 gap-3">
              <PrimaryButton onClick={()=>navigate(-1)}>Cancel</PrimaryButton>
              <PrimaryButton type="primary">Add Sync</PrimaryButton>
            </div>
          </div> */}
        </div>
      </div>
      <div>
        <h5 className="my-4">Add effects</h5>
        <FormItem label="Effect" colon={false}>
          <Selector
            placeholder="Add units"
            // size="middle"
            style={{ width: "29%", marginLeft: "33px" }}
            options={[
              {
                value: "Add Units",
                label: "Add units",
              },
            ]}
          />
        </FormItem>
        <FormItem label="Wallet type" colon={false}>
          <Selector
            placeholder="Points"
            // size="middle"
            style={{ width: "30%" }}
            options={[
              {
                value: "Reward",
                label: "Reward",
              },
            ]}
          />
        </FormItem>
        <FormItem label="Calculation" colon={false}>
          <Selector
            placeholder="Transaction value"
            // size="middle"
            style={{ width: "20%", marginRight: "10px" }}
            options={[
              {
                value: "Transaction Value",
                label: "Transaction Value",
              },
              {
                value: "Reward Points",
                label: "Reward Points",
              },
            ]}
          />
          <Selector
            placeholder="operator"
            // size="middle"
            style={{ width: "20%", marginRight: "10px" }}
            options={audienceItems["number"]}
          />
          <TextInput style={{ width: "20%", marginRight: "10px" }}/>
        </FormItem>
      </div>
      <div className="row">
            <div className="col-10">

            </div>
            <div className="d-flex col-2 gap-3">
              <PrimaryButton onClick={()=>navigate(-1)}>Cancel</PrimaryButton>
              <PrimaryButton type="primary">Add Sync</PrimaryButton>
            </div>
          </div>
    </div>
  );
}
