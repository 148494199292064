import { Timeline, TimelineProps } from "antd";
import { isString } from "highcharts";
import { FC } from "react";
import "./CustomTimeline.css"
import { COLORS, CONSTANTS } from "utils/constants/constants";
type CustomTimelineProps = TimelineProps & {
  layout?: typeof CONSTANTS.EVENT;
};
const CustomTimeline: FC<CustomTimelineProps> = ({ ...props }) => {
  const eventLayoutData = (data: typeof props.items): typeof props.items => {
    return data?.map((item,index) => {
      const label = isString(item.label)
        ? item.label?.toString()?.split(",")
        : ["", ""];
        const children = isString(item.children) ? item.children?.toString()?.split(",") : [];

      const label1 = label && (label[0] || "");
      const label2 = label && (label[1] || "");
      let customColors
      if(data[index].color){
       customColors = data[index].color
      }
      const colors = COLORS.TIMELINE_DOT_COLORS;
      return {
        color: customColors == undefined ? colors[index%colors.length] : customColors  ,
        label: (
          <div className="d-flex flex-column custom-time-line-event-label gap-1">
            <span>{label1}</span>
            <span>{label2}</span>
          </div>
        ),
        children: (
          <div className="d-flex flex-column custom-time-line-event-children w-100 gap-1">
         
            {children?.map((child, i) => (
            <span key={i} className={i === 0 ? "" : "w-100 custom-time-line-child2"}>{child}</span>
          ))}
          </div>
        ),
      };
    });
  };
  return (
    <div className="custom-time-line-main-container">
      <Timeline
        {...props}
        items={
          props.layout == CONSTANTS.EVENT ? eventLayoutData(props.items) : props.items
        }
      />
    </div>
  );
};
export default CustomTimeline;
