import  { FC } from "react";
import "./MessageCard.css"
import { EditOutlined } from "@ant-design/icons";
import SkeletonLoader from "components/SkeletonLoader/SkeletonLoader";
import { useSelector } from "react-redux";
interface MessageCardProps {

    title:string
    body:string
    loading:boolean
    formData:{title:string,body:string}

}


const MessageCard: FC<MessageCardProps> = ({title,body,loading,formData}) => {
  const { appName } = useSelector((state: any) => state.activeApp);

  return (
    <div className="d-flex gen-ai-message-card-container flex-column">
      {!loading?<>
      <div className="d-flex gap-2 align-items-center ">
        {/* <img src={editPen} alt="edit_pen" /> */}
        <EditOutlined />
        <span className="gen-ai-message-card-app-text">{appName}... 5m</span>
      </div>
      <div className="d-flex p-2 px-4 flex-column">
        <span className="gen-ai-message-card-title2">{title ||   "Get ready to binge."}</span>
        <span className="gen-ai-message-card-body pb-1 mt-2">{body ||  "Discover your next favorite movie with our recommendations"}</span>
      </div>
      <div></div>
      </>:<SkeletonLoader type="default"  size="small"/>}
      
    </div>
  );
};

export default MessageCard;
