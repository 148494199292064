import { Select, SelectProps } from "antd";
import { useMemo, useState } from "react";
import "./Selector.css";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { SelectorModes } from "utils/enums";
import { t } from "i18next";
function Selector({ children, ...props }: SelectProps) {
  const [selectedValues, setSelectedValues] = useState<any[] | undefined>(
    props.mode == SelectorModes.MULTIPLE && props.value
  );
  const filteredOptions=useMemo(()=>{
  // return empty array when options prop is not thr
    if (!props.options) return [];
    // remove duplicates based on value 
  const seenValues = new Set();
  return props.options?.filter(item => {
    //check if duplicate 
    const duplicate = seenValues.has(item.value)
    //add value in  seenValues set
    seenValues.add(item.value);
    return !duplicate;
  });

  },[props.options])
 //handle "select all" and "clear all" click
  const handleAction = (clear=false) => {
    // on clear all click
    if (clear) {
      setSelectedValues(undefined);
      if(props.onChange){
        props.onChange(undefined,[])
      }
      // on select all click
    } else if (filteredOptions) {
      let data=[]
      let options=props?.options
      if(props?.maxCount){
       options=options?.slice(0,props.maxCount)
      }
      data=options?.map((child: any) => child?.value) || []
      setSelectedValues(data);
      if(props.onChange){
        props.onChange(data,[])
      }
    }
  };

  return (
    <span className="custom-ant-selector-parent-container">
      <Select
        {...props}
        options={filteredOptions}
        value={props.mode == SelectorModes.MULTIPLE ? selectedValues : props.value}
        onChange={props.mode == SelectorModes.MULTIPLE?(values:any) => {
            setSelectedValues(values);
            if(props.onChange){
              props.onChange(values,[])
            
        }}:props.onChange} 
        allowClear={false}
        showSearch
        dropdownRender={
          props.mode == SelectorModes.MULTIPLE && filteredOptions?.length
            ? (menu) => (
                <>
                  <PrimaryButton
                    disabled={props?.maxCount?props.maxCount==selectedValues?.length:false}
                    className="px-2 mx-1"
                    type="link"
                    onClick={()=>handleAction(false)}
                  >
                    { t("selectAllLabel")}
                  </PrimaryButton> 
                  <PrimaryButton
                    className="px-2 mx-1"
                    type="link"
                    onClick={()=>handleAction(true)}
                  >
                    { t("clearAllLabel")}
                  </PrimaryButton>
                  {menu}
                </>
              )
            : props.dropdownRender
        }
      >
        {children}
      </Select>
    </span>
  );
}

export default Selector;
Selector.defaultProps = {
  size: "middle",
  width: "auto",
};
