import { SearchOutlined } from "@ant-design/icons";
import { Input, InputProps } from "antd";
import CSS from "csstype";
import searchIcon from "../../../images/search.svg";
type propType = {
  placeholder: string;
  onChange: (e?: any) => void;
  size: "small" | "middle" | "large";
  width: number;
  style?: CSS.Properties;
  className?: string;
  maxLength?: number;
  props?: InputProps;
};
function Search({
  onChange,
  maxLength,
  ...props
}: propType) {
  return (
    <Input
      {...props}
      prefix={<img style={{ height: 16, width: 16 }} src={searchIcon} />}
      onChange={onChange}
      maxLength={maxLength}
    />
  );
}

export default Search;
Search.defaultProps = {
  placeholder: "Search",
  onChange: () => {},
  size: "middle",
  width: 100,
};
