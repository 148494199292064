import { FC, useEffect, useMemo, useState } from "react";
import "./AppUsersDetails.css";
import CustomTimeline from "components/CustomTimeline/CustomTimeline";

import { t } from "i18next";
import { useParams } from "react-router-dom";
import centralApi from "services/centralApi";
import { useSelector } from "react-redux";
import { formatDate } from "helperFunctions/date";
import { Helmet } from "react-helmet";
import { API_ENDPOINTS } from "utils/constants";
const AppUsersDetails: FC = () => {
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const [userDetails, setUserDetails] = useState<any>({ data: {}, eventlist: {} });
  const params = useParams();
  //fetch user details from api
  const fetchAppUserDetails = async () => {
    const paylod = {
      api_key: loginUser.api_key,
      app_id: appId,
      did: params.id,
    };
    const data = await centralApi(
      "GET",
      API_ENDPOINTS.GET_USER_DETAILS_URL,
      null,
      paylod
    );
    setUserDetails(()=>data);
  };

  
  const isOtherThanObject = (value:any) => typeof value !== 'object' ;
  //modify the data for Timeline component
  
  const eventData = useMemo(() => {
    if (!userDetails.eventlist || !userDetails.eventlist.el) {
      return [];
    }
  
    const modifiedData = userDetails.eventlist.el.map((event:any) => {
      const availableStrings = [];
  //mapping attributes
      for (const key in event.segment) {
        if (isOtherThanObject(event.segment[key])) {
          availableStrings.push(`${key}:${event.segment[key]}`);
        }
      }  
      const availableString = availableStrings.join(',');
      return {
        label: formatDate(event.p, "DD/MM/YY:HH:mm"),
        children: `${event.k}${availableString ? `,${availableString}` : ''}`,
      };
    });
  
    return modifiedData;
  }, [userDetails]);
  useEffect(() => {
    //fetch user data if user is login
    if (loginUser.api_key) {

      fetchAppUserDetails();
    }
  }, [loginUser]);

  return (
    <div className="c-three-sixty-main-container">
      <Helmet>
        <title>Appice | C360</title>
      </Helmet>
      {/* -----header container starts from here----- */}
      <div className="d-flex justify-content-between my-3">
       
        {/* -----recent activity container starts here----- */}

        <div className="c-three-sixty-recent-activity c-three-sixty-recent-activity-width   p-4 ">
          <div className="mb-2" >
            <h6 className="m-0">{t("recentActivityLabel")}</h6>
          </div>
          <div className=" d-flex c-three-sixty-timeline-wrapper  pt-2 " >
            <CustomTimeline mode="left" layout="event" items={eventData} />
          </div>
        </div>
        {/* -----recent activity container ends here----- */}
      </div>
     
    </div>
  );
};

export default AppUsersDetails;
