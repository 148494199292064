import { CloseOutlined } from "@ant-design/icons";
import { Card } from "antd";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import FormItem from "antd/es/form/FormItem";
import TextInput from "../../Inputs/TextInput/TextInput";
import Selector from "../../Selector/Selector";
import { pushActionButtonType } from "@customTypes/template";

export const ActionButtons = ({
  buttonsState,
  setButtonsState,
  type,
  maxButtons,
}: {
  buttonsState: pushActionButtonType[];
  setButtonsState: (data: pushActionButtonType[]) => void;
  type: "PUSH" | "WEB_PUSH" | "WHATSAPP";
  maxButtons: number;
}) => {
  const types = {
    PUSH: { actionName: "", actionType: "", actionExternalUrl: "" },
    WEB_PUSH: { actionName: "", actionType: "", actionExternalUrl: "" },
    WHATSAPP: {
      actionName: "",
      actionType: "",
      urlType: "",
      actionExternalUrl: "",
      countryCode: "",
    },
  };
  const handleAddClick = () => {
    setButtonsState([...buttonsState, types[type]]);
  };
  const handleRemoveClick = (indexToRemove: number) => {
    const modifiedButtons = buttonsState.filter(
      (_: any, index: number) => index !== indexToRemove
    );
    setButtonsState(modifiedButtons);
  };
  const handleActionChange = (key: string, index: number, value: string) => {
    const actions = [...buttonsState];
    actions[index] = { ...actions[index], [key]: value };
    setButtonsState(actions);
  };

  return (
    <>
      {buttonsState?.map((button: any, index: number) => {
        const isLastButton = buttonsState.length - 1 == index;
        return (
          <>
            <ActionButtonCard
              key={index}
              index={index}
              onCloseClick={isLastButton ? handleRemoveClick : undefined}
            >
              {(type == "PUSH" || type == "WEB_PUSH") && (
                <div>
                  <div className="d-flex w-100 gap-2">
                    <FormItem className="my-1 w-100">
                      <TextInput
                        value={button.actionName}
                        onChange={(e: any) => {
                          handleActionChange(
                            "actionName",
                            index,
                            e.target.value
                          );
                        }}
                        placeholder="Button name"
                      />
                    </FormItem>
                    <FormItem className="my-1 w-100">
                      <Selector
                        placeholder="Button Type"
                        className="w-100"
                        value={button.actionType}
                        style={{ width: 140 }}
                        onChange={(value) => {
                          handleActionChange("actionType", index, value);
                        }}
                        options={[
                          {
                            label: "Open App",
                            value: "oa",
                          },
                          {
                            label: "Redirect to Web URL",
                            value: "dl",
                          },
                          {
                            label: "Deeplink into Application",
                            value: "lp",
                          },
                          {
                            label: "Call",
                            value: "call",
                          },
                        ]}
                      />
                    </FormItem>
                  </div>
                  <FormItem className="my-1">
                    <TextInput
                      value={button.actionExternalUrl}
                      onChange={(e: any) => {
                        handleActionChange(
                          "actionExternalUrl",
                          index,
                          e.target.value
                        );
                      }}
                      placeholder={
                        button.actionType == "call" ? "Phone" : "Url"
                      }
                    />
                  </FormItem>
                </div>
              )}
              {type == "WHATSAPP" && (
                <div>
                  <div className="d-flex w-100 gap-2">
                    <FormItem className="my-1 w-100">
                      <Selector
                        placeholder="Button Type"
                        className="w-100"
                        value={button.actionType}
                        style={{ width: 140 }}
                        onChange={(value) => {
                          handleActionChange("actionType", index, value);
                        }}
                        options={[
                          {
                            value: "visit_website",
                            label: "Visit website",
                          },
                          {
                            value: "call",
                            label: "Call phone ",
                          },
                        ]}
                      />
                    </FormItem>
                    <FormItem className="my-1 w-100">
                      <TextInput
                        value={button.actionName}
                        onChange={(e: any) => {
                          handleActionChange(
                            "actionName",
                            index,
                            e.target.value
                          );
                        }}
                        placeholder="Button name"
                      />
                    </FormItem>
                  </div>
                  <div className="d-flex w-100 gap-2">
                  
                    {button.actionType == "call" ? (
                      <FormItem className="my-1 w-100">
                        <Selector
                          // className="w-100"
                          // style={{ width: 140 }}

                          value={button.countryCode}
                          onChange={(value) => {
                            handleActionChange("countryCode", index, value);
                          }}
                          placeholder="Country code"
                          options={[
                            {
                              value: "+91",
                              label: "+91",
                            },
                            {
                              value: "+974",
                              label: "+974",
                            },
                            {
                              value: "+966",
                              label: "+966",
                            },
                          ]}
                        />
                      </FormItem>
                    ) : (
                      <FormItem className="my-1 w-100">
                        <Selector
                          placeholder=""
                          // style={{ width: 140 }}

                          // className="w-100"
                          value={button.urlType}
                          onChange={(value) => {
                            handleActionChange("urlType", index, value);
                          }}
                          options={[
                            {
                              value: "Dynamic",
                              label: "Dynamic",
                            },
                          ]}
                        />
                      </FormItem>
                    )}
                    <FormItem className="my-1 w-100">
                      <TextInput
                        value={button.actionExternalUrl}
                        onChange={(e: any) => {
                          handleActionChange(
                            "actionExternalUrl",
                            index,
                            e.target.value
                          );
                        }}
                        placeholder={
                          button.actionType == "call" ? "Phone" : "Url"
                        }
                      />
                    </FormItem>
                  </div>
                </div>
              )}
            </ActionButtonCard>
            {isLastButton && maxButtons !== buttonsState?.length && (
              <PrimaryButton onClick={handleAddClick}>Add Button</PrimaryButton>
            )}
          </>
        );
      })}
    </>
  );
};
interface ActionButtonCardProps {
  onCloseClick?: (name: number) => void;
  index: number;
  children: React.ReactNode;
}

const ActionButtonCard = ({
  onCloseClick,
  index,
  children,
}: ActionButtonCardProps) => {
  return (
    <Card
      style={{ width: "100%" }}
      className="my-1"
      size="small"
      title={`Button ${index + 1}`}
      extra={
        onCloseClick && (
          <CloseOutlined
            onClick={() => {
              onCloseClick(index);
            }}
          />
        )
      }
    >
      {children}
    </Card>
  );
};

export default ActionButtonCard;
