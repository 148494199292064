import React from "react";
import { Spin } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
type propType = {
  size?: "small" | "large" | "default";
};
const Spinner: React.FC<propType> = ({ ...props }) => (
  <div className="d-flex h-100 justify-content-center align-items-center">
    <Spin {...props} />
  </div>
);

export default Spinner;
