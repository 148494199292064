// DataAggregator.tsx

interface DataObject {
  [key: string]: any;
}
export function aggregateData(
  data: DataObject,
  platformName: string,
  appName: string,
  propertyName: string
): DataObject | null {
  const platforms = Object.keys(data);

  if (platformName.toLowerCase() === "all") {
    const aggregatedData: DataObject = {};

    platforms.forEach((platform) => {
      if (data[platform][appName] && data[platform][appName][propertyName]) {
        const propertyData = data[platform][appName][propertyName];

        for (const range in propertyData) {
if (Object.prototype.hasOwnProperty.call(propertyData, range)) {
            if (!aggregatedData[range]) {
              aggregatedData[range] = 0;
            }
            aggregatedData[range] += propertyData[range];
          }
        }
      }
    });

    return aggregatedData;
  }

  if (!platforms.includes(platformName)) {
    return null;
  }

  const platform = platformName;

  if (!data[platform][appName] || !data[platform][appName][propertyName]) {
    return null;
  }

  const propertyData = data[platform][appName][propertyName];
  const aggregatedData: DataObject = {};

  for (const range in propertyData) {
    // if (propertyData.hasOwnProperty(range)) {
      if (Object.prototype.hasOwnProperty.call(propertyData, range)) {

      aggregatedData[range] = propertyData[range];
    }
  }

  return aggregatedData;
}

export function aggregateTimelySessionData(
  data: DataObject | null,
  platformName: string
): DataObject | null {
  if (!data || !data.current || !Array.isArray(data.current)) {
    return {}; // Return an empty object if data is null, does not have 'current' property, or 'current' is not an array
  }

  const aggregatedData: DataObject = {};
  data.current.forEach((platformData: any) => {
    if (
      platformName === "all" ||
      (platformData && Object.prototype.hasOwnProperty.call(platformData, platformName))
      ) {
      const platforms: string[] =
        platformName === "all" ? Object.keys(platformData) : [platformName];

      platforms?.forEach((platform: string) => {
        if (platformData[platform] && Array.isArray(platformData[platform])) {
          platformData[platform].forEach((timePeriodEntry: any) => {
            const [timePeriod, timePeriodValue] =
              Object.entries(timePeriodEntry)[0];

            if (!aggregatedData[timePeriod]) {
              aggregatedData[timePeriod] = {};
            }

            const timePeriodValues = timePeriodValue as {
              [key: string]: number;
            };

            for (const place in timePeriodValues) {
              if (!aggregatedData[timePeriod][place]) {
                aggregatedData[timePeriod][place] = 0;
              }
              aggregatedData[timePeriod][place] += timePeriodValues[place] || 0;
            }
          });
        }
      });
    }
  });

  return aggregatedData;
}

// Usage example:
export function formatData(inputData: DataObject | null) {
  if (inputData === null) {
    return []; // or any other default value you prefer when inputData is null
  }

  return Object.entries(inputData).map(([id, values]) => {
    const data = Object.entries(values).map(([place, value]) => ({
      x: place,
      y: value || 0,
    }));
    return { id, data };
  });
}
export function calculateTotalValues(data: any, platform: string): any {
  const totalValues: any = {};
  const platforms = Object.keys(data);

  platforms.forEach((platformName) => {
    if (platform === "all" || platform === platformName) {
      if (data[platformName]) {
        for (const field in data[platformName]) {
          if (typeof data[platformName][field] === "number") {
            if (!totalValues[field]) {
              totalValues[field] = 0;
            }
            totalValues[field] += data[platformName][field];
          }
        }
      }
    }
  });

  return totalValues;
}

export function fillMissingValues(data: any[])  {
  if (!Array.isArray(data)) {
    return [];
  }

  const allXValues = Array.from(new Set(data.flatMap(item => item.data.map((d:any) => d.x))));
  return data.map(item => ({
    id: item.id,
    data: allXValues.map(xValue => ({
      x: xValue,
      y: (item.data.find((d: any) => d.x === xValue) || { y: 0 }).y
    }))
  }));
};