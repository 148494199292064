import { Col, Form, Row } from "antd";
import "./UtmBuilder.css";
import { t } from "i18next";
import TextInput from "../../components/Inputs/TextInput/TextInput";
import PrimaryButton from "../../components/Buttons/PrimaryButton/PrimaryButton";
import { validateCampaignId } from "../../validator/antdValidator";
import { useEffect, useState } from "react";
import { CopyOutlined } from "@ant-design/icons";
import { getToken } from "../../services/getToken";
import { useSelector } from "react-redux";
import centralApi from "../../services/centralApi";
import { copyText } from "../../helperFunctions/string";
import ShowMessage from "../../components/ShowMessage/ShowMessage";
const UtmBuilder: React.FC = () => {
  const [form] = Form.useForm();
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState({ generatedUrl: "", shortenUrl: "" });
  const [showOutput, setShowOutput] = useState(false);
  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  });
  const fetch_token = async () => {
    await getToken(appId, loginUser.api_key);
  };
  const sendFormData = async (url: string, campId: string) => {
    const params = {
      api_key: loginUser.api_key,
      app_id: appId,
      campId,
      args: JSON.stringify({ url }),
    };
    const res = await centralApi(
      "GET",
      "o/metrices/postShortUrl",
      null,
      params
    );
    return res.toString();
  };
  const handleCopy = async (value: string) => {
   const {message,success}=await copyText(value)
   setShowMessage({type:success?"success":"error",message:message,show:true})
   setTimeout(() => {
    setShowMessage({
      type: "success",
      message: "",
      show: false,
    });
  }, 2000);

  };
  const onFormSubmit = async (value: any) => {
    try {
      const generated_url = `https://${value.url}?utm_id=${
        value.campaignId
      }&utm_source=${value.campaignSource}&utm_medium=${
        value.campaignMedium
      }&utm_name=${value.campaignName}${
        value.campaignTerm ? "&utm_term=" + value.campaignTerm : ""
      }${value.campaignContent ? "&utm_content=" + value.campaignContent : ""}`;
      setLoading(true);
      const shortUrl = await sendFormData(generated_url, value.campaignId);
      if(shortUrl==500){
        form.setFields([
          {
            name: 'campaignId',
            errors: [t("enterValidCampaignIdMsg")],
          },])
      }else{

        setShowOutput(true);
      }
      setLoading(false);
      setUrl({ shortenUrl: shortUrl, generatedUrl: generated_url });
    } catch (error) {
      setLoading(false);
      setShowOutput(false);
    }
  };
  useEffect(() => {
    fetch_token();
  }, []);
  return (
    <div className="utmbuilder-container">
      <div>
        <div className="utmbuilder-heading">
          <h4>{t("campaignURLBuilderLabel")}</h4>
        </div>
        <div className="utmbuilder-body p-3 mt-3 ">
        {showMessage.show && (
            <ShowMessage
              type={showMessage.type}
              content={showMessage.message}
            />
          )}
          <Form
            form={form}
            layout="vertical"
            onFinish={onFormSubmit}
            onFieldsChange={() => {
              setShowOutput(false);
            }}
          >
            <Form.Item
              label={t("urlLabel")}
              name={"url"}
              rules={[{ required: true }]}
            >
              <TextInput
                addonBefore="https://"
                placeholder={t("enterUrlLabel")}
              />
            </Form.Item>
            <Row className="d-flex justify-content-between">
              <Col span={11}>
                <Form.Item
                  label={t("campaignIdLabel")}
                  name={"campaignId"}
                  rules={[
                    {
                      required: true,
                      validator: validateCampaignId,
                    },
                  ]}
                >
                  <TextInput placeholder={t("enterCampaignIdLabel")} />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  label={t("campaignSrcLabel")}
                  name={"campaignSource"}
                  rules={[{ required: true }]}
                >
                  <TextInput placeholder={t("enterCampaignSrcLabel")} />
                </Form.Item>
              </Col>
            </Row>

            <Row className="d-flex justify-content-between">
              <Col span={11}>
                <Form.Item
                  label={t("campaignMediumLabel")}
                  name={"campaignMedium"}
                  rules={[{ required: true }]}
                >
                  <TextInput placeholder={t("enterCampaignMediumLabel")} />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  label={t("campaignNameLabel")}
                  name={"campaignName"}
                  rules={[{ required: true }]}
                >
                  <TextInput placeholder={t("enterCampaignNameLabel")} />
                </Form.Item>
              </Col>
            </Row>
            <Row className="d-flex justify-content-between">
              <Col span={11}>
                <Form.Item label={t("campaignTermLabel")} name={"campaignTerm"}>
                  <TextInput placeholder={t("enterCampaignTermLabel")} />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  label={t("campaignContentLabel")}
                  name={"campaignContent"}
                >
                  <TextInput placeholder={t("enterCampaignContentLabel")} />
                </Form.Item>
              </Col>
            </Row>
            <div className="d-flex justify-content-end">
              <PrimaryButton loading={loading} type="primary" htmlType="submit">
                {t("generateUrlLabel")}
              </PrimaryButton>
            </div>
          </Form>
        </div>
        {showOutput && (
          <div className="utmbuilder-footer p-3 ">
            <Form layout="vertical">
              <Form.Item label={t("generatedUrlLabel")}>
                <TextInput
                  className="mt-2 "
                  addonAfter={
                    <CopyOutlined
                      onClick={() => handleCopy(url.generatedUrl)}
                    />
                  }
                  value={url.generatedUrl}
                />
              </Form.Item>
              <Form.Item label={t("shortenUrlLabel")}>
                <TextInput
                  className="mt-2 "
                  addonAfter={
                    <CopyOutlined
                      onClick={() => handleCopy(url.shortenUrl)}
                    />
                  }
                  value={url.shortenUrl}
                />
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};
export default UtmBuilder;
