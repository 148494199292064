import { Modal } from "antd";
import { useEffect, useState } from "react";
import Selector from "../../Selector/Selector";
import validator from "validator";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import centralApi from "services/centralApi";
import useMessage from "hooks/useMessage";
import TextInput from "components/Inputs/TextInput/TextInput";
import { Form } from "antd";
import { getToken } from "services/getToken";

const AddUserModel = ({
  visibility,
  setVisibility,
  getUpdatedData,
  // setShowMessage,
}: any) => {
  const { t } = useTranslation();
  const emailLabel = t("emailLabel");
  const selectLabel = t("selectLabel");
  const { showError, showSuccess } = useMessage();
  const [form] = Form.useForm();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [role, setRole] = useState("");
  const { appId } = useSelector((state: any) => state.activeApp);
  const loginUser = useSelector((state: any) => state.loginUser.data);

  async function validateEmail() {
    const payload = {
      args: JSON.stringify({
        email: email,
      }),

      api_key: loginUser.api_key,
    };
    const url: string = `/o/users/validateEmail`;
    try {
      const data = await centralApi("GET", url, null, payload);
      if (data?.result) {
        setEmailError(data.result);

        // showError(data.result);
        return false;
      }
      return true;
    } catch (error) {
      showError(t("somethingWrongLabel"));
      return false;
    }
  }
  const handleSave = async () => {

    try {
      if (email === "" || role === "") {
        showError(t("fillAllDetailsMsg"));
      } else if (!validator.isEmail(email)) {
        showError(t("enterValidMsg"));
      } else {
        const payload = {
          args: JSON.stringify({
            modified_by: loginUser._id,
            appid: appId,
            role: role,
            email: email,
          }),
          api_key: loginUser.api_key,
          app_id: appId,
        };
  
        const url = `/o/users/addUsersRole`;
  
        const data = await centralApi("POST", url, payload, null);
  if (data. acknowledged == true) {
          await getUpdatedData();

          showSuccess(t("userAddedSuccessfullyMsg"));
          setEmail("");
            setRole("");
            setVisibility(false);
          

        } else {
          showError(t("emailNotExistsMsg"));

        }
        
  
      }
    } catch (error) {
      console.error("Error in handleSave:", error);
      showError(t("somethingWrongLabel"));
    }
  };
  
  setTimeout(() => {
    // setShowMessage({ type: "", message: "", show: false });
  }, 2000);
  // const handleSave = async () => {
  //   // await form.validateFields();

  //   if (email === "" || role === "") {
  //     showError(t("fillAllDetailsMsg"));
  //   } else if (!validator.isEmail(email)) {
  //     showError(t("enterValidMsg"));
  //   } else {
  //     // const isEmailValid = await validateEmail();

  //     try {
  //       console.log("helo1")

  //       const payload = {
  //         // email,
  //         // role,
  //         // apiKey: loginUser.api_key,
  //         // appId,
  //         // loginUserId: loginUser._id,
  //         args: JSON.stringify({
  //           modified_by: loginUser._id,
  //           appid: appId,
  //           role: role,
  //           email: email,

  //         }),
  //         apiKey: loginUser.api_key,
  //         app_id:appId
  //       };
  //       console.log("payload",payload)
  //       // const _csrf = getCookie("_csrf");
  //       // const payload = {
  //       //   args: JSON.stringify({
  //       //     email: email,
  //       //   }),

  //       //   api_key: loginUser.api_key,
  //       // };
  //       const url: string = `/o/users/addUsersRole`;
  //       // try {
  //         console.log("try",payload)

  //         const {data} =       await centralApi("POST", url, payload,null);
  //         ;
  //         console.log("data",data)
  //       //  } catch (error) {
  //       //   showError(t("somethingWrongLabel"));
  //       //   return false;
  //       // }
  //       // const { data } = await addUser(payload, _csrf ? _csrf : "");
  //       // this function will load the updated data in user management
  //       // if email exists in db
  //       // if (data.n == 1 || data.nModified == 1) {
  //       //   await getUpdatedData();
  //       //   showSuccess(t("userAddedSuccessfullyMsg"));
  //       // } else {
  //       //   showError(t("emailNotExistsMsg"));
  //       // }
  //       setEmail("");
  //       setRole("");
  //       setVisibility(false);
  //     } catch (error) {
  //       showError(t("somethingWrongLabel"));

  //       setVisibility(false);
  //     }
  //   }
  //   setTimeout(() => {
      // setShowMessage({ type: "", message: "", show: false });
  //   }, 2000);
  // };
  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
    setEmailError(""); // Clear error when user starts typing
  };
  const handleRoleChange = async (value: any) => {
    const isEmailValid = await validateEmail();
    if (isEmailValid) {
      setRole(value);
    }
  };
  const handleDropdownVisibleChange = async (open: boolean) => {
    if (open) {
      try {
        await validateEmail();
      } catch (error) {
        console.error("Validation error:", error);
      }
    }
  };
  useEffect(() => {
    getToken(appId, loginUser.api_key);

  }, []);
  return (
    <Modal
      title={t("addNewUserLabel")}
      centered
      open={visibility}
      okText={t("saveLabel")}
      cancelText={t("cancelLabel")}
      onOk={handleSave}
      onCancel={() => setVisibility(false)}
      style={{ position: "relative" }}
    >
      <Form
        form={form}
        layout="horizontal"
        // onFinish={onFormSubmit}
      >
        <Form.Item
          required={true}
          className="m-0"
          validateStatus={emailError ? "error" : ""}
          help={emailError}
        >
          <TextInput
            value={email}
            onChange={handleEmailChange}
            // size="large"
            type="email"
            placeholder={emailLabel}
            style={{ width: "100%", margin: "15px 0px" }}
          />
        </Form.Item>
        <Form.Item required={true} className="m-0">
          <Selector
            placeholder={selectLabel}
            // onChange={(value) =>
            //   validateEmail()
            //    setRole(value)}
            onChange={handleRoleChange}
            onDropdownVisibleChange={handleDropdownVisibleChange}
            style={{ width: "100%", margin: "15px 0px" }}
            // size="large"
            options={[
              {
                value: "admin",
                label: t("adminLabel"),
              },
              {
                value: "manager",
                label: t("managerLabel"),
              },
              {
                value: "marketer",
                label: t("marketerLabel"),
              },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddUserModel;
