import centralApi from "../services/centralApi";

export const validateName = async (
  templateName: string,
  type: "PUSH" | "WHATSAPP" | "WEB_PUSH" |"IN-APP"|"NATIVE",
  apiKey: string,
  appId: string
) => {
  const payload = {
    api_key: apiKey,
    app_id: appId,
    template_name: templateName,
    type: type,
  };
  const url = `/i/templates/validateTemplateName`;

  return await centralApi("GET", url, null, payload);
};
