import pushNotificationIcon from "../../../../../../images/Push_new.svg"
import inAppNotificationIcon from "../../../../../../images/InApp_new.svg"
import inAppSurveyIcon from "../../../../../../images/InApp_new.svg"
import webBellIcon from "../../../../../../images/Web_Push_new.svg"
import inAppIcon from "../../../../../../images/Rating_new.svg"
import emailIcon from "../../../../../../images/Email_new.svg"
import webPopupIcon from "../../../../../../images/Web_Popup_new.svg"
import messageIcon from "../../../../../../images/SMS_new.svg"
import googleIcon from "../../../../../../images/Google.svg"
import facebookIcon from "../../../../../../images/Facebook.svg"
import whatsappIcon from "../../../../../../images/WhatsApp_new.svg"
import landingPageIcon from "../../../../../../images/Landing_Pg.svg"
import nativeDisplayIcon from "../../../../../../images/NativeDisplay.svg"
import "./EngagementToolHome.css"
import React, { ReactElement, useEffect, useState } from "react"
import MobilePushNotification from "../MobilePushNotification/MobilePushNotification"
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { setCampaignData } from "../../../../../../redux/features/campaign/campaignDataSlice"
import SMS from "../SMS/SMS"
import InAppNotification from "../InAppNotification/InAppNotification"
import LandingPage from "../LandingPage/LandingPage"
import WebPush from "../WebPush/WebPush"
import Email from "../Email/Email"
import Facebook from "../Facebook/Facebook"
import WhatsApp from "../WhatsApp/WhatsApp"
import Google from "../Google/Google"
import WebPopUp from "../WebPopUp/WebPopUp"
import NativeDisplay from "../NativeDisplay/NativeDisplay"
type toolType = {
  id: toolIdType
  label: string
  icon: string
  component: ReactElement
}
type toolIdType =
  | "PUSH"
  | "IN-APP"
  | "IN-APP-RATING"
  | "IN-APP-SURVEY"
  | "SMS"
  | "WEB_PUSH"
  | "WEB_POPUP"
  | "EMAIL"
  | "Facebook"
  | "WHATSAPP"
  | ""
  | "LandingPage"
  | "Google"
  | "NATIVE"
function Tool({ label, icon, onClick }: any) {
  return (
    <div
      onClick={onClick}
      className="d-flex flex-column engagement-tool-comp align-items-center"
    >
      <img
        className="engagement-tool-comp-icon"
        height={120}
        width={120}
        src={icon}
      ></img>
      <h6>{label}</h6>
    </div>
  )
}
function EngagementToolHome() {
  const dispatch: any = useDispatch()
  const features = useSelector((state: any) => state.featuresSetting.data);
  const featuresSetting = features[0]?.features;
  const campaignData = useSelector((state: any) => state.campaignData.data)
  const [selectedTool, setSelectedTool] = useState<toolIdType>(
    campaignData.t || ""
  )
  const { state } = useLocation()
  useEffect(() => {
    if (state?.cid && state?.type) {
      setSelectedTool(state.type)
    }
  }, [state])
  useEffect(() => {
    setSelectedTool(campaignData.t)
  }, [campaignData?.t])
  const toolData: toolType[] = [
    {
      id: "PUSH",
      label: "Push",
      icon: pushNotificationIcon,
      component: <MobilePushNotification />,
    },
      {
      id: "IN-APP",
      label: "In-App",
      icon: inAppNotificationIcon,
      component: <InAppNotification />,
    },

    {
      id: "SMS",
      label: "SMS",
      icon: messageIcon,
      component: <SMS />,
    },
    {
      id: "EMAIL",
      label: "Email",
      icon: emailIcon,
      component: <Email />,
    },
    {
      id: "WEB_PUSH",
      label: "Web Push",
      icon: webBellIcon,
      component: <WebPush />,
    },

    {
      id: "WEB_POPUP",
      label: "Web Popup",
      icon: webPopupIcon,
      component: <WebPopUp />,
    },
    {
      id: "WHATSAPP",
      label: "WhatsApp",
      icon: whatsappIcon,
      component: <WhatsApp />,
    },
    {
      id: "IN-APP-RATING",
      label: "In-App Rating",
      icon: inAppIcon,
      component: <></>,
    },
    {
      id: "IN-APP-SURVEY",
      label: "In-App Survey",
      icon: inAppSurveyIcon,
      component: <></>,
    },
    {
      id: "LandingPage",
      label: "Landing Page",
      icon: landingPageIcon,
      component: <LandingPage />,
    },
    {
      id: "Facebook",
      label: "Facebook",
      icon: facebookIcon,
      component: <Facebook />,
    },
    {
      id: "Google",
      label: "Google",
      icon: googleIcon,
      component: <Google />,
    },
    {
      id: "NATIVE",
      label: "Native Display",
      icon: nativeDisplayIcon,
      component: <NativeDisplay />,
    },

  
  ]
  const filteredToolData = toolData.filter(tool => {
    // If campExceptPush is true, hide all tools except "PUSH"
    return !featuresSetting?.campExceptPush || tool.id === "PUSH";
  });
  return (
    <div className="campaign-engagement-tool-content  p-2 ">
      {selectedTool
        ? toolData.find(({ id }) => id == selectedTool)?.component
        : filteredToolData.map((tool: toolType) => {
            return (
              <Tool
                key={tool.label}
                {...tool}
                onClick={() => {
                  setSelectedTool(tool.id)
                  dispatch(setCampaignData({ ...campaignData, t: tool.id }))
                }}
              />
            )
          })}
    </div>
  )
}

export default React.memo(EngagementToolHome)
