import React, { useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DrawingManager,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import TextInput from "components/Inputs/TextInput/TextInput";
import FormItem from "antd/es/form/FormItem";
import { Form } from "antd";
import config from "config";
import { GeoFencingModel } from "utils/models";
import { t } from "i18next";
import { GoogleMapShapes } from "utils/enums";
import Spinner from "components/Spinner/Spinner";

/**
 * GeoFencing Component
 * 
 * This component allows users to define geographic regions (geo-fencing) using Google Maps.
 * It provides functionalities to draw circles, polygons, and rectangles on the map,
 * search for places, and save geo-fencing details with a name and description.
 * 
 * Props:
 * - setGeoInfo: Function to update geo fencing information in the parent component.
 */
interface GeoFencingPropsType {
  setGeoInfo: (data: GeoFencingModel) => void;
}

// Main component function
const GeoFencing: React.FC<GeoFencingPropsType> = ({ setGeoInfo }) => {
  // Load Google Maps API using useJsApiLoader hook
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: config.GOOGLE_MAP_KEY, // API key from configuration
    libraries: ["places", "drawing"], // Additional libraries required
  });

  // State variables to manage component state
  const [data, setData] = useState<GeoFencingModel>({ name: "", desc: "", details: [] });
  const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox | null>(null);
  const drawingManagerRef = useRef<google.maps.drawing.DrawingManager | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow | null>(null);
  const [markers, setMarkers] = useState<google.maps.Marker[]>([]);
  const [geo, setGeo] = useState<any[]>([]);
  const [shapes, setShapes] = useState<(google.maps.Circle | google.maps.Polygon | google.maps.Rectangle | undefined)[]>([]);


  // Effect hook to get the user's current location and set it on the map
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = { lat: position.coords.latitude, lng: position.coords.longitude };
          if (infoWindow && map) {
            infoWindow.open(map); // Open info window at current location
            map.setCenter(pos); // Center map at current location
          }
        },
        () => { handleLocationError(true); } // Handle error if location cannot be determined
      );
    } else {
      handleLocationError(false); // Handle case where browser doesn't support geolocation
    }
  }, [infoWindow, map]);

  /**
   * Handles errors related to geolocation.
   * @param {boolean} browserHasGeolocation - Indicates if the browser supports geolocation.
   */
  const handleLocationError = (browserHasGeolocation: boolean) => {
    const pos = map?.getCenter();
    if (pos && infoWindow) {
      infoWindow.setPosition(pos); // Set position of info window at map center
      infoWindow.setContent(browserHasGeolocation ? t("geoLocServiceFailedMsg") : t("geoLocBrowserErrorMsg"));
      infoWindow.open(map); // Open info window with error message
    }
  };

  /**
   * Event handler for when the map loads.
   * @param {google.maps.Map} map - The loaded Google Map instance.
   */
  const onMapLoad = (map: google.maps.Map) => {
    setMap(map); // Set map state
    setInfoWindow(new google.maps.InfoWindow()); // Initialize info window
  };

  // Event handler for when places change in the search box
  const onPlacesChanged = () => {
    const places = searchBox?.getPlaces();
    if (places?.length === 0) return;

    const newMarkers: google.maps.Marker[] = [];
    const bounds = new google.maps.LatLngBounds();
    places?.forEach((place) => {
      if (!place.geometry) return;
      const location = place.geometry.location;
      if (location) {
        const icon = {
          url: place.icon!,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25),
        };

        // Create a marker for each place
        const marker = new google.maps.Marker({
          position: location,
          icon,
          title: place.name,
          map: map,
        });

        newMarkers.push(marker);

        // Adjust map bounds to include this place
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(location);
        }
      }
    });
    setMarkers(newMarkers); // Update markers state
    map?.fitBounds(bounds); // Fit map to the new bounds
  };

  /**
   * Event handler for when an overlay (circle, polygon, rectangle) is completed.
   * @param {google.maps.drawing.OverlayCompleteEvent} event - Event object containing information about the completed overlay.
   */
  const onOverlayComplete = (event: google.maps.drawing.OverlayCompleteEvent) => {
    drawingManagerRef.current?.setDrawingMode(null); // Disable drawing mode

    let newShape: google.maps.Circle | google.maps.Polygon | google.maps.Rectangle | undefined=undefined


    if (event.type === GoogleMapShapes.CIRCLE.toString()) {
      const circle = event.overlay as google.maps.Circle;
      const radius = circle.getRadius();
      const path = circle.getCenter();
        if (path) placeMarker(path, radius); // Place marker at circle center
      setGeo((prev) => [
        ...prev,
        {
          type: event.type,
          coordinates: { lat: path?.lat(), lng: path?.lng() },
          radius,
        },
      ]);
      newShape = circle;
    }

    if (event.type === GoogleMapShapes.POLYGON.toString() || event.type === GoogleMapShapes.RECTANGLE.toString()) {
      const polygon = event.overlay as google.maps.Polygon;
      const path = polygon.getPath().getArray();
      setGeo((prev) => [
        ...prev,
        {
          type: event.type,
          coordinates: path.map((coord) => ({ lat: coord.lat(), lng: coord.lng() })),
        },
      ]);
      newShape = polygon;
    }

    if (newShape) {
      setShapes((prev) => [...prev, newShape]); // Store the shape for later removal
    }
  };
  // Function to save geo details when the form is submitted
  const handleSaveGeoDetails = async () => {
    setGeoInfo({ ...data, details: geo }); // Set geo info with updated data
  };

  // Function to place a marker on the map
  const placeMarker = (position: google.maps.LatLng, radius: number) => {
    const marker = new google.maps.Marker({
      position,
      label: `${position.toUrlValue(5)} ( Radius: ${parseInt(radius.toString(), 10)} )`,
      map,
    });
    setMarkers((prev) => [...prev, marker]); // Update markers state
  };
  //Removes the most recent shape from the map and updates the shapes and marker state.
  const removeShape = () => {
    // Get the last shape from the shapes array, or undefined if the array is empty
    const lastShape = shapes.length ? shapes[shapes.length - 1] : undefined;
    
    // Get the last marker from the markers array, or undefined if the array is empty
    const lastMarker = markers.length ? markers[markers.length - 1] : undefined;
    
    // Check if there is a last marker and the last shape is type Circle
    if (lastMarker && lastShape instanceof google.maps.Circle) {
      setMarkers((prevState) => {
        // Remove the last marker from the map
        lastMarker.setMap(null);
        
        // Create a new array from the previous state and remove the last marker
        const modifiedState = [...prevState];
        modifiedState.pop();
        
        // Return the updated array of markers
        return modifiedState;
      });
    }
  
    // Check if there is a last shape
    if (lastShape) {
      setShapes((prevState) => {
        // Remove the last shape from the map
        lastShape.setMap(null);
        
        // Create a new array from the previous state and remove the last shape
        const modifiedState = [...prevState];
        modifiedState.pop();
        
        // Return the updated array of shapes
        return modifiedState;
      });
  
      // Also update the main state 
      setGeo((prevState) => {
        // Create a new array from the previous state and remove the last geo entry
        const modifiedState = [...prevState];
        modifiedState.pop();
        
        // Return the updated array of geo data
        return modifiedState;
      });
    }
  };

  // Options for drawing shapes
  const shapeOptions = {
    fillColor: "#f0f0f0",
    fillOpacity: 0.2,
    strokeWeight: 2,
    clickable: true,
    editable: true,
    zIndex: 1,
  };

  // Return loading state if the API is not loaded
  if (!isLoaded) return <div className="d-flex justify-content-center align-item-center w-100 h-100"><Spinner/></div>;

  // Main return of the component
  return (
    <div className="position-relative">
      <Form className="row" onFinish={handleSaveGeoDetails}>
        <div className="col-6">
          {/* Search Box for entering location names */}
          <StandaloneSearchBox
            onLoad={(ref) => setSearchBox(ref)}
            onPlacesChanged={onPlacesChanged}
          >
            <FormItem
              label={t("nameLabel")}
              name="name"
              rules={[{ required: true }]}
            >
              <TextInput
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setData({ ...data, name: e.target.value }); // Update name in state
                }}
              />
            </FormItem>
          </StandaloneSearchBox>
        </div>
        <FormItem
          className="col-6"
          label={t("descriptionLabel")}
          name="description"
          rules={[{ required: true }]}
        >
          <TextInput
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setData({ ...data, desc: e.target.value }); // Update description in state
            }}
          />
        </FormItem>

        {/* Google Map Component */}
        <GoogleMap
          id="map"
          mapContainerStyle={{ width: "100%", height: "75vh" }}
          zoom={8}
          onLoad={onMapLoad}
        >
          {markers.map((marker, index) => (
            <Marker
              key={index}
              position={marker.getPosition()!}
              icon={marker.getIcon() as google.maps.Icon}
              title={marker.getTitle() || ""}
            />
          ))}
          {/* Drawing Manager for drawing shapes on the map */}
          <DrawingManager
            onLoad={(drawingManager) => {
              drawingManagerRef.current = drawingManager;
            }}
            onOverlayComplete={onOverlayComplete}
            options={{
              drawingControl: true,
              drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [
                  google.maps.drawing.OverlayType.CIRCLE,
                  google.maps.drawing.OverlayType.POLYGON,
                ],
              },
              circleOptions: shapeOptions,
              polygonOptions: shapeOptions,
              rectangleOptions: shapeOptions,
            }}
          />
        </GoogleMap>

        <div className="d-flex w-100 justify-content-end gap-2 pt-3">
          <PrimaryButton type="primary" disabled={!geo?.length} htmlType="submit">{t("saveLabel")}</PrimaryButton>
          <PrimaryButton type="default" onClick={removeShape} disabled={!geo?.length}>{t("clearLabel")}</PrimaryButton>

        </div>
      </Form>
    </div>
  );
};

export default GeoFencing;
