import { Fragment, useEffect } from "react";
import { Divider } from "antd";
import "./AppSettings.css";
import TextInput from "../../../../components/Inputs/TextInput/TextInput";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { useSelector } from "react-redux";
import React, { useState } from "react";
import centralApi from "../../../../services/centralApi";
import appstorenew from "../../../../images/appstore.png";
import googlePlay from "../../../../images/googleplay.png";
import { Form } from "antd";
import ShowMessage from "../../../../components/ShowMessage/ShowMessage";
import CustomCheckbox from "../../../../components/CustomCheckbox/CustomCheckbox";
import { getToken } from "services/getToken";
interface AppSettings {
  currentAppKey: any;
}

const AppSettings: React.FC<AppSettings> = ({ currentAppKey }) => {
  const [loading, setLoading] = useState(false);

  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  });
  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;
  const onFormSubmit = (values: any) => {
    const { iosUrl, googleUrl } = values;
    const payload = {
      args: JSON.stringify({
        iosUrl,
        googleUrl,
        app_id: appId,
        name: currentAppKey[0]?.name,
        isAppDeleted: "false",
      }),
      app_id: appId,

      api_key: api_key,
    };
    updateApp(payload);
  };
  async function updateApp(formData: any) {
    const url: string = `/i/apps/updateApp`;

    try {
      setLoading(true);
      await centralApi("POST", url, formData, null);
      setShowMessage({
        type: "success",
        message: "Successfully Saved",
        show: true,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setShowMessage({
        type: "error",
        message: "Something went wrong",
        show: true,
      });
    }
    hideMessage();
  }
  const hideMessage = () => {
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false });
    }, 2000);
  };
  useEffect(() => {
    
    getToken(appId, api_key)

  }, [])
  
  const onChange = (e: CheckboxChangeEvent) => {};
  return (
    <Fragment>
      {showMessage.show && (
        <ShowMessage type={showMessage.type} content={showMessage.message} />
      )}
      <div className="">
        <div>
          <h5 className="mt-4 app-settings-container-top">Update App Url</h5>
        </div>

        <div className="d-flex gap-1 app-settings-container-flex ">
          <div className="col-sm-12 col-lg-6  app-settings-container app-settings-borderRadius">
            <p className="app-settings-paragraph">
              Give us the path to your application and we will start gathering
              data and churn insights for you to act upon.If your app is
              available on different platforms,please make sure you provide us
              the path of all the platforms below
            </p>
            <Form
              // form={form}
              layout="horizontal"
              colon={false}
              // onValuesChange={onFormValueChange}
              // requiredMark={journeyData}
              onFinish={onFormSubmit}
            >
              <Form.Item
                label={
                  <div>
                    <img
                      src={appstorenew}
                      alt="app-store"
                      className="app-settings-imgs"
                    />{" "}
                  </div>
                }
                name="iosUrl"
                rules={[{ required: true, message: "Please input Url!" }]}
              >
                <TextInput />
              </Form.Item>
              <Form.Item
                label={
                  <div>
                    <img
                      src={googlePlay}
                      alt="app-store"
                      className="app-settings-imgs"
                    />
                  </div>
                }
                name="googleUrl"
                rules={[{ required: true, message: "Please input Url!" }]}
              >
                <TextInput />
              </Form.Item>
              <div className="d-flex w-100">
                <div className="col-sm-3">
                  <Form.Item
                    name="googleUrl"
                    // rules={[{ required: true, message: "Please input Url!" }]}
                  >
                    <CustomCheckbox label="Other Store" />
                  </Form.Item>
                </div>
                <div className="col-sm-9">
                  <Form.Item
                    name="googleUrl"
                    // rules={[{ required: true, message: "Please input Url!" }]}
                  >
                    <TextInput />
                  </Form.Item>
                </div>{" "}
              </div>
              <PrimaryButton
                size="middle"
                type="primary"
                htmlType="submit"
                style={{ border: "none", width: "70px" }}
              >
                Update
              </PrimaryButton>
            </Form>

            {/* <div className="d-flex gap-4">
              <div className="col-sm-3">
                <Checkbox onChange={onChange}>Other Store</Checkbox>
              </div>
              <div className="col-sm-5">
                <TextInput />
              </div>
            </div> */}
          </div>
          <div className="col-sm-12 col-lg-6 app-settings-container app-settings-borderRadius">
            <div>
              <div className="app-settings-heading pt-3 mb-3">
                <h6>Entry of App</h6>
              </div>
              <Divider />
              <div className="d-flex gap-4 mb-4 align-items-baseline">
                <div>
                  <img
                    src={appstorenew}
                    alt="app-store-logo"
                    className="app-settings-imgs-logo"
                  />
                  <img
                    src={googlePlay}
                    alt="app-store-logo"
                    className="app-settings-imgs-logo"
                  />
                </div>
                <h6>{currentAppKey[0]?.name}</h6>
              </div>
              <div className="mb-4">
                <img
                  src={appstorenew}
                  alt="app-store"
                  className="app-settings-imgs"
                />
              </div>
              <div className="mb-2">
                <img
                  src={googlePlay}
                  alt="app-store"
                  className="app-settings-imgs"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default AppSettings;
