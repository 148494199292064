import React, { FC } from "react";
import docIcon from "../../../../../../images/uploadImageCustom.svg";
import { Upload } from "antd";
import CustomTextArea from "../../../../../../components/Inputs/CustomTextArea/CustomTextArea";
import visitWebsiteIcon from "../../.../../../../../../images/WA_button_Icon.svg";
import callPhoneIcon from "../../.../../../../../../images/phone-handset.svg";
import { replacePersonlizedVariable } from "../../Personalization/Personalization";
import Spinner from "components/Spinner/Spinner";
import "./WhatsAppContainer.css";
import TextInput from "components/Inputs/TextInput/TextInput";
interface WhatsAppContainerProps {
  formData?: any;
  uploadImage?: any;
  uploadVideo?: any;
  hideUpload?: boolean;
  imageUrl?: string;
  videoUrl?: string;
  headerTitle?: string;
  description?: any;
  actions?: any;
  headerType?: string;
  variableInfo?: any;
  fileUrl?:{name:string,url:string};
  imageLoading?: {
    imageUrl: { loading: boolean };
    fileUrl: { loading: boolean };

  };
  uploadFile?: any;
}
const WhatsAppContainer: FC<WhatsAppContainerProps> = ({
  // formData,
  uploadImage,
  // uploadVideo,
  imageLoading,
  hideUpload,
  variableInfo,
  imageUrl,
  videoUrl,
  headerTitle,
  description,
  actions,
  headerType,
  // uploadFile,
  fileUrl,

}) => {
  console.log("variable info",variableInfo)
  const direction = "ltr"; // Set your direction here or pass it as a prop if needed
  const ButtonDataRenderer = () => {

    return (
      <div
        className="d-flex justify-content-center"
        style={{ borderTop: "0.7px solid lightgray" }}
      >
        <div className=" p-2 d-flex flex-column  ">
          {Object.keys(actions || {}).map((key, buttonIndex) => (
            <div
              key={buttonIndex}
              className="mx-1 mt-1 d-flex align-items-center "
            >
              {actions[key].actionName && (
                <img
                  src={
                    actions[key].actionType === "visit_website"
                      ? visitWebsiteIcon
                      : callPhoneIcon
                  }
                  style={{ maxHeight: "13px", maxWidth: "16px" }}
                />
              )}
              <span className="ms-2  whatsapp-device-container-button-text ">
                {actions[key].actionName || ""}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="" style={{ background: "white" }}>
      <div className="whatsapp-device-container d-flex flex-column justify-content-between g-0 position-relative">
        {headerType === "media" ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: 123 }}
          >
            <Upload
              showUploadList={false}
              multiple={false}
              beforeUpload={() => false}
              onChange={(value: any) => uploadImage(value, "imageUrl")}
              disabled={hideUpload}
            >
              <div>
                {imageLoading?.imageUrl?.loading ? (
                  <Spinner />
                ) : (
                  <img
                    loading="lazy"
                    src={imageUrl || docIcon}
                    className={`whatsapp-container-image ${
                      imageUrl ? "with-image" : ""
                    }`}
                  />
                )}
              </div>
            </Upload>
           
          </div>
        ) : headerType === "video" ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: 135 }}
          >
            <Upload
              showUploadList={false}
              multiple={false}
              beforeUpload={() => false}
              // onChange={uploadVideo}
              onChange={(value: any) => uploadImage(value, "videoUrl")}
            >
              <video
                src={videoUrl}
                // poster={ docIcon}

                controls
                style={{
                  // height: formData?.video ? 110 : 30,
                  width: videoUrl ? "215px" : 215,
                  // marginTop: 13,
                }}
              />
            </Upload>
          </div>
        ) : headerType === "file" ? (
          <div>
            <div
              className="d-flex align-items-center whats-app-container-file-type justify-content-center flex-column"
              // style={{ height: 135 }}
              // style={{ height: 151 }}

            >
              <Upload
                showUploadList={false}
                multiple={false}
                beforeUpload={() => false}
                accept=".pdf" // Specify accepted file types (only PDF files)

                // onChange={uploadFile}
                onChange={(value: any) => uploadImage(value, "fileUrl")}
                disabled={hideUpload}
              >
                 {imageLoading?.fileUrl?.loading ? (
                  <Spinner />
                ) : (
                <div
                className="d-flex align-items-center justify-content-center"
                  style={{
                    width: 197,
                    height: 110,
                    overflow: "hidden",
                    border: "none",
                  }}
                >
                  {fileUrl?.url?
                   
                  <iframe
                    // title="PDF Viewer"

                    src={fileUrl?.url}
                    className={`whatsapp-container-image-with-pdf`}
                    // src="https://appice-prod-campaign-images.objectstore.e2enetworks.net/1713334851452_gyJ-NQEE4VpDEHXeZunj89Nl.pdf"
                    // style={{
                    //   width: 210,
                    //   height: 110,
                    // }}
                  />:<img
                  loading="lazy"
                  src={docIcon}
                  className={`whatsapp-container-image `}
                />
}
                </div>)}
              </Upload>
              <div className="whats-app-container-file-type-input-container"  >
              <TextInput
                disabled={true}
                className="pt-2 whats-app-container-file-type-input-container-text"
                value={fileUrl?.name}
              />
            </div>
            </div>
            
          </div>
        ) : (
          <div className="pt-1 px-1 whatsapp-device-container-textarea">
            <CustomTextArea
              disabled={true}
              className="pt-2"
              value={headerTitle}
              style={{
                resize: "none",
                lineHeight: 1.1,
                fontSize: 12,
                fontWeight: 600,
                direction,
                background: "transparent",
                color: "black",
              }}
            />
          </div>
        )}
        <div className="m-1 d-flex flex-column whatsapp-device-container-textarea justify-content-between">
          <CustomTextArea
            disabled={true}
            value={replacePersonlizedVariable(description, variableInfo)}
            style={{
              height:
                headerType === "media" || headerType === "video"
                  ? "226px":headerType=="file"?"180px"
                  : "280px",
              resize: "none",
              lineHeight: 1.25,
              fontSize: 12,
              direction,
              background: "transparent",
              color: "black",
            }}
          />
        </div>
        <ButtonDataRenderer />
      </div>
    </div>
  );
};

export default WhatsAppContainer;
