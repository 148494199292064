//this will return top ten events and total
interface AggregatedItem {
  // date: string;
  [key: string]: number; // Define the properties and their types here
}
interface DataObject {
  [key: string]: number;
}

export function getTopTenEvents(data: any,platform:"android" | "ios" | "web" | "all") {
  const osKeys = new Set();
  const allData: any = [];
  data?.map((event: any) => {
    Object.keys(event).map((key) => {
      if (key != "_id") {
        if(platform=="all"){
          osKeys.add(key);
        }
        else{
          osKeys.add(platform)
        }
      }
    });
    osKeys.forEach((value: any) => {
      Object.keys(event[value] ? event[value] : {}).map((key) => {
        if (event[value][key]) allData.push(event[value][key]);
      });
    });
  });
  function concatObjects(objects: any) {
    const result: any = {};

    for (const obj of objects) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (key in result) {
            result[key] += obj[key];
          } else {
            result[key] = obj[key];
          }
        }
      }
    }

    return result;
  }
  function getTopTenKeyValuePairs(obj: any) {
    const sortedPairs = Object.entries(obj).sort(
      (a: any, b: any) => b[1] - a[1]
    );
    const topTenPairs = sortedPairs.slice(1, 11);

    const total = sortedPairs[0];
    const result: any = {};

    for (const [key, value] of topTenPairs) {
      result[key] = value;
    }

    return { result, total };
  }
  const concatenated = concatObjects(allData);
  return getTopTenKeyValuePairs(concatenated);
}

export function selectedPlatform(platform: string, data: any): string[] {
  // Initialize an empty array to store date keys
  let dateKeys: string[] = [];

  // Loop through each array (0, 1, 2, 3, 4, 5, 6, etc.)
  for (const key in data) {
    // Check if the array has a valid platform property
    if (data[key]?.android || data[key]?.ios || data[key]?.web) {
      // Add date keys based on the selected platform
      if (platform === "android") {
        dateKeys.push(...Object.keys(data[key]?.android || {}));
      } else if (platform === "ios") {
        dateKeys.push(...Object.keys(data[key]?.ios || {}));
      } else if (platform === "web") {
        dateKeys.push(...Object.keys(data[key]?.web || {}));
      } else if (platform === "all") {
        // Merge date keys from all platforms
        dateKeys = dateKeys.concat(
          Object.keys(data[key]?.android || {}),
          Object.keys(data[key]?.ios || {}),
          Object.keys(data[key]?.web || {})
        );
      }
    }
  }

  // Convert date keys to a Set to ensure uniqueness
  const uniqueDates = Array.from(new Set(dateKeys));

  // Sort the dates in chronological order
  uniqueDates.sort((a, b) => {
    // Extract year, month, and day from the date strings
    const [yearA, monthA, dayA] = a.split('_').map(Number);
    const [yearB, monthB, dayB] = b.split('_').map(Number);

    // Compare years
    if (yearA !== yearB) {
      return yearA - yearB;
    }

    // Compare months
    if (monthA !== monthB) {
      return monthA - monthB;
    }

    // Compare days
    return dayA - dayB;
  });

  return uniqueDates;
}


export function aggregateDataByPlatform(
  platform: string,
  statsData: { [key: string]: any }, // Define the type of statsData
  allDates: string[],
  excludedKeys: string[]
): DataObject[] {
  const aggregatedData: DataObject[] = [];

  for (const key in statsData) {
    if (Object.prototype.hasOwnProperty.call(statsData, key)) {
      if (platform === "android") {
        allDates?.forEach((date: any) => {
          const androidData =
            (statsData[key]?.android && statsData[key]?.android[date]) || {};

          // Check if androidData has any properties before pushing
          if (Object.keys(androidData).length > 0) {
            aggregatedData.push({
              date,
              ...androidData,
            });
          }
        });
      } else if (platform === "ios") {
        allDates.forEach((date) => {
          const iosData = (statsData[key]?.ios && statsData[key]?.ios[date]) || {};

          // Check if iosData has any properties before pushing
          if (Object.keys(iosData).length > 0) {
            aggregatedData.push({
              date,
              ...iosData,
            });
          }
        });
      } else if (platform === "web") {
        allDates.forEach((date) => {
          const webData = (statsData[key]?.web && statsData[key].web[date]) || {};

          // Check if webData has any properties before pushing
          if (Object.keys(webData).length > 0) {
            aggregatedData.push({
              date,
              ...webData,
            });
          }
        });
      } else if (platform === "all") {
        allDates?.forEach((date: any) => {
          const androidData =
            (statsData[key]?.android && statsData[key]?.android[date]) || {};
          const iosData =
            (statsData[key]?.ios && statsData[key]?.ios[date]) || {};
          const webData = (statsData[key]?.web && statsData[key].web[date]) || {};

          const mergedData: DataObject = {};

          // Merge Android data
          for (const property in androidData) {
            if (
              property !== "total" &&
              !excludedKeys.includes(property) // Check if the property is not in the excludedKeys array
            ) {
              if (mergedData[property]) {
                mergedData[property] += androidData[property];
              } else {
                mergedData[property] = androidData[property];
              }
            }
          }

          // Merge iOS data
          for (const property in iosData) {
            if (
              property !== "total" &&
              !excludedKeys.includes(property) // Check if the property is not in the excludedKeys array
            ) {
              if (mergedData[property]) {
                mergedData[property] += iosData[property];
              } else {
                mergedData[property] = iosData[property];
              }
            }
          }

          // Merge Web data
          for (const property in webData) {
            if (
              property !== "total" &&
              !excludedKeys.includes(property) // Check if the property is not in the excludedKeys array
            ) {
              if (mergedData[property]) {
                mergedData[property] += webData[property];
              } else {
                mergedData[property] = webData[property];
              }
            }
          }

          // Check if mergedData has any properties before pushing
          if (Object.keys(mergedData).length > 0) {
            aggregatedData.push({
              date,
              ...mergedData,
            });
          }
        });
      }
    }
  }

  return aggregatedData;
}

interface DataItem {
  date: string;
  [key: string]: any;
}
export const generateAndDownloadCSV = (dataRows: DataItem[]) => {
  const csvRows = ["Date,Key,Count"];
  dataRows.forEach((item) => {
    const date = item.date;
    for (const key in item) {
      if (key !== "date") {
        const count = item[key];
        csvRows.push(`${date},${key},${count}`);
      }
    }
  });

  const csvData = csvRows.join("\n");

  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "data.csv";
  a.click();
  URL.revokeObjectURL(url);
};

function getRandomBoolean() {
  return Math.random() < 0.5; // 50% chance of true or false
}

export const filterKeysAndAddEnabled = (data: any, excludedKeys: string[]) => {
  const keysWithEnabled: Record<string, boolean> = {}; // Type annotation for keysWithEnabled

  data.forEach((item: any) => {
    for (const key in item) {
      if (!excludedKeys.includes(key) && !keysWithEnabled[key]) {
        keysWithEnabled[key] = getRandomBoolean();
      }
    }
  });

  return keysWithEnabled;
};
export const generateSeriesData=(statsData:any, platformVersion:string, excludedKeys: string[]) =>{
  return Array.isArray(statsData) && statsData.length > 0
    ? Object.entries(
        statsData.reduce((acc, data) => {
          const platform =
            platformVersion === "all" ? ["android", "ios", "web"] : [platformVersion];
          platform.forEach((platform) => {
            if (data[platform]) {
              Object.keys(data[platform]).forEach((category) => {
                Object.keys(data[platform][category] || {}).forEach((metric) => {
                  if (!excludedKeys.includes(metric)) {
                    if (!acc[metric]) {
                      acc[metric] = {
                        name: metric,
                        data: [],
                        total: 0,
                      };
                    }
                    acc[metric].data.push(data[platform][category][metric]);
                    acc[metric].total += data[platform][category][metric];
                  }
                });
              });
            }
          });
          return acc;
        }, {})
      ).map(([_, series]) => series)
    : [];
}
export const extractCategories=(statsData:any, platformVersion:string, excludedKeys: string[]) =>{

  return Array.isArray(statsData) && statsData.length > 0
    ? statsData.flatMap((data) => {
        const platform =
          platformVersion === "all" ? ["android", "ios", "web"] : [platformVersion];
        return platform.flatMap((platform) => {
          if (data[platform]) {
            return Object.keys(data[platform]).filter(
              (category) => !excludedKeys.includes(category)
            );
          }
          return [];
        });
      })
    : [];
}
/**
 * Generates a list of unique journey types from an array of events.
 * 
 * @param  events - The array of events to extract journey types from.
 * @returns {{label: string, value: string}[]} - An array of objects containing 
 *                                               unique journey labels and values.
 */
export const getJourneyTypeList = (events: any[]) => {
  const journeySet = new Set<string>();
  const journeys: { label: string, value: string }[] = [];

  events?.forEach((event) => {
    if (event.journey && !journeySet.has(event.journey)) {
      journeySet.add(event.journey);
      journeys.push({ label: event.journey, value: event.journey });
    }
  });

  return journeys;
};



