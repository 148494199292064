import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import centralApi from "../../../services/centralApi";
import { STATUS } from "../../constant";
const initialState:{data:any,status:any}={data:[],status:STATUS.IDLE}
export const fetchAudienceSegments = createAsyncThunk(
  "audience/fetchAudienceSegments",
  async (params:any) => {
    return centralApi(
      "GET",
      "o/aud/getAudienceSegments",
      null,
      params
    ).then(({result}) => { 
      return result
    });
  }
);
const AudienceSegmentsSlice = createSlice({
  name: "AudienceSegments",
  initialState,
  reducers: {
    setAudienceSegments(state, action) {
      state.data=action.payload
    },

  },
  extraReducers: (builder) => {
    builder.addCase(fetchAudienceSegments.pending, (state) => {
      state.status = STATUS.LOADING;
    });
    builder.addCase(fetchAudienceSegments.fulfilled, (state,action) => {
      state.status = STATUS.IDLE;
      state.data=action.payload
    });
    builder.addCase(fetchAudienceSegments.rejected, (state) => {
      state.status = STATUS.ERROR;
    });
  },
});
export const {setAudienceSegments}=AudienceSegmentsSlice.actions
export default AudienceSegmentsSlice.reducer
