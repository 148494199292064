import React from "react";
import {
  MinusCircleTwoTone,
  PlusCircleTwoTone,
  UploadOutlined,
} from "@ant-design/icons";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { v4 as uuid } from "uuid";
import TextInput from "../../../../../components/Inputs/TextInput/TextInput";
import {  Col, Upload } from "antd";
import Selector from "../../../../../components/Selector/Selector";
import { JsonTree } from "./JsonTree";
import TreeSelector from "components/Selector/TreeSelector/TreeSelector";
import "./CustomData.css";
import centralApi from "services/centralApi";
import { useSelector } from "react-redux";
import { CustomDataEnum } from "utils/enums";
import useMessage from "hooks/useMessage";
import { t } from "i18next";
export const CustomData = ({ customDataState, setCustomDataState }: any) => {
  const { appId } = useSelector((state: any) => state.activeApp);
  const { NUMBER, IMAGE, TREE, BOOLEAN, DEVICE } = CustomDataEnum;

  const { showSuccess, showError } = useMessage();

  const handleAddClick = () => {
    setCustomDataState((prevState: any) => ({
      [uuid()]: { key: "", value: "", type: "string" },
      ...prevState,
    }));
  };

  const handleRemoveClick = (idToRemove: string) => {
    setCustomDataState((prevState: any) => {
      const newState = { ...prevState };
      delete newState[idToRemove]; // Remove the row corresponding to the provided id
      return newState;
    });
  };

  const handleTypeChange = (value: string, id: string) => {

    setCustomDataState((prevState: any) => {
      const newData = { ...prevState };
      newData[id] = { ...newData[id], type: value };
      return newData;
    });
  };
  const handleImageUpload = async (value: any, id: number) => {

    // let newFileList = [value.fileList];
    // const newFileList = [...value.fileList];

    try {
      // setFileList(...value.fileList);

      const formData = new FormData();
      formData.append("image", value.file);
      formData.append("image_id", value.file.name);
      formData.append("appid", appId);
      const res = await centralApi(
        "POST",
        "/i/campaignFiles/campaignimages",
        formData,
        null
      );
      setCustomDataState((prevState: any) => ({
        ...prevState,
        [id]: { ...prevState[id], value: res.baseURL + res.url },
      }));
      showSuccess(t("sucessfullyUploadedLabel"));
    } catch (error) {
      showError((error as Error).message);
    }
  };
  const valueTypes = [
    {
      value: "boolean",
      label: t("booleanLabel"),
      hasChildren: false,

      // checkable: false,
    },
    {
      value: "string",
      label: t("stringLabel"),
      hasChildren: false,

      // checkable: false,
    },
    {
      value: "number",
      label: t("numberLabel"),
      hasChildren: false,

      // checkable: false,
    },
    { value: "json", label: t("objectLabel"), hasChildren: false },
    {
      label: t("imageLabel"),
      value: "img",
      checkable: false,
      hasChildren: true,
      selectable: false, // Set selectable to false for the "Image" node

      children: [
        {
          label: t("uploadFromDeviceLabel"),
          value: "device",
          hasChildren: false,
        },
        {
          label: t("uploadFromWebLabel"),
          value: "image",
          hasChildren: false,
        },
      ],
    },
  ];
  const updateCustomDataState = (id: number, jsonData: any) => {
    // Transform jsonData into the key value format without key value names
    const transformedData: any = {};
    jsonData.forEach((item: any) => {
      transformedData[item.key] = item.value;
    });
    // Update customDataState with the provided key-value pair under the given ID
    setCustomDataState((prevState: any) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        value: encodeURIComponent(JSON.stringify(transformedData)), // Assign transformedData to value
      },
    }));
  };
  // Function to decode JSON value if it's not empty
  const decodeJsonValue = (value: string) => {
    if (value !== "" && /^%7B.*%7D$/.test(value)) {
      const decodedData = JSON.parse(decodeURIComponent(value));
      return Object.entries(decodedData)?.map(([key, value]) => ({
        key,
        value,
      })); //making it in  form of key value
    } else {
      return "";
    }
  };
  //rendering 3rd input based on selected type
  const renderThirdInput = (type: string, id: number) => {
    switch (type) {
      case BOOLEAN:
        return (
          <Selector
            value={customDataState[id]?.value}
            className="custom-data-common-width"
            onChange={(e: any) => {
              setCustomDataState((prevState: any) => ({
                ...prevState,
                [id]: { ...prevState[id], value: e },
              }));
            }}
            options={[
              { label: "True", value: "true" },
              { label: "False", value: "false" },
            ]}
          ></Selector>
        );
      case NUMBER:
        return (
          <TextInput
            type="number"
            className="custom-data-common-width"
            value={customDataState[id]?.value}
            onChange={(e: any) => {
              setCustomDataState((prevState: any) => ({
                ...prevState,
                [id]: { ...prevState[id], value: e.target.value },
              }));
            }}
            placeholder={t("valueLabel")}
          />
        );
      case TREE:
        return (
          <>
            <div>
              <JsonTree
                initialJsonTreeData={decodeJsonValue(
                  customDataState[id]?.value
                )}
                updateCustomDataState={(treeData: any) =>
                  updateCustomDataState(id, treeData)
                }
              />
            </div>
          </>
        );
      case IMAGE:
        return (
          <>
            <TextInput
              type="text"
              className="custom-data-common-width"
              value={customDataState[id]?.value}
              onChange={(e: any) => {
                setCustomDataState((prevState: any) => ({
                  ...prevState,
                  [id]: { ...prevState[id], value: e.target.value },
                }));
              }}
              placeholder={t("enterUrlLabels")}
            />
          </>
        );
      case DEVICE:
        return (
          <div className="custom-data-upload-width">
            <Upload
              // fileList={fileList}
              showUploadList={{
                showDownloadIcon: false,
                showPreviewIcon: false,
                showRemoveIcon: false,
              }}
              maxCount={1}
              multiple={false}
              beforeUpload={() => false}
              onChange={(value: any) => handleImageUpload(value, id)}
            >
              <PrimaryButton className="custom-data-common-width">
                <UploadOutlined className="me-2" /> {t("uploadLabel")}
              </PrimaryButton>
            </Upload>
            
          </div>
        );
      default:
        return (
          <TextInput
            className="custom-data-common-width"
            value={customDataState[id]?.value}
            onChange={(e: any) => {
              setCustomDataState((prevState: any) => ({
                ...prevState,
                [id]: { ...prevState[id], value: e.target.value },
              }));
            }}
  placeholder={t("valueLabel")}
          />
        );
    }
  };

  return (
    <>
      {Object.keys(customDataState)
        ?.reverse()
        .map((id: any, index: number) => {
          return (
            <Col key={id} className="d-flex  mt-1 gap-1">
              <TextInput
                className="custom-data-key-width"
                value={customDataState[id].key}
                onChange={(e: any) => {
                  setCustomDataState((prevState: any) => ({
                    ...prevState,
                    [id]: { ...prevState[id], key: e.target.value },
                  }));
                }}
                placeholder={t("keyLabel")}
              />
              <TreeSelector
                className="custom-data-tree-width"
                value={customDataState[id]?.type}
                onChange={(value: string) => handleTypeChange(value, id)}
                treeData={valueTypes}
                height={800}
                placeholder={t("typeLabel")}
              />

              {renderThirdInput(customDataState[id].type, id)}

              {Object.keys(customDataState)?.length - 1 == index ? (
                <PrimaryButton style={{ width: 40 }} onClick={handleAddClick}>
                  <PlusCircleTwoTone />
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  className="custom-data-button-width"
                  onClick={() => handleRemoveClick(id)}
                >
                  <MinusCircleTwoTone />
                </PrimaryButton>
              )}
            </Col>
          );
        })}
    </>
  );
};
