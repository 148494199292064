import { Form } from "antd";
import { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import "./FrequencyWindow.css";
import Selector from "components/Selector/Selector";
import { generateNumbersInRange } from "helperFunctions/number";
import { t } from "i18next";
import { audienceItems } from "helperFunctions/audience";


type frequencyType = {
  f_operand: string | undefined;
  value: number | undefined;
};
type FrequencyWindowPropType = {
  frequency?: frequencyType;
  setFrequency: (data: frequencyType | undefined) => void;
};
function FrequencyWindow({ frequency, setFrequency }: FrequencyWindowPropType) {
  const [fieldData, setFieldData] = useState<frequencyType>({
    f_operand: frequency?.f_operand,
    value: frequency?.value,
  });
  const [show, setShow] = useState(false);
  const [valid, setValid] = useState(false);
  const handleInputChange = (data: any) => {
    setFieldData((prevState) => {
      const modifiedData={ ...prevState, ...data }
      if (modifiedData.f_operand && modifiedData.value) {
        setFrequency(modifiedData);
      }
      return modifiedData;
    });
    
  };
  useEffect(() => {
    if (fieldData.f_operand && fieldData.value) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [fieldData]);
  return (
    <div className="frequency-window-parent-container">
      {show || !valid ? (
        <div
          className="d-flex gap-2"
          onMouseLeave={() => {
            setShow(false);
          }}
        >
          <Form.Item
            className="frequency-inputs"
            initialValue={fieldData.f_operand}
          >
            <Selector
              value={fieldData.f_operand}
              onChange={(value) =>
                handleInputChange({ f_operand: value, value: undefined })
              }
              options={audienceItems.frequencyOperands}
              placeholder={t("selectLabel")}
            />
          </Form.Item>
          <Form.Item
            className="frequency-inputs w-25 m-0"
            initialValue={fieldData.value}
          >
            <Selector
              value={fieldData.value}
              onChange={(value) => {
                handleInputChange({ value: value });
              }}
              options={generateNumbersInRange(1, 360).map((number) => ({
                label: number,
                value: number,
              }))}
              placeholder={t("selectLabel")}
            />
          </Form.Item>
        </div>
      ) : (
        <div className="d-flex align-items-center gap-2">
          <div
            className="frequency-result-window d-flex gap-2 text-overflow-ellipsis"
            onClick={() => {
              setShow(true);
              setFieldData((prevState) => ({ ...prevState, value: undefined }));
            }}
          >
            <span>{fieldData?.f_operand}</span> <span>{fieldData?.value}</span>{" "}
            {fieldData?.value && (
              <span className="aud-freq-color-disabled">
                {fieldData.value == 1 ? "time" : "times"}
              </span>
            )}
          </div>
          <CloseOutlined
            onClick={() => {
              setFrequency(undefined);
              setFieldData({
                f_operand: undefined,
                value: undefined,
              });
            }}
          />
        </div>
      )}
    </div>
  );
}

export default FrequencyWindow;
