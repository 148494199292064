import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton"
import { Form } from "antd"
import { useSelector } from "react-redux"
import React, { useState, useEffect } from "react"
import centralApi from "services/centralApi"
import { getToken } from "services/getToken"
import ShowMessage from "components/ShowMessage/ShowMessage"
import Spinner from "components/Spinner/Spinner"
import { t } from "i18next"
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox"
import "./ConfigureColumns.css"
import { API_ENDPOINTS } from "utils/constants"
interface ConfigureColumnsProps {
  currentAppKey: any
  updateCurrentAppKey: () => void
}

interface RootState {
  loginUser: {
    data: {
      api_key: string
    }
  }
  activeApp: {
    appId: string
  }
}

const ConfigureColumns: React.FC<ConfigureColumnsProps> = ({
  currentAppKey,
  updateCurrentAppKey,
}) => {
  const allCampaignColumns = currentAppKey[0]?.campaign_columns
  const allowedItems = currentAppKey[0]?.allowedItems
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [campaignColumns, setCampaignColumns] = useState<any>({});

  useEffect(() => {
    if (allCampaignColumns) {
      const initialCampaignColumns = {
        receivedTo: allCampaignColumns.receivedTo || false,
        clickedTo: allCampaignColumns.clickedTo || false,
        impressionRate: allCampaignColumns.impressionRate || false,
        ctr: allCampaignColumns.ctr || false,
        conversion: allCampaignColumns.conversion || false,
        // Add more keys and initial values as needed
      };
      setCampaignColumns(initialCampaignColumns);
    }
  }, [allCampaignColumns,currentAppKey]);
  const loginUser = useSelector(
    (state: RootState) => state.loginUser.data.api_key
  )
  const activeApp = useSelector((state: RootState) => state.activeApp.appId)
  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  })
  const appId = activeApp
  const api_key = loginUser
  const onFormSubmit =async () => {
    const payload = {
      args: JSON.stringify({
        app_id: appId,
        selectedColumns: {
          ...campaignColumns,
        },
        allowedItems
      }),
      api_key: api_key,
    }
    await saveColumnSettings(payload)
    await updateCurrentAppKey()
    await getToken(appId, api_key)
  }
  async function saveColumnSettings(formData: any) {
    const url: string = API_ENDPOINTS.SAVE_CAMPAIGN_SETTINGS_URL

    try {
      setLoading(true)

      await centralApi("POST", url, formData, null)
      setShowMessage({
        type: "success",
        message: "Successfully Saved",
        show: true,
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setShowMessage({
        type: "error",
        message: "Something went wrong",
        show: true,
      })
    }
    hideMessage()
  }
  const hideMessage = () => {
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false })
    }, 2000)
  }

  useEffect(() => {
    getToken(appId, api_key)
  }, [])
  const handleChange = (columnKey: string, newValue: boolean) => {
    setCampaignColumns((prevData: any) => ({
      ...prevData,
      [columnKey]: newValue,
    }))
  }
  return (
    <div className="configure-columns-setting-container">
      <div className=" mb-4  ">
        <h6 className="configure-columns-setting-container-heading">
          {t("campaignColumnsLabel")}
        </h6>
        <p className="configure-columns-setting-form-headings ">
        {t("appSettingColumnsAnalyticsDes")}

                </p>
        
        {/* <Divider className="configure-columns-setting-custom-divider" /> */}
        <div>
          <div className="  mt-2 align-items-center configure-columns-setting-form ">
            <div className="mb-4">
              <div className=" col-sm-10 mt-2 align-items-center configure-columns-setting-form ">
               
                {showMessage.show && (
                  <ShowMessage
                    type={showMessage.type}
                    content={showMessage.message}
                  />
                )}
                {loading ? (
                  <Spinner />
                ) : (
                  <Form
                    form={form}
                    // initialValues={initialValues}
                    layout="horizontal"
                    onFinish={onFormSubmit}
                  >
                    
                    <Form.Item
                      name="receivedTo"
                      valuePropName="checked"
                      key="recievedTo"
                    >
                      <CustomCheckbox
                        checked={campaignColumns?.receivedTo}
                        onChange={(e: any) =>
                          handleChange("receivedTo", e.target.checked)
                        }
                        label="Recieved"
                      />
                      <span className="configure-columns-setting-form-headings">
                        Display the number of received campaigns.
                      </span>
                    </Form.Item>

                    <Form.Item name={"clickedTo"} valuePropName="checked">
                      <CustomCheckbox
                        label="Clicked"
                        checked={campaignColumns?.clickedTo}

                        onChange={(e: any) =>
                          handleChange("clickedTo", e.target.checked)
                        }
                      />
                      <span className="configure-columns-setting-form-headings">
                        Display the number of clicked campaigns.
                      </span>
                    </Form.Item>
                    <Form.Item name={"impressionRate"} valuePropName="checked">
                      <CustomCheckbox
                        label="Impression rate"
                        checked={campaignColumns?.impressionRate}

                        onChange={(e: any) =>
                          handleChange("impressionRate", e.target.checked)
                        }
                      />
                      <span className="configure-columns-setting-form-headings">
                        Display the impression rate of campaigns.
                        configure-columns
  </span>
                    </Form.Item>
                    <Form.Item name={"ctr"} valuePropName="checked">
                      <CustomCheckbox
                                              checked={campaignColumns?.ctr}

                        label="CTR (Click-through-rate)"
                        // checked={featureData[featureKey]}
                        onChange={(e: any) =>
                          handleChange("ctr", e.target.checked)
                        }
                        // Make sure e.target.checked is being used correctly
                      />
                      <span className="configure-columns-setting-form-headings">
                        Display the click-through rate of campaigns.
                      </span>
                    </Form.Item>
                    <Form.Item name={"conversion"} valuePropName="checked">
                      <CustomCheckbox
                                              checked={campaignColumns?.conversion}

                        label="Conversion"
                        // checked={featureData[featureKey]}
                        onChange={(e: any) =>
                          handleChange("conversion", e.target.checked)
                        }
                        // Make sure e.target.checked is being used correctly
                      />
                      <span className="configure-columns-setting-form-headings">
                        Display the conversion rate of campaigns.
                      </span>
                    </Form.Item>
                    <div className="">
                      <PrimaryButton
                        size="middle"
                        type="primary"
                        htmlType="submit"
                        style={{ border: "none", width: "70px" }}
                      >
                        {t("saveLabel")}
                      </PrimaryButton>
                    </div>
                  </Form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ConfigureColumns
