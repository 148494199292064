import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../../constant";
import centralApi from "../../../services/centralApi";
export const fetchEventList = createAsyncThunk(
  "event/fetchEventList",
  async ({api_key, app_id}:any) => {
    const params = {
      api_key,
      app_id,
    };

    return centralApi(
      "GET",
      "o/metrices/getEventList",
      null,
      params
    ).then(({data}) => { 
      return data || [];
    });
  }
);
const initialState:any= {
  data: [],
  status: STATUS.IDLE,
}
const eventListSlice = createSlice({
  name: "eventList",
 initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchEventList.pending, (state) => {
      state.status = STATUS.LOADING;
    });
    builder.addCase(fetchEventList.fulfilled, (state,action) => {
      state.status = STATUS.IDLE;
      state.data=action.payload
    });
    builder.addCase(fetchEventList.rejected, (state) => {
      state.status = STATUS.ERROR;
      state.data=[]
    });
  },
  reducers: {
  },
});
export default eventListSlice.reducer;

