"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageSettings = exports.AzureStorageConfig = exports.MinioConfig = exports.BaseConfig = void 0;
const typescript_json_serializer_1 = require("typescript-json-serializer");
const base_1 = require("../common/base");
// Base configuration class for shared storage properties
class BaseConfig {
    constructor(endpoint, bucketName) {
        this.endpoint = endpoint;
        this.bucketName = bucketName;
    }
}
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], BaseConfig.prototype, "endpoint", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], BaseConfig.prototype, "bucketName", void 0);
exports.BaseConfig = BaseConfig;
// Minio configuration class for storage
let MinioConfig = class MinioConfig extends BaseConfig {
    constructor(minioEndpoint, port, useSSL, accessKey, secretKey) {
        super();
        this.minioEndpoint = minioEndpoint;
        this.port = port;
        this.useSSL = useSSL;
        this.accessKey = accessKey;
        this.secretKey = secretKey;
    }
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], MinioConfig.prototype, "minioEndpoint", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], MinioConfig.prototype, "port", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Boolean)
], MinioConfig.prototype, "useSSL", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], MinioConfig.prototype, "accessKey", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], MinioConfig.prototype, "secretKey", void 0);
MinioConfig = __decorate([
    (0, typescript_json_serializer_1.JsonObject)(),
    __metadata("design:paramtypes", [String, Number, Boolean, String, String])
], MinioConfig);
exports.MinioConfig = MinioConfig;
// Azure Storage configuration class for storage
let AzureStorageConfig = class AzureStorageConfig extends BaseConfig {
    constructor(azureApiBlobSASURL, azureApiStorageSASToken) {
        super();
        this.azureApiBlobSASURL = azureApiBlobSASURL;
        this.azureApiStorageSASToken = azureApiStorageSASToken;
    }
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AzureStorageConfig.prototype, "azureApiBlobSASURL", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AzureStorageConfig.prototype, "azureApiStorageSASToken", void 0);
AzureStorageConfig = __decorate([
    (0, typescript_json_serializer_1.JsonObject)(),
    __metadata("design:paramtypes", [String, String])
], AzureStorageConfig);
exports.AzureStorageConfig = AzureStorageConfig;
// Storage settings class including Minio and Azure configurations
let StorageSettings = class StorageSettings extends base_1.BaseModel {
    constructor(minioConfig, azureStorageConfig) {
        super();
        this.minioConfig = minioConfig;
        this.azureStorageConfig = azureStorageConfig;
    }
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ type: MinioConfig }),
    __metadata("design:type", MinioConfig)
], StorageSettings.prototype, "minioConfig", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ type: AzureStorageConfig }),
    __metadata("design:type", AzureStorageConfig)
], StorageSettings.prototype, "azureStorageConfig", void 0);
StorageSettings = __decorate([
    (0, typescript_json_serializer_1.JsonObject)(),
    __metadata("design:paramtypes", [MinioConfig,
        AzureStorageConfig])
], StorageSettings);
exports.StorageSettings = StorageSettings;
