import { ResponsiveHeatMap } from "@nivo/heatmap";
import { data } from "./data";
import NoData from "../../NoData/NoData";
type propTypes = {
  schema?:
    | "oranges"
    | "cool"
    | "purple_blue_green"
    | "rainbow"
    | "plasma"
    | "blues"
    | "red_yellow_green";
  showUpperLegend?: boolean;
  showLowerLegend?: boolean;
  minValue?: number;
  maxValue?: number;
  heatMapData?: any;
  xInnerPadding?: number;
  yInnerPadding?: number;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
};
export const Heatmap = ({
  schema,
  showUpperLegend,
  showLowerLegend,
  minValue,
  maxValue,
  heatMapData,
  xInnerPadding,
  yInnerPadding,
  top,
  right,
  bottom,
  left,
}: propTypes) => {
  const formatLabel = (label: any) =>
    label.charAt(0).toUpperCase() + label.slice(1);

    return heatMapData && heatMapData.length == 0 || null ? (
      <NoData />
  ) : (
    <>
      <ResponsiveHeatMap
        data={heatMapData ? heatMapData : data}
        margin={{
          top: top ? top : 60,
          right: right ? right : 90,
          bottom: bottom ? bottom : 60,
          left: left ? left : 90,
        }}
        // valueFormat=" >-.0s"
        valueFormat=".0f"
        axisTop={showUpperLegend ? {} : null}
        // forceSquare={true}
        // xOuterPadding={0.6}
        xInnerPadding={xInnerPadding ? xInnerPadding : 0.005}
        yInnerPadding={yInnerPadding ? yInnerPadding : 0.02}
        axisRight={null}
        axisBottom={
          showLowerLegend
            ? {
                tickSize: 0,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: "middle",
                legendOffset: -72,
                format: formatLabel,
              }
            : null
        }
        axisLeft={{
          tickSize: 0,
          tickPadding: 14,
          tickRotation: 0,
          legendPosition: "middle",
          legendOffset: -72,
          format: formatLabel,
        }}
        colors={{
          type: "diverging",
          scheme: schema,

          divergeAt: 0.2,

          // minValue: minValue,
          // maxValue: maxValue,
        }}
        emptyColor="#555555"
        borderColor="white"
        enableLabels={true}
        // labelTextColor="black"
        legends={[]}
      />{" "}
    </>
  );
};
Heatmap.defaultProps = {
  data:data,
  schema: "blues",
  showUpperLegend: false,
  showLowerLegend: true,
  minValue: -100000,
  maxValue: 100000,
};
