import React from "react";
import { Radio } from "antd";

interface RadioButtonProps {
  name: string;

  value?: any;
  onChange?: (e: any) => void;
  disabled?: boolean;
}

const RadioButton: React.FC<RadioButtonProps> = ({ name, ...props }) => (
  <Radio {...props}>{name}</Radio>
);

export default RadioButton;
