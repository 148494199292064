import { Tree } from "antd";
import type { DataNode, TreeProps } from "antd/es/tree";
import { useEffect, useState } from "react";
import { Typography } from "antd";
import "./DisplayPrefrence.css";
import centralApi from "../../services/centralApi";
import PrimaryButton from "../../components/Buttons/PrimaryButton/PrimaryButton";
import Spinner from "../../components/Spinner/Spinner";
import { getToken } from "../../services/getToken";
import { useSelector } from "react-redux";
import React from "react";
import useMessage from "../../hooks/useMessage";
import { t } from "i18next";
import { API_ENDPOINTS } from "utils/constants";

const { Paragraph } = Typography;
const defaultData: DataNode[] = [];

const DisplayPrefrence: React.FC = () => {
  const { showError, showSuccess } = useMessage();

  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;
  const [displayPreferenceData, setDisplayPreferenceData] =
    useState<DataNode[]>(defaultData);
  const [expandedKeys] = useState(["0-0", "0-0-0", "0-0-0-0"]);
  const [loading, setLoading] = useState(false);

  type ApiData = {
    id: number;
    name: string;
    nodes: ApiData[]; // Array of objects with the same structure
    p?: number; // Optional property in the nodes
  };

  function transformData(apiData: ApiData[]): DataNode[] {
    return apiData.map((item) => {
      const dataNode: DataNode = {
        key: item.id.toString(),
        title: item.name,
        children: item.nodes && item.nodes.length ? transformData(item.nodes) : [], // check children is always an array
      };

      // Include the 'p' property if it exists
      if (item.p !== undefined) {
        (dataNode as any).p = item.p; //new variable p added to child nodes
      }

      return dataNode;
    });
  }

  async function getDisplayPreferenceData() {
    try {
      setLoading(true);
      const response = await centralApi(
        "GET",
        API_ENDPOINTS.GET_DISPLAY_PREEFERENCE,
        "",
        null
      );

      const transformedData = transformData(response?.data); // Function to transform the API response into DataNode array
      setDisplayPreferenceData(transformedData);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError(t("somethingWrongLabel"));
    }
  }
  useEffect(() => {
    getDisplayPreferenceData();
  }, []);

  const onDrop: TreeProps["onDrop"] = (info) => {
    const dropKey = info.node.key; // Key of the node where the item is dropped
    const dragKey = info.dragNode.key; // Key of the node being dragged
    const dropPos = info.node.pos.split("-"); // Position of the drop node in the tree
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]); // Calculate drop position relative to the drop node

    // Function to traverse the tree structure
    const traverseTree = (
      data: DataNode[],
      key: React.Key,
      callback: (node: DataNode, i: number, data: DataNode[]) => void
    ) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data); // If key matches, execute callback
        }
        if (data[i].children) {
          traverseTree(data[i].children!, key, callback); // Traverse children if they exist
        }
      }
    };

    const data = [...displayPreferenceData]; // Copy the current tree data

    // Find the dragged object and remove it from its current position
    let dragObj: DataNode;
    traverseTree(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1); // Remove the dragged item from its original position
      dragObj = item; // Store the dragged item
    });

    if (!info.dropToGap) {
      // If dropped on a node (not in the gap)
      traverseTree(data, dropKey, (item) => {
        item.children = item.children || []; // Ensure the children array exists
        item.children.unshift(dragObj); // Add the dragged item as the first child
      });
    } else if (
      ((info.node as any).props.children || []).length > 0 && // If the node has children
      (info.node as any).props.expanded && // If the node is expanded
      dropPosition === 1 // If dropped at the bottom gap of the node
    ) {
      traverseTree(data, dropKey, (item) => {
        item.children = item.children || []; // Ensure the children array exists
        item.children.unshift(dragObj); // Add the dragged item as the first child
      });
    } else {
      // If dropped between nodes
      let ar: DataNode[] = [];
      let i: number;
      traverseTree(data, dropKey, (_item, index, arr) => {
        ar = arr; // Reference to the array containing the drop node
        i = index; // Index of the drop node in the array
      });
      if (dropPosition === -1) {
        ar.splice(i!, 0, dragObj!); // Insert the dragged item before the drop node
      } else {
        ar.splice(i! + 1, 0, dragObj!); // Insert the dragged item after the drop node
      }
    }
    setDisplayPreferenceData(data); // Update the tree data state
  };

  function saveTransformedData(originalData: any) {
    function transformItem(item: any) {
      const transformedItem: any = {
        id: parseInt(item.key),
        name: item.title,
        nodes: item.children ? item.children.map(transformItem) : [],
      };

      // Include the 'p' property if it exists
      if (item.p !== undefined) {
        transformedItem.p = item.p;
      }

      return transformedItem;
    }

    const transformedData = originalData.map(transformItem);
    return transformedData;
  }

  async function saveDisplayPreferenceData(formData: any) {
    const payload = {
      prefData: formData,
    };

    try {
      setLoading(true);
      await centralApi(
        "PATCH",
        API_ENDPOINTS.UPDATE_DISPLAY_PREFERENCE,
        payload,
        null
      );
      showSuccess(t("sucessfullySavedLabel"));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError(t("somethingWrongLabel"));
    }
  }

  useEffect(() => {
    getToken(appId, api_key);
  }, []);

  const handleSavePreference = () => {
    const prefData = saveTransformedData(displayPreferenceData);
    saveDisplayPreferenceData(prefData);
  };

  return (
    <div className="display-preference-container">
      <div className="display-preference-header">
        <h4>Display Preference</h4>
        <Paragraph>
          Drag and drop the navigation links below to rearrange the display.
          Press save once done and refresh the site to see the navigation.
        </Paragraph>
      </div>

      {loading ? (
        <Spinner />
      ) : (
        <div className="display-preference-body">
          <Tree
            className="draggable-tree"
            defaultExpandedKeys={expandedKeys}
            draggable
            blockNode
            onDrop={onDrop}
            treeData={displayPreferenceData}
          />
          <PrimaryButton
            size="middle"
            type="primary"
            className="display-preference-button"
            onClick={handleSavePreference}
          >
            Save
          </PrimaryButton>
        </div>
      )}
    </div>
  );
};

export default DisplayPrefrence;
