import { FC, useEffect, useState } from "react";
import TemplateLayout from "../../../layout/TemplateLayout/TemplateLayout";
import { Col, Form, Row, Space } from "antd";
import FormItem from "antd/es/form/FormItem";
import Selector from "components/Selector/Selector";
import TextInput from "components/Inputs/TextInput/TextInput";
import SegmentedCustom from "components/SegmentedCustom/SegmentedCustom";
import CustomTextArea from "components/Inputs/CustomTextArea/CustomTextArea";
import CampaignDevice from "components/CampaignDevice/CampaignDevice";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import WebPushContainer from "../../NotificationContainer/WebPushContainer/WebPushContainer";
import { useDispatch, useSelector } from "react-redux";
import centralApi from "services/centralApi";
import { t } from "i18next";
import { fetchTemplatesList } from "redux/features/templates_/templatesByTypeSlice";
import {
  fetchTemplateById,
  setSelectedTemplate,
} from "redux/features/templates_/templateByIdSlice";
import { v4 as uuid } from "uuid";

import { setCampaignData } from "redux/features/campaign/campaignDataSlice";
import { validateImageDimensions } from "helperFunctions/file";
import useMessage from "hooks/useMessage";
import { MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { setCurrentStep } from "redux/features/campaign/campaignCreateCurrentStepSlice";
import VariantList from "../../VariantList/VariantList";
import { fetchTemplateMetaData } from "redux/features/templates_/variantsSlice";
import { ActionButtons } from "components/Buttons/ActionButtons/ActionsButtons";
import { getToken } from "services/getToken";
import { formatDate, getCurrentDate } from "helperFunctions/date";
import Personalization, {
  } from "../../Personalization/Personalization";
import { validateName } from "services/validateName";
import { useForm } from "antd/es/form/Form";
import CustomDrawer from "components/CustomDrawer/CustomDrawer";
import { CustomData } from "../../CustomData/CustomData";
import {  CampaignChannelTypeEnum, CustomDataEnum } from "utils/enums";
import { API_ENDPOINTS } from "utils/constants";

interface WebPushProps {}

const WebPush: FC<WebPushProps> = ({}) => {
  const { appId } = useSelector((state: any) => state.activeApp);
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const languageVariants = useSelector((state: any) => state.templateMetaData.data);
  const [deviceType, setDeviceType] =
    useState<"Android" | "IOS" | "windows" | "Mac">("windows");
  const [isPreview, setIsPreview] = useState<boolean>(true);
  const dispatch: any = useDispatch();
  const templates = useSelector((state: any) => state.templatesList.data);
  const selectedTemplate = useSelector((state: any) => state.templateById.data);
  const [variants, setVariants] = useState<any>([]);
  const [selectedVariant, setSelectedVariant] = useState<any>(null);
  const campaignData = useSelector((state: any) => state.campaignData.data);
  const [personalization, setPersonalization] = useState({
    open: false,
    field: "",
  });
  const [blobContainerCDN, setBlobContainerCDN] = useState(
    campaignData?.data?.blobContainerCDN || ""
  );
  const currentStep = useSelector(
    (state: any) => state.campaignCreateCurrentStep
  );
  const [imageLoading, setImageLoading] = useState({
    webPushExpanded: false,
    webPushHeader: false,
  });
  const [customDataState, setCustomDataState] = useState<any>({});
  const [showDrawer, setShowDrawer] = useState(false);

  const{DEVICE,IMAGE}=CustomDataEnum;

  const { showError } = useMessage();
  const [template, setTemplate] = useState({
    template_name: "",
    template_type: CampaignChannelTypeEnum.WEB_PUSH,
    isTemplateDeleted: "false",
  });
  const [formData, setFormData] = useState<any>({
    title: "",
    message: "",
    icon: "",
    extendedImage: "",
    cdata: {},

  });
  const [conditon, setCondition] = useState({
    expandedImg: false,
  });
  const [oldTemplateName, setOldTemplateName] = useState(""); //old template name store to avoid validation if remplate name does not change
  const [form] = useForm();

  const handleChangeCondition = (
    key: string,
    value: string | number | boolean
  ) => {
    setCondition((prevState) => ({ ...prevState, [key]: value }));
  };
  const handleCreateVariant = async (
    type: "variant" | "language" = "variant",
    language: any = {}
  ) => {
    const payload = {
      app_id: appId,
      api_key: loginUser.api_key,
    };
    const id =
      type == "variant"
        ? await centralApi("GET", "o/metrices/getObjectId", null, payload)
        : language?._id;
    const variance_top_name =
      type == "variant"
        ? `Variant ${
            variants.filter((variant: any) =>
              variant?.variance_top_name.includes("Variant")
            ).length + 1
          }`
        : language?.name;
    const payloadData: any = {
      variance_top_name,
      variance_id: id,
      variant_type: "Multivariate",
      ...formData,
      variance_name: template.template_name,
    };
    setVariants((prevState: any) => [...prevState, payloadData]);
  };
  const handleDeleteVariant = (id: string) => {
    const afterVariantDelete = variants.filter(
      (variant: any) => variant.variance_id !== id
    );
    setVariants(afterVariantDelete);
  };
  const handleVariantClick = (id: string) => {
    variants.forEach((variant: any) => {
      if (variant.variance_id == id) {
        setSelectedVariant(variant);
      }
    });
  };
  const handleFormDataChange = (name: string, value: string) => {
    setFormData((prevState: typeof formData) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSaveOrUpdate = async () => {
    try {
      // Perform name validation
      let isValidTemplateName;
      // Call validateName only when template name is changed
      if (template.template_name !== oldTemplateName) {
        try {
          isValidTemplateName = await validateName(
            template.template_name,
            CampaignChannelTypeEnum.WEB_PUSH,
            loginUser.api_key,
            appId
          );
          // Proceed with form validation and submission
        } catch (error) {
          showError(t("somethingWrongLabel"));
        }
      }

      // Validate the form fields
      if (isValidTemplateName) {
        form.setFields([
          {
            name: "templateName",
            errors: [t("templateNameExistsErrorMessage")],
          },
        ]);
      } else {
        //check other fields validation
        await form.validateFields();
        // Form is valid, proceed with saving or updating
        selectedTemplate ? handleTemplateUpdate() : handleTemplateSave();
      }
    } catch (errors) {
showError(t("validationErrorLabel"))    }
  };

  useEffect(() => {
    // Set initial values when template changes
    if (selectedTemplate) {
      form.setFieldsValue({
        templateName: template.template_name,
      });
    }
  }, [template, form]);
  useEffect(() => {
    dispatch(
      fetchTemplatesList({
        app_id: appId,
        api_key: loginUser.api_key,
        template_type: CampaignChannelTypeEnum.WEB_PUSH,
      })
    );
    dispatch(
      fetchTemplateMetaData({
        app_id: appId,
        api_key: loginUser.api_key,
        args: {},
      })
    );
    getToken(appId, loginUser.api_key);
    if (campaignData.data) {
      dispatch(setSelectedTemplate(campaignData.data));
    }
  }, []);
  useEffect(() => {
    if (selectedTemplate) {
      dispatch(
        setCampaignData({
          ...campaignData,
          data: selectedTemplate,
          tid: selectedTemplate._id,
          t: selectedTemplate.template_type,
        })
      );
      if (Array.isArray(selectedTemplate?.template)) {
        setVariants(selectedTemplate.template);
        setSelectedVariant(selectedTemplate.template[0]);
      } else {
        setVariants([]);
        setSelectedVariant(null);
      }
    }
  }, [selectedTemplate]);
//check for existing template 
  useEffect(() => {
    const { template, template_name, template_type, blobContainerCDN } =
      selectedTemplate || campaignData.data || formData;
    if (blobContainerCDN) {
      setBlobContainerCDN(blobContainerCDN);
    }

    if (template || selectedVariant) {
      const {
        actions,
        title,
        message,
        extendedImage,
        externalUrl,
        icon,
        variableInfo,
        cdata,
      } =
        Array.isArray(template) || selectedVariant
          ? selectedVariant
            ? selectedVariant
            : template[0]
          : template
          ? template
          : formData;
      setFormData((prevState: any) => ({
        ...prevState,
        actions,
        title,
        message,
        extendedImage,
        externalUrl,
        icon,
        variableInfo,
        cdata,
      }));
      
      if (template_name) {
        setTemplate((prevState) => ({
          ...prevState,
          template_name,
          template_type,
        }));
      }
      if (cdata) {
        const custom_data: any = {};
        if (Array.isArray(cdata)) {
          // Handle array of objects
          cdata.forEach((obj: any) => {
            const [key, value] = Object.entries(obj)[0]; // Destructuring to get the first key-value pair
            custom_data[uuid()] = {
              key: key, // Key obtained from destructuring
              value: value, // Value obtained from destructuring
              type: obj?.type || "", // Assuming obj has a property called 'type'
            };
          });
        } else if (typeof cdata === "object" && cdata !== null) {
          // Handle plain object
          Object.keys(cdata).forEach((key: string) => {
            custom_data[uuid()] = { key, value: cdata[key] };
          });
        }
        setCustomDataState(custom_data);
        // setCustomDataState(cdata);
      }
    }
    setOldTemplateName(template_name); // save template name
  }, [selectedTemplate, selectedVariant, campaignData]);
  useEffect(() => {
    if (selectedVariant) {
      const modifiedVariants: any = [];
      variants.forEach((variant: any) => {
        if (variant.variance_id == selectedVariant.variance_id) {
          let targetVariant = variant;
          targetVariant = { ...targetVariant, ...formData };
          modifiedVariants.push(targetVariant);
        } else {
          modifiedVariants.push(variant);
        }
      });
      setVariants(modifiedVariants);
    }
    handleChangeCondition("expandedImg", formData.extendedImage ? true : false);
  }, [formData, selectedVariant]);
  const handleImageUplaod = async (
    value: any,
    type: "webPushExpanded" | "webPushHeader"
  ) => {
    try {
      const { valid, message } = await validateImageDimensions(
        type,
        value.file
      );
      if (valid && appId) {
        //upload image
        setImageLoading((prevState: any) => ({
          ...prevState,
          [type]: true,
        }));
        const formData = new FormData();
        formData.append("image", value.file);
        formData.append("image_id", value.file.name);
        formData.append("appid", appId);
        const res = await centralApi(
          "POST",
          API_ENDPOINTS.SAVE_CAMPAIGN_IMAGES,
          formData,
          null
        );
        setTimeout(() => {
          setBlobContainerCDN(res.baseURL);
          setFormData((prevState: any) => ({
            ...prevState,
            [type == "webPushHeader" ? "icon" : "extendedImage"]: "/" + res.url,
          }));
          setImageLoading((prevState: any) => ({
            ...prevState,
            [type]: false,
          }));
        }, 1000);
      } else {
        showError(message);
      }
    } catch (error) {
      setImageLoading((prevState: any) => ({
        ...prevState,
        [type]: false,
      }));
    }
  };
  const handleTemplateSave = async () => {
    try {
      const payload = {
        api_key: loginUser.api_key,
        app_id: appId,
        args: JSON.stringify({
          ...template,
          template: variants.length > 0 ? variants : formData,
          createdOn: formatDate(getCurrentDate(), "MM/DD/YYYY"),
          modifiedOn: "",
        }),
      };
      const res = await centralApi("POST", API_ENDPOINTS.CREATE_CAMPAIGN_TEMPLATE, payload);
      dispatch(
        setCampaignData({
          ...campaignData,
          data: { ...res, blobContainerCDN },
          tid: res._id,
          t: res.template_type,
        })
      );
      dispatch(setCurrentStep(currentStep + 1));
    } catch (error) {
      showError(t("somethingWrongLabel"));
    }
  };
  
  const handleTemplateUpdate = async () => {
    try {
      
      const payload = {
        api_key: loginUser.api_key,
        app_id: appId,
        template_id: selectedTemplate._id,
        args: JSON.stringify({
          ...template,
          template: variants.length > 0 ? variants : formData,
          modifiedOn: formatDate(getCurrentDate(), "MM/DD/YYYY"),
        }),

      };
      const res = await centralApi("POST", API_ENDPOINTS.UPDATE_CAMPAIGN_TEMPLATE, payload);
      dispatch(
        setCampaignData({
          ...campaignData,
          data: { ...res, blobContainerCDN },
          tid: res._id,
          t: res.template_type,
        })
      );
      dispatch(setCurrentStep(currentStep + 1));
    } catch (error) {
      showError(t("somethingWrongLabel"));

    }
  };
  const saveCdata = () => {
    const cdata: any = [];

    Object.values(customDataState).forEach((value: any) => {
      if (value.value && value.key) {
        const dataObject = {
          [value.key]: value.value,
          type: value.type === DEVICE ? IMAGE:value?.type,

        };
        cdata.push(dataObject);
      }
    });
    setFormData((prevState: any) => ({ ...prevState, cdata }));
  };

  return (
    <div>
      <Form
        colon={false}
        labelAlign="left"
        labelCol={{ flex: "180px" }}
        layout="horizontal"
        form={form}
      >
        <Personalization
          personalizationState={personalization}
          setFormData={setFormData}
          setPersonalizationState={setPersonalization}
        />
          <CustomDrawer
        open={showDrawer}
        placement="right"
        onClose={() => {
          setShowDrawer(false);
        }}
        className="p-0"
        closable={false}
        width={540}
        footer={
          <Space className="d-flex justify-content-end">
            <PrimaryButton
              onClick={() => {
                setShowDrawer(false);
              }}
            >
              {t("cancelLabel")}
            </PrimaryButton>
            <PrimaryButton
              type="primary"
              onClick={() => {
                saveCdata();
                setShowDrawer(false);
              }}
            >
              {t("saveLabel")}
            </PrimaryButton>
          </Space>
        }
        height={"90%"}
      >
      
        <CustomData
          customDataState={customDataState}
          setCustomDataState={setCustomDataState}
        />
      </CustomDrawer>
        <TemplateLayout
          rightRatio={6}
          leftRatio={6}
          leftItems={
            <div
              className={`p-2 ${!isPreview ? "ms-lg-5" : "ms-lg-1"} d-flex ${
                deviceType == "Android" && isPreview
                  ? "justify-content-end"
                  : "justify-content-center"
              }`}
            >
              <CampaignDevice
                intialDeviceType={deviceType}
                campType="webpush"
                setDevice={(value: typeof deviceType) => setDeviceType(value)}
                setIsPreview={setIsPreview}
                isPreview={isPreview}
              >
                <div
                  className={`my-2 h-100 w-100  d-flex  ${
                    isPreview
                      ? deviceType == "Android"
                        ? "justify-content-center"
                        : deviceType == "Mac"
                        ? "justify-content-end align-items-start "
                        : "justify-content-end align-items-end "
                      : "justify-content-center align-items-center"
                  }`}
                >
                  <WebPushContainer
                    isPreview={isPreview}
                    deviceType={deviceType}
                    title={formData?.title}
                    variableInfo={formData?.variableInfo}
                    message={formData?.message}
                    uploadImage={handleImageUplaod}
                    headerImg={
                      formData.icon ? `${blobContainerCDN + formData.icon}` : ""
                    }
                    showExpanded={conditon.expandedImg}
                    expandedImage={
                      formData.extendedImage
                        ? `${blobContainerCDN + formData.extendedImage}`
                        : ""
                    }
                    buttons={formData?.actions}
                    url={formData?.externalUrl}
                    isLoading={imageLoading}
                  />
                </div>
              </CampaignDevice>
            </div>
          }
          rightItems={
            <div>
              <Row className="pt-2">
                <Col span={24}>
                  <h5 className="mt-0">{t("webPushCampaignLabel")}</h5>
                </Col>
                <Col className="d-flex gap-1 justify-content-between" span={24}>
                  <FormItem
                    className="my-0 w-100"
                    name={"template_name"}
                    label=""
                  >
                    <Selector
                      // showSearch
                      onChange={(tempString) =>
                        dispatch(
                          fetchTemplateById({
                            app_id: appId,
                            api_key: loginUser.api_key,
                            template_id: JSON.parse(tempString)._id,
                          })
                        )
                      }
                      options={templates.map(
                        ({
                          template_name,
                          _id,
                        }: {
                          template_name: string;
                          _id: string;
                        }) => ({
                          label: template_name,
                          value: JSON.stringify({ _id, template_name }),
                        })
                      )}
                      placeholder={t("selectingExistingTemplateLabel")}
                    />
                  </FormItem>
                  <div className="d-flex gap-1  align-items-center">
                    <Selector
                      style={{ width: 110 }}
                      onChange={handleVariantClick}
                      disabled={!template.template_name || variants.length >= 6}
                      value={
                        selectedVariant?.variance_top_name?.includes(
                          "Variant"
                        ) && selectedVariant?.variance_id
                      }
                      placeholder={t("variantLabel")}
                      dropdownRender={(menu) => (
                        <>
                          <PrimaryButton
                            type="link"
                            className="w-100"
                            onClick={() => handleCreateVariant()}
                          >
                            <PlusOutlined />
                            <span className="ms-2 me-3">
                              {t("createLabel")}
                            </span>
                          </PrimaryButton>
                          {menu}
                        </>
                      )}
                      options={variants
                        ?.filter((variant: any) =>
                          variant?.variance_top_name?.includes("Variant")
                        )
                        .map((variant: any) => ({
                          label: variant.variance_top_name,
                          value: variant?.variance_id,
                        }))}
                    />
                    <Selector
                      style={{ width: 110 }}
                      disabled={!template.template_name || variants.length >= 6}
                      onChange={(jsonData) => {
                        handleCreateVariant("language", JSON.parse(jsonData));
                      }}
                      options={languageVariants?.varaints?.map((langVariant: any) => ({
                        label: langVariant.name,
                        value: JSON.stringify(langVariant),
                      }))}
                      placeholder={t("languageLabel")}
                    />
                  </div>
                </Col>
                <Col
                  span={24}
                  className="d-flex aligm-items-center pt-2 justify-content-between"
                >
                  <VariantList
                    variants={variants}
                    selectedVariant={selectedVariant}
                    handleDeleteVariant={handleDeleteVariant}
                    handleVariantClick={handleVariantClick}
                  />
                </Col>
              </Row>{" "}
              <Row className="pt-2 position-relative d-flex justify-content-center">
                <Col className="d-flex " span={24}>
                  <FormItem
                    className="w-100 m-0  "
                    label={t("templateNameLabel")}
                    name={"templateName"}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <TextInput
                      value={template.template_name}
                      onChange={(e: any) => {
                        setTemplate((prevState) => ({
                          ...prevState,
                          template_name: e.target.value,
                        }));
                      }}
                    ></TextInput>
                  </FormItem>
                </Col>
                <div className="d-flex pt-2 pb-1 mt-3 w-100 justify-content-end">
                  <MoreOutlined
                    onClick={() => {
                      setPersonalization({ open: true, field: "title" });
                    }}
                  />
                </div>
                <Col span={24}>
                  <FormItem className="m-0 " label={t("titleLabel")}>
                    <TextInput
                      value={formData.title}
                      onChange={(e: any) => {
                        handleFormDataChange("title", e.target.value);
                      }}
                    ></TextInput>
                  </FormItem>
                </Col>
                {formData.actions?.length == undefined && <>
                <div className="d-flex pt-2 w-100 mt-3 pb-1 justify-content-end">
                  <MoreOutlined
                    onClick={() => {
                      setPersonalization({ open: true, field: "externalUrl" });
                    }}
                  />
                </div>
               

                <Col span={24}>
                  <FormItem className="m-0" label={t("redirectURLLabel")}>
                    <TextInput
                      value={formData.externalUrl}
                      onChange={(e: any) => {
                        handleFormDataChange("externalUrl", e.target.value);
                      }}
                    ></TextInput>
                  </FormItem>
                </Col></>}
                <div className="d-flex pt-2 mt-3 pb-1 w-100 justify-content-end">
                  <MoreOutlined
                    onClick={() => {
                      setPersonalization({ open: true, field: "message" });
                    }}
                  />
                </div>
                <Col span={24}>
                  <FormItem className="m-0 " label={t("messageLabel")}>
                    <CustomTextArea
                      value={formData.message}
                      onChange={(e: any) => {
                        handleFormDataChange("message", e.target.value);
                      }}
                      maxLength={140}
                      style={{ height: 120, resize: "none" }}
                    />
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem
                    className="m-0 mt-3"
                    label={t("expandedImageLabel")}
                  >
                    <SegmentedCustom
                      value={conditon.expandedImg}
                      onChange={(value: boolean) => {
                        if (!value) {
                          setFormData((prevState: any) => {
                            return {
                              ...prevState,
                              extendedImage: "",
                            };
                          });
                        }
                        handleChangeCondition("expandedImg", value);
                      }}
                    />
                  </FormItem>
                </Col>
                <Col className="py-2" span={24}>
                  <FormItem
                    className="m-0 "
                    style={{ minWidth: 300 }}
                    label={t("buttonsLabel")}
                  >
                    <SegmentedCustom
                      value={formData.actions?.length > 0}
                      
                    onChange={(value: boolean) => {
                      setFormData((prevState: any) => {
                        // Create a new state object with the updated actions
                        const updatedState = { ...prevState };
                
                        // Update actions based on the value
                        if (value) {
                          updatedState.actions = [
                            {
                              actionName: "",
                              actionExternalUrl: "",
                              actionType: "",
                            },
                          ];
                          delete updatedState?.externalUrl;

                        } else {
                          // Remove actions from state if value is false
                          delete updatedState?.actions;

                        }
                
                        return updatedState;
                      });
                    }}
                     />
                    {formData.actions?.length > 0 && (
                      <div className={"my-2"}>
                        <ActionButtons
                          maxButtons={2}
                          type={CampaignChannelTypeEnum.WEB_PUSH}
                          buttonsState={formData.actions}
                          setButtonsState={(value: any) =>
                            setFormData((prevState: any) => ({
                              ...prevState,
                              actions: value,
                            }))
                          }
                        />
                      </div>
                    )}
                  </FormItem>
                </Col>
                <Col className="mt-2" span={24}>
                              <FormItem
                                className="my-0  w-100"
                                label={t("customDataLabel")}
                              >
                                <div className="d-flex gap-2">
                                  <SegmentedCustom
                                    value={
                                      Object.keys(customDataState)?.length > 0
                                    }
                                    onChange={(value: boolean) => {
                                      setCustomDataState(() =>
                                        value
                                          ? {
                                              [uuid()]: {
                                                key: "",
                                                value: "",
                                              },
                                            }
                                          : {}
                                      );
                                      !value &&
                                        setFormData((prevState: any) => {
                                          return { ...prevState, cdata: {} };
                                        });
                                    }}
                                  />
                                  {Object.keys(customDataState).length > 0 && (
                                    <PrimaryButton
                                      onClick={() => {
                                        setShowDrawer(true);
                                      }}
                                      style={{
                                        color: "white",
                                        background: "var( --color-red-main)",
                                      }}
                                    >
                                      {t("addLabel")}
                                    </PrimaryButton>
                                  )}
                                </div>
                              </FormItem>
                            </Col>
              </Row>
            </div>
          }
          footerItem={
            <Row gutter={10} className="d-flex justify-content-end">
              <Col>
                <PrimaryButton
                  disabled={!template.template_name}
                  type="primary"
                  htmlType="submit"
                  onClick={handleSaveOrUpdate}
                >
                  {selectedTemplate ? t("updateLabel") : t("saveLabel")}
                </PrimaryButton>
              </Col>
              <Col>
                <PrimaryButton
                  style={{
                    color: "white",
                    background: "var( --color-red-main)",
                  }}
                  onClick={() => {
                    dispatch(setCampaignData({}));
                    dispatch(setCurrentStep(0));
                  }}
                >
                  {t("closeLabel")}
                </PrimaryButton>
              </Col>
            </Row>
          }
        />
      </Form>
    </div>
  );
};

export default WebPush;
