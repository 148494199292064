import Picker, { PickerProps } from 'emoji-picker-react';

const EmojiPicker = (props:PickerProps) => {

  return (
    <div>
      <Picker {...props}  />
    </div>
  );
};

export default EmojiPicker;
