import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

const useDebouncedValue = <T,>(initialValue: T, delay: number): [T, (value: T) => void] => {
  const [value, setValue] = useState<T | undefined>(initialValue);

  const debounced = useDebouncedCallback((val: T) => {
    setValue(val);
  }, delay);

  const setDebouncedValue = (val: T) => {
    debounced(val);
  };

  return [value as T, setDebouncedValue];
};

export default useDebouncedValue;
