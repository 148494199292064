export const getData = function(){
    return{
    data1:[
    
  
    [
        "wk 1",
        7254,
    ],
    [
        "wk 2",
        8464
    ],
    [
        "wk 3",
        5646
    ],
    [
        "wk 4",
        6646
    ],
    [
        "wk 5",
        7646
    ],
    [
        "wk 6",
        6646
    ],
    [
        "wk 7",
        6646
    ],
    [
        "wk 8",
        8546
    ],
    [
        "wk 9",
        6264
    ],
    [
        "wk 10",
        7098
    ],
    
],
data2 : [
    
  
    [
        "wk 1",
        7254,
    ],
    [
        "wk 2",
        8464
    ],
    [
        "wk 3",
        5646
    ],
    [
        "wk 4",
        6000
    ],
    [
        "wk 5",
        7646
    ],
    [
        "wk 6",
        5000
    ],
    [
        "wk 7",
        6646
    ],
    [
        "wk 8",
        8546
    ],
    [
        "wk 9",
        6264
    ],
    [
        "wk 10",
        7098
    ],
    
],
data3 :[
    
  
    [
        "wk 1",
        7254,
    ],
    [
        "wk 2",
        8464
    ],
    [
        "wk 3",
        5646
    ],
    [
        "wk 4",
        7646
    ],
    [
        "wk 5",
        7646
    ],
    [
        "wk 6",
        6646
    ],
    [
        "wk 7",
        6746
    ],
    [
        "wk 8",
        8546
    ],
    [
        "wk 9",
        6264
    ],
    [
        "wk 10",
        7098
    ],
    
]}}
  
  