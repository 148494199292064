import { useEffect, useMemo, useState } from "react";
import Selector from "components/Selector/Selector";
import SegmentedCustom from "components/SegmentedCustom/SegmentedCustom";
import { Form, FormInstance, Tag } from "antd";
import { t } from "i18next";
import "./TraitCondition.css";
import { eOperatorType } from "@customTypes/audience";
import { AudienceEnum, KeyboardKeysEnum } from "utils/enums";
import { validateNotEmpty } from "validator/antdValidator";
import { useDispatch, useSelector } from "react-redux";
import { setComputedTraitData } from "redux/features/audience/computedTraitSlice";
import { OperatorManager } from "utils/operators";
import { TraitInfo } from "utils/models";
import NoData from "components/NoData/NoData";
type Props = {
  form: FormInstance;
  condition: TraitInfo;
  index: number;
  metaData: any;
  fetchTableInfo: (operand: string | undefined) => Promise<any>;
};
/**
 * TraitCondition Component
 *
 * this component  renders a form condition for trait
 * It allows users to configure conditions based on operands, operators, and values,
 * and supports adding/removing computation logic and logical operators (AND/OR).
 *
 * @param {Object} props - Component props.
 * @param {FormInstance} props.form - Ant Design Form instance used for form handling.
 * @param {traitConditionType} props.condition - Initial condition data for the trait.
 * @param {number} props.index - Index of the condition in the list.
 * @param {any} props.metaData - Metadata used to populate options in the form fields.
 *
 * @returns {JSX.Element} - The rendered component.
 */
function TraitCondition({
  form,
  index,
  condition,
  metaData,
  fetchTableInfo,
}: Props) {
  const [formData, setFormData] = useState<TraitInfo>({
    compute: condition?.compute,
    func: condition?.func,
    type: condition?.type,
    operand: condition?.operand,
    operator: condition?.operator,
    jsonb:condition?.jsonb,
    value: condition?.value,
    e_operator: index ? condition.e_operator : undefined, //set e_operator for the first condition undefined
  });
  const [values, setValues] = useState({ data: [], loading: false });
  const [manualValue, setManualValue] = useState("");
  const dispatch: any = useDispatch();
  //get trait form redux store
  const trait = useSelector((state: any) => state.computedTrait.data);
  /**
   * Handles changes in the form data.
   * @param data - An object representing the  form data. Each key is a string, and values can be a string, array of strings, boolean, or undefined.
   */
  const handleFormChange = (data: {
    [key: string]: string | string[] | boolean | undefined;
  }) => {
    //set the local state
    setFormData((prevState) => ({ ...prevState, ...data }));
  };
  /**
   * Handles the change in traitInfo property and updates the redux store
   * @param data - The new trait condition data to update or remove.
   */
  const onValueChange = (data: TraitInfo) => {
    // Create a copy of the traitInfo array to modify it.
    const modifiedTraitInfo = [...trait.traitInfo];

    if (data) {
      // If new data is provided, update the traitInfo array at the specified index.
      modifiedTraitInfo[index] = data;
    } else {
      // If no data is provided (i.e., `data` is falsy), remove the attribute object from the array at the specified index.
      modifiedTraitInfo.splice(index, 1);
    }

    // Dispatch an action to update the traitInfo in the Redux store with the modified array.
    dispatch(setComputedTraitData({ traitInfo: modifiedTraitInfo }));
  };
  /**
   * Fetches column values based on the current operand(column) and updates the state accordingly.
   * This function performs the following actions:
   * 1. Sets the loading state to true before making the API request.
   * 2. Calls the `fetchTableInfo` function with the current operand to retrieve column values.
   * 3. Updates the state with the fetched column values and sets the loading state to false.
   */
  const getColumnValues = async () => {
    setValues((prevState) => ({ ...prevState, loading: true }));
    const values = await fetchTableInfo(formData.operand);
    setValues({ data: values, loading: false });
  };
  //get selected column/operand maxValue 
  const maxValueCount=useMemo(()=>{
    if(formData.type && formData.operator){
     const option= OperatorManager.getPgSqlOperatorOptions(formData.type)
      ?.options.find((option)=>option.value==formData.operator)
      return option?.maxValues
    }
  },[formData.operator,formData.type])
  useEffect(() => {
    // Call onValueChange to update the Redux store with the current formData
    onValueChange(formData);
  }, [formData]);
  useEffect(() => {
    //get column values
    if (formData.operand) {
      getColumnValues();
    }
    return () => {
      //reset the form fields on component unmount
      form.resetFields([
        `func[${index}]`,
        `operand[${index}]`,
        `operator[${index}]`,
        `value[${index}]`,
      ]);
    };
  }, [formData.operand]);
  useEffect(() => {
    if (!formData.value) {
      form.resetFields([`value[${index}]`]);
    }
  }, [formData.value]);

  return (
    <>
      <div className="ctrait-condition-container p-2  d-flex flex-column gap-2">
        <div className="d-flex   ps-1 align-items-center">
          <span className="ctrait-w100px">
            <Tag
              className="cursor-pointer"
              onClick={() =>
                handleFormChange({
                  compute: !formData.compute,
                  func: undefined,
                })
              }
              color={formData?.compute ? `var(--color-success)` : undefined}
            >
              {(formData?.compute ? "- " : "+ ") + `${t("computeLabel")}`}
            </Tag>
          </span>
          {formData?.compute && (
            <Form.Item
              className="m-0"
              labelCol={{ flex: "100px" }}
              rules={[
                {
                  validator: validateNotEmpty,
                  message: `${t("selectFunctionLabel")}`,
                },
              ]}
              name={`func[${index}]`}
              initialValue={formData.func || undefined}
              label={t("functionLabel")}
            >
              <Selector
                onChange={(value) => {
                  handleFormChange({ func: value });
                }}
                className="input-size-lg"
                options={metaData?.functions}
              />
            </Form.Item>
          )}
        </div>
        <div className="d-flex align-items-center   w-100">
          <span className=" ctrait-w100px ps-1 ">{t("whereLabel")}</span>
          <div className="my-2 ps-1  gap-2  d-flex  ">
            <Form.Item
              name={`operand[${index}]`}
              className="mb-1 input-size-md"
              initialValue={formData.operand || undefined}
              rules={[
                {
                  validator: validateNotEmpty,
                  message: `${t("selectOperandMsg")}`,
                },
              ]}
            >
              <Selector
                options={metaData.columns}
                filterOption={(input, option) => typeof option?.label=="string"?option?.label?.toLowerCase().includes(input?.toLowerCase()):false}
                onChange={(val) => {
                  const operandInfo = metaData?.columns?.find(
                    ({ value }: { value: string }) => val == value
                  );
                  const type =
                    OperatorManager.getPgSqlOperatorOptions(operandInfo.type).type;
                  handleFormChange({
                    operand: val,
                    type: type,
                    jsonb:operandInfo.jsonb || undefined,
                    operator: undefined,
                    value: undefined,
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              className="mb-1 input-size-md"
              name={`operator[${index}]`}
              initialValue={formData.operator || undefined}
              rules={[
                {
                  validator: validateNotEmpty,
                  message: `${t("selectOperatorMsg")}`,
                },
              ]}
            >
              <Selector
                options={
                  OperatorManager.getPgSqlOperatorOptions(formData.type)
                    ?.options
                }
                onChange={(value) => {
                  handleFormChange({ operator: value, value: undefined });
                }}
              />
            </Form.Item>
            <Form.Item
              className="mb-1 input-size-lg"
              name={`value[${index}]`}
              initialValue={formData.value || undefined}
              rules={[
                {
                  validator: validateNotEmpty,
                  message: `${t("selectValueMsg")}`,
                },
              ]}
            >
              <Selector
                maxCount={maxValueCount}
                loading={values.loading}
                notFoundContent={<div className="text-center">
                <NoData/>
                <strong>
                {t("pressEnterKeyMsg")}
                </strong>
                </div>}
                options={values.data}
                onKeyDown={(event) => {
                  if (event.key === KeyboardKeysEnum.ENTER && manualValue) {
                    setValues((prevState:any) => {
                      const newValue={label:manualValue,value:manualValue}
                     return ({...prevState,data:[...prevState.data,newValue]})
                     
                    });
                  }
                }}
                mode="multiple"
                onSearch={(value) => {
                  // Check if there are any search results
                  const results = values.data.filter((item: any) =>
                    item.value
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  );
                  if (!results.length) {
                    setManualValue(value);
                  }
                }}
                onChange={(value) => {
                    handleFormChange({ value });
                }}
              />
            </Form.Item>
          </div>
        </div>
      </div>
      {index !== 0 && (
        <div className="pb-2">
          <SegmentedCustom
            value={formData.e_operator}
            size="small"
            onChange={(value: eOperatorType) => {
              handleFormChange({ e_operator: value });
            }}
            options={[
              { label: AudienceEnum.AND, value: AudienceEnum.AND },
              { label: AudienceEnum.OR, value: AudienceEnum.OR },
            ]}
          />
        </div>
      )}
    </>
  );
}

export default TraitCondition;
