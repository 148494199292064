import PrimaryButton from "../../../../components/Buttons/PrimaryButton/PrimaryButton";
import Selector from "../../../../components/Selector/Selector";
import { Form } from "antd";
import CohortChart from "../../../../components/Charts/CohortChart/CohortChart";
import SegmentedCustom from "../../../../components/SegmentedCustom/SegmentedCustom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import centralApi from "../../../../services/centralApi";
import "./Cohort.css";
import FormItem from "antd/es/form/FormItem";
import { getDaysDifference } from "../../../../helperFunctions/cohort";
import { getMonthName } from "../../../../helperFunctions/cohort";
import { formattedHeader } from "../../../../helperFunctions/cohort";
import SkeletonLoader from "components/SkeletonLoader/SkeletonLoader";
import { DateRangeSelector } from "components/DateTimeSelectors/DateRangeSelector/DateRangeSelector";
import { DateRangeData } from "@customTypes/dateRange";
export const Cohort = () => {
  const [loading, setLoading] = useState(false);
  const [cohortData, setCohortData] = useState<any>([]);
  const [chartData, setChartData] = useState([]);
  const [periodType, setPeriodType] = useState("m");
  const [showPercentage, setShowPercentage] = useState(false);
  const [chartKey, setChartKey] = useState(0);
  const [dateRange, setDateRange] = useState<DateRangeData | null>(null);

  const { api_key } = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const styleBtn = {
    width: "auto",
    marginRight: "10px",
    marginBottom: "10px",
  };
  async function getCohortDataForInstalls(payload: any) {
    const url: string = `/o/metrices/getCohortDataForInstalls`;
    if (appId && api_key) {
      try {
        setLoading(true);
        const data = await centralApi("GET", url, null, payload);
        setCohortData(data);
      } catch (error) {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    if (periodType == "m") {
      const data: any = {
        weeks: {},
      };
      Object.keys(cohortData).forEach((year: any) => {
        const months = Object.keys(cohortData[year]);
        let currentLength = months.length;

        months.forEach((month: any) => {
          const monthName = getMonthName(Number(month));
          const key = `${monthName} ${year}`;
          const monthTotal = Object.values(cohortData[year][month]).reduce(
            (acc, day: any) => acc + day.in,
            0
          );

          // Add the month to the data object
          if (!data.weeks[key]) {
            data.weeks[key] = [];
          }
          for (let i = 0; i < currentLength; i++) {
            data.weeks[key].push(monthTotal);
          }
          data.weeks[key].push(monthTotal);

          currentLength -= 1;
        });
      });
      setLoading(false);
      setChartData(data);
    }
    if (periodType === "d") {
      const data: any = {
        weeks: {},
      };
      const endDateString = dateRange?.endDate_CNew;
      const startDateString = dateRange?.startDate_CNew;
      let maxLength = getDaysDifference(startDateString, endDateString);
      Object.keys(cohortData).forEach((year: any) => {
        const months = Object.keys(cohortData[year]);
        months.forEach((month: any) => {
          const daysWithData = Object.keys(cohortData[year][month]).map(Number);
          const earliestDay = Math.min(...daysWithData);
          const endDate = endDateString ? new Date(endDateString) : null;
          const startDate = startDateString ? new Date(startDateString) : null;
          // Adjust the time to the end of the day if endDate is not null
          if (endDate && startDate) {
            endDate.setHours(23, 59, 59, 999);
            startDate.setHours(23, 59, 59, 999);
          }
          const latestDay = Math.max(...daysWithData);
          const totalDaysInMonth = new Date(year, month, 0).getDate();

          // Process each day in the month
          for (let day = 1; day <= totalDaysInMonth; day++) {
            if (day >= earliestDay && day <= latestDay) {
              const dayValue = cohortData[year][month][day]?.in || 0;
              const monthName = getMonthName(Number(month));
              const paddedDay = day.toString().padStart(2, "0");
              const key = `${paddedDay} ${monthName} ${year}`;

              // Add the day to the data object
              if (!data.weeks[key]) {
                data.weeks[key] = [];
              }
              for (let i = 0; i < maxLength; i++) {
                data.weeks[key].push(dayValue);
              }
              maxLength--;
            }
          }
        });
      });
      setLoading(false);
      setChartData(data);
    }
    if (periodType === "w") {
      const data: any = {
        weeks: {},
      };

      Object.keys(cohortData).forEach((year: any) => {
        const weeks = Object.keys(cohortData[year]);
        let currentLength = weeks.length;
        weeks.forEach((week) => {
          // Assuming cohortData[year][week] represents the data for a given week
          const inEntries = cohortData[year][week].in;

          // Create the key for the week
          const key = `week ${week}`;

          // Add the total "in" entries for the current week
          if (!data.weeks[key]) {
            data.weeks[key] = [];
          }
          for (let i = 0; i < currentLength; i++) {
            data.weeks[key].push(inEntries);
          }
          currentLength -= 1;

          data.weeks[key].push(inEntries);
        });
      });
      setLoading(false);

      setChartData(data);
    }
    if (periodType === "y") {
      const data: any = {
        weeks: {},
      };

      Object.keys(cohortData).forEach((year: any) => {
        const monthsData = cohortData[year];
        let totalIn = 0;

        Object.values(monthsData).forEach((month: any) => {
          Object.values(month).forEach((week: any) => {
            totalIn += week.in;
          });
        });

        // Store the total "in" values for the year
        data.weeks[year] = [totalIn, totalIn];
      });
      setLoading(false);

      setChartData(data);
    }
  }, [cohortData, showPercentage]);

  useEffect(() => {
    if (appId && api_key && dateRange) {
      const payload = {
        args: JSON.stringify({
          startDate: dateRange?.startDate_C,
          endDate: dateRange?.endDate_C,
          startDateNew: dateRange?.startDate_CNew,
          endDateNew: dateRange?.endDate_CNew,
          periodtype: periodType,
          metrices: [{}],
        }),
        app_id: appId,

        api_key: api_key,
      };

      getCohortDataForInstalls(payload);
    }
  }, [appId, api_key,dateRange]);

  const onFormSubmit = () => {
    setChartData([]);
    const payload = {
      args: JSON.stringify({
        startDate: dateRange?.startDate_C,
        endDate: dateRange?.endDate_C,
        startDateNew: dateRange?.startDate_CNew,
        endDateNew: dateRange?.endDate_CNew,
        periodtype: periodType,
        metrices: [{}],
      }),
      app_id: appId,

      api_key: api_key,
    };
    getCohortDataForInstalls(payload);
  };
  const handleDateRange = (dateRangeData: any) => {
    setDateRange(dateRangeData);
  };
  useEffect(() => {
    setChartKey((prevKey) => prevKey + 1);
  }, [showPercentage]);
  return (
    <div>
      <div className="d-flex flex-column">
        <strong className="my-3">Install Cohort</strong>
        <div className="d-flex justify-content-between flex-wrap">
          <div className="d-flex align-items-baseline gap-2">
            <SegmentedCustom
              onChange={() => {
                setShowPercentage((prev) => !prev);
              }}
              options={[
                { label: "ON", value: "true" },
                { label: "OFF", value: "false" },
              ]}
              defaultValue="false" // Set the default value to "OFF"
            />

            <span className="mx-2">Show Percentage</span>
          </div>
          <div>
            <div>
              <Form onFinish={onFormSubmit} layout="inline">
                <strong className="mx-1 my-1 ">Period </strong>
                <Form.Item name="periodtype">
                  <Selector
                    onChange={(value) => setPeriodType(value)}
                    size="middle"
                    value={periodType}
                    defaultValue={periodType}
                    style={{ ...styleBtn, width: "130px" }}
                    options={[
                      {
                        value: "d",
                        label: "D+1 (Daily)",
                      },
                      {
                        value: "w",
                        label: "D+7 (Weekly)",
                      },
                      {
                        value: "m",
                        label: "D+30 (Monthly)",
                      },
                      {
                        value: "y",
                        label: "Yearly",
                      },
                    ]}
                  />
                </Form.Item>
                <FormItem>

                  <DateRangeSelector
              onDateChange={handleDateRange}
              disableRange={{ range: 1, rangeType: "year" }}
              disableType="year"
              defaultToYearStart={true} 

            />
                </FormItem>

                <span className="mx-1"></span>
                <PrimaryButton htmlType="submit" type="primary">
                  View
                </PrimaryButton>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <div className="cohort-chart-container">
        {loading ? (
          <SkeletonLoader type="default" paragraph={{rows:8}}/>
        ) : (
          <CohortChart
            key={chartKey} // Add a key to force re-render
            data={chartData}
            headerFormatter={(header: any) =>
              formattedHeader(header, chartData)
            }
            defaultValueType={showPercentage ? "percent" : "value"}
          />
        )}
      </div>
    </div>
  );
};
