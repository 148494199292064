import { CaretDownFilled, CaretUpOutlined } from "@ant-design/icons";
import "./UserCard.css";
import rightArrowIcon from "../../../images/rightArrowIcon.png";
import downloadIcon from "../../../images/download.png";
import andriodIcon from "../../../images/andriodIcon.png";
import appleIcon from "../../../images/appleIcon.png";
import globeIcon from "../../../images/webnew.svg";
import PrimaryButton from "../../Buttons/PrimaryButton/PrimaryButton";
import SparkLine from "../../Charts/SparkLine/SparkLine";
import { formateIntoKorM } from "../../../helperFunctions/number";
import SkeletonLoader from "components/SkeletonLoader/SkeletonLoader";
type sparkValueType = {
  x: string | number;
  y: number;
};
const iconStyle = {
  width: "12px",
  height: "12px",
  marginRight: 5,
};

type propTypes = {
  title: string;
  value: number;
  timeString: string;
  key1: string;
  key2: string;
  key3: string;
  value1: number;
  value2: number;
  value3: number;
  showIcons?: boolean;
  lineColor?: string;
  upDownStatus: 0 | 1 | -1;
  sparkData?: sparkValueType[];
  loading?: boolean;
  // onDownloadClick: (e: MouseEvent) => void;
  // onDownloadClick?: (e: React.MouseEvent) => void;
  onDownloadClick?: any;
  showDownloadIcon?: boolean;

  onViewDetailsClick: (e: MouseEvent) => void;
};
const UserCard: React.FC<propTypes> = ({
  title,
  value,
  timeString,
  key1,
  key2,
  key3,
  value1,
  value2,
  value3,
  lineColor,
  showIcons,
  sparkData,
  onDownloadClick,
  onViewDetailsClick,
  upDownStatus,
  loading,
  showDownloadIcon = true, // Set default value here
}) => (
  <div className="user-card-container d-flex flex-column justify-content-around">
    <span className="user-card-title  ">
      {title.length > 20 ? `${title.slice(0, 20)}...` : title}
    </span>
    <div className="d-flex justify-content-between align-items-center  ">
      <span className="user-card-value">
        {loading ? <SkeletonLoader type="button" /> : formateIntoKorM(value)}
      </span>
      <div>
        <SparkLine
          lineColor={lineColor ? lineColor : "green"}
          values={sparkData}
        />
      </div>
    </div>
    <div>
      <span className="d-flex align-items-center user-card-item-22-height">
        {" "}
        {loading ? (
          <SkeletonLoader className="user-card-item-18-height" style={{width:200}}  type="button" />
        ) : (
          <>
            {upDownStatus > 0 ? (
              <CaretUpOutlined style={{ color: "var(--color-success)" }} />
            ) : upDownStatus < 0 ? (
              <CaretDownFilled style={{ color: "var(--color-red-main)" }} />
            ) : (
              ""
            )}
            <strong className="text-overflow-ellipsis">{timeString}</strong>
            {/* <strong>51% w.r.t. Jan 11, 23 - Feb 9, 23</strong> */}
          </>
        )}
      </span>
    </div>
    <div className="d-flex justify-content-between align-items-center mt-1 user-card-item-22-height ">
      <div className="d-flex justify-content-around align-items-center ">
        {showIcons ? (
          <img src={andriodIcon} alt="android-icon" style={iconStyle} />
        ) : (
          <span
          className="app-card-span-placholder bg-danger"

          ></span>
        )}
        <span className="mx-1 os-name">{key1}</span>
      </div>
      {loading ? (
        <SkeletonLoader
         className="user-card-item-20-height"
          type="button"
        />
      ) : (
        <strong>{formateIntoKorM(value1)}</strong>
      )}
    </div>
    <div className="d-flex justify-content-between align-items-center mt-1 user-card-item-22-height">
      <div className="d-flex justify-content-around align-items-center  ">
        {showIcons ? (
          <img src={appleIcon} alt="apple-icon" style={iconStyle} />
        ) : (
          <span
          className="app-card-span-placholder bg-dark"
          ></span>
        )}
        <span className="mx-1 ">{key2}</span>
      </div>
      {loading ? (
        <SkeletonLoader
          className="user-card-item-20-height"
          type="button"
        />
      ) : (
        <strong>{formateIntoKorM(value2)}</strong>
      )}
    </div>
    <div className="d-flex justify-content-between align-items-center mt-1 user-card-item-22-height">
      <div className="d-flex justify-content-around align-items-center ">
        {showIcons ? (
          <img src={globeIcon} alt="globe-icon" style={iconStyle} />
        ) : (
          <span
          className="app-card-span-placholder bg-warning"
          ></span>
        )}

        <span className="mx-1 ">{key3}</span>
      </div>
      {loading ? (
        <SkeletonLoader
          className="user-card-item-20-height"
          type="button"
        />
      ) : (
        <strong>{formateIntoKorM(value3)}</strong>
      )}
    </div>
    <div className="d-flex justify-content-between align-items-center mt-1 user-card-item-22-height">
      <div className="d-flex justify-content-around align-items-center  ">
        {showDownloadIcon && (
          <PrimaryButton className="p-0" type="link" onClick={onDownloadClick}>
            <img src={downloadIcon} alt="download-icon" style={iconStyle} />
          </PrimaryButton>
        )}
        <span className="mx-1 "></span>
      </div>
      {/* <Link
        to={"#"}
        className="text-decoration-none d-flex align-items-center "
      > */}
      <PrimaryButton
        type="link"
        className="p-0"
        style={{ fontSize: "var(--font-size-sm)" }}
        onClick={onViewDetailsClick}
      >
        <span className="mx-1">View Details</span>
        <img src={rightArrowIcon} alt="right-arrow-icon" style={iconStyle} />
      </PrimaryButton>
      {/* </Link> */}
    </div>
  </div>
);

export default UserCard;
