import React from "react";
import CustomHighChart from "../../../../components/Charts/CustomHighChart/CustomHighChart";
export const BrowserChart = () => {
  const dataSeries1 = [11, 20, 13];
  const dataSeries2 = [21, 13, 24];

  const options = {
    title: {
      text: "",
    },
    chart: {
      type: "column",
    },
    xAxis: {
      categories: ["Sent", "Clicked", "Converted"],
    },
    yAxis: {
      title: {
        text: "Values",
      },
    },
    plotOptions: {
      column: {
        borderRadius: "50%",
        // dataLabels: {
        //     enabled: true
        // },
        // groupPadding: ,
        // groupPadding: -0.0005, // Adjust the space between groups
        pointPadding: -0.005, // Adjust the space between columns within the same group

        pointWidth: 20, // Set the column width, adjust as needed
      },
    },
    series: [
      {
        name: "Chrome",
        data: dataSeries1,
        // type: 'column',
      },
      {
        name: "Safari",
        data: dataSeries2,
        // type: 'column',
      },
    ],
  };
  return (
    <div>
      {" "}
      <CustomHighChart options={options} />
    </div>
  );
};
