import React from "react";
import { Switch } from "antd";
import CSS from "csstype";
import { SwitchSize } from "antd/es/switch";
import "./SwitchBtn.css";
type propType = {
  onChange?: (e?: any) => void;
  style?: CSS.Properties;
  background?: string;
  size?: SwitchSize;
  checked?:boolean;
};

const SwitchBtn: React.FC<propType> = ({
  onChange,
  background,
  size,
  ...props
}) => (
  <span className="switch-btn-wrapper">
    <Switch  {...props} size={size} onChange={onChange} />
  </span>
);

export default SwitchBtn;
SwitchBtn.defaultProps = {
  size: "small",
};
