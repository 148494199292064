import { keys } from "@mui/system";
import { getRandomNumber } from "helperFunctions/number";
import { json } from "stream/consumers";
import apiRequest from "../services/apiRequest";
// get audience segment data
export const getAudienceSegments = ({appId,apiKey}:any) => {
  const url: string = `/o/aud/getAudienceSegments`;
  return apiRequest
    .get(url, {
      params: {
        app_id: appId,
        api_key: apiKey,
        uniqueNumber:getRandomNumber()
        
      },
    })
    .then((res) => {
      const data = res.data.result.map((aud: any) => {
        return {
          Key: aud._id,
          Name: aud.name,
          Description: aud.description,
          Syncs: aud._id,
          Range:aud.range,
          Created_On: new Date(aud.createdate).toLocaleDateString("en-GB"),
          Action: aud._id
        };
     });
      return data;
    })
    .catch((e) => {
      throw new Error(e.message);
    });
};
//delete deleteAudienceSegment
export const deleteAudienceSegment = ({id,appId,apiKey}:any) => {
  const url: string = `/i/aud/delete`;
  return apiRequest
    .get(url, {
      params: {
        app_id: appId,
        api_key: apiKey,
        args: JSON.stringify({ _id: id }),
        uniqueNumber:getRandomNumber()
      },
    })
    .then((res) => {
      return res
    })
    .catch((e) => {
      throw new Error(e.message);
    });
};