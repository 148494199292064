import { Divider } from "antd";
import "./WebPushNotification.css";
import PrimaryButton from "../../../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { Form } from "antd";
import { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import centralApi from "../../../../../../services/centralApi";
import { getToken } from "../../../../../../services/getToken";
import ShowMessage from "../../../../../../components/ShowMessage/ShowMessage";
import Spinner from "../../../../../../components/Spinner/Spinner";
import TextInput from "../../../../../../components/Inputs/TextInput/TextInput";
import React from 'react';
interface WebPushNotificationProps {
  currentAppKey: any;
  Mode: string;
  fetchCurrentAppKey:any;
}

const WebPushNotification: React.FC<WebPushNotificationProps> = ({
  currentAppKey,
  Mode,
  fetchCurrentAppKey,
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  });
  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;
  const mode =
    Mode === "Development" ? "dev" : Mode === "Production" ? "prod" : "";

  const onFormSubmit = (values: any) => {
    const payload = {
      args: JSON.stringify({
        app_id: appId,

        webPush: {
          ...values,
        },
        mode,
      }),
      app_id: appId,
      api_key: api_key,
    };
    updateGCMKeys(payload);
    getToken(appId, api_key);

  };

  async function updateGCMKeys(formData: any) {
    const url: string = `/i/apps/updateGCMKeys`;

    try {
      setLoading(true);
      await centralApi("POST", url, formData, null);
      setShowMessage({
        type: "success",
        message: "Successfully Saved",
        show: true,
      });
      setLoading(false);
      fetchCurrentAppKey();
    } catch (error) {
      setLoading(false);
      setShowMessage({
        type: "error",
        message: "Something went wrong",
        show: true,
      });
    }
    hideMessage();
  }
  const hideMessage = () => {
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false });
    }, 2000);
  };
  useEffect(() => {
    // Update form values based on mode and currentAppKey data
    const updatedFormValues = {
      privateKey:
        Mode === "Development"
          ? currentAppKey[0]?.push_notification?.wpn?.dev?.webPush_dev
              ?.privateKey
          : Mode === "Production"
          ? currentAppKey[0]?.push_notification?.wpn?.prod?.webPush_prod
              ?.privateKey
          : "",
      publicKey:
        Mode === "Development"
          ? currentAppKey[0]?.push_notification?.wpn?.dev?.webPush_dev
              ?.publicKey
          : Mode === "Production"
          ? currentAppKey[0]?.push_notification?.wpn?.prod?.webPush_prod
              ?.publicKey
          : "",
      subject:
        Mode === "Development"
          ? currentAppKey[0]?.push_notification?.wpn?.dev?.webPush_dev?.subject
          : Mode === "Production"
          ? currentAppKey[0]?.push_notification?.wpn?.prod?.webPush_prod
              ?.subject
          : "",
    };
    form.setFieldsValue(updatedFormValues);
  }, [mode, currentAppKey, form]);
  useEffect(() => {
    getToken(appId, api_key);
  }, []);

  return (
    <Fragment>
      <div className="web-push-notification-container">
        <div className="mt-4 mb-4  ">
          <h6 className="web-push-notification-container-heading">
            Web Push Notification (WPN) -- Development
          </h6>
          <Divider className="web-push-notification-custom-divider" />
        </div>

        <div className=" col-sm-7 mt-4 mb-4 align-items-center web-push-notification-form ">
          {showMessage.show && (
            <ShowMessage
              type={showMessage.type}
              content={showMessage.message}
            />
          )}
          {loading ? (
            <Spinner />
          ) : (
            <Form
              form={form}
              layout="horizontal"
              // initialValues={""}
              // onValuesChange={onFormValueChange}
              // requiredMark={journeyData}
              onFinish={onFormSubmit}
            >
              <Form.Item
                label="Private Key"
                name={"privateKey"}
                rules={[
                  {
                    required: true,
                    message: "Please input Public Key",
                  },
                ]}
              >
                <TextInput maxLength={100} />
              </Form.Item>
              <Form.Item
                label="Public Key"
                name={"publicKey"}
                rules={[
                  {
                    required: true,
                    message: "Please input Public Key",
                  },
                ]}
              >
                <TextInput maxLength={100} />
              </Form.Item>
              <Form.Item
                label="Subject"
                name={"subject"}
                rules={[
                  {
                    required: true,
                    message: "Please input Subject",
                  },
                ]}
              >
                <TextInput  maxLength={100}/>
              </Form.Item>

              <div className="">
                <PrimaryButton
                  size="middle"
                  type="primary"
                  htmlType="submit"
                  style={{ border: "none", width: "80px" }}
                >
                  Save
                </PrimaryButton>
              </div>
            </Form>
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default WebPushNotification;
