import { FC, useState, useEffect,  } from "react"
import { Col, Form, Row } from "antd"
import FormItem from "antd/es/form/FormItem"
import Selector from "../../../../../../components/Selector/Selector"
import PrimaryButton from "../../../../../../components/Buttons/PrimaryButton/PrimaryButton"
import "./Facebook.css"
import {  useSelector } from "react-redux"
import TemplateLayout from "../../../layout/TemplateLayout/TemplateLayout"
import { useForm } from "antd/es/form/Form"
import docIcon from "../../../../../../images/uploadImageCustom.svg"
import Murabaha from "../../../../../../images/Murabaha Deposit .jpeg"
import Meem from "../../../../../../images/Meme_Mersoul cards.jpeg"
import CustomCheckbox from "../../../../../../components/CustomCheckbox/CustomCheckbox"
interface FacebookProps {}
const Facebook: FC<FacebookProps> = () => {
  const [form] = useForm()
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null)
  const campaignData = useSelector((state: any) => state.campaignData.data)
  const [formData, setFormData] = useState<any>({})
  const [selectedAdset, setSelectedAdset] = useState<any>(undefined)
  useEffect(() => {
    if (formData.actions?.length > 0) {
      setFormData((prevState: any) => ({
        ...prevState,
      }))
    }
  }, [formData.actions])
  
  
  useEffect(() => {
    if (campaignData.data) {
      setFormData((prevState: any) => ({
        ...prevState,
        ...campaignData.data,
      }))
      setSelectedTemplate(campaignData.data)
    }
  }, [campaignData])
  return (
    <div className="d-flex gap-md-5 ">
      <TemplateLayout
        leftItems={
          <div className="h-100">
            <div className=" py-2 facebook-parent-container g-0 ">
              <div className="   d-flex align-items-center   justify-content-center facebook-campaign-upload ">
                <img
                  loading="lazy"
                  src={
                    selectedAdset === "murabaha"
                      ? Murabaha
                      : selectedAdset === "Meme"
                      ? Meem
                      : docIcon
                  }
                  // src={Murabaha}
                  alt="Description of the image"
                  className="w-100 "
                  style={
                    selectedAdset
                      ? { maxHeight: 300, maxWidth: 200 }
                      : { maxHeight: 50, maxWidth: 50 }
                  }
                />
              </div>
            </div>
          </div>
        }
        rightItems={
          <div className="w-100">
            <Form
              colon={false}
              labelAlign="left"
              labelCol={{ flex: "180px" }}
              layout="horizontal"
              form={form}
            >
              <Row className="pt-3  justify-content-between">
                <h5> Facebook Campaign</h5>
              </Row>
              <Row className="pt-3">
                <Col span={24}>
                  <FormItem className="" label="">
                    <Selector
                      onChange={(value) => setSelectedAdset(value)}
                      options={[
                        {
                          label: "Murabaha Deposit investment ",
                          value: "murabaha",
                        },
                        {
                          label: "Meme_Mersoul cards ",
                          value: "Meme",
                        },
                      ]}
                      placeholder="Select Adset"
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row
                className="py-4 position-relative d-flex justify-content-center"
              >
                <Col className="" span={24}>
                  <p>Select user detail you would like to upload</p>
                </Col>
                <Col className="d-flex gap-4" span={24}>
                  <CustomCheckbox label="Email" />
                  <CustomCheckbox label="Phone" />
                </Col>
              </Row>
            </Form>
          </div>
        }
        footerItem={
          <div>
            <Row className="gap-2  d-flex justify-content-end">
              <PrimaryButton
                disabled={!selectedAdset}
                type="primary"
                htmlType="submit"
              >
                {selectedTemplate ? "Update" : "Save"}
              </PrimaryButton>
              <PrimaryButton
                style={{
                  color: "white",
                  background: "var( --color-red-main)",
                }}
              >
                Close
              </PrimaryButton>
            </Row>
          </div>
        }
      />
    </div>
  )
}

export default Facebook
