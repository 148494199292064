
export enum AudienceEnum {
  WHO = "who",
  WHAT = "what",
  WHEN = "when",
  WHERE = "where",
  CUSTOM_VARS = "Custom Vars",
  CUSTOM_SEGMENT = "Custom Segment",
  CUSTOM = "custom",
  GF = "gf",
  FS = "fs",
  LS = "ls",
  DEVICE = "Device",
  PLACE_LIST = "placelist",
  TIME_LIST = "timelist",
  BOOLEAN_LIST = "booleanlist",
  NETWORK_LIST = "networklist",
  DEVICE_LANGUAGE_LIST = "deviceLanguagelist",
  AND = "AND",
  OR = "OR",
  CATEGORY_TYPE = "category_type",
  OPERATOR = "operator",
  VALUE = "value",
  OPERAND = "operand",
  EVENTS = "Events",
  H = "h",
  HN = "hn",
  LIVE = "live",
  PAST = "past",
  QUERY = "query",
  TRAIT = "trait",
  SYNC = "sync",
  SPLIT = "split",
  SAVE = "save",
  COUNT = "count",
  INCOMING = "incoming",
  OUTGOING = "outgoing",
  CATEGORY = "category",
  ID = "id",
  CHILDREN = "children",
  GEO = "Geo",
  CTRAIT = "cTrait",
  MODELS = "models"
}
export enum PlatformEnum {
  ALL = "all",
  ANDROID = "android",
  IOS = "ios",
  WEB = "web",
}
export enum CustomDataEnum {
  BOOLEAN="boolean",
  NUMBER="number",
  TREE="json",
  IMAGE="image",
  DEVICE="device"
}

export enum PushNotificationMode {
  DEVELOPMENT = "Development",
  PRODUCTION = "Production"
}
export enum FcmDeliveryMethod {
  JSON = "JSON File",
  FCM_Key = "Server key (legacy)"
}
export enum SelectorModes{
  MULTIPLE="multiple",
  TAGS="tags"
}

export enum GoogleMapShapes{
  CIRCLE="circle",
  POLYGON="polygon",
  RECTANGLE="rectangle"
}

export enum SchedulerPayloadEnum{
  FREQUENCYTYPE = "ft",
  FREQUENCYMODE = "m",
  CAMPAIGNRUNAT = "r",
  WEEKLYDAYS = "d",
  MONTHLYDAYS = "dt",
  INTERVAL = "i",
  INTERVALUNIT = "iu",
  TIME = "t"
}

export enum FrequencyTypeEnum {
  NONE = "n",
  RECURRING = "r",
  ONMULTIPLEDATES = "mm"
}

export enum FrequencyModeTypesEnum {
  DAILY = "d",
  WEEKLY = "w",
  MONTHLY = "m",
  CONTINOUS = "c"
}

export enum IntervalUnitTypesEnum{
  DAYS = "d",
  HOURS = "h",
  MINUTES = "m",
  SECONDS ="s"
}
export enum HighChartTypeEnum{
  PIE="pie",
  SPLINE="spline",

}
export enum SSOTypeEnum{
  LOGIN_EMAIL="login_Email",
  LOGIN_SSO="login_SSO"
}
export enum adAuthTypeEnum{
  SAML="saml",
  AD="ad",
  DISABLED="disabled"
}

export enum MinuteEnum {
  TWENTY_FOUR_HOUR = 24 * 60 - 15,
  FIFTEEN_MINUTE_INTERVAL = 15
}

export enum DayAndTimeSelectorEnum {
  ALLWEEK = "ALLWEEK",
  ALLDAY = "ALLDAY",
  SPECIFIC = "SPECIFIC"

}

export enum DeliveryTypeEnum {
  ALL = 'a',
  DIRECTPUSH = "d",
  INBOXONLY = "i"
}

export enum TimeUnitEnum {
  SECONDS = "Seconds",
  MINUTES = "Minutes",
  HOURS = "Hours",
  DAYS = "Days",
  WEEKS="Weeks",
  MONTHS="Months"
  
}

export enum CampaignCategoryEnum {
  ENGAGEMENT = "e",
  GEOFENCE = "g",
  UNINSTALL = "u",
  TRANSACTIONAL = "t"
}

export enum CampaignStatusEnum {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  PAST = "PAST"
}

export enum CampaignTimeRangeEnum{
  STARTTIME = "0800" ,
  ENDTIME = "2000"

}

export enum LaunchCampStagUnitTimeEnum {
  DAYS = "dd",
  HOURS = "hh",
  MINUTES = "mm"
}

export enum CampaignChannelTypeEnum {
  ALL = "All",
  SMS = "SMS",
  IN_APP = "IN-APP",
  PUSH =  "PUSH",
  WEB_PUSH= "WEB_PUSH",
  EMAIL = "EMAIL",
  WHATSAPP ="WHATSAPP",
  WEB_POPUP="WEB_POPUP",
  NATIVE = "NATIVE"


}

export enum E_OperatorEnum{
  AND = "AND",
  OR = "OR",
}
export enum CustomUnitsEnum {
  YESTERDAY = "Yesterday",
  TODAY = "Today",
  THIS_WEEK = "This Week",
  LAST_WEEK = "Last Week",
  THIS_MONTH = "This Month",
  LAST_MONTH = "Last Month",
  CUSTOM = "Custom",
}
export enum DateFormatEnum{
  DEFAULT_FORMAT_="MMM D, YYYY",
  ISO_FORMAT="YYYY-MM-DD",

} 
export enum DataTypeEnum {
  STRING = "string",
  NUMBER = "number",
  BOOLEAN = "boolean",
  OBJECT = "object",
  ARRAY = "array",
  NULL = "null",
  UNDEFINED = "undefined",
  FUNCTION = "function",
}

export enum KeyboardKeysEnum {
  ENTER = 'Enter',
}
export enum FunnelPlatformEnum {
  ALL = "all",
  ANDROID = "Android",
  IOS = "IOS",
  WEB = "Web",
}
export enum EDStatusEnum{
ENABLE="enable",
DISABLE="disable"
}

export enum SiteSettingsEnum{
  DISABLED="disabled",
  ACTIVE_DIRECTORY="ad",
  SAML_BASED="saml"

}

export enum AppNavSettingEnum {
  APP_KEYS = "app_keys",
  API_GATEWAY = "api_gateway",
  APP_SETTINGS = "app_settings",
  EMAIL = "email",
  SMS = "sms",
  SECURITY = "security",
  KILL_SWITCH = "kill_switch",
  CHANNEL = "channel",
  APP_INBOX = "app_inbox",
  FILE_ENCRYPTION = "file_encryption",
  UTM_SETTING = "utm_setting",
  PARTNER_SETTING = "partner_setting",
  ALLOWLIST_SETTING = "allowList_setting",
  PUSH_NOTIFICATION_SETTINGS = "push_notification_settings",
  FEATURES = "features",
  PUSH_NOTIFICATION = "push_notification",
  CAMPAIGN_ANALYTICS = "campaign_analytics",
  CAMPAIGN_CONFIGURATOR = "campaign_configurator",
  SITE_SETTING = "site_setting"
}
export enum ApproveDeclineEnum{
  APPROVED="Approved",
  DECLINED="Declined"
}

export enum SuccessErrorEnum{
  SUCCESS="success",
  ERROR="error"
}
