import React, { FC, useState } from "react"
import PrimaryButton from "../../../components/Buttons/PrimaryButton/PrimaryButton"
import CustomTable from "../../../components/CustomTable/CustomTable"
import { PlusOutlined } from "@ant-design/icons"
import Search from "../../../components/Inputs/Search/Search"
import { columns, data } from "./data"
import { useLocation, useNavigate } from "react-router-dom"
import FormItem from "antd/es/form/FormItem"
import Selector from "../../../components/Selector/Selector"
interface RewardListProps {}

const RewardList: FC<RewardListProps> = ({}) => {
  const navigate = useNavigate()
  const [dataCopy, setDataCopy] = useState<typeof data>(data)

  const handleSearch = (value: string) => {
    const regex = new RegExp(value, "i")
    console.log(data)
    const temp: typeof data = data.filter((user: any) => {
      return user.email ? regex.test(user.email) : false
    })
    setDataCopy(temp)
  }
  const handleButtonClick = () => {
    // Navigate to the desired URL when the button is clicked
    navigate("/dashboard/loyalty/rewards/create")
  }
  return (
    <div className="p-4 mt-2" style={{ background: "white" }}>
      <div className="d-flex justify-content-between">
        <h5>List of rewards</h5>
        <div className="d-flex gap-3">
          <PrimaryButton>Configuration</PrimaryButton>
          <PrimaryButton type="primary" onClick={handleButtonClick}>
            Add reward
          </PrimaryButton>
        </div>
      </div>
      <div className="d-flex py-3 justify-content-between">
        <PrimaryButton>
          <span className="px-3 d-flex align-items-center">
            <PlusOutlined /> <span className="px-2">Add filter</span>
          </span>
        </PrimaryButton>
        <Search
          onChange={(e) => handleSearch(e.target.value)}
          style={{ width: "225px" }}
        />
      </div>
      <div className="w-100 py-3">
        <CustomTable
          tableSize="small"
          dataSource={dataCopy}
          columns={columns}
          pagination={{ pageSize: 12, simple: false, size: "small" }}
        />
      </div>
      
    </div>
  )
}

export default RewardList
