// serializationUtils.ts
import { JsonSerializer } from 'typescript-json-serializer';

/**
 * Serializes an object to a JSON string.
 * @param data - The object to serialize.
 * @param clazz - The model class type of the object.
 * @returns The serialized JSON object.
 */
export function serialize<T>(data: T): any {
try {
  const serializer = new JsonSerializer();
  
  // Ensure data is not undefined or null
  if (data === undefined || data === null) {
    throw new Error("Cannot serialize undefined or null data");
  }
  
  // Serialize data using the provided class type
  const serializedData = serializer.serialize(data);

  return serializedData;
}
  catch (error) {
    throw new Error(`Serialization error: ${error}`);
  }
}

/**
 * Deserializes a JSON string to an object of a given class type.
 * @param json - The JSON string to deserialize.
 * @param clazz - The class type to deserialize to.
 * @returns The deserialized object.
 */
export function deserialize<T>(json: string | object , clazz: { new (...args: any[]): T }): T {
try{
  const serializer = new JsonSerializer();
  
  // Ensure the deserialization result matches the expected type
  const result = serializer.deserialize(json, clazz);
  
  if (result === null || typeof result !== 'object') {
    throw new Error('Deserialization resulted in a non-object value');
  }
  
  return result as T;
}
catch(error){
    throw new Error(` Deserialization error: ${error}`); 
}
}
