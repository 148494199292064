import { useEffect, useState } from "react";
import "./UserManagement.css";
import deleteIcon from "../../images/trashpink.svg";
import keyIcon from "../../images/key.svg";
import unlockIcon from "../../images/unlock.svg";
import lockIcon from "../../images/lock.svg";
import "../../global.css";
import { SizeType } from "antd/es/config-provider/SizeContext";
import Selector from "../../components/Selector/Selector";
import Search from "../../components/Inputs/Search/Search";
import PrimaryButton from "../../components/Buttons/PrimaryButton/PrimaryButton";
import ToggleBtn from "../../components/Buttons/ToggleBtn/ToggleBtn";
import {
  getData,
  updateApproved,
  updateRole,
  resetPassword,
  unblock,
  deleteRole,
} from "../../helper/user";
import CopyModal from "../../components/Models/CopyModel/CopyModel";

import ShowMessage from "../../components/ShowMessage/ShowMessage";
import AddUserModel from "../../components/Models/AddUserModel/AddUserModel";
import ConfirmPopUp from "../../components/PopUp/ConfirmPopUp/ConfirmPopUp";
import CustomTable from "../../components/CustomTable/CustomTable";
import { useSelector } from "react-redux";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import CustomToolTip from "../../components/CustomToolTip/CustomToolTip";
import { getToken } from "services/getToken";
type propTypes = {
  pageSize?: number;
  tableSize: SizeType;
};

function UserManagement({ pageSize, tableSize }: propTypes) {
  const { t } = useTranslation();
  const searchLabel=t("searchLabel")
  const [entries, setEntries] = useState(pageSize);
  const [copyModelVisibility, setCopyModelVisibility] = useState(false);
  const [addUserModel, setAddUserModel] = useState(false);
  const [copyModelInputValue, setCopyModelInputValue] = useState("");
  const [usersData, setUsersData] = useState<any>([]);
  const [usersDataCopy, setUsersDataCopy] = useState<any>([]);
  const [search,setSearch]=useState("")
  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  });
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const {appId} = useSelector((state: any) => state.activeApp);
const isAppAdmin=()=>{
return loginUser?.user_role?.admin?.includes(appId)?true:false
}
  async function getUpdatedData() {
    try {
      const apiKey = loginUser.api_key;
      if (apiKey && appId) {
        const data = await getData(apiKey, appId);
        setUsersData(()=>data);
        handleSearch(search,data)
      }
    } catch (error) {console.log(error)}
  }
  //on delete user popup confirm
  const confirm = async (id: string) => {
    try {
      const payload = {
        userId: id,
        appId: appId,
        apiKey: loginUser.api_key,
        loginUserId: loginUser._id,
      };
      await deleteRole(payload);
      setShowMessage({
        type: "success",
        message: t("userDeletedSuccessfullyLabel"),
        show: true,
      });
      getUpdatedData();
    } catch (error) {
      setShowMessage({
        type: "error",
        message:t("somethingWrongMsg"),
        show: true,
      });
    }
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false });
    }, 2000);
  };

  useEffect(() => {
    getUpdatedData();

  }, [loginUser]);
  useEffect(() => {
    getToken(appId, loginUser.api_key);

  }, [])
  
  const showAddUserModel = () => {
    setAddUserModel(true);
  };
  // when the user click on the delete icon
  const changeStatus = async (value: any, id: any, email: string) => {
    const payload = {
      userId: id,
      email,
      newState: value,
      apiKey: loginUser.api_key,
      loginUserId: loginUser._id,
      appId,
    };
    await updateApproved(payload);
    getUpdatedData();
  };
  //  when the user changes role
  const changeRole = async (id: any, value: string) => {
    const payload = {
      id,
      newRole: value,
      appId,
      apiKey: loginUser.api_key,
      loginUserId: loginUser._id,
      loginUserEmail: loginUser.email,
    };
    await updateRole(payload);
    getUpdatedData();
  };
  const modifyPassword = async (id: any, email: string) => {
    try {
      const payload = {
        id,
        email,
        appId,
        apiKey: loginUser.api_key,
        loginUserId: loginUser._id,
      };
      const data = await resetPassword(payload);
      if (data.inputValue !== "0") {
        setCopyModelInputValue(data.inputValue);
        setCopyModelVisibility(true);
      }
    } catch (error) {console.log(error)}
  };
  const Unblock = async (id: any, email: string) => {
    const payload = {
      id,
      email,
      appId,
      apiKey: loginUser.api_key,
      loginUserId: loginUser._id,
    };
    const data = await unblock(payload);
    setShowMessage({
      ...showMessage,
      message: `${data.email} ${t("isUnblockedSuccessfullyMsg")}`,
      show: true,
    });
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false });
    }, 2000);
    getUpdatedData();
  };

  //Name of columns and default enteries
  const columns: any = [
    {
      title: t("emailLabel"),
      dataIndex: "Email",
      className: "fix",
      width: 80,
    },
    {
      title: t("usernameLabel"),
      dataIndex: "Username",

      width: 150,
    },

    {
      title: t("createdOnLabel"),
      dataIndex: "Created_On",
      width: 40,
    },
    {
      title: t("modifiedOnLabel"),
      dataIndex: "Modified_On",
      width: 100,
    },
    // {
    //   title: t("roleLabel"),
    //   dataIndex: "Role",
    //   width: 40,
    //   // render: ({ _id,}: { _id: any;}) => {
    //   //   return (
    //   //      <AddUserModel
    //   //   visibility={addUserModel}
    //   //   setVisibility={setAddUserModel}
    //   //   getUpdatedData={getUpdatedData}
    //   // />
    //   //   )}
    // },
    {
      title: t("roleLabel"),
      dataIndex: "Role",
      width: 40,
      render: (text: string, record: any) => {
        if (record.Role) {
          // If role data is available, render the role value
          return <span>{record.Role}</span>;
        } else {
          // If role data is not available, render the AddUserModel component
          return (
            <PrimaryButton
            size="middle"
            type="primary"
            style={{ border: "none" }}
            onClick={showAddUserModel}
          >
          {t("addUserLabel")}
          </PrimaryButton>
          );
        }
      }
    },
     {
      title: t("changeRoleLabel"),
      dataIndex: "Change_Role",
      width: 100,
      className: "item-align",

      render: ({ _id,}: { _id: any;}) => {
        return (
          (loginUser?.global_admin || isAppAdmin()) && (
            <Selector
              defaultValue={"Select"}
              onChange={(value) => {
                changeRole(_id, value);
              }}
              style={{ width: 90 }}
              options={[
                {
                  value: "admin",
                  label: "Admin",
                },
                {
                  value: "manager",
                  label: "Manager",
                },
                {
                  value: "marketer",
                  label: "Marketer",
                },
              ]}
            />
          )
        );
      },
    },
    {
      title: t("removeAccessLabel"),
      dataIndex: "Action",
      width: 120,
      className: "item-align",

      render: (id: any) => {
        return (
          (loginUser?.global_admin || isAppAdmin()) &&(
            <ConfirmPopUp
              title="Delete User?"
              onConfirm={confirm}
              id={id}
              icon={deleteIcon}
            >
              <CustomToolTip title={t("deleteRoleMsg")}>
              <PrimaryButton>
                <img src={deleteIcon} alt=""></img>
              </PrimaryButton>
              </CustomToolTip>
            </ConfirmPopUp>
          )
        );
      },
    },
    // approve button visible to global admin and app admin
    ...(loginUser?.global_admin || isAppAdmin()  ?
    [{
      title: t("statusLabel"),
      dataIndex: "Status",
      width: 100,
      className: "item-align",
      render: (value: { isApproved: boolean; _id: any; email: string }) => {
        return (
            <ToggleBtn
              isApproved={value.isApproved}
              onChange={(e: any) => {
                changeStatus(e.target.value, value._id, value.email);
              }}
            />
          )
      },
    }] : []
    ),
    {
      title: t("resetPasswordShortLabel"),
      dataIndex: "Reset_Password",
      width: 120,

      className: "item-align",
      render: (value: any) => {
        return (
          (loginUser?.global_admin || isAppAdmin()) && (
            <CustomToolTip title={t("resetLabel")}>
            <PrimaryButton
              onClick={() => modifyPassword(value._id, value.email)}
            >
              <img
                style={{ transform: "rotate(20deg)" }}
                src={keyIcon}
                alt=""
              ></img>
            </PrimaryButton>
            </CustomToolTip>
          )
        );
      },
    },
    {
      title: t("unBlockLabel"),
      dataIndex: "Unblock",
      width: 40,

      className: "item-align",
      render: (value: any) => {
        return (
          (loginUser?.global_admin || isAppAdmin()) && (
            <CustomToolTip title={t("unBlockLabel")}>
            <PrimaryButton onClick={() => Unblock(value._id, value.email)}>
              {value.isBlock ? (
                <img src={lockIcon} alt=""></img>
                ) : (
                  <img src={unlockIcon} alt=""></img>
                  )}
            </PrimaryButton>
                  </CustomToolTip>
          )
        );
      },
    },
  ];

  const handleSearch = (value:string,data:any=null) => {
    setSearch(()=>value)
    const originalData=data?data:usersData
    const regex = new RegExp(value, "i");
    setUsersDataCopy(
      originalData?.filter((user: any) => {
        return user.Email ? regex.test(user.Email) : false;
      })
    );
  };
  return (
    <div className="container-fluid py-3 px-sm-3 px-1 user-table-container">
      <Helmet>
        <title>Appice | All Users</title>
      </Helmet>
      <div className=" w-100   overflow-hidden">
        <div className="py-4 ">
          <CopyModal
            visibility={copyModelVisibility}
            setVisibility={setCopyModelVisibility}
            inputValue={copyModelInputValue}
          />

          <AddUserModel
            visibility={addUserModel}
            setVisibility={setAddUserModel}
            getUpdatedData={getUpdatedData}
            // setShowMessage={setShowMessage}
          />
          {/* //show message  */}
          {showMessage.show && (
            <ShowMessage
              type={showMessage.type}
              content={showMessage.message}
            />
          )}
          <div className="d-flex flex-row flex-nowrap w-100">
            <div className="w-100  d-flex">
              <Search
                placeholder={searchLabel}
                onChange={(e)=>handleSearch(e.target.value)}
                size="middle"
                style={{
                  width: "100%",
                  maxWidth: "300px",
                  marginRight: "10px",
                }}
              />
              <Selector
                onChange={(value) => {
                  setEntries(value);
                }}
                style={{ width: "auto", marginRight: "10px" }}
                size="middle"
                defaultValue={pageSize}
                options={[
                  {
                    value: 6,
                    label: 6,
                  },
                  {
                    value: 12,
                    label: 12,
                  },
                  {
                    value: 18,
                    label: 18,
                  },
                ]}
              />
            </div>
            <div>
              {appId && (
                <PrimaryButton
                  size="middle"
                  type="primary"
                  style={{ border: "none" }}
                  onClick={showAddUserModel}
                >
                {t("addUserLabel")}
                </PrimaryButton>
              )}
            </div>
          </div>
        </div>

        <CustomTable
          columns={columns}
          dataSource={usersDataCopy.map((user: any) => ({
            ...user,
            key: user.Key,
          }))}
          size={tableSize}
          pagination={{ pageSize: entries, simple: false }}
          scroll={{ x: 1390 }}
        />
      </div>
    </div>
  );
}
export default UserManagement;
UserManagement.defaultProps = {
  tableSize: "small",
  pageSize: 6,
};
