import CustomAvatar from "components/CustomAvatar/CustomAvatar";
import { FC, useEffect, useState } from "react";
import "./Customer360.css";
import ThreeSixtyCard from "./components/Customer360Card/Customer360Card";
import Selector from "components/Selector/Selector";
import { data } from "./data";
import { t } from "i18next";
import { CONSTANTS } from "utils/constants/constants";
import CustomHighChart from "components/Charts/CustomHighChart/CustomHighChart";
import { HighChartTypeEnum } from "utils/enums";
import { useLocation, useParams } from "react-router-dom";
import centralApi from "services/centralApi";
import { useSelector } from "react-redux";
import { formatDate } from "helperFunctions/date";
import { Helmet } from "react-helmet";
import { API_ENDPOINTS } from "utils/constants";
import UserInteractions from "./components/UsersInteractions/UsersInteractions";
import {UserProfile} from '../../utils/models/index'

const Customer360: FC = () => {
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const [userDetails, setUserDetails] = useState<any>();
  const [userInfo , setUserInfo] = useState<any>()
  const params = useParams(); // This will get the ID from the URL path
  const location = useLocation();
  const { id } = useParams();
  // Parse query parameters
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');

  
  //fetch user details from api
  const fetchAppUserDetails = async () => {
    let payload ;
    //checks if customer id is present in params it will send cci in payload for search
    if(type === 'ci'){
      payload = {
        api_key: loginUser.api_key,
        app_id: appId,
        ci: id,
      };
    }
    //if customer id is not present in parasm it will sedn did as a payload for search
    else{
      payload = {
        api_key: loginUser.api_key,
        app_id: appId,
        did: id,
      };
    }   
    const response  : UserProfile = await centralApi(
      "GET",
      API_ENDPOINTS.GET_USER_DETAILS_URL,
      null,
      payload
    );
     setUserDetails(response);
  };
 
  // debit transaction data 
 const debit_account_engagement =[{ 
    color: "#A8CAFF",
    value: userInfo?.debitOnlineCount,
    label: t("onlineCountLabel"), 
   },
   { 
     color: "#A8CAFF", 
     value: userInfo?.debitOnlineSum,
     label: t("onlineSumLabel"), 
    },
   { 
     color: "#FFD8E4",
      value: userInfo?.debitOfflineCount,
       label: t("offlineCountLabel"), 
     },
   { 
     color: "#FFD8E4",
      value: userInfo?.debitOfflineSum,
       label: t("offlineSumLabel"),
    }
  ]

  //credit transaction data
  const credit_account_engagement =[{ 
    color: "#A8CAFF",
    value: userInfo?.creditOnlineCount,
    label: t("onlineCountLabel"), 
   },
   { 
     color: "#A8CAFF", 
     value: userInfo?.creditOnlineSum, 
     label: t("onlineSumLabel"), 
    },
   { 
     color: "#FFD8E4",
      value: userInfo?.creditOfflineCount,
       label: t("offlineCountLabel"), 
     },
   { 
      color: "#FFD8E4",
      value: userInfo?.creditOfflineSum,
      label: t("offlineSumLabel"),
    }
  ]
 
useEffect(() => {
  if(userDetails){
    const modifiedEventData = userDetails?.eventHistory?.el.map((event: any) => {
      // Construct avaibleString by iterating over segment keys
      const avaibleString = Object.entries(event?.segment || {}).map(([key, value]) => {
          if (value) {
              // Format the key and value pair as "key:value"
              return `${key}:${value}`;
          }
          return null;
      }).filter(Boolean).join(", "); // Join with comma and filter out null values
  
      return {
          label: formatDate(event.p, "DD/MM/YY:HH:mm"),
          children: `${event.k}, ${avaibleString}`,
      };
  });
    const modifiedTransactionData = userDetails?.customProperties?.transaction_info?.map((transaction : any) => {
      return {
        color : transaction.type == "D" ? "red" : "green" ,
        label: formatDate(transaction.date, "DD/MM/YY:HH:mm"),
        children: `${transaction.currency} ${transaction.amount},Trans_Mode: ${transaction.mode}`
      };
    });
  
    setUserInfo({
      cifId : userDetails?.userId || data.cifId,
      c360Id : userDetails?.x360 || data.c360Id,
      name: userDetails?.name || data?.name,
      icon : getInitials(userDetails?.name ) || data?.icon,
      firstSeen: userDetails?.firstSeen ? formatDate(userDetails?.firstSeen * 1000 , "DD/MM/YY hh:mm A") : data?.firstSeen,
      lastSeen: userDetails?.lastSeen ? formatDate(userDetails?.lastSeen * 1000 , 'DD/MM/YY hh:mm A') : data?.lastSeen,
      city : userDetails?.geo?.cty || data?.city,
      state : userDetails?.geo?.region || data ?.state,
      eventList : modifiedEventData,
      transactionData : modifiedTransactionData,
      //debit transactions info
      debitOnlineCount : userDetails?.customProperties?.count_of_online_debit_transactions || "0",
      debitOnlineSum : userDetails?.customProperties?.sum_of_online_debit_transactions_amount || "0",
      debitOfflineCount : userDetails?.customProperties?.count_of_offline_debit_transactions || "0",
      debitOfflineSum : userDetails?.customProperties?.sum_of_offline_debit_transactions_amount || "0",
      //credit transactions info
      creditOnlineCount : userDetails?.customProperties?.count_of_online_credit_transactions || "0", 
      creditOnlineSum : userDetails?.customProperties?.sum_of_online_credit_transactions_amount || "0", 
      creditOfflineCount :userDetails?.customProperties?.count_of_offline_credit_transactions || "0",
      creditOfflineSum : userDetails?.customProperties?.sum_of_offline_credit_transactions_amount || "0",
    });
  }
}, [userDetails]);
  useEffect(() => {
    //fetch user data if user is login
    if (loginUser.api_key) {

      fetchAppUserDetails();
    }
  }, [loginUser]);



      /**
     * Generates an abbreviation from the full name based on the initials.
     * @param fullName - The full name from which to generate the abbreviation.
     * @returns A string with the initials of the name parts.
     */
    function getInitials(fullName: string | undefined): string {
      // Check if fullName is undefined, null, or an empty string
      if (!fullName || typeof fullName !== 'string' || fullName.trim() === '') {
        return ''; // Return an empty string if the input is invalid
      }
      // Split the full name into parts
      const nameParts = fullName.split(' ');

      // Get the first letter of each part and join them
      const initials = nameParts
        .filter(part => part.length > 0) // Ensure the part is not empty
        .map(part => part.charAt(0).toUpperCase()) // Get the first character and convert to uppercase
        .join('');

      return initials;
    }
    const interactionItems = [
      {
        title: t("behaviouralDataLabel") ,
        eventData: userInfo?.eventList
      },
      {
        title: t("transactionalDataLabel"),
        eventData:  userInfo?.transactionData
      },
    ];
  return (
    <div className="c-three-sixty-main-container">
      <Helmet>
        <title>Appice | C360</title>
      </Helmet>
      {/* -----header container starts from here----- */}
      <div className="d-flex justify-content-between my-3">
        <div className="d-flex gap-lg-3 gap-2  c-three-sixty-header-container ">
          <div>
            <CustomAvatar
              className="c-three-sixty-header-avatar"
              shape="square"
              size={100}
            >
              {userInfo?.icon}
            </CustomAvatar>
          </div>
          <div className="d-flex flex-column justify-content-between py-2">
            <h6>{userInfo?.name}</h6>
            <div className="d-flex flex-column gap-2 ">
              <div className="d-flex justify-content-between gap-2">
                <span>{t("cifIdLabel")}</span>
                <span>{userInfo?.cifId}</span>
              </div>
              <div className="d-flex justify-content-between gap-2">
                <span>{t("c360IdLabel")}</span>
                <span>{userInfo?.c360Id}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex w-50 justify-content-between">
          <ThreeSixtyCard
            cardType={CONSTANTS.HEADER}
            initialKey={t("firstSeenLabel")}
            initialValue={userInfo?.firstSeen}
            secondaryKey={t("lastSeenLabel")}
            secondaryValue={userInfo?.lastSeen}
          />
          <ThreeSixtyCard
            cardType={CONSTANTS.HEADER}
            initialKey={t("cityLabel")}
            initialValue={userInfo?.city}
            secondaryKey={t("stateLabel")}
            secondaryValue={userInfo?.state}
          />
        </div>
        
      </div>
      {/*-------SelectAccount starts here------- */}
      <div className="w-100 pt-3 d-flex align-items-center">
      <span className="me-3 fw-bold">Select Account</span>
      <Selector placeholder="SBA" style={{ width: '200px' }} />
     </div>
      {/* -----header container ends here----- */}
      {/* -----Customer account engagement debit transactions starts from here----- */}

      <div className="w-100 c-three-sixty-full-row-container c-three-sixty-customer-account_engagement-container my-4 p-4">
      <div className="col-12 m-0 p-0 d-flex justify-content-between pb-3">
          <div>
          <h6 className="m-0">{t("accountEngagmentLabel")}</h6>
          <span className="m-0">{t("debitTransactionsLabel")}</span>
          </div>
          <div>
            <Selector placeholder="Last 1 mth" />
          </div>
        </div>
        <div className="d-flex w-100 justify-content-around pt-4">
          {debit_account_engagement?.map((item, index) => {
            return (
              <ThreeSixtyCard
                 key={index}
                 cardType={CONSTANTS.ACCOUNT_ENGAGEMENT}
                 value={item?.value}
                 label={item?.label}
                 color={item?.color}
              />
            );
          })}
        </div>
      </div>
       {/* -----Customer account engagement credit transactions starts from here----- */}

       <div className="w-100 c-three-sixty-full-row-container c-three-sixty-customer-account_engagement-container my-4 p-4">
      <div className="col-12 m-0 p-0 d-flex justify-content-between pb-3">
          <div>
          <h6 className="m-0">{t("accountEngagmentLabel")}</h6>
          <span className="m-0">{t("creditTransactionLabel")}</span>
          </div>
          <div>
            <Selector placeholder="Last 1 mth" />
          </div>
        </div>
        <div className="d-flex w-100 justify-content-around pt-4">
          {credit_account_engagement?.map((item, index) => {
            return (
              <ThreeSixtyCard
                 key={index}
                 cardType={CONSTANTS.ACCOUNT_ENGAGEMENT}
                 value={item?.value}
                 label={item?.label}
                 color={item?.color}
              />
            );
          })}
        </div>
      </div>
      {/* -----Customer Account engagement  container ends here----- */}
      {/* -----Digital Engagement container starts from here----- */}

      <div className=" mx-0  row c-three-sixty-full-row-container c-three-sixty-customer-engagement-container my-4 p-4">
        <div className="col-12 m-0 p-0 d-flex justify-content-between pb-3">
          <div>
            <h6 className="m-0">{t("digitalEngagemnetLabel")}</h6>
          </div>
          <div>
            <Selector placeholder="Last 3 mth" />
          </div>
        </div>
        <div className="col-6 m-0 p-0 d-flex flex-column gap-3">
          {data.engagement.visits.map((item, index) => {
            return (
              <span key={index} className="d-flex gap-3">
                <span  className={"digital-engagment-span"}>{item.title} :</span>
                <span>{item.value}</span>
              </span>
            );
          })}
        </div>
        <div className="col-6">
          <CustomHighChart
            options={{
              chart: {
                type: HighChartTypeEnum.SPLINE,
                height: 250,
                // Set the desired height in pixels
              },
              title: {
                text: undefined,
              },
              xAxis: {
                categories: CONSTANTS.MONTHS_FIRST_LETTER_LIST,
                tickInterval: 1,
                title: {
                  text: "",
                },
              },
              legend: {
                enabled: false, // Set to false to hide the legend
              },
              navigation: {
                buttonOptions: {
                  enabled: false, // Set to false to hide the hamburger icon
                },
              },
              yAxis: {
                title: {
                  text: "",
                },
                tickInterval: -1,

                lineWidth: 1,
                gridLineWidth: 0,
              },
              series: [
                {
                  name: "",
                  data: data.engagement.data,
                  lineWidth: 1,
                  marker: {
                    enabled: false,
                  },
                },
              ],
            }}
          />
        </div>
      </div>
      {/* -----Engagement container ends here----- */}
      {/*-----------User/Interaction component starts from here*/}
      <div>
      <UserInteractions items={interactionItems} />
    </div>
      
    </div>
    
  );
};

export default Customer360;
