import { FC, useState, useEffect } from "react"
import { Col, Form, Row} from "antd"
import FormItem from "antd/es/form/FormItem"
import Selector from "../../../../../../components/Selector/Selector"
import PrimaryButton from "../../../../../../components/Buttons/PrimaryButton/PrimaryButton"
import TextInput from "../../../../../../components/Inputs/TextInput/TextInput"
import "./Google.css"
import TemplateLayout from "../../../layout/TemplateLayout/TemplateLayout"
import { useForm } from "antd/es/form/Form"
import docIcon from "../../../../../../images/uploadImageCustom.svg"
import Murabaha from "../../../../../../images/GoogleAd1.png"
import Meem from "../../../../../../images/GoogleAd2.png"
import CustomCheckbox from "../../../../../../components/CustomCheckbox/CustomCheckbox"
import RadioButton from "../../../../../../components/Buttons/RadioButton/RadioButton"
interface FacebookProps {}
const Google: FC<FacebookProps> = () => {
  const [form] = useForm()
  const [selectedAdset, setSelectedAdset] = useState<any>(undefined)
  return (
    <div className="d-flex gap-md-5 ">
      {/* <div className="mx-3">  */}
      <TemplateLayout
        leftItems={
          <div className="h-100">
            <div className=" py-2 google-parent-container d-flex align-items-center justify-content-center g-0 ">
              <div className="   d-flex align-items-center mt-4  justify-content-center google-campaign-upload ">
                <img
                  loading="lazy"
                  src={
                    selectedAdset === "murabaha"
                      ? Murabaha
                      : selectedAdset === "Meme"
                      ? Meem
                      : docIcon
                  }
                  alt="Description of the image"
                  className="w-100"
                  style={
                    selectedAdset
                      ? { maxHeight: 400, maxWidth: 300 }
                      : { maxHeight: 50, maxWidth: 50 }
                  }
                />
              </div>
            </div>
          </div>
        }
        rightItems={
          <div className="w-100">
            <Form colon={false} labelAlign="left" layout="vertical" form={form}>
              <Row className="pt-3  justify-content-between">
                <h5> Google Campaign </h5>
              </Row>
              <Row className="pt-3">
                <Col span={24}>
                  <FormItem className="" label="Select Adset">
                    <Selector
                      onChange={(value) => setSelectedAdset(value)}
                      options={[
                        {
                          label: "Murabaha Deposit investment ",
                          value: "murabaha",
                        },
                        {
                          label: "Save with meem credit card",
                          value: "Meme",
                        },
                      ]}
                      placeholder="Select Adset"
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <h6 className="my-2">Audience type</h6>
                <Col className="d-flex flex-column gap-2" span={24}>
                  <RadioButton name="Add qualified users to existing audience" />
                  <div className="ps-4">
                    <Selector
                      placeholder="Select"
                      options={[
                        {
                          label: "GoogleAds Audience List 1",
                          value: "GoogleAds Audience List 1",
                        },
                      ]}
                      style={{ width: 400 }}
                    />
                    <Col className="pt-3" span={24}>
                      <p className="p-0">
                        Select user detail you would like to upload
                      </p>
                    </Col>
                    <Col className="d-flex gap-4" span={24}>
                      <CustomCheckbox label="Email" />
                      <CustomCheckbox label="Phone" />
                    </Col>
                  </div>
                </Col>
                <Col className="d-flex flex-column gap-2 mt-2" span={24}>
                  <RadioButton name="Create new audience on Google Ads account with qualified users" />
                  <div className="ps-4">
                    <FormItem label="Name" style={{ width: 400 }}>
                      <TextInput placeholder="Name" />
                    </FormItem>
                    <FormItem label="Description" style={{ width: 400 }}>
                      <TextInput placeholder="Description" />
                    </FormItem>
                    <FormItem
                      label="Membership Duration"
                      className="d-flex"
                      style={{ width: 200 }}
                    >
                      <TextInput
                        className="w-50"
                        placeholder="Membership Duration"
                      />
                      <span className="mx-2">Days</span>
                    </FormItem>
                    <Col className="pt-1" span={24}>
                      <p className="p-0">
                        Select user detail you would like to upload
                      </p>
                    </Col>
                    <Col className="d-flex gap-4" span={24}>
                      <CustomCheckbox label="Email" />
                      <CustomCheckbox label="Phone" />
                    </Col>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        }
        footerItem={
          <div>
            <Row className="gap-2  d-flex justify-content-end">
              <PrimaryButton
                disabled={!selectedAdset}
                type="primary"
                htmlType="submit"
              >
                {"Save"}
              </PrimaryButton>
              <PrimaryButton
                style={{
                  color: "white",
                  background: "var( --color-red-main)",
                }}
              >
                Close
              </PrimaryButton>
            </Row>
          </div>
        }
      />
    </div>
  )
}

export default Google
