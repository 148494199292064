import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../../constant";
import centralApi from "../../../services/centralApi";
interface paramType{
    api_key:string,
     app_id:string,
      template_id: string,
  }
export const fetchTemplateById = createAsyncThunk(
  "templates/fetchTemplateById",
  async (params:paramType) => {
    return centralApi(
      "GET",
      "o/templates/getTemplatesById",
      null,
      params
    ).then((data) => { 
      return data;
    });
  }
);
const initialState:any= {
  data: null,
  status: STATUS.IDLE,
}
const templateSlice = createSlice({
  name: "templateById",
 initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchTemplateById.pending, (state) => {
      state.status = STATUS.LOADING;
    });
    builder.addCase(fetchTemplateById.fulfilled, (state,action) => {
      state.status = STATUS.IDLE;
      state.data=action.payload
    });
    builder.addCase(fetchTemplateById.rejected, (state) => {
      state.status = STATUS.ERROR;
    });
  },
  reducers: {
    setSelectedTemplate:(state,action)=>{
      state.data=action.payload
    }
  },
});
export default templateSlice.reducer;
export const {setSelectedTemplate}=templateSlice.actions


