import React from 'react';
import { TimePicker } from 'antd';
import type { TimePickerProps } from 'antd/lib/time-picker';
import dayjs, { Dayjs } from 'dayjs'; // Import Dayjs type explicitly
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

interface TimePickerSelectorProps extends Omit<TimePickerProps, 'onChange' | 'value'> {
  onChange: (time: Dayjs | null, timeString: string) => void; // Adjust onChange to match Ant Design's TimePickerProps
  value?: Dayjs | null;
  use12Hours?: boolean;
}

const TimePickerSelector: React.FC<TimePickerSelectorProps> = ({ onChange,value ,use12Hours, ...rest }) => {
  const handleChange = (time: any, timeString: string) => {
 if(timeString){
   const formattedTime: string = use12Hours ? dayjs(timeString, 'h:mm A').format('HH:mm') : timeString;
   onChange(time, formattedTime);
 }
 else{
  onChange(time, timeString)
 }
  };


  return (
    <TimePicker 
        use12Hours={use12Hours}
        format={use12Hours ? 'h:mm A' : 'HH:mm'}
        onChange={handleChange}
        value={value ? dayjs(value) : null}
         {...rest}
          />
  );
};

export default TimePickerSelector;
