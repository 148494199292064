// customChartData.js

export const data = {
  categories: ["Category 1", "Category 2", "Category 3", "Category 4"],
  series: [
    {
      name: "Bar",
      data: [15, 22, 15, 20],
      type: "column",
    },
    {
      name: "Line",
      type: "line",
      data: [11, 20, 4, 19],
    },
    // Add more series as needed
  ],
};
export const colors = {
  primary: [
    "#55efc4",
    "#74b9ff",
    "#6c5ce7",
    "#64b5f6",
    "#1976d2",
    "#ef6c00",
    "#96a6a6",
  ],
};
