import TextArea, { TextAreaProps } from 'antd/es/input/TextArea';
import React, { FC } from 'react'



const CustomTextArea: FC<TextAreaProps> = ({ ...props }) => {
  return (
   <TextArea {...props}/>
  )
}

export default CustomTextArea;