import { Divider, Form } from "antd";
import { useForm } from "antd/es/form/Form";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import CustomDrawer from "components/CustomDrawer/CustomDrawer";
import TextInput from "components/Inputs/TextInput/TextInput";
import { t } from "i18next";
import { FC, useEffect, useState } from "react";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
interface SelectedSteps {
  [index: number]: "h" | "hn";
}
interface LaunchCampaignProps {
  launchCampaignState: boolean;
  setLaunchCampaignState: (value: boolean) => void; // Optional setter function that accepts a boolean value
  totalSteps: number;
  handleCreateAudience: (
    selectedSteps: SelectedSteps,
    name: string,
    description: string
  ) => void; // Callback function to handle form submission
}

const LaunchCampaign: FC<LaunchCampaignProps> = ({
  launchCampaignState,
  setLaunchCampaignState,
  totalSteps,
  handleCreateAudience,
}) => {
  const [form] = useForm();

  const [selectedSteps, setSelectedSteps] = useState<SelectedSteps>({});
  const handleCheckChange = (index: number, type: "h" | "hn") => {
    setSelectedSteps((prev: any) => ({
      ...prev,
      [index]: type,
    }));
  };

  const onSubmit = (values: any) => {
    const { audienceDescription, audienceName } = values;

    handleCreateAudience(selectedSteps, audienceName, audienceDescription);
    setLaunchCampaignState(false);
  };
  const validateCheckboxGroup = () => ({
    validator() {
      if (Object.values(selectedSteps).length === totalSteps) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error("Please select steps to create campaign")
      );
    },
  });
  useEffect(() => {
    form.resetFields();
    setSelectedSteps({});

    form.resetFields(["checkboxGroup"]);
  }, [launchCampaignState]);
  return (
    <CustomDrawer
      footer={
        <div>
          <PrimaryButton htmlType="submit" onClick={() => form.submit()}>
            {t("saveLabel")}
          </PrimaryButton>
        </div>
      }
      open={launchCampaignState}
      placement="right"
      closable={false}
      onClose={() => setLaunchCampaignState(false)}
    >
      <div className=" mt-1 ">
        <h5 className="fw-semibold">Launch Campaign</h5>
        <Form
          colon={false}
          labelAlign="left"
          labelCol={{ flex: "25px" }}
          layout="vertical"
          form={form}
          onFinish={onSubmit}
        >
          <Divider />
          <Form.Item
            label="Audience name"
            name="audienceName"
            rules={[{ required: true, message: "Audience name is required" }]}
          >
            <TextInput />
          </Form.Item>
          <Form.Item
            label="Audience description"
            name="audienceDescription"
            rules={[
              { required: true, message: "Audience description is required" },
            ]}
          >
            <TextInput />
          </Form.Item>

          <Form.Item name="checkboxGroup" rules={[validateCheckboxGroup()]}>
            <div>
              {Array.from({ length: totalSteps }, (_, index) => (
                <div key={index}>
                  <CustomCheckbox
                    className="mb-2"
                    value={`step${index + 1}+h`}
                    checked={selectedSteps[index + 1] === "h"}
                    onChange={() => handleCheckChange(index + 1, "h")}
                    label={`Users who have done Step ${
                      index + 1
                    } of ${totalSteps}`}
                  />
                  {index + 1 === totalSteps && totalSteps > 1 && (
                    <CustomCheckbox
                      className="mb-2"
                      value={`step${index + 1}+hn`}
                      checked={selectedSteps[index + 1] === "hn"}
                      onChange={() => handleCheckChange(index + 1, "hn")}
                      label={`Users who have not done Step ${
                        index + 1
                      } of ${totalSteps}`}
                    />
                  )}
                </div>
              ))}
              {form.getFieldError("checkboxGroup") && (
                <span>{form.getFieldError("checkboxGroup")}</span>
              )}
            </div>
          </Form.Item>
        </Form>
      </div>
    </CustomDrawer>
  );
};

export default LaunchCampaign;
