export function getDaysDifference(startDate:any, endDate:any) {
  const start:any = new Date(startDate);
  const end:any = new Date(endDate);
  // Calculate the time difference in milliseconds
  const timeDifference = end - start;

  // Calculate the number of days
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference;
}
export function getMonthName(monthNumber: number): string {
  const monthNames: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return monthNames[monthNumber - 1] || "Invalid Month";
}
export function generateRepeatedArray<T>(value: T, maxRepeat: number): T[] {
  return Array.from({ length: maxRepeat }, () => value);
}
// formattedHeader.js

export const formattedHeader = (header:any, chartData:any) => {
  if (chartData) {
    if (header.label === "Weeks") return "Time";
    if (header.label === "Week 0") return "Acquisition";
    else {
      const numericPartMatch = header.label.match(/\d+/);
      const numericPart = numericPartMatch ? numericPartMatch[0] : "";
      return numericPart;
    }
  }
  // Handle the case when chartData is not available
  return "N/A";
};
