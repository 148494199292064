const config = {
  reactAppUrl: process.env.REACT_APP_URL,
  beefree: {
    clientId: process.env.REACT_APP_AIEmailEditor_client_id,
    clientSecret: process.env.REACT_APP_AIEmailEditor_client_secret,
    endpoint: process.env.REACT_APP_AIEmailEditor_endpoint,
    frameSrc: process.env.REACT_APP_framesrc,
    connectSrc: process.env.REACT_APP_connectsrc,
    grantType:process.env.REACT_APP_AIEmailEditor_grant_type
  },
  HEART_BEAT_INTERVAL:parseInt(process.env.REACT_APP_HEART_BEAT_INTERVAL || "40000")
 ,GOOGLE_MAP_KEY:process.env.REACT_APP_GOOGLE_MAP_KEY || ""
};
export default config