export const data=[
    {
      "id": "Morning",
      "data": generateData(7, {
        min: 0,
        max: 90,
      })
    },
    {
      "id": "Afternoon",
      "data": generateData(7, {
        min: 0,
        max: 90,
      })
    },
    {
      "id": "Evening",
      "data": generateData(7, {
        min: 0,
        max: 90,
      })
    },
    
    {
      "id": "Night",
      "data": generateData(7, {
        min: 0,
        max: 90,
      })
    }
  ]
  function generateData(count: any, yrange: any) {
    var i = 0;
    var dayNames=["Mon","Tue","Wed","Thur","Fri","Sat","Sun"]
    var series = [];
    while (i < count) {
      var x = dayNames[i]
      var y =
        Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

      series.push({
        x: x,
        y: y,
      });
      i++;
    }
    return series;
  }