import { DataTypeEnum } from "utils/enums";

/**
 * Formats a PostgreSQL operator and its associated values 
 * 
 * This function adjusts the PostgreSQL operator and its values based on the presence of `%` symbols,
 * 1.(Sending data to API) **Operator Contains `%`**: If the operator includes `%`, the function removes the `%` symbols from the operator
 *    and inserts them at appropriate positions in the values. For example:
 *    - **Input:** `operator = 'LIKE%'`, `value = ['xxx']`
 *    - **Output:** `operator = 'LIKE'`, `value = ['xxx%']` (inserting `%` at the end of the value)
 * 
 * 2.(Retrieving data from an API) **Values Contain `%`**: If any value contains `%`, the function removes `%` symbols from the value and adds
 *    them to the operator. For example:
 *    - **Input:** `operator = 'LIKE'`, `value = ['%xxx%']`
 *    - **Output:** `operator = '%LIKE%'`, `value = ['xxx']` (adding `%` to the end of the operator and removing `%` from the value) 
 * @param {string} operator - The PostgreSQL operator, which may include `%` symbols (e.g., `LIKE%`).
 * @param {string[]} value - An array of values, which may also contain `%` symbols.
 * @returns {{ operator: string, value: string[] }} - An object containing:
 *   - `operator`: The formatted PostgreSQL operator
 *   - `value`: The array of formatted values
 */
export function formatPgSqlOperatorAndValues(operator: string, value: (string| boolean | number)[]) {
   // Extract the first value from the array to determine its type and content
    const firstValue:any=value[0]
    // Remove '%' characters from the operator for internal processing
    const pureOperator = operator.replace(/%/g, "");
     // Initialize formatted values and operator with the original inputs
    let formattedValue = value;
    let formattedOperator = operator;
    // Check if the first value is a string and the operator contains '%'(we are sending )
    if (typeof firstValue === DataTypeEnum.STRING &&  operator.includes("%")) {
      // Create a regex pattern to match the operator with optional '%' characters
      const regex = new RegExp(`(%?)${pureOperator}(%?)`, "g");
        // Format each value 
      formattedValue = value.map((singleValue) => {
        return operator.replace(
          regex,
          (_:string, p1:string, p2:string) => `${p1}${singleValue}${p2}`
        );
      });
      formattedOperator = pureOperator;
       // Check if the first value is a string and contains '%'
    } else if (typeof firstValue === DataTypeEnum.STRING && firstValue.includes("%")) {
       // Remove '%' from the first value to create a pattern
      const pattern = firstValue.replace(/%/g, ""); 
       // Create a regex pattern to match the pattern with optional '%' characters
      const regex = new RegExp(`(%?)${pattern}(%?)`, "g");
      // Format the operator
      formattedOperator = firstValue.replace(
        regex,
        (_:string, p1:string, p2:string) => `${p1}${pureOperator}${p2}`
      );
      // Clean '%' characters from each value
      formattedValue = value.map((singleValue) => singleValue.toString().replace(/%/g, ""));
    }
    return { operator: formattedOperator, value: formattedValue };
  }