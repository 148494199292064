import { createSlice } from "@reduxjs/toolkit";
const initialState=0
const campaignCreateCurrentStepSlice = createSlice({
  name: "campaignCreateCurrentStep",
  initialState,
  reducers: {
    setCurrentStep(state, action) {
      return action.payload;
    },
  },
});
export const {setCurrentStep}=campaignCreateCurrentStepSlice.actions
export default campaignCreateCurrentStepSlice.reducer
