import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  sideNavCollapsed: false,
  contentDimensions: {
    width: window.screen.width, 
    height: window.screen.height,
  },
};

const layoutInfoSlice = createSlice({
  name: "layoutInfo",
  initialState,
  reducers: {
    setContentDimensions(state, action) {
       state.contentDimensions.width=action?.payload?.width;
       state.contentDimensions.height=action?.payload?.height;

    },
    setSideNavCollapsed(state, action) {
      state.sideNavCollapsed=action.payload;
    },
  },
});

export const { setContentDimensions, setSideNavCollapsed } =
  layoutInfoSlice.actions;
export default layoutInfoSlice.reducer;
