export const modelChartData:any = {
  "Propensity home_loan": [
    { month: 'June', height: '1px' },
    { month: 'July', height: '70px' },
    { month: 'August', height: '80px' },
  ],
  "High LTV Home Loan": [
    { month: 'June', height: '2px' },
    { month: 'July', height: '65px' },
    { month: 'August', height: '75px' },
  ],
  "Cross sell for FD": [
    { month: 'June', height: '0px' },
    { month: 'July', height: '80px' },
    { month: 'August', height: '90px' },
  ],
};

  // Example data for the table
export const columns = [
      { title: 'CIF', dataIndex: '_custom_userid', key: '_custom_userid' },
      { title: 'Name', dataIndex: 'name', key: 'name' },
      { title: 'Mobile', dataIndex: 'mobile', key: 'mobile' },
      { title: 'Account Type', dataIndex: 'account_type', key: 'account_type' },
      { title: 'City', dataIndex: 'city', key: 'city' },
      { title: 'State', dataIndex: 'customer_state', key: 'customer_state' },
    ];

