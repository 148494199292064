import React from "react";
import { CaretUpOutlined } from "@ant-design/icons";
import Oval from "../../../images/Oval 2.svg";
import "./WebPushReportCard.css";
import { Divider } from "antd";
interface WebPushReportCardProps {
  type?: "default" | "simple" | "sent" | "double";
  heading?: string;
  number?: string;
  percentageOne?: string;
  percentageTwo?: string;
  headingTwoPercentage?: string;
  headingTwoLastMonthPercentage?: string;
  headingTwo?: string;
  completed?: number;
  scheduled?: number;
}

const WebPushReportCard: React.FC<WebPushReportCardProps> = ({
  type = "default",
  heading,
  number,
  percentageOne,
  percentageTwo,
  headingTwo,
  headingTwoPercentage,
  headingTwoLastMonthPercentage,
  completed,
  scheduled,
}) => {
  // const showImage = type === "default";
  // const showLastMonth = type === "default";
  const isSimple = type === "simple";
  const showImage = !isSimple;
  const showLastMonth = !isSimple;
  const isSent = type === "sent";
  const isDouble = type === "double";

  const containerStyle: React.CSSProperties = isSimple
    ? { background: "#D8D8D82B" }
    : {}; // Set background color if type is "simple"

  return (
    <div className="web-push-card " style={containerStyle}>
      <div className="web-push-card-container d-flex flex-nowrap ">
        <div>
          <div className="d-flex gap-2">
            <p
              className="web-push-card-common-settings-heading mt-3 ml-3 "
              style={{ color: isSimple ? "black" : "" }}
            >
              {heading}
            </p>
            {showImage && <img src={Oval} alt="icon" width={10} />}
          </div>
          <div className="d-flex gap-2 web-push-card-common-settings-number">
            <p>{number}</p>

            {percentageOne && (
              <>
                {/* Remove the Divider if not needed */}
                <Divider
                  className="web-push-card-impression-divider"
                  type="vertical"
                />
                <p className="web-push-card-impression-percentage">
                  {percentageOne}%
                </p>
              </>
            )}
          </div>
          {isSent && (
            <>
              {completed !== undefined && (
                <div className="d-flex gap-3 web-push-card-sent">
                  <p className="web-push-card-sent-completed">Completed</p>
                  <p className="web-push-card-sent-completed-number">
                    {completed}
                  </p>
                </div>
              )}
              {scheduled !== undefined && (
                <div className="d-flex gap-3 web-push-card-sent">
                  <p className="web-push-card-sent-scheduled">Scheduled</p>
                  <p className="web-push-card-sent-scheduled-number">
                    {scheduled}
                  </p>
                </div>
              )}
            </>
          )}
          {percentageTwo && (
            <div className="mt-4">
              <div className="d-flex gap-1">
                <CaretUpOutlined
                  style={{ color: "var(--color-success)", fontSize: "20px" }}
                />
                <p className="web-push-card-impression-bottom-percentage">
                  {percentageTwo}%
                </p>
              </div>
              {showLastMonth && (
                <p className="web-push-card-impression-bottom-month">
                  vs last month
                </p>
              )}
            </div>
          )}
        </div>

        {isDouble && (
          <>
            {/* Remove the Divider if not needed */}
            <Divider className="web-push-card-ctr-divider" type="vertical" />
            <div className="web-push-card-second-col-ctr">
              {headingTwo && (
                <div className="d-flex gap-2">
                  <p className="web-push-card-common-settings-heading mt-3 ml-3">
                    {headingTwo}
                  </p>
                  <img src={Oval} alt="icon" width={10} />
                </div>
              )}
              {headingTwoPercentage && (
                <div className="d-flex gap-2 web-push-card-common-settings-number">
                  <p className="web-push-card-impression-percentage">
                    {headingTwoPercentage}%
                  </p>
                </div>
              )}
                {headingTwoLastMonthPercentage && (
              <div className="mt-4">
                  <div className="d-flex gap-1">
                    <CaretUpOutlined
                      style={{
                        color: "var(--color-success)",
                        fontSize: "20px",
                      }}
                    />
                    <p className="web-push-card-impression-bottom-percentage">
                      {headingTwoLastMonthPercentage}%
                    </p>
                  </div>
                {showLastMonth && (
                  <p className="web-push-card-impression-bottom-month">
                    vs last month
                  </p>
                )}
              </div>
                )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default WebPushReportCard;
