import React from "react";
import { Helmet } from "react-helmet";
import Selector from "../../../components/Selector/Selector";
import WebPushReportCard from "../../../components/Cards/WebPushReportCard/WebPushReportCard";
import { DateSelector } from "../../../components/DateTimeSelectors/DateSelector/DateSelector";
import RadioButton from "../../../components/Buttons/RadioButton/RadioButton";
import "./WebPushReports.css";
import { PerformanceChartOne } from "./PerformanceCharts/PerformanceChartOne";
import { PerformanceChartTwo } from "./PerformanceCharts/PerformanceChartTwo";
import { Divider, Table } from "antd";
import { BrowserChart } from "./BrowserChart/BrowserChart";
import CustomTable from "../../../components/CustomTable/CustomTable";
export const WebPushReports = () => {
  const tableStyle = {
    border: "none",
  };
  const columns = [
    {
      title: "Technical Errors",
      dataIndex: "name",
      key: "name",
      // render: (text) => <a>{text}</a>,
    },
    {
      title: "Chrome",
      dataIndex: "chrome",
      key: "chrome",
    },
    {
      title: "Safari",
      dataIndex: "safari",
      key: "safari",
    },
  ];
  const data = [
    {
      // key: 'name',
      name: "Dispatch Error",
      chrome: 13,
      safari: 1,
    },
    {
      // key: 'name',
      name: "Duplicate Profile for channel",
      chrome: 3,
      safari: 3,
    },
    {
      // key: 'name',
      name: "Count",
      chrome: 16,
      safari: 4,
    },
  ];
  const columns2 = [
    {
      title: "Non Technical Errors",
      dataIndex: "name",
      key: "name",
      // render: (text) => <a>{text}</a>,
    },
    {
      title: "Chrome",
      dataIndex: "chrome",
      key: "chrome",
    },
    {
      title: "Safari",
      dataIndex: "safari",
      key: "safari",
    },
    {
      title: "Unclasssified",
      dataIndex: "unclassified",
      key: "unclassified",
    },
  ];
  const data2 = [
    {
      // key: 'name',
      name: "Invalid profiles",
      chrome: 6,
      safari: 16,
      unclassified: 0,
    },
    {
      // key: 'name',
      name: "Global frequency caps exceeded",
      chrome: 9,
      safari: 29,
      unclassified: 0,
    },
    {
      // key: 'name',
      name: "User DND",
      chrome: 22,
      safari: 13,
      unclassified: 0,
    },
    {
      // key: 'name',
      name: "User not reachable",
      chrome: 5,
      safari: 9,
      unclassified: 0,
    },
    {
      // key: 'name',
      name: "Count",
      chrome: 52,
      safari: 67,
      unclassified: 0,
    },
  ];
  return (
    <div className="container-fluid">
      <div className="web-push-reports-container">
        <Helmet>
          <title>Appice | Web Push Reports</title>
        </Helmet>
        <div className="d-flex justify-content-between flex-wrap my-3">
          <h4>Overview</h4>
          <DateSelector />
        </div>
        <div className="web-push-reports-cards   ">
          <WebPushReportCard heading="Reachable audience" number="31,93,234" />
          <WebPushReportCard
            type="sent"
            heading="Sent"
            number="55,65,65"
            completed={4}
            scheduled={5}
          />
          <WebPushReportCard
            heading="Impressions"
            number="86,85,499"
            percentageOne="39"
            percentageTwo="10.5"
          />
          <WebPushReportCard
          type="double"
            heading="Clicks"
            headingTwo="CTR"
            number="45,302"
            percentageOne="2.1"
            headingTwoPercentage="23"
          />
          <WebPushReportCard
            heading="Conversions"
            number="2,799"
            percentageOne="5.1"
            percentageTwo="0.3"
          />
        </div>
        
        <div className="mt-3">
          <h6>Campaign Performance</h6>
        </div>
        <div className="web-push-reports-campaigns">
          <div className="d-flex gap-2 align-items-baseline ">
            <p className="web-push-reports-campaigns-heading">Select Channel</p>
            <Selector
              className="web-push-reports-campaigns-heading"
              defaultValue="SMB"
              size="middle"
              options={[
                {
                  value: "SMB",
                  label: "SMB",
                },
              ]}
            />
            <RadioButton name="Yesterday" />
            <RadioButton name="Last 7 D" />
            <RadioButton name="Last 30 D" />
          </div>
          <div className="web-push-reports-performance-charts ">
            <div className="web-push-reports-performance-charts-one">
              <PerformanceChartOne />
            </div>
            <div className="web-push-reports-performance-charts-one">
              <PerformanceChartTwo />
            </div>
          </div>
        </div>
        <div className="  mt-3  web-push-reports-bottom-card ">
          <WebPushReportCard
            type="simple"
            heading="Reachablity"
            number="3,23,483"
            percentageOne="21.8"
          />
          <WebPushReportCard
            type="simple"
            heading="New Subscribers"
            number="6,380"
            percentageTwo="4.5"
          />
          <WebPushReportCard
            type="simple"
            heading="Unsubscribed"
            number="549"
            percentageTwo="12"
          />{" "}
          <WebPushReportCard
            type="simple"
            heading="Users Engaged"
            number="4020"
            percentageTwo="4.5"
          />
          <WebPushReportCard
            type="simple"
            heading="Revenue"
            number="SAR 1,44,213"
            percentageTwo="12"
          />{" "}
        </div>
        <div className="web-push-reports-browser mt-3">
          <h6>Browser type</h6>
          <Divider />
          <div className="web-push-reports-browser-container">
          <div className="web-push-reports-browser-chart">
              <BrowserChart />
            </div>
            <Divider
              className="web-push-reports-browser-type-divider-center h-auto"
              type="vertical"
            />

            <div>
              <h6 className="web-push-reports-browser-type-heading">
                Conversions
              </h6>
              <div className="d-flex justify-content-between web-push-reports-browser-type-container ">
                <div className="d-flex">
                  <div>
                    <p className="web-push-reports-browser-type-sub-heading">
                      Chrome
                    </p>
                    <p
                      className="web-push-reports-browser-type-sub-heading-percentage"
                      style={{ color: "#6C5AFF" }}
                    >
                      36%
                    </p>
                  </div>
                  <Divider
                    className="web-push-reports-browser-type-divider"
                    type="vertical"
                  />
                </div>
                <div className="d-flex">
                  <div>
                    <p className="web-push-reports-browser-type-sub-heading">
                      Safari
                    </p>
                    <p
                      className="web-push-reports-browser-type-sub-heading-percentage"
                      style={{ color: "#02C68A" }}
                    >
                      26%
                    </p>
                  </div>
                  <Divider
                    className="web-push-reports-browser-type-divider"
                    type="vertical"
                  />
                </div>
                <div className="d-flex">
                  <div>
                    <p className="web-push-reports-browser-type-sub-heading">
                      Firefox
                    </p>
                    <p
                      className="web-push-reports-browser-type-sub-heading-percentage"
                      style={{ color: "#FF955A" }}
                    >
                      2%
                    </p>
                  </div>
                  {/* <Divider className="web-push-reports-browser-type-divider"  type="vertical"/> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="web-push-reports-table mt-3 ">
          <div className="web-push-reports-table-heading m-3  ">
            <div className="d-flex align-items-baseline gap-1">
              <h4>139</h4>
              <p className="web-push-reports-table-sub-heading">Total Errors</p>
            </div>
            <div className="d-flex align-items-baseline gap-1">
              <h4>20</h4>
              <p className="web-push-reports-table-sub-heading">
                Technical Errors
              </p>
            </div>
            <div className="d-flex align-items-baseline gap-1">
              <h4>119</h4>
              <p className="web-push-reports-table-sub-heading">
                Non technical Errors
              </p>
            </div>
          </div>
          <div className="web-push-reports-table-container  ">
            <CustomTable
              columns={columns}
              dataSource={data}
              pagination={false}
            />
            <div className="mt-3">
              <CustomTable
                columns={columns2}
                dataSource={data2}
                pagination={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
