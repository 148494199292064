import React, { FC, useState } from "react";
import PrimaryButton from "../../../components/Buttons/PrimaryButton/PrimaryButton";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { PlusOutlined } from "@ant-design/icons";
import Search from "../../../components/Inputs/Search/Search";
import { columns, data } from "./data";
import { useNavigate } from "react-router-dom";

interface DemoMembersTableProps {}

const DemoMembersTable: FC<any> = ({onTabClickUrl}) => {
  const [dataCopy,setDataCopy]=useState<typeof data>(data)
  const navigate=useNavigate()
  const rowProps = (record:any, index:any) => {
    return {
      onClick: () => navigate(onTabClickUrl,{state:{data:record,index}}),
    };
  };

 
    const handleSearch = (value: string) => {
      const regex = new RegExp(value, "i");
      console.log(data)
      const temp: typeof data = data.filter((user: any) => {
        return user.email ? regex.test(user.email) : false;
      });
      setDataCopy(temp);
    };
  return (
    <div className="p-4 mt-2" style={{ background: "white" }}>
      <div className="d-flex justify-content-between">
        <h5>List of members</h5>
        <div className="d-flex gap-3">
          <PrimaryButton>Configuration</PrimaryButton>
          <PrimaryButton type="primary">Add Members</PrimaryButton>
        </div>
      </div>
      <div className="d-flex py-3 justify-content-between">
        <PrimaryButton >
          <span className="px-3 d-flex align-items-center">

          <PlusOutlined /> <span className="px-2">Filter</span>
          </span>
        </PrimaryButton>
        <Search onChange={(e)=>handleSearch(e.target.value)} style={{width:"225px"}} />
      </div>
      <div className="w-100 py-3">
        <CustomTable
        tableSize= "small"
        onRow={rowProps}
          dataSource={dataCopy}
          columns={columns}
          pagination={{ pageSize: 12, simple: false ,size:"small"}}
        />
      </div>
    </div>
  );
};

export default DemoMembersTable;
