import React, { useState } from 'react'
import OtpInput from 'react-otp-input';
import "./OtpAuthInput.css"
function OtpAuthInput({onChange,value,numInputs,...props}:any) {
    
return (
    <OtpInput
    value={value}
    onChange={onChange}
    numInputs={numInputs}
    {...props}
    renderSeparator={<span className='mx-1'>-</span>}
    renderInput={(props) => {return <input {...props} className="otp-auth-input"/>}}
  />
  )
}

export default OtpAuthInput
OtpAuthInput.defaultProps={
  numInputs:6
}