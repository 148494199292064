import React, { FC, useState } from "react"
import PrimaryButton from "../../../components/Buttons/PrimaryButton/PrimaryButton"
import CustomTable from "../../../components/CustomTable/CustomTable"
import { PlusOutlined } from "@ant-design/icons"
import Search from "../../../components/Inputs/Search/Search"
import { columns, data } from "./data"
import CustomBreadCrumb from "../../../components/CustomBreadCrumb/CustomBreadCrumb"
import { Link } from "react-router-dom"
import Selector from "../../../components/Selector/Selector"
import FormItem from "antd/es/form/FormItem"
import TextInput from "../../../components/Inputs/TextInput/TextInput"
import { Card } from "antd"
import CustomCheckbox from "../../../components/CustomCheckbox/CustomCheckbox"
import TextArea from "antd/es/input/TextArea"
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import SwitchBtn from "../../../components/Buttons/SwitchBtn/SwitchBtn"
const { Dragger } = Upload;
interface CreateRewardProps {}

const CreateReward: FC<CreateRewardProps> = ({}) => {
  const [dataCopy, setDataCopy] = useState<typeof data>(data)

  const handleSearch = (value: string) => {
    const regex = new RegExp(value, "i")
    console.log(data)
    const temp: typeof data = data.filter((user: any) => {
      return user.email ? regex.test(user.email) : false
    })
    setDataCopy(temp)
  }
  return (
    <div className="p-4 mt-2">
      <div>
        {/* <h4>{t("journeysViewDataLabel")}</h4> */}
        <CustomBreadCrumb
          items={[
            {
              title: <Link to="/dashboard/loyalty/rewards">List rewards</Link>,
            },

            {
              title: (
                <Link to="/dashboard/loyalty/rewards/create">Add reward</Link>
              ),
            },
          ]}
        />
      </div>
      <div className="mt-4 mb-2 ">
        <h6>Types of reward</h6>
      </div>
      <div className="p-4 " style={{ background: "white" }}>
        <div className="d-flex gap-4" >
          <Card
            style={{
              width: "35%",
              backgroundColor: "#00000005", // Hexadecimal representation
            }}
            className="mb-4"
        
          >
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column">
                <span
                  className="font-weight-bold mb-2 "
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Material reward
                </span>
                <span>Reward in the form of material gift</span>
              </div>
              <CustomCheckbox checked/>
            </div>
          </Card>
          <Card
            style={{
              width: "35%",
              backgroundColor: "#00000005", // Hexadecimal representation
            }}
            className="mb-4"
        
          >
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column">
                <span
                  className="font-weight-bold mb-2 "
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Coupon
                </span>
                <span>Reward in the form of coupon</span>
              </div>
              <CustomCheckbox />
            </div>
          </Card>
        </div>
        <FormItem>
          <Selector
            // className="loyalty-campaigns-heading"
            placeholder="Wallet type allowed for redumption"
            // size="middle"
            style={{ width: "100%" }}
            options={[
              {
                value: "Wallet type allowed for redumption",
                label: "Wallet type allowed for redumption",
              },
            ]}
          />
        </FormItem>
        <FormItem>
          <Selector
            // className="loyalty-campaigns-heading"
            placeholder="Reward price in points"
            // size="middle"
            style={{ width: "100%" }}
            options={[
              {
                value: "Wallet type allowed for redumption",
                label: "Reward price in points",
              },
            ]}
          />
        </FormItem>
      </div>

      
      <div className="mt-4 mb-2 ">
      <h6>Basic settings</h6>
      </div>

      <div className="p-4 " style={{ background: "white" }}>
        <FormItem>
          <TextInput placeholder="Name" />
        </FormItem>
        <FormItem>
          <TextArea rows={3} placeholder="Description" />{" "}
        </FormItem>
        <div className="d-flex gap-2 align-items-baseline" >

        <PlusOutlined />
        <p style={{fontSize:'16px', 
        // color: ' #7d7e80'
        }} >Translation</p>
        </div>

        <FormItem>
        <Selector
            // className="loyalty-campaigns-heading"
            placeholder="Reward is always available"
            // size="middle"
            style={{ width: "100%" }}
            options={[
              {
                value: "Wallet type allowed for redumption",
                label: "Reward is always available",
              },
            ]}
          />        </FormItem>
           <FormItem>
        <Selector
            // className="loyalty-campaigns-heading"
            placeholder="Reward is always visible"
            // size="middle"
            style={{ width: "100%" }}
            options={[
              {
                value: "Wallet type allowed for redumption",
                label: "Reward is always visible",
              },
            ]}
          />        </FormItem>
           <FormItem>
        <Selector
        placeholder="Available for everone"
            // className="loyalty-campaigns-heading"
            // placeholder="Available for everyone"
            // size="middle"
            style={{ width: '100%', color: '#00000005' }} // Set the color to light grey
            options={[
              {
                value: "Wallet type allowed for redumption",
                label: "Available for everyone",
              },
            ]}
          />        </FormItem>
          <FormItem>
            <TextInput type="number" placeholder="Redemption limit"/>
          </FormItem>
          <FormItem>
            <TextInput type="number" placeholder="Redemption limit per member"/>
          </FormItem>
      
      </div>
      <div className="mt-4 mb-2 ">
      <h6>Additional settings</h6>
      </div>
      <div className="p-4 " style={{ background: "white" }}>
        
        <FormItem>
          <TextArea rows={3} placeholder="Usage instruction" />{" "}
        </FormItem>

        <FormItem>
          <TextArea rows={3} placeholder="Condition description" />{" "}
        </FormItem>

        <FormItem>
          <TextArea rows={3} placeholder="Brand name" />{" "}
        </FormItem>

        <FormItem>
          <TextArea rows={3} placeholder="Brand description" />{" "}
        </FormItem>
        <div className="d-flex gap-2 align-items-baseline" >

<PlusOutlined />
<p style={{fontSize:'16px', 
// color: ' #7d7e80'
}} >Translation</p>
</div>
<FormItem>
        <Selector
        placeholder="Category"
            // className="loyalty-campaigns-heading"
            // placeholder="Available for everyone"
            // size="middle"
            style={{ width: '100%', color: '#00000005' }} // Set the color to light grey
            options={[
              {
                value: "Wallet type allowed for redumption",
                label: "Category",
              },
            ]}
          />        </FormItem>

<FormItem>
        <Selector
        placeholder="Custom reward attributes"
            // className="loyalty-campaigns-heading"
            // placeholder="Available for everyone"
            // size="middle"
            style={{ width: '100%', color: '#00000005' }} // Set the color to light grey
            options={[
              {
                value: "Wallet type allowed for redumption",
                label: "Custom reward attributes",
              },
            ]}
          />        </FormItem>


      </div>
      <div className="mt-4 mb-2 ">
      <h6>Reward image</h6>
      </div>
      <div className="p-4 " style={{ background: "white" }}>

      <Dragger style={{width:'30%'}}>
    <p className="ant-upload-drag-icon">
      <InboxOutlined />
    </p>
    <p className="ant-upload-text">Reward image</p>
    <p className="ant-upload-hint">
      Accept formats .png, .jpg
    </p>
  </Dragger>
  </div>
  <div className="mt-4 mb-2 ">
      <h6>Reward status</h6>
      </div>
      <div className="p-4 " style={{ background: "white" }}>
        <FormItem>

        <SwitchBtn/>
        <span> Active</span>

        </FormItem>
</div>
<PrimaryButton
                  size="middle"
                  type="primary"
                  htmlType="submit"
                  style={{ border: "none", width: "80px" ,marginTop:'10px' }}
                >
                  Save
                </PrimaryButton>    </div>
  )
}

export default CreateReward
