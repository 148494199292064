import apiRequest from "./apiRequest";
import { getRandomNumber } from "../helperFunctions/number";
import { getCookie } from "../helperFunctions/cookie";
type HttpMethod = "GET" | "POST" | "PATCH"; // Add "PATCH" to HttpMethod

const centralApi = async (
  method: HttpMethod,
  endpoint: string,
  data: any = null,
  params: any = {},
  isBlob: boolean = false, 

  
) => {
  
    const _csrf = getCookie("_csrf");
    let requestParams = { ...params };
    if (method === "GET") {
      const uniqueNumber = getRandomNumber();
      requestParams = { ...requestParams, uniqueNumber };
    }

    let headers = {};

    if (method === "POST" || method === "PATCH") { // Update for PATCH
      const uniqueNumber:number = getRandomNumber();
      requestParams = { ...requestParams, uniqueNumber }; // unique number for POST or Patch request

      if (data instanceof FormData) {
      } else {
        data = { ...data, uniqueNumber };
      }
      headers = {
        "csrf-token": _csrf,
      };
    }
    const responseType = isBlob ? "blob" : "json"; // Set responseType based on isBlob parameter

    const response = await apiRequest({
      method,
      url: `${endpoint}`,
      data,
      params: requestParams,
      headers,
      responseType,

    });
    if (isBlob) {
      return new Blob([response.data], { type: response.headers['content-type'] });
    }
    return response.data;
  
};

export default centralApi;
