import { Space } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import CustomTable from "components/CustomTable/CustomTable";
// import closeIcon from "../../images/_close.svg";
// import addIcon from "../../images/_add.svg";

import TextInput from "components/Inputs/TextInput/TextInput";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { t } from "i18next";
interface ConfiguratorTableProps {
  tableData: any;
  setTableData: any;
  type: "request" | "response";
}

const ConfiguratorTable = ({
  tableData,
  setTableData,
  type,
}: ConfiguratorTableProps) => {
  interface RecordType {
    SourceKey: string; // Assuming SourceKey is of type string, adjust this according to your actual data structure
    SourceValue: string;
    DestinationKey: string;
    DestinationType: string;
    DestinationValue: string;

    SourceType: string;
  }
  const types = {
    [type]: {
      SourceKey: "",
      SourceValue: "",
      SourceType: "",
      DestinationKey: "",
      DestinationValue: "",
      DestinationType: "",
    },
  };
  const handleAddRow = () => {
    setTableData([...tableData, types[type]]);
  };

  const handleDeleteRow = (index: number) => {
    const newData = [...tableData];
    newData.splice(index, 1);
    setTableData(newData);
  };
  const handleActionChange = (key: string, index: number, value: string) => {
    const data = [...tableData];
    data[index] = { ...data[index], [key]: value };
    setTableData(data);
  };
  const columns = [
    {
      title: t("sourceKeyLabel"),
      dataIndex: "SourceKey",
      key: "SourceKey",
      render: (_:any,record: RecordType, index: number) => (
        <TextInput
          value={record?.SourceKey}
          onChange={(e: any) => {
            handleActionChange("SourceKey", index, e.target.value);
          }}
        />
      ),
    },
    {
      title: t("sourceValueLabel"),
      dataIndex: "SourceValue",
      key: "SourceValue",
      render: (_:any,record: RecordType, index: number) => (
        <TextInput
          value={record?.SourceValue}
          onChange={(e: any) => {
            handleActionChange("SourceValue", index, e.target.value);
          }}
        />
      ),
    },
    {
      title: t("sourceTypeLabel"),
      dataIndex: "SourceType",
      key: "SourceType",
      render: (_:any,record: RecordType, index: number) => (
        <TextInput
          value={record?.SourceType}
          onChange={(e: any) => {
            handleActionChange("SourceType", index, e.target.value);
          }}
        />
      ),
    },
    {
      title: t("destinationKeyLabel"),
      dataIndex: "DestinationKey",
      key: "DestinationKey",
      render: (_:any,record: RecordType, index: number) => (
        <TextInput
          value={record?.DestinationKey}
          onChange={(e: any) => {
            handleActionChange("DestinationKey", index, e.target.value);
          }}
        />
      ),
    },
    {
      title: t("destinationValueLabel"),
      dataIndex: "DestinationValue",
      key: "DestinationValue",
      render: (_:any,record: RecordType, index: number) => (
        <TextInput
          value={record?.DestinationValue}
          onChange={(e: any) => {
            handleActionChange("DestinationValue", index, e.target.value);
          }}

          // placeholder="Destination Value"
        />
      ),
    },
    {
      title: t("destinationTypeLabel"),
      dataIndex: "DestinationType",
      key: "DestinationType",
      render: (_:any,record: RecordType, index: number) => (
        <TextInput
          value={record?.DestinationType}
          onChange={(e: any) => {
            handleActionChange("DestinationType", index, e.target.value);
          }}
        />
      ),
    },
    {
      title: t("actionLabel"),
      key: "action",
      render: (_:any,record:any,index: number) => (
        <Space>
          
          <PrimaryButton
            className="action-btn mx-1"
            onClick={() => handleDeleteRow(index)}
            disabled={tableData.length === 1} // Disable the button if index is 0 (only one row)

          >
            {/* <img
                    src={closeIcon}
                    alt={"close"}
                    style={{ width: 18 }}
                  ></img> */}
            <CloseOutlined />
          </PrimaryButton>
          <PrimaryButton
            className="action-btn mx-1"
            onClick={() => handleAddRow()}
          >
            {/* <img
                    src={addIcon}
                    alt={"addIcon"}
                    style={{ width: 18 }}
                  ></img> */}
            <PlusOutlined />
          </PrimaryButton>

          </Space>
      ),
    },
  ];

  return (
    <div>
      
      <CustomTable
        dataSource={tableData}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default ConfiguratorTable;
