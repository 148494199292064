import BeePlugin from "@mailupinc/bee-plugin";
import axios from "axios";
import config from "../config";
import { beefreeOptionType } from "../components/BeefreeEditor/BeefreeEditor";

export const beefree = async (options: beefreeOptionType) => {
  try {
    const { endpoint, clientId, clientSecret, grantType }: any = config.beefree;
    const payload = {
      client_id: clientId, // Enter your client id
      client_secret: clientSecret, // Enter your secret key
      grant_type: grantType, // Do not change
    };
    const token: any = await axios.post(endpoint, payload);
    //simple Beefree application configuration...
    var conf: any = {
      uid: "22",
      container: "bee-plugin-container",
      workspace: {
        onSave: function (jsonFile: string, htmlFile: string) {
          /* Implements function for save */
        }, // [optional]
        onChange: function (jsonFile: string, response: string) {
          /* Implements function for change */
        }, // [optional]
        onSaveAsTemplate: function (jsonFile: string) {
          /* Implements function for save as template (only JSON file) */
        }, // [optional]
        onAutoSave: function (jsonFile: string) {
          /* Implements function for auto save */
        }, // [optional]
        onSend: function (htmlFile: string) {
          /* Implements function to send the message */
        }, // [optional]
        onLoad: function (jsonFile: string) {
          /* Implements function to perform an action once the template is loaded */
        }, // [optional]
        onError: function (errorMessage: string) {
          /* Implements function to handle error messages */
        }, // [optional]
      },
    };
    const bee = new BeePlugin(token, conf);
    await bee.getToken(clientId, clientSecret);
    bee.start(
      conf,
      options?.existingTemplate ? JSON.parse(options?.existingTemplate) : {}
    );
    conf.onSave = options.workspace?.onSave;
    conf.onChange = options.workspace?.onChange;
    conf.onSaveAsTemplate = options.workspace?.onSaveAsTemplate;
    conf.onAutoSave = options.workspace?.onAutoSave;
    conf.onSend = options.workspace?.onSend;
    conf.onLoad = options.workspace?.onLoad;
    conf.onError = options.workspace?.onError;
    return bee
  } catch (error) {}
};
