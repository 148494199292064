import React, { useEffect, useState, Fragment } from "react";
import "./PushNotificationNav.css";
import InnerNav from "../../../../../../components/InnerNav/InnerNav";
import type { MenuProps } from "antd";
import ApplePushNotification from "../ApplePushNotification/ApplePushNotification";
import FirebaseCloudMessaging from "../FirebaseCloudMessaging/FirebaseCloudMessaging";
import WebPushNotification from "../WebPushNotification/WebPushNotification";
import GlobalFrequencyCapping from "../GlobalFrequencyCapping/GlobalFrequencyCapping";
import DoNotDisturb from "../DoNotDisturb/DoNotDisturb";
import { t } from "i18next";

interface PushNotificationNavProps {
  currentAppKey: any;
  mode: string;
  fetchCurrentAppKeys: any;
}

const PushNotificationNav: React.FC<PushNotificationNavProps> = ({
  currentAppKey,
  mode,
  fetchCurrentAppKeys,
}) => {
  const [appTabFilter, setAppTabFilter] = useState("apple_push_notification");
  const [selectedComponent, setSelectedComponent] =
    useState<JSX.Element | null>(null);

  // useEffect to handle component updates based on mode and appTabFilter
  useEffect(() => {
    switch (appTabFilter) {
      case "apple_push_notification":
        setSelectedComponent(
          <ApplePushNotification
            currentAppKey={currentAppKey}
            mode={mode}
            fetchCurrentAppKeys={fetchCurrentAppKeys}
          />
        );
        break;
      case "firebase_cloud_messaging":
        setSelectedComponent(
          <FirebaseCloudMessaging
            currentAppKey={currentAppKey}
            Mode={mode}
            fetchCurrentAppKey={fetchCurrentAppKeys}
          />
        );
        break;
      case "web_push_notification":
        setSelectedComponent(
          <WebPushNotification
            currentAppKey={currentAppKey}
            Mode={mode}
            fetchCurrentAppKey={fetchCurrentAppKeys}
          />
        );
        break;
      case "global_frequency_capping":
        setSelectedComponent(
          <GlobalFrequencyCapping
            currentAppKey={currentAppKey}
            Mode={mode}
            fetchCurrentAppKey={fetchCurrentAppKeys}
          />
        );
        break;
      case "do-not-disturb":
        setSelectedComponent(
          <DoNotDisturb
            currentAppKey={currentAppKey}
            Mode={mode}
            fetchCurrentAppKey={fetchCurrentAppKeys}
          />
        );
        break;
      // Add cases for other tabs
      default:
        setSelectedComponent(<div></div>); // Default empty component
    }
  }, [mode, appTabFilter, currentAppKey]);

  // Event handler for tab clicks
  const onTabClick: MenuProps["onClick"] = (e) => {
    setAppTabFilter(e.key);
  };

  return (
    <Fragment>
      <div className="push-notification-nav-container ">
        <div className="push-notification-nav-heading">
          <InnerNav
            theme="light"
            onTabClick={onTabClick}
            selected={appTabFilter}
            menu={[
              {
                label: t("applePushNotificationLabel"),
                key: "apple_push_notification",
              },
              {
                label: t("fcmLabel"),
                key: "firebase_cloud_messaging",
              },
              {
                label: t("wpnLabel"),
                key: "web_push_notification",
              },
              {
                label: t("globalFrequencyCappingLabel"),
                key: "global_frequency_capping",
              },
              {
                label: t("donotDisturbLabel"),
                key: "do-not-disturb",
              },
            ]}
          />
        </div>
      </div>
      {selectedComponent}
    </Fragment>
  );
};

export default PushNotificationNav;
