import React, { useState } from "react"
import { useEffect } from "react"
import { t } from "i18next"
import CustomHighChart from "../../../components/Charts/CustomHighChart/CustomHighChart"
import centralApi from "../../../services/centralApi"
import { timestampToFormattedDate } from "../../../helperFunctions/common"
type propTypes = {
  api_key?: string
  app_id?: string
  cid?: string
  sd?: string
  ed?: string
  type?:string
}
const CampaignDetailChart: React.FC<propTypes> = ({
  api_key,
  app_id,
  cid,
  sd,
  ed,
  type
}) => {
  const [loading, setLoading] = useState(false)
  const [statsData, setStatsData] = useState<any | []>([]) // Change the initial state to null
  // const dummySeries =[Delivered,Open,Click,Bounce, CTR%]

  // const dummyData = {
  //   android: {
  //     "2024_01_02": {
  //       Campaign_Received: 10,
  //       Campaign_Viewed: 0,
  //       Campaign_Clicked: 2,
  //       Campaign_Deleted: 0,
  //     },
  //   },
  //   ios: {},
  //   web: {},
  // }
  const dummyDataEmail = {
   
        "2024_01_02": {
            delivered: 10,
            open: 0,
            click: 2,
            bounce: 0,
            ctr: 10
        },
        "2024_01_03": {
            delivered: 15,
            open: 2,
            click: 5,
            bounce: 1,
            ctr: 8
        },
  
    
        "2024_01_04": {
            delivered: 8,
            open: 1,
            click: 3,
            bounce: 0,
            ctr: 12
        },
        "2024_01_05": {
            delivered: 12,
            open: 3,
            click: 7,
            bounce: 2,
            ctr: 9
        }
    ,
        "2024_01_06": {
            delivered: 20,
            open: 5,
            click: 10,
            bounce: 2,
            ctr: 15
        },
        "2024_01_07": {
            delivered: 18,
            open: 7,
            click: 12,
            bounce: 3,
            ctr: 14
        }
    
};
  async function getCampaignStatsDateWise() {
    const payload = {
      api_key: api_key,
      app_id: app_id,
      cid: cid,
      ed,
      platform: "android",
      sd,
      endDate: timestampToFormattedDate(ed),
      startDate: timestampToFormattedDate(sd),
    }
    const url: string = `/o/templates/getCampaignStatsDateWise`
    try {
      setLoading(true)
      const data = await centralApi("GET", url, null, payload)
      setStatsData(data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  useEffect(() => {
    getCampaignStatsDateWise()
  }, [])
  const isEmailType = type === "EMAIL";

  const seriesData =
      [
        {
          name: "Clicked",
          data: statsData && statsData.android
            ? Object.values(statsData?.android).map((stats:any) => stats?.Campaign_Clicked)
            : [''],
          pointWidth: 40,
        },
        {
          name: "Sent",
          data: statsData && statsData.android
            ? Object.values(statsData?.android).map((stats:any) => stats?.Campaign_Received)
            : [''],
          pointWidth: 40,
        },
        {
          name: "CTR",
          type: "spline",
          data: statsData && statsData.android
            ? Object.values(statsData?.android).map((stats:any) => stats?.Campaign_Viewed)
            : [''],
        },
      ];
    

  const options = {
    chart: {
      type: "column", // Use 'column' for bars
    },
    title: {
      text: "",
    },
    plotOptions: {
      column: {
        plotBackgroundColor: "rgba(0, 0, 0, 0.02)", // Set the background color of the plot area
      },
    },
    xAxis: {
      categories: 
      
         statsData &&  statsData.android
          ? Object.keys(statsData?.android).map((date) => date)
          : [''],
    },
    yAxis: [
      {
        title: {
          text: "Bar Axis",
        },
        labels: {
          format: "{value}",
        },
      },
      {
        title: {
          text: "Spline Axis",
        },
        labels: {
          format: "{value}",
        },
        opposite: true,
      },
    ],
    
    series:seriesData
  }

  return (
    <div>
      <CustomHighChart options={options} />
    </div>
  )
}

export default CampaignDetailChart
