import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import centralApi from "../../../services/centralApi";
import { STATUS } from "../../constant";
import { E_OperatorEnum } from "utils/enums";
import { API_ENDPOINTS } from "utils/constants";
import { formatPgSqlOperatorAndValues } from "utils/operators";
import { TraitInfo, TraitsPayload } from "utils/models";
// Initial value for a trait condition
export const traitConditionIntialValue: TraitInfo = {
  func: undefined,
  compute: false,
  operand: "",
  operator: "",
  value: [],
  type: "",
  e_operator: E_OperatorEnum.AND,
};

// Initial state of the computedTrait slice
const initialState: { data: TraitsPayload; status: any } = {
  data: {
    name: "",
    tableName: "",
    column:[],
    days: undefined,
    audid:"",
    traitInfo: [],
  },
  status: STATUS.IDLE,
};

// Async thunk to fetch computed trait by ID
export const fetchComputedTraitById = createAsyncThunk(
  "computedTrait/fetchComputedTraitById",
  async ({ api_key, app_id, id }: any) => {
    const params = {
      api_key,
      app_id,
      id,
    };
    // Call to API to get the trait data by ID
    return centralApi(
      "GET",
      API_ENDPOINTS.GET_TRAIT_BY_ID_URL,
      null,
      params
    ).then((data) => {
      return data;
    });
  }
);

// Slice for managing computed trait state
const computedTraitSlice = createSlice({
  name: "computedTrait",
  initialState,
  reducers: {
    // Action to set computed trait data
    setComputedTraitData(state, action) {
      state.data = { ...state.data, ...action.payload };
    },
    // Action to reset computed trait data to initial state
    resetComputedTrait(state) {
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    // Handle pending state of fetchComputedTraitById
    builder.addCase(fetchComputedTraitById.pending, (state) => {
      state.status = STATUS.LOADING;
    });
    // Handle fulfilled state of fetchComputedTraitById
    builder.addCase(fetchComputedTraitById.fulfilled, (state, action) => {
      const traitInfo = action.payload.traitInfo.map(
        (condition: TraitInfo) => {
          let modifiedCondition = condition;
          if (condition.operator && condition.value) {
            let modifiedValues = formatPgSqlOperatorAndValues(
              condition.operator,
              condition.value
            );
            modifiedCondition = { ...modifiedCondition, ...modifiedValues };
          }
          return modifiedCondition;
        }
      );
      state.status = STATUS.IDLE;
      state.data = { ...action.payload, traitInfo };
    });
    // Handle rejected state of fetchComputedTraitById
    builder.addCase(fetchComputedTraitById.rejected, (state) => {
      state.status = STATUS.ERROR;
    });
  },
});

// Export actions and reducer
export const { setComputedTraitData, resetComputedTrait } =
  computedTraitSlice.actions;
export default computedTraitSlice.reducer;
