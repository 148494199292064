import { Skeleton, SkeletonProps } from "antd";
import  { FC } from "react";
interface SkeletonLoaderProps extends SkeletonProps {
  type?: "default" | "button" | "avatar" | "image" | "node";
  size?: "default" | "large" | "small";
}
const SkeletonLoader: FC<SkeletonLoaderProps> = ({ type, size, ...props }) => {
  return type === "default" ? (
    <Skeleton active {...props} />
  ) : type == "button" ? (
    <Skeleton.Button shape="round" active size={size || "small"} {...props} />
  ) : type == "avatar" ? (
    <Skeleton.Avatar active size={size || "small"} />
  ) : type == "image" ? (
    <Skeleton.Image active/>):type=="node"?<Skeleton.Node  {...props}/>:<></>;
};
export default SkeletonLoader;
