"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseModel = void 0;
const typescript_json_serializer_1 = require("typescript-json-serializer");
class BaseModel {
    // Implementing toString method to serialize object to string
    toString() {
        const payload = new typescript_json_serializer_1.JsonSerializer().serialize(this);
        return JSON.stringify(payload);
    }
    // Implementing fromString method to deserialize object from string
    static fromString(jsonString) {
        return new typescript_json_serializer_1.JsonSerializer().deserialize(jsonString, this);
    }
}
exports.BaseModel = BaseModel;
