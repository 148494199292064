"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OperatorManager = void 0;
const constants_1 = require("../../constants");
// Define operator options for different types
const operatorOptions = {
    mongo: {
        number: [
            {
                label: constants_1.OperatorLabels.EQUALS,
                value: constants_1.MongoOperators.EQUALS,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.GREATER_THAN,
                value: constants_1.MongoOperators.GREATER_THAN,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.LESS_THAN,
                value: constants_1.MongoOperators.LESS_THAN,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.NOT_EQUALS,
                value: constants_1.MongoOperators.NOT_EQUALS,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.LESS_THAN_OR_EQUAL_TO,
                value: constants_1.MongoOperators.LESS_THAN_OR_EQUAL_TO,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.GREATER_THAN_OR_EQUAL_TO,
                value: constants_1.MongoOperators.GREATER_THAN_OR_EQUAL_TO,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.IN,
                value: constants_1.MongoOperators.IN,
                maxValues: undefined, // No limit for IN operator
            },
            {
                label: constants_1.OperatorLabels.NOT_IN,
                value: constants_1.MongoOperators.NOT_IN,
                maxValues: undefined, // No limit for NOT IN operator
            },
            {
                label: constants_1.OperatorLabels.IS_BETWEEN,
                value: constants_1.MongoOperators.IS_BETWEEN,
                maxValues: 2,
            },
            {
                label: constants_1.OperatorLabels.IS_NOT_BETWEEN,
                value: constants_1.MongoOperators.IS_NOT_BETWEEN,
                maxValues: 2,
            },
        ],
        string: [
            {
                label: constants_1.OperatorLabels.EQUALS,
                value: constants_1.MongoOperators.EQUALS,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.NOT_EQUALS,
                value: constants_1.MongoOperators.NOT_EQUALS,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.CONTAINS,
                value: constants_1.MongoOperators.CONTAINS,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.IN,
                value: constants_1.MongoOperators.IN,
                maxValues: undefined, // No limit for IN operator
            },
            {
                label: constants_1.OperatorLabels.NOT_IN,
                value: constants_1.MongoOperators.NOT_IN,
                maxValues: undefined, // No limit for NOT IN operator
            },
            {
                label: constants_1.OperatorLabels.STARTS_WITH,
                value: constants_1.MongoOperators.STARTS_WITH,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.ENDS_WITH,
                value: constants_1.MongoOperators.ENDS_WITH,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.DOES_NOT_START_WITH,
                value: constants_1.MongoOperators.DOES_NOT_START_WITH,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.DOES_NOT_END_WITH,
                value: constants_1.MongoOperators.DOES_NOT_END_WITH,
                maxValues: 1,
            },
        ],
        boolean: [
            {
                label: constants_1.OperatorLabels.EQUALS,
                value: constants_1.MongoOperators.EQUALS,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.NOT_EQUALS,
                value: constants_1.MongoOperators.NOT_EQUALS,
                maxValues: 1,
            },
        ],
    },
    pgSql: {
        number: [
            {
                label: constants_1.OperatorLabels.EQUALS,
                value: constants_1.PgsqlOperators.EQUALS,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.GREATER_THAN,
                value: constants_1.PgsqlOperators.GREATER_THAN,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.LESS_THAN,
                value: constants_1.PgsqlOperators.LESS_THAN,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.NOT_EQUALS,
                value: constants_1.PgsqlOperators.NOT_EQUALS,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.LESS_THAN_OR_EQUAL_TO,
                value: constants_1.PgsqlOperators.LESS_THAN_OR_EQUAL_TO,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.GREATER_THAN_OR_EQUAL_TO,
                value: constants_1.PgsqlOperators.GREATER_THAN_OR_EQUAL_TO,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.IS_BETWEEN,
                value: constants_1.PgsqlOperators.IS_BETWEEN,
                maxValues: 2,
            },
            {
                label: constants_1.OperatorLabels.IS_NOT_BETWEEN,
                value: constants_1.PgsqlOperators.IS_NOT_BETWEEN,
                maxValues: 2,
            },
        ],
        string: [
            {
                label: constants_1.OperatorLabels.EQUALS,
                value: constants_1.PgsqlOperators.EQUALS,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.NOT_EQUALS,
                value: constants_1.PgsqlOperators.NOT_EQUALS,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.CONTAINS,
                value: constants_1.PgsqlOperators.CONTAINS,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.STARTS_WITH,
                value: constants_1.PgsqlOperators.STARTS_WITH,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.ENDS_WITH,
                value: constants_1.PgsqlOperators.ENDS_WITH,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.DOES_NOT_START_WITH,
                value: constants_1.PgsqlOperators.DOES_NOT_START_WITH,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.DOES_NOT_END_WITH,
                value: constants_1.PgsqlOperators.DOES_NOT_END_WITH,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.IN,
                value: constants_1.PgsqlOperators.IN,
                maxValues: undefined, // No limit for IN operator
            },
            {
                label: constants_1.OperatorLabels.NOT_IN,
                value: constants_1.PgsqlOperators.NOT_IN,
                maxValues: undefined, // No limit for NOT IN operator
            },
        ],
        boolean: [
            {
                label: constants_1.OperatorLabels.EQUALS,
                value: constants_1.PgsqlOperators.EQUALS,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.NOT_EQUALS,
                value: constants_1.PgsqlOperators.NOT_EQUALS,
                maxValues: 1,
            },
        ],
        date: [
            {
                label: constants_1.OperatorLabels.EQUALS,
                value: constants_1.PgsqlOperators.EQUALS,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.NOT_EQUALS,
                value: constants_1.PgsqlOperators.NOT_EQUALS,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.GREATER_THAN,
                value: constants_1.PgsqlOperators.GREATER_THAN,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.LESS_THAN,
                value: constants_1.PgsqlOperators.LESS_THAN,
                maxValues: 1,
            },
            {
                label: constants_1.OperatorLabels.IS_BETWEEN,
                value: constants_1.PgsqlOperators.IS_BETWEEN,
                maxValues: 2,
            },
            {
                label: constants_1.OperatorLabels.IS_NOT_BETWEEN,
                value: constants_1.PgsqlOperators.IS_NOT_BETWEEN,
                maxValues: 2,
            },
        ],
    }
};
class OperatorManager {
    /**
     * Retrieves MongoDB operator options based on the given type.
     *
     * @param type - The type of the operator (e.g., number, numeric, boolean).
     * @returns An object containing the type and corresponding MongoDB operator options.
     */
    static getMongoOperatorOptions(type) {
        switch (type) {
            case constants_1.OperatorTypeEnum.NUMBER:
            case constants_1.OperatorTypeEnum.NUMERIC:
            case constants_1.OperatorTypeEnum.INTEGER:
                // Return operator options for numeric types
                return { type: constants_1.OperatorTypeEnum.NUMBER, options: operatorOptions.mongo.number };
            case constants_1.OperatorTypeEnum.STRING:
            case constants_1.OperatorTypeEnum.CHARACTER_VARYING:
            case constants_1.OperatorTypeEnum.CHARACTER:
            case constants_1.OperatorTypeEnum.DATE:
                // Return operator options for string types
                return { type: constants_1.OperatorTypeEnum.STRING, options: operatorOptions.mongo.string };
            case constants_1.OperatorTypeEnum.BOOLEAN:
                // Return operator options for boolean type
                return { type: constants_1.OperatorTypeEnum.BOOLEAN, options: operatorOptions.mongo.boolean };
            default:
                // Return an object with the type and an empty options array for unrecognized types
                return { type, options: [] };
        }
    }
    /**
     * Retrieves PostgreSQL operator options based on the given type.
     *
     * @param type - The type of the operator (e.g., number, date, character varying).
     * @returns An object containing the type and corresponding PostgreSQL operator options.
     */
    static getPgSqlOperatorOptions(type) {
        switch (type) {
            case constants_1.OperatorTypeEnum.NUMBER:
            case constants_1.OperatorTypeEnum.NUMERIC:
            case constants_1.OperatorTypeEnum.INTEGER:
                // Return operator options for numeric types
                return { type: constants_1.OperatorTypeEnum.NUMBER, options: operatorOptions.pgSql.number };
            case constants_1.OperatorTypeEnum.STRING:
            case constants_1.OperatorTypeEnum.CHARACTER_VARYING:
            case constants_1.OperatorTypeEnum.CHARACTER:
                // Return operator options for string types
                return { type: constants_1.OperatorTypeEnum.STRING, options: operatorOptions.pgSql.string };
            case constants_1.OperatorTypeEnum.BOOLEAN:
                // Return operator options for boolean type
                return { type: constants_1.OperatorTypeEnum.BOOLEAN, options: operatorOptions.pgSql.boolean };
            case constants_1.OperatorTypeEnum.DATE:
                return { type: constants_1.OperatorTypeEnum.DATE, options: operatorOptions.pgSql.date };
            default:
                // Return an object with the type and an empty options array for unrecognized types
                return { type, options: [] };
        }
    }
    /**
     * "getOperatorLabel" Retrieves the label of an operator based on its value.
     * @param value - The value of  operator , this can be a MongoDB or PostgreSQL operator value.
     * @returns The label of the operator if found, otherwise returns the original value.
     */
    static getOperatorLabel(value) {
        // Create a unified list of all operator options from MongoDB and PostgreSQL
        const allOperators = [
            ...Object.values(operatorOptions.mongo).flat(),
            ...Object.values(operatorOptions.pgSql).flat(),
        ];
        // Search through all operators to find the one with the matching value
        const operator = allOperators.find((option) => option.value === value);
        // Return the label if the operator is found, otherwise return the original value
        return operator ? operator.label : value;
    }
}
exports.OperatorManager = OperatorManager;
