
import apiRequest from "../services/apiRequest";
import {getRandomNumber} from "helperFunctions/number"
// get funnel data
export const getEventFunnels = ({dateRange,appId,apiKey}:any) => {
  const url: string = `/o/funnels/getEventFunnels`;
  return apiRequest
    .get(url, {
      params: {
        app_id: appId,
        api_key: apiKey,
        d: dateRange,
        uniqueNumber:getRandomNumber()

      },

    })
    .then((res) => {
      const data = res.data.map((fun: any) => {
        return {
          Key: fun._id,
          Funnel_Name: fun.name,
          Funnel_Description: fun.description,
          Action: fun._id,
          PlatForm: fun.platform,
          createdOn: fun.createdOn,
          modifiedOn: fun.modifiedOn,
        };
      });
      return data;
    })
    .catch((e) => {
      throw new Error(e.message);
    });
};
//create funnel
export const createFunnel = ({data,appId,apiKey,csrfToken}:any) => {
  const url: string = `/i/funnels/create`;
  return apiRequest
    .post(
      url,
      {
        app_id: appId,
        api_key: apiKey,
      },
      {
        params: {
          app_id: appId,
          api_key: apiKey,
          args: JSON.stringify(data),
        },
        headers:{
          "csrf-token":csrfToken
        }
      }
    )
    .then((res) => {
      if (res.data.result.ok && res.data.result.n) {
        return { message: "Funnel Created Successfully", status: 1 };
      } else {
        return { message: "Something Went Wrong", status: 0 };
      }
    })
    .catch((e) => {
      throw new Error(e.message);
    });
};
//delete the funnel
export const deleteFunnel = ({funnelId,appId,apiKey}:any) => {
  const url: string = `/i/funnels/delete`;
  return apiRequest
    .get(url, {
      params: {
        app_id: appId,
        api_key: apiKey,
        id: funnelId,
      },
    })
    .then((res) => {
      return { status: res.data };
    })
    .catch((e) => {
      throw new Error(e.message);
    });
};
//copy the funnel
export const copyFunnel = ({funnelId,appId,apiKey}:any) => {
  const url: string = `/i/funnels/copy`;
  return apiRequest
    .get(url, {
      params: {
        app_id: appId,
        api_key: apiKey,
        id: funnelId,
      },
    })
    .then((res) => {
      return { status: res.data };
    })
    .catch((e) => {
      throw new Error(e.message);
    });
};
//get funnel by id
export const getFunnel = ({funnelId,appId,apiKey}:any) => {
  const url: string = `/i/funnels/getFunnel`;
  return apiRequest
    .get(url, {
      params: {
        app_id: appId,
        api_key: apiKey,
        id: funnelId,
      },
      withCredentials:true

    })
    .then((res) => {
     return res.data
    })
    .catch((e) => {
      throw new Error(e.message);
    });
};
export const updateFunnel = ({data,appId,apiKey,funnelId,csrfToken}:any) => {
  const url: string = `/i/funnels/update`;
  return apiRequest
    .post(url,
      {
        app_id: appId,
        api_key: apiKey,
      },
      {
        params: {
          app_id: appId,
          api_key: apiKey,
          args: JSON.stringify(data),
          id:funnelId
        },
        headers:{
          "csrf-token":csrfToken
        }
      })
    .then((res) => {
      if (res.data==200) {
        return { message: "Funnel Updated Successfully", status: 1 };
      } else {
        return { message: "Something Went Wrong", status: 0 };
      }
    })
    .catch((e) => {
      throw new Error(e.message);
    });
};
