import React, { useEffect, useState } from "react";
import Selector from "../../components/Selector/Selector";
import "./Events.css";
import { SizeType } from "antd/es/config-provider/SizeContext";
import centralApi from "../../services/centralApi";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { t } from "i18next";
import { getToken } from "../../services/getToken";
import WhatDoUserDo from "./WhatDoUserDo";
import DrillDownAttributes from "./DrillDownAttributes";
import { DateRangeSelector } from "components/DateTimeSelectors/DateRangeSelector/DateRangeSelector";
import { DateRangeData } from "@customTypes/dateRange";
type propTypes = {
  pageSize?: number;
  tableSize?: SizeType;
};

const Events: React.FC<propTypes> = () => {
  const [statsData, setStatsData] = useState<{
    android: any;
    ios: any;
    web: any;
  }>({
    android: {},
    ios: {},
    web: {},
  });

  const { appId } = useSelector((state: any) => state.activeApp);
  const excludedKeys = [
    "date",
    "_App_Stop",
    "_App_Start",
    "Session_Start",
    "Session_End",
    "App_Foreground",
    "App_Background",
    "total",
    "Campaign_Clicked",
    "Campaign_Received",
    "Campaign_Deleted",
    "Campaign_Viewed",

    "Churn",
  ];

  const loginUser = useSelector((state: any) => state.loginUser);
  // const { endDate_C, endDate_CNew, startDate_C, startDate_CNew } = useSelector(
  //   (state: any) => state.dateRange
  // );
  
  const api_key = loginUser.data.api_key;
  const [dateRange, setDateRange] =
    useState<DateRangeData | null>(null);

  const [loading, setLoading] = useState(false);
  const [platform, setPlatform] =
    useState<"all" | "android" | "ios" | "web">("all");
    const { endDate_C, endDate_CNew, startDate_C, startDate_CNew } = dateRange || {}

  async function getEventsStats() {
    const payload = {
      args: JSON.stringify({
        startDate: startDate_C,
        endDate: endDate_C,
        startDateNew: startDate_CNew,
        endDateNew: endDate_CNew,
      }),
      app_id: appId,

      api_key: api_key,
    };
    const url: string = `/o/metrices/getEventsStats`;
    if (appId && api_key) {
      try {
        setLoading(true);
        const data = await centralApi("GET", url, null, payload);
        setStatsData(data);

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  }
  useEffect(() => {
    if (appId && api_key && dateRange) {
      getEventsStats();

      getToken(appId, api_key);
    }
  }, [loginUser, endDate_C, platform, appId, api_key,dateRange]);

  const styleBtn = {
    width: "auto",
    marginRight: "10px",
    marginBottom: "10px",
  };
  const handleDateRange = (dateRangeData: any) => {
    
    setDateRange(dateRangeData);
  };

  return (
    <div className="container-fluid p-sm-2 p-1 ">
      <Helmet>
        <title>Appice | Events</title>
      </Helmet>
      <div className="d-flex justify-content-between align-items-center py-3 flex-wrap">
        <div>
          <h4>{t("eventDataLabel")}</h4>
        </div>
        <div className="d-flex">
          <div>
            <strong>{t("platformLabel")} </strong>
            <Selector
              onChange={(value) => {
                setPlatform(value);
              }}
              size="middle"
              defaultValue={platform}
              style={{ ...styleBtn, width: "90px" }}
              options={[
                {
                  value: "all",
                  label: "All",
                },
                {
                  value: "android",
                  label: "Android",
                },
                {
                  value: "ios",
                  label: "IOS",
                },
                {
                  value: "web",
                  label: "Web",
                },
              ]}
            />
          </div>
          <div>
            <strong>{t("periodLabel")} </strong>{" "}
            <DateRangeSelector
              onDateChange={handleDateRange}
              disableRange={{ range: 3, rangeType: "month" }}
              disableType="beforeMonths"
              numDaysToShow={30} // Example value
            />
          </div>
        </div>
      </div>
      {/* {loading ? (
<Spinner />
) : ( */}
      <div className="px-sm-3 px-1 events-container">
        <WhatDoUserDo
          eventStatsData={statsData}
          platformVersion={platform}
          excludedKeys={excludedKeys}
          loading={loading}
          dateRange={dateRange}
        />
        <DrillDownAttributes
          platformVersion={platform}
          excludedKeys={excludedKeys}
          dateRange={dateRange}
          loadings={loading}
        />
      </div>
      {/* )} */}
    </div>
  );
};

export default Events;
