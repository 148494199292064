import { Table } from 'antd'
import "./CustomTable.css"
function CustomTable(props:any) {
  return (
    
      <Table
         {...props}
         pagination={props?.pagination ? {
          showSizeChanger: false,
          ...(props?.pagination),
          showTotal: props?.pagination?.showTotal ? props.pagination.showTotal : 
            (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries ${props.totalEntries && props.totalEntries!==total  ? `(filtered from ${props.totalEntries} total entries)` : ""}`,
        } : false}
        
         className='custom-table-container'
         
        />
  
  )
}

export default CustomTable
