import { Card } from "antd";
import React from "react";
import "./AppUserChunks.css";
import audienceIcon from "../../../images/audience.svg";
import "../../../global.css";
interface AppUserChunksProps {
  name: string;
  data: number;
  showImg?: boolean;
}

const AppUserChunks: React.FC<AppUserChunksProps> = ({
  name,
  data,
  showImg=true,
}) => {
  // function AppUserChunks({name,data}:any) {
  return (
    <div
      className="w-100"
      id="chunk-wrapper"
      style={{ margin: 5, minWidth: "200px" }}
    >
      <Card style={{ width: "100%", background: "aliceblue" }} className="p-0">
        <div className="d-flex justify-content-between align-items-center h-100 p-0">
          <div
            className="h-100 d-flex flex-column"
            style={{ color: "var(--color-text-black)" }}
          >
            <span
          
              style={{ fontSize: "var(--font-size-large)", fontWeight: "500" }}
            >
              {name}
            </span>
            <span
            className="app-user-chunk-data"
             
            >
              {data}
            </span>
          </div>
          {showImg && (
            <div>
              <img src={audienceIcon} alt="icon" width={60} />
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default AppUserChunks;
