import { ReactPropTypes } from 'react';
import CountUp from 'react-countup';
type propTypes={
end:number;
duration:number;
style?:React.CSSProperties
className?:string
}
function Counter({end,duration,className,style}:propTypes) {
  return (
    <CountUp className={className} style={style} end={end} duration={duration}></CountUp>
  )
}

export default Counter