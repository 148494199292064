import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../../constant";
import centralApi from "../../../services/centralApi";
export const fetchCustomVars = createAsyncThunk(
  "customMetaVars/fetchCustomVars",
  async ({api_key, app_id}:any) => {
    const params = {
      api_key,
      app_id,
      method: "get_metacustomvariables",
    };

    return centralApi(
      "GET",
      "o/aud/get_metacustomvariables",
      null,
      params
    ).then(({ result }) => {
      const modifiedResult: { label: string; value: string;type:string;}[] = [];
      result?.forEach((item: { name: string; type: string }) => {
        const length = "_context_".length - 1;
        const label = item.name.slice(length);
        if (label.length !== 0) {
          modifiedResult.push({
            label,
            value: item?.name,
            type:item.type
          });
        }
      });
      return modifiedResult.filter((item, index, self) => {
        const key="value"
        return self.findIndex(obj => obj[key] === item[key]) === index;
    });
    });
  }
);
const initialState:any= {
  data: [],
  status: STATUS.IDLE,
}
const customMetaVarsSlice = createSlice({
  name: "customMetaVars",
 initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchCustomVars.pending, (state) => {
      state.status = STATUS.LOADING;
    });
    builder.addCase(fetchCustomVars.fulfilled, (state,action) => {
      state.status = STATUS.IDLE;
      state.data=action.payload
    });
    builder.addCase(fetchCustomVars.rejected, (state) => {
      state.status = STATUS.ERROR;
    });
  },
  reducers: {},
});
export default customMetaVarsSlice.reducer;

