import { Divider } from "antd";
import "./FileEncryption.css";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { Form } from "antd";
import Selector from "../../../../components/Selector/Selector";
import TextInput from "../../../../components/Inputs/TextInput/TextInput";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import centralApi from "../../../../services/centralApi";
import { getToken } from "../../../../services/getToken";
import ShowMessage from "../../../../components/ShowMessage/ShowMessage";
import Spinner from "../../../../components/Spinner/Spinner";
import { t } from "i18next";

interface FileEncryptionProps {
  currentAppKey: any;
  updateCurrentAppKey: () => void;
}

const FileEncryption: React.FC<FileEncryptionProps> = ({
  currentAppKey,updateCurrentAppKey

}) => {
  const [form] = Form.useForm();
  const [showEncryptionKey, setShowEncryptionKey] = useState(false);
  // State to control the visibility of the encryption key field
  const [loading, setLoading] = useState(false);

  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  });
  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;
  const onFormSubmit = (values: any) => {
    const payload = {
      args: JSON.stringify({
        ...values,

        app_id: appId,
      }),

      app_id: appId,
      api_key: api_key,
    };
    saveEncryptionStatus(payload);
    getToken(appId,api_key)

  };

  async function saveEncryptionStatus(formData: any) {
    const url: string = `/i/apps/saveEncryptionStatus`;

    try {
      setLoading(true);
      const data = await centralApi("POST", url, formData, null);
      setShowMessage({
        type: "success",
        message: t("sucessfullySavedLabel"),
        show: true,
      });
      setLoading(false);
      updateCurrentAppKey();

    } catch (error) {
      setLoading(false);
      setShowMessage({
        type: "error",
        message: t("somethingWrongLabel"),
        show: true,
      });
    }
    hideMessage();
  }
  const hideMessage = () => {
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false });
    }, 2000);
  };

  useEffect(() => {
    getToken(appId, api_key);
  }, []);
  useEffect(() => {
    // Set initial form values when currentAppKey changes
    const updatedFormValues = {
      encryptionStatus:currentAppKey[0]?.encryptionStatus,
      encryptionKey:currentAppKey[0]?.encryptionKey
    };
    form.setFieldsValue(updatedFormValues);
    setShowEncryptionKey(updatedFormValues.encryptionStatus === "Yes");

    }, [currentAppKey,form]);
  const handleFileEncryption = (value: string) => {
    setShowEncryptionKey(value === "Yes");
  };
  return (
    <div className="file-encryption-setting-container">
      <div className="mt-4 mb-4  ">
        <h6 className="file-encryption-setting-container-heading">
          {t("fileEncryptionSettingLabel")}
        </h6>
        <Divider className="file-encryption-setting-custom-divider" />
      </div>

      <div className="  mt-4 align-items-center file-encryption-setting-form ">
        <div className="mb-4">
          <p>{t("encryptionStatusLabel")}</p>
          <div className="col-sm-4 ">
          {showMessage.show && (
              <ShowMessage
                type={showMessage.type}
                content={showMessage.message}
              />
            )}
            {loading ? (
              <Spinner />
            ) : (
            <Form
              form={form}
              layout="horizontal"
              onFinish={onFormSubmit}
            >
              <Form.Item
                name={"encryptionStatus"}
                rules={[{ required: true, message: "Please select  Mode" }]}
              >
                <Selector
                  onChange={handleFileEncryption}
                  size="middle"
                  style={{ width: "100%" }}
                  // defaultValue={"Select"}
                  options={[
                    {
                      value: "",
                      label: t("selectLabel")
                    },
                    {
                      value: "Yes",
                      label: t("yesLabel"),
                    },
                    {
                      value: "No",
                      label: t("noLabel"),
                    },
                  ]}
                />
              </Form.Item>
              {showEncryptionKey && ( // Conditional rendering of the encryption key field
                <Form.Item
                  name="encryptionKey"
                  rules={[
                    {
                      required: true,
                      message: t("plzInputLabel") as string,
                    },
                  ]}
                >
                  <TextInput placeholder= {t("encryptionKeyLabel")}/>
                </Form.Item>
              )}
              <PrimaryButton
                size="middle"
                type="primary"
                htmlType="submit"
                style={{ border: "none", width: "70px" }}
              >
                {t("saveLabel")}
              </PrimaryButton>
            </Form>)}
          </div>
        </div>
      </div>
    </div>
  );
};
export default FileEncryption;
