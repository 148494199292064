import { Form } from "antd"
import CustomModal from "../../../components/Models/CustomModel/CustomModel"
import React from "react"
type propTypes = {
  openModal?: any
  setModalOpen?: any
}
const SyncModal: React.FC<propTypes> = ({ openModal, setModalOpen }) => {
 
  
  const [form] = Form.useForm() // Create a form instance
  
  const handleCancel = () => {
    form.resetFields() // Reset the form fields after successful download

    setModalOpen(false)
  }

  return (
    <div>
      <CustomModal
      
        title="Sync your templates from facebook business manager"
        open={openModal}
        setVisibility={setModalOpen}
        onOk={handleCancel}
        onCancel={handleCancel}
        okText="Sync Templates"
        cancelText="Cancel"
      >
        <div>
          <p>
            Do you want to sync your template from facebook business manager?
            <span>Templates that are incompatible will not be synced</span>
          </p>
          <p>Syncing can take anywhere from a few seconds or upto 10 mins</p>
          <p>Last synced date: Yesterday at 2:30pm</p>
        </div>
      </CustomModal>
    </div>
  )
}

export default SyncModal
