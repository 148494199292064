import { createAsyncThunk, createSlice, } from "@reduxjs/toolkit";
import { STATUS } from "../../constant";
import centralApi from "services/centralApi";
export const logoutUser = createAsyncThunk(
  "loginUser/logout",
  async () => {
    return centralApi(
      "GET",
      "/i/logout",
    ).then((data) => { 
      return data;
    });
  }
);
export const fetchLoginUser = createAsyncThunk(
  "loginUser/getUser",
  async () => {
    return centralApi(
      "GET",
      "/o/users/me",
    ).then((data) => { 
      return data;
    });
  }
);
const loginUserSlice = createSlice({
  name: "loginUser",
  initialState: {
    data: {},
    status: STATUS.IDLE,
  },
  extraReducers: (builder) => {
    //for logout  ********
    
    builder.addCase(logoutUser.pending, (state:any) => {
      state.status = STATUS.LOADING;
    });
    builder.addCase(logoutUser.fulfilled, (state:any) => {
      state.status = STATUS.LOGGEDOUT;
      state.data={}
    });
    builder.addCase(logoutUser.rejected, (state:any) => {
      state.status = STATUS.LOGGEDOUT;
      state.data={}
    });
    //******** */

    //login user *****
    builder.addCase(fetchLoginUser.pending, (state:any) => {
      state.status = STATUS.LOADING;
    });
    builder.addCase(fetchLoginUser.fulfilled, (state,action) => {
      state.status =STATUS.IDLE
      state.data=action.payload
    });
    builder.addCase(fetchLoginUser.rejected, (state:any) => {
      state.status = STATUS.ERROR;
      state.data={}
    });
    //******** */
  },
  reducers: {
  },
});
export default loginUserSlice.reducer;
