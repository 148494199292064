import React from 'react';
import { CaretUpOutlined, CaretDownFilled } from '@ant-design/icons';

interface HeaderCountPercentageProps {
  total: number;
  percentage: string;
  upDownStatus: number;
}

function HeaderCountPercentage({ total, percentage, upDownStatus }: HeaderCountPercentageProps) {
  return (
    <div className="d-flex justify-content-between position-absolute w-100 top-0 end-0 p-2">
      <h6>{total}</h6>
      <div className="d-flex gap-1 align-items-center">
        {upDownStatus > 0 ? (
          <CaretUpOutlined style={{ color: "var(--color-success)" }} />
        ) : upDownStatus < 0 ? (
          <CaretDownFilled style={{ color: "var(--color-red-main)" }} />
        ) : null}
        <h6>{percentage}</h6>
      </div>
    </div>
  );
}

export default HeaderCountPercentage;
