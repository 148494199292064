import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { STATUS } from "../../constant"
import centralApi from "../../../services/centralApi"

export const getFeaturesSettings = createAsyncThunk(
  "app/featuresSetting",
  async ({api_key, app_id}:any) => {
    const params = {
      args:JSON.stringify({
        app_id
      }),
      api_key,
      app_id,
    };
    return centralApi(
      "GET",
      "/o/apps/getFeaturesSettings",
      null,
      params
    ).then((data) => {
      return data
    })
  }
)
const initialState: any = {
  data: [],
  status: STATUS.IDLE,
}
const featuresSettingSlice = createSlice({
  name: "featuresSetting",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getFeaturesSettings.pending, (state) => {
      state.status = STATUS.LOADING
    })
    builder.addCase(getFeaturesSettings.fulfilled, (state, action) => {
      state.status = STATUS.IDLE
      state.data = action.payload
    })
    builder.addCase(getFeaturesSettings.rejected, (state) => {
      state.status = STATUS.ERROR
    })
  },
  reducers: {},
})
export default featuresSettingSlice.reducer
