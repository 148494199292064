import centralApi from "../services/centralApi";

export const getObjectId = async (appID: string, apiKey: string) => {
  const appId = appID;
  const api_key = apiKey;
  const payload = {
    app_id: appId,
    api_key: api_key,
  };

  const url = `o/metrices/getObjectId`;
  try {
    const id = await centralApi("GET", url, null, payload);
    return id;
  } catch (error) {
    throw error;
  }
};
