import { Steps } from "antd"
import { useEffect } from "react"
import SelectAudience from "./components/SelectAudience/SelectAudience"
import LaunchCampaign from "./components/LaunchCampaign/LaunchCampaign"
import "./CampaignCreate.css"
import PrimaryButton from "../../../components/Buttons/PrimaryButton/PrimaryButton"
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons"
import EngagementToolHome from "./components/EngagementTool/EngagementToolHome/EngagementToolHome"
import { useDispatch, useSelector } from "react-redux"
import { setCurrentStep } from "../../../redux/features/campaign/campaignCreateCurrentStepSlice"
import {
  fetchCampaignById,
  setCampaignData,
} from "../../../redux/features/campaign/campaignDataSlice"
import { fetchChildApps } from "../../../redux/features/app/childAppsSlice"
import { Link, useLocation } from "react-router-dom"
import { fetchAudienceSegments } from "../../../redux/features/app/audienceSegmentsSlice"
import CustomBreadCrumb from "../../../components/CustomBreadCrumb/CustomBreadCrumb"
import { setSelectedTemplate } from "redux/features/templates_/templateByIdSlice"

function CampaignCreate() {
  const dispatch: any = useDispatch()
  const currentStep = useSelector(
    (state: any) => state.campaignCreateCurrentStep
  )
  const loginUser = useSelector((state: any) => state.loginUser.data)
  const { appId } = useSelector((state: any) => state.activeApp)
  const { state } = useLocation()
  const campaignData = useSelector((state: any) => state.campaignData.data)
  const steps: any = [
    {
      title: <strong>Create Message</strong>,
      status: campaignData?.tid || currentStep == 0 ? "finish" : "error",
      content: <EngagementToolHome />,
    },
    {
      title: <strong>Select Audience</strong>,
      status: campaignData?.aud
        ? "finish"
        : currentStep <= 1
        ? "wait"
        : "error",
      content: <SelectAudience />,
    },
    {
      title: <strong>Launch Campaign</strong>,
      status: campaignData?.nm ? "finish" : "wait",
      content: <LaunchCampaign />,
    },
  ]
  useEffect(() => {
    if (loginUser.api_key && appId) {
      if (state?.cid && state?.type) {
        dispatch(
          fetchCampaignById({
            app_id: appId,
            api_key: loginUser.api_key,
            args: {},
            cid: state.cid,
          })
        )
      }
      dispatch(
        fetchAudienceSegments({
          api_key: loginUser.api_key,
          app_id: appId,
          method: "getAudienceSegments",
        })
      )
      dispatch(
        fetchChildApps({
          api_key: loginUser.api_key,
          app_id: appId,
        })
      )
    }
    return () => {
      dispatch(setCurrentStep(0))
    }
  }, [state, loginUser])
  const items = steps.map((item: any) => ({ key: item.title, ...item }))
  const onChange = (value: number) => {
    dispatch(setCurrentStep(value))
  }
  return (
    <div className="campaign-create-page position-relative p-1">
      <div className="mx-1 pb-2">
        <CustomBreadCrumb
          items={[
            {
              title: <Link to="/dashboard/campaign"> All campaigns</Link>,
            },
            {
              title: (
                <Link
                  to=""
                  onClick={() => {
                    //reset campaign state in redux
                    dispatch(setCampaignData({}))
                    dispatch(setSelectedTemplate(null))
                    dispatch(setCurrentStep(0))
                  }}
                >
                  create
                </Link>
              ),
            },
            campaignData && {
              title: (
                <Link to="" className="text-lowercase">
                  {campaignData && campaignData.t}
                </Link>
              ),
            },
          ]}
        />
      </div>
      <div className=" d-flex align-items-center justify-content-center campaign-create-page-header">
        {campaignData?.t && (
          <Steps
            className="m-0 p-0 pb-2 w-50"
            // type="navigation"
            size="small"
            onChange={onChange}
            current={currentStep}
            items={items}
          />
        )}
      </div>
      <div className="campaign-create-page-current-body  p-2 ">
        {steps[currentStep].content}
      </div>
      <div className="d-flex w-100 justify-content-between pt-1">
        <PrimaryButton
          onClick={() => dispatch(setCurrentStep(currentStep - 1))}
          disabled={currentStep == 0}
        >
          <ArrowLeftOutlined className="mx-1" />
        </PrimaryButton>
        <PrimaryButton
          onClick={() => dispatch(setCurrentStep(currentStep + 1))}
          disabled={currentStep == items.length - 1}
        >
          {" "}
          <ArrowRightOutlined className="mx-1" />
        </PrimaryButton>
      </div>
    </div>
  )
}

export default CampaignCreate
