import { Collapse } from "antd";
import React, { FC } from "react";
const { Panel } = Collapse;
interface CustomCollapseProps {
  header: React.ReactNode;
  body: React.ReactNode;
  props?: any;
  ghost?: boolean;
  defaultActiveKey?: string | string[]; // Allow initial open panel(s)

}

const CustomCollapse: FC<CustomCollapseProps> = ({
  header,
  body,
  ghost,
  defaultActiveKey,

  ...props
}) => {
  return (
    <Collapse
      {...props}
      ghost={ghost}
      size="small"
      className="w-100"
      accordion
      expandIconPosition="right"
      defaultActiveKey={defaultActiveKey} // Set initial open panel(s)

    >
      <Panel header={header} key="1">
        {body}
      </Panel>
    </Collapse>
  );
};

export default CustomCollapse;
