import { FC, useState, useEffect } from "react";
import { Col, Form, Radio, Row, message } from "antd";
import FormItem from "antd/es/form/FormItem";
import Selector from "components/Selector/Selector";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import TextInput from "components/Inputs/TextInput/TextInput";
import { useForm } from "antd/es/form/Form";
import "./PushGenAi.css";
import CustomTextArea from "components/Inputs/CustomTextArea/CustomTextArea";
import MessagePreview from "./components/MessagePreview/MessagePreview";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { fetchTemplatesList } from "redux/features/templates_/templatesByTypeSlice";
import { fetchTemplateById } from "redux/features/templates_/templateByIdSlice";
interface PushGenAiProps {}

const PushGenAi: FC<PushGenAiProps> = ({}) => {
  const [form] = useForm();
  const [type, setType] = useState(0);
  const dispatch: any = useDispatch();
  const [keyword, setKeyWord] = useState("Rewards");
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const [formData, setFormData] = useState({
    title: "",
    body: "",
  });
  const [tone, setTone] = useState("");
  const [exclude, setExclude] = useState("");
  const [writingStyle, setWritingStyle] = useState("default");

  const [loading, setLoading] = useState(false);
  const templates = useSelector((state: any) => state.templatesList.data);
  const [prompt, setPrompt] = useState("");
  const selectedTemplate = useSelector((state: any) => state.templateById.data);
  const [resData, setResData] = useState<any>([]);

  const callApi = async () => {
    try {
      setLoading(true);
      const data = {
        payload: `You are an expert copywriter in the marketing department of a Saudi Arabian Retail Bank. You are given the examples earlier of push notifications for a Saudi Arabian Retail Bank. You can use emojis in title and body also. Please put the emojis in the middle or end of the title or body. Please give examples of a push notification for the following keywords ${keyword}. Use the following prompt to guide you:${prompt} . Exclude the following keywords from the title and body + ${exclude}. The tone and voice of the notification should be ${tone} , writing style should be ${writingStyle},should contain any word from ${formData?.title}`,
        context: [formData],
      };
      const res = await axios.post(
        "https://motaai.appice.io/api/v1/create_notification",
        data
      );
      // console.log(res)
      // Store the new data in a variable
      const newData = res.data;

      // Update modifiedData array
      setResData((prevData: any) => {
        const updatedData = [...prevData]; // Copy previous data
        updatedData.push(newData); // Add new data to the beginning
        if (updatedData.length > 2) {
          updatedData.shift(); // Remove the last element if more than 2 entries
        }
        return updatedData; // Return the updated array
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loginUser.api_key) {
      dispatch(
        fetchTemplatesList({
          app_id: appId,
          api_key: loginUser.api_key,
          template_type: "PUSH",
        })
      );
    }
  }, [loginUser]);
  useEffect(() => {
    if (selectedTemplate) {
      if (Array.isArray(selectedTemplate.template)) {
        setFormData({
          title: selectedTemplate.template[0]?.notificationHeader,
          body: selectedTemplate.template[0]?.notificationDescription,
        });
      } else {
        setFormData({
          title: selectedTemplate.template?.notificationHeader,
          body: selectedTemplate.template?.notificationDescription,
        });
      }
    }
  }, [selectedTemplate]);

  return (
    <div className=" push-gen-container m-2 mt-3 row">
      <div className="col-5 px-4 mt-3">
        <div>
          <strong>
            Xmit AI blends the creative excellence with campaign performance
            insights to maximize your engagement rates
          </strong>
        </div>
        <Form
          colon={false}
          labelAlign="left"
          labelCol={{ span: 3 }}
          layout="horizontal"
          form={form}
        >
          <Row>
            <p className="my-2">Generate campaign with:</p>
            <Col className="d-flex my-2  gap-1" span={24}>
              <Radio.Group
                value={type}
                onChange={(e: any) => {
                  setPrompt("");
                  setFormData({ title: "", body: "" });
                  setType(e.target.value);
                }}
              >
                <Radio value={0}>Custom prompt</Radio>
                <Radio value={1}>Existing campaign variant</Radio>
              </Radio.Group>
            </Col>
            {type == 1 ? (
              <>
                <Col className="d-flex  " span={24}>
                  <FormItem
                    className="w-100"
                    labelCol={{ flex: "60%" }}
                    label="Generate Push campaign based on *"
                  >
                    <Selector
                      placeholder="Select"
                      onChange={(tempString) =>
                        dispatch(
                          fetchTemplateById({
                            app_id: appId,
                            api_key: loginUser.api_key,
                            template_id: JSON.parse(tempString)._id,
                          })
                        )
                      }
                      options={templates.map(
                        ({
                          template_name,
                          _id,
                        }: {
                          template_name: string;
                          _id: string;
                        }) => ({
                          label: template_name,
                          value: JSON.stringify({ _id, template_name }),
                        })
                      )}
                      // style={{ width: 150 }}
                    />
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem
                    label="Title"
                    labelCol={{ flex: "20%" }}
                    className="w-100"
                  >
                    <TextInput
                      value={formData?.title}
                      onChange={(e: any) =>
                        setFormData((prevState) => ({
                          ...prevState,
                          title: e.target.value,
                        }))
                      }
                      placeholder="Title"
                    />
                  </FormItem>
                  <FormItem
                    label="Message"
                    labelCol={{ flex: "20%" }}
                    className="w-100"
                  >
                    <TextInput
                      value={formData?.body}
                      onChange={(e: any) =>
                        setFormData((prevState) => ({
                          ...prevState,
                          body: e.target.value,
                        }))
                      }
                      placeholder="Message"
                    />
                  </FormItem>
                </Col>
              </>
            ) : (
              <FormItem className="w-100">
                <strong>Prompt for Xmit AI</strong>
                <span className="my-1"></span>
                <CustomTextArea
                  value={prompt}
                  onChange={(e: any) => setPrompt(e.target.value)}
                  placeholder="Human,type here"
                />
              </FormItem>
            )}
            <Col className="d-flex  " span={24}>
              <FormItem
                className="w-100"
                labelCol={{ flex: "50%" }}
                label="Include high performing keywords"
              >
                <Selector
                  value={keyword}
                  onChange={(value) => setKeyWord(value)}
                  options={[
                    { label: "Prices", value: "Prices" },
                    { label: "Coupons", value: "Coupons" },
                    { label: "Special Deals", value: "Special Deals" },
                    { label: "Rewards", value: "Rewards" },
                    { label: "Cashback", value: "Cashback" },
                    { label: "Points", value: "Points" },
                    { label: "Bonus Miles", value: "Bonus Miles" },
                    {
                      label: "Introductory offers",
                      value: "Introductory offers",
                    },
                    { label: "Perks", value: "Perks" },
                    { label: "Benefits", value: "Benefits" },
                    { label: "Sign-up bonuses", value: "Sign-up bonuses" },
                    { label: "Lounge access", value: "Lounge access" },
                    {
                      label: "Concierge services",
                      value: "Concierge services",
                    },
                  ]}
                  placeholder="Select keywords to include"
                />
              </FormItem>
            </Col>
            <Col className="d-flex  " span={24}>
              <FormItem
                className="w-100"
                labelCol={{ flex: "50%" }}
                label="and exclude keywords"
              >
                <TextInput
                  placeholder="Type keywords to exclude"
                  onChange={(e: any) => setExclude(e.target.value)}
                  // style={{ width: 150 }}
                />
              </FormItem>
            </Col>
            <Col className="d-flex  " span={24}>
              <FormItem
                className="w-100"
                labelCol={{ flex: "50%" }}
                label="and convert this for *"
              >
                <Selector
                  placeholder="Type target audience i.e HV base"
                  options={[
                    { value: 'Re-Activation', label: 'Re-Activation' },
                    { value: 'Abandon Cart Recovery', label: 'Abandon Cart Recovery' },
                    { value: 'Activation', label: 'Activation' },
                    { value: 'Engagement', label: 'Engagement' },
                    { value: 'Investment Tips', label: 'Investment Tips' },
                    { value: 'Upsell', label: 'Upsell' },
                    { value: 'Limited Deals', label: 'Limited Deals' },
                  ]}
                />
              </FormItem>
            </Col>
            <Col className="d-flex  " span={24}>
              <FormItem
                className="w-100"
                labelCol={{ flex: "50%" }}
                label="with voice/tone"
              >
                <TextInput
                  value={tone}
                  placeholder="Type voice and tone of the campaign"
                  onChange={(e: any) => {
                    setTone(e.target.value);
                  }}
                  // style={{ width: 150 }}
                />
              </FormItem>
            </Col>
            <Col className="d-flex" span={24}>
              <FormItem
                className="w-100"
                labelCol={{ flex: "50%" }}
                label="in the writing style of"
              >
                <Selector
                  onChange={(value) => setWritingStyle(value)}
                  options={[
                    { value: "Emoji Language", label: "Emoji Language" },
                    { value: "Storytelling", label: "Storytelling" },
                    { value: "Inspirational", label: "Inspirational" },
                    { value: "Minimalistic", label: "Minimalistic" },
                    { value: "Questioning", label: "Questioning" },
                  ]}
                ></Selector>
              </FormItem>
            </Col>
          </Row>
        </Form>
        <div className="  py-1 d-flex justify-content-end">
          <PrimaryButton
            loading={loading}
            onClick={callApi}
            type="primary"
            htmlType="submit"
          >
            {resData.length == 0 ? "Generate" : "Regenerate"}
          </PrimaryButton>
        </div>
      </div>

      <div className="col-7">
        <MessagePreview
          formData={formData}
          loading={loading}
          resData={resData}
          prompt={prompt}
          type={type}
        />
      </div>
    </div>
  );
};

export default PushGenAi;
