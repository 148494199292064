import React from 'react';
import {Col, Divider, Row} from "antd";
import "./Decryption.css";
import { Form } from "antd";
import {useState, Fragment } from "react";
import ShowMessage from 'components/ShowMessage/ShowMessage';
import Spinner from 'components/Spinner/Spinner';
import TextInput from 'components/Inputs/TextInput/TextInput';
import PrimaryButton from 'components/Buttons/PrimaryButton/PrimaryButton';
import CustomTextArea from 'components/Inputs/CustomTextArea/CustomTextArea';
import  {PayloadDecryptor} from '../../../../utils/models/index'
import { t } from 'i18next';



const Decryption= () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [decryptedData ,setDecryptedData] = useState<string>("");
  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  });


  /**
   * 
   * @param values takes privateKey and encryptedText from form values 
   * returns decrypted text
   */
  const onFormSubmit = async(values: any) => {
    const encryptionKey = values.privateKey;
    const encryptedData = values.encryptedText;
    const data = new PayloadDecryptor(encryptedData, encryptionKey)
    try { 
       const  decryptedText = await data.decryptWithRandomIV();
       setShowMessage({
        type: "success",
        message: "Successfully Decrypted",
        show: true,
      });
       setLoading(false);
       setDecryptedData(decryptedText);
       const decryptedValue = {
        decryptedData : decryptedText
       }
       form.setFieldsValue(decryptedValue);
    } catch (error) {
       setLoading(false);
       setShowMessage({
        type :"error",
        message : t("somethingWrongLabel"),
        show : true
       })
       setDecryptedData("");
           
    }
    hideMessage();
  };

  /**
   * 
   * @param textToCopy it takes textToCopy as a string 
   * and copys the text in the textAREA
   */
  const handleCopyClick = async (textToCopy: string) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setShowMessage({
        type: "success",
        message: t("sucessfullyCopiedLabel"),
        show: true,
      });
    } catch (error) {
      setShowMessage({
        type: "error",
        message: t("somethingWrongLabel"),
        show: true,
      });
    }

    hideMessage();
  };
  const hideMessage = () => {
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false });
    }, 2000);
  };


  return (
    <Fragment>
      <div className="decryption-notification-container">
        <div className="mt-4 mb-4  ">
          <h6 className="decryption-notification-container-heading">
          {t("decryptionServiceLabel")}
          </h6>
          <Divider className="decryption-notification-custom-divider" />
        </div>

        <div className=" col-sm-7 mt-4 mb-4 align-items-center decryption-notification-form ">
          {showMessage.show && (
            <ShowMessage
              type={showMessage.type}
              content={showMessage.message}
            />
          )}
          {loading ? (
            <Spinner />
          ) : (
            <Form
              form={form}
              layout="horizontal"
              onFinish={onFormSubmit}
            >
                 <Form.Item
                  label={t("privateKeyLabel")}
                  name="privateKey"
                  rules={[
                    {
                      required: true,
                      message: "Please input Private Key",
                    },
                  ]}
                >
                  <TextInput />
                </Form.Item>
            <Row gutter={50} >
            <Col span={12}>
                <Form.Item
                  label={t("encryptedTextLabel")}
                  name="encryptedText"
                  rules={[
                    {
                      required: true,
                      message: "Please input Encrypted Text",
                    },
                  ]}
                >
                  <CustomTextArea rows={10} />
                </Form.Item>
                <div className="decryption-button">
                <PrimaryButton
                  size="middle"
                  type="primary"
                  htmlType="submit"
                  style={{ border: "none", width: "80px" }}
                >
                  {t("decryptLabel")}
                </PrimaryButton>
               </div>
                </Col>
          
              { decryptedData &&(
                <Col span={12}>
                 <Form.Item
                  label="Decrypted Text"
                  name="decryptedData" 
                 >
                  <CustomTextArea rows={10} />
                </Form.Item>
                <div className="decryption-button" >
                <PrimaryButton
                  size="middle"
                  type="primary"
                  htmlType="button" 
                  onClick={() => handleCopyClick(decryptedData)}
                  style={{ border: "none", width: "80px" }}
                >
                  {t("copyLabel")}
                </PrimaryButton>
               </div>
                </Col>
            )}        
              </Row>
            </Form>  
          )}
        </div>
        
      </div>
    </Fragment>
    
  );
};
export default Decryption;