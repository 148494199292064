import { Segmented } from "antd";

import { FC, ReactNode } from "react";
import "./SegmentedCustom.css";
import { SegmentedProps, SegmentedValue } from "antd/es/segmented";

const SegmentedCustom: FC<any> = ({ ...props }) => {
  return (
    <div className="custom-segmented-container">
      <Segmented
        {...props}
        options={
          props.options
            ? props.options
            : [
                {
                  value: true,
                  label: "Yes",
                },
                {
                  value: false,
                  label: "No",
                },
              ]
        }
      />
    </div>
  );
};

export default SegmentedCustom;
