import React from "react";
import { useState } from "react";
import { MinusCircleTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton/PrimaryButton";
import TextInput from "../../../../../components/Inputs/TextInput/TextInput";
import { Col } from "antd";
export const JsonTree = ({
  initialJsonTreeData,
  updateCustomDataState,
}: any) => {
  const [jsonTreeData, setJsonTreeData] = useState(
    initialJsonTreeData || [{ key: "", value: "" }]
  );
  const handleAddClick = () => {
    setJsonTreeData((prevState: any) => [...prevState, { key: "", value: "" }]);
  };

  const handleRemoveClick = (index: number) => {
    setJsonTreeData((prevState: any) => {
      const updatedData = [...prevState];
      updatedData.splice(index, 1);
      return updatedData;
    });
  };

  const handleKeyValueChange = (
    index: number,
    field: string,
    value: string
  ) => {
    setJsonTreeData((prevState: any) => {
      const updatedData = [...prevState];
      updatedData[index][field] = value;
      return updatedData;
    });
    updateCustomDataState(jsonTreeData);
  };
  return (
    <div>
      {jsonTreeData?.map((item: any, index: number) => (
        <Col
          key={index}
          className={`d-flex ${index !== 0 ? "mt-1" : ""} gap-1`}
        >
          <TextInput
            style={{ width: 78 }}
            value={item?.key}
            onChange={(e: any) =>
              handleKeyValueChange(index, "key", e.target.value)
            }
            placeholder="key"
          />
          <TextInput
            style={{ width: 78 }}
            value={item?.value}
            onChange={(e: any) =>
              handleKeyValueChange(index, "value", e.target.value)
            }
          />

          {Object.keys(jsonTreeData).length - 1 == index ? (
            <PrimaryButton style={{ width: 40 }} onClick={handleAddClick}>
              <PlusCircleTwoTone />
            </PrimaryButton>
          ) : (
            <PrimaryButton
              style={{ width: 40 }}
              onClick={() => handleRemoveClick(index)}
            >
              <MinusCircleTwoTone />
            </PrimaryButton>
          )}
        </Col>
      ))}
      {/* );
        })} */}
    </div>
  );
};
