import centralApi from "../services/centralApi";

export const getToken = async (appID:string, apiKey:string) => {
  const appId = appID;
  const api_key = apiKey;

  const payload = {
    app_id: appId,
    api_key: api_key,
  };

  const url = `/o/metrices/token`;

  try {
    const data = await centralApi("GET", url, null, payload);
    return data.token; // Return the token
  } catch (error) {
    throw error;
  }
};
