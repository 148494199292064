import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { STATUS } from "../../constant"
import centralApi from "../../../services/centralApi"
interface paramType {
  api_key: string
  app_id: string
  template_type:
    | "PUSH"
    | "WEB_PUSH"
    | "EMAIL"
    | "SMS"
    | "IN-APP"
    | "WEB_POPUP"
    | "WHATSAPP"
    |"NATIVE"
}
export const fetchTemplatesList = createAsyncThunk(
  "templates/fetchTemplatesList",
  async (params: paramType) => {
    return centralApi(
      "GET",
      "o/templates/getTemplatesByType",
      null,
      params
    ).then(({ result }) => {
      return result
    })
  }
)
const initialState: any = {
  data: [],
  status: STATUS.IDLE,
}
const templatesListSlice = createSlice({
  name: "templatesList",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchTemplatesList.pending, (state) => {
      state.status = STATUS.LOADING
    })
    builder.addCase(fetchTemplatesList.fulfilled, (state, action) => {
      state.status = STATUS.IDLE
      state.data = action.payload
    })
    builder.addCase(fetchTemplatesList.rejected, (state) => {
      state.status = STATUS.ERROR
    })
  },
  reducers: {},
})
export default templatesListSlice.reducer
