import React, { FC } from "react"
interface WebPopUpContainer {
  headerImg?: string
  title?: string
  message?: string
  mode?:string
  borderRadius?: number
  width?: number
  messageTextColor?: string
  headerTextColor?: string
  backgroundColor?: string
  buttonName?: string
  buttonBackground?: string
  buttonTextColor?: string
  isLoading?: {
    webPopUp?: boolean
  }
  uploadImage?: (value: any, type: "webPopUp") => void
}
import "./WebPopUpContainer.css"
import docIcon from "../../../../../../images/uploadImageCustom.svg"
import { Upload } from "antd"
import Spinner from "../../../../../../components/Spinner/Spinner"
import CustomTextArea from "../../../../../../components/Inputs/CustomTextArea/CustomTextArea"
import { textOverflowThreeDot } from "../../../../../../helperFunctions/string"
import TextInput from "../../../../../../components/Inputs/TextInput/TextInput"
import PrimaryButton from "../../../../../../components/Buttons/PrimaryButton/PrimaryButton"
const WebPopUpContainer: FC<WebPopUpContainer> = ({ ...props }) => {
  return <WebPopUpDevice {...props} />
}

export default WebPopUpContainer

const WebPopUpDevice = ({
  isLoading,
  headerImg,
  headerTextColor,
  messageTextColor,
  backgroundColor,
  title,
  buttonName,
  message,
  borderRadius,
  width,
  buttonBackground,
  buttonTextColor,
  uploadImage,
  mode,
}: WebPopUpContainer) => {
  
  return (
    <div
      className="webpopup-notification-main-container"
      style={{
        borderRadius: borderRadius ? borderRadius + "%" : "",
        width: width ? (width > 300  || width < 100 ? 250 : width + "px") : 200,
        minHeight: "50px",
        backgroundColor: backgroundColor,
      }}
    >
      <div
        className={`d-flex  h-100 align-items-center justify-content-center `}
      >
        <Upload
          disabled={!uploadImage}
          showUploadList={false}
          multiple={false}
          className="p-0 m-0 "
          beforeUpload={() => false}
          onChange={(value: any) => {
            if (uploadImage) {
              uploadImage(value, "webPopUp")
            }
          }}
        >
          <div>
            {isLoading && isLoading.webPopUp ? (
              <Spinner />
            ) : (
              <img
                loading="lazy"
                className="m-0 p-0"
                src={headerImg || docIcon}
                style={{
                  width: headerImg ? "100%" : 37,
                  height: headerImg ? "100%" : 37,
                }}
              />
            )}
          </div>
        </Upload>
      </div>
      {mode!== "ImageOnly" &&<>
      {(title || message) && (
        <div className="d-flex flex-column pt-2   w-100">
          {title && (
            <div className="mx-1 mb-1 text-center d-flex align-items-center justify-content-center">
              <TextInput
                disabled={true}
                className="p-0 text-center "
                value={textOverflowThreeDot(title, 30)}
                style={{
                  lineHeight: 1,
                  background: "transparent",
                  border: "none",
                  color: headerTextColor ? headerTextColor : "black",
                  // fontSize: customStyle.fontSize,
                  fontWeight: 600,
                }}
              />
            </div>
          )}
          {message && (
            <div className="mx-1 mb-1 d-flex align-items-center justify-content-center webpopup-notification-main-container-textarea">
              <CustomTextArea
                disabled={true}
                className="p-0 text-center "
                autoSize
                value={textOverflowThreeDot(message, 100)}
                style={{
                  // lineHeight: 1,
                  background: "transparent",
                  border: "none",
                  color: messageTextColor ,
                  fontSize: "12px",
                  resize:"none"
                  // height:15
                }}
                rows={4}
              />
            </div>
          )}
        </div>
      )}
      {buttonName && (
        <div className="mx-1 d-flex align-items-center justify-content-center">
          <PrimaryButton
            style={{
              fontSize: "var(--font-size-sm)",
              color: buttonTextColor,
              background: buttonBackground,
            }}
          >
            {buttonName}
          </PrimaryButton>
        </div>
      )}
      </>}
    </div>
  )
}
