"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeoFencing = exports.GeoName = void 0;
const typescript_json_serializer_1 = require("typescript-json-serializer");
const base_1 = require("../common/base"); // Import the BaseModel class from the common base file
/**
 * GeoName class
 *
 * this class holds various pieces of geographical information,
 * such as country, city, and potentially other relevant details.
 * It extends the BaseModel class, inheriting common functionality.
 */
let GeoName = class GeoName extends base_1.BaseModel {
    /**
     * Constructor to initialize the variablename property
     * @param name - The name of the variable
     */
    constructor(name) {
        super(); // Call the constructor of the base class
        this.variablename = name; // Initialize the variableName property
    }
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], GeoName.prototype, "variablename", void 0);
GeoName = __decorate([
    (0, typescript_json_serializer_1.JsonObject)() // Mark the class as serializable to JSON
    ,
    __metadata("design:paramtypes", [String])
], GeoName);
exports.GeoName = GeoName;
/**
 * GeoFencingDetails class
 *
 * This class represents the details of a geofence, including its type, coordinates, and optional radius.
 */
let GeoFencingDetails = class GeoFencingDetails {
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], GeoFencingDetails.prototype, "type", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Object)
], GeoFencingDetails.prototype, "coordinates", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], GeoFencingDetails.prototype, "radius", void 0);
GeoFencingDetails = __decorate([
    (0, typescript_json_serializer_1.JsonObject)()
], GeoFencingDetails);
/**
 * GeoFencing class
 *
 * This class represents a geofencing model, which includes properties such as an optional unique identifier _id,
 * name, description, and details of the geofence. It extends the BaseModel class, inheriting common functionality.
 */
let GeoFencing = class GeoFencing extends base_1.BaseModel {
    /**
     * Constructor to initialize the name, description, and details properties
     *
     * @param name - The name of the geofence
     * @param desc - The description of the geofence
     * @param details - An array of GeoFencingDetails objects representing the details of the geofence,
     *                  including the type of geofence, coordinates, and optionally the radius
     */
    constructor(name, desc, details) {
        super(); // Call the constructor of the base class
        this.name = name; // Initialize the name property
        this.desc = desc; // Initialize the desc property
        this.details = details; // Initialize the details property
    }
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)() // Mark the type property as a serializable JSON property
    ,
    __metadata("design:type", String)
], GeoFencing.prototype, "_id", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], GeoFencing.prototype, "name", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], GeoFencing.prototype, "desc", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Array)
], GeoFencing.prototype, "details", void 0);
GeoFencing = __decorate([
    (0, typescript_json_serializer_1.JsonObject)(),
    __metadata("design:paramtypes", [String, String, Array])
], GeoFencing);
exports.GeoFencing = GeoFencing;
