import { Divider } from "antd";
import "./AppInbox.css";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { Form } from "antd";
import Selector from "../../../../components/Selector/Selector";
import { useSelector } from "react-redux";
import { getToken } from "../../../../services/getToken";
import React, { useState, useEffect } from "react";
import centralApi from "../../../../services/centralApi";
import ShowMessage from "../../../../components/ShowMessage/ShowMessage";
import Spinner from "../../../../components/Spinner/Spinner";
import { t } from "i18next";

interface AppInboxProps {
  currentAppKey: any;
  updateCurrentAppKey: () => void;

}

const AppInbox: React.FC<AppInboxProps> = ({ currentAppKey,updateCurrentAppKey }) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  });
  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;
  const onFormSubmit = (values: any) => {
    const payload = {
      args: JSON.stringify({
        ...values,

        app_id: appId,
      }),
      app_id: appId,
      api_key: api_key,
    };
    saveAppInboxSetting(payload);
  };
  async function saveAppInboxSetting(formData: any) {
    const url: string = `/i/apps/saveAppInboxSettings`;

    try {
      setLoading(true);
     await centralApi("POST", url, formData, null);
      setShowMessage({
        type: "success",
        message: t("sucessfullySavedLabel"),
        show: true,
      });
      updateCurrentAppKey();

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setShowMessage({
        type: "error",
        message: t("somethingWrongLabel"),
        show: true,
      });
    }
    hideMessage();
  }
  const hideMessage = () => {
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false });
    }, 2000);
  };

  useEffect(() => {
    getToken(appId, api_key);
  }, [showMessage]);

  useEffect(() => {
    // Set initial form values when currentAppKey changes
    const updatedFormValues = {
      days: currentAppKey[0].AppInboxExpiry
    };
    console.log("updated",updatedFormValues)
    form.setFieldsValue(updatedFormValues);
    }, [currentAppKey,form]);
  return (
    <div className="app-inbox-setting-container">
      <div className="mt-4 mb-4  ">
        <h6 className="app-inbox-setting-container-heading">
          {t("appInboxSettingLabel")}
        </h6>
        <Divider className="app-inbox-setting-custom-divider" />
        <div>
          <div className="  mt-4 align-items-center app-inbox-setting-form ">
            <div className="mb-4">
              <p>{t("appInboxExpiry")}</p>
              <div className="col-sm-4 ">
                {showMessage.show && (
                  <ShowMessage
                    type={showMessage.type}
                    content={showMessage.message}
                  />
                )}
                {loading ? (
                  <Spinner />
                ) : (
                  <Form
                    form={form}
                    layout="horizontal"
                    onFinish={onFormSubmit}
                  >
                    <Form.Item
                      name="days"
                      rules={[
                        { required: true, message: t('plzSelectDaysLabel') as string },
                      ]}
                    >
                      <Selector
                        onChange={(value) => {}}
                        size="middle"
                        style={{ width: "100%" }}
                        // defaultValue={"Select"}
                        options={[
                          {
                            value: "",
                            label: t("selectLabel"),
                          },
                          ...Array.from({ length: 31 }, (_, index) => ({
                            value: index.toString(),
                            label: index.toString(),
                          })),
                        ]}
                      />
                    </Form.Item>

                    <PrimaryButton
                      size="middle"
                      type="primary"
                      htmlType="submit"
                      style={{ border: "none", width: "70px" }}
                    >
                      {t("saveLabel")}
                    </PrimaryButton>
                  </Form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AppInbox;
