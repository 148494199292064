
import { Helmet } from "react-helmet"
import WebPushReportCard from "../../../components/Cards/WebPushReportCard/WebPushReportCard"
import { DateSelector } from "../../../components/DateTimeSelectors/DateSelector/DateSelector"
import "./DemoDashboard.css"
import { DonutPieChart } from "./PieChart"
import { PerformanceChart } from "./PerformanceChart"
export const DemoDashboard = () => {
  const chartPointsType = [
    { name: 'Active Points', y: 75902 },
    { name: 'Pending Points', y: 531 },
    { name: 'Used Points ', y: 9229 },
    // { name: 'Expired Points ', y: 1 },

  ];
  const chartMembersType = [
    { name: 'Bronze', y: 44053 },
    { name: 'Silver', y: 10166 },
    { name: 'Gold ', y: 2259 },

  ];
  return (
    <div className="container-fluid">
      <div className="loyalty-container">
        <Helmet>
          <title>Appice | Loyalty</title>
        </Helmet>
        <div className="d-flex justify-content-between flex-wrap my-3">
          <h4>Overview:Dashboard</h4>
          <DateSelector />
        </div>
        <div className="loyalty-cards   ">
          <WebPushReportCard heading="New Members" number="1029" />
          <WebPushReportCard
            type="sent"
            heading="Earned Points"
            number="7926"
            percentageTwo="5.5"
          />
          <WebPushReportCard
            heading="Pending Points"
            number="531"
            // percentageOne="39"
            percentageTwo="10.5"
          />
          <WebPushReportCard
            // type="double"
            heading="Used Points"
            // headingTwo="CTR"
            number="9229"
            // percentageOne="2.1"
            percentageTwo="2.5"

            // headingTwoPercentage="23"
          />
          <WebPushReportCard
            heading="Expired Points"
            number="51226"
            // percentageOne="5.1"
            percentageTwo="0.3"
          />
        </div>

        {/* <div className="mt-3">
          <h6>Campaign Performance</h6>
        </div> */}
        <div className="loyalty-campaigns">
          {/* <div className="d-flex gap-2 align-items-baseline ">
            <p className="loyalty-campaigns-heading">Select Channel</p>
            <Selector
              className="loyalty-campaigns-heading"
              defaultValue="SMB"
              size="middle"
              options={[
                {
                  value: "SMB",
                  label: "SMB",
                },
              ]}
            />
            <RadioButton name="Yesterday" />
            <RadioButton name="Last 7 D" />
            <RadioButton name="Last 30 D" />
          </div> */}
          <div className="loyalty-performance-charts ">
            <PerformanceChart />
          </div>
        </div>

        <div className="loyalty-browser mt-3">
          {/* <div className="d-flex justify-content-around">
            <h6>Members by Tiers</h6>
            <h6 >Points By Type</h6>
          </div>
          <Divider /> */}
          <div className="loyalty-browser-container">
            <div className="loyalty-browser-chart">
            {/* <h6>Members by Tiers</h6> */}

              <DonutPieChart
                            title="Points By Type"
                            heading="Available Points"
                total={70652 
                }
                data={chartPointsType}
                valueIst={25}
                
                valueSecond={2}
              />
            </div>

            <div className="loyalty-browser-chart">
            {/* <h6 >Points By Type</h6> */}

              <DonutPieChart
                            title="Members by Tiers"

                heading="All Member"
                total={56478}
                valueIst={2}
                valueSecond={25}
                data={chartMembersType}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
