import { FormInstance, Steps } from "antd";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { FC, } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addSegment,
  removeSegment,
} from "redux/features/audience/audienceSegmentDataSlice";
import { AudienceEnum } from "utils/enums";
import UserTraitParent from "../UserTrait/UserTraitParent/UserTraitParent";
import AudienceEvent from "../AudienceEvent/AudienceEvent";
import FilterPopup from "components/PopUp/FilterPopup/FilterPopup";
import { t } from "i18next";
import { audienceItems } from "helperFunctions/audience";
import { useNavigate } from "react-router-dom";
import ComputedTrait from "components/ComputedTrait/ComputedTrait";
import centralApi from "services/centralApi";
import { API_ENDPOINTS, ROUTES } from "utils/constants";
import SegmentBlockLayout from "layout/SegmentBlockLayout/SegmentBlockLayout";

interface QueryStepsProps {
  form: FormInstance;
}

const QuerySteps: FC<QueryStepsProps> = ({ form }) => {
  const treeBtn = <PrimaryButton type="primary">{t("andLabel")}</PrimaryButton>;
  const segment = useSelector((state: any) => state.audienceSegmentData.data);
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const traitList = useSelector((state: any) => state.computedTraitList.data);
  const { who, what, cTrait = [] } = segment?.segmentinfo;
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const fetchComputedTrait = async (id: string) => {
    try {
    const params = {
      api_key: loginUser.api_key,
      app_id: appId,
      id,
    };
    return await centralApi("GET", API_ENDPOINTS.TRAIT_BY_ID_URL, null, params);
  } catch (error) {
      return undefined
  }
  };
  //adds new condition
 async function addCondition({ key }: { key: string }) {
    if (key == AudienceEnum.WHO) {
      dispatch(addSegment({ segmentType: AudienceEnum.WHO, data: {} }));
    } else if (key == AudienceEnum.LIVE || key == AudienceEnum.PAST) {
      dispatch(
        addSegment({
          segmentType: AudienceEnum.WHAT,
          data: {
            category: AudienceEnum.EVENTS,
            isLive: key == AudienceEnum.LIVE,
            e_operator: AudienceEnum.AND,
          },
        })
      );
    } else if (key == AudienceEnum.CTRAIT) {
      navigate(ROUTES.DASHBOARD_AUDIENCE_TRAIT_CREATE);
    } else if(key == AudienceEnum.MODELS){
      navigate(ROUTES.DASHBOARD_AUDIENCE_MODELS);
    }
    else {
      const computedTrait=await fetchComputedTrait(key)
      if(computedTrait){
        dispatch(
          addSegment({
            segmentType: AudienceEnum.CTRAIT,
            data: computedTrait,
          })
        );
      }

    }
  }
  const getTreeItems = () => {
    return [
      ...who.map((item: any, index: number) => ({
        icon: treeBtn,
        title: (
          <SegmentBlockLayout
            key={item.id}
            onDeleteClick={() => {
              dispatch(
                removeSegment({
                  segmentType: AudienceEnum.WHO,
                  category: item?.category,
                  indexToRemove: index,
                })
              );
            }}
          >
            <UserTraitParent id={item.id} form={form} blockIndex={index} />
          </SegmentBlockLayout>
        ),
      })),
      ...what.map((item: any, index: number) => ({
        icon: treeBtn,
        title: (
          <SegmentBlockLayout
            key={item.id}
            onDeleteClick={() => {
              dispatch(
                removeSegment({
                  segmentType: AudienceEnum.WHAT,
                  indexToRemove: index,
                })
              );
            }}
          >
            <AudienceEvent form={form} blockIndex={index} id={item.id} />
          </SegmentBlockLayout>
        ),
      })),
      ...cTrait.map((item: any, index: number) => ({
        icon: treeBtn,
        title: (
          <SegmentBlockLayout
            key={item._id}
            onDeleteClick={() => {
              dispatch(
                removeSegment({
                  segmentType: AudienceEnum.CTRAIT,
                  indexToRemove: index,
                })
              );
            }}
          >
            <ComputedTrait trait={item} key={item._id} />
          </SegmentBlockLayout>
        ),
      })),
      {
        title: "",
        icon: (
          <FilterPopup
            data={audienceItems.getfilterPopupData(traitList)}
            onItemClick={addCondition}
          >
            <PrimaryButton type="primary">
              {t("addConditionLabel")}
            </PrimaryButton>
          </FilterPopup>
        ),
      },
    ];
  };
  return (
    <div>
      <Steps direction="vertical" current={100} items={getTreeItems()} />
    </div>
  );
};

export default QuerySteps;
