import React, { useEffect, useState } from "react";
import PrimaryButton from "../../../components/Buttons/PrimaryButton/PrimaryButton";
import filterIcon from "../../../images/_filter.svg";
import { Form, Steps } from "antd";
import TextInput from "../../../components/Inputs/TextInput/TextInput";
import Selector from "../../../components/Selector/Selector";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomBreadCrumb from "../../../components/CustomBreadCrumb/CustomBreadCrumb";
import FunnnelSteps from "./FunnelSteps/FunnelSteps";
import "./CreateFunnels.css";
import Spinner from "../../../components/Spinner/Spinner";
import {
  validateNoSpecialChars,
  validateNotEmpty,
} from "../../../validator/antdValidator";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { t } from "i18next";
import useMessage from "../../../hooks/useMessage";
import { fetchEventList } from "../../../redux/features/event/eventListSlice";
import { STATUS } from "../../../redux/constant";
import SegmentBlockLayout from "layout/SegmentBlockLayout/SegmentBlockLayout";
import { API_ENDPOINTS, ROUTES } from "utils/constants";
import centralApi from "services/centralApi";
import { Funnel } from "src_typescript_appice-core/dist/models/analytics/common/what";
import { CustomUnitsEnum, FunnelPlatformEnum, TimeUnitEnum } from "utils/enums";
import { getJourneyTypeList } from "helperFunctions/events";


const CreateFunnels: React.FC = () => {
  const selectPeriodMsg = t("selectPeriodMsg");
  const selectPlatformMsg = t("selectPlatformMsg");
  const inputFunnelNameMsg = t("inputFunnelNameMsg");
  const inputFunnelDescriptionMsg = t("inputFunnelDescriptionMsg");
  const inputValueMsg = t("inputValueMsg");
  const periodLabel = t("periodLabel");
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [funnelId, setFunnelId] = useState("");
  const [funnelData, setFunnelData] = useState<any>({});
  const [unit, setUnit] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const { showError, showSuccess } = useMessage();
  const eventList = useSelector((state: any) => state.eventList);
  const [form] = Form.useForm();
  const dispatch: any = useDispatch();

  const [selectedJourney, setSelectedJourney] =
    useState<"agri gold" | "agri silver" | "">(""); // State for selected journey
  const [boxFormData, setBoxFormData] = useState<any[]>([]);
  
  const platformOptions = [
    
    {
      value: FunnelPlatformEnum.ANDROID,
      label: t("androidLabel"),
    },
    {
      value: FunnelPlatformEnum.IOS,
      label: t("iosLabel"),
    },
    {
      value: FunnelPlatformEnum.WEB,
      label: t("webLabel"),
    },
  
  ];

  const initialCondition = {
    eventName: undefined,
    list: [],
    attributes: [],
  };
  const getFunnel = async () => {
    const payload = {
      api_key: loginUser.api_key,
      app_id: appId,
      id: funnelId,
    };

    const response = await centralApi(
      "GET",
      API_ENDPOINTS.GETFUNNEL,
      null,
      payload
    );
    return response; // Ensure to return the response
  };

  const updateFunnel = async (data: any) => {
    const updateFunnelObj = new Funnel(data);
    const serializedData = updateFunnelObj.toString();

    try {
      const payload = {
        api_key: loginUser.api_key,
        app_id: appId,
      };
      const params = {
        app_id: appId,
        api_key: loginUser.api_key,
        args: serializedData,
        id: funnelId,
      };

      await centralApi("POST", API_ENDPOINTS.FUNNEL_UPDATE, payload, params);
      showSuccess(t("funnelUpdatedSuccessfullyLabel"));
      setTimeout(() => {
        navigate(ROUTES.DASHBOARD_FUNNELS);
      }, 2000);
    } catch (error) {
      showError(t("somethingWrongLabel"));
    }
  };

  const createFunnel = async (data: any) => {
    const createFunnelObj = new Funnel(data);
    const serializedData = createFunnelObj.toString();
    try {
      const payload = {
        api_key: loginUser.api_key,
        app_id: appId,
      };
      const params = {
        app_id: appId,
        api_key: loginUser.api_key,
        args: serializedData,

      };

      await centralApi("POST", API_ENDPOINTS.CREATE_FUNNEL, payload, params);
      showSuccess(t("funnelCreatedSuccessfullyLabel"));
      setTimeout(() => {
        navigate(ROUTES.DASHBOARD_FUNNELS);
      }, 2000);
    } catch (error) {
      showError(t("somethingWrongLabel"));
    }
  };
  // };

  const validateFunnelName = async (name: string) => {
    const payload = {
      api_key: loginUser?.api_key,
      app_id: appId,
      funnelName: name,
    };

    return await centralApi(
      "GET",
      API_ENDPOINTS.VALIDATE_FUNNEL_NAME,
      null,
      payload
    );
  };

  const removeFunnelStep = (indexToRemove: number) => {
    setBoxFormData((prevState) => {
      const newState = prevState.filter((_, index) => index !== indexToRemove);
      return newState;
    });
  };
  // if the page is edit
  async function fetchFunnelData() {
    try {
      const apiKey = loginUser.api_key;
      if (apiKey) {
        setLoading(true);
        const data: any = await getFunnel();
        setFunnelData(data);
        setUnit(data?.unit);
        setSelectedJourney(data?.journeyName);
        const attributesData = data.events.map((event: any) => ({
          eventName: event.eventName,
          list: event.list,
          attributes: event?.attributes?.map((attribute: any) => ({
            attributeName: attribute.attributeName,
            type: attribute.type,
            operator: attribute.operator,
            value: attribute.value,
            e_operator: attribute?.e_operator,
          })),
        }));

        setBoxFormData(attributesData);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  //***** */
  useEffect(() => {
    if (loginUser && appId) {
      if (!location.state) {
        setIsEdit(false);
      } else {
        setIsEdit(true);
        const { id } = location.state;
        setFunnelId(id);
      }
      //if the page is edit
      if (funnelId) {
        fetchFunnelData();
      }
    }
  }, [funnelId, loginUser]);
  useEffect(() => {
    if (loginUser && appId) {
      dispatch(fetchEventList({ api_key: loginUser.api_key, app_id: appId }));
    }
  }, [loginUser]);

  //when user submit the form
  const onFormSubmit = async (value: any) => {
    //check if update and name is not changed then no need to validate name
    if (funnelData.name !== value.name) {
      try {
        const isValidFunnelName = await validateFunnelName(value.name);
        if (isValidFunnelName) {
          form.setFields([
            {
              name: "name",
              errors: [t("funnelNameExistsErrorMessage")],
            },
          ]);
          return; // Stop execution if the name is invalid
        }
        // Proceed with form validation and submission
      } catch (error) {
        showError(t("somethingWrongLabel"));
      }
    }

    const payload: any = {
      name: value.name,
      description: value.description,
      unit: value.unit,
      platform: value.platform,
      duration: value.duration ? value.duration : "",
      customunit: value.customunit ? value.customunit : "",
      customDate: {},
    };
    const events = boxFormData.map((item) => ({ ...item }));
    payload.events = events;
    isEdit ? updateFunnel(payload) : createFunnel(payload);
    form.resetFields();
  };

  const styleWidth = {
    width: 130,
  };

  const addFunnelStep = () => {
    setBoxFormData((prevState) => [...prevState, { ...initialCondition }]);
  };

  const getTreeItems = () => {
    const conditionItems = boxFormData?.map((item, index) => ({
      icon: (
        <PrimaryButton style={{ minWidth: 41 }} type="primary">
          {index === 0 ? t("allLabel") : t("andLabel")}
        </PrimaryButton>
      ),
      title: (
        <SegmentBlockLayout
          key={index}
          index={index}
          onDeleteClick={index === boxFormData.length - 1?() => removeFunnelStep(index):undefined}
          showSteps={true}
        > 
          <FunnnelSteps
            selectedJourney={selectedJourney}
            blockIndex={index}
            form={form}
            boxFormData={item}
            setBoxFormData={setBoxFormData}
          />
        </SegmentBlockLayout>
      ),
    }));

    const addConditionButton = {
      id: null,
      icon: (
        <PrimaryButton
          type="primary"
          onClick={addFunnelStep}
          disabled={boxFormData.length >= 5} //ifselected steps length > 5
        >
          {t("addConditionLabel")}
        </PrimaryButton>
      ),
      title: "",
    };

    return [...conditionItems, addConditionButton];
  };

  return (
    <div className="container-fluid funnels-create-container px-1">
      <Helmet>
        <title>Appice | Funnel | {isEdit ? "Edit" : "Create"}</title>
      </Helmet>
      <div className="pb-4 pt-1">
        <CustomBreadCrumb
          items={[
            {
              title: <Link to={ROUTES.DASHBOARD_FUNNELS}>{t("funnelsLabel")}</Link>,
            },
            {
              title: (
                <Link to={ROUTES.DASHBOARD_FUNNELS_CREATE}>
                  {isEdit ? t("editLabel") : t("createLabel")}
                </Link>
              ),
            },
          ]}
        />
      </div>

      <div
        className="px-sm-3 py-4 px-2 d-flex flex-column "
        style={{ background: "var(--color-other-white)" }}
      >
        {loading ? (
          <Spinner />
        ) : (
          <Form form={form} onFinish={onFormSubmit}>
            <div className="d-flex  justify-content-between heading-input-values align-items-start ">
              {/* for icon and title of header */}
              <div className="d-flex align-items-center flex-wrap justify-content-between w-100">
                <div className="d-flex   align-items-center">
                  <span className="d-flex   filter-icon-container justify-content-center ">
                    <img
                      src={filterIcon}
                      height={"100%"}
                      width={"100%"}
                      alt="filter"
                    ></img>
                  </span>
                  <span>
                    <h5 className="m-0 mx-2">
                      {" "}
                      {isEdit
                        ? t("editFunnelLabel")
                        : t("createNewFunnelLabel")}
                    </h5>
                  </span>
                </div>
                {/* </span> */}
                <div className="d-flex flex-wrap  space-r custom-fields-gap" >
                  <Form.Item
                    rules={[
                      {
                        validator: validateNotEmpty,
                        message: selectPeriodMsg,
                      },
                    ]}
                    name={"unit"}
                    className="m-0"
                    initialValue={funnelData?.unit} //when thr is edit page
                  >
                    <Selector
                      style={styleWidth}
                      onChange={(value) => {
                        setUnit(value);
                      }}
                      placeholder={selectPeriodMsg}
                      options={[
                        {
                          label: t("yesterdayLabel"),
                          value: CustomUnitsEnum.YESTERDAY,
                        },
                        {
                          label: t("todayLabel"),
                          value: CustomUnitsEnum.TODAY,
                        },
                        {
                          label: t("thisWeekLabel"),
                          value: CustomUnitsEnum.THIS_WEEK,
                        },
                        {
                          label: t("lastWeekLabel"),
                          value: CustomUnitsEnum.LAST_WEEK,
                        },
                        {
                          label: t("thisMonthLabel"),
                          value: CustomUnitsEnum.THIS_MONTH,
                        },
                        {
                          label: t("lastMonthLabel"),
                          value: CustomUnitsEnum.LAST_MONTH,
                        },
                        {
                          label: t("customLabel"),
                          value: CustomUnitsEnum.CUSTOM,
                        },
                      ]}
                    />
                  </Form.Item>

                  {unit == "Custom" && (
                    <>
                      <Form.Item
                        name={"duration"}
                        initialValue={funnelData?.duration} //when thr is edit page
                        rules={[
                          {
                            validator: validateNoSpecialChars,
                          },
                          {
                            validator: validateNotEmpty,
                            message: inputValueMsg,
                          },
                        ]}
                      >
                        <TextInput
                          placeholder={t("valueLabel")}
                          style={styleWidth}
                        ></TextInput>
                      </Form.Item>
                      <Form.Item
                        rules={[
                          {
                            validator: validateNotEmpty,
                            message: selectPeriodMsg,
                          },
                        ]}
                        name={"customunit"}
                        className="m-0"
                        initialValue={funnelData?.customunit} //when thr is edit page
                      >
                        <Selector
                          placeholder={periodLabel}
                          style={styleWidth}
                          options={[
                            {
                              label: t("secondsLabel"),
                              value: TimeUnitEnum.SECONDS,
                            },
                            {
                              label: t("minutesLabel"),
                              value: TimeUnitEnum.MINUTES,
                            },
                            {
                              label: t("hoursLabel"),
                              value: TimeUnitEnum.HOURS,
                            },
                            {
                              label: t("dayLabel"),
                              value: TimeUnitEnum.DAYS,
                            },
                            {
                              label: t("weekLabel"),
                              value: TimeUnitEnum.WEEKS,
                            },
                            {
                              label: t("monthLabel"),
                              value: TimeUnitEnum.MONTHS,
                            },
                          ]}
                        ></Selector>
                      </Form.Item>
                    </>
                  )}
                  <Form.Item
                    rules={[
                      {
                        validator: validateNotEmpty,
                        message: selectPlatformMsg,
                      },
                    ]}
                    name={"platform"}
                    className="m-0 funnel-platform-width"
                    initialValue={funnelData?.platform} //when thr is edit page
                  >
                    <Selector
                      className=" funnel-platform-width"
                      onChange={(selectedOptions) =>
                        form.setFieldValue("platform", selectedOptions)
                      }
                      mode="multiple"
                      placeholder="Select Platform"
                      options={platformOptions} 
                    />
                  </Form.Item>
                </div>
              </div>
              <PrimaryButton
                style={{ marginTop: 4 }}
                disabled={
                  eventList?.data?.length == 0 ||
                  eventList?.data?.status == STATUS.ERROR ||
                  eventList?.data?.status === STATUS.LOADING
                }
                htmlType="submit"
                type="primary"
              >
                {isEdit ? t("updateFunnelLabel") : t("saveFunnelLabel")}
              </PrimaryButton>
              {/* for buttons of header */}
            </div>
            <div className="d-flex justify-content-between pt-4 ">
              <div className="d-flex flex-wrap w-100">
                <div
                  className="w-100 funnel-name-width"
                >
                  <strong>{t("funnelNameLabel")}</strong>
                  <Form.Item
                    rules={[
                      {
                        validator: validateNoSpecialChars,
                      },
                      {
                        validator: validateNotEmpty,
                        message: inputFunnelNameMsg,
                      },
                    ]}
                    name={"name"}
                    initialValue={funnelData?.name} //when thr is edit page
                  >
                    <TextInput />
                  </Form.Item>
                </div>
                <div className="w-100 funnel-description-width">
                  <strong>{t("funnelDescriptionLabel")}</strong>
                  <Form.Item
                    name={"description"}
                    initialValue={funnelData?.description} //when thr is edit page
                    rules={[
                      {
                        validator: validateNoSpecialChars,
                      },
                      {
                        validator: validateNotEmpty,
                        message: inputFunnelDescriptionMsg,
                      },
                    ]}
                  >
                    <TextInput />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="mb-3 ">
              <strong className="mb-1">{t("selectJourneyMsg")}</strong>

              <Form.Item
                name={"journeyName"}
                className="funnel-select-journey-width"
              >
                <Selector
                  onChange={(value) => {
                    setSelectedJourney(value);
                  }}
                  className="funnel-select-journey-width"
                  placeholder={`${t("selectJourneyMsg")}`}
                  options={getJourneyTypeList(eventList.data)}

                ></Selector>
              </Form.Item>
             </div>
            {/* steps starts from here */}

            <div>
              <Steps
                direction="vertical"
                current={boxFormData.length}
                items={getTreeItems()}
              />
            </div>
          </Form>
        )}
      </div>
    </div>
  );
};

export default CreateFunnels;
