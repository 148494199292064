import React, { useEffect, useState } from "react";
import Selector from "../../../components/Selector/Selector";
import Search from "../../../components/Inputs/Search/Search";
import "./JourneysTable.css";
import CustomTable from "../../../components/CustomTable/CustomTable";
import PrimaryButton from "../../../components/Buttons/PrimaryButton/PrimaryButton";
import { SizeType } from "antd/es/config-provider/SizeContext";
import editIcon from "../../../images/_edit.svg";
import minusIcon from "../../../images/minus.svg";
import deleteIcon from "../../../images/trashpink.svg";
import documentIcon from "../../../images/document.png";
import eyeIcon from "../../../images/eye.png";
import { useSelector } from "react-redux";
import {
  copyJourney,
  deleteJourney,
  getJourneyList,
} from "../../../helper/journeys";
import { PlusOutlined } from "@ant-design/icons";
import ConfirmPopUp from "../../../components/PopUp/ConfirmPopUp/ConfirmPopUp";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { t } from "i18next";
import { getToken } from "../../../services/getToken";
import { DateRangeSelector } from "components/DateTimeSelectors/DateRangeSelector/DateRangeSelector";
import { PAGE_OPTIONS } from "utils/constants/selectorOptions";
import useMessage from "../../../hooks/useMessage";

type propTypes = {
  pageSize?: number;
  tableSize?: SizeType;
};
const JourneysTable: React.FC<propTypes> = ({ tableSize }) => {
  const navigate = useNavigate();
  const searchLabel = t("searchLabel");
  const loginUser = useSelector((state: any) => state.loginUser);
  const { appId } = useSelector((state: any) => state.activeApp);
  const [pageData, setPageData] = useState({
    pageSize: PAGE_OPTIONS.DEFAULT_VALUE,
    simple: PAGE_OPTIONS.PAGINATION.simple,
  });
  const [journeysData, setJourneysData] = useState([]);
  const [journeysDataCopy, setJourneysDataCopy] = useState([]);
  const [dateRange, setDateRange] = useState<any>(null);
  const { showError, showSuccess } = useMessage();

  const [loading, setLoading] = useState(false);
  async function getJourneysListData() {
    const apiKey = loginUser.data.api_key;
    try {
      if (apiKey && appId) {
        const payload = {
          dateRange,
          apiKey,
          appId,
        };
        setLoading(true);
        const data: any = await getJourneyList(payload);
        setJourneysData(data);
        filterData();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getJourneysListData();
  }, [loginUser]);
  // FILTER THE DATA
  const filterData = (search = "") => {
    const searchRegex = new RegExp(search, "i");
    setJourneysDataCopy(
      journeysData?.filter((jur: any) => {
        const isWithinDateRange =
          (!dateRange?.startDate_C ||
            jur.modifiedOn >= dateRange.startDate_C) &&
          (!dateRange?.endDate_C || jur.modifiedOn <= dateRange.endDate_C);

        // Check if the search term matches
        const isSearchMatch = searchRegex.test(jur.Journey_Name);

        // Return true if both conditions are met
        return isWithinDateRange && isSearchMatch;

      })
    );
  };

  //on  popup confirm
  const journeyDeleteConfirm = async (id: string) => {
    try {
      const payload = {
        id,
        appId,
        apiKey: loginUser.data.api_key,
      };
      await deleteJourney(payload);
      showSuccess(t("journeyDeletedSuccessfullyLabel"));

      getJourneysListData();
    } catch (error) {
      showError(t("somethingWrongLabel"));
    }
  };
  //copy the journay
  const journeyCopyConfirm = async (id: string) => {
    try {
      const payload = {
        id,
        appId,
        apiKey: loginUser.data.api_key,
      };
      await copyJourney(payload);
      showSuccess(t("copiedMsg"));

      //get updated data
      getJourneysListData();
    } catch (error) {
      showError(t("somethingWrongLabel"));
    }
  };
  const handleDateRange = (dateRangeData: any) => {
    setDateRange(dateRangeData);
  };
  useEffect(() => {
    filterData();
  }, [dateRange, journeysData]);
  useEffect(() => {
    getToken(appId, loginUser.api_key);
  }, []);
  const columns: any = [
    {
      title: t("journeyNameLabel"),
      dataIndex: "Journey_Name",
    },
    {
      title: t("journeyDescriptionLabel"),
      dataIndex: "Journey_Description",
    },
    {
      title: t("startEventLabel"),
      dataIndex: "Start_Event",
    },
    {
      title: t("endEventLabel"),
      dataIndex: "End_Event",
    },
    {
      title: t("actionLabel"),
      dataIndex: "Action",
      width: "180px",
      className: "item-align",
      render: (id: string) => {
        const imgStyle = {
          width: 16,
        };
        return (
          <span className="d-flex justify-content-between campaign-action-btns">
            {" "}
            <ConfirmPopUp
              title={t("copyJourneyMsg")}
              onConfirm={journeyCopyConfirm}
              id={id}
              icon={documentIcon}
              placement="leftTop"
            >
              <PrimaryButton title={t("copyLabel")} className="action-btn mx-1">
                <img
                  src={documentIcon}
                  alt={"document_icon"}
                  style={imgStyle}
                ></img>
              </PrimaryButton>
            </ConfirmPopUp>
            <PrimaryButton
              title={t("editLabel")}
              className="action-btn mx-1"
              onClick={() => navigate("create", { state: { id } })}
            >
              <img src={editIcon} alt={"edit_icon"} style={imgStyle}></img>
            </PrimaryButton>{" "}
            <ConfirmPopUp
              title={t("deleteJourneyMsg")}
              onConfirm={journeyDeleteConfirm}
              id={id}
              icon={deleteIcon}
              placement="leftTop"
            >
              <PrimaryButton
                title={t("deleteLabel")}
                className="action-btn mx-1"
              >
                <img src={minusIcon} alt={"minus_icon"} style={imgStyle}></img>
              </PrimaryButton>
            </ConfirmPopUp>
            <Link to={`/dashboard/journeys/${id}`}>
              <PrimaryButton title={t("viewLabel")} className="action-btn mx-1">
                <img src={eyeIcon} alt={"eye_icon"} style={imgStyle}></img>
              </PrimaryButton>
            </Link>
          </span>
        );
      },
    },
  ];
 
  return (
    <div className="container-fluid  p-sm-2 p-1">
      <Helmet>
        <title>Appice | Journeys</title>
      </Helmet>
      <div className="d-flex justify-content-between align-items-center py-3  flex-wrap">
        <div>
          <h4>{t("journeysDataLabel")}</h4>
        </div>
        <div className="d-flex">

          <div>
            <strong>{t("periodLabel")} </strong>
            <DateRangeSelector
              onDateChange={handleDateRange}
              disableRange={{ range: 1, rangeType: "year" }}
              disableType="year"
              numDaysToShow={30} // Example value
            />
          </div>
        </div>
      </div>

      <div className="px-sm-3 px-1 journey-table-container">
        <div className="py-3 m-0 d-flex flex-row w-100">
          <div className="w-100 d-flex h-100 ">
            <Search
              placeholder={searchLabel}
              onChange={(e: any) => filterData(e.target.value)}
              size="middle"
              className="table-search"
            />
            <Selector
              onChange={(value) => {
                setPageData({ ...pageData, pageSize: value });
              }}
              className="page-selector"
              size="middle"
              defaultValue={PAGE_OPTIONS.DEFAULT_VALUE}
              options={PAGE_OPTIONS.DATA}
            />
          </div>
          <div className="">
            <PrimaryButton
              type="primary"
              onClick={() => {
                navigate("create");
              }}
            >
              <PlusOutlined />
              <span className="mx-1">{t("journeyLabel")}</span>
            </PrimaryButton>
          </div>
        </div>

        <CustomTable
          columns={columns}
          totalEntries={journeysData?.length}
          dataSource={journeysDataCopy.map((jur: any) => ({
            ...jur,
            key: jur.Key,
          }))}
          size={tableSize}
          loading={loading}
          pagination={{ pageSize: pageData.pageSize, simple: false }}
          scroll={{ x: 1000 }}
        />
      </div>
    </div>
  );
};

export default JourneysTable;

