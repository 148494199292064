import { Divider } from "antd";
import "./WebHook.css";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton/PrimaryButton";
import Selector from "../../../../../components/Selector/Selector";
import React, { useState, useEffect } from "react";
// import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { useSelector } from "react-redux";
import centralApi from "../../../../../services/centralApi";
import { getToken } from "../../../../../services/getToken";
import ShowMessage from "../../../../../components/ShowMessage/ShowMessage";
import Spinner from "../../../../../components/Spinner/Spinner";
import TextInput from "../../../../../components/Inputs/TextInput/TextInput";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Space } from "antd";
import { t } from "i18next";

interface FormData {
  installs?: boolean;
  events?: boolean;
  httpMethod?: string;
}
interface WebHookProps {
  currentAppKey: any;
  updateCurrentAppKey: () => void;
}

const WebHook: React.FC<WebHookProps> = ({
  currentAppKey,
  updateCurrentAppKey,
}) => {
  const [form] = Form.useForm();

  const [formData, setFormData] = useState<FormData>({
    httpMethod: currentAppKey[0]?.webhook?.httpMethod,
  });
  const [loading, setLoading] = useState(false);
  // const [selectedValues, setSelectedValues] = useState([]); // State to store selected values from the API

  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  });
  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;
  const onFormSubmit = (values: any) => {
    const { key_value, ...restValues } = values;
    const fieldValues = key_value
      ? key_value.map(({ key, value }: any) => ({ key, value }))
      : [];

    const { callbackurl, httpMethod, partner, channel } = values;
    const payload = {
      args: JSON.stringify({
        callbackurl,
        httpMethod,
        key_value: fieldValues,
        partner,
        channel,
        // ...formData,
      }),
      app_id: appId,

      api_key: api_key,
    };
    updateCallBackUrl(payload);
    getToken(appId, api_key);
  };

  // const handleCheckboxChange = (checkedValues: CheckboxValueType[]) => {
  //   // Update formData based on checkbox selections
  //   setFormData({
  //     installs: checkedValues.includes("Installs"),
  //     events: checkedValues.includes("Events"),
  //   });
  // };

  async function updateCallBackUrl(formData: any) {
    const url: string = `/i/apps/updateCallBackUrl`;
    try {
      setLoading(true);
      await centralApi("POST", url, formData, null);
      setShowMessage({
        type: "success",
        message: "Successfully Saved",
        show: true,
      });
      setLoading(false);
      updateCurrentAppKey();
    } catch (error) {
      setLoading(false);
      setShowMessage({
        type: "error",
        message: "Something went wrong",
        show: true,
      });
    }
    hideMessage();
  }
  const hideMessage = () => {
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false });
    }, 2000);
  };
  async function deleteCallBackUrl() {
    const payload = {
      app_id: appId,

      api_key: api_key,
    };
    const url: string = `/i/apps/deleteCallBackUrl`;

    try {
      setLoading(true);
      await centralApi("POST", url, payload, null);
      setShowMessage({
        type: "success",
        message: t("sucessfullyDeletedLabel"),
        show: true,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setShowMessage({
        type: "error",
        message: t("somethingWrongLabel"),
        show: true,
      });
    }
    hideMessage();
  }
  const onFormValuesChange = (changedValues: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...changedValues,
    }));
  };
  useEffect(() => {
    getToken(appId, api_key);
  }, []);
  useEffect(() => {
    // Set initial form values when currentAppKey changes
    const updatedFormValues = {
      url: currentAppKey[0]?.webhook?.url,
      httpMethod: currentAppKey[0]?.webhook?.httpMethod,
      channel: currentAppKey[0]?.webhook?.channel,
      partner: currentAppKey[0]?.webhook?.partner,
      installs:
        currentAppKey[0]?.installs && currentAppKey[0]?.events
          ? ["Installs", "Events"]
          : currentAppKey[0]?.installs
          ? ["Installs"]
          : currentAppKey[0]?.events
          ? ["Events"]
          : [],
      key_value: currentAppKey[0]?.webhook?.key_value || [
        { key: "", value: "" },
      ],
    };
    form.setFieldsValue(updatedFormValues);
  }, [currentAppKey, form]);

  return (
    <div className="web-hook-setting-container">
      <div className="mt-4 mb-4  ">
        <div className="d-flex justify-content-between">
          <h6 className="web-hook-setting-container-heading">
            {t("webHookSettingLabel")}
          </h6>

          <PrimaryButton
            size="middle"
            type="primary"
            htmlType="submit"
            style={{ border: "none", width: "70px" }}
          >
            WebHook
          </PrimaryButton>
        </div>
        <Divider className="web-hook-setting-custom-divider" />

        <div>
          <div className=" col-sm-7 mt-4 align-items-center web-hook-setting-form ">
            {showMessage.show && (
              <ShowMessage
                type={showMessage.type}
                content={showMessage.message}
              />
            )}
            {loading ? (
              <Spinner />
            ) : (
              <Form
                form={form}
                layout="horizontal"
                initialValues={{}}
                onValuesChange={onFormValuesChange}
                onFinish={onFormSubmit}
              >
                <Form.Item
                  label={t("webHookLabel")}
                  name="url"
                  rules={[
                    {
                      required: true,
                      message: t("plzInputUrl") as string,
                    },
                    // {
                    //   type: "url",
                    //   message: "Please enter a valid URL",
                    // },
                  ]}
                >
                  <TextInput maxLength={100} />
                </Form.Item>

                <Form.Item
                  label={t("httpMethodLabel")}
                  name="httpMethod"
                  rules={[
                    { required: true, message: t("plzInputLabel") as string },
                  ]}
                >
                  <Selector
                    // onChange={handleDisableButton}
                    size="middle"
                    style={{ width: "100%" }}
                    defaultValue={"NONE"}
                    options={[
                      {
                        value: "NONE",
                        label: t("noneLabel") as string,
                      },
                      {
                        value: "GET",
                        label: t("getLabel") as string,
                      },
                      {
                        value: "POST",
                        label: t("postLabel") as string,
                      },
                    ]}
                  />
                </Form.Item>
                {formData.httpMethod === "GET" ||
                formData.httpMethod === "POST" ? (
                  <Form.Item label={t("valuesLabel") as string}>
                    <Form.List
                      name="key_value"
                      initialValue={
                        currentAppKey[0]?.webhook?.key_value || [
                          { key: "", value: "" },
                        ]
                      }
                    >
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space
                              key={key}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              align="baseline"
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "key"]}
                                rules={[
                                  {
                                    required:
                                      form.getFieldValue("httpMethod") ===
                                        "GET" ||
                                      form.getFieldValue("httpMethod") ===
                                        "POST",
                                    message: t(
                                      "missingKeyValueLabel"
                                    ) as string,
                                  },
                                ]}
                              >
                                <TextInput maxLength={100} placeholder="Key" />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "value"]}
                                rules={[
                                  {
                                    required:
                                      form.getFieldValue("httpMethod") ===
                                        "GET" ||
                                      form.getFieldValue("httpMethod") ===
                                        "POST",
                                    message: t("plzInputLabel") as string,
                                  },
                                ]}
                              >
                                <TextInput
                                  maxLength={100}
                                  placeholder="Value"
                                />
                              </Form.Item>
                              {fields.length > 1 && (
                                <MinusCircleOutlined
                                  onClick={() => remove(name)}
                                />
                              )}
                            </Space>
                          ))}
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              {t("addFieldLabel")}
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Form.Item>
                ) : null}
                <Form.Item
                  label={t("partnerLabel")}
                  name="partner"
                  rules={[
                    { required: true, message: t("plzInputLabel") as string },
                  ]}
                >
                  <Selector
                    // onChange={handleDisableButton}
                    size="middle"
                    style={{ width: "100%" }}
                    defaultValue={t("noneLabel")}
                    options={[
                      {
                        value: "NETCORE",
                        label: t("netCoreLabel"),
                      },
                      {
                        value: "AWS",
                        label: t("awsLabel"),
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  label={t("channelLabel")}
                  name="channel"
                  rules={[
                    { required: true, message: t("plzInputLabel") as string },
                  ]}
                >
                  <Selector
                    // onChange={handleDisableButton}
                    size="middle"
                    style={{ width: "100%" }}
                    defaultValue={"None"}
                    options={[
                      {
                        value: "NONE",
                        label: t("noneLabel"),
                      },
                      {
                        value: "EMAIL",
                        label: t("emailLabel"),
                      },
                      {
                        value: "SMS",
                        label: t("smsLabel"),
                      },
                      {
                        value: "WHATSAPP",
                        label: t("whatsappLabel"),
                      },
                    ]}
                  />
                </Form.Item>
                {/* <Form.Item
                  label="Data Selection"
                  name="installs"
                  rules={[{ required: true, message: "Please Select" }]}
                >
                  <Checkbox.Group onChange={handleCheckboxChange}>
                    <Checkbox value="Installs">Installs</Checkbox>
                    <Checkbox value="Events">Events</Checkbox>
                  </Checkbox.Group>
                </Form.Item> */}

                <div className="d-flex gap-2">
                  <PrimaryButton
                    size="middle"
                    type="primary"
                    htmlType="submit"
                    style={{ border: "none", width: "70px" }}
                  >
                    {t("saveLabel")}
                  </PrimaryButton>

                  <PrimaryButton
                    type="primary"
                    size="middle"
                    danger
                    style={{
                      background: "var( --color-red-main)",
                      border: "none",
                      width: "70px",
                    }}
                    onClick={deleteCallBackUrl}
                  >
                    {t("deleteLabel")}
                  </PrimaryButton>
                </div>
              </Form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default WebHook;
