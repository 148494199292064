import { MoreOutlined } from "@ant-design/icons";
import infinity from "../../../images/infinity.svg"
export const columns = [
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Availablity',
      dataIndex: 'availablity',
      // key: 'availablity',
      render:()=><div  
      // className="d-flex justify-content-center"
      >
            
            {/* <MoreOutlined style={{fontSize:"20px",fontWeight:"bold"}} /> */}
            
            <img
                src={infinity}
                alt={"close_icon"}
                style={{ width: 16 }}
              ></img>{" "}
            </div>

    },
    {
      title: 'Created On',
      dataIndex: 'createdOn',
      key: 'createdOn',
    },
    {
        title: 'Action',
        dataIndex: 'action',
        render:()=><div style={{cursor:"pointer"}} className="d-flex justify-content-center">
            
            <MoreOutlined style={{fontSize:"20px",fontWeight:"bold"}} />
            </div>
      },
  ];
  export const data = [
    { fullName: 'Coupon 10USD', type: 'Fixed value coupon', availablity: 1200, createdOn: "21 Aug, 2023 03:18 PM"  },
    { fullName: 'Draft', type: 'Material', availablity: 1200, createdOn: "22 Sept, 2023 04:18 PM"  },
    { fullName: '100PLN coupon', type: 'Fixed value coupon', availablity: 1200, createdOn: "23 Oct, 2023 05:10 PM"  },
    { fullName: 'Free Product', type: 'Material', availablity: 1200, createdOn: "24 Nov, 2023 04:18 PM"  },
    { fullName: '50PLN coupon', type: 'Fixed value coupon', availablity: 1200, createdOn: "25 Dec, 2023 03:10 PM"  },

  ];
  
  