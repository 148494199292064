import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { Button, Form } from "antd";
import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import centralApi from "services/centralApi";
import { getToken } from "services/getToken";
import Spinner from "components/Spinner/Spinner";
import { t } from "i18next";
import "./ConfigureUrls.css";
import {
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import "./ConfigureUrls.css";
import TextInput from "components/Inputs/TextInput/TextInput";
import useMessage from "hooks/useMessage";
import { API_ENDPOINTS } from "utils/constants";

interface ConfigureUrlsProps {
  currentAppKey: any;
  updateCurrentAppKey: () => void;
}

interface RootState {
  loginUser: {
    data: {
      api_key: string;
    };
  };
  activeApp: {
    appId: string;
  };
}

const ConfigureUrls: React.FC<ConfigureUrlsProps> = ({
  currentAppKey,
  updateCurrentAppKey,
}) => {
  const allCampaignColumns = currentAppKey[0]?.campaign_columns;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { showError, showSuccess } = useMessage();

  const loginUser = useSelector(
    (state: RootState) => state.loginUser.data.api_key
  );
  const activeApp = useSelector((state: RootState) => state.activeApp.appId);
  const appId = activeApp;
  const api_key = loginUser;
  const onFormSubmit = async(values: string[]) => {
    const payload = {
      args: JSON.stringify({
        app_id: appId,
        ...values,
        selectedColumns: {
          ...allCampaignColumns,
        },
      }),
      api_key: api_key,
    };
   await saveCampaignSettings(payload);
   await updateCurrentAppKey();
   await getToken(appId, api_key);
  };
  async function saveCampaignSettings(formData: any) {
    const url: string = API_ENDPOINTS.SAVE_CAMPAIGN_SETTINGS_URL;

    try {
      setLoading(true);

      await centralApi("POST", url, formData, null);
      showSuccess(t("sucessfullySavedLabel"));

      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError(t("somethingWrongLabel"));
    }
    hideMessage();
  }
  const hideMessage = () => {
    setTimeout(() => {
      showSuccess(t("sucessfullySavedLabel"));
    }, 2000);
  };

  useEffect(() => {
    getToken(appId, api_key);
  }, []);

  return (
    <div className="col-sm-7 mt-3">
      {loading ? (
        <Spinner />
      ) : (
        <Form
          form={form}
          layout="horizontal"
          initialValues={{}}
          // onValuesChange={onFormValuesChange}
          onFinish={onFormSubmit}
        >
          <Form.Item label={t("addNewUrl") as string}>
            <Form.List
              name="allowedItems"
              initialValue={
                currentAppKey[0]?.allowedItems || [{ allowedItems: [""] }]
              }
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div key={key} className="configure-url-form">
                      <Form.Item
                        {...restField}
                        name={[name]} // Set the field name to ''
                        style={{ flexGrow: 1 }}
                        rules={[
                          {
                            required: true, // Add a validation rule to check if the field is not empty
                            message: "Please enter a URL",
                          },
                        ]}
                      >
                        <TextInput maxLength={100} placeholder="urls" />
                      </Form.Item>

                      {/* Show remove icon for all fields */}
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </div>
                  ))}

                  <Button
                    type="dashed"
                    onClick={() => add()}
                    // block
                    icon={<PlusOutlined />}
                  >
                    {t("addFieldLabel")}
                  </Button>
                </>
              )}
            </Form.List>
          </Form.Item>

          <div className="d-flex gap-2">
            <PrimaryButton
              size="middle"
              type="primary"
              htmlType="submit"
              style={{ border: "none", width: "70px" }}
            >
              {t("saveLabel")}
            </PrimaryButton>
          </div>
        </Form>
      )}
    </div>
  );
};
export default ConfigureUrls;
