import { useEffect, useState, Fragment } from "react";
import "./AppSetting.css";
import { useSelector } from "react-redux";
import centralApi from "../../services/centralApi";
import AppNav from "./Components/AppNavbar/AppNav";
import React from "react";

const AppSetting: React.FC = () => {
  // const [loading, setLoading] = useState(false);
  const [currentAppKey, setCurrentAppKey] = useState("");
  const [smsConfigUn, setSmsConfigUn] = useState("");
  const [smsConfigPs, setSmsConfigPs] = useState("");
  const [channelObj, setChannelObj] = useState("");
  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);

  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;
  async function getCurrentAppKeys(){
    const payload = {
      args: JSON.stringify({
        app_id: appId,
      }),
      app_id: appId,

      api_key: api_key,
    };
    const url: string = `/i/apps/getCurrentAppKeys`;
      try {
        // setLoading(true);
        const data = await centralApi("GET", url, null, payload);
        setCurrentAppKey(data?.result);
        setSmsConfigUn(data?.result[0].smsCfg?.gupshup?.un);
        setSmsConfigPs(data?.result[0].smsCfg?.gupshup?.ps);

        // setLoading(false);
      } catch (error) {
        // setLoading(false);
      }
    }
  
  async function getChannelObj() {
    const payload = {
      args: JSON.stringify({
        app_id: appId,
      }),
      app_id: appId,

      api_key: api_key,
    };
    const url: string = `/i/apps/getChannelAccountData`;

    try {
      // setLoading(true);
      const data = await centralApi("GET", url, null, payload);
      setChannelObj(data);
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
    }
  }
  useEffect(() => {
    // Check if both loginUser and activeApp are defined
    if (appId && api_key) {
      getCurrentAppKeys();
      getChannelObj();
    }
  }, []);
  const fetchCurrentAppKeys = async () => {
    getCurrentAppKeys();
  };
  return (
    <Fragment>
      
        <AppNav
          currentAppKey={currentAppKey}
          smsConfigUn={smsConfigUn}
          smsConfigPs={smsConfigPs}
          channelObj={channelObj}
          updateCurrentAppKey={fetchCurrentAppKeys}
        />
   
    </Fragment>
  );
};
export default AppSetting;
