import React, { createContext, useContext, ReactNode } from 'react';
import { notification} from 'antd';
import { FrownOutlined, SmileOutlined } from '@ant-design/icons';
import { SuccessErrorEnum } from 'utils/enums';

// Create a context to store the notification API
interface NotificationContextProps {
  api: any;
  contextHolder: React.ReactElement;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

 const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();

  return (
    <NotificationContext.Provider value={{ api, contextHolder }}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};

 const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};


const useCustomNotification = () => {
  const { api } = useNotification();

  const openNotification = (message:string,description:string,type:"success" | "error"=SuccessErrorEnum.SUCCESS,duration:number=0
  
  ) => {
    api.open({
      message: message,
      description:
        description,
        duration,
      icon: type==SuccessErrorEnum.SUCCESS?<SmileOutlined style={{ color: '#108ee9' }} />:<FrownOutlined style={{ color: '#f5222d' }} />,
    });
  };

  return { openNotification };
};

export { useCustomNotification,NotificationProvider};
