import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../../constant";
import centralApi from "../../../services/centralApi";
interface payloadType{
    api_key:string,
     app_id:string,
      args: any,
  }
export const fetchTemplateMetaData = createAsyncThunk(
  "variants/fetchTemplateMetaData",
  async (payload:payloadType) => {
    return centralApi(
      "POST",
      "/i/templates/getTemplateMetaData",
      payload
    ).then((data) => { 
      return data;
    });
  }
);
const initialState:any= {
  data: [],
  status: STATUS.IDLE,
}
const variantsSlice = createSlice({
  name: "templateMetaData",
 initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchTemplateMetaData.pending, (state) => {
      state.status = STATUS.LOADING;
    });
    builder.addCase(fetchTemplateMetaData.fulfilled, (state,action) => {
      state.status = STATUS.IDLE;
      state.data=action.payload
    });
    builder.addCase(fetchTemplateMetaData.rejected, (state) => {
      state.status = STATUS.ERROR;
    });
  },
  reducers: {
  },
});
export default variantsSlice.reducer;


