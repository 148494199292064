import { Divider, Space } from "antd";
import "./Partner.css";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { Form } from "antd";
import TextInput from "../../../../components/Inputs/TextInput/TextInput";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import centralApi from "../../../../services/centralApi";
import { getToken } from "../../../../services/getToken";
import Spinner from "../../../../components/Spinner/Spinner";
import { t } from "i18next";
import Selector from "../../../../components/Selector/Selector";
import { MinusCircleOutlined } from "@ant-design/icons";
import useMessage from "hooks/useMessage";
interface PartnerProps {
  currentAppKey: any;
  updateCurrentAppKey: () => void;
}

const Partner: React.FC<PartnerProps> = () => {
  interface Partner {
    partnerName: string;
    channel: string;
    partnerId: string;
  }
  interface PartnerInfo {
    partnerData: Partner[];
  }
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const { showError, showSuccess } = useMessage();

  const [partnerData, setPartnerData] = useState<PartnerInfo | null>(null);
  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;
  const onFormSubmit = (values: any) => {
    const payload = {
      args: JSON.stringify({
        app_id: appId,
        ...values,
        // partnerData: values.partner,
      }),
      app_id: appId,

      api_key: api_key,
    };
    savePartnerInfo(payload);
    getToken(appId, api_key);
  };

  async function savePartnerInfo(formData: any) {
    const url: string = `/i/apps/savePartnerInfo`;

    try {
      setLoading(true);
      await centralApi("POST", url, formData, null);
      showSuccess(t("sucessfullySavedLabel"));
      setLoading(false);
      getPartnerInfo();
    } catch (error) {
      setLoading(false);

      showError(t("somethingWrongLabel"));
    }
  }
  async function getPartnerInfo() {
    const payload = {
      args: JSON.stringify({
        app_id: appId,
      }),
      app_id: appId,

      api_key: api_key,
    };
    const url: string = `/o/apps/getPartnerInfo`;

    try {
      setLoading(true);
      const data = await centralApi("GET", url, null, payload);
      //setting partner data
      setPartnerData(data?.partnerInfo?.partnerData);
      // showSuccess(t("sucessfullyFetchedLabel"));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError(t("somethingWrongLabel"));
    }
  }
  useEffect(() => {
    getPartnerInfo();
    getToken(appId, api_key);
  }, [appId]);

  useEffect(() => {
    getToken(appId, api_key);
  }, []);

  useEffect(() => {
    const updatedFormValues = {
      partnerData: partnerData,
    };
    form.setFieldsValue(updatedFormValues);
  }, [partnerData, form]);
  return (
    <div className="partner-setting-container">
      <div className="mt-4 mb-4  ">
        <h6 className="partner-setting-container-heading">
          {t("addOrRemovePartnerLabel")}
        </h6>
        <p className="partner-setting-form-headings ">
          {t("partnerSettingText")}
        </p>

        <Divider className="partner-setting-custom-divider" />

        <div>
          <div className=" col-sm-8 mt-4 align-items-center partner-setting-form ">
            {loading ? (
              <Spinner />
            ) : (
              <Form
                form={form}
                layout="horizontal"
                onFinish={onFormSubmit}
                colon={false}
              >
                {/* <Form.Item
                  label={t("nameLabel")}
                  name={"partnerName"}
                  rules={[
                    { required: true, message: t("emptyNameMsg") as string },
                  ]}
                >
                  <Selector
                    // onChange={handleDisableButton}
                    size="middle"
                    style={{ width: "100%" }}
                    // defaultValue={"NONE"}
                    options={[
                      {
                        value: "Provider",
                        label: "Provider",
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  // label={t("payloadKeyLabel")}
                  label="Channel"
                  name={"payloadKey"}
                  rules={[
                    {
                      required: true,
                      message: t("pleaseInputPayloadKeyLabel") as string,
                    },
                  ]}
                >
                  <Selector
                    // onChange={handleDisableButton}
                    size="middle"
                    style={{ width: "100%" }}
                    // defaultValue={"NONE"}
                    options={[
                      {
                        value: "Provider",
                        label: "Channel",
                      },
                    ]}
                  />{" "}
                </Form.Item>
                <Form.Item
                  // label={t("payloadKeyLabel")}
                  label="Website"
                  name={"payloadKey"}
                  rules={[
                    {
                      required: true,
                      message: t("pleaseInputPayloadKeyLabel") as string,
                    },
                  ]}
                >
                  <TextInput />
                </Form.Item> */}
                <Form.Item label={t("nameLabel") as string}>
                  <Form.List
                    name="partnerData"
                    // initialValue={partnerData|| []}

                    initialValue={
                      (partnerData as any) || [{ partnerName: "", channel: "" }]
                    }
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            key={key}
                            style={{
                              display: "flex",
                              // justifyContent: "space-between",
                            }}
                            align="baseline"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "partnerName"]}
                              // rules={[
                              //   {
                              //     required:
                              //       form.getFieldValue("httpMethod") ===
                              //         "GET" ||
                              //       form.getFieldValue("httpMethod") === "POST",
                              //     message: t("missingKeyValueLabel") as string,
                              //   },
                              // ]}
                            >
                              <TextInput maxLength={100} placeholder="Key" />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "channel"]}
                              // rules={[
                              //   {
                              //     required:
                              //       form.getFieldValue("httpMethod") ===
                              //         "GET" ||
                              //       form.getFieldValue("httpMethod") === "POST",
                              //     message: t("plzInputLabel") as string,
                              //   },
                              // ]}
                            >
                              <Selector
                                // onChange={handleDisableButton}
                                size="middle"
                                style={{ width: 150 }}
                                // defaultValue={"NONE"}
                                options={[
                                  {
                                    value: "NONE",
                                    label: t("noneLabel"),
                                  },
                                  {
                                    value: "EMAIL",
                                    label: t("emailLabel"),
                                  },
                                  {
                                    value: "WHATSAPP",
                                    label: t("whatsappLabel"),
                                  },
                                  {
                                    value: "SMS",
                                    label: t("smsLabel"),
                                  },
                                ]}
                              />
                              {/* <TextInput maxLength={100} placeholder="Value" /> */}
                            </Form.Item>
                            {fields.length > 1 && (
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            )}
                          </Space>
                        ))}
                        <Form.Item>
                          <PrimaryButton
                            type="primary"
                            onClick={() => add()}
                            // block
                            // icon={<PlusOutlined />}
                          >
                            {t("addPartnerLabel")}
                          </PrimaryButton>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Form.Item>

                <div className="">
                  <PrimaryButton
                    size="middle"
                    type="primary"
                    htmlType="submit"
                    style={{ border: "none", width: "70px" }}
                  >
                    {t("saveLabel")}
                  </PrimaryButton>
                </div>
              </Form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Partner;
