import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker } from "antd";
import type { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import "./DateRangeSelector.css";
import { DateFormatEnum } from "utils/enums";
import { t } from "i18next";
const { RangePicker } = DatePicker;

dayjs.extend(customParseFormat);

type propTypes = {
  onChange?: (value: any) => void;
  onDateChange?: (value: any) => void;
  className?: string;
  numDaysToShow?: number;
  disableRange: {
    range: number;
    rangeType: "month" | "year";
  };
  disableType: "year" | "years" | "future" | "past" | "months" | "beforeMonths";
  disableDates?: "future" | "past";
  disableFuture?: boolean;
  defaultToYearStart?: boolean; // New prop to determine if component should default to year start
  setInitialDate?: boolean;
};

export const DateRangeSelector = ({
  onDateChange,
  className,
  disableRange,
  numDaysToShow = 30,
  defaultToYearStart = false,
  setInitialDate = true,
  disableType,
}: propTypes) => {
  const [selectedDates, setSelectedDates] = useState<Dayjs[]>([]);
  const [isFirstOpen, setIsFirstOpen] = useState(true); // Flag to track first open
  
  //  range presets for quick date selection

  const rangePresets: {
    label: string;
    value: [Dayjs, Dayjs];
  }[] = [
    {
      label: t("todayLabel"),
      value: [dayjs().startOf("day"), dayjs().endOf("day")],
    },
  
    {
      label: t("yesterdayLabel"),
      value: [
        dayjs().subtract(1, "day").startOf("day"),
        dayjs().subtract(1, "day").endOf("day"),
      ],    },
  
    {
      label: t("lastWeekLabel"),
      value: [
        dayjs().subtract(1, "week").startOf("week"),
        dayjs().subtract(1, "weeks").endOf("week"),
      ],    },
    {
      label: t("last30DaysLabel"),
      value: [dayjs().subtract(29, "days").startOf("day"), dayjs().endOf("day")],
    },
    {
      label: t("thisMonthLabel"),
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
  
    {
      label: t("lastMonthLabel"),
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
  ];
  
  const handleOk = (value: any) => {
    // Ensure both start and end dates are selected

    if (value && value[1] && value[0]) {
      const startDate_C = value[0].unix();
      const endDate_C = value[1].unix();
      const startDate_P = dayjs(startDate_C * 1000)
        .subtract(endDate_C - startDate_C, "second")
        .unix();

      const endDate_P = dayjs(endDate_C * 1000)
        .subtract(endDate_C - startDate_C, "second")
        .unix();
      // Format dates for payload

      const startDate_PNew = dayjs(startDate_P * 1000).format(
        DateFormatEnum.ISO_FORMAT
      );
      const endDate_PNew = dayjs(endDate_P * 1000).format(
        DateFormatEnum.ISO_FORMAT
      );
      const startDate_CNew = dayjs(startDate_C * 1000).format(
        DateFormatEnum.ISO_FORMAT
      );
      const endDate_CNew = dayjs(endDate_C * 1000).format(
        DateFormatEnum.ISO_FORMAT
      );
      const dateRangeData = {
        startDate_P,
        endDate_P,
        startDate_PNew,
        endDate_PNew,
        startDate_CNew,
        endDate_CNew,
        endDate_C,
        startDate_C,
      };
      if (onDateChange) {
        // Send data to the parent component
        onDateChange(dateRangeData);
        setIsFirstOpen(true); // Reset flag for next open
      }
    }
  };

  useEffect(() => {
    if (setInitialDate) {
      //check if to set initial date
      const startDate = defaultToYearStart
        ? dayjs().startOf("year")
        : dayjs()
            .subtract(numDaysToShow - 1, "days")
            .startOf("day");
      const endDate = dayjs().endOf("day");

      const defaultRange = [startDate, endDate];
      setSelectedDates(defaultRange);
      handleOk(defaultRange);
    } else {
      setSelectedDates([]);
    }
  }, [defaultToYearStart, numDaysToShow]);

  const handleCalendarChange = (dates: any[] | null) => {
    if (dates && dates.length === 1) {
      // set start date
      setSelectedDates([dates[0]]);
    } else if (dates && dates.length === 2) {
      setSelectedDates(dates);
    }
  };
  const handleOpenChange = (open: boolean) => {
    //when calender opens ist time
    if (open && isFirstOpen) {
      setSelectedDates([]); // Reset dates when calendar opens for the first time
      // setSelectedDates([selectedDates[0], null]); // Set end date to null

      setIsFirstOpen(false); // Update the flag
    }
  };
  const disabledDate = (current: Dayjs, { from }: { from?: Dayjs }) => {
    const today = dayjs(); // Current date

    if (disableRange) {
      const { range, rangeType } = disableRange;

      if (disableType === "beforeMonths") {
        // Calculate the start date to compare against
        const startDate = today.subtract(range, rangeType).startOf("day"); //rangeType is month
        // Disable dates before startDate and future dates beyond today
        return current.isBefore(startDate) || current.isAfter(today);
      }
      // Handle 'year' or 'years' disableType
      if (disableType === "year" || disableType === "years") {
        const yearsToDisable = from
          ? from.add(range, rangeType).endOf("day")
          : null; //rangeType is year
        return (
          (from &&
            (current.isBefore(from, "day") ||
              (yearsToDisable && current.isAfter(yearsToDisable, "day")))) ||
          current.isAfter(today, "day")
        );
      }
    }

    // Default to not disabled if no conditions matched
    return false;
  };

  return (
    <RangePicker
      className={className}
      disabledDate={disabledDate}
      value={
        selectedDates.length > 0
          ? [selectedDates[0], selectedDates[1]]
          : undefined
      }
      onCalendarChange={handleCalendarChange}
      picker="date"
      showTime
      popupClassName="custom-date-range-selector"
      onChange={handleOk} // Call handleOk whenever dates are changed
      onOk={handleOk}
      format={DateFormatEnum.DEFAULT_FORMAT_}
      presets={rangePresets}
      onOpenChange={handleOpenChange}
    />
  );
};
