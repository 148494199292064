import { API_ENDPOINTS } from "utils/constants";
import { getRandomNumber } from "helperFunctions/number";
import apiRequest from "../services/apiRequest";
import { formatDate } from "helperFunctions/date";

//COPY CAMPAIGN
export const copyCampaign = ({cid,appId,apiKey}:any) => {
  const url: string = API_ENDPOINTS.COPY_CAMPAIGN_URL;
  return apiRequest
    .get(url, {
      params: {
        app_id: appId,
        api_key: apiKey,
        args: JSON.stringify({ d: true }),
        cid: cid,
        uniqueNumber:getRandomNumber()
      },
    })
    .then((res) => {
      console.log(res);
    })
    .catch((e) => {
      throw new Error(e.message);
    });
};
// GET CAMPAIGNS BY STATUS
export const getCampaignsByStatus = ({

  campaignTabFilter,
  pageData,
  search,
  appId,
  sd,
  ed,
  apiKey,
  channelType
}:any
) => {
  const url: string =  API_ENDPOINTS.GET_CAMPAIGNS_BY_STATUS;
  return apiRequest
    .get(url, {
      params: {
        app_id: appId,
        api_key: apiKey,
        status: campaignTabFilter,
        search: search,
        sd,
        ed,
        page: JSON.stringify(pageData),
        channelType,
        uniqueNumber:getRandomNumber()
      },
    })
    .then((res) => {
      const campaignData = res?.data?.data?.retVal; //data from API
      const totalCampaigns = res?.data?.totalCampaigns;
      const columnStatus = res?.data?.data?.columnStatus;
      const data = campaignData
        ? campaignData.map((campaign: any, ) => {
            return {
              Key: campaign._id,
              campaignDetails: {name:campaign.nm,id:campaign._id},
              Type: campaign.t,
              Audience_Segment: campaign.segmentName,
              Reachable: campaign.reach,
              Sent: campaign.pushedTo,
              Receive: campaign.receivedTo,
              Clicked: campaign.clickedTo,
              CTR: campaign.ctr,
              Conversion: campaign.conversion,
              Impressionrate: campaign.impressionRate,
              Start_Date: formatDate(campaign.sd,'DD-MM-YYYY hh:mm A'),
              End_Date: formatDate(campaign.ed,'DD-MM-YYYY hh:mm A'),
              Action: campaign,
            };
          })
        : [];
      return { data, totalCampaigns,columnStatus };
    })
    .catch((e) => {
      throw new Error(e.message);
    });
};
