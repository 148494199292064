import React, { useEffect, useState } from 'react'
import "./NativeDisplay.css";
import Personalization from '../../Personalization/Personalization';
import CustomDrawer from 'components/CustomDrawer/CustomDrawer';
import { Col, Form, Row, Space } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton/PrimaryButton';
import { t } from 'i18next';
import { v4 as uuid } from "uuid";
import { CustomData } from '../../CustomData/CustomData';
import { useForm } from 'antd/es/form/Form';
import TemplateLayout from "../../../layout/TemplateLayout/TemplateLayout";
import CampaignDevice from 'components/CampaignDevice/CampaignDevice';
import {
  fetchTemplateById,
  setSelectedTemplate,
} from "redux/features/templates_/templateByIdSlice";
import NativeDisplayContainer from '../../NotificationContainer/NativeDisplayContainer/NativeDisplayContainer';
import FormItem from 'antd/es/form/FormItem';
import Selector from 'components/Selector/Selector';
import { useDispatch, useSelector } from 'react-redux';
import {  MoreOutlined,  PlusOutlined } from '@ant-design/icons';
import VariantList from '../../VariantList/VariantList';
import centralApi from 'services/centralApi';
import { CampaignChannelTypeEnum } from 'utils/enums';
import TextInput from 'components/Inputs/TextInput/TextInput';
import SegmentedCustom from 'components/SegmentedCustom/SegmentedCustom';
import CustomCollapse from 'components/CustomCollapse/CustomCollapse';
import { CustomDataEnum } from "utils/enums";
import { validateName } from 'services/validateName';
import useMessage from 'hooks/useMessage';
import { formatDate, getCurrentDate } from 'helperFunctions/date';

import { setCurrentStep } from "redux/features/campaign/campaignCreateCurrentStepSlice";
import { setCampaignData } from "redux/features/campaign/campaignDataSlice";
import { fetchTemplatesList } from 'redux/features/templates_/templatesByTypeSlice';
import { getFeaturesSettings } from 'redux/features/app/featuresSettingSlice';
import { getToken } from 'services/getToken';
import { API_ENDPOINTS } from 'utils/constants';
const NativeDisplay = () => {
    const [form] = useForm();
    const [personalization, setPersonalization] = useState({
        open: false,
        field: "",
      });
      const { DEVICE, IMAGE } = CustomDataEnum;
      const [oldTemplateName, setOldTemplateName] = useState(""); //old template name store to avoid validation if remplate name does not change
      const { showError } = useMessage();

    const loginUser = useSelector((state: any) => state.loginUser.data);
    const templates = useSelector((state: any) => state.templatesList.data);
    const { appId } = useSelector((state: any) => state.activeApp);
    const languageVariants = useSelector((state: any) => state.templateMetaData.data);
    const features = useSelector((state: any) => state.featuresSetting.data);
    const featuresSetting = features[0]?.features;
    const campaignData = useSelector((state: any) => state.campaignData.data);
    const currentStep = useSelector(
      (state: any) => state.campaignCreateCurrentStep
    );
    const [formData, setFormData] = useState<any>({
      notificationType: "dl",
      externalUrl:"",
      cdata: [],
    });
    const selectedTemplate = useSelector((state: any) => state.templateById.data);

    const [showDrawer, setShowDrawer] = useState(false);
    const [deviceType, setDeviceType] = useState<"IOS" | "Android">("Android");
    const [selectedVariant, setSelectedVariant] = useState<any>(null);
    const [variants, setVariants] = useState<any>([]);
    const [customDataState, setCustomDataState] = useState<any>({
      [uuid()]: {
        key: "",
        value: ""
      }
    });
    const [template, setTemplate] = useState({
        template_name: "",
        template_type: CampaignChannelTypeEnum.NATIVE,
        isTemplateDeleted: "false",
      });

    const dispatch: any = useDispatch();
   useEffect(() => {
    saveCdata();
   },[customDataState])
  useEffect(() => {
    const { template, template_name, template_type } =
      selectedTemplate || campaignData.data || formData;
   
    if (template || selectedVariant) {
      const {   
        externalUrl,
        notificationType,
        cdata,
        } =
        Array.isArray(template) || selectedVariant
          ? selectedVariant
            ? selectedVariant
            : template[0]
          : template
          ? template
          : formData;
      setFormData((prevState: any) => ({
        ...prevState,
        externalUrl,
        cdata,
        notificationType,
        
      }));
      if (template_name) {
        setTemplate((prevState) => ({
          ...prevState,
          template_name,
          template_type,
        }));
      }
     
      if (cdata) {
        const custom_data: any = {};
        if (Array.isArray(cdata)) {
          // Handle array of objects
          cdata.forEach((obj: any) => {
            const [key, value] = Object.entries(obj)[0]; // Destructuring to get the first key-value pair
            custom_data[uuid()] = {
              key: key, // Key obtained from destructuring
              value: value, // Value obtained from destructuring
              type: obj?.type || "", // Assuming obj has a property called 'type'
            };
          });
        } else if (typeof cdata === "object" && cdata !== null) {
          // Handle plain object
          Object.keys(cdata).forEach((key: string) => {
            custom_data[uuid()] = { key, value: cdata[key] };
          });
        }
        setCustomDataState(custom_data);
      }
    
    }
    
    setOldTemplateName(template_name); // save template name
  }, [selectedTemplate, selectedVariant, campaignData]);
  
  const handleTemplateUpdate = async () => {
    try {
      const payload = {
        api_key: loginUser.api_key,
        app_id: appId,
        template_id: selectedTemplate._id,
        args: JSON.stringify({
          ...template,
          template: variants.length > 0 ? variants : formData,
          modifiedOn: formatDate(getCurrentDate(), "MM/DD/YYYY"),
        }),
      };
      const res = await centralApi("POST", API_ENDPOINTS.UPDATE_CAMPAIGN_TEMPLATE, payload);
      dispatch(
        setCampaignData({
          ...campaignData,
          data: { ...res },
          tid: res._id,
          t: res.template_type,
        })
      );
      dispatch(setCurrentStep(currentStep + 1));
    } catch (error) {
      showError(t("somethingWrongLabel"))

    }
  };
  const handleTemplateSave = async () => {
    try {
      const payload = {
        api_key: loginUser.api_key,
        app_id: appId,
        args: JSON.stringify({
          ...template,
          template: variants.length > 0 ? variants : formData,
          createdOn: formatDate(getCurrentDate(), "MM/DD/YYYY"),
          modifiedOn: "",
        }),
      };
      const res = await centralApi("POST", API_ENDPOINTS.CREATE_CAMPAIGN_TEMPLATE, payload);
      dispatch(
        setCampaignData({
          ...campaignData,
          data: { ...res },
          tid: res._id,
          t: res.template_type,
        })
      );
      dispatch(setCurrentStep(currentStep + 1));
    } catch (error) {
      showError(t("somethingWrongLabel"))
    }
  };
   const handleSaveOrUpdate = async () => {
    try {
      // Perform name validation
      let isValidTemplateName;
      // Call validateName only when template name is changed
      if (template.template_name !== oldTemplateName) {
        try {
          isValidTemplateName = await validateName(
            template.template_name,
            CampaignChannelTypeEnum.NATIVE,
            loginUser.api_key,
            appId
          );
          // Proceed with form validation and submission
        } catch (error) {
          showError(t("somethingWrongLabel"))
        }
      }

      // Validate the form fields
      if (isValidTemplateName) {
        form.setFields([
          {
            name: "templateName",
            errors: [t("templateNameExistsErrorMessage")],
          },
        ]);
      } else {
        //check other fields validation
        await form.validateFields();
        // Form is valid, proceed with saving or updating
        selectedTemplate ? handleTemplateUpdate() : handleTemplateSave();
      }
    } catch (errors) {
      showError(t("validationErrorLabel"))
    }
  };
    const handleVariantClick = (id: string) => {
        variants.forEach((variant: any) => {
          if (variant.variance_id == id) {
            setSelectedVariant(variant);
          }
        });
      };
      useEffect(() => {
        dispatch(
          fetchTemplatesList({
            app_id: appId,
            api_key: loginUser.api_key,
            template_type: "NATIVE",
          })
        );
        dispatch(
          getFeaturesSettings({
            api_key: loginUser.api_key,
            app_id: appId,
          })
        );
       
        getToken(appId, loginUser.api_key);
        if (campaignData.data) {
          dispatch(setSelectedTemplate(campaignData.data));
        }
      }, []);
    const handleCreateVariant = async (
        type: "variant" | "language" = "variant",
        language: any = {}
      ) => {
        const payload = {
          app_id: appId,
          api_key: loginUser.api_key,
        };
        const id =
          type == "variant"
            ? await centralApi("GET", "o/metrices/getObjectId", null, payload)
            : language?._id;
        const variance_top_name =
          type == "variant"
            ? `Variant ${
                variants.filter((variant: any) =>
                  variant?.variance_top_name.includes("Variant")
                ).length + 1
              }`
            : language?.name;
        const payloadData: any = {
          variance_top_name,
          variance_id: id,
          variant_type: "Multivariate",
          ...formData,
          variance_name: template.template_name,
        };
        setVariants((prevState: any) => [...prevState, payloadData]);
      };

    const handleDeleteVariant = (id: string) => {
        const afterVariantDelete = variants.filter(
          (variant: any) => variant.variance_id !== id
        );
        setVariants(afterVariantDelete);
      };

      
      useEffect(() => {
        // Set initial values when template changes
        if (selectedTemplate) {
          form.setFieldsValue({
            templateName: template.template_name,
           });
        }
      }, [template, form]);
//set custom data in form state
const saveCdata = () => {
  const cdata: any = [];

  Object.values(customDataState).forEach((value: any) => {
    if (value.value && value.key) {
      const dataObject = {
        [value.key]: value.value,
        type: value.type === DEVICE ? IMAGE:value?.type,

      };
      cdata.push(dataObject);
    }
  });
  setFormData((prevState: any) => ({ ...prevState, cdata }));
};
  return (
    <div className="native-display-parent-container container-fluid  ">
    <Personalization
      personalizationState={personalization}
      setFormData={setFormData}
      setPersonalizationState={setPersonalization}
    />
    <CustomDrawer
      open={showDrawer}
      placement="right"
      onClose={() => {
        setShowDrawer(false);
      }}
      className="p-0"
      closable={false}
      width={540}
      footer={
        <Space className="d-flex justify-content-end">
          <PrimaryButton
            onClick={() => {
              setShowDrawer(false);
            }}
          >
            {t("cancelLabel")}
          </PrimaryButton>
          <PrimaryButton
            type="primary"
            onClick={() => {
              setShowDrawer(false);
            }}
          >
            {t("saveLabel")}
          </PrimaryButton>
        </Space>
      }
      height={"90%"}
    >
      <CustomData
        customDataState={customDataState}
        setCustomDataState={setCustomDataState}
      />
     </CustomDrawer>
     <Form
        colon={false}
        labelAlign="left"
        labelCol={{ flex: "180px" }}
        layout="horizontal"
        form={form}
      >
        <TemplateLayout
          leftItems={
            <CampaignDevice
              setDevice={(value: "IOS" | "Android") => {
                setDeviceType(value);
              }}
            >
              <div className="h-100">
                <NativeDisplayContainer
                  type={deviceType}
                  message={t("nativePhoneLabel")}
                />
              </div>
            </CampaignDevice>
          }
          rightItems={
            <div>
                <Row className="pt-2">
                <Col span={24}>
                  <h5 className="mt-0">{t("nativeDisplayLabel")}</h5>
                </Col>
                <Col className="d-flex gap-1 justify-content-between" span={24}>
                  <FormItem
                    className="my-0 w-100"
                    name={"template_name"}
                    label=""
                  >
                    <Selector
                      onChange={(tempString) =>
                        dispatch(
                          fetchTemplateById({
                            app_id: appId,
                            api_key: loginUser.api_key,
                            template_id: JSON.parse(tempString)._id,
                          })
                        )
                      }
                      options={templates.map(
                        ({
                          template_name,
                          _id,
                        }: {
                          template_name: string;
                          _id: string;
                        }) => ({
                          label: template_name,
                          value: JSON.stringify({ _id, template_name }),
                        })
                      )}
                      placeholder={t("selectingExistingTemplateLabel")}
                    />
                  </FormItem>
                  <div className="d-flex gap-1  align-items-center">
                    {!featuresSetting?.ab_testing_variant && (
                      <Selector
                        style={{ width: 110 }}
                        onChange={handleVariantClick}
                        disabled={
                          !template.template_name || variants.length >= 6
                        }
                        value={
                          selectedVariant?.variance_top_name?.includes(
                            "Variant"
                          ) && selectedVariant?.variance_id
                        }
                        placeholder={t("variantLabel")}
                        dropdownRender={(menu) => (
                          <>
                            <PrimaryButton
                              type="link"
                              className="w-100"
                              onClick={() => handleCreateVariant()}
                            >
                              <PlusOutlined />
                              <span className="ms-2 me-3">Create</span>
                            </PrimaryButton>
                            {menu}
                          </>
                        )}
                        options={variants
                          ?.filter((variant: any) =>
                            variant?.variance_top_name?.includes("Variant")
                          )
                          .map((variant: any) => ({
                            label: variant.variance_top_name,
                            value: variant?.variance_id,
                          }))}
                      />
                    )}
                    {!featuresSetting?.language_variant && (
                      <Selector
                        style={{ width: 110 }}
                        disabled={
                          !template.template_name || variants.length >= 6
                        }
                        onChange={(jsonData) => {
                          handleCreateVariant("language", JSON.parse(jsonData));
                        }}
                        options={languageVariants?.varaints?.map((langVariant: any) => ({
                          label: langVariant.name,
                          value: JSON.stringify(langVariant),
                        }))}
                        placeholder={t("languageLabel")}
                      />
                    )}
                  </div>
                </Col>
                <Col
                  span={24}
                  className="d-flex aligm-items-center justify-content-between pt-2"
                >
                  <VariantList
                    variants={variants}
                    selectedVariant={selectedVariant}
                    handleDeleteVariant={handleDeleteVariant}
                    handleVariantClick={handleVariantClick}
                  />
                </Col>
              </Row>
              <Row className="pt-2 pb-2 position-relative d-flex justify-content-center">
                <Col className="d-flex gap-1 mb-4 " span={24}>
                  <FormItem
                    initialValue={template.template_name}
                    className="w-100 m-0 mt-2  "
                    label={t("templateNameLabel")}
                    name={"templateName"}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <TextInput
                      onChange={(e: any) => {
                        setTemplate((prevState) => ({
                          ...prevState,
                          template_name: e.target.value,
                        }));
                      }}
                      placeholder={t("templateNameLabel")}
                      value={template.template_name}
                    ></TextInput>
                  </FormItem>
             
                </Col>
             
                <CustomCollapse
                  props={{ className: "w-100" }}
                  header={t("settingsLabel")}
                  defaultActiveKey="1" // Keep the panel open initially

                  body={
                    <>                     
                        <>

                          <div className="d-flex  w-100 justify-content-end">
                            <MoreOutlined
                              onClick={() => {
                                setPersonalization({
                                  open: true,
                                  field: "externalUrl",
                                });
                              }}
                            />
                          </div>
                          <Col className="d-flex mt-2" span={24}>
                            <FormItem
                              className="w-100 m-0 mt-2"
                              label={t("urlLabel")}
                            >
                              <TextInput
                                onChange={(e: any) =>
                                  setFormData((prevState: any) => ({
                                    ...prevState,
                                    externalUrl: e.target.value,
                                  }))
                                }
                                value={formData.externalUrl}
                                placeholder={t("externalURL/deepLinkURLLabel")}
                              />
                            </FormItem>
                          </Col></>
                          <Col className="mt-2" span={24}>
                            <div className="d-flex flex-wrap justify-content-between w-100">
                              <FormItem
                                className="m-0 w-100 mt-2"
                                label={t("URLTypeLabel")}
                              >
                                <SegmentedCustom
                                  value={formData.notificationType || "dl"}
                                  onChange={(value: "lp" | "dl") => {
                                    setFormData((prevState: any) => ({
                                      ...prevState,
                                      notificationType: value,
                                    }));
                                  }}
                                  options={[
                                    {
                                      label: t("landingPageLabel"),
                                      value: "lp",
                                    },
                                    { label: t("deepLinkLabel"), value: "dl" },
                                  ]}
                                />
                              </FormItem>
                              {formData?.notificationType == "lp" && !featuresSetting?.landingPageView &&(
                                <FormItem
                                  className=" my-0 mt-2"
                                  label={t("openInLabel")}
                                >
                                  <SegmentedCustom
                                    options={[
                                      {
                                        label: t("browserLabel"),
                                        value: "browser",
                                      },
                                      {
                                        label: t("webViewLabel"),
                                        value: "webview",
                                      },
                                    ]}
                                  />
                                </FormItem>
                              )}
                            </div>

                           </Col>
                        </>
                  }
                >

                </CustomCollapse>
              </Row>
              <Row>
                 <div className="key-paircontainer">
                    <div className='border-bottom mb-3 d-flex '>
                       <h6 className='mb-3 fw-bold text-secondary'>{t("customKeyValueLabel")}</h6> 
                     </div>                      
                    <Row>
              <CustomData
        customDataState={customDataState}
        setCustomDataState={setCustomDataState}
      />
              </Row>
                  </div>
            
              </Row> 
              
            </div>
          }
          footerItem={
            <Row className="gap-2  d-flex justify-content-end">
              <PrimaryButton
                disabled={!template.template_name}
                type="primary"
                htmlType="submit"
                onClick={() => {
                  saveCdata();
                  handleSaveOrUpdate();
                }}>
               {t("saveLabel")}
              </PrimaryButton>
              <PrimaryButton
                style={{
                  color: "white",
                  background: "var( --color-red-main)",
                }}
              >
                {t("closeLabel")}
              </PrimaryButton>
            </Row>
          }
          />
     </Form>
</div>
  )
}

export default NativeDisplay