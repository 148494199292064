import { Divider } from "antd";
import "./CampaignConfigurator.css";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { Form } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Spinner from "../../../../components/Spinner/Spinner";
import TextInput from "../../../../components/Inputs/TextInput/TextInput";
import { t } from "i18next";
import centralApi from "services/centralApi";
import Selector from "components/Selector/Selector";
import ConfiguratorTable from "components/ConfiguratorTable/ConfiguratorTable";
import useMessage from "hooks/useMessage";
import { getToken } from "services/getToken";

interface CampaignConfiguratorProps {
  currentAppKey: any;
}

const CampaignConfigurator: React.FC<CampaignConfiguratorProps> = () => {
  const [form] = Form.useForm();
  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  
  const { showError, showSuccess } = useMessage();
  const [loading, setLoading] = useState(false);
  const [partnerData, setPartnerData] = useState<any>({});

  const [partnerNames, setPartnerNames] = useState<any[]>([]);
  const [selectedChannel, setSelectedChannel] = useState<string>("");
  const [formData, setFormData] = useState<any>({
    partner: "",
    channel: "",
    providerType: "",
    url: "",
    callBackUrl: "",
    response: [
      {
        SourceKey: "",
        SourceValue: "",
        SourceType: "",
        DestinationKey: "",
        DestinationValue: "",
        DestinationType:"",
      },
    ],
    request: [
      {
        SourceKey: "",
        SourceValue: "",
        SourceType: "",
        DestinationKey: "",
        DestinationValue: "",
        DestinationType:"",
      },
  
    ],
  });
  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;

  const saveCampaignConfigurator = () => {
    const payload = {
      args: JSON.stringify({
        app_id: appId,
        ...formData,
        api_key: api_key,
        appKey: api_key,
        date: new Date(),
      }),
      api_key: api_key,
      app_id: appId,
    };
    saveCampConfigurator(payload);
    getPartnerInfo();
    // updateCurrentAppKey()
    getToken(appId, api_key)
  };


  async function saveCampConfigurator(formData: any) {
    const url: string = `/i/apps/saveCampConfigurator`;

    try {
      setLoading(true);

      await centralApi("POST", url, formData, null);
      showSuccess(t("sucessfullySavedLabel"));

      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError((error as Error).message);

    }
  }
  async function getPartnerInfo() {
    const payload = {
      args: JSON.stringify({
        app_id: appId,
      }),
      app_id: appId,

      api_key: api_key,
    };
    const url: string = `/o/apps/getPartnerInfo`;

    try {

      setLoading(true);
      const data = await centralApi("GET", url, null, payload);
      setPartnerData(data);
      // setFormData(data)
      setLoading(false);
    } catch (error) {
      setLoading(false);

      showError((error as Error).message);
    }
  }
  const handleChannelChange = (selectedChannel: string) => {
    setSelectedChannel(selectedChannel);

    // Find the selected partner
    const selectedPartner = partnerData?.partnerInfo?.partnerData.find((partner:{channel:string}) => partner.channel === selectedChannel);

    // Update the options for partnerName selector
    const partnerNames = selectedPartner ? [{ value: selectedPartner.partnerName, label: selectedPartner.partnerName }] : [];
    setPartnerNames(partnerNames);

    // Update the form data with the selected partner's name
    setFormData((prevState:any) => ({
        ...prevState,
        partnerName: selectedPartner?.partnerName || '', // Ensure to handle the case when selectedPartner is undefined
    }));
};

  // const handleChannelChange = (selectedChannel:string) => {
  //   setSelectedChannel(selectedChannel)
  //   // Filter partnerData based on selectedChannel
  //   // const partnerInfo = partnerData?.partnerInfo;

  //   const filteredPartners = partnerData?.partnerInfo?.partnerData.filter(
  //     (item: { channel: string }) => item?.channel === selectedChannel
  //   );
  //   const selectedPartner = partnerData?.partnerInfo?.partnerData.find((partner:{channel:string})=> partner?.channel === selectedChannel);

  //   // Update the options for partnerName selector
  //   setPartnerNames(
  //     filteredPartners.map((item:{partnerName:string}) => ({
  //       value: item.partnerName,
  //       label: item.partnerName,
  //     }))
  //   );
  //   setFormData((prevState: any) => ({
  //     ...prevState,
  //     partnerName: selectedPartner?.partnerName,
  //   }));
  // };
  
  useEffect(() => {
    if(selectedChannel){
    if (partnerData?.campaignConfigurator?.[selectedChannel]) {
      const {
        campaignConfigurator: {
          [selectedChannel]: {
            Verb,
            response,
            callBackUrl,
            channel,
            key_value,
            providerType,
            url,
          },
        },
      } = partnerData || {};
      setFormData((prevState: any) => ({
        ...prevState,
        httpRequest:Verb,
        response,
        callBackUrl,
        channel,
        request: key_value,
        providerType,
        url,
        
      }));
    }}

  }, [selectedChannel]);
  useEffect(() => {
    getPartnerInfo();
    getToken(appId, api_key)

  }, []);
  return (
    <div className="campaign-configurator-setting-container">
      
      <div className="mt-4 mb-4  ">
        <div className="d-flex ">
          <h6 className="campaign-configurator-setting-container-heading">
            {t("campaignConfiguratorHeadingLabel")}
          </h6>
        </div>
        <Divider className="campaign-configurator-setting-custom-divider" />
        {loading ? (
          <Spinner />
        ) : (
          <div>
            <div className=" col-sm-11 mt-4 align-items-center campaign-configurator-setting-form ">
              <h6 className="campaign-configurator-setting-container-heading">
                {t("partnerLabel")}
              </h6>
              {/* <Divider className="campaign-configurator-setting-custom-divider" /> */}
              <Form form={form} layout="horizontal" colon={false}>
                <div className="d-flex gap-4 col-sm-7">
                  <Form.Item className="w-100" label="" labelCol={{ span: 5 }}>
                    <Selector
                
                placeholder= {t("selectChannelLabel")}
                      value={formData?.channel || undefined}
                      size="middle"
                      onChange={handleChannelChange}
                     
                      options={partnerData?.partnerInfo?.partnerData.map(
                        (item:any) => ({
                          value: item.channel,
                          label: item.channel,
                        })
                      )}
             
                    />
                  </Form.Item>
                  <Form.Item label="" className="w-100" labelCol={{ span: 7 }}>
                    <Selector
                      // onChange={(value) => {
                      //   setFormData((prevState:any)=>({
                      //     ...prevState,
                      //     partner:value
                      //   }));
                      // }}
                      placeholder= {t("selectPartnerLabel")}

                      value={formData?.partnerName}

                      size="middle"
                      // style={{ width: 150 }}
                      // defaultValue={"0"}
                      options={partnerNames}
                    />{" "}
                  </Form.Item>
                </div>
                <div>
                  <h6 className="campaign-configurator-setting-container-heading">
                    
                  {t("requestLabel")}

                  </h6>
                  {/* <Divider className="campaign-configurator-setting-custom-divider" /> */}

                  <div className="d-flex gap-4 col-sm-7">
                    <Form.Item label="" className="w-100">
                      <Selector
                                            placeholder= {t("providerTypeLabel")}

                        onChange={(value) => {
                          setFormData((prevState: any) => ({
                            ...prevState,
                            providerType: value,
                          }));
                        }}
                        size="middle"
                        value={formData?.providerType || undefined}

              
                        options={[
                          {
                            value: "generic",
                            label: "Generic",
                          },
                          {
                            value: "ses",
                            label: "Ses",
                          },
                          {
                            value: "smtp",
                            label: "SMTP",
                          },
                          {
                            value: "cns",
                            label: "CNS",
                          },
                        ]}
                      />{" "}
                    </Form.Item>
                    <Form.Item
                      label=""
                      className="w-100"
                      labelCol={{ span: 5 }}
                    >
                      <TextInput
                                              value={formData?.url}

                        onChange={(e:any) => {
                          setFormData((prevState: any) => ({
                            ...prevState,
                            url: e.target.value,
                          }));
                        }}
                        placeholder= {t("urlLabel")}

                      />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <ConfiguratorTable
                    tableData={formData.request}
                    type="request"
                    setTableData={(value: any) =>
                      setFormData((prevState: any) => ({
                        ...prevState,
                        request: value,
                      }))
                    }
                  />
                </div>
                <div className="mt-3">
                  <h6 className="campaign-configurator-setting-container-heading">
                    {t("responseLabel")}
                  </h6>
                  {/* <Divider className="campaign-configurator-setting-custom-divider" /> */}

                  <div className="d-flex gap-4 col-sm-7 ">
                    <Form.Item
                      label=""
                      className="w-100"
                      labelCol={{ span: 5 }}
                    >
                      <Selector
                        onChange={(value) => {
                          setFormData((prevState: any) => ({
                            ...prevState,
                            httpRequest: value,
                          }));
                        }}
                        value={formData?.httpRequest}
                        size="middle"
                        placeholder= {t("httpMethodLabel")}

                        options={[
                          {
                            value: "GET",
                            label: "GET",
                          },
                          {
                            value: "POST",
                            label: "POST",
                          },
                        ]}
                      />{" "}
                    </Form.Item>
                    <Form.Item
                      className="w-100"
                      labelCol={{ span: 5 }}
                      label=""
                    >
                      <TextInput
                        //  onChange={(e) => {
                        //   setFormData((prevState: any) => ({
                        //     ...prevState,
                        //     url: e.target.value,
                        //   }))
                        // }}
                        // style={{ width: 300 }}
                        value={formData?.callBackUrl}
                        placeholder= {t("destinationLabel")}

                        onChange={(e:any) => {
                          setFormData((prevState: any) => ({
                            ...prevState,
                            callBackUrl: e.target.value,
                          }));
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <ConfiguratorTable
                    type="response"
                    tableData={formData.response}
                    setTableData={(value: any) =>
                      setFormData((prevState: any) => ({
                        ...prevState,
                        response: value,
                      }))
                    }
                  />
                </div>

                <div className="mt-4">
                  <PrimaryButton
                    size="middle"
                    type="primary"
                    htmlType="submit"
                    style={{ border: "none", width: "70px" }}
                    onClick={saveCampaignConfigurator}
                  >
                    {t("saveLabel")}
                  </PrimaryButton>
                </div>
              </Form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default CampaignConfigurator;
