import { MoreOutlined } from "@ant-design/icons";

export const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Condition Value",
    dataIndex: "value",
    key: "value",
  },

  {
    title: "Action",
    dataIndex: "action",
    className: "text-center",
    width: "100px",
    render: () => {
      return (
        <div
          style={{ cursor: "pointer" }}
          className="d-flex justify-content-center"
        >
          <MoreOutlined style={{ fontSize: "20px", fontWeight: "bold" }} />
        </div>
      );
    },
  },
];
export const tierData = [
  { name: 'Bronze', value: 500 },
  { name: 'Silver', value: 300 },
];
