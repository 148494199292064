import { DatePicker, DatePickerProps } from 'antd';
import React, { FC } from 'react'
import { disabledDate } from '../../../helperFunctions/date';
const SingleDateSelector: FC<DatePickerProps> = ({ ...props }) => {
  return (
    <div>
     <DatePicker {...props} disabledDate={props.disabledDate || disabledDate}/>
    </div>
  )
}

export default SingleDateSelector;