import React, { useEffect,useState } from "react";
import Selector from "components/Selector/Selector";
import "./Usage.css";
import centralApi from "../../services/centralApi";
import { useSelector } from "react-redux";
import HeaderCountPercentage from "./component/HeaderCountPercentage";
import { Helmet } from "react-helmet";
import { t } from "i18next";
import { DonutPieChart } from "components/Charts/DonutPieChart/DonutPieChart";
import CustomTable from "components/CustomTable/CustomTable";
import { colorSchema } from "components/Charts/DonutPieChart/colorSchema";
import { Heatmap } from "components/Charts/Heatmap/Heatmap";
import { aggregateData } from "../../helperFunctions/usage";
import { aggregateTimelySessionData } from "../../helperFunctions/usage";
import { formatData } from "../../helperFunctions/usage";
import { getPercentage } from "../../helperFunctions/number";
import { calculateTotalValues } from "../../helperFunctions/usage";
import { fillMissingValues } from "../../helperFunctions/usage";
import Spinner from "components/Spinner/Spinner";
import { DateRangeSelector } from "components/DateTimeSelectors/DateRangeSelector/DateRangeSelector";
import { DateRangeData } from "@customTypes/dateRange";
import TableChartLayout from "layout/TableChartLayout/TableChartLayout";
import { API_ENDPOINTS } from "utils/constants";
import { PlatformEnum } from "utils/enums";
import { CONSTANTS } from "utils/constants/constants";

const Usage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [activeUserSessionStats, setActiveUserSessionStats] = useState<any>({
    apiData: {},
    aggregateData: [],
    averageAggregateData: [],
    tableData: [],
    averageTableData: [],
    totalSessionInfo: {},
  });
  const [userTimelySessionData, setUserTimelySessionData] = useState<any>({
    apiData: {},
    aggregateData: [],
    tableData: [],
    count: { previous: 0, current: 0 },
  });
  const { appId, appName } = useSelector((state: any) => state.activeApp);
  const [dateRange, setDateRange] = useState<DateRangeData | null>(null);
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const [platform, setPlatform] = useState(PlatformEnum.ALL);
  // fetch session stats  data from api
  async function getActiveUserSessionStats() {
    const payload = {
      args: JSON.stringify({ ...dateRange }),
      app_id: appId,
      platform: platform,
      api_key: loginUser.api_key,
    };
    try {
      const data = await centralApi(
        "GET",
        API_ENDPOINTS.ACTIVE_USER_SESSION_STATS_URL,
        null,
        payload
      );
      setActiveUserSessionStats((prevState: any) => ({
        ...prevState,
        apiData: data,
      }));
    } catch (error) {}
  }
  //fetch timely session data from api
  async function getActiveUserTimelySessions() {
    const payload = {
      args: JSON.stringify({ ...dateRange }),
      app_id: appId,
      api_key: loginUser.api_key,
      platform,
    };
    try {
      setLoading(true);
      const data = await centralApi(
        "GET",
        API_ENDPOINTS.ACTIVE_USER_TIMELY_SESSIONS_URL,
        null,
        payload
      );
      setUserTimelySessionData((prevState: any) => ({
        ...prevState,
        apiData: data,
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  const formatDataForTable = (data: any) => {
    return data?.map((entry: any) => {
      const rowData = {
        key: entry.id.charAt(0).toUpperCase() + entry.id.slice(1), // Capitalize the first letter
        other: 0,
        office: 0,
        home: 0,
        ...entry.data.reduce(
          (acc: Record<string, number>, place: { x: string; y: number }) => {
            acc[place.x] = place.y;
            return acc;
          },
          {}
        ),
      };
      const total = Object.values(rowData).reduce(
        (acc: any, value: any) => acc + (parseFloat(value) || 0),
        0
      );
      rowData["total"] = total;

      return rowData;
    });
  };
  useEffect(() => {
    const total_session_info = calculateTotalValues(
      activeUserSessionStats.apiData,
      platform
    );
    setActiveUserSessionStats((prevState: any) => ({
      ...prevState,
      totalSessionInfo: total_session_info,
    }));
    const setAggregateStates = (type: typeof CONSTANTS.AU | typeof CONSTANTS.ASL) => {
      const aggregate_data = aggregateData(
        activeUserSessionStats.apiData,
        platform,
        appName,
        type
      );
      const key_name = type == CONSTANTS.AU ? CONSTANTS.AGGREGATE_DATA : CONSTANTS.AVG_AGGREGATE_DATA;
      const table_key_name = type == CONSTANTS.AU ? CONSTANTS.TABLE_DATA : CONSTANTS.AVG_TABLE_DATA;
      if (aggregate_data !== null) {
        const formattedData = Object.entries(aggregate_data).map(
          ([key, value]) => ({
            id: key,
            label: key,
            value: value,
          })
        );
        const aggregate_data_table =
          formattedData !== null
            ? formattedData?.map((item: any) => ({
                session: item.label + type == "asl" ? "mins" : "",
                user: item.value || 0,
              }))
            : [];
        setActiveUserSessionStats((prevState: any) => ({
          ...prevState,
          [key_name]: formattedData,
          [table_key_name]: aggregate_data_table,
        }));
      } else {
        setActiveUserSessionStats((prevState: any) => ({
          ...prevState,
          [key_name]: [],
          [table_key_name]: [],
        }));
      }
    };
    setAggregateStates(CONSTANTS.AU);
    setAggregateStates(CONSTANTS.ASL);
  }, [activeUserSessionStats.apiData, platform]);
  useEffect(() => {
    const timelySessionDataAggregate = aggregateTimelySessionData(
      userTimelySessionData.apiData,
      platform
    );
    const formattedData = formatData(timelySessionDataAggregate);
    // Modified data with missing values filled in
    const aggregateData = fillMissingValues(formattedData);
    const tableData = formatDataForTable(aggregateData);
    const totalCurrentCount = tableData
      ? tableData.reduce((acc: any, entry: any) => acc + (entry?.total || 0), 0)
      : 0;
    const filter = platform == PlatformEnum.ALL ? CONSTANTS.PREVIOUS_TOTAL : platform;
    const totalPreviousCount =
      userTimelySessionData?.apiData?.previous?.[filter];
    setUserTimelySessionData((prevState: any) => ({
      ...prevState,
      aggregateData: aggregateData,
      tableData: tableData,
      count: { previous: totalPreviousCount, current: totalCurrentCount },
    }));
  }, [userTimelySessionData.apiData, platform]);
  useEffect(() => {
    if (dateRange && loginUser.api_key) {
      getActiveUserSessionStats();
      getActiveUserTimelySessions();
    }
  }, [loginUser, dateRange]);
  const styleBtn = {
    width: "auto",
    marginRight: "10px",
    marginBottom: "10px",
  };

  const userSessionColumn = [
    {
      title: t("sessionAndUsersLabel"), // Hardcoded title
      dataIndex: "session",
      key: "session",
    },
    {
      title: t("usageBoxLabelUser"),
      dataIndex: "user",
      key: "user",
    },
  ];
  const avgSessionLengthColumn = [
    {
      title: t("sessionLengthUsageLabel"),
      dataIndex: "session",
      key: "session",
    },
    {
      title: t("usageBoxLabelUser"),
      dataIndex: "user",
      key: "user",
    },
  ];
  const userTimelySessionColumn = [
    {
      title: t("timePeriodlabel"),
      dataIndex: "key",
    },
    {
      title: t("otherLabel"),
      dataIndex: "other",
      key: "other",
    },
    {
      title: t("officeLabel"),
      dataIndex: "office",
      key: "office",
    },
    {
      title: t("homeLabel"),
      dataIndex: "home",
      key: "home",
    },
    {
      title: t("totalLabel"),
      dataIndex: "total",
      key: "total",
    },
  ];
  const userHeaderComp = (
    <HeaderCountPercentage
      total={activeUserSessionStats.totalSessionInfo?.currentU}
      percentage={
        getPercentage(
          activeUserSessionStats.totalSessionInfo?.previousU,
          activeUserSessionStats.totalSessionInfo?.currentU
        ).percentage
      }
      upDownStatus={
        getPercentage(
          activeUserSessionStats.totalSessionInfo?.previousU,
          activeUserSessionStats.totalSessionInfo?.currentU
        ).status
      }
    />
  );
  const avgSessionHeaderComp = (
    <HeaderCountPercentage
      total={activeUserSessionStats.totalSessionInfo?.currentTotalSc}
      percentage={
        getPercentage(
          activeUserSessionStats.totalSessionInfo?.previousTotalSc,
          activeUserSessionStats.totalSessionInfo?.currentTotalSc
        ).percentage
      }
      upDownStatus={
        getPercentage(
          activeUserSessionStats.totalSessionInfo?.previousTotalSc,
          activeUserSessionStats.totalSessionInfo?.currentTotalSc
        ).status
      }
    />
  );
  const sessionHeaderComp = (
    <HeaderCountPercentage
      total={userTimelySessionData.count.current}
      percentage={
        getPercentage(
          userTimelySessionData.count.previous,
          userTimelySessionData.count.current
        ).percentage
      }
      upDownStatus={
        getPercentage(
          userTimelySessionData.count.previous,
          userTimelySessionData.count.current
        ).status
      }
    />
  );
  return (
    <div className="container-fluid p-sm-2 p-1 ">
      <Helmet>
        <title>Appice | Usage</title>
      </Helmet>
      <div className="d-flex justify-content-between align-items-center py-3 flex-wrap">
        <div>
          <h4>{t("eventDataLabel")}</h4>
        </div>
        <div className="d-flex">
          <div>
            <strong>{t("platformLabel")} </strong>
            <Selector
              onChange={(value) => {
                setPlatform(value);
              }}
              size="middle"
              defaultValue={platform}
              style={{ ...styleBtn, width: "90px" }}
              options={[
                {
                  value: PlatformEnum.ALL,
                  label: t("allLabel"),
                },
                {
                  value: PlatformEnum.ANDROID,
                  label: t("androidLabel"),
                },
                {
                  value: PlatformEnum.IOS,
                  label: t("iosLabel"),
                },
                {
                  value: PlatformEnum.WEB,
                  label: t("webLabel"),
                },
              ]}
            />
          </div>
          <div>
            <strong>{t("periodLabel")} </strong>
            <DateRangeSelector
              onDateChange={(value: DateRangeData) => setDateRange(value)}
              disableRange={{ range: 1, rangeType: "year" }}
              disableType="year"
              numDaysToShow={29} // Example value
            />
          </div>
        </div>
      </div>
      <div className=" d-flex justify-content-between usage-container">
        <TableChartLayout
          title={t("usageBoxLabelUser")}
          className="usage-container-layout-box"
          showDownloadIcon={false}
        >
          <>
            {userHeaderComp}
            {loading ? (
              <Spinner />
            ) : (
              <DonutPieChart
                showLegends
                data={activeUserSessionStats.aggregateData}
                colorSchema={colorSchema}
                totalEvents={20}
                innerRadius={0.001}
                enableArcLinkLabels={false}
              />
            )}
          </>
          <>
            {userHeaderComp}
            {loading ? (
              <Spinner />
            ) : (
              <CustomTable
                dataSource={activeUserSessionStats.tableData}
                columns={userSessionColumn}
                pagination={false}
                className="usage-container-table"
                style={{ marginTop: "35px" }}
              />
            )}
          </>
        </TableChartLayout>
        <TableChartLayout
          title={t("usageBoxLabelAvgSession")}
          className="usage-container-layout-box "
          showDownloadIcon={false}
        >
          <>
            {avgSessionHeaderComp}
            {loading ? (
              <Spinner />
            ) : (
              <DonutPieChart
                showLegends
                data={activeUserSessionStats.averageAggregateData}
                colorSchema={colorSchema}
                totalEvents={20}
                innerRadius={0.001}
                enableArcLinkLabels={false}
              />
            )}
          </>
          <>
            {avgSessionHeaderComp}
            {loading ? (
              <Spinner />
            ) : (
              <CustomTable
                dataSource={activeUserSessionStats.averageTableData}
                columns={avgSessionLengthColumn}
                pagination={false}
                className="usage-container-table mt-3"
                style={{ marginTop: "35px" }}
              />
            )}
          </>
        </TableChartLayout>
      </div>

      <div>
        <TableChartLayout
          title={t("usageBoxLabelSession")}
          className="col-lg-12 col-md-12 col-12 "
          showDownloadIcon={false}
        >
          <>
            {sessionHeaderComp}
            {loading ? (
              <Spinner />
            ) : (
              <Heatmap
                heatMapData={userTimelySessionData.aggregateData}
                xInnerPadding={0.005}
                yInnerPadding={0.002}
                top={20}
                right={100}
                bottom={20}
                left={100}
              />
            )}
          </>
          <>
            {sessionHeaderComp}
            {loading ? (
              <Spinner />
            ) : (
              <CustomTable
                dataSource={userTimelySessionData.tableData}
                columns={userTimelySessionColumn}
                pagination={false}
                className="usage-container-table"
                style={{ marginTop: "35px" }}
              />
            )}
          </>
        </TableChartLayout>
      </div>
    </div>
  );
};
export default Usage;
