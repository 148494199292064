import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
export const paylaodFormat="YYYY-MM-DD"
dayjs.extend(customParseFormat);
const _monthAgo = dayjs().subtract(29, "days").startOf("day");//a
let d = dayjs().endOf("day").diff(_monthAgo, "day");
d = d === 0 ? 1 : d + 1;
let n = dayjs(_monthAgo).subtract(d, "day");



const initialState: any = {
  startDate_C: _monthAgo.unix(),
  endDate_C: dayjs().endOf("day").unix(),
  startDate_P: n.unix(),//
  endDate_P: _monthAgo.unix(),//
  startDate_CNew: _monthAgo.format(paylaodFormat),
  endDate_CNew: dayjs().endOf("day").format(paylaodFormat),
  startDate_PNew: dayjs.unix(n.unix()).format(paylaodFormat),//
  endDate_PNew: dayjs.unix(dayjs().subtract(30, "days").endOf("day").unix()).format(paylaodFormat),
};

const dateRangeSlice = createSlice({
  name: "dateRange",
  initialState,
  reducers: {
    setDateRange(state, action) {
      return action.payload;
    },
  },
});

export const { setDateRange } = dateRangeSlice.actions;
export default dateRangeSlice.reducer;

