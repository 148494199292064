import { FC, useState } from "react";
import CustomBreadCrumb from "../../components/CustomBreadCrumb/CustomBreadCrumb";
import { Link } from "react-router-dom";
import Selector from "../../components/Selector/Selector";
import { getData } from "../../components/Charts/GoogleLineChart/dummyData";
import SingleDateSelector from "../../components/DateTimeSelectors/SingleDateSelector/SingleDateSelector";
import CustomHighChart from "../../components/Charts/CustomHighChart/CustomHighChart";

interface TrendsProps {}

const Trends: FC<TrendsProps> = ({}) => {
  const [chartData, setChartData] = useState(getData().data1);
  return (
    <div className="cdp-trends-maim-container px-md-2 h-100 d-flex flex-column gap-3">
      <div className="p-1">
        <CustomBreadCrumb
          items={[
            {
              title: <Link to="#">Trends</Link>,
            },
            {
              title: <Link to="#">All trends</Link>,
            },
          ]}
        />
      </div>
      <div className="d-flex    justify-content-between">
        <div className="d-flex gap-4 flex-wrap">
          <Selector
            style={{ width: 300 }}
            placeholder=""
            onChange={(value) =>
              setChartData(
                value == "data1"
                  ? getData().data1
                  : value == "data2"
                  ? getData().data2
                  : getData().data3
              )
            }
            defaultValue={"hvs"}
            options={[
              {
                label: "Inactive new users",
                value: "data3",
              },
              {
                label: "New users-high digital transactions",
                value: "data2",
              },
              {
                label: "High value segment",
                value: "data1",
              },
            ]}
          />
          <Selector
            style={{ width: 150 }}
            defaultValue={"uc"}
            options={[
              {
                label: "Users count",
                value: "uc",
              },
              {
                label: "Unique user",
                value: "uu",
              },
            ]}
          />
        </div>
        <div>
          <SingleDateSelector />
        </div>
      </div>
      <div
        className="p-4 overflow-hidden "
        style={{ height: "90%", background: "white" }}
      >
        <CustomHighChart
          options={{
            chart: {
              type: "spline",
              height:600
            },
            legend: {
              enabled: false, // Set to false to hide the legend
            },
            navigation: {
              buttonOptions: {
                enabled: false, // Set to false to hide the hamburger icon
              },
            },
            title: {
              text: undefined,
            },
            xAxis: {
              title: {
                text: "x",
              },
              tickWidth: 0,
            },
            yAxis: {
              title: {
                text: "Count",
                
              },
              min: 0,
            },
            series: [
              {
                name: "Count",
                data: chartData,
                lineWidth: 1,
                marker: {
                 enabled: false
              }}
            ],
          }}
        />
      </div>
    </div>
  );
};

export default Trends;
