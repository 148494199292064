import React, { useEffect } from "react";
import Selector from "components/Selector/Selector";
import TextInput from "components/Inputs/TextInput/TextInput";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import "./FunnelSteps.css"
import Spinner from "components/Spinner/Spinner";
import { Form } from "antd";
import { validateNotEmpty } from "../../../../validator/antdValidator";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { STATUS } from "../../../../redux/constant";
import AttributeWindow from "pages/Audience/AudienceCreate/components/WindowContainers/AttributeWindow/AttributeWindow";
import { AudienceEnum } from "utils/enums";
type propTypes = {
  form?: any;
  boxFormData: any; // Pass specific boxFormData for id '1'
  setBoxFormData: any;
  blockIndex: any; //on which box we are
  selectedJourney:string;
};
function FunnelSteps({
  blockIndex,
  form,
  boxFormData,
  setBoxFormData,
  selectedJourney,
}: propTypes) {
  const selectEventMsg = t("selectEventMsg");
  const { data, status } = useSelector((state: any) => state.eventList);

useEffect(() => {
  return ()=>{
      form.resetFields([`eventName_${blockIndex}`]);
  }
}, [])


  
  const filteredEventsOnJourneyBased = data.filter((e: any) => 
    e.journey === selectedJourney
);
  //Adding new multiple attributes
  const handleAddAttribute = (e_operator: string) => {
    const newAttribute = {
      attributeName: undefined,
      type: "",
      operator: undefined,
      value: "",
      e_operator: e_operator,
    };

    setBoxFormData((prevState: any) => {
      const newState = [...prevState]; // Create a copy of the array
      newState[blockIndex] = {
        ...newState[blockIndex],
        attributes: [...newState[blockIndex].attributes, newAttribute], //added new attribute here for multiple attribute
      };
      return newState;
    });
  }; 
 
  const handleBoxFormDataChange = (
    key: string,
    value: any,
    attributeIndex: number | null = null  // Specify type for attributeIndex
  ) => {
    setBoxFormData((prevState: any) => {
      const newState = [...prevState];
      const updatedBox = { ...newState[blockIndex] };
      if (key === "attributes" && attributeIndex !== null) {
        if (value === undefined) {
          // Remove the attribute at attributeIndex if value is undefined
          updatedBox.attributes.splice(attributeIndex, 1);
        } else {
          updatedBox.attributes[attributeIndex] = {
            ...updatedBox.attributes[attributeIndex],
            ...value,
          };
        }
      } else {
        updatedBox[key] = value;
        if (key === "list" && value.length === 0) {
          updatedBox.attributes = [];
        }
      }
      newState[blockIndex] = updatedBox;
      return newState;
    });
  };
  //rename the attributename and add type also to attributes
  function renameAttrAndTypeAdd(data: any) {
    const updatedData = { ...data, attributeName: data.attr }; //renaming attr to attributeName
    if (data.attr) {
      const selectedAttribute = boxFormData.list.find(
        (a: any) => a.name === data.attr
      );
      updatedData.type = selectedAttribute.type; //adding type property
    }
    delete updatedData.attr; // Remove the old attr property

    return updatedData;
  }
  return (
    <div className="p-3 flex-wrap funnel-condition-box">
      {status == STATUS.LOADING ? (
        <Spinner />
      ) : (
        <div className="d-flex flex-row">
          {data?.length > 0 && (
            <div className="w-100">
              <Form.Item
                rules={[
                  { validator: validateNotEmpty, message: selectEventMsg },
                ]}
                className=" funnel-select-event-width"
                name={`eventName_${blockIndex}`}
                initialValue={boxFormData?.eventName}
              >
                <Selector
                  options={filteredEventsOnJourneyBased.map((e: any) => {
                    return { label: e.displayname, value: e.event };
                  })}
                 
                  onChange={(value) => {
                    const selectedEvent = data.find(
                      (e: any) => e.event === value
                    );
                    handleBoxFormDataChange("eventName", value);
                    handleBoxFormDataChange("list", selectedEvent?.list || []);
                  }}
                  placeholder={selectEventMsg}
                />
              </Form.Item>
              {/* hidden input */}
              <Form.Item
                name={`list_${blockIndex}`}
                initialValue={JSON.stringify(boxFormData.list || [])}
                hidden
              >
                <TextInput />
              </Form.Item>
              {boxFormData?.attributes?.map((attr: any, index: number) => (
                <div key={index} className="d-flex flex-row align-items-center">
                  <AttributeWindow
                    key={index}
                    id={blockIndex}
                    index={index}
                    attributes={boxFormData.list}
                    form={form}
                    value={{
                      ...attr,
                      attr: attr.attributeName,
                      attributeName: undefined,
                    }} //rename attributeName to back attr
                    totalAttributes={boxFormData.attributes.length}
                    setValue={(data) => {
                      if (data) {
                        const updatedData = renameAttrAndTypeAdd(data);
                        handleBoxFormDataChange(
                          "attributes",
                          updatedData,
                          index
                        );
                      }
                      // remove data attribute
                      else {
                        handleBoxFormDataChange("attributes", data, index); //for removing
                      }
                    }}
                  />
                </div>
              ))}
              {boxFormData.eventName &&
                boxFormData?.list?.length > 0 &&
                boxFormData?.attributes?.length > 0 && (
                  <div>
                    <PrimaryButton
                      onClick={() => handleAddAttribute(AudienceEnum.AND)}
                      type="link"
                    >
                      <span className="funnel-condition-box-color-primary">
                        + and
                      </span>
                    </PrimaryButton>
                    <PrimaryButton
                      onClick={() => handleAddAttribute(AudienceEnum.OR)}
                      type="link"
                    >
                      <span className="funnel-condition-box-color-primary">
                        + or
                      </span>
                    </PrimaryButton>
                  </div>
                )}
            </div>
          )}
        </div>
      )}
      {boxFormData.eventName &&
        boxFormData?.list?.length > 0 &&
        boxFormData?.attributes?.length === 0 && (
            <PrimaryButton
            className="attribute-w120px"
                        onClick={() => handleAddAttribute(AudienceEnum.AND)}

          >
            {t("attributeLabel")}
          </PrimaryButton>
        )}
    </div>
  );
}

export default FunnelSteps;
