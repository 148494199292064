import { MenuProps } from 'antd'
import InnerNav from 'components/InnerNav/InnerNav'
import { t } from 'i18next'
import React, { Fragment, useEffect, useState } from 'react'
import UserList from './Components/UserList/UserList'
import Decryption from './Components/Decryption/Decryption'


const UserListNav = () => {
    const [appTabFilter, setAppTabFilter] = useState("userList")
    const [selectedComponent, setSelectedComponent] =
      useState<JSX.Element | null>(null)
  
    const onTabClick: MenuProps["onClick"] = (e) => {
      setAppTabFilter(e.key)
    }
  
    useEffect(() => {
      switch (appTabFilter) {
        case "userList":
          setSelectedComponent(<UserList />)
          break
        case "decryption":
          setSelectedComponent(< Decryption/>)
          break
           // Add cases for other tabs
      default:
        setSelectedComponent(<div></div>) // Default empty component
    }
  }, [appTabFilter])
  return (
    <Fragment>
        <div className="app-setting-container ">
        <div className="app-setting-nav">
          <InnerNav
            theme="light"
            onTabClick={onTabClick}
            selected={appTabFilter}
            menu={[
              {
                
                label: t("userListLabel"),
                 key: "userList",
            
              },              
              {
                
                label: t("decryptionLabel"),
                 key: "decryption",
            
              }
            ]}
            />
          </div>
        </div>
        {selectedComponent}
      </Fragment>
    )
}

export default UserListNav;