import { CloseOutlined } from '@ant-design/icons';
import { Drawer, DrawerProps } from 'antd';
import  { FC } from 'react'
const CustomDrawer: FC<DrawerProps> = ({...props  }) => {
  return (
    <Drawer {...props} closeIcon={<CloseOutlined  style={{ fontSize: '20px',fontWeight:"bold" }} />} placement={props.placement?props.placement:"top"}>
        {props.children}
    </Drawer>
  )
}
export default CustomDrawer;