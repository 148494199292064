import { Divider } from "antd";
import "./ApplePushNotification.css";
import PrimaryButton from "../../../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { Form, Upload } from "antd";
import Selector from "../../../../../../components/Selector/Selector";
import { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import centralApi from "../../../../../../services/centralApi";
import { getToken } from "../../../../../../services/getToken";
import ShowMessage from "../../../../../../components/ShowMessage/ShowMessage";
import Spinner from "../../../../../../components/Spinner/Spinner";
import TextInput from "../../../../../../components/Inputs/TextInput/TextInput";
import { t } from "i18next";
import { ApplePushNotificationProps, FormValues, ShowMessageState } from "./interfaces";
import { PushNotificationMode } from "utils/enums";
import { API_ENDPOINTS } from "utils/constants";
import {AppSettings} from "../../../../../../utils/models/index";
import { APNS_FILE_TYPE_OPTIONS, STRING_BOOLEAN_OPTIONS } from "utils/constants/selectorOptions";
import { deserialize, serialize } from "utils/models/serializer";



//class for the component state
class ApplePushNotificationState {
  loading: boolean;
  fileType: string;
  showMessage: ShowMessageState;

  constructor() {
    this.loading = false;
    this.fileType = "";
    this.showMessage = {
      type: "success",
      message: "",
      show: false,
    };
  }
}

const ApplePushNotification= ({
  currentAppKey,
  mode,
  fetchCurrentAppKeys,
}:ApplePushNotificationProps) => {
  // Initialize the state
  const [state, setState] = useState(new ApplePushNotificationState());
  const [proxy ,setProxy] = useState<string>(STRING_BOOLEAN_OPTIONS.FALSE);
  const [batching , setBatching] = useState<string>(STRING_BOOLEAN_OPTIONS.FALSE);
  const [form] = Form.useForm();

  const isdev = mode === PushNotificationMode.DEVELOPMENT ? STRING_BOOLEAN_OPTIONS.TRUE : mode === PushNotificationMode.PRODUCTION ? STRING_BOOLEAN_OPTIONS.FALSE : "";

  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;

  /**
   * 
   * @param e will be an event and e.target.value will be a number string
   * @param fieldName will be the key of and object where we are setting the values
   * @returns key with number value
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    const inputValue = e.target.value;
    const numberValue = Number(inputValue);
  
    // Validate number or use default values
    const isValid = !isNaN(numberValue) && inputValue.trim() !== "";
  
    // Update form field with the correct number value
    if (isValid) {
      form.setFieldsValue({
        [fieldName]: numberValue,
      });
    }
    else{
      return undefined
    }
  };
  

  const onFormSubmit = async (values: FormValues) => {
    try {
      // Serializing and deserializing APNS Payload and appsettings model class
      const deserializedData = deserialize(values, AppSettings);
      const serializedData = serialize(deserializedData as AppSettings);
  
      if (validateAuthKeyType(values.ios_cert3)) {
        const payload = {
          args: JSON.stringify({
            app_id: appId,
            ...serializedData,
            ios_certPath: "",
            isdev,
          }),
          app_id: appId,
          api_key: api_key,
        };
  
        await updateIOSCertificates(payload);
        await getToken(appId, api_key);
      } else {
        setState({
          ...state,
          showMessage: {
            type: "error",
            message: "Please check Auth Key type matches Auth Key",
            show: true,
          },
        });
        hideMessage();
      }
    } catch (error) {
      setState({
        ...state,
        showMessage: {
          type: "error",
          message: "Serialization/Deserialization error. Please check the data types.",
          show: true,
        },
      });
      hideMessage();
    }
  };

  async function updateIOSCertificates(formData: any) {
    const url: string = API_ENDPOINTS.UPDATE_IOS_CERTIFICATES;

    try {
      setState({ ...state, loading: true });
      await centralApi("POST", url, formData, null);
      setState({
        ...state,
        showMessage: {
          type: "success",
          message: "Successfully Saved",
          show: true,
        },
        loading: false,
      });
      fetchCurrentAppKeys();
    } catch (error) {
      setState({
        ...state,
        loading: false,
        showMessage: {
          type: "error",
          message: "Something went wrong",
          show: true,
        },
      });
    }
    hideMessage();
  }

  const hideMessage = () => {
    setTimeout(() => {
      setState({ ...state, showMessage: { type: "success", message: "", show: false } });
    }, 2000);
  };

  useEffect(() => {
    getToken(appId, api_key);
  }, [appId, api_key]);

  useEffect(() => {
    // Update form values based on mode and currentAppKey data
    const devConfig = currentAppKey[0]?.push_notification?.apns?.dev;
    const prodConfig = currentAppKey[0]?.push_notification?.apns?.prod;
    const proxyUrlData = currentAppKey[0]?.proxyUrl;
    const proxyPortData = currentAppKey[0]?.proxyPORT;

    const updatedFormValues: FormValues = {
      ios_cert3: mode === PushNotificationMode.DEVELOPMENT ? devConfig?.dev_ios_cert3 : prodConfig?.ios_cert3 || "",
      ios_bundleid: mode === PushNotificationMode.DEVELOPMENT ? devConfig?.dev_ios_bundleid : prodConfig?.ios_bundleid || "",
      ios_key_type: mode === PushNotificationMode.DEVELOPMENT ? devConfig?.dev_ios_key_type : prodConfig?.ios_key_type || "",
      ios_keyid: mode ===PushNotificationMode.DEVELOPMENT ? devConfig?.dev_ios_keyid : prodConfig?.ios_keyid || "",
      ios_teamid: mode === PushNotificationMode.DEVELOPMENT ? devConfig?.dev_ios_teamid : prodConfig?.ios_teamid || "",
      ios_topic: mode ===PushNotificationMode.DEVELOPMENT ? devConfig?.dev_ios_topic : prodConfig?.ios_topic || "",
      ios_passphrase: mode === PushNotificationMode.DEVELOPMENT ? devConfig?.dev_ios_passphrase : prodConfig?.ios_passphrase || "",
      proxyUrl: proxyUrlData,
      proxyPORT: proxyPortData,
      //sets apns_batchSize  from currentkey[0] as per the mode and checks if the key is not undefined aor null otherwise we set up the key as empty string
      apns_batchSize : mode === PushNotificationMode.DEVELOPMENT 
        ? (currentAppKey[0]?.dev_apns_batchSize !== null && currentAppKey[0]?.dev_apns_batchSize !== undefined 
           ? currentAppKey[0]?.dev_apns_batchSize 
            : "") 
        : (currentAppKey[0]?.apns_batchSize !== null && currentAppKey[0]?.apns_batchSize !== undefined 
           ? currentAppKey[0]?.apns_batchSize 
            : ""),
      //sets apns_chunkSize  from currentkey[0] as per the mode and checks if the key is not undefined aor null otherwise we set up the key as empty string   
      apns_chunkSize : mode === PushNotificationMode.DEVELOPMENT 
        ? (currentAppKey[0]?.dev_apns_chunkSize !== null && currentAppKey[0]?.dev_apns_chunkSize !== undefined 
            ? currentAppKey[0]?.dev_apns_chunkSize 
             : "") 
        : (currentAppKey[0]?.apns_chunkSize !== null && currentAppKey[0]?.apns_chunkSize !== undefined 
            ? currentAppKey[0]?.apns_chunkSize 
             : ""),
      //sets apns_delay from currentkey[0] as per the mode and checks if the key is not undefined aor null otherwise we set up the key as empty string   
      apns_delay : mode === PushNotificationMode.DEVELOPMENT 
        ? (currentAppKey[0]?.dev_apns_delay !== null && currentAppKey[0]?.dev_apns_delay !== undefined 
           ? currentAppKey[0]?.dev_apns_delay 
            : "") 
        : (currentAppKey[0]?.apns_delay !== null && currentAppKey[0]?.apns_delay !== undefined 
           ? currentAppKey[0]?.apns_delay 
            : "")
            
    };

    //checks if data is in proxyHost or proxyPort we set the enableProxy selector to true
    if(proxyUrlData || proxyPortData){
      setProxy("true")
    }

    //checks if data is in  apns_batchSize  we set the enableBatching selector to true
    if(updatedFormValues.apns_batchSize){
      setBatching("true")
    }
    
    form.setFieldsValue(updatedFormValues);
    setState({ ...state, fileType: updatedFormValues.ios_key_type });
  }, [mode, currentAppKey, form]);

  const handleFileChange = async (formData: any) => {
    try {
      // Create a new FormData object
      const form_data = new FormData();
  
      // Check if the 'csv' property exists in the formData object
      if (formData.csv) {
        // Append necessary data to the FormData object
        form_data.append(t("appCertLabel"), formData.csv);
        form_data.append(t("appCertNameLabel"), formData.csv.name);
        form_data.append(t("appCertIdLabel"), appId);
        form_data.append(t("isDevLabel"), isdev);
        form_data.append(t("PlatformLabel"), "ios")
      } else {
        // If 'csv' property is missing, show an error and return
        throw new Error("CSV file is missing.");
      }
      // Make the first API request
      await centralApi("POST", API_ENDPOINTS.UPLOAD_CERTIFICATE, form_data, null);
            // Make the second API request
      await centralApi("POST", API_ENDPOINTS.CERTIFICATE, form_data, null);

      setState({
        ...state,
        showMessage: {
          type: "success",
          message: "Successfully Uploaded",
          show: true,
        },
      });

      form.setFieldsValue({ ios_cert3: formData.csv.name });
    } catch (error) {
      setState({
        ...state,
        showMessage: {
          type: "error",
          message: "Something went wrong",
          show: true,
        },
      });
    }
  };

  const handleFileTypeChange = (value: string) => {
    setState({ ...state, fileType: value });
  };

  const validateAuthKeyType = (value: string) => {
    const authFileType = value.split(".")[1];
    return authFileType === state.fileType;
  };

  
  return (
    <Fragment>
      <div className="apple-push-notification-container">
        <div className="mt-4 mb-4  ">
          <h6 className="apple-push-notification-container-heading">
            {t("applePushServiceLabel")} - {mode}
          </h6>
          <Divider className="apple-push-notification-custom-divider" />
        </div>

        <div className=" col-sm-8 mt-4 mb-4 align-items-center apple-push-notification-form ">
          {state.showMessage.show && (
            <ShowMessage
              type={state.showMessage.type}
              content={state.showMessage.message}
            />
          )}
          {state.loading ? (
            <Spinner />
          ) : (
            <Form
              form={form}
              layout="horizontal"
              // initialValues={formValues}

              onFinish={onFormSubmit}
            >
              <Form.Item
                label={t("authKeyLabel")}
                name={"ios_cert3"}
                className="auth-key-item" // Add this className
                rules={[
                  {
                    required: true,
                    message: "Please select Auth Key",
                  },
                ]}
              >
                <TextInput
                  disabled={true}
                  addonAfter={
                    <div>
                      <Upload
                        showUploadList={false}
                        accept=".p12, .p8"
                        multiple={false}
                        onChange={(value) =>
                          handleFileChange({ csv: value.file })
                        }
                        beforeUpload={() => false}
                      >
                        <PrimaryButton type="primary">
                          {/* <FileImageFilled />  */}
                          Upload
                         
                        </PrimaryButton>
                      </Upload>{" "}
                    </div>
                  }
                />
              </Form.Item>
              <Form.Item
                label={t("authKeyTypeLabel")}
                name={"ios_key_type"}
                rules={[
                  { required: true, message: "Please Select Auth Key Type" },
                ]}
              >
                <Selector
                  onChange={handleFileTypeChange}
                  size="middle"
                  style={{ width: "100%" }}
                  defaultValue={t("selectLabel")}
                  options={[
                    {
                      value: "",
                      label: t("selectLabel"),
                    },
                    {
                      value: APNS_FILE_TYPE_OPTIONS.P8,
                      label: t("p8Label"),
                    },
                    {
                      value: APNS_FILE_TYPE_OPTIONS.P12,
                      label: t("p12Label"),
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label={t("appBundleIdLabel")}
                name={"ios_bundleid"}
                rules={[
                  { required: true, message: "Please Select App Bundle ID" },
                ]}
                initialValue={""}
              >
                <TextInput />
              </Form.Item>
              {state.fileType === APNS_FILE_TYPE_OPTIONS.P8 && (
              <>
              <Form.Item
                label={t("keyIdLabel")}
                name={"ios_keyid"}
                rules={[{ required: true, message: "Please Select Key ID" }]}
              >
                <TextInput />
              </Form.Item>
              <Form.Item
                label={t("teamIdLabel")}
                name={"ios_teamid"}
                rules={[{ required: true, message: "Please Select Team ID" }]}
              >
                <TextInput />
              </Form.Item>
              <Form.Item
                label={t("topicLabel")}
                name={"ios_topic"}
                rules={[{ required: true, message: "Please Select Topic" }]}
              >
                <TextInput />
              </Form.Item>
              </>
              )}
              {state.fileType === APNS_FILE_TYPE_OPTIONS.P12 && (
                <Form.Item
                label={t("passPhraseLabel")}
                name={"ios_passphrase"}
                rules={[{ required: true, message: "Please Select passphrase" }]}
              >
                <TextInput placeholder="password"/>
              </Form.Item>
              )}

                 <Form.Item
                     label={t("enableProxyLabel")}
                  >
                    <Selector
                     value={proxy}
                     onChange={(value: string) =>
                       setProxy(value)
                    }

                      options={[
                        {
                          label: t("TrueLabel"),
                          value: STRING_BOOLEAN_OPTIONS.TRUE,
                        },
                        {
                          label: t("FalseLabel"),
                          value: STRING_BOOLEAN_OPTIONS.FALSE,
                        },
                      ]}
                     
                    />
                  </Form.Item>
        {/* Conditional redering  for proxy host and proxy port*/}
         {proxy === STRING_BOOLEAN_OPTIONS.TRUE&& (
           <>
              <Form.Item
                label={t("proxyHostLabel")}
                name={"proxyUrl"}
              >
                <TextInput placeholder="Please Enter Proxy Host"/>
              </Form.Item>
              <Form.Item
                label={t("proxyPortLabel")}
                name={"proxyPORT"}  
              >
               <TextInput placeholder="Please Enter Proxy Port"/>
              </Form.Item>
           </>
              )}

                <Form.Item
                      label={t("enableBatchingLabel")}
                    >
                      <Selector
                      value={batching}
                      onChange={(value: string) =>
                        setBatching(value)
                      }

                        options={[
                          {
                            label: t("TrueLabel"),
                            value: STRING_BOOLEAN_OPTIONS.TRUE,
                          },
                          {
                            label: t("FalseLabel"),
                            value: STRING_BOOLEAN_OPTIONS.FALSE,
                          },
                        ]}
                      
                      />
                </Form.Item>
          {/* Conditional rendering for batch size , chunk size and delay */}
          {batching === STRING_BOOLEAN_OPTIONS.TRUE && (
            <>
              <Form.Item
                    label={t("batchSize")}
                    name={"apns_batchSize"}
                  >
                    <TextInput
                      onChange={(e : any) => handleChange(e, 'apns_batchSize')}
                      type="number" 
                    />
               </Form.Item>
               <Form.Item
                    label={t("chunkSize")}
                    name={"apns_chunkSize"}
                  >
                    <TextInput
                      onChange={(e : any) => handleChange(e, 'apns_chunkSize')}
                      type="number" 
                    />
               </Form.Item>
               <Form.Item
                    label={t("delay")}
                    name={"apns_delay"}
                  >
                    <TextInput
                      onChange={(e : any) => handleChange(e, 'apns_delay')}
                      type="number" 
                    />
               </Form.Item>
            </>
         )}

              <div className="">
                <PrimaryButton
                  size="middle"
                  type="primary"
                  htmlType="submit"
                  style={{ border: "none", width: "80px" }}
                >
                  Save Files
                </PrimaryButton>
              </div>
            </Form>
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default ApplePushNotification;
