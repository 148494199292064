import { Button, Divider, Space } from "antd";
import "./PassthroughSetting.css";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { Form } from "antd";

import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import centralApi from "../../../../../services/centralApi";
import { getToken } from "../../../../../services/getToken";
import ShowMessage from "../../../../../components/ShowMessage/ShowMessage";
import Spinner from "../../../../../components/Spinner/Spinner";
import { t } from "i18next";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import Selector from "components/Selector/Selector";
import TextInput from "components/Inputs/TextInput/TextInput";
import useMessage from "hooks/useMessage";

interface PassthroughSettingProps {
  currentAppKey: any;
  updateCurrentAppKey: () => void;
}

interface RootState {
  loginUser: {
    data: {
      api_key: string;
    };
  };
  activeApp: {
    appId: string;
  };
}

const PassthroughSetting: React.FC<PassthroughSettingProps> = ({
  currentAppKey,
  updateCurrentAppKey,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { showError, showSuccess } = useMessage();

  useEffect(() => {
    // Set initial form values when currentAppKey changes
    const updatedFormValues = {
      pasUrl: currentAppKey[0]?.passThrough?.url,
      verb: currentAppKey[0]?.passThrough?.verb,
      keyValuePairs: currentAppKey[0]?.passThrough?.keyValuePairs || [
        { key: "", value: "" },
      ],
    };
    form.setFieldsValue(updatedFormValues);
  }, [currentAppKey, form]);

  const loginUser = useSelector(
    (state: RootState) => state.loginUser.data.api_key
  );
  const activeApp = useSelector((state: RootState) => state.activeApp.appId);
  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  });
  const appId = activeApp;
  const api_key = loginUser;
  const onFormSubmit = (values: any) => {
    const payload = {
      args: JSON.stringify({
        app_id: appId,
        ...values,
        filter: "thirdParty",
      }),
      api_key: api_key,
    };
    saveWebhookThirdPartyApiSettings(payload);
    updateCurrentAppKey();
    getToken(appId, api_key);
  };
  //post request to send data
  async function saveWebhookThirdPartyApiSettings(formData: any) {
    const url: string = `/i/apps/saveWebhookThirdPartyApiSettings`;

    try {
      setLoading(true);
      await centralApi("POST", url, formData, null);
      showSuccess(t("sucessfullySavedLabel"));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError(t("somethingWrongLabel"));
    }
    hideMessage();
  }
  const hideMessage = () => {
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false });
    }, 2000);
  };

  useEffect(() => {
    getToken(appId, api_key);
  }, []);

  return (
    <div className="passthrough-setting-container">
      <div className="mt-4 mb-4  ">
        <h6 className="passthrough-setting-container-heading">
          {t("passthroughSettingLabel")}
        </h6>

        <Divider className="passthrough-setting-custom-divider" />
        <div>
          <div className="  mt-2 align-items-center passthrough-setting-form ">
            <div className="mb-4">
              <div className=" col-sm-7 mt-4 align-items-center passthrough-setting-form ">
                {showMessage.show && (
                  <ShowMessage
                    type={showMessage.type}
                    content={showMessage.message}
                  />
                )}
                {loading ? (
                  <Spinner />
                ) : (
                  <Form
                    form={form}
                    layout="horizontal"
                    initialValues={{}}
                    onFinish={onFormSubmit}
                  >
                    <Form.Item
                      label={t("urlLabel")}
                      name="pasUrl"
                      rules={[
                        {
                          required: true,
                          message: t("plzInputUrl") as string,
                        },
                        {
                          type: "url",
                          message: "Please enter a valid URL",
                        },
                      ]}
                    >
                      <TextInput maxLength={100} />
                    </Form.Item>

                    <Form.Item
                      label={t("verbLabel")}
                      name="verb"
                      rules={[
                        {
                          required: true,
                          message: t("plzInputLabel") as string,
                        },
                      ]}
                    >
                      <Selector
                        // onChange={handleDisableButton}
                        size="middle"
                        style={{ width: "100%" }}
                        defaultValue={"NONE"}
                        options={[
                          {
                            value: "GET",
                            label: t("getLabel") as string,
                          },
                          {
                            value: "POST",
                            label: t("postLabel") as string,
                          },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item label={t("valuesLabel") as string}>
                      <Form.List name="keyValuePairs">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <Space
                                key={key}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                                align="baseline"
                              >
                                <Form.Item
                                  {...restField}
                                  name={[name, "key"]}
                                  rules={[
                                    {
                                      required:
                                        form.getFieldValue("httpMethod") ===
                                          "GET" ||
                                        form.getFieldValue("httpMethod") ===
                                          "POST",
                                      message: t(
                                        "missingKeyValueLabel"
                                      ) as string,
                                    },
                                  ]}
                                >
                                  <TextInput
                                    maxLength={100}
                                    placeholder="Key"
                                  />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "value"]}
                                  rules={[
                                    {
                                      required:
                                        form.getFieldValue("httpMethod") ===
                                          "GET" ||
                                        form.getFieldValue("httpMethod") ===
                                          "POST",
                                      message: t("plzInputLabel") as string,
                                    },
                                  ]}
                                >
                                  <TextInput
                                    maxLength={100}
                                    placeholder="Value"
                                  />
                                </Form.Item>
                                {fields.length > 1 && (
                                  <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  />
                                )}
                              </Space>
                            ))}
                            <Form.Item>
                              <Button
                                type="dashed"
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}
                              >
                                {t("addFieldLabel")}
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </Form.Item>

                    <div className="d-flex gap-2">
                      <PrimaryButton
                        size="middle"
                        type="primary"
                        htmlType="submit"
                        style={{ border: "none", width: "70px" }}
                      >
                        {t("saveLabel")}
                      </PrimaryButton>
                    </div>
                  </Form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PassthroughSetting;
