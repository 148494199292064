import {
  DownloadOutlined,
  PieChartOutlined,
  TableOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import "./TableChartLayout.css";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
type propTypes = {
  title: string;
  headerRight?: any;
  children?: any;
  width?: string;
  height?: string;
  className?: string;
  showDownloadIcon?: boolean;
  onDownloadClick?: () => void; // Add a prop for the download click handler
};
function TableChartLayout({
  title,
  headerRight,
  children,
  width,
  height,
  className,
  showDownloadIcon = true,
  onDownloadClick, // Include the prop in the component
}: propTypes) {
  const [isChartView, setIsChartView] = useState(true);
  const iconStyle = {
    fontSize: 18,
    color: "var(--color-main-primary-dark)",
  };

  return (
    <div
      className={`table-chart-toggle-layout-main-container ` + " " + className}
      style={{ width: width }}
    >
      <div className="table-chart-toggle-layout-heading d-flex justify-content-between align-items-center  p-2">
        <strong>{title}</strong>
        <div className="d-flex align-items-center gap-1">
          {isChartView
            ? Array.isArray(children) && (
                <TableOutlined
                  onClick={() => setIsChartView(false)}
                  className="mx-2"
                  style={iconStyle}
                />
              )
            : Array.isArray(children) && (
                <PieChartOutlined
                  className="mx-2"
                  onClick={() => setIsChartView(true)}
                  style={iconStyle}
                />
              )}
          {headerRight}
          
          {showDownloadIcon && ( 
 

            <PrimaryButton  title={`Download ${title} Csv`} type="link" onClick={onDownloadClick}>
              <DownloadOutlined style={iconStyle} />
            </PrimaryButton>
          )}
        </div>
      </div>
      <div
        className="table-chart-toggle-layout-body position-relative p-2"
        style={{ height: height ? height : "300px" }}
      >
        {Array.isArray(children)
          ? isChartView
            ? children[0]
            : children[1]
          : children}
      </div>
    </div>
  );
}

export default TableChartLayout;
