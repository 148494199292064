import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Panel from "./routes/Panel";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/config";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setContentDimensions } from "./redux/features/layout/layoutInfoSlice";
import { useDebouncedCallback } from "use-debounce";
import { NotificationProvider } from "./hooks/useNotification";

function App() {
  const dispatch=useDispatch()
  const debounced = useDebouncedCallback(
    handleScreenSizeChange,
    500
  );
  function handleScreenSizeChange() {
    dispatch(setContentDimensions({width:window.screen.width,height:window.screen.height}))
  }
  useEffect(() => {
    // Function to handle screen size changes
    
    handleScreenSizeChange();
    window.addEventListener("resize", debounced);
    return () => {
      window.removeEventListener("resize", debounced);
    };
  }, []);
  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <NotificationProvider>
          <Routes>
            <Route path="/*" element={<Panel />} />
          </Routes>
        </NotificationProvider>
      </I18nextProvider>
    </BrowserRouter>
  );
}

export default App;
