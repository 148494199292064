import { Breadcrumb } from "antd"
import React, { useState } from "react"
import "./CustomBreadCrumb.css"
const CustomBreadCrumb: React.FC<any> = ({ ...props }) => {
  return (
    <div className="breadcrumb-container">
      <Breadcrumb
        {...props}
        style={{
          fontSize: "18px",
          fontWeight: 500,
          lineHeight: "21px",
          letterSpacing: "1.61px",
          height: "21px",
          color: "var(--color-text-disabled-strong)",
        }}
      />
    </div>
  )
}

export default CustomBreadCrumb
