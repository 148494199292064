import React, { useEffect, useState } from "react";
import SwitchBtn from "../../../components/Buttons/SwitchBtn/SwitchBtn";
import editIcon from "../../../images/_edit.svg";
import removeIcon from "../../../images/remove.svg";
import PrimaryButton from "../../../components/Buttons/PrimaryButton/PrimaryButton";
import { PlusOutlined } from "@ant-design/icons";
import Selector from "../../../components/Selector/Selector";
import CustomTable from "../../../components/CustomTable/CustomTable";
import Search from "../../../components/Inputs/Search/Search";
import {
  deleteAudienceSegment,
  getAudienceSegments,
} from "../../../helper/audiences";
import { useSelector } from "react-redux";
import minusIcon from "../../../images/minus.svg";
import ConfirmPopUp from "../../../components/PopUp/ConfirmPopUp/ConfirmPopUp";
import { Link, useNavigate } from "react-router-dom";
import CustomBreadCrumb from "../../../components/CustomBreadCrumb/CustomBreadCrumb";
import { Helmet } from "react-helmet";
import { t } from "i18next";
import useMessage from "hooks/useMessage";
import { PAGE_OPTIONS } from "utils/constants/selectorOptions";
import { ROUTES } from "utils/constants";

const AudienceTable: React.FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const loginUser = useSelector((state: any) => state.loginUser);
  const { showError, showSuccess } = useMessage();
  const {appId} = useSelector((state: any) => state.activeApp);
  const [audienceData, setAudienceData] = useState<any>([]);
  const [audienceDataCopy, setAudienceDataCopy] = useState<any>([]);
  const [pageData, setPageData] = useState({
    pageSize: PAGE_OPTIONS.DEFAULT_VALUE,
    simple: PAGE_OPTIONS.PAGINATION.simple
  })
  const featuresSetting=useSelector((state:any)=>state.featuresSetting.data)

  const audienceSegmentDeleteConfirm = async (id: string) => {
    try {
      const payload={
        id,
        appId,
        apiKey:loginUser.data.api_key
      }
      await deleteAudienceSegment(payload);
      showSuccess(t("segmentDeletedSuccessfullyMsg"))
      getAudienceData();
    } catch (error) {
      showError(t("somethingWrongLabel"))
    }
  };
  const columns: any = [
    {
      title: t("nameLabel"),
      dataIndex: "Name",
    },
    {
      title: t("descriptionLabel"),
      dataIndex: "Description",
    },
   ...(featuresSetting[0]?.features?.audienceSync === false
    ? [{
        title: t("syncsLabel"),
        dataIndex: "Syncs",
        className: "text-center",
        render: () => (
          <div className="d-flex justify-content-center h-100">
            <SwitchBtn />
          </div>
        ),
      }]
    : []
  ),  {
      title: t("rangeLabel"),
      dataIndex: "Range",
    },
    {
      title: t("createdOnLabel"),
      dataIndex: "Created_On",
    },
    {
      title: t("actionLabel"),
      dataIndex: "Action",
      className: "text-center",

      render: (id: string) => {
        const imgStyle = {
          width: 16,
        };
        return (
          <div className="d-flex justify-content-center  h-100">
            <ConfirmPopUp
              title={t("deleteSegmentLabel")}
              icon={removeIcon}
              onConfirm={() => {
                audienceSegmentDeleteConfirm(id);
              }}
              id={id}
            >
              <PrimaryButton title={t("deleteLabel")} className="mx-1">
                <img src={minusIcon} title={`${t("deleteLabel")}`} alt={"minus_icon"} style={imgStyle}></img>
              </PrimaryButton>
            </ConfirmPopUp>
            <PrimaryButton
            title={t("editLabel")}
              className="mx-1"
              onClick={() => {
                navigate(ROUTES.DASHBOARD_AUDIENCE_CREATE,{state:{id}});
              }}
              >
              <img src={editIcon} title={`${t("editLabel")}`} alt={"edit_icon"} style={imgStyle}></img>
            </PrimaryButton>{" "}
          </div>
        );
      },
    },
  ];
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  //get the audience data from api and set the state
  async function getAudienceData() {
    try {
      const apiKey=loginUser.data.api_key
      if(apiKey && appId){

        setLoading(true);
        const data: any = await getAudienceSegments({appId,apiKey});
        setLoading(false);
        setAudienceData(data);
        setAudienceDataCopy(data);
      }
    } catch (error) {
      setLoading(false);
    }
  }
  const handleSearch = (e: any) => {
    const regex = new RegExp(e.target.value, "i");
    setAudienceDataCopy(
      audienceData?.filter((aud: any) => {
        return aud.Name ? regex.test(aud.Name) : false;
      })
    );
  };
  useEffect(() => {
    getAudienceData();
  }, [loginUser]);
  return (
    <div className="container-fluid px-1">
      <Helmet>
        <title>Appice | Audience</title>
      </Helmet>
      <div className="pb-4 pt-1">
        <CustomBreadCrumb
          items={[
            {
              title: <Link to={"/dashboard/audience"}>{t("audienceLabel")}</Link>,
            }
          ]}
        />
      </div>
      <div className="px-3" style={{ background: "var(--color-other-white)" }}>
        <div className="d-flex justify-content-between align-items-center py-4">
          <h6>Audiences</h6>
          <PrimaryButton type="primary" onClick={() => {navigate("create")}}>
            <PlusOutlined />
            <span className="mx-2">{t("audienceLabel")}</span>
          </PrimaryButton>
        </div>
        <div>
          <Search
              data-testid="search-audience"
              placeholder={`${t("searchLabel")}`}

            style={{
              width: "100%",
              maxWidth: "300px",
              marginRight: "10px",
              marginBottom: "10px",
              height: "100%",
            }}
            onChange={handleSearch}
          />
          <Selector
           onChange={(value) => {
            setPageData({ ...pageData, pageSize: value })
            }}
            options={PAGE_OPTIONS.DATA} 
            defaultValue={pageData.pageSize}
          />
        </div>
        <CustomTable
          columns={columns}
          scroll={{ x: 1000 }}
          rowKey={(aud: any) => aud.Key}
          dataSource={audienceDataCopy}
          rowSelection={rowSelection}
          size="small"
          totalEntries={audienceData?.length}
          pagination={pageData}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default AudienceTable;
