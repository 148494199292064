type SelectedImageType = "Header" | "Footer" | "Mini"|"Fullscreen"
export function downloadFileFromUrl(fileUrl: string, fileName: string) {
  const link = document.createElement("a");
  link.href = fileUrl;
  link.download = fileName;
  
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const concatDayWiseDauDuplicateDate = (data: [string, number][]) => {
  const modifiedData: [string, number][] = [];
  const keyValues: any = {};
  data.forEach((dateData) => {
    const key = dateData[0];
    const value = dateData[1];
    if (keyValues[key]) {
      keyValues[key] += value;
    } else {
      keyValues[key] = value;
    }
  });
  Object.keys(keyValues).forEach((key) => {
    modifiedData.push([key, keyValues[key]]);
  });
  return modifiedData;
};
export function generateCSV(data: any, filename: string, columns?: string[]) {
  if (!data || data.length === 0) {
    console.error("No data to export.");
    return;
  }

  const csvRows = [];

  // If columns are provided, use them; otherwise, extract columns from the first item in the data
  const dataColumns = columns || Object.keys(data[0]);
  csvRows.push(dataColumns.join(","));

  data.forEach((item: any) => {
    const rowData = dataColumns.map((column) => item[column]);
    csvRows.push(rowData.join(","));
  });

  const csvData = csvRows.join("\n");
  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();

  URL.revokeObjectURL(url);
}
// Function to download CSV using API
export function downloadCsv(csvData: string, fileName: string) {
  const blob = new Blob([csvData], { type: "text/csv" });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
}
type DateRange = {
  startDate: string;
  endDate: string;
};

type DateArray = string[];

export function getDatesRange({ startDate, endDate }: DateRange): DateArray {
  const dateArray: DateArray = [];
  const currentDate = new Date(startDate);

  while (currentDate <= new Date(endDate)) {
    dateArray.push(currentDate.toISOString().split("T")[0]);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateArray;
}
export function timestampToFormattedDate(timestamp: any) {
  // Check if the timestamp is in milliseconds and convert to seconds if needed
  if (timestamp > 1000000000000) {
    timestamp = Math.floor(timestamp / 1000);
  }

  const milliseconds = timestamp * 1000;
  const date = new Date(milliseconds);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const ampm = hours >= 12 ? "AM" : "PM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

  return `${month} ${day}, ${year} ${formattedHours}:${
    minutes < 10 ? "0" : ""
  }${minutes}:${seconds < 10 ? "0" : ""}${seconds} ${ampm}`;
}
export function formatISODateString(formatISODateString: string | undefined) {
  if (!formatISODateString) {
    return "Invalid date"; // Or handle the undefined case accordingly
  }

  const date = new Date(formatISODateString)
  // Array of month names
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  // Get month, day, year, hours, minutes, and seconds from the date
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // Determine AM or PM
  const ampm = hours >= 12 ? "PM" : "AM";

  // Format hours to 12-hour format
  hours = hours % 12 || 12; // Convert 0 to 12 for midnight

  // Add leading zero for minutes and seconds if less than 10
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

  // Construct the formatted date string
  return `${month} ${day}, ${year} ${hours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
}

export function capitalizeFirstLetter(str: string | undefined) {
  return str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : "";
}

export function getInAppBazelInsetValues(selectedImage: SelectedImageType) {
  switch (selectedImage) {
    case "Header":
      return { top: 40, bottom: 30, left: 16, right: 16 };
    case "Footer":
      return { top: 400, bottom: 30, left: 16, right: 16 };
    case "Mini":
      return { top: 96, bottom: 204, left: 24, right: 25 };
    default:
      return { top: 28, bottom: 31, left: 24, right: 25 };
  }
}
export function replacePlaceholdersWithAttributes(
  messageText: any,
  attributeMappings: any[]
): string {
  if (!messageText || !attributeMappings) {
    return messageText; // If messageText or attributeMappings is not available, return the original messageText
  }

  // Replace placeholders with attribute names dynamically
  const replacedMessageText = messageText.replace(
    /{{(\d+)}}/g,
    (match: string, index: string) => {
      const attributeObject = attributeMappings.find((obj: any) => obj[match]); // Find the object with the matching placeholder
      if (attributeObject) {
        return attributeObject[match].attribute_name;
      } else {
        return match; // If no matching attribute object found, return the original placeholder
      }
    }
  );
  return replacedMessageText;
}
//remove the duplicate url example: https://www.google.comhttps://www.google.com -> https://www.google.com
export const removeDuplicateUrl = (image: string | undefined) => {
  if (image && image?.includes("https://")) {
    const index=image?.split("https://")?.length - 1
    return "https://" + image?.split("https://")[index];
  } else {
    return undefined;
  }
};
export const removeTrailingNumber = (name:string) => {
  return name.replace(/_\d+$/, '');
};
export const getTrailingNumber = (str:string) => {
  const match = str.match(/_(\d+)$/);
  return match ? parseInt(match[1], 10) : null;
};

export const generateRandomAlphanumericString = (length:number) => {
  const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const digitChars = '0123456789';
  const specialChars = '@#.';
  const allChars = uppercaseChars + digitChars + specialChars;

  // Custom random number generator (LCG-like algorithm)
  let seed = Date.now();
  function random() {
    seed = (seed * 1664525 + 1013904223) & 0x7fffffff;
    return seed;
  }

  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = random() % allChars.length;
    result += allChars.charAt(randomIndex);
  }
  return result;
};
// clipboardUtils.js
export const copyTextToClipboard = async (textToCopy:string) => {
  navigator.clipboard.writeText(textToCopy);
   
};
