import React, { FC } from "react";

interface WebPushContainer {
  headerImg?: string;
  expandedImage?: string;
  title: string;
  message: string;
  url: string;
  isLoading?: {
    webPushExpanded?: boolean;
    webPushHeader?: boolean;
  };
  variableInfo?: { [key: string]: personalizationVariableInfoType };
  uploadImage?: (value: any, type: "webPushExpanded" | "webPushHeader") => void;
  showExpanded: boolean;
  deviceType: "Mac" | "Android" | "windows" | "IOS";
  isPreview: boolean;
  buttons?: pushActionButtonType[];
}
import "./WebPushContainer.css";
import docIcon from "../../../../../../images/uploadImageCustom.svg";
import safariIcon from "../../../../../../images/safari.svg";
import { Upload } from "antd";
import Spinner from "../../../../../../components/Spinner/Spinner";
import { Link } from "react-router-dom";
import { ChromeFilled, CloseOutlined, SettingFilled } from "@ant-design/icons";
import { textOverflowThreeDot } from "../../../../../../helperFunctions/string";
import TextInput from "../../../../../../components/Inputs/TextInput/TextInput";
import {
  personalizationVariableInfoType,
  pushActionButtonType,
} from "@customTypes/template";
import { removeDuplicateUrl } from "helperFunctions/common";
import { replacePersonlizedVariable } from "../../Personalization/Personalization";

const WebPushContainer: FC<WebPushContainer> = ({ ...props }) => {
  const modifiedProps = {
    ...props,
    expandedImage: removeDuplicateUrl(props.expandedImage),
    headerImg: removeDuplicateUrl(props.headerImg),
    title: replacePersonlizedVariable(props.title, props?.variableInfo),
    message: replacePersonlizedVariable(props.message, props?.variableInfo),
    url: replacePersonlizedVariable(props.url, props?.variableInfo),
  };
  return props.deviceType == "windows" ? (
    <Windows {...modifiedProps} />
  ) : props.deviceType == "Mac" ? (
    <Macbook {...modifiedProps} />
  ) : (
    <Android {...modifiedProps} />
  );
};

export default WebPushContainer;

const Windows = ({
  isPreview,
  isLoading,
  headerImg,
  title,
  url,
  message,
  showExpanded,
  expandedImage,
  uploadImage,
  buttons,
}: WebPushContainer) => {
  const customStyle = {
    fontSize: isPreview ? "var(--font-size-xsm)" : "var(--font-size-sm)",
    color: "var(--color-text-disabled-strong)",
    border: "1px solid var(--color-text-disabled)",
  };
  return (
    <div
      className={`webpush-notification-main-container ${
        isPreview
          ? "notification-main-container-preview-window"
          : "notification-main-container-edit-window"
      }`}
    >
      <div className={`d-flex justify-content-between h-100  gap-2 `}>
        <Upload
          disabled={!uploadImage}
          showUploadList={false}
          multiple={false}
          className="p-0 m-0 "
          beforeUpload={() => false}
          onChange={(value: any) => {
            if (uploadImage) {
              uploadImage(value, "webPushHeader");
            }
          }}
        >
          <div>
            {isLoading?.webPushHeader ? (
              <Spinner />
            ) : (
              <img
                loading="lazy"
                className="m-0 p-0"
                src={headerImg || docIcon}
                style={{
                  height: isPreview ? 55 : 70,
                  width: isPreview ? 55 : 70,
                }}
              />
            )}
          </div>
        </Upload>
        <div className="d-flex flex-column p-1 py-2 justify-content-between  w-100">
          <div className="d-flex justify-content-between  align-items-center">
            <TextInput
              disabled={true}
              className="p-0"
              value={textOverflowThreeDot(title, 30)}
              style={{
                lineHeight: 1,
                background: "transparent",
                border: "none",
                color: "black",
                fontSize: customStyle.fontSize,
                fontWeight: 600,
              }}
            />

            <div className="d-flex  gap-1 mx-1">
              <SettingFilled
                style={{
                  fontSize: customStyle.fontSize,
                  color: customStyle.color,
                }}
              />
              <CloseOutlined
                style={{
                  fontSize: customStyle.fontSize,
                  color: customStyle.color,
                }}
              />
            </div>
          </div>
          <TextInput
            disabled={true}
            className="p-0"
            value={textOverflowThreeDot(message, 50)}
            style={{
              lineHeight: 1,
              background: "transparent",
              border: "none",
              color: "black",
              fontSize: customStyle.fontSize,
            }}
          />
          <Link style={{ fontSize: customStyle.fontSize }} to={""}>
            {textOverflowThreeDot(url, 40)}
          </Link>
        </div>
      </div>
      {showExpanded && (
        <div className="d-flex  justify-content-center w-100 align-items-center">
          <Upload
            disabled={!uploadImage}
            showUploadList={false}
            multiple={false}
            className="m-0 p-0"
            beforeUpload={() => false}
            onChange={(value: any) => {
              if (uploadImage) {
                uploadImage(value, "webPushExpanded");
              }
            }}
          >
            <div style={{ minHeight: 120, marginTop: 1 }}>
              {isLoading?.webPushExpanded ? (
                <Spinner />
              ) : (
                <img
                  loading="lazy"
                  src={expandedImage || docIcon}
                  style={{
                    width: expandedImage ? "100%" : 70,
                    height: expandedImage ? "100%" : 70,
                  }}
                />
              )}
            </div>
          </Upload>
        </div>
      )}
      <div className="d-flex flex-column">
        {buttons?.map((button) => (
          <span
            key={button?.actionName} // Ensure to provide a unique key for each item in the array
            className="px-2 py-1"
            style={{
              fontSize: customStyle.fontSize,
              borderTop: customStyle.border,
              fontWeight: "var(--font-weight-heading)",
              minHeight: 25,
            }}
          >
            {button.actionName}
          </span>
        ))}
      </div>
    </div>
  );
};
const Android = ({
  isPreview,
  isLoading,
  headerImg,
  title,
  url,
  message,
  showExpanded,
  expandedImage,
  uploadImage,
  buttons,
}: WebPushContainer) => {
  const customStyle = {
    fontSize: isPreview ? 8 : "var(--font-size-sm)",
    color: "var(--color-text-disabled-strong)",
    border: "1px solid var(--color-text-disabled)",
  };
  return (
    <div
      className={`webpush-notification-main-container ${
        isPreview
          ? "notification-main-container-preview-android mt-3"
          : "notification-main-container-edit-android"
      }`}
    >
      <div className={`${isPreview ? "px-2 pt-2" : "px-3 pt-3"} w-100`}>
        <div
          className="d-flex  align-items-center"
          style={{
            fontSize: isPreview ? 8 : "var(--font-size-xsm)",
            color: customStyle.color,
          }}
        >
          <ChromeFilled className="m-0 p-0" />
          <span className="mx-1">Chrome .</span>
          <span className="text-overflow-ellipsis">
            {textOverflowThreeDot(url, 30) || "www.example.com"}
          </span>
          <span
            className="mx-1"
            style={{
              fontSize: "var(--font-size-xsm)",
              color: customStyle.color,
            }}
          >
            . 1m
          </span>
        </div>
        <div className={`d-flex mt-1 justify-content-between w-100 `}>
          <div className="d-flex flex-column gap-2  w-100">
            <TextInput
              disabled={true}
              className="p-0"
              value={textOverflowThreeDot(title, 30)}
              style={{
                lineHeight: 1,
                background: "transparent",
                border: "none",
                color: "black",
                fontSize: customStyle.fontSize,
                fontWeight: 600,
              }}
            />

            <TextInput
              disabled={true}
              className="p-0"
              value={textOverflowThreeDot(message, 50)}
              style={{
                lineHeight: 1,
                background: "transparent",
                border: "none",
                color: customStyle.color,
                fontSize: customStyle.fontSize,
              }}
            />
          </div>

          <Upload
            disabled={!uploadImage}
            showUploadList={false}
            multiple={false}
            className="p-0 m-0 "
            beforeUpload={() => false}
            onChange={(value: any) => {
              if (uploadImage) {
                uploadImage(value, "webPushHeader");
              }
            }}
          >
            <div>
              {isLoading?.webPushHeader ? (
                <Spinner />
              ) : (
                <img
                  loading="lazy"
                  className="m-0 p-0"
                  src={headerImg || docIcon}
                  style={{
                    height: isPreview ? 25 : 40,
                    width: isPreview ? 25 : 40,
                  }}
                />
              )}
            </div>
          </Upload>
        </div>
      </div>

      {showExpanded && (
        <div
          className={`d-flex w-100 ${
            isPreview ? "px-2 pt-2" : "px-3 pt-3"
          } justify-content-center`}
        >
          <Upload
            disabled={!uploadImage}
            showUploadList={false}
            multiple={false}
            beforeUpload={() => false}
            onChange={(value: any) => {
              if (uploadImage) {
                uploadImage(value, "webPushExpanded");
              }
            }}
          >
            <div>
              {isLoading?.webPushExpanded ? (
                <Spinner />
              ) : (
                <img
                  loading="lazy"
                  src={expandedImage || docIcon}
                  style={{
                    width: expandedImage ? "100%" : 70,
                    height: expandedImage ? "100%" : 70,
                  }}
                />
              )}
            </div>
          </Upload>
        </div>
      )}
      <div
        className={`d-flex align-items-center ${
          isPreview ? "mt-2" : "mt-3"
        } w-100 `}
        style={{
          background: "#eee",
          fontSize: customStyle.fontSize,
          fontWeight: "var(--font-weight-heading-strong)",
          color: customStyle.color,
          minHeight: 25,
        }}
      >
        {buttons?.map((button) => (
          <span
            key={button?.actionName} // Ensure to provide a unique key for each item in the array
            className="px-2 py-1"
          >
            {button?.actionName}
          </span>
        ))}
      </div>
    </div>
  );
};
const Macbook = ({
  isPreview,
  isLoading,
  headerImg,
  title,
  url,
  message,
  uploadImage,
}: WebPushContainer) => {
  const customStyle = {
    fontSize: isPreview ? 8 : "var(--font-size-sm)",
    color: "var(--color-text-disabled-strong)",
    imageDim: {
      header: {
        height: isPreview ? 25 : 40,
        width: isPreview ? 25 : 40,
      },
    },
    border: "1px solid var(--color-text-disabled)",
  };
  return (
    <div
      className={`webpush-notification-main-container d-flex ${
        isPreview
          ? "notification-main-container-preview-macbook"
          : "notification-main-container-edit-macbook"
      }`}
    >
      <div className={` d-flex p-1 gap-1 align-items-center w-100 `}>
        <div className={`${!isPreview && "mx-1"}`}>
          <img
            loading="lazy"
            className="m-0 p-0"
            src={safariIcon}
            style={customStyle.imageDim.header}
          />
        </div>
        <div className="d-flex flex-column   w-100">
          <TextInput
            disabled={true}
            className="p-0"
            value={textOverflowThreeDot(title, 27)}
            style={{
              lineHeight: 1,
              background: "transparent",
              border: "none",
              color: "black",
              fontSize: customStyle.fontSize,
              fontWeight: 600,
            }}
          />
          <Link
            className="text-overflow-ellipsis"
            style={{ fontSize: isPreview ? 8 : "var(--font-size-xsm)" }}
            to={""}
          >
            {textOverflowThreeDot(url, 30)}
          </Link>
          <TextInput
            disabled={true}
            className="p-0"
            value={textOverflowThreeDot(message, 30)}
            style={{
              lineHeight: 1,
              background: "transparent",
              border: "none",
              color: customStyle.color,
              fontSize: customStyle.fontSize,
            }}
          />
        </div>
        <Upload
          disabled={!uploadImage}
          showUploadList={false}
          multiple={false}
          className="p-0 m-0 "
          beforeUpload={() => false}
          onChange={(value: any) => {
            if (uploadImage) {
              uploadImage(value, "webPushHeader");
            }
          }}
        >
          <div>
            {isLoading?.webPushHeader ? (
              <Spinner />
            ) : (
              <img
                loading="lazy"
                className="m-0 p-0"
                src={headerImg || docIcon}
                style={customStyle.imageDim.header}
              />
            )}
          </div>
        </Upload>
      </div>
      <div
        className="d-flex  justify-content-center  flex-column"
        style={{
          fontSize: customStyle.fontSize,
          fontWeight: "var(--font-weight-heading-strong)",
          color: customStyle.color,
          borderLeft: "1px solid var(--color-other-line)",
        }}
      >
        <span className="py-1 px-2">Close</span>
        <span
          style={{ borderBottom: "1px solid var(--color-other-line)" }}
        ></span>
        <span className="py-1 px-2">More</span>
      </div>
    </div>
  );
};
