import { FC, useMemo } from "react";
interface ComputedTraitProps {
  trait: any;
}
import "./ComputedTrait.css";
import { t } from "i18next";

/**
 * ComputedTrait Component
 *
 * This component is used to display the summary of a computed trait's calculation.
 * It generates a human-readable string that summarizes the conditions and parameters 
 * used for the trait's computation, including the operand, operator, value, and the 
 * time frame for the calculation. The component uses memoization to optimize the 
 * generation of this summary string based on the trait's data.
 *
 * @param {Object} props - Component props.
 * @param {any} props.trait - The trait object containing information for calculation summary.
 *
 * @returns {JSX.Element} - The rendered component displaying the calculation summary.
 */
const ComputedTrait: FC<ComputedTraitProps> = ({ trait }) => {
  //generate calculation summary string
  const calculationSummary = useMemo(() => {
    let substring = "";
    trait?.traitInfo.forEach((element: any, index: number) => {
        substring = substring.concat(
          `${!index ? t("countOfCustomerLabel") : element.e_operator} ${
            element?.func ? element?.func + ` ${t("ofLabel")}` : ""
          } ${element?.operand || ""}  ${element?.operator || ""} ${element?.value || ""} `
        );
    });
    substring = substring.concat(trait?.days?`, ${t("overTheLastLabel")} ${trait?.days} ${t("daysLabel")}`:"");
    return substring;
  }, [trait]);
  return (
    <div className="computed-trait-main-container  mt-4 d-flex align-items-center py-2 px-3">
      {calculationSummary}
    </div>
  );
};

export default ComputedTrait;
