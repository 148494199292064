import { getRandomNumber } from "../helperFunctions/number";
import apiRequest from "../services/apiRequest";

//get MAU WAU DAU
export const getDauWauMau = ({ value, appId, apiKey }: any) => {
  const url: string = `/o/metrices/getDauWauMau`;
  return apiRequest
    .get(url, {
      params: {
        app_id: appId,
        args: JSON.stringify(value),
        api_key: apiKey,
        uniqueNumber:getRandomNumber()
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw new Error(e?.response?.data?.result);
    });
};
//get MAU WAU DAU with platform

export const getDauWauMauWithPlatform = ({
  value,
  whichPlatform,
  apiKey,
  appId,
}: any) => {
  const url: string = `/o/metrices/getDauWauMauWithPlatform`;
  return apiRequest
    .get(url, {
      params: {
        app_id: appId,
        args: JSON.stringify(value),
        api_key: apiKey,
        platform_:whichPlatform,
        uniqueNumber:getRandomNumber()

      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw new Error(e.message);
    });
};
export const getEventList = ({appId,apiKey}:any) => {
  const url: string = `/o/metrices/getEventList`;
  
  return apiRequest
    .get(url, {
      params: {
        app_id: appId,
        api_key: apiKey,
        uniqueNumber:getRandomNumber()

      },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch((e) => {
      throw new Error(e.message);
    });
};

export const getActiveUserTimelySessions = ({ value,
  apiKey,
  appId}:any) => {
  const url: string = `/o/metrices/getActiveUserTimelySessions`;
  return apiRequest
  .get(url, {
    params: {
      app_id: appId,
      args: JSON.stringify(value),
      api_key: apiKey,
      uniqueNumber:getRandomNumber()

    },
  })
  .then((res) => {
    return res.data;
  })
  .catch((e) => {
    
    throw new Error(e?.response?.data?.result);
  });
};
export const getEventsStats = ({ value,
  apiKey,
  appId}:any) => {
  const url: string = `/o/metrices/getEventsStats`;
  return apiRequest
  .get(url, {
    params: {
      app_id: appId,
      args: JSON.stringify({
        startDate:value.startDate_C,
        endDate:value.endDate_C,
        startDateNew:value.startDate_CNew,
        endDateNew:value.endDate_CNew,
      }),
      api_key: apiKey,
      uniqueNumber:getRandomNumber()

    },
  })
  .then((res) => {
    return res.data;
  })
  .catch((e) => {
    
    throw new Error(e?.response?.data?.result);
  });
};
export const getTotalStats = ({ value,
  apiKey,
  appId}:any) => {
  const url: string = `/o/metrices/getTotalStats`;
  return apiRequest
  .get(url, {
    params: {
      app_id: appId,
      args: JSON.stringify(value),
      api_key: apiKey,
      uniqueNumber:getRandomNumber()

    },
  })
  .then((res) => {
    return res.data;
  })
  .catch((e) => {
    
    throw new Error(e?.response?.data?.result);
  });
};
