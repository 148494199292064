import { Modal } from "antd";
import {  useState } from "react";
import Selector from "../../Selector/Selector";
import { useTranslation } from "react-i18next";


const AddAtributeModel = ({
  isVisible,
  onOk,
  onCancel,
  selectedAttributes,
  handleAttributeSelect,
}: any) => {
  const { t } = useTranslation();
const selectLabel=t("selectLabel")
const [selectedAttributeCsv, setSelectedAttributeCsv] = useState([]);


const handleOkClick = () => {
  handleAttributeSelect(selectedAttributeCsv);
  onOk();
};
 

  return (
    <Modal
    title="Select Attributes"
    open={isVisible}
    onOk={handleOkClick} 
    onCancel={onCancel}
    >
    
      <Selector
      placeholder={selectLabel}
      onChange={(value) => setSelectedAttributeCsv(value)} 
      style={{ width: "100%", margin: "15px 0px" }}
        size="large"
        mode="multiple"
        options={selectedAttributes.map((item:any) => ({
          value: item?.name,
          label: item?.name,
        }))}
      />
    </Modal>
  );
};

export default AddAtributeModel;
