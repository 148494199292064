import { Form, FormInstance, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./UserTraitParent.css";
import { t } from "i18next";
import {
  getCategory,
  getCategoryContextConfig,
} from "helperFunctions/audience";
import { addSegment, setCategory } from "redux/features/audience/audienceSegmentDataSlice";
import UserTraitNested from "../UserTraitNested/UserTraitNested";
import CustomSegment from "../CustomSegment/CustomSegment";
import { AudienceEnum } from "utils/enums";
import Selector from "components/Selector/Selector";
import { fetchGeoFencingList } from "redux/features/audience/geoFencingListSlice";


type propType = {
  form: FormInstance;
  blockIndex: number;
  id:string
};
const UserTraitParent: React.FC<propType> = ({ form, blockIndex,id }) => {
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const userTrait = useSelector(
    (state: any) =>
      state.audienceSegmentData.data.segmentinfo.who[blockIndex]
  );
  const [categoryContext, setCategoryContext] = useState();
  const customMetaVars = useSelector((state: any) => state.customMetaVars.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const dispatch:any = useDispatch();
  useEffect(() => {
    
    if (
      userTrait?.category &&
      (getCategoryContextConfig(userTrait?.category)[0]?.attributes ||
      userTrait?.category == AudienceEnum.CUSTOM_VARS)
    ) {
      userTrait?.category == AudienceEnum.CUSTOM_VARS
        ? setCategoryContext(customMetaVars)
        : setCategoryContext(
            getCategoryContextConfig(userTrait.category)[0].attributes
          );
    } else if (userTrait?.category) {
      setCategoryContext(getCategoryContextConfig(AudienceEnum.DEVICE)[0].attributes);
    }
  }, [userTrait?.category]);
  useEffect(() => {
    if(loginUser.api_key && !blockIndex){
      dispatch(fetchGeoFencingList({api_key:loginUser.api_key,app_id:appId}))
    }
  }, [loginUser,userTrait?.category]);
  return (
    <div className="audience-usertrait-main-container position-relative mt-2 gap-2 d-flex flex-column flex-wrap w-100  ">
      <div className="d-flex">
        <div>
          <Form.Item
            rules={[{ required: true, message: `${t("selectCategoryMsg")}` }]}
            name={`category[${id}]`}
            label={t("userTraitLabel")}
            colon={false}
            className="m-0 user-trait-min-width-300px w-25 mx-2"
            initialValue={userTrait?.category}
          >
            <Selector
            data-testid="category-selector"
              value={userTrait?.category}
              className="user-trait-category-input"
              showSearch
              onChange={(value: any) => {dispatch(setCategory({segmentType:AudienceEnum.WHO,category:value,indexToModify:blockIndex}))}}
              options={getCategory()}
              placeholder={`${t("selectCategoryMsg")}`}
            />
          </Form.Item>
          <div className="user-trait-where-nested-container mt-2">
            {userTrait?.children?.map((child: any,childIndex:number) => (
             userTrait.category!==AudienceEnum.CUSTOM_SEGMENT? <UserTraitNested
                key={child.id}
                form={form}
                category={userTrait.category}
                childValue={child} 
                childIndex={childIndex}
                categoryContext={categoryContext}
              />:<div key={child.id}  className="ms-4 ps-3"><CustomSegment   category={userTrait.category} childIndex={childIndex} childValue={child}/></div>
            ))}
          </div>
        </div>
      </div>
      {userTrait?.category && userTrait?.category!==AudienceEnum.CUSTOM_SEGMENT && <div className="d-flex justify-content-between">
        <div className="p-1">
          <Tag
          data-testid="user-trait-where-btn"
            className="cursor-pointer"
            onClick={() => {
              dispatch(
                addSegment({
                  segmentType: AudienceEnum.WHO,
                  children: {e_operator:AudienceEnum.AND},
                  category: userTrait?.category,
                })
              );
            }}
          >
            {t("plusWhereLabel")}
          </Tag>
        </div>
      </div>}
    </div>
  );
};

export default UserTraitParent;
