import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "./DateSelector.css";
import { DatePicker } from "antd";
import type { Dayjs } from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { paylaodFormat, setDateRange } from "../../../redux/features/dateRange/dateRangeSlice";
import { disabledDate } from "../../../helperFunctions/date";
const { RangePicker } = DatePicker;
const dateFormat = "MMM D, YYYY";
const changeFormat = (value?: number) => {
  const date = value ? new Date(value) : new Date();
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};
dayjs.extend(customParseFormat);
const rangePresets: {
  label: string;
  value: [Dayjs, Dayjs];
}[] = [
  { label: "Today", value: [dayjs().startOf("day"), dayjs().startOf("day")] },
  { label: "Last 30 Days", value: [dayjs().subtract(29, "days").startOf("day"), dayjs().endOf("day")] },
  { label: "This Month", value: [dayjs().startOf("month"), dayjs().endOf("month")] },

  {
    label: "Yesterday",
    value: [dayjs().subtract(1, "day").startOf("day"), dayjs().subtract(1, 'day').endOf('day')]
  },
  {
    label: "Last Week",
    value: [
      dayjs().subtract(1, "week").startOf("week"),
      dayjs().subtract(1, "weeks").endOf("week"),
    ],
  },
  {
    label: "Last Month",
    value: [
      dayjs().subtract(1, "month").startOf("month"),
      dayjs().subtract(1, "month").endOf("month"),
    ],
  },
];
type propTypes = {
  notGlobal?: boolean;
  onChange?: (value: any) => void;
  setLocalDate?: (value: any) => void;
  className?:string
};
export const DateSelector = ({ notGlobal, setLocalDate ,className}: propTypes) => {
  const dispatch = useDispatch();
  const dateRange = useSelector((state: any) => state.dateRange);
  const onChange = (value: any) => {
    const startDate_C = Date.parse(value[0].$d) / 1000;
    const endDate_C =
      Date.parse(value[1].$d) / 1000 +
      dayjs().endOf("day").diff(dayjs().startOf("day"), "second");//added number of seconds today so for
      const startDate_P = dayjs(startDate_C*1000).subtract(endDate_C-startDate_C,"second").endOf("day").unix()
      const endDate_P =dayjs(endDate_C*1000).subtract(endDate_C-startDate_C,"second").endOf("day").unix()
      const startDate_PNew = dayjs(startDate_P*1000).format(paylaodFormat);
      const endDate_PNew = dayjs(endDate_P*1000).format(paylaodFormat);
      const startDate_CNew = dayjs(startDate_C*1000).format(paylaodFormat);
    const endDate_CNew = dayjs(endDate_C*1000).format(paylaodFormat);
    const dateRangeData={
      startDate_P,
      endDate_P,
      startDate_PNew,
      endDate_PNew,
      startDate_CNew,
      endDate_CNew,
      endDate_C,
      startDate_C,
    }
    if (notGlobal && setLocalDate) {
      //update the state in local
      setLocalDate(dateRangeData);
    } else {
      //update the state in redux
      dispatch(
        setDateRange(dateRangeData)
      );
    }
  };
  return (
    <RangePicker
    className={className}
      defaultValue={notGlobal?null:[
        dayjs(changeFormat(dateRange.startDate_C * 1000), dateFormat),
        dayjs(changeFormat(dateRange.endDate_C * 1000), dateFormat),
      ]}
      disabledDate={disabledDate}
      format={dateFormat}
      popupClassName="custom-date-selector"
      picker="date"
      presets={rangePresets}
      onChange={onChange}
      inputReadOnly
      showTime
      disabled={[false, false]}
      allowClear={false}
    />
  );
};
