import React from "react";
import CustomHighChart from "../../../../components/Charts/CustomHighChart/CustomHighChart";

export const PerformanceChartTwo = () => {
  const dataSeries1 = [1, 2, 3, 4, 5];
  const dataSeries2 = [5, 4, 3, 2, 1];

  const options = {
    chart: {
      type: "column", // Use 'column' for bars
    },
    title: {
      text: "",
    },
    plotOptions: {
      column: {
        // color: '#ff0000', // Color of the columns
        // borderColor: '#000000', // Border color of the columns
        // borderWidth: 2, // Border width of the columns
        plotBackgroundColor: "rgba(0, 0, 0, 0.02)", // Set the background color of the plot area
      },
    },
    xAxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    },
    yAxis: [
      {
        title: {
          text: "Bar Axis",
        },
        labels: {
          format: "{value}",
        },
      },
      {
        title: {
          text: "Spline Axis",
        },
        labels: {
          format: "{value}",
        },
        opposite: true,
      },
    ],
    series: [
      {
        name: "Clicks",
        data: [15, 22, 15, 20],
      },

      //
      {
        name: "Conversions",
        type: "spline", // Use 'spline' for the spline series
        data: [11, 20, 4, 19],
        // Use the second y-axis for the spline series
      },
    ],
  };
  return (
    <div>
      {" "}
      <CustomHighChart options={options} />
    </div>
  );
};
