import { Divider } from "antd";
import "./DoNotDisturb.css";
import PrimaryButton from "../../../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { Form, Radio } from "antd";
import Selector from "../../../../../../components/Selector/Selector";
import { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import centralApi from "../../../../../../services/centralApi";
import { getToken } from "../../../../../../services/getToken";
import ShowMessage from "../../../../../../components/ShowMessage/ShowMessage";
import Spinner from "../../../../../../components/Spinner/Spinner";
import TextInput from "../../../../../../components/Inputs/TextInput/TextInput";
import SwitchBtn from "../../../../../../components/Buttons/SwitchBtn/SwitchBtn";
import RadioButton from "../../../../../../components/Buttons/RadioButton/RadioButton";
import React from 'react'
interface DoNotDisturbProps {
  currentAppKey: any;
  Mode: string;
  fetchCurrentAppKey:any;
}

const DoNotDisturb: React.FC<DoNotDisturbProps> = ({ currentAppKey, Mode,fetchCurrentAppKey }) => {
  const [form] = Form.useForm();
  const mode =
    Mode === "Development" ? "dev" : Mode === "Production" ? "prod" : "";
    let dndDataValues: any = {};
    if (currentAppKey[0]?.DND_Values) {
  const fetchedModeData =
    Mode === "Development"
      ? "mode_dev"
      : Mode === "Production"
      ? "mode_prod"
      : "";
  dndDataValues = currentAppKey[0]?.DND_Values[fetchedModeData];
}

  const initialIsTotalPushEnabled = dndDataValues?.is_Dnd;
  const [isTotalPushEnabled, setIsTotalPushEnabled] = useState(
    initialIsTotalPushEnabled
  );

  const [loading, setLoading] = useState(false);
  const [selectedDndPeriod, setSelectedDndPeriod] = useState(
    !dndDataValues?.discard_message
  );
  const initialSaveShowCriteria = !dndDataValues?.discard_message;

  const [showSaveCriteria, setShowSaveCriteria] = useState(
    initialSaveShowCriteria
  );
  const initialQueuedMessageSent = dndDataValues?.send_message?.sendAll;

  const [queuedMessageSent, setQueuedMessageSent] = useState(
    initialQueuedMessageSent
  );
  const [controlQueuedMessageSent, setControlQueuedMessageSent] =
    useState(false);
  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  });
  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;
  const onFormSubmit = (values: any) => {
    let payload: any = {
      app_id: appId,
      Dnd_Values: {
        is_Dnd: values.is_Dnd,
      },
      mode,
    };
    if (values?.is_Dnd) {
      const dndValues = payload?.Dnd_Values;
      dndValues.start_Hour = values?.start_Hour;
      dndValues.start_Min = values?.start_Min;
      dndValues.start_timeshift = values?.start_timeshift;
      dndValues.end_Hour = values?.end_Hour;
      dndValues.end_Min = values?.end_Min;
      dndValues.end_timeshift = values?.end_timeshift;
      dndValues.discard_message = values?.discard_message;

      if (values?.is_Dnd && values?.send_message) {
        dndValues.send_message = {
          sendAll: values?.send_message === "sendAll",
          oneFromCampaign: values?.send_message === "oneFromCampaign",
          oneFromAll: values?.send_message === "oneFromAll",
        };
      }
      if (values?.is_Dnd && values?.send_message && values?.method) {
        dndValues.send_message.method = {
          lifo: values?.method === "Senxd ",
        };
      }
      if (
        values?.is_Dnd &&
        values?.send_message &&
        values?.method &&
        values?.queueControl
      ) {
        dndValues.send_message.queueControl = {
          all_at_once: values.queueControl === "all_at_once",
          with_gap: values.queueControl === "with_gap",
        };
      }
      if (dndValues?.send_message?.queueControl?.with_gap) {
        dndValues.send_message.queueControl.gap_Min = values?.gap_Min;
      }
    }
    const formValues = {
      args: JSON.stringify({
        ...payload,
      }),
      app_id: appId,
      api_key: api_key,
    };

    updateDndValue(formValues);
      getToken(appId, api_key);
  
  };

  async function updateDndValue(formData: any) {
    const url: string = `/i/apps/updateDndValue`;

    try {
      setLoading(true);
     await centralApi("POST", url, formData, null);
      setShowMessage({
        type: "success",
        message: "Successfully Saved",
        show: true,
      });
      fetchCurrentAppKey();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setShowMessage({
        type: "error",
        message: "Something went wrong",
        show: true,
      });
    }
    hideMessage();
  }
  const hideMessage = () => {
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false });
    }, 2000);
  };
  const handleRadioChange = (e: any) => {
    setSelectedDndPeriod(!e.target.value);
    setShowSaveCriteria(!e.target.value);
    setQueuedMessageSent(false);
    setControlQueuedMessageSent(false);
  };
  const handleQueuedMessageSent = (e: any) => {
    setControlQueuedMessageSent(true);
  };
  const handleSaveSendCriteria = (e: any) => {
    const value = e.target.value;
    if (value === "sendAll") {
      setQueuedMessageSent(true);
    } else {
      setQueuedMessageSent(false);
      setControlQueuedMessageSent(false);
    }
  };
  const handleDndToggle = (e: any) => {
    setIsTotalPushEnabled(e);
    setShowSaveCriteria(false);

    if (!e) {
      form.resetFields();
    }
  };
  useEffect(() => {
    if (!initialIsTotalPushEnabled) {
      form.resetFields();
      setIsTotalPushEnabled(false);
    } else {
      setIsTotalPushEnabled(true);
    }
  }, [initialIsTotalPushEnabled]);
  useEffect(() => {
    if (initialQueuedMessageSent) {
      setQueuedMessageSent(true);
    } else {
      setQueuedMessageSent(false);
    }
  }, [initialQueuedMessageSent]);
  useEffect(() => {
    if (initialSaveShowCriteria) {
      setShowSaveCriteria(true);
    } else {
      setShowSaveCriteria(false);
    }
  }, [initialSaveShowCriteria]);
  useEffect(() => {
    let sendMessageValue = undefined;

    if (dndDataValues?.send_message?.oneFromAll) {
      sendMessageValue = "oneFromAll";
    } else if (dndDataValues?.send_message?.oneFromCampaign) {
      sendMessageValue = "oneFromCampaign";
    } else if (dndDataValues?.send_message?.sendAll) {
      sendMessageValue = "sendAll";
    }
    const ControlQueue = dndDataValues?.send_message?.queueControl?.all_at_once;
    const selectedControlQueue = ControlQueue ? "all_at_once" : "with_gap";
    const method = dndDataValues?.send_message?.method?.lifo;
    const selectedmethod = method ? "fifo" : "lifo";
    const withGap = dndDataValues?.send_message?.queueControl?.with_gap
      ? dndDataValues?.send_message?.queueControl?.gap_Min
      : "";
    const updatedFormValues = {
      gap_Min: withGap,
      send_message: sendMessageValue,
      discard_message: dndDataValues?.discard_message,
      method: selectedmethod,
      queueControl: selectedControlQueue,
      is_Dnd: dndDataValues?.is_Dnd,
      start_Hour: dndDataValues?.start_Hour,
      end_Hour: dndDataValues?.end_Hour,
      end_Min: dndDataValues?.end_Min,
      end_timeshift: dndDataValues?.end_timeshift,
      start_timeshift: dndDataValues?.start_timeshift,
      start_Min: dndDataValues?.start_Min,
    };
    form.setFieldsValue(updatedFormValues);
  }, [mode, currentAppKey, form]);
  useEffect(() => {
    getToken(appId, api_key);
  }, []);

  return (
    <Fragment>
      <div className="do-not-disturb-container">
        <div className="mt-4 mb-4  ">
          <h6 className="do-not-disturb-container-heading">
            DND Setting -- {Mode}
          </h6>
          <Divider className="do-not-disturb-custom-divider" />
        </div>

        <div className=" col-sm-10 mt-4 mb-4 align-items-center do-not-disturb-form ">
          {showMessage.show && (
            <ShowMessage
              type={showMessage.type}
              content={showMessage.message}
            />
          )}
          {loading ? (
            <Spinner />
          ) : (
            <Form form={form} layout="horizontal" onFinish={onFormSubmit}>
              <div className="d-flex align-items-baseline gap-2">
                <Form.Item
                  // label="Total push notifications send up to"
                  name="is_Dnd"
                  valuePropName="checked"
                >
                  <SwitchBtn onChange={handleDndToggle} />
                </Form.Item>

                <span> Do Not Disturb (DND)</span>
              </div>

              <Form.Item label="Set DND Period" style={{ marginBottom: 0 }}>
                <Form.Item
                  name="start_Hour"
                  rules={
                    isTotalPushEnabled
                      ? [
                          {
                            required: true,
                            message: "Please enter a Start Hour",
                          },
                        ]
                      : []
                  }
                  style={{ display: "inline-block", width: "calc(30% - 8px)" }}
                >
                  <TextInput
                    placeholder="Start Hour"
                    disabled={!isTotalPushEnabled}
                    type="number"
                  />
                </Form.Item>
                <Form.Item
                  name="start_Min"
                  rules={
                    isTotalPushEnabled
                      ? [
                          {
                            required: true,
                            message: "Please enter a start min",
                          },
                        ]
                      : []
                  }
                  style={{
                    display: "inline-block",
                    width: "calc(30% - 8px)",
                    margin: "0 8px",
                  }}
                >
                  <TextInput
                    placeholder="Start Min"
                    disabled={!isTotalPushEnabled}
                    type="number"
                  />
                </Form.Item>
                <Form.Item
                  name="start_timeshift"
                  rules={
                    isTotalPushEnabled
                      ? [
                          {
                            required: true,
                            message: "Please enter a start Time Shift",
                          },
                        ]
                      : []
                  }
                  style={{
                    display: "inline-block",
                    width: "calc(30% - 8px)",
                    margin: "0 8px",
                  }}
                >
                  <Selector
                    onChange={(value) => {}}
                    size="middle"
                    style={{ width: "100%" }}
                    defaultValue={"Select"}
                    options={[
                      {
                        value: "",
                        label: "Select",
                      },
                      {
                        value: "PM",
                        label: "PM",
                      },
                      {
                        value: "AM",
                        label: "AM",
                      },
                    ]}
                    disabled={!isTotalPushEnabled}
                  />
                </Form.Item>
              </Form.Item>
              <Form.Item label="To" style={{ marginBottom: 0 }}>
                <Form.Item
                  name="end_Hour"
                  rules={
                    isTotalPushEnabled
                      ? [{ required: true, message: "Please enter a End hour" }]
                      : []
                  }
                  style={{ display: "inline-block", width: "calc(30% - 8px)" }}
                >
                  <TextInput
                    placeholder="End Hour"
                    disabled={!isTotalPushEnabled}
                    type="number"
                  />
                </Form.Item>
                <Form.Item
                  name="end_Min"
                  rules={
                    isTotalPushEnabled
                      ? [{ required: true, message: "Please enter a End Min" }]
                      : []
                  }
                  style={{
                    display: "inline-block",
                    width: "calc(30% - 8px)",
                    margin: "0 8px",
                  }}
                >
                  <TextInput
                    placeholder="End Min"
                    type="number"
                    disabled={!isTotalPushEnabled}
                  />
                </Form.Item>
                <Form.Item
                  name="end_timeshift"
                  rules={
                    isTotalPushEnabled
                      ? [
                          {
                            required: true,
                            message: "Please enter a End time Shift",
                          },
                        ]
                      : []
                  }
                  style={{
                    display: "inline-block",
                    width: "calc(30% - 8px)",
                    margin: "0 8px",
                  }}
                >
                  <Selector
                    onChange={(value) => {}}
                    size="middle"
                    style={{ width: "100%" }}
                    defaultValue={"Select"}
                    options={[
                      {
                        value: "",
                        label: "Select",
                      },
                      {
                        value: "PM",
                        label: "PM",
                      },
                      {
                        value: "AM",
                        label: "AM",
                      },
                    ]}
                    disabled={!isTotalPushEnabled}
                  />
                </Form.Item>
              </Form.Item>
              <h6 className="do-not-disturb-container-sub-heading">
                For the messages generated during the DND period
              </h6>
              <Form.Item
                name={"discard_message"}
                rules={
                  isTotalPushEnabled
                    ? [{ required: true, message: "Please select one opition" }]
                    : []
                }
              >
                <Radio.Group>
                  <div className="do-not-disturb-container-radio-button-margin">
                    <RadioButton
                      name="Discard these messages and do not send them later "
                      onChange={handleRadioChange}
                      disabled={!isTotalPushEnabled}
                      value={true}
                    />
                  </div>
                  <div>
                    <RadioButton
                      name="Save these messages and send them after DND period is over "
                      onChange={handleRadioChange}
                      disabled={!isTotalPushEnabled}
                      value={false}
                    />
                  </div>
                </Radio.Group>
              </Form.Item>
              {isTotalPushEnabled && (
                <div>
                  {selectedDndPeriod ? (
                    <div>
                      {isTotalPushEnabled && showSaveCriteria ? (
                        <div>
                          <h6 className="do-not-disturb-container-sub-heading">
                            Save And Send Criteria
                          </h6>
                          <Form.Item
                            name={"send_message"}
                            rules={
                              showSaveCriteria
                                ? [
                                    {
                                      required: true,
                                      message: "Please select one opition",
                                    },
                                  ]
                                : []
                            }
                          >
                            <Radio.Group>
                              <div className="do-not-disturb-container-radio-button-margin">
                                <RadioButton
                                  name="Send all the queued messages "
                                  value="sendAll"
                                  onChange={handleSaveSendCriteria}
                                />
                              </div>
                              <div className="do-not-disturb-container-radio-button-margin">
                                <RadioButton
                                  name="Send only one message from each Campaign "
                                  value="oneFromCampaign"
                                  onChange={handleSaveSendCriteria}
                                />
                              </div>
                              <div>
                                <RadioButton
                                  name="Send only one message across Push Campaigns "
                                  value="oneFromAll"
                                  onChange={handleSaveSendCriteria}
                                />
                              </div>
                            </Radio.Group>
                          </Form.Item>
                        </div>
                      ) : null}

                      {isTotalPushEnabled &&
                      showSaveCriteria &&
                      queuedMessageSent ? (
                        <div>
                          <h6 className="do-not-disturb-container-sub-heading">
                            Order in which queued messages should be sent
                          </h6>
                          <Form.Item
                            name={"method"}
                            rules={
                              queuedMessageSent
                                ? [
                                    {
                                      required: true,
                                      message: "Please select one opition",
                                    },
                                  ]
                                : []
                            }
                          >
                            <Radio.Group>
                              <div className="do-not-disturb-container-radio-button-margin">
                                <RadioButton
                                  name="Send most recent messages first (last in first out) "
                                  value="lifo"
                                  onChange={handleQueuedMessageSent}
                                />
                              </div>
                              <div>
                                <RadioButton
                                  name="Send least recent message first (first in first out) "
                                  value="fifo"
                                  onChange={handleQueuedMessageSent}
                                />
                              </div>
                            </Radio.Group>
                          </Form.Item>
                        </div>
                      ) : null}
                      {isTotalPushEnabled &&
                      showSaveCriteria &&
                      queuedMessageSent ? (
                        <div>
                          <h6 className="do-not-disturb-container-sub-heading">
                            Control queued messages sending
                          </h6>
                          <Form.Item
                            name={"queueControl"}
                            rules={
                              controlQueuedMessageSent
                                ? [
                                    {
                                      required: true,
                                      message: "Please select one opition",
                                    },
                                  ]
                                : []
                            }
                          >
                            <Radio.Group>
                              <div className="do-not-disturb-container-radio-button-margin">
                                <RadioButton
                                  name="Send all messages at one"
                                  value="all_at_once"
                                />
                              </div>
                              <div className="d-flex align-items-baseline">
                                <RadioButton
                                  name="Send messages at a gap of "
                                  value="with_gap"
                                />
                                <Form.Item
                                  name={"gap_Min"}
                                  rules={[
                                    ({ getFieldValue }) => ({
                                      required:
                                        getFieldValue("queueControl") ===
                                        "with_gap",
                                      message: "Please enter minutes",
                                    }),
                                  ]}
                                >
                                  <TextInput
                                    placeholder="minutes"
                                    type="number"
                                  />
                                </Form.Item>
                              </div>
                            </Radio.Group>
                          </Form.Item>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              )}

              <div className="">
                <PrimaryButton
                  size="middle"
                  type="primary"
                  htmlType="submit"
                  style={{ border: "none", width: "80px" }}
                >
                  Save
                </PrimaryButton>
              </div>
            </Form>
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default DoNotDisturb;
