import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../redux/features/user/loginUserSlice";
import AppCard from "../../../components/Cards/AppCard/AppCard";
import useMessage from "../../../hooks/useMessage";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../components/Spinner/Spinner";
import { setActiveApp } from "../../../redux/features/app/activeAppSlice";
import { Helmet } from "react-helmet";
import centralApi from "../../../services/centralApi";
const Home: React.FC =()=>{
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState<any>({});
  const { showError, showSuccess } = useMessage();
  const [appStats, setAppStats] = useState<any>({data:{},loading:true});
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const dispatch:any = useDispatch();
  const navigate = useNavigate();
  const dashboardClick = async (id: string, appName: string) => {
    // if the selected appid is included in his approved appid 
    if (dashboardData.member.approvedAppids && dashboardData.member.approvedAppids.includes(id)) {
      dispatch(setActiveApp({ appName, appId: id }));
      navigate(`app?id=${id}`, { state: { appName } });
    } else {
      //show Error
      showError("Your approval is pending for this App. Please contact the Admin");
    }
  };
  const settingClick = (id: string,appName: string) => {
    dispatch(setActiveApp({ appName, appId: id }));
    navigate(`appsetting`, { state: { appName } });

  };
  const deleteClick = (id: string,appName: string) => {
    console.log(`${id}:${appName}`)
  };
  const fetchAppsStats=async()=>{
    try {
      const params = {
        api_key: loginUser.api_key,
        app_id: appId,
      };
      const app_stats = await centralApi(
        "GET",
        "o/metrices/getAppStats",
        null,
        params
      );
      setAppStats({data:app_stats?.data,loading:false});
    } catch (error) {
      setAppStats({data:{},loading:false});
    }
    
  }
  const fetchDashboard = async () => {
    try {
      setLoading(true);
      const data= await centralApi("GET","/o/dashboard")
      if (data.redirect) {
        if(data.redirectPath=="/login"){
          dispatch(logoutUser())
        }
        else{
          navigate(data.redirectPath);
        }
      }
      setDashboardData(data);
      setLoading(false);
    } catch (error) {
      dispatch(logoutUser())
      setLoading(false);
    }
  };
  useEffect(() => {
    if(loginUser.api_key){
      fetchDashboard();
      fetchAppsStats()
    }
  }, []);
  return (
    <div className="panel-home ">
      <Helmet>
        <title>Appice | Home</title>
      </Helmet>
      {loading && <Spinner />}
      {!loading &&
        dashboardData.userOfApps?.map((app: any) => {
          return (
            <AppCard
              key={app._id}
              appName={app.name}
              dashboardClick={() => dashboardClick(app._id, app.name)}
              metaDataLoading={appStats.loading}
              values={{
                installs: {
                  android: appStats.data[app._id]?.androidInstallTotal || "NA",
                  ios: appStats.data[app._id]?.iosInstallTotal  || "NA" ,
                  global:appStats.data[app._id]?.webInstallTotal || "NA" ,
                },
                churned: {
                  android: appStats.data[app._id]?.androidUnInstallTotal || "NA",
                  ios: appStats.data[app._id]?.iosUnInstallTotal || "NA",
                  global: appStats.data[app._id]?.webUnInstallTotal || "NA",
                },
              }}
              settingClick={() => settingClick(app._id,app.name)}
              deleteClick={() => deleteClick(app._id,app.name)}
            ></AppCard>
          );
        })}
    </div>
  );
}

export default Home;
