import { t } from "i18next";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./Demographics.css";
import centralApi from "../../services/centralApi";
import { useSelector } from "react-redux";
import CustomHighChart from "../../components/Charts/CustomHighChart/CustomHighChart";
import { colors } from "../../components/Charts/CustomHighChart/data";
function DemographicsCard({ children, title, className }: any) {
  return (
    <div className={`demographics-card-container ${className} `}>
      <div className="demographics-card-heading d-flex justify-content-between align-items-center  p-2">
        <strong>{title}</strong>
      </div>
      <div className="demographics-card-body p-3">{children}</div>
    </div>
  );
}
function Demographics() {
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const [activeUserInterests, setActiveUserInterests] = useState<any>([]);
  const [competingAppStats, setCompetingAppStats] = useState<any>([]);
  const getActiveUserInterests = async () => {
    try {
      if (loginUser.api_key && appId) {
        const params = {
          api_key: loginUser.api_key,
          app_id: appId,
        };
        const data = await centralApi(
          "GET",
          "o/metrices/getActiveUserInterests",
          null,
          params
        );
        setActiveUserInterests(
          data.sort((a: any, b: any) => a.total - b.total)
        );
      }
    } catch (error) {}
  };
  const getActiveUserCompetingAppStats = async () => {
    try {
      if (loginUser.api_key && appId) {
        const params = {
          api_key: loginUser.api_key,
          app_id: appId,
        };
        const data = await centralApi(
          "GET",
          "o/metrices/getActiveUserCompetingAppStats",
          null,
          params
        );
        setCompetingAppStats(data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getActiveUserInterests();
    getActiveUserCompetingAppStats();
  }, [appId, loginUser]);
  return (
    <div className="demographics-container my-3">
      <Helmet>
        <title>Appice | demographics</title>
      </Helmet>
      <h4>{t("demographicsLabel")}</h4>
      <div className="d-flex  my-3 flex-wrap">
        <div className="col-lg-6 col-12">
          <DemographicsCard title={t("userInterestsLabel")} className="mx-1">
            <CustomHighChart
              options={{
                chart: {
                  type: "bar",
                  height: 470,
                },
                title: {
                  text: "",
                },
                xAxis: {
                  categories: activeUserInterests.map(
                    ({ interest }: { interest: string }) => interest
                  ),
                  title: {
                    text: "",
                  },
                },
                plotOptions: {
                  series: {
                    colorByPoint: true,
                    stacking: "normal",
                    pointPadding: 0, // Set pointPadding to 0 to remove gap between columns
                    groupPadding: 0, // Set groupPadding to 0 to remove gap between groups of columns
                  },
                },
                
                // Set to false to remove the gap between bars
                legend: {
                  enabled: false, // Set to false to hide the legend
                },
                navigation: {
                  buttonOptions: {
                    enabled: false, // Set to false to hide the hamburger icon
                  },
                },
                yAxis: {
                  title: {
                    text: "",
                  },
                },

                series: [
                  {
                    name: "Interest",
                    data: activeUserInterests.map(
                      ({ total }: { total: number }) => total
                    ),
                  },
                ],
                colors: colors["primary"],
              }}
            />
          </DemographicsCard>
        </div>
        <div className="col-lg-6 col-12">
          <DemographicsCard title={t("competingAppsLabel")} className="mx-1">
            <CustomHighChart
              options={{
                chart: {
                  type: "column",
                  height: 480,
                },
                title: {
                  text: undefined,
                },

                xAxis: {
                  categories: competingAppStats.map(
                    (item: { ctr: string }) => item.ctr
                  ),
                  title: {
                    text: t("numberOfUsersLabel"),
                  },
                },
                yAxis: {
                  title: {
                    text: t("numberOfCompetingAppsLabel"),
                  },
                },
                legend: {
                  enabled: false, // Set to false to hide the legend
                },
                navigation: {
                  buttonOptions: {
                    enabled: false, // Set to false to hide the hamburger icon
                  },
                },
                colors: colors["primary"],
                
                plotOptions: {
                  column: {
                    grouping: true,
                    colorByPoint: true,
                    dataLabels: {
                      enabled: true,
                    },
                  },
                  // Set to false to remove the gap between bars
                },
                
                series: [
                  {
                    name: t("countLabel"),
                    data: competingAppStats.map(
                      (item: { total: number }) => item.total
                    ),
                  },

                ],
              }}
            />
          </DemographicsCard>
        </div>
      </div>
    </div>
  );
}

export default Demographics;
