import { FC, useEffect, useState } from "react"
import TemplateLayout from "../../../layout/TemplateLayout/TemplateLayout"
import { Col, Form, Row } from "antd"
import FormItem from "antd/es/form/FormItem"
import Selector from "../../../../../../components/Selector/Selector"
import TextInput from "../../../../../../components/Inputs/TextInput/TextInput"
import CustomTextArea from "../../../../../../components/Inputs/CustomTextArea/CustomTextArea"
import CampaignDevice from "../../../../../../components/CampaignDevice/CampaignDevice"
import PrimaryButton from "../../../../../../components/Buttons/PrimaryButton/PrimaryButton"
import { useDispatch, useSelector } from "react-redux"
import centralApi from "../../../../../../services/centralApi"
import { t } from "i18next"
import { fetchTemplatesList } from "../../../../../../redux/features/templates_/templatesByTypeSlice"
import {
  fetchTemplateById,
  setSelectedTemplate,
} from "../../../../../../redux/features/templates_/templateByIdSlice"
import { setCampaignData } from "../../../../../../redux/features/campaign/campaignDataSlice"
import { validateImageDimensions } from "../../../../../../helperFunctions/file"
import { setCurrentStep } from "../../../../../../redux/features/campaign/campaignCreateCurrentStepSlice"
import { useForm } from "antd/es/form/Form"
import WebPopUpContainer from "../../NotificationContainer/WebPopUpContainer/WebPopUpContainer"
import useMessage from "../../../../../../hooks/useMessage"
import { getToken } from "../../../../../../services/getToken"
interface WebPopUpProps {}

const WebPopUp: FC<WebPopUpProps> = () => {
  const dispatch: any = useDispatch()
  const { showError } = useMessage()
  const { appId } = useSelector((state: any) => state.activeApp)
  const loginUser = useSelector((state: any) => state.loginUser.data)
  const templates = useSelector((state: any) => state.templatesList.data)
  const selectedTemplate = useSelector((state: any) => state.templateById.data)

  const [form] = useForm()
  const [formData, setFormData] = useState<any>({
    title: "",
    message: "",
    icon: "",
    url: "",
    mode: "",
    ps: "",
    br: "",
    wh: "",
    hc: "#444444",
    dc: "#666666",
    bg: "#ffffff",
  })
  const [imageLoading, setImageLoading] = useState({
    webPopUp: false,
  })
  const currentDate = new Date()
  const todayDate = `${(currentDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${currentDate
    .getDate()
    .toString()
    .padStart(2, "0")}/${currentDate.getFullYear()}`
  const [template, setTemplate] = useState({
    template_name: "",
    template_type: "WEB_POPUP",
    isTemplateDeleted: "false",
    modifiedOn: "",
  })
  const currentStep = useSelector(
    (state: any) => state.campaignCreateCurrentStep
  )

  const campaignData = useSelector((state: any) => state.campaignData.data)
  // const [blobContainerCDN, setBlobContainerCDN] = useState(
  //   campaignData?.data?.blobContainerCDN || ""
  // )
  const [deviceType, setDeviceType] =
    useState<"Android" | "IOS" | "windows" | "Mac">("windows")


  const handleTemplateUpdate = async () => {
    try {
      const payload = {
        api_key: loginUser.api_key,
        app_id: appId,
        template_id: selectedTemplate._id,
        args: JSON.stringify({
          ...template,
          template: formData,
        }),
      }
      const res = await centralApi("POST", "i/templates/update", payload)
      dispatch(
        setCampaignData({
          ...campaignData,
          data: res,
          tid: res._id,
          t: res.template_type,
        })
      )
      dispatch(setCurrentStep(currentStep + 1))
    } catch (error) {}
  }
  const handleTemplateSave = async () => {
    try {
      const payload = {
        api_key: loginUser?.api_key,
        app_id: appId,
        args: JSON.stringify({
          ...template,
          template: formData,
          createdOn: todayDate,
        }),
      }
      const res = await centralApi("POST", "i/templates/create", payload)
      dispatch(
        setCampaignData({
          ...campaignData,
          data: res,
          tid: res._id,
          t: res.template_type,
        })
      )
      dispatch(setCurrentStep(currentStep + 1))
    } catch (error) {}
  }
 
  useEffect(() => {
    if (selectedTemplate) {
      dispatch(
        setCampaignData({
          ...campaignData,
          data: selectedTemplate,
          tid: selectedTemplate._id,
          t: selectedTemplate.template_type,
        })
      )
    }
  }, [selectedTemplate])


  const handleImageUpload = async (value: any, type: "webPopUp") => {
    try {
      const { valid, message } = await validateImageDimensions(type, value.file)
      if (valid && appId) {
        //upload image
        setImageLoading((prevState: any) => ({
          ...prevState,
          [type]: true,
        }))
        const formData = new FormData()
        formData.append("image", value.file)
        formData.append("image_id", value.file.name)
        formData.append("appid", appId)
        const res = await centralApi(
          "POST",
          "/i/campaignFiles/campaignimages",
          formData,
          null
        )
        setTimeout(() => {
          // setBlobContainerCDN(res.baseURL)
          setFormData((prevState: any) => ({
            ...prevState,

            [type == "webPopUp" ? "icon" : "icon"]:
              res.baseURL + "/" + res?.url,
          }))
          setImageLoading((prevState: any) => ({
            ...prevState,
            [type]: false,
          }))
        }, 1000)
      } else {
        showError(message)
      }
    } catch (error) {
      setImageLoading((prevState: any) => ({
        ...prevState,
        [type]: false,
      }))
    }
  }
  useEffect(() => {
    dispatch(
      fetchTemplatesList({
        app_id: appId,
        api_key: loginUser.api_key,
        template_type: "WEB_POPUP",
      })
    )
    getToken(appId, loginUser.api_key)
  }, [])
  useEffect(() => {
    if (campaignData.data) {
      dispatch(setSelectedTemplate(campaignData.data))
    }
  }, [campaignData?.data?._id])
  useEffect(() => {
    const {
      template,
      template_name,
      template_type,
      // blobContainerCDN,
      createdOn,
    } = selectedTemplate || campaignData.data || formData
    // if (blobContainerCDN) {
    //   setBlobContainerCDN(blobContainerCDN)
    // }
    if (template) {
      const { bg, br, dc, hc, icon, iconB, message, mode, ps, title, url, wh } =
        template
      setFormData((prevState: any) => ({
        ...prevState,
        bg,
        br,
        dc,
        hc,
        icon,
        iconB,
        message,
        mode,
        ps,
        title,
        url,
        wh,
      }))
    }
    if (template_name) {
      setTemplate((prevState) => ({
        ...prevState,
        template_name,
        template_type,
        createdOn,
      }))
    }
  }, [selectedTemplate, campaignData])
  return (
    <div>
      <Form
        colon={false}
        labelAlign="left"
        labelCol={{ flex: "130px" }}
        layout="horizontal"
        form={form}
      >
        <TemplateLayout
          rightRatio={6}
          leftRatio={6}
          leftItems={
            <CampaignDevice
              campType="WEB_POPUP"
              intialDeviceType={deviceType}
              setDevice={(value: typeof deviceType) => setDeviceType(value)}
            >
              <WebPopUpContainer
                width={formData?.wh}
                title={formData?.title}
                message={formData?.message}
                uploadImage={handleImageUpload}
                borderRadius={formData?.br}
                messageTextColor={formData?.dc}
                headerTextColor={formData?.hc}
                backgroundColor={formData?.bg}
                buttonName={formData?.buttonName}
                buttonBackground={formData?.buttonBackground}
                buttonTextColor={formData?.buttonTextColor}
                isLoading={imageLoading}
                headerImg={formData.icon}
                mode={formData?.mode}
              />
            </CampaignDevice>
          }
          rightItems={
            <div>
              <Row className="pt-1">
                <Col span={24}>
                  <h5 className="mt-0 mb-2">{t("webPopUpCampaignLabel")}</h5>
                </Col>
                <Col span={24}>
                  <FormItem
                    className="my-0 mt-2 w-100"
                    name={"template_name"}
                    label=""
                  >
                    <Selector
                      // showSearch
                      onChange={(tempString) =>
                        dispatch(
                          fetchTemplateById({
                            app_id: appId,
                            api_key: loginUser.api_key,
                            template_id: JSON.parse(tempString)._id,
                          })
                        )
                      }
                      options={templates.map(
                        ({
                          template_name,
                          _id,
                        }: {
                          template_name: string
                          _id: string
                        }) => ({
                          label: template_name,
                          value: JSON.stringify({ _id, template_name }),
                        })
                      )}
                      placeholder={t("selectingExistingTemplateLabel")}
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row className="pt-2">
                <Col className="d-flex mt-2" span={24}>
                  <FormItem className="w-100 m-0  " label="Template Name">
                    <TextInput
                      onChange={(e: any) => {
                        setTemplate((prevState) => ({
                          ...prevState,
                          template_name: e.target.value,
                        }))
                      }}
                      placeholder={t("templateNameLabel")}
                      value={template.template_name}
                    />
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem
                    className="m-0 mt-3"
                    // name={"template_name"}
                    label={t("popUpTypeLabel")}
                  >
                    <Selector
                      onChange={(selectedValue: any) => {
                        setFormData((prevState: any) => ({
                          ...prevState,

                          mode: selectedValue,
                        }))
                      }}
                      value={formData?.mode}
                      options={[
                        { value: "Box", label: t("boxLabel") },
                        { value: "Banner", label: t("bannerLabel") },
                        { value: "Interstitial", label: t("intersitialLabel") },
                        { value: "ImageOnly", label: t("imageOnlyLabel") },
                      ]}
                    />
                  </FormItem>
                </Col>
                {formData?.mode !== "ImageOnly" && (
                  <>
                    <Col className="d-flex mt-2" span={24}>
                      <FormItem className="w-100 m-0  " label="Title">
                        <TextInput
                          onChange={(e: any) => {
                            setFormData((prevState: any) => ({
                              ...prevState,
                              title: e.target.value,
                            }))
                          }}
                          value={formData?.title}
                        ></TextInput>
                      </FormItem>
                    </Col>
                    <Col className="d-flex mt-2" span={24}>
                      <FormItem
                        className="w-100 m-0  "
                        label={t("messageLabel")}
                      >
                        <CustomTextArea
                          onChange={(e: any) => {
                            setFormData((prevState: any) => ({
                              ...prevState,
                              message: e.target.value,
                            }))
                          }}
                          value={formData?.message}
                        ></CustomTextArea>
                      </FormItem>
                    </Col>
                    <Col className="d-flex mt-2" span={24}>
                      <FormItem
                        className="w-100 m-0  "
                        label={t("buttonNameLabel")}
                      >
                        <TextInput
                          onChange={(e: any) => {
                            setFormData((prevState: any) => ({
                              ...prevState,

                              buttonName: e.target.value,
                            }))
                          }}
                          value={formData?.buttonName}
                        ></TextInput>
                      </FormItem>
                    </Col>
                  </>
                )}
                <Col className="d-flex " span={24}>
                  <FormItem
                    className=" w-100 m-0 mt-3"
                    label={t("redirectUrlLabel")}
                  >
                    <TextInput
                      onChange={(e: any) => {
                        setFormData((prevState: any) => ({
                          ...prevState,
                          url: e.target.value,
                        }))
                      }}
                      value={formData?.url}
                    ></TextInput>
                  </FormItem>
                </Col>

                {/* <Col span={24}>
                  <FormItem
                    className="m-0 mt-3"
                    // name={"template_name"}
                    label="Pop up position"
                  >
                    <Selector
                      options={[
                        { value: "Top-right", label: "Top-right" },
                        { value: "Top-left", label: "Top-left" },
                        { value: "Botton-right", label: "Botton-right" },
                        { value: "Bottom-left", label: "Bottom-left" },
                      ]}
                    />
                  </FormItem>
                </Col> */}
                <div className="d-flex ">
                  <Col span={12}>
                    <FormItem
                      className="m-0 mt-3"
                      // name={"template_name"}
                      label={t("borderRadiusLabel")}
                    >
                      <TextInput
                        onChange={(e: any) => {
                          setFormData((prevState: any) => ({
                            ...prevState,
                            br: e.target.value,
                          }))
                        }}
                        value={formData?.br}
                        type="number"
                      />
                    </FormItem>
                  </Col>
                  <Col span={12} className="ps-4">
                    <FormItem
                      className="m-0 mt-3"
                      // name={"template_name"}
                      labelCol={{ flex: "80px" }}
                      label={t("widthLabel")}
                    >
                      <TextInput
                        onChange={(e: any) => {
                          setFormData((prevState: any) => ({
                            ...prevState,
                            wh: e.target.value,
                          }))
                        }}
                        value={formData?.wh}
                        type="number"
                      />
                    </FormItem>
                  </Col>
                </div>

                <Col className="d-flex mt-3 " span={24}>
                  <FormItem
                    className="w-100 m-0  in-app-notification-container-text-field-color"
                    label={t("colorsLabel")}
                  >
                    <div className="d-flex  justify-content-between">
                      <FormItem className="text-center">
                        <TextInput
                          onChange={(e: any) => {
                            setFormData((prevState: any) => ({
                              ...prevState,

                              bg: e.target.value,
                            }))
                          }}
                          type="color"
                          className=""
                          style={{ maxWidth: "60px" }}
                          value={formData?.bg}
                        ></TextInput>
                        <p
                          style={{ fontSize: "12px" }}
                          className="m-0 p-0 mt-2"
                        >
                          {t("backgroundLabel")}
                        </p>
                      </FormItem>
                      <FormItem className="text-center">
                        <TextInput
                          onChange={(e: any) => {
                            setFormData((prevState: any) => ({
                              ...prevState,

                              hc: e.target.value,
                            }))
                          }}
                          type="color"
                          className=""
                          style={{ maxWidth: "60px" }}
                          value={formData?.hc}
                        ></TextInput>
                        <p
                          style={{ fontSize: "12px" }}
                          className="m-0 p-0 mt-2"
                        >
                          {t("headerTextLabel")}
                        </p>
                      </FormItem>
                      <FormItem className="text-center">
                        <TextInput
                          onChange={(e: any) => {
                            setFormData((prevState: any) => ({
                              ...prevState,

                              dc: e.target.value,
                            }))
                          }}
                          type="color"
                          className=""
                          value={formData?.dc}
                          style={{ maxWidth: "60px" }}
                        ></TextInput>
                        <p
                          style={{ fontSize: "12px" }}
                          className="m-0 p-0 mt-2"
                        >
                          {t("messageTextLabel")}
                        </p>
                      </FormItem>

                      <FormItem className="text-center">
                        <TextInput
                          onChange={(e: any) => {
                            setFormData((prevState: any) => ({
                              ...prevState,

                              buttonTextColor: e.target.value,
                            }))
                          }}
                          type="color"
                          className=""
                          style={{ maxWidth: "60px" }}
                          value={formData?.buttonTextColor}
                        ></TextInput>
                        <p
                          style={{ fontSize: "12px" }}
                          className="m-0 p-0 mt-2"
                        >
                          {t("buttonTextLabel")}
                        </p>
                      </FormItem>
                      <FormItem className="text-center">
                        <TextInput
                          onChange={(e: any) => {
                            setFormData((prevState: any) => ({
                              ...prevState,

                              buttonBackground: e.target.value,
                            }))
                          }}
                          type="color"
                          className=""
                          style={{ maxWidth: "60px" }}
                          value={formData?.buttonBackground}
                        ></TextInput>
                        <p
                          style={{ fontSize: "12px" }}
                          className="m-0 p-0 mt-2"
                        >
                          {t("buttonBackgroundLabel")}
                        </p>
                      </FormItem>
                    </div>
                  </FormItem>
                </Col>
              </Row>
            </div>
          }
          footerItem={
            <Row className="gap-2  d-flex justify-content-end">
              <PrimaryButton
                type="primary"
                htmlType="submit"
                onClick={() => {
                  selectedTemplate
                    ? handleTemplateUpdate()
                    : handleTemplateSave()
                }}
              >
                {selectedTemplate ? t("updateLabel") : t("saveLabel")}
              </PrimaryButton>
              <PrimaryButton
                style={{
                  color: "white",
                  background: "var( --color-red-main)",
                }}
                onClick={() => {
                  //reset campaign state in redux
                  dispatch(setCampaignData({}))
                  dispatch(setSelectedTemplate(null))
                  dispatch(setCurrentStep(0))
                }}
              >
                {t("closeLabel")}
              </PrimaryButton>
            </Row>
          }
        />
      </Form>
    </div>
  )
}
export default WebPopUp
