import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import centralApi from "../../../services/centralApi";
import { STATUS } from "../../constant";
const initialState:{data:any,status:any}={data:[],status:STATUS.IDLE}
export const fetchChildApps = createAsyncThunk(
  "app/childApps",
  async (params:any) => {
    return centralApi(
      "GET",
      "o/apps/getChildApps",
      null,
      params
    ).then((data) => { 
      return data;
    });
  }
);
const childAppsSlice = createSlice({
  name: "childApps",
  initialState,
  reducers: {
    setChildApps(state, action) {
      state.data=action.payload
    },

  },
  extraReducers: (builder) => {
    builder.addCase(fetchChildApps.pending, (state) => {
      state.status = STATUS.LOADING;
    });
    builder.addCase(fetchChildApps.fulfilled, (state,action) => {
      state.status = STATUS.IDLE;
      state.data=action.payload
    });
    builder.addCase(fetchChildApps.rejected, (state) => {
      state.status = STATUS.ERROR;
    });
  },
});
export const {setChildApps}=childAppsSlice.actions
export default childAppsSlice.reducer
