import { Avatar, AvatarProps } from 'antd';
import { FC } from 'react'
const CustomAvatar: FC<AvatarProps> = ({...props}) => {
  return (
    <Avatar {...props}>
        {props.children}
    </Avatar>
  )
}

export default CustomAvatar;