import { FC } from "react";
import "./Customer360Card.css";
import CustomHighChart from "components/Charts/CustomHighChart/CustomHighChart";
import { CONSTANTS } from "utils/constants/constants";
import { t } from "i18next";
import { HighChartTypeEnum } from "utils/enums";
interface HeaderCardProps {
  initialKey: string;
  secondaryKey: string;
  initialValue: string;
  secondaryValue: string;
  cardType: typeof CONSTANTS.HEADER;
}
interface OfferCardProps {
  title: string;
  icon: string;
  updatedOn: string;
  amount: string;
  cardType: typeof CONSTANTS.OFFER;
}
interface PortfolioChartProps {
  total: number;
  title: string;
  subTitle: string;
  data: any;
  colors: string[];
  cardType: typeof CONSTANTS.PORTFOLIO;
}

interface AccountEngagmentProps {
    color: string;
    value: string | number;
    label: string;
    cardType: typeof CONSTANTS.ACCOUNT_ENGAGEMENT;
}

const Customer360Card: FC<
  HeaderCardProps | OfferCardProps | PortfolioChartProps | AccountEngagmentProps> = ({ ...data }) => {
  const options =
    data.cardType == CONSTANTS.PORTFOLIO
      ? {
          colors: data.colors,
          chart: {
            type: HighChartTypeEnum.PIE,
            height: 260, // Set a fixed height for the container
            width: 260,
          },
          title: {
            text: `<div class="d-flex flex-column align-items-center">
      <span style='font-size:var(--font-size-sm);' >${data.title}</span><br/>
      <span style='color:var(--color-text-disabled-strong);font-size:var(--font-size-xsm);' class='c-three-sixty-portfolio-chart-subtitle'>${data.subTitle}</span>
    </div>`,
            verticalAlign: CONSTANTS.BOTTOM,
            style: {
              fontSize: "14px",
              y: -5,
            },
          },

          navigation: {
            buttonOptions: {
              enabled: false, // Set to false to hide the hamburger icon
            },
          },
          subtitle: {
            useHTML: true,
            text: `<div class="mb-5"><span style='font-size:14px;font-weight:bold;'>${data.total}</span></div>`,
            floating: true,
            verticalAlign: CONSTANTS.MIDDLE,
          },
          plotOptions: {
            pie: {
              allowPointSelect: false,
              dataLabels: {
                enabled: false,
              },
              showInLegend: false,
            },
          },

          series: [
            {
              innerSize: "60%",
              data: data.data,
            },
          ],
        }
      : {};
  return data.cardType == CONSTANTS.HEADER ? (
    <div className="py-2 d-flex flex-column gap-3 justify-content-center c-three-sixty-header-card p-3">
      <div className="d-flex justify-content-between gap-3">
        <span>{data?.initialKey}</span>
        <span>{data?.initialValue}</span>
      </div>
      <div className="d-flex justify-content-between gap-3">
        <span>{data?.secondaryKey}</span>
        <span>{data?.secondaryValue}</span>
      </div>
    </div>
  ) : data.cardType == CONSTANTS.OFFER ? (
    <div className="c-three-sixty-offer-card d-flex flex-column gap-1">
      <span className="c-three-sixty-card-strong-text text-center">
        {data.title}
      </span>
      <div className="c-three-sixty-offer-card-image-wrapper d-flex justify-content-center align-items-center">
        <img src={data.icon} alt="icon" />
      </div>
      <div className="d-flex flex-column gap-1 mt-2">
        <span className="c-three-sixty-card-strong-text">{t("newOfferLabel")}</span>
        <div className="d-flex justify-content-between ">
          <span>{t("amountLabel")}</span>
          <span className="c-three-sixty-card-strong-text">{data?.amount}</span>
        </div>
        <div className="d-flex justify-content-between ">
          <span>{t("updatedLabel")}</span>
          <span className="c-three-sixty-card-strong-text">
            {data?.updatedOn}
          </span>
        </div>
      </div>
    </div>
  ) : data.cardType === CONSTANTS.ACCOUNT_ENGAGEMENT ? (
    <div className="c-three-sixty-account-engagement-container">
    <div className="c-three-sixty-account-engagement-box-wrapper">
        <span className="mb-1">{data?.label}</span>
        <div className={`c-three-sixty-account-engagement-box ${data?.color === "#A8CAFF" ? 'blue-border' : 'pink-border'}`}>
            <h5 className="mb-0">{data?.value}</h5>
        </div>    
    </div>
    </div>
  ): (
    <div className="c-three-sixty-portfolio-chart">
      <CustomHighChart options={options} />
    </div>
  );
};

export default Customer360Card;
