import { Button, message } from 'antd';
import { useEffect } from 'react';
interface propType{
  content:string;
  type: "success" | "error";
}
const ShowMessage= ({content,type}:propType) => {
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: type,
      content: content,
      className: 'custom-class',
      style: {
        marginTop: '20vh',
      },
    });
  };
  useEffect(()=>{
    success()
  },[])

  return (
    <>
      {contextHolder}
    </>
  );
};

export default ShowMessage;