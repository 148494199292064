import { Divider } from "antd"
import "./SMS.css"
import PrimaryButton from "../../../../components/Buttons/PrimaryButton/PrimaryButton"
import { Form } from "antd"
import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import centralApi from "../../../../services/centralApi"
import { getToken } from "../../../../services/getToken"
import ShowMessage from "../../../../components/ShowMessage/ShowMessage"
import Spinner from "../../../../components/Spinner/Spinner"
import TextInput from "../../../../components/Inputs/TextInput/TextInput"
import React from "react"
import { t } from "i18next"
import Selector from "../../../../components/Selector/Selector"

interface RootState {
  loginUser: {
    data: {
      api_key: string
      // Other properties as needed
    }
  }
  activeApp: {
    appId: string
    // Other properties as needed
  }
  // Add other properties as needed
}

interface SMSProps {
  smsConfigUn: string
  smsConfigPs: string
  updateCurrentAppKey: () => void // Add void return type
}
const SMS: React.FC<SMSProps> = ({
  smsConfigUn,
  smsConfigPs,
  updateCurrentAppKey,
}) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const loginUser = useSelector(
    (state: RootState) => state.loginUser.data.api_key
  )
  const activeApp = useSelector((state: RootState) => state.activeApp.appId)

  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  })
  const appId = activeApp
  const api_key = loginUser
  const onFormSubmit = (values: any) => {
    const now = new Date()
    const isoString = now.toISOString()

    const payload = {
      args: JSON.stringify({
        app_id: activeApp,
        ...values,
        active: "gupshup",
        date: isoString,
      }),
      app_id: activeApp,

      api_key: loginUser,
    }
    saveSmsConfig(payload)
    updateCurrentAppKey()

    getToken(appId, api_key)
  }
  async function saveSmsConfig(formData: any) {
    const url: string = `/i/apps/saveSMSConfiguration`

    try {
      setLoading(true)
      await centralApi("POST", url, formData, null)
      setShowMessage({
        type: "success",
        message: t("sucessfullySavedLabel"),
        show: true,
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setShowMessage({
        type: "error",
        message: t("somethingWrongLabel"),
        show: true,
      })
    }
    hideMessage()
  }
  const hideMessage = () => {
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false })
    }, 2000)
  }
  useEffect(() => {
    getToken(loginUser, activeApp)
  }, [])
  useEffect(() => {
    // Set initial form values when currentAppKey changes
    const updatedFormValues = {
      username: smsConfigUn,
      password: smsConfigPs,
    }
    form.setFieldsValue(updatedFormValues)
  }, [smsConfigUn, smsConfigPs, form])
  return (
    <div className="sms-setting-container">
      <div className="mt-4 mb-4  ">
        <div className="d-flex align-items-baseline justify-content-between">
          <h6 className="sms-setting-container-heading">
            {t("smsSettingLabel")}
          </h6>
          <PrimaryButton
            size="middle"
            type="primary"
            htmlType="submit"
            // style={{ border: "none", width: "70px" }}
          >
            SMS Setting
          </PrimaryButton>
        </div>
        <Divider className="sms-setting-custom-divider" />

        {/* <h4 className=" mt-4 sms-setting-container-heading">{t("smsSettingHeadingLabel")}</h4> */}
        <div className="col-sm-7 mt-4 sms-setting-form-new">
          <Form.Item
            label="Primary Provider"
            name="primary"
            // rules={[{ required: true, message:t("plzSelectUserIdLabel") as string }]}
          >
            <Selector
              // onChange={handleDisableButton}
              size="middle"
              style={{ width: "100%" }}
              // defaultValue={"NONE"}
              options={[
                {
                  value: "Provider",
                  label: "Provider",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Secondary Provider"
            // name="password"
            // rules={[
            //   {
            //     required: true,
            //     message: t("plzSelectPasswordLabel") as string,
            //   },
            // ]}
          >
            <Selector
              // onChange={handleDisableButton}
              size="middle"
              style={{ width: "100%" }}
              // defaultValue={"NONE"}
              options={[
                {
                  value: "Provider",
                  label: "Provider",
                },
              ]}
            />
          </Form.Item>
        </div>
        <Divider className="sms-setting-custom-divider" />
        <h4 className=" mt-4 sms-setting-container-heading">
          {t("smsSettingHeadingLabel")}
        </h4>

        <div>
          <div className=" col-sm-7 mt-4 align-items-center sms-setting-form ">
            {showMessage.show && (
              <ShowMessage
                type={showMessage.type}
                content={showMessage.message}
              />
            )}
            {loading ? (
              <Spinner />
            ) : (
              <Form
                form={form}
                layout="horizontal"
                initialValues={{
                  username: smsConfigUn,
                  password: smsConfigPs, // Access properties from smsConfigPm
                }}
                // onValuesChange={onFormValueChange}
                // requiredMark={journeyData}
                onFinish={onFormSubmit}
              >
                <Form.Item
                  label={t("userIdLabel")}
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: t("plzSelectUserIdLabel") as string,
                    },
                  ]}
                >
                  <TextInput />
                </Form.Item>
                <Form.Item
                  label={t("passwordLabel")}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t("plzSelectPasswordLabel") as string,
                    },
                  ]}
                >
                  <TextInput />
                </Form.Item>

                <div className="">
                  <PrimaryButton
                    size="middle"
                    type="primary"
                    htmlType="submit"
                    style={{ border: "none", width: "70px" }}
                  >
                    {t("saveLabel")}
                  </PrimaryButton>
                </div>
              </Form>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default SMS
