import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import centralApi from "../../../services/centralApi";
import { STATUS } from "../../constant";
import { changeAudienceDataFormat } from "helperFunctions/audience";
import { segmentType } from "@customTypes/audience";
import { v4 as uuid } from "uuid";
import { AudienceEnum } from "utils/enums";
const initialState: { data: any; status: any } = {
  data: {
    name: "",
    description: "",
    range: 0,
    objectid: "",
    //fields for custom segment****
    customUpload:undefined,
    File:undefined,
    datatype:undefined,
    fileheader:undefined,
    csvFile_id:undefined,
    audid:undefined,
    email:undefined,
    clientid:undefined,
    did:undefined,
    //**** */
    segmentinfo: {
      who: [],
      what: [],
      when: [],
      where: [],
      cTrait:[],
      q: "",
      userQ: "",
    },
  },
  status: STATUS.IDLE,
};
//fetch the audience segment using id (used when the page is edit)
export const fetchAudienceSegmentById = createAsyncThunk(
  "audience/fetchAudienceSegmentById",
  async ({ api_key, app_id, aud_id }: any) => {
    const params = {
      api_key,
      app_id,
      aud_id,
    };
    return centralApi("GET", "o/aud/getAudienceSegmentById", null, params).then(
      ({ result }) => {
        return result;
      }
    );
  }
);
const audienceSegmentDataSlice = createSlice({
  name: "audienceSegmentData",
  initialState,
  reducers: {
     // Action creator to set audience segmentinfo data
     setAudienceSegmentInfo(state, action: {payload: {
      segmentType: segmentType;  // Type of segment (who ,what ..etc)
      category: string;  // Category of the segment
      indexToModify: number;  // Index of the segment to modify
      data: any  // New data for the segment
    }}) {
      // Destructure payload
      const { segmentType, category, indexToModify, data } = action.payload;
      // Get the segment info based on segmentType
      const segmentInfo = state.data.segmentinfo[segmentType];
      // Update state immutably based on segmentType
      if (segmentType == AudienceEnum.WHO) {
        // Find the index of the category
        const categoryIndex = segmentInfo.findIndex(
          (catInfo: any) => catInfo.category === category
        );
        // Use immer to update state immutably
        if (categoryIndex !== -1) {
          // Copy the categoryInfo object
          const categoryInfo: any = segmentInfo[categoryIndex];
          // Update the child with the new value
          categoryInfo.children[indexToModify] = {...categoryInfo.children[indexToModify],...data};
          state.data.segmentinfo[segmentType][categoryIndex] = categoryInfo;
        }
      } else if (segmentType == AudienceEnum.WHAT) {
        // Update the segment directly for "what" type
        state.data.segmentinfo[segmentType][indexToModify] = {
          ...state.data.segmentinfo[segmentType][indexToModify],
          ...data,
        };
      }
    },
    setCategory(
      state,
      action: {
        payload: {
          segmentType: segmentType;
          category: string;
          indexToModify: number;
        };
      }
    ) {
      // Check if the category doesn't already exist in the segmentType array
      if (
        !state.data.segmentinfo[action.payload.segmentType].find(
          ({ category }: { category: string }) =>
            category == action.payload.category
        )
      ) {
        // If the category doesn't exist, add a new category object
        state.data.segmentinfo[action.payload.segmentType][
          action.payload.indexToModify
        ] = {
          ...state.data.segmentinfo[action.payload.segmentType][
            action.payload.indexToModify
          ],
          category: action.payload.category,
          children: [
            {
              id:uuid(),
              operand: undefined,
              operator: undefined,
              e_operator: AudienceEnum.AND,
              value: undefined,
            },
          ],
        };
      } else if (
        state.data.segmentinfo[action.payload.segmentType]?.length > 1
      ) {
        // If the category already exists and there is more than one category, remove the existing category
        const indexToRemove = state.data.segmentinfo[
          action.payload.segmentType
        ].findIndex(
          (catInfo: any) => catInfo.category === action.payload.category
        );
        state.data.segmentinfo[action.payload.segmentType].splice(
          indexToRemove+1,
          1
        );
      }
    },
    
    addSegment(state, action:{
      payload: {
        segmentType: segmentType; // Define the possible values for segmentType
        category?: string;
        children?:any,
        data?:any
      }
    }) {
      if (action.payload.children && action.payload.category) {
        state.data.segmentinfo[action.payload.segmentType] =
          state.data.segmentinfo[action.payload.segmentType].map(
            (userTrait: any) => {
              if (userTrait.category === action.payload.category) {
                // Update the children array of the found userTrait
                return {
                  ...userTrait,
                  children: [
                    ...userTrait.children,
                    { id: uuid(), ...action.payload.children },
                  ],
                };
              } else {
                return userTrait;
              }
            }
          );
      } else {
        state.data.segmentinfo[action.payload.segmentType] = [
          ...state.data.segmentinfo[action.payload.segmentType],
          { id:action.payload.segmentType!==AudienceEnum.CTRAIT?uuid():undefined, ...action.payload.data },
        ];
      }
    },
    /**
 * Removes a segment or a child segment from the state based on the provided action payload.
 * If isChild is true, it removes the specified child segment from the segment's children array.
 * If isChild is false or not provided, it removes the specified segment from the segmentType array.
 */
removeSegment(state, action:{
  payload: {
    segmentType: segmentType; 
    category?: string;
    indexToRemove: number;
    isChild?:boolean
  }
}) {
  if (action.payload.isChild) {
    // Remove the specified child segment
    state.data.segmentinfo[action.payload.segmentType] =
      state.data.segmentinfo[action.payload.segmentType].map(
        (segment: any) => {
          if (segment.category === action.payload.category) {
            // Filter out the item to be removed from the children array
            const updatedChildren = segment.children.filter(
              (_: any, index: number) =>
                index !== action.payload.indexToRemove
            );

            return {
              ...segment,
              children: updatedChildren,
            };
          } else {
            return segment;
          }
        }
      );
  } else {
    // Remove the specified segment
    state.data.segmentinfo[action.payload.segmentType].splice(
      action.payload.indexToRemove,
      1
    );
  
  }
},
    setSegmentData(state,action){
      state.data={...state.data,...action.payload}

    },
    resetSegment() {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchAudienceSegmentById.pending, (state) => {
      state.status = STATUS.LOADING;
    });
    builder.addCase(fetchAudienceSegmentById.fulfilled, (state, action) => {
      const modifiedSegInfo = changeAudienceDataFormat(
        action.payload.segmentinfo,
        "incoming"
        );
        state.status = STATUS.IDLE;
        const updatedPayload = { ...action.payload };
        delete updatedPayload.createdate;
        delete updatedPayload.isDeleted;
      state.data = { ...updatedPayload,segmentinfo: modifiedSegInfo };
    });
    builder.addCase(fetchAudienceSegmentById.rejected, (state) => {
      state.status = STATUS.ERROR;
    });
  },
});
export const {
  setAudienceSegmentInfo,
  addSegment,
  removeSegment,
  setCategory,
  resetSegment,
  setSegmentData
} = audienceSegmentDataSlice.actions;
export default audienceSegmentDataSlice.reducer;
