import React, { FC, useCallback, useEffect, useState } from "react";
import BeefreeEditor from "../../../../../../components/BeefreeEditor/BeefreeEditor";
import Selector from "../../../../../../components/Selector/Selector";
import { Col, Form, Row } from "antd";
import FormItem from "antd/es/form/FormItem";
import TextInput from "../../../../../../components/Inputs/TextInput/TextInput";
import { useDispatch, useSelector } from "react-redux";
import centralApi from "../../../../../../services/centralApi";
import {
  formatDate,
  getCurrentDate,
} from "../../../../../../helperFunctions/date";
import { setCampaignData } from "../../../../../../redux/features/campaign/campaignDataSlice";
import { setCurrentStep } from "../../../../../../redux/features/campaign/campaignCreateCurrentStepSlice";
import { getToken } from "../../../../../../services/getToken";
import { STATUS } from "../../../../../../redux/constant";
import { logoutUser } from "redux/features/user/loginUserSlice";
import { getFeaturesSettings } from "redux/features/app/featuresSettingSlice";

interface EmailProps {}

const Email: FC<EmailProps> = ({}) => {
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const [templates, setTemplates] = useState([]);
  const dispatch: any = useDispatch();
  const currentStep = useSelector(
    (state: any) => state.campaignCreateCurrentStep
  );
  const campaignData = useSelector((state: any) => state.campaignData.data);
  const [selectedTemplate, setSelectedTemplate] = useState<any>(campaignData?.data || null);
  const [formData, setFormData] = useState({
    template_name: campaignData?.data?.template_name || selectedTemplate?.template_name || "",
    subjectData: campaignData?.data?.template?.subjectData || selectedTemplate?.template?.subjectData || "",
    template_type: "EMAIL",
  });
  const features = useSelector((state: any) => state.featuresSetting.data);
  const featuresSetting = features[0]?.features;
  // fetch all templates
  const fetchTemplates = async () => {
    try {
      const params = {
        app_id: appId,
        api_key: loginUser.api_key,
        template_type: "EMAIL",
      };
      const { result } = await centralApi(
        "GET",
        "o/templates/getTemplatesByType",
        null,
        params
      );
      setTemplates(result);
    } catch (error:any) {
      // if user is unauthorized  logout user
      if(error?.response?.status===401){
      dispatch(logoutUser())}
    }
  };
  // fetch a single template by id
  const fetchTemplateById = async (templateId: string) => {
    try {
      const params = {
        app_id: appId,
        api_key: loginUser.api_key,
        template_id: templateId,
      };
      const result = await centralApi(
        "GET",
        "o/templates/getTemplatesById",
        null,
        params
      );
      dispatch(
        setCampaignData({
          ...campaignData,
          data: result,
          tid: result?._id,
          t: result?.template_type,
        })
      );
    } catch (error:any) {
      // if user is unauthorized  logout user
      if(error?.response?.status===401){
        dispatch(logoutUser())}
    }
  };

  const onSave = useCallback(
    async (jsonFile: string, htmlFile: string) => {
      try {
        const tempInput: any = document.getElementById("email_template_name");
        const subInput: any = document.getElementById(
          "email_template_subject_data"
        );
        const data = {
          template_name: tempInput?.value,
          template_type: formData?.template_type,
          template: {
            subjectData: subInput?.value,
            bodyHtmlData: htmlFile,
            jsonData: jsonFile,
          },
          isTemplateDeleted: "false",
          createdOn: selectedTemplate?._id
            ? undefined
            : formatDate(getCurrentDate(), "DD/MM/YYYY"),
          modifiedOn: selectedTemplate?._id
            ? formatDate(getCurrentDate(), "DD/MM/YYYY")
            : undefined,
        };
        const payload: any = {
          api_key: loginUser?.api_key,
          app_id: appId,
          args: JSON.stringify(data),
        };
        const url = selectedTemplate?._id
          ? "/i/templates/update"
          : "/i/templates/create";
        if (selectedTemplate?._id) {
          payload["template_id"] = selectedTemplate?._id;
        }
        const result = await centralApi("POST", url, payload);
        dispatch(
          setCampaignData({
            ...campaignData,
            data: result,
            tid: result?._id,
            t: result?.template_type,
          })
        );
        dispatch(setCurrentStep(currentStep + 1));
      } catch (error:any) {
      // if user is unauthorized  logout user

        if(error?.response?.status===401){
          dispatch(logoutUser())
        }
      }
    },
    [formData]
  );
  useEffect(() => {
    if (loginUser){

      fetchTemplates();
      dispatch(
        getFeaturesSettings({
          api_key: loginUser.api_key,
          app_id: appId,
        })
      );
      getToken(appId, loginUser?.api_key);
    } 
  }, [loginUser]);
  useEffect(() => {
    if (campaignData?.data) {
      setSelectedTemplate(campaignData?.data)
      setFormData((prevState) => ({
      ...prevState,
      template_name: campaignData?.data?.template_name,
      subjectData: campaignData?.data?.template?.subjectData,
    }));
  }
  }, [campaignData]);
  return (
    <div>
      <div className="d-flex justify-content-between">
        <h5>Email</h5>
        <FormItem className="w-25 m-0">
          <Selector
          showSearch
          onChange={(tempString) => fetchTemplateById(JSON.parse(tempString)._id)}
            options={templates.map(({ template_name, _id }) => ({
              label: template_name,
              value:JSON.stringify({_id,template_name}),
            }))}
            placeholder="Select existing template"
          />
        </FormItem>
        {/* <SegmentedCustom
          onChange={(value: typeof type) => {
            setType(value);
          }}
          value={type}
          options={[
            { label: "New", value: "new" },
            { label: "Existing", value: "existing" },
          ]}
        /> */}
      </div>
      <Form colon={false} layout="vertical">
        <Row
          gutter={20}
          wrap
          className="py-3 position-relative d-flex justify-content-center"
        >
          <Col className="d-flex" span={12}>
            <FormItem className="w-100 m-0" label="Template name">
              <TextInput
                id="email_template_name"
                value={formData.template_name}
                onChange={(e: any) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    template_name: e.target.value,
                  }))
                }
              />
            </FormItem>
          </Col>
          <Col className="d-flex " span={12}>
            <FormItem className="w-100 m-0  " label="Email subject">
              <TextInput
                id="email_template_subject_data"
                value={formData.subjectData}
                onChange={(e: any) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    subjectData: e.target.value,
                  }))
                }
              />
            </FormItem>
          </Col>
        </Row>
      </Form>
      {!featuresSetting?.email_editor &&(
      <BeefreeEditor
        style={{ height: "70vh" }}
        options={{
          existingTemplate: selectedTemplate?.template?.jsonData,
          workspace: { onSave },
        }}
      />)}
    </div>
  );
};

export default React.memo(Email);
