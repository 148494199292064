"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Decryption = void 0;
/**
 * The Decryption class provides methods for performing cryptographic operations such as decryption
 */
class Decryption {
    constructor(cipherText, key) {
        this.cipherText = cipherText;
        this.key = key;
    }
    /**
     * Converts an ArrayBuffer to a hexadecimal string.
     * @param buffer The ArrayBuffer to convert.
     * @returns The hexadecimal representation of the buffer.
    */
    arrayBufferToHex(buffer) {
        return [...new Uint8Array(buffer)]
            .map(b => b.toString(16).padStart(2, '0'))
            .join('');
    }
    /**
     * Converts a hexadecimal string to an ArrayBuffer.
     * @param hex The hexadecimal string to convert.
     * @returns The ArrayBuffer representation of the hex string.
    */
    hexToArrayBuffer(hex) {
        const bytes = new Uint8Array(hex.length / 2);
        for (let i = 0; i < hex.length; i += 2) {
            bytes[i / 2] = parseInt(hex.substring(i, i + 2), 16);
        }
        return bytes.buffer;
    }
    /**
     * Generates a random 16-byte Initialization Vector (IV) and returns it as a hexadecimal string.
     * @returns A hexadecimal string representing the random IV.
    */
    generateRandomIV16() {
        const iv = crypto.getRandomValues(new Uint8Array(16));
        return this.arrayBufferToHex(iv);
    }
    /**
      * Encodes an ArrayBuffer to a Base64 string.
      * @param buffer The ArrayBuffer to encode.
      * @returns The Base64 encoded string.
    */
    base64Encode(buffer) {
        return btoa(String.fromCharCode(...new Uint8Array(buffer)));
    }
    /**
      * Decodes a Base64 string to an ArrayBuffer.
      * @param base64 The Base64 encoded string to decode.
      * @returns The ArrayBuffer representation of the Base64 string.
    */
    base64Decode(base64) {
        return Uint8Array.from(atob(base64), c => c.charCodeAt(0)).buffer;
    }
    /**
    * Computes the SHA-256 hash of a given message and returns it as a hexadecimal string.
    * @param message The message to hash.
    * @returns A promise that resolves to the hexadecimal representation of the SHA-256 hash.
    */
    async sha256(message) {
        const msgBuffer = new TextEncoder().encode(message);
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
        return this.arrayBufferToHex(hashBuffer);
    }
    /**
       * Imports a raw key data string and returns it as a CryptoKey for AES-CBC encryption/decryption.
       * @param keyData The raw key data as a string.
       * @returns A promise that resolves to the imported CryptoKey.
     */
    async importKey(keyData) {
        const keyBuffer = new TextEncoder().encode(keyData);
        return await crypto.subtle.importKey('raw', keyBuffer, 'AES-CBC', false, ['encrypt', 'decrypt']);
    }
    /**
       * Encrypts a plain text string using AES-CBC with a specified key and IV, and returns the result as a Base64 string.
       * @param plainText The text to encrypt.
       * @param encryptionKey The key used for encryption.
       * @param iv The Initialization Vector (IV) as a hexadecimal string.
       * @returns A promise that resolves to the Base64 encoded encrypted text.
      */
    async encrypt(plainText, encryptionKey, iv) {
        const key = await this.importKey(encryptionKey);
        const ivBuffer = this.hexToArrayBuffer(iv);
        const encryptedBuffer = await crypto.subtle.encrypt({ name: 'AES-CBC', iv: ivBuffer }, key, new TextEncoder().encode(plainText));
        return this.base64Encode(encryptedBuffer);
    }
    /**
       * Decrypts a Base64 encoded ciphertext using AES-CBC with a specified key and IV, and returns the result as a plain text string.
       * @param cipherText The Base64 encoded ciphertext to decrypt.
       * @param encryptionKey The key used for decryption.
       * @param iv The Initialization Vector (IV) as a hexadecimal string.
       * @returns A promise that resolves to the decrypted plain text.
       */
    async decrypt(cipherText, encryptionKey, iv) {
        const key = await this.importKey(encryptionKey);
        const ivBuffer = this.hexToArrayBuffer(iv);
        const decryptedBuffer = await crypto.subtle.decrypt({ name: 'AES-CBC', iv: ivBuffer }, key, this.base64Decode(cipherText));
        return new TextDecoder().decode(decryptedBuffer);
    }
    /**
       * Decrypts the ciphertext stored in this.cipherText using a randomly generated IV.
       * Assumes the IV is stored in the first 32 hexadecimal characters of this.cipherText.
       * Computes the key by hashing this.key with SHA-256 and using the first 32 characters.
       * @returns A promise that resolves to the decrypted plain text.
     */
    async decryptWithRandomIV() {
        const iv = this.cipherText.slice(0, 32); // extract the IV (first 32 hex chars => 16 bytes)
        const actualCipherText = this.cipherText.slice(32); // the rest is the actual ciphertext
        const encryptionKey = await this.sha256(this.key).then(hash => hash.substring(0, 32));
        return await this.decrypt(actualCipherText, encryptionKey, iv);
    }
}
exports.Decryption = Decryption;
