import React, { FC, useState } from "react";
import CustomCollapse from "../../../../../../components/CustomCollapse/CustomCollapse";
import BeefreeEditor from "../../../../../../components/BeefreeEditor/BeefreeEditor";
import Selector from "../../../../../../components/Selector/Selector";
import { Col, Form, Row } from "antd";
import FormItem from "antd/es/form/FormItem";
import TextInput from "../../../../../../components/Inputs/TextInput/TextInput";
import SegmentedCustom from "../../../../../../components/SegmentedCustom/SegmentedCustom";

interface LandingPageProps {}

const LandingPage: FC<LandingPageProps> = ({}) => {
  const [type,setType]=useState("new")
  return (
    <div>
      <div className="d-flex justify-content-between">
        <h5>Landing Page</h5>
        <SegmentedCustom 
        onChange={(value: typeof type)=>{setType(value)}}
        value={type}
        options={[
            {label:"New",value:"new"},
            {label:"Existing",value:"existing"},
        ]}/>
      </div>
      <Form colon={false} layout="vertical">
       {type=="existing"?<Row className="pt-3">
          <Col span={24}>
            <FormItem className="w-50" label="Select existing template">
              <Selector
                //   onChange={(id) => fetchTemplateById(id)}
                //   options={templates.map(({ template_name, _id }) => ({
                //       label: template_name,
                //       value: _id,
                //     }))}
                placeholder="Select existing template"
              />
            </FormItem>
          </Col>
        </Row>:
        <Row
          gutter={20}
          wrap
          className="py-3 position-relative d-flex justify-content-center"
        >
          <Col  span={24}>
            <FormItem className="w-50 m-0  " label="Template name">
              <TextInput />
            </FormItem>
          </Col>
        </Row>}
      </Form>
      <BeefreeEditor style={{ height: "70vh" }} />
    </div>
  );
};

export default LandingPage;
