import { Divider } from "antd";
import "./PushNotificationSetting.css";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { Form } from "antd";
import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import centralApi from "../../../../services/centralApi";
import { getToken } from "../../../../services/getToken";
import ShowMessage from "../../../../components/ShowMessage/ShowMessage";
import Spinner from "../../../../components/Spinner/Spinner";
import TextInput from "../../../../components/Inputs/TextInput/TextInput";
import { t } from "i18next";
import Selector from "components/Selector/Selector";

interface PushNotificationSettingProps {
  currentAppKey: any;
  updateCurrentAppKey: () => void;
}
interface RootState {
  loginUser: {
    data: {
      api_key: string;
    };
  };
  activeApp: {
    appId: string;
  };
}

const PushNotificationSetting: React.FC<PushNotificationSettingProps> = ({
  currentAppKey,
  updateCurrentAppKey,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const loginUser = useSelector(
    (state: RootState) => state.loginUser.data.api_key
  );
  const activeApp = useSelector((state: RootState) => state.activeApp.appId);
  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  });
  const appId = activeApp;
  const api_key = loginUser;
  const onFormSubmit = (values: any) => {
    const payload = {
      args: JSON.stringify({
        ...values,

        app_id: appId,
      }),

      app_id: appId,
      api_key: api_key,
    };
    savePushCharLimit(payload);
    updateCurrentAppKey();
    getToken(appId, api_key);
  };

  async function savePushCharLimit(formData: any) {
    const url: string = `/i/apps/savePushCharLimit`;

    try {
      setLoading(true);
      await centralApi("POST", url, formData, null);
      setShowMessage({
        type: "success",
        message: "Successfully Saved",
        show: true,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setShowMessage({
        type: "error",
        message: "Something went wrong",
        show: true,
      });
    }
    hideMessage();
  }
  const hideMessage = () => {
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false });
    }, 2000);
  };

  useEffect(() => {
    getToken(appId, api_key);
  }, []);

  useEffect(() => {
    // Set initial form values when currentAppKey changes
    const updatedFormValues = {
      hl: currentAppKey[0]?.pushNotificationCharLimit?.hl,
      dl: currentAppKey[0]?.pushNotificationCharLimit?.dl,
      edl: currentAppKey[0]?.pushNotificationCharLimit?.edl,
      getAppDropdown : currentAppKey[0]?.getAppEnabled
    };
    form.setFieldsValue(updatedFormValues);
  }, [currentAppKey, form]);
  return (
    <div className="push-notification-setting-container">
      <div className="mt-4 mb-4  ">
        <h6 className="push-notification-setting-container-heading">
         {t("pushNotificationSettingLabel")}
        </h6>
        <Divider className="push-notification-setting-custom-divider" />
        <div>
          <div className="  mt-4 align-items-center push-notification-setting-form ">
            <div className="mb-4">
              <div className=" col-sm-8 mt-4 align-items-center push-notification-setting-form ">
                <p>
                  Below inputs are for setting character limits for push
                  notifications.
                </p>
                {showMessage.show && (
                  <ShowMessage
                    type={showMessage.type}
                    content={showMessage.message}
                  />
                )}
                {loading ? (
                  <Spinner />
                ) : (
                  <Form form={form} layout="horizontal" onFinish={onFormSubmit}>
                    <Form.Item
                      label= {t("headerLimitLabel")}
                      name={"hl"}
                      rules={[
                        {
                          required: true,
                          message: t("plzInputLabel") as string,
                        },
                      ]}
                    >
                      <TextInput />
                    </Form.Item>
                    <Form.Item
                      label={t("descriptionLimit")}
                      name={"dl"}
                      rules={[
                        {
                          required: true,
                          message: t("plzInputLabel") as string,
                        },
                      ]}
                    >
                      <TextInput />
                    </Form.Item>
                    <Form.Item
                      label= {t("expandedDescriptionList")}
                      name={"edl"}
                      rules={[
                        {
                          required: true,
                          message: t("plzInputLabel") as string,
                        },
                      ]}
                    >
                      <TextInput />
                    </Form.Item>
                    <Form.Item
                      label= {t("getAppEnablementLabel")}
                      name={"getAppDropdown"}
                      rules={[
                        {
                          required: true,
                          message: t("plzInputLabel") as string,
                        },
                      ]}
                    >
                       <Selector

                      options={[
                        {
                          label: t("enableLabel"),
                          value: true ,
                        },
                        {
                          label: t("disableLabel"),
                          value: false ,
                        },
                      ]}
                    />
                    </Form.Item>

                    <div className="">
                      <PrimaryButton
                        size="middle"
                        type="primary"
                        htmlType="submit"
                        style={{ border: "none", width: "70px" }}
                      >
                        {t("saveLabel")}
                      </PrimaryButton>
                    </div>
                  </Form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PushNotificationSetting;
