import { useState, useEffect, Fragment } from "react"
import "./AppNav.css"
import InnerNav from "../../../../components/InnerNav/InnerNav"
import type { MenuProps } from "antd"
import AppKeys from "../AppKeys&SDK/AppKeys"
import AppSettings from "../AppSettings/AppSettings"
import EmailNav from "../Email/EmailNav/EmailNav"
import SMS from "../SMS/SMS"
import Security from "../Security/Security"
import KillSwitch from "../KillSwitch/KillSwitch"
import Channel from "../Channel/Channel"
import AppInbox from "../AppInbox/AppInbox"
import FileEncryption from "../FileEncryption/FileEncryption"
import UtmSetting from "../UtmSetting/UtmSetting"
import PushNotificationSetting from "../PushNotificationSetting/PushNotificationSetting"
import Features from "../Features/Features"
import PushNotification from "../PushNotification/PushNotification"
import Partner from "../Partner/Partner"
import AllowList from "../AllowList/AllowList"
import { t } from "i18next"
import React from "react"
import CampaignAnalyticsNav from "../CampaignAnalytics/CampaignAnalyticsNav/CampaignAnalyticsNav"
import ApiGatewayNav from "../ApiGateway/ApiGatewayNav/ApiGatewayNav"
import CampaignConfigurator from "../CampaignConfigurator/CampaignConfigurator"
import SiteSettings from "../SiteSettings/SiteSettings"
import { AppNavSettingEnum } from "utils/enums"

interface AppNavProps {
  currentAppKey: string
  smsConfigUn: string
  smsConfigPs: string
  channelObj: string
  updateCurrentAppKey: any // Add this line
}
const AppNav: React.FC<AppNavProps> = ({
  currentAppKey,
  updateCurrentAppKey,
  smsConfigUn,
  smsConfigPs,
  channelObj,
}) => {
  const [appTabFilter, setAppTabFilter] = useState(AppNavSettingEnum.APP_KEYS)
  const [selectedComponent, setSelectedComponent] =
    useState<JSX.Element | null>(null)

  const onTabClick: MenuProps["onClick"] = (e:any) => {
    setAppTabFilter(e.key)
  }

  useEffect(() => {
    switch (appTabFilter) {
      case AppNavSettingEnum.APP_KEYS:
        setSelectedComponent(<AppKeys currentAppKey={currentAppKey} />)
        break
        case AppNavSettingEnum.API_GATEWAY:

        setSelectedComponent(
       
          <ApiGatewayNav currentAppKey={currentAppKey}
          updateCurrentAppKey={updateCurrentAppKey}
        />
        )
        break
      case AppNavSettingEnum.APP_SETTINGS:
        setSelectedComponent(<AppSettings currentAppKey={currentAppKey} />)
        break
      case AppNavSettingEnum.EMAIL:
        setSelectedComponent(
          <EmailNav
            currentAppKey={currentAppKey}
            updateCurrentAppKey={updateCurrentAppKey}
          />
        )
        break
      case AppNavSettingEnum.SMS:
        setSelectedComponent(
          <SMS
            smsConfigUn={smsConfigUn}
            smsConfigPs={smsConfigPs}
            updateCurrentAppKey={updateCurrentAppKey}
          />
        )
        break
      case AppNavSettingEnum.SECURITY:
        setSelectedComponent(
          <Security
            currentAppKey={currentAppKey}
            updateCurrentAppKey={updateCurrentAppKey}
          />
        )
        break
      case AppNavSettingEnum.KILL_SWITCH:
        setSelectedComponent(<KillSwitch />)
        break
      case AppNavSettingEnum.CHANNEL:
        setSelectedComponent(
          <Channel
            channelObj={channelObj}
            updateCurrentAppKey={updateCurrentAppKey}
          />
        )
        break
      case AppNavSettingEnum.APP_INBOX:
        setSelectedComponent(
          <AppInbox
            currentAppKey={currentAppKey}
            updateCurrentAppKey={updateCurrentAppKey}
          />
        )
        break
      case AppNavSettingEnum.FILE_ENCRYPTION:
        setSelectedComponent(
          <FileEncryption
            currentAppKey={currentAppKey}
            updateCurrentAppKey={updateCurrentAppKey}
          />
        )
        break
      case AppNavSettingEnum.UTM_SETTING:
        setSelectedComponent(
          <UtmSetting
            currentAppKey={currentAppKey}
            updateCurrentAppKey={updateCurrentAppKey}
          />
        )
        break
      case AppNavSettingEnum.PARTNER_SETTING:
        setSelectedComponent(
          <Partner
            currentAppKey={currentAppKey}
            updateCurrentAppKey={updateCurrentAppKey}
          />
        )
        break
      case AppNavSettingEnum.ALLOWLIST_SETTING:
        setSelectedComponent(
          <AllowList
            currentAppKey={currentAppKey}
            updateCurrentAppKey={updateCurrentAppKey}
          />
        )
        break
      case AppNavSettingEnum.PUSH_NOTIFICATION_SETTINGS:
        setSelectedComponent(
          <PushNotificationSetting
            currentAppKey={currentAppKey}
            updateCurrentAppKey={updateCurrentAppKey}
          />
        )
        break
      case AppNavSettingEnum.FEATURES:
        setSelectedComponent(<Features />)
        break
      case AppNavSettingEnum.PUSH_NOTIFICATION:
        setSelectedComponent(<PushNotification />)
        break
      case AppNavSettingEnum.CAMPAIGN_ANALYTICS:
        setSelectedComponent(
          <CampaignAnalyticsNav
            currentAppKey={currentAppKey}
            updateCurrentAppKey={updateCurrentAppKey}
          />
        )
      
        break
      
          case AppNavSettingEnum.CAMPAIGN_CONFIGURATOR:
          setSelectedComponent(
            <CampaignConfigurator
              currentAppKey={currentAppKey}
              // updateCurrentAppKey={updateCurrentAppKey}
            />
          )
          break
          case AppNavSettingEnum.SITE_SETTING:
          setSelectedComponent(
            <SiteSettings
            />
          )
          break
      // Add cases for other tabs
      default:
        setSelectedComponent(<div></div>) // Default empty component
    }
  }, [appTabFilter, currentAppKey])

  return (
    <Fragment>
      <div className="app-setting-container ">
        <div className="app-setting-nav">
          <InnerNav
            theme="light"
            onTabClick={onTabClick}
            selected={appTabFilter}
            menu={[
              {
                label: t("appKeysLabel"),
                key: AppNavSettingEnum.APP_KEYS,
              },
              {
                label: t("pushNotificationLabel"),
                key: AppNavSettingEnum.PUSH_NOTIFICATION,
              },
              {
                label: t("appSettingLabel"),
                key: AppNavSettingEnum.APP_SETTINGS,
              },
              {
                label: t("emailLabel"),
                key: AppNavSettingEnum.EMAIL,
              },
              {
                label: t("smsLabel"),
                key: AppNavSettingEnum.SMS,
              },
              {
                label: t("securityLabel"),
                key: AppNavSettingEnum.SECURITY,
              },
              {
                label: t("killSwitchLabel"),
                key: AppNavSettingEnum.KILL_SWITCH,
              },
              {
                label: t("channelLabel"),
                key: AppNavSettingEnum.CHANNEL,
              },
              {
                label: t("featuresLabel"),
                key: AppNavSettingEnum.FEATURES,
              },
              {
                label: t("appInboxSettingLabel"),
                key: AppNavSettingEnum.APP_INBOX,
              },
              {
                label: t("fileEncryptionSettingLabel"),
                key: AppNavSettingEnum.FILE_ENCRYPTION,
              },
              {
                label: t("pushNotificationSettingLabel"),
                key: AppNavSettingEnum.PUSH_NOTIFICATION_SETTINGS,
              },
              {
                label: t("utmSettingLabel"),
                key: AppNavSettingEnum.UTM_SETTING,
              },
              {
                label: t("partnerSettingLabel"),
                key: AppNavSettingEnum.PARTNER_SETTING,
              },
              {
                label: t("allowListSettingsLabel"),
                key: AppNavSettingEnum.ALLOWLIST_SETTING,
              },
              {
                label: t("campaignAnalyticsLabel"),
                key: AppNavSettingEnum.CAMPAIGN_ANALYTICS,
              },
            
              {
                label: t("campaignCongiguratorLabel"),
                key: AppNavSettingEnum.CAMPAIGN_CONFIGURATOR,
              },
              {
                label: t("siteSettingLabel"),
                key: AppNavSettingEnum.SITE_SETTING,
              },
            ]}
          />
        </div>
      </div>
      {selectedComponent}
    </Fragment>
  )
}
export default AppNav
