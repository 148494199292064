import apiRequest from "../services/apiRequest";
import { generateStrongRandomString } from "../helperFunctions/string";
import { getRandomNumber } from "helperFunctions/number";
export const getData = (apiKey: string, appId: string) => {
  const url: string = `/o/users/allUsers`;
  return apiRequest
    .get(url, {
      params: {
        appid: appId,
        args: JSON.stringify({ appid: appId }),
        api_key: apiKey,
      },
    })
    .then((res) => {
      const data = res.data.members.map((user: any) => {
        let role = "";
        if (user.user_role) {
          Object.keys(user.user_role).forEach((ele) => {
            user.user_role[ele].forEach((ele2: any) => {
              if (ele2 == appId) {
                role = ele;
              }
            });
          });
        }
        return {
          //restructuring the data
          Key: user._id,
          Email: user.email,
          Username: user.username,
          Created_On: new Date(user.createdOn).toLocaleDateString("en-GB"),
          Modified_On: new Date(user.modifiedOn).toLocaleDateString("en-GB"),
          Role: role,
          Change_Role: { _id: user._id, currentRole: role },
          Action: user._id,
          Status: {
            _id: user._id,
            isApproved: user.approvedAppids && user.approvedAppids.includes(appId) ? true : false,
            email: user.email,
          },
          Reset_Password: { _id: user._id, email: user.email },
          Unblock: {
            _id: user._id,
            email: user.email,
            isBlock: user.isblocked ? user.isblocked : false,
          },
        };
      });
      return data;
    })
    .catch((e) => {
      throw new Error(e.message);
    });
};

//ADD USER
// export const addUser = ({ email, role, apiKey, appId, loginUserId }: any,_csrf:string) => {
//   console.log("email",email)
//   const url: string = `/o/users/addUsersRole`;

//   return apiRequest
//     .post(url, {
//       appid: appId,
//       api_key: apiKey,
//       args: JSON.stringify({
//         modified_by: loginUserId,
//         appid: appId,
//         role: role,
//         email: email,

//       }),
//     },{
//       headers:{
//         "csrf-token":_csrf
//       }
//     })
//     .then((data) => {
//       return data;
//     })
//     .catch((e) => {
//       throw new Error(e.message);
//     });
// };
//DELETE USER
export const deleteRole = ({ userId, apiKey, appId, loginUserID }: any) => {
  const url: string = `/o/users/deleteUsersRole`;
  return apiRequest
    .get(url, {
      params: {
        args: JSON.stringify({
          userId: userId,
          modified_by: loginUserID,
          appid: appId,
        }),
        api_key: apiKey,
        uniqueNumber:getRandomNumber()
      },
    })
    .then((data) => {
      return data;
    })
    .catch((e) => {
      throw new Error(e.message);
    });
};
//GET APP USER
export const getAppUsers = ({
  pageData,
  search,
  type,
  platform,
  appId,
  apiKey,
}: any) => {
  const url: string = `/o/users/getAppUsers`;
  return apiRequest
    .get(url, {
      params: {
        app_id: appId,
        api_key: apiKey,
        uniqueNumber:getRandomNumber(),
        role: JSON.stringify("admin"),
        draw: pageData.current,
        start: pageData.pageSize * (pageData.current - 1),
        length: pageData.pageSize,
        "search[value]": search,
        type,
        platform,
      },
    })
    .then((res) => {
      const data:any = []
      res.data.data.forEach((user: any, index: number) => {
        data.push({

          //restructuring the data
          Key: index,
          User: user.did?user.did:"",
          User_Since: user.fs,
          Last_Seen: user.ls,
          of_Sessions: user.sc,
          Average_Duration: user.ad,
          Client_ID: user.ci,
        });
      });
      return { data, totalRecord: res.data.recordsTotal };
    })
    .catch((e) => {
      throw new Error(e.message);
    });
};
export const resetPassword = ({
  id,
  email,
  apiKey,
  appId,
  loginUserID,
}: any) => {
  const generatedPassword = generateStrongRandomString(6);
  const url: string = `/o/users/resetUserPassword`;
  return apiRequest
    .get(url, {
      params: {
        api_id: appId,
        args: JSON.stringify({
          field: generatedPassword,
          userId: id,
          appid: appId,
          modified_by: loginUserID,
          email: email,
        }),
        api_key: apiKey,
      },
    })
    .then((data) => {
      return { data, inputValue: generatedPassword };
    })
    .catch((e) => {
      return { error: e, inputValue: "0" };
    });
};
//unblock user
export const unblock = ({ id, email, apiKey, loginUserID }: any) => {
  const url: string = `/o/users/unblockUser`;
  return apiRequest
    .get(url, {
      params: {
        args: JSON.stringify({
          userId: id,
          modified_by: loginUserID,
          email: email,
        }),
        api_key: apiKey,
      },
    })
    .then((data) => {
      return { ...data, email: email };
    })
    .catch((e) => {
      throw new Error(e.message);
    });
};
//change user status
export const updateApproved = ({
  newState,
  userId,
  email,
  appId,
  apiKey,
  loginUserId,
}: any) => {
  const url: string = `/i/users/updateApproved`;
  const booleanState = newState == "true" ? true : false;

  return apiRequest
    .get(url, {
      params: {
        app_id: appId,
        args: JSON.stringify({
          user_id: userId,
          modified_by: loginUserId,
          isapproved: booleanState,
          email:email,  
        }),
        api_key: apiKey,
        uniqueNumber:getRandomNumber()
      },
      withCredentials: true,
    })
    .then((data) => {
      if (data) {
        return true;
      }
    })
    .catch((e) => {
      console.log("errrr",e)
      throw new Error(e.message);
    });
};

// update role of user
export const updateRole = ({
  id,
  newRole,
  appId,
  apiKey,
  loginUserID,
  loginUserEmail,
}: any) => {
  const url: string = `/o/users/updateRole`;
  return apiRequest
    .get(url, {
      params: {
        api_id: appId,
        args: JSON.stringify({
          appid: appId,
          userId: id,
          role: newRole,
          email: loginUserEmail,
        }),
        api_key: apiKey,
        modified_by: loginUserID,
      },
    })
    .then((data) => {
      if (data) {
        return true;
      }
    })
    .catch((e) => {
      throw new Error(e.message);
    });
};
// /Display Preference
export const getDisplayPreference = () => {
  const url: string = `/o/getDisplayPref`;
  return apiRequest
    .get(url)
    .then((res) => {
      return res.data.data;
    })
    .catch((e) => {
      throw new Error(e.message);
    });
};
// Upload Profile Image
export const uploadProfileImage = (params: any, csrf: string) => {
  const { user_image, file_name, user_image_id } = params;

  // Create a FormData object to hold the form data
  const formDataImage = new FormData();
  formDataImage.append("user_image", user_image);
  formDataImage.append("file_name", file_name);
  formDataImage.append("user_image_id", user_image_id);
  console.log(csrf);

  // Make the POST request to upload the image
  const url: string = "/users/images"; // API endpoint URL
  return apiRequest
    .post(url, formDataImage, {
      headers: {
        "Content-Type": "multipart/form-data", // Important: set the Content-Type header to indicate form data
        "csrf-token": csrf,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error.message);
    });
};
export const getTransactionalCampaign = ({
  app_id,
  status,
  cmpType,
  sd,
  ed,
  api_key,
  uniqueNumber,
}: any) => {
  const url: string = `/o/templates/getCampaignsByStatus`;
  return apiRequest
    .get(url, {
      params: {
        app_id: app_id,
        status: status,
        cmpType: cmpType,
        sd: sd,
        ed: ed,
        api_key: api_key,
        uniqueNumber: uniqueNumber,
        // _: apiRequest,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw new Error(e.message);
    });
};
