import { Checkbox, Form, FormInstance } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import "./AudienceEvent.css";
import filterIcon from "../../../../../images/filterIcon.svg";
import TimeWindow from "../WindowContainers/TimeWindow.tsx/TimeWindow";
import { useDispatch, useSelector } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import { t } from "i18next";
import Selector from "components/Selector/Selector";
import TextInput from "components/Inputs/TextInput/TextInput";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { setAudienceSegmentInfo } from "redux/features/audience/audienceSegmentDataSlice";
import FrequencyWindow from "../WindowContainers/FrequencyWindow/FrequencyWindow";
import AttributeWindow from "../WindowContainers/AttributeWindow/AttributeWindow";
import { AudienceEnum } from "utils/enums";
import { getJourneyTypeList } from "helperFunctions/events";
// Type definition for component props
type propType = {
  blockIndex: number; // Index of the component/block block
  form: FormInstance; // Form instance
  id: string; // ID of the component/block
};
const AudienceEvent: React.FC<propType> = ({ blockIndex, form, id }) => {
  // Redux state
  const segment = useSelector(
    (state: any) => state.audienceSegmentData.data.segmentinfo.what[blockIndex] // get segment information from Redux state
  );
  const eventList = useSelector((state: any) => state.eventList.data); // Selecting event list from Redux state
  const dispatch: any = useDispatch(); // Initializing useDispatch hook

  // Local state
  const [selectedJourney, setSelectedJourney] =
    useState<"AGL" | "PAPL" | "">(""); // State for selected journey
  const [attributeList, setAttributeList] = useState({intial:[],secondary:[]}); // State for attribute list
  const attributeIntialValue = {
    // Initial value for attributes
    attr: undefined,
    operator: undefined,
    value: undefined,
    e_operator: AudienceEnum.AND,
  };

  // Handle input change function
  const handleInputChange = (data: any) => {
    // Dispatching action to update audience segment information in Redux state
    let modifiedData=data
    if(data["attributes"] && data["attributes"]?.length==0){
      modifiedData={...data,attr:undefined,operator:undefined,value:undefined}
    }
    dispatch(
      setAudienceSegmentInfo({
        segmentType: AudienceEnum.WHAT, // Segment type
        indexToModify: blockIndex, // Index of the block to modify
        category: AudienceEnum.EVENTS, // Category of the segment
        data:modifiedData, // Data to update
      })
    );
  };

  // Filtering events by journey
  const eventsByJourney = useMemo(() => {
    let event_by_journey = [];
    if (selectedJourney !== "") {
      event_by_journey = eventList?.filter((event: any) => {
        return event.journey == selectedJourney;
      });
    } else {
      event_by_journey = eventList;
      
    }
    return event_by_journey?.map((event: any) => ({
      label: event.displayname, // Display name of the event
      value: event.event, // value of the event
      list: event.list, // List of attributes
    }));
  }, [selectedJourney]);
  // Updating attribute list when segment operand changes
  useEffect(() => {
    if(segment?.since?.event){
      eventsByJourney.forEach(({ value, list }: any) => {
        if (value == segment.since.event) {
           setAttributeList((prevState)=>({...prevState,intial:list}))
        }
      });
    }
  }, [segment?.since?.event]);
  useEffect(() => {
    eventList?.forEach(({ event, list }: any) => {
      if (event == segment.operand) {
         setAttributeList((prevState)=>({...prevState,secondary:list}))
      }
    });
  }, [segment?.operand]);

  

  return (
    <div className="audience-event-box">
      <div className="box-header-container mb-2 pb-2  d-flex align-items-center">
        <span>
          {segment?.isLive ? t("willPerformLabel") : t("hasPerformedLabel")}
        </span>
        <Form.Item
          rules={[{ required: true, message: "" }]}
          className="header-inputs"
          name={`category_${id}`}
          initialValue={
            segment?.isLive ? t("liveEventLabel") : t("pastEventLabel")
          }
        >
          <TextInput disabled />
        </Form.Item>
        <span>{t("fromJourneyLabel")}</span>
        <Form.Item className="header-inputs" name={`journey_${id}`}>
          <Selector
            onChange={(value) => {
              setSelectedJourney(value);
              // form.resetFields([`performedEvent[${id}]`]);
            }}
            placeholder={`${t("selectJourneyMsg")}`}
            options={getJourneyTypeList(eventList)}
          ></Selector>
        </Form.Item>
      </div>

      <div className="box-body-container mx-1  px-1">
        <div className="py-2 d-flex flex-wrap gap-md-4 gap-2 align-items-center w-100  ">
          <Form.Item
            className="event-inputs"
            name={`event_${id}`}
            initialValue={segment?.since?.event}
          >
            <Selector
              placeholder={t("selectEventMsg")}
              showSearch
              onChange={(value: string) =>
                handleInputChange({ since: {...segment?.since, event: value ,type:undefined,time:undefined,t_operand:undefined} })
              }
              options={eventsByJourney}
            />
          </Form.Item>
          <FrequencyWindow
            frequency={segment.frequency}
            setFrequency={(frequency) => handleInputChange({ frequency })}
          />
          {!segment.timeWindow ? (
            <PrimaryButton
              onClick={() => {
                handleInputChange({ timeWindow: {} });
              }}
              disabled={!segment?.since?.event}
              className="audience-w120px"
            >
              {t("timeWindowLabel")}
            </PrimaryButton>
          ) : (
            <TimeWindow
              setValue={(value) => {
                handleInputChange({ timeWindow: value });
              }}
              id={id}
              windowType={"intial"}
              form={form}
              value={segment?.timeWindow}
            ></TimeWindow>
          )}
        </div>
        <div className="d-flex flex-wrap  flex-column     py-2">
            {!segment?.since?.attributes || segment?.since?.attributes?.length == 0 ? (
              <PrimaryButton
                className="audience-w120px"
                onClick={() =>
                  handleInputChange({...segment,since:{ ...segment?.since, attributes: [attributeIntialValue] }})
                }
                disabled={attributeList?.intial?.length == 0}
              >
                {t("attributeLabel")}
              </PrimaryButton>
            ) : (
              segment?.since?.attributes.map((attribute: any, attrIndex: number) => {
                return (
                  <AttributeWindow
                    form={form}
                    id={`${id}_intial`}
                    key={`${id}_${attrIndex}_intial`}
                    totalAttributes={segment?.since?.attributes?.length}
                    setValue={(data) => {
                      let modifiedAttribute = [...segment.since.attributes];
                      if (data) {
                        modifiedAttribute[attrIndex] = data;
                      }
                      //remove the attribute object from attributes in redux
                      else {
                        modifiedAttribute.splice(attrIndex, 1);
                      }
                      handleInputChange({...segment,since:{...segment.since, attributes: modifiedAttribute }});
                    }}
                    index={attrIndex}
                    value={attribute}
                    attributes={attributeList?.intial}
                  />
                );
              })
            )}
            <div >
              {segment?.since?.attributes && segment?.since?.attributes?.length > 0 && (
                <>
                  {" "}
                  <PrimaryButton
                    onClick={() => {
                      handleInputChange({...segment,since:{
                        ...segment.since,
                        attributes: [
                          ...segment.since.attributes,
                          attributeIntialValue,
                        ],
                      }});
                    }}
                    type="link"
                  >
                    <span className="audience-color-primary">+ and</span>
                  </PrimaryButton>{" "}
                  <PrimaryButton
                    onClick={() => {
                      handleInputChange(
                        {...segment,since:{
                        ...segment.since,
                        attributes: [
                          ...segment.since.attributes,
                          { ...attributeIntialValue, e_operator: AudienceEnum.OR },
                        ],
                      }});
                    }}
                    type="link"
                  >
                    <span className="audience-color-primary">+ or</span>
                  </PrimaryButton>
                </>
              )}
            </div>
          </div>
        <div className="d-flex flex-column ">
          <div className="d-flex flex-wrap gap-md-4 gap-2 py-2">
            {(segment?.since?.have == AudienceEnum.H || segment?.since?.have !== AudienceEnum.HN) && (
              <PrimaryButton
                onClick={() => {
                  handleInputChange({ since: { ...segment.since, have: AudienceEnum.H } });
                }}
              >
                <img src={filterIcon} alt="" />
                <span className="mx-2 w-100">
                  {" "}
                  {t("andThenDidPerfromLabel")}{" "}
                </span>
              </PrimaryButton>
            )}
            {(segment?.since?.have == AudienceEnum.HN || segment?.since?.have !== AudienceEnum.H) && (
              <PrimaryButton
                onClick={() => {
                  handleInputChange({
                    since: { ...segment.since, have:AudienceEnum.HN },
                  });
                }}
              >
                <img src={filterIcon} alt="" />
                <span className="mx-2 w-100">
                  {" "}
                  {t("andThenDidNotPerfromLabel")}{" "}
                </span>
              </PrimaryButton>
            )}
            {(segment?.since?.have == AudienceEnum.H || segment?.since?.have == AudienceEnum.HN) && (
              <div className="d-flex align-items-center ">
                <Form.Item
                  rules={[{ required: true, message: "" }]}
                  className="event-inputs"
                  name={`operand_${id}`}
                  initialValue={segment?.operand}
                >
                  <Selector
                    showSearch
                    onChange={(value: string) => {
                      handleInputChange({
                        operand: value,
                        attributes: []
                      });
                    }}
                    options={eventsByJourney}
                    placeholder={`${t("selectEventMsg")}`}
                  />
                </Form.Item>
                <CloseOutlined
                  className="mx-2 mt-0 p-0"
                  onClick={() => {
                    handleInputChange({
                      since: { ...segment?.since, have: undefined },
                      attributes: [],
                      operand: undefined,
                    });
                    form.setFieldsValue({ [`operand_${id}`]: undefined });
                  }}
                />
                
              </div>
            )}
            <div className="d-flex flex-wrap gap-md-4 gap-2 align-items-center">
            {segment?.since?.time == undefined ? (
              <PrimaryButton
                onClick={() => {
                  handleInputChange({ since: { ...segment?.since, time: "" } });
                }}
                disabled={
                  !segment?.since?.event || !segment?.operand
                }
                className="audience-w120px"
              >
                {t("timeWindowLabel")}
              </PrimaryButton>
            ) : (
              <TimeWindow
              form={form}
                setValue={(value) => {
                  handleInputChange({ since: { ...segment?.since, ...value } });
                }}
                id={id}
                windowType={"secondary"}
                value={segment?.since}
              ></TimeWindow>
            )}
          </div>
          </div>
          <div className="d-flex flex-wrap  flex-column     py-2">
            {!segment.attributes || segment?.attributes?.length == 0 ? (
              <PrimaryButton
                className="audience-w120px"
                onClick={() =>
                  handleInputChange({ attributes: [attributeIntialValue] })
                }
                disabled={attributeList.secondary.length == 0}
              >
                {t("attributeLabel")}
              </PrimaryButton>
            ) : (
              segment.attributes.map((attribute: any, attrIndex: number) => {
                return (
                  <AttributeWindow
                    form={form}
                    id={`${id}_secondary`}
                    key={`${id}_${attrIndex}_secondary`}
                    totalAttributes={segment?.attributes?.length}
                    setValue={(data) => {
                      let modifiedAttribute = [...segment.attributes];
                      if (data) {
                        modifiedAttribute[attrIndex] = data;
                      }
                      //remove the attribute object from attributes in redux
                      else {
                        modifiedAttribute.splice(attrIndex, 1);
                      }
                      handleInputChange({ attributes: modifiedAttribute });
                    }}
                    index={attrIndex}
                    value={attribute}
                    attributes={attributeList.secondary}
                  />
                );
              })
            )}
            <div >
              {segment.attributes && segment?.attributes?.length > 0 && (
                <>
                  {" "}
                  <PrimaryButton
                    onClick={() => {
                      handleInputChange({
                        attributes: [
                          ...segment.attributes,
                          attributeIntialValue,
                        ],
                      });
                    }}
                    type="link"
                  >
                    <span className="audience-color-primary">+ and</span>
                  </PrimaryButton>{" "}
                  <PrimaryButton
                    onClick={() => {
                      handleInputChange({
                        attributes: [
                          ...segment.attributes,
                          { ...attributeIntialValue, e_operator: AudienceEnum.OR },
                        ],
                      });
                    }}
                    type="link"
                  >
                    <span className="audience-color-primary">+ or</span>
                  </PrimaryButton>
                </>
              )}
            </div>
          </div>
          

          {/* <div className="d-inline-block w-100 py-2">
            <Form.Item className="m-0" name={`isCheck_${id}`}>
              <Checkbox>
                <span>{t("limitToUsersLabel")}</span>
              </Checkbox>
            </Form.Item>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AudienceEvent;
