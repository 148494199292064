export const CONSTANTS = {
  WHO: "who",
  WHAT: "what",
  WHEN: "when",
  WHERE: "where",
  CUSTOM_VARS: "Custom Vars",
  CUSTOM_SEGMENT: "Custom Segment",
  CUSTOM: "custom",
  GF: "gf",
  FS: "fs",
  LS: "ls",
  DEVICE: "Device",
  PLACE_LIST: "placelist",
  TIME_LIST: "timelist",
  BOOLEAN_LIST: "booleanlist",
  NETWORK_LIST: "networklist",
  DEVICE_LANGUAGE_LIST: "deviceLanguagelist",
  AND: "AND",
  OR: "OR",
  CATEGORY_TYPE: "category_type",
  OPERATOR: "operator",
  VALUE: "value",
  OPERAND: "operand",
  EVENTS: "Events",
  H: "h",
  HN: "hn",
  LIVE: "live",
  PAST: "past",
  QUERY: "query",
  TRAIT: "trait",
  SYNC: "sync",
  SPLIT: "split",
  SAVE: "save",
  COUNT: "count",
  INCOMING: "incoming",
  OUTGOING: "outgoing",
  CATEGORY: "category",
  ID: "id",
  CHILDREN: "children",
  GEO: "Geo",
  CTRAIT: "cTrait",
  BOOLEAN: "boolean",
  NUMBER: "number",
  TREE: "json",
  IMAGE: "image",
  AU: "au",
  ASL: "asl",
  AGGREGATE_DATA: "aggregateData",
  AVG_AGGREGATE_DATA: "averageAggregateData",
  TABLE_DATA: "tableData",
  AVG_TABLE_DATA: "averageTableData",
  PREVIOUS_TOTAL: "previousTotal",
  HEADER: "Header",
  OFFER: "Offer",
  PORTFOLIO: "Portfolio",
  ACCOUNT_ENGAGEMENT: "AccountEngagement",
  BOTTOM: "bottom",
  MIDDLE: "middle",
  EVENT: "event",
  MONTHS_FIRST_LETTER_LIST: [
    "J",
    "F",
    "M",
    "A",
    "M",
    "J",
    "J",
    "A",
    "S",
    "O",
    "N",
    "D",
  ],
} as const;
export const COLORS = {
  TIMELINE_DOT_COLORS: ["blue", "gray", "green", "red"]
} as const;
