import { MoreOutlined } from "@ant-design/icons";

// Sample data
const data = [
  {
    name: 'Black Friday customers',
    members: 5397,
    avgTransactionValue: 150,
    avgNumberOfTransactions: 5,
    avgSpending: 1000,
    createdOn: '15 Feb, 2023 04:08 PM',
  },
  {
    name: 'Frequent fliers high value',
    members: 2654,
    avgTransactionValue: 135,
    avgNumberOfTransactions: 4,
    avgSpending: 780,
    createdOn: '12 Mar, 2024 03:30 PM',
  },
  {
    name: 'Customers with high AOV',
    members: 3219,
    avgTransactionValue: 125,
    avgNumberOfTransactions: 5,
    avgSpending: 850,
    createdOn: '25 Apr, 2024 11:45 AM',
  },
  {
    name: 'Christmas gift shoppers',
    members: 6267,
    avgTransactionValue: 140,
    avgNumberOfTransactions: 6,
    avgSpending: 920,
    createdOn: '08 May, 2024 05:00 PM',
  },
  {
    name: 'New sign-up to rewards',
    members: 3401,
    avgTransactionValue: 130,
    avgNumberOfTransactions: 4,
    avgSpending: 800,
    createdOn: '21 Jun, 2024 09:15 AM',
  },
  {
    name: 'Sleeping users mid value',
    members: 6672,
    avgTransactionValue: 145,
    avgNumberOfTransactions: 5,
    avgSpending: 950,
    createdOn: '04 Jul, 2024 02:30 PM',
  },
];

  
  // Columns configuration
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Members',
      dataIndex: 'members',
      key: 'members',
    },
    {
      title: 'Avg. Transaction Value (USD)',
      dataIndex: 'avgTransactionValue',
      key: 'avgTransactionValue',
    },
    {
      title: 'Avg. Number of Transactions',
      dataIndex: 'avgNumberOfTransactions',
      key: 'avgNumberOfTransactions',
    },
    {
      title: 'Avg. Spending (USD)',
      dataIndex: 'avgSpending',
      key: 'avgSpending',
    },
    {
      title: 'Created On',
      dataIndex: 'createdOn',
      key: 'createdOn',
    },
    {
        title: 'Action',
        dataIndex: 'action',
        render:()=><div style={{cursor:"pointer"}} className="d-flex justify-content-center">
            
            <MoreOutlined style={{fontSize:"20px",fontWeight:"bold"}} />
            </div>
      },
  ];
  
  export { columns, data };
  