import React from "react";
import CohortGraph from "react-cohort-graph";
import NoData from "../../NoData/NoData";
interface propTypes {
  data: any;
  defaultValueType: any; // Define your prop types here
  bodyCellColor?: any;
  headerFormatter?: any;
  headerLabels?: any;
  labelFormatter?: any;
  cellFormatter?: any;
}

const CohortChart: React.FC<propTypes> = ({
  data,
  cellFormatter,
  defaultValueType,
  bodyCellColor,
  headerFormatter,
  labelFormatter,
}) => {
  
  // console.log("data in cohort",data)
  // console.log("data", Object.keys(data?.weeks || {}).length);

  return Object.keys(data?.weeks || {}).length == 0 ? (
    <NoData />
  ) : (
    <CohortGraph
      shadeColor={"#689dff"}
      data={data}
      defaultValueType={defaultValueType}
      bodyCellColor={bodyCellColor}
      headerFormatter={headerFormatter}
      labelFormatter={labelFormatter}
      cellFormatter={cellFormatter}
      //  headerLabels={headerLabels}
    />
  );
};

export default CohortChart;
