import { FC } from "react";
import CustomTextArea from "../../../../../../components/Inputs/CustomTextArea/CustomTextArea";
import { replacePersonlizedVariable } from "../../Personalization/Personalization";
import { personalizationVariableInfoType } from "@customTypes/template";

interface SMSContainer {
  message?: any;
  direction?: any;
  variableInfo?:{[key:string]:personalizationVariableInfoType},
}
const SMSContainer: FC<SMSContainer> = ({ message, direction ,variableInfo}) => {
  return (
    <div className="h-100">
      <div className="row py-2 sms-device-container g-0 ">
        <div className=" mx-1 d-flex ">
          <CustomTextArea
            disabled={true}
            // value={message}
            value={replacePersonlizedVariable(message,variableInfo)}

            style={{
              height: 100,
              resize: "none",
              direction: direction,
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default SMSContainer;
