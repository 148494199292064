import centralApi from "../services/centralApi";

export const heartBeat = async (apiKey: string) => {
  const payload = {
    api_key: apiKey,
  };
  const url = `o/metrices/heartBeat`;
  const data = await centralApi("GET", url, null, payload);
  return data;
};
