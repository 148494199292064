import React, { useEffect, useMemo, useState } from "react";
import filterIcon from "../../../images/_filter.svg";
import { Form, MenuProps, Space, Tag } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./AudienceCreate.css";
import { SyncOutlined } from "@ant-design/icons";
import downloadIcon from "../../../images/downloadIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { STATUS } from "redux/constant";
import {
  audienceItems,
  changeAudienceDataFormat,
  getCriteria,
} from "helperFunctions/audience";
import { useTranslation } from "react-i18next";
import centralApi from "services/centralApi";
import {
  fetchAudienceSegmentById,
  resetSegment,
  setSegmentData,
} from "redux/features/audience/audienceSegmentDataSlice";
import { fetchCustomVars } from "redux/features/app/customMetaVarsSlice";
import { fetchEventList } from "redux/features/event/eventListSlice";
import { getToken } from "services/getToken";
import CustomBreadCrumb from "components/CustomBreadCrumb/CustomBreadCrumb";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { validateName } from "validator/antdValidator";
import TextInput from "components/Inputs/TextInput/TextInput";
import Counter from "components/Counter/Counter";
import InnerNav from "components/InnerNav/InnerNav";
import useMessage from "hooks/useMessage";
import Spinner from "components/Spinner/Spinner";
import { Helmet } from "react-helmet";
import { getObjectId } from "services/getObjectId";
import useDebouncedValue from "hooks/useDebouncedState";
import { AudienceEnum, SuccessErrorEnum } from "utils/enums";
import QuerySteps from "./components/QuerySteps/QuerySteps";
import { fetchComputedTraitList } from "redux/features/audience/computedTraitListSlice";
import { API_ENDPOINTS, ROUTES } from "utils/constants";
import {useCustomNotification} from "hooks/useNotification";
// import { SegmentInfo ,SegmentPayload} from "src_typescript_appice-core/dist/models/audience/audience.js";
// import { JsonSerializer } from "typescript-json-serializer";
type propTypes = {
  afterSaveClick?: (saved: boolean) => void;
};
const AudienceCreate: React.FC<propTypes> = ({ afterSaveClick }) => {
  const [form] = Form.useForm();
  const [isSpin, setIsSpin] = useState(false);
  const [tabFilter, setTabFilter] = useState<string>(AudienceEnum.QUERY);
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const featuresSetting=useSelector((state:any)=>state.featuresSetting.data)
  const { appId } = useSelector((state: any) => state.activeApp);
  const [segmentNameRepeated, setSegmentNameRepeated] = useState(false);
  const { showError, showSuccess } = useMessage();
  const {openNotification } = useCustomNotification();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state } = useLocation();
  const segment = useSelector((state: any) => state.audienceSegmentData.data);
  const segmentStatus = useSelector(
    (state: any) => state.audienceSegmentData.status
  );
  const [inputValues, setInputValues] = useDebouncedValue<{
    name: string;
    description: string;
  }>({ name: "", description: "" }, 300);
  const eventListStatus = useSelector((state: any) => state.eventList.status);
  const customVarStatus = useSelector(
    (state: any) => state.customMetaVars.status
  );
  const { who, what, when,cTrait = [] } = segment.segmentinfo;
  const isValidForm = useMemo(() => {
    // The form is considered valid if at least one segment exists and all 'what' segments have an 'operand'.
    if ([...who, ...when, ...what,...cTrait].length) {
      if (what.length) {
        // Return false if any 'what' segment has no 'operand'
        const hasNoOperand = what.some((item: any) => !item.operand);
        if (hasNoOperand) {
          return false;
        }
      }
      // Return true if the form is valid.
      return true;
    }
    // Return false if any segment ('who', 'when', or 'what') is empty.
    return false;
  }, [segment?.segmentinfo]);
  const setObjectId = async () => {
    try {
      const objectid = await getObjectId(appId, loginUser.api_key);
      dispatch(setSegmentData({ objectid }));
    } catch (error) {
      console.error(error)
    }
  };
  //fetch the segment size
  const fetchSegmentSize = async (criteria: any[]) => {
    // Initialize info object with default values
    let info = { count: 0, q: "", userQ: "" };
    try {
      // Set loading state
      setIsSpin(true);

      // Define selectors for the API request
      const selectors = {
        generate: true,
        countOrData: true,
        execute: true,
        column: ["did"],
      };

      // Prepare data for the API request
      const data = {
        api_key: loginUser.api_key,
        app_id: appId,
        criteria: JSON.stringify(criteria),
        method: "countAudienceReach",
        selectors: JSON.stringify(selectors),
      };

      // Make the API request to fetch segment size
      const { result } = await centralApi(
        "POST",
        API_ENDPOINTS.COUNT_AUDIENCE_REACH_URL,
        data,
        null
      );

      // Update info if the API response contains q and userQ values
      if (result.q && result.userQ) {
        info = { count: result.count, q: result.q, userQ: result.userQ };
      }

      // Reset loading state
      setIsSpin(false);
    } catch (error) {
      // Reset loading state in case of error
      setIsSpin(false);
    }

    // Update segment data in Redux state
    dispatch(
      setSegmentData({
        ...segment,
        range: info.count,
        segmentinfo: { ...segment.segmentinfo, q: info.q, userQ: info.userQ },
      })
    );

    // Return the fetched info
    return info;
  };

  useEffect(() => {
    //update in redux
    dispatch(setSegmentData(inputValues));
  }, [inputValues]);
  useEffect(() => {
    if (state?.id) {
      //if edit fetch audience segment
      dispatch(
        fetchAudienceSegmentById({
          api_key: loginUser.api_key,
          app_id: appId,
          aud_id: state.id,
        })
      );
    }
    if (loginUser.api_key && appId) {
      if (!state?.id) {
        setObjectId();
      }
      const payload={ api_key: loginUser.api_key, app_id: appId }
      dispatch(fetchCustomVars(payload));
      dispatch(fetchEventList(payload));
      dispatch(fetchComputedTraitList(payload))
      getToken(appId, loginUser.api_key);
    }
    return () => {
      dispatch(resetSegment());
    };
  }, [loginUser, state]);

  const onTabClick: MenuProps["onClick"] = ({ key }) => {
    setTabFilter(key);
    if (key == AudienceEnum.TRAIT) {
      navigate(ROUTES.DASHBOARD_AUDIENCE_TRAIT_CREATE);
    }
    else if (key == AudienceEnum.MODELS) {
      navigate(ROUTES.DASHBOARD_AUDIENCE_MODELS);
    }
    //----
  };
  const getSerializedPayload = (segmentData: any) => {
    // const segmentInfo = new SegmentInfo(payload["segmentinfo"]);
    // const defaultSerializer = new JsonSerializer();
    // const deserializedData=defaultSerializer.deserialize(segmentInfo,segmentInfo)
    // if(deserializedData){
    // const serializedData=defaultSerializer.serialize(deserializedData)
    const segmentinfo = JSON.stringify(segmentData["segmentinfo"]);
    return {
      ...segmentData,
      segmentinfo: segmentinfo,
    };
  };
  //save audience
  const saveAudience = async (segmentData: any) => {
    const serializedPayload: any = getSerializedPayload(segmentData);
    
    try {
      const params: any = {
        api_key: loginUser.api_key,
        app_id: appId,
        id: segment?.id,
      };
      const data = new FormData();
      for (const key in serializedPayload) {
        if (serializedPayload[key] !== undefined) {
          data.set(key, serializedPayload[key]);
        }
      }
      for (const key in params) {
        data.set(key, params[key]);
      }

      const resAud = await centralApi("POST", API_ENDPOINTS.CREATE_AUDIENCE_URL, data, params);
      if (resAud?.name) {
        if(serializedPayload.file){
          openNotification(
            t("uploadStartedMsg"),
           t("willNotifyOnUploadMsg"))
        }
        else{
          showSuccess(t("segmentCreatedSuccessfullyMsg"));
        }

      } else {
        throw new Error();
      }
      form.resetFields();
    } catch (error) {
      if(serializedPayload.file){
        openNotification(
          t("uploadFailedMsg"),
          t("errorWhileUploadingMsg"),
          SuccessErrorEnum.ERROR
        )}
      else{
        showError(t("somethingWrongLabel"));
      }
    }
    // Check if the `afterSaveClick` callback is provided in props.
    // If provided, call the callback with `true` to indicate a successful save.
    // This callback is provided when AudienceCreate is integrated into another page.
    // If the callback is not provided, navigate to the "/dashboard/audience" route.
    if (afterSaveClick) {
      afterSaveClick(true);
    } else {
      navigate(ROUTES.DASHBOARD_AUDIENCE);
    }
  };
  //update the audience
  const updateAudience = async (segmentData: any) => {

    const serializedPayload: any = getSerializedPayload(segmentData);
    const data : any = {
      ...serializedPayload,
      objectid : serializedPayload._id
    }
    try {
      const params: any = {
        api_key: loginUser.api_key,
        app_id: appId,   
      };
      const formData = new FormData();
      for (const key in data) {
        if (data[key] !== undefined) {
          formData.set(key, data[key]);
        }
      }
      for (const key in params) {
        formData.set(key, params[key]);
      }
  
      const resAud = await centralApi("POST",  API_ENDPOINTS.UPDATE_AUDIENCE_URL, formData, params);
      if (resAud?.name) {
        if(serializedPayload.file){
          openNotification(
            t("uploadStartedMsg"),
           t("willNotifyOnUploadMsg"))
        }
        else{
          showSuccess(t("segmentUpdatedSuccessfullyMsg"));
        }
      } else {
        throw new Error();
      }
      form.resetFields();
    } catch (error) {
      if(serializedPayload.file){
        openNotification(
          t("uploadFailedMsg"),
          t("errorWhileUploadingMsg"),SuccessErrorEnum.ERROR
        )}
      else{
        showError(t("somethingWrongLabel"));
      }
    }
    // };
    navigate("/dashboard/audience");
  };
  //submit the form
  async function onFormSubmit(_?:any,action: "save" | "count" = AudienceEnum.SAVE) {
    // Validate the form fields
    form
      .validateFields()
      .then(async () => {
        // Check if the segment name is repeated
        if (segmentNameRepeated) {
          // Set an error message for the "name" field
          form.setFields([
            {
              name: "name",
              errors: [t("segmentNameAlreadyExistsMsg")],
            },
          ]);
          return;
        }

        // Convert segmentinfo to the outgoing format
        const segmentinfo = changeAudienceDataFormat(
          segment.segmentinfo,
          AudienceEnum.OUTGOING
        );

        // Check if user is loggedin
        if (appId && loginUser.api_key) {
          try {
            // Fetch segment size
            const info = await fetchSegmentSize(getCriteria(segmentinfo));

            // Update segmentData with range and updated segmentinfo
            const segmentData = {
              ...segment,
              range: info.count,
              segmentinfo: { ...segmentinfo, q: info.q, userQ: info.userQ },
            };

            // Get the token
            await getToken(appId, loginUser.api_key);

            // Perform action based on the specified action type
            // If the action is to save the audience...
            if (action == AudienceEnum.SAVE) {
              // Check if the audience already exists (state.id exists)
              if (state?.id) {
                // If the audience exists, update it
                updateAudience(segmentData);
              } else {
                // If the audience is new, save it
                saveAudience(segmentData);
              }
            }
          } catch (error) {
            console.log(error);
          }
        }
      })
      .catch((error) => {
        console.error(error)
      });
  }

  //validates the name
  const validateSegmentName = async (value: string) => {
    try {
      // Check if the segment name  is not empty
      if (value?.length > 0) {
        // Prepare parameters for the API request
        const params = {
          api_key: loginUser.api_key,
          app_id: appId,
          method: "validateSegmentName",
          segmentName: value,
        };

        // Make an API request to validate the segment name
        const { result } = await centralApi(
          "GET",
          API_ENDPOINTS.VALIDATE_SEGMENT_NAME_URL,
          null,
          params
        );

        // Set the state to indicate if the segment name is repeated
        setSegmentNameRepeated(result);

        // If the segment name is repeated, show an error message in the form
        if (result) {
          form.setFields([
            {
              name: "name",
              errors: [t("segmentNameAlreadyExistsMsg")],
            },
          ]);
        }
      }
    } catch (error) {}
  };
  return (
    <div className="container-fluid px-1 audience-create-container">
     {/* dont show this title and breadcrumb when the page is integrated in another page */}
      {!afterSaveClick && (
        <div className="pb-4 pt-1">
          <Helmet>
            <title>Appice | Audience | Create</title>
          </Helmet>
          <CustomBreadCrumb
            items={[
              {
                title: (
                  <Link to={"/dashboard/audience"}>{t("audienceLabel")}</Link>
                ),
              },
              {
                title: (
                  <Link to={""}>
                    {state?.id ? t("editLabel") : t("createLabel")}
                  </Link>
                ),
              },
            ]}
          />
        </div>
      )}
      <div className="audience-inner-container  p-sm-4 p-2 py-4 d-flex flex-column ">
        {segmentStatus !== STATUS.LOADING &&
        customVarStatus !== STATUS.LOADING &&
        eventListStatus !== STATUS.LOADING ? (
          <Form form={form} onFinish={onFormSubmit} disabled={isSpin}>
            <div className="d-flex position-relative gap-2  justify-content-between  align-items-center ">
              {/* for icon and title of header */}
              <div className="d-flex justify-content-between w-100 gap-3  flex-wrap">
                <div className="d-flex align-items-center ">
                  <div className="d-flex   align-items-center">
                    <span className="d-flex   filter-icon-container justify-content-center ">
                      <img
                        src={filterIcon}
                        height={"100%"}
                        width={"100%"}
                        alt="filter"
                      ></img>
                    </span>

                    <h4 className="m-0 mx-2 audience-segment-heading">
                      {state?.id
                        ? t("updateSegmentLabel")
                        : t("createSegmentLabel")}
                    </h4>
                  </div>
                </div>

                {/* for buttons of header */}
                <Space className="d-flex  mb-auto">
                <PrimaryButton onClick={() => navigate("/dashboard/audience")}>
                 {t("discardLabel")}
                </PrimaryButton>
                </Space>
              </div>
              <div className="mb-auto audience-segment-save">
                <PrimaryButton
                  disabled={isSpin || !isValidForm}
                  htmlType="submit"
                  type="primary"
                >
                  {state?.id ? t("updateSegmentLabel") : t("saveSegmentLabel")}
                </PrimaryButton>
              </div>
            </div>
            <div className="d-flex justify-content-between py-3  flex-wrap-reverse gap-2 ">
              <div className="d-flex gap-3 flex-wrap w-75">
                <div className="col-md-5 col-12 ">
                  <strong className="form-item-custom-label">
                    {t("segmentNameLabel")}
                  </strong>
                  <Form.Item
                    rules={[
                      {
                        validator: validateName,
                        message: `${t("inputSegmentNameMsg")}`,
                      },
                    ]}
                    initialValue={segment?.name || undefined}
                    name={"name"}
                    className="m-0"
                  >
                    <TextInput
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setInputValues({
                          name: e.target.value,
                          description: segment?.description,
                        })
                      }
                      onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                        validateSegmentName(e.target.value)
                      }
                      className="mt-2"
                    />
                  </Form.Item>
                </div>
                <div className="col-md-5 col-12">
                  <strong className="form-item-custom-label">
                    {t("segmentDescriptionLabel")}
                  </strong>
                  <Form.Item
                    initialValue={segment?.description || undefined}
                    rules={[
                      {
                        validator: validateName,
                        message: `${t("inputSegmentDescriptionMsg")}`,
                      },
                    ]}
                    name={"description"}
                    className="m-0"
                  >
                    <TextInput
                      className="mt-2"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setInputValues({
                          name: segment?.name,
                          description: e.target.value,
                        })
                      }
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="segment-size-container">
                <strong className="form-item-custom-label">
                  {t("segmentSizeLabel")}
                </strong>
                <div className="d-flex  mt-2">
                  <Counter
                    className="segment-size-counter"
                    end={segment?.range}
                    duration={1.5}
                  />
                  {featuresSetting[0]?.features?.audience_exports==false && <Tag className="mx-1">
                    <img height={18} width={18} src={downloadIcon}></img>
                  </Tag>}
                  <PrimaryButton
                    className="d-flex align-items-center segment-size-btn"
                    disabled={!isValidForm}
                    onClick={() => {
                      onFormSubmit({},AudienceEnum.COUNT);
                    }}
                  >
                    <SyncOutlined spin={isSpin} />
                  </PrimaryButton>
                </div>
              </div>
            </div>
            {/* inner nav starts from here */}
          
             <InnerNav
              menu={audienceItems.audienceCreateNavItems(featuresSetting[0]?.features)}
              onTabClick={onTabClick}
              selected={tabFilter}
            />

            <div className="py-4">
              <QuerySteps form={form} />
            </div>
          </Form>
        ) : (
          <div className="d-flex align-items-center audience-create-loader-container justify-content-center h-100 w-100">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default AudienceCreate;
