import { Form, Upload } from "antd";
import { useEffect, useState } from "react";
import { FileImageFilled, UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import Selector from "components/Selector/Selector";
import TextInput from "components/Inputs/TextInput/TextInput";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import {
  setAudienceSegmentInfo,
  setSegmentData,
} from "redux/features/audience/audienceSegmentDataSlice";
import { AudienceEnum } from "utils/enums";
import "./CustomSegment.css";
import { audienceItems } from "helperFunctions/audience";
import sampleCsv from "assets/csv/sample.csv"
import {  downloadFileFromUrl } from "helperFunctions/common";

function CustomSegment({
  category,
  childIndex,
  childValue,
}: {
  category: string;
  childIndex: number;
  childValue: any;
}) {
  const {objectid} = useSelector((state: any) => state.audienceSegmentData.data);
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const dispatch: any = useDispatch();
  const [data, setData] = useState({
    operand: childValue.operand || "audid",
    operator: childValue.operator || "eq",
    value: childValue.value || objectid,
    category_type: childValue.category_type || undefined,
  });
  const [formData, setFormData] = useState<any>({
    customUpload: true,
    file: null,
    datatype: undefined,
    fileheader: undefined,
    csvFile_id: "",
    audid: "",
    email: "",
    clientid: "",
    did: "",
  });
  const handleFormChange = (key: string, value: any) => {
    if (
      key == AudienceEnum.CATEGORY_TYPE ||
      key == AudienceEnum.OPERATOR ||
      key == AudienceEnum.VALUE ||
      key == AudienceEnum.OPERAND
    ) {
      setData((prevState: any) => ({ ...prevState, [key]: value }));
    } else {
      setFormData((prevState: any) => ({ ...prevState, [key]: value }));
    }
  };
  useEffect(() => {
    if (loginUser && objectid) {
      setFormData((prevState: any) => ({
        ...prevState,
        email: loginUser.email,
        audid: objectid,
      }));
    }
  }, [loginUser, objectid]);
  useEffect(() => {
    if (formData.file) {
      dispatch(setSegmentData(formData));
      dispatch(
        setAudienceSegmentInfo({
          segmentType: AudienceEnum.WHO,
          category,
          data,
          indexToModify: childIndex,
        })
      );
    }
  }, [data, formData]);
  useEffect(() => {
    return ()=>{
      dispatch(
        setSegmentData({
          customUpload: undefined,
          File: undefined,
          datatype: undefined,
          fileheader: undefined,
          csvFile_id: undefined,
          audid: undefined,
          email: undefined,
          clientid: undefined,
          did: undefined,
        })
        );
      }
  }, []);
  return (
    <div data-testid="custom-segment-component" className="d-flex flex-wrap user-trait-custom-segment-container">
      <Form.Item
        name={`fileheader[${childValue.id}]`}
        initialValue={formData.fileheader}
      >
        <Selector
        data-testid="fileheader-input"
          onChange={(value) => handleFormChange("fileheader", value)}
          className="custom-segment-input"
          placeholder={`${t("fileHeaderMsg")}`}
          options={audienceItems.fileHeaderOptions}
          value={formData.fileheader}
        ></Selector>
      </Form.Item>
      <Form.Item
        name={`category_type[${childValue.id}]`}
        initialValue={data.category_type}
      >
        <Selector
        data-testid="category-type-input"
          onChange={(value) => {
            handleFormChange("category_type", value);
            handleFormChange("datatype", value);
            handleFormChange("clientid", value == "cid" ? "true" : "false");
            handleFormChange("did", value == "did" ? "true" : "false");
          }}
          className="custom-segment-input"
          placeholder={`${t("typeOfDataMsg")}`}
          value={data.category_type}
          options={audienceItems.typeOfDataOptions}
        ></Selector>
      </Form.Item>
      <Form.Item
        name="csv"
        valuePropName="file"
        getValueFromEvent={(e) => e.fileList}
        rules={[{ required: true, message: `${t("PlzUploadCsvMsg")}` }]}
      >
        <Upload
          showUploadList={false}
          accept=".csv"
          multiple={false}
          onChange={(value) => {
            handleFormChange("file", value.file);
            handleFormChange("csvFile_id", value.file.name);
          }}
          beforeUpload={() => false}
        >
          <PrimaryButton data-testid="custom-segment-upload-btn" className="custom-segment-input">
            {formData.file ? <FileImageFilled /> : <UploadOutlined />}

            <span className="mx-1 custom-segment-upload-btn-name">
              {formData.file ? formData.file.name : t("chooseCsvFileMsg")}
            </span>
          </PrimaryButton>
        </Upload>
      </Form.Item>
      <Form.Item
        hidden
        initialValue={formData.value}
        name={`value[${childValue.id}]`}
      >
        <TextInput
          onChange={(e: any) => handleFormChange("value", e.target.value)}
          className="custom-segment-input"
        ></TextInput>
      </Form.Item>
      <Form.Item>
      <PrimaryButton onClick={()=>   downloadFileFromUrl(sampleCsv, "sample.csv")}>       
         {t("sampleCsvLabel")}
          </PrimaryButton>
          </Form.Item>
      
    </div>
  );
}

export default CustomSegment;
