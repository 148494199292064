import React, { FC } from "react";
import MessageCard from "../MessageCard/MessageCard";
import "./MessagePreview.css";
import { Avatar } from "antd";
interface MessagePreviewProps {
  formData: { title: string; body: string };
  resData: {
    recipient: string;
    title: string;
    body: string;
    data: {
      offerId: string;
    };
  }[];
  loading: boolean;
  prompt:string,
  type:number
}

const MessagePreview: FC<MessagePreviewProps> = ({
  formData,
  resData,
  loading,
  type,
  prompt
}) => {
  return (
    <div className=" ai-gen-message-preview-container">
      <div className="d-flex h-75  gap-2 justify-content-center align-items-center">
        {resData.map((item, index) => {
          return (
            <div className="d-flex flex-column">
              {resData.length!==1 && <Avatar className="mb-2" style={{ backgroundColor: index?'#f56a00':'#87d068' }}>{index ? "B" : "A"}</Avatar>}
              <MessageCard
                title={item.title}
                body={item.body}
                loading={loading}
                formData={formData}
              />
            </div>
          );
        })}
      </div>
      <div className=" m-1 p-3 ai-gen-message-preview-generatedfrom">
        <span>Generated from</span>
        <div className="ai-gen-message-preview-input mt-2 d-flex flex-column gap-1">
          {type==1?
          <>
          <span>title:{formData?.title}</span>
          <span>message: {formData?.body}</span>
          </>:<span>Prompt:{prompt}</span>
        }
        </div>
      </div>
    </div>
  );
};

export default MessagePreview;
