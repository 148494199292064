import deleteIcon from "images/trashpink.svg";

import {  useSelector } from "react-redux";
import { t } from "i18next";
import CustomTable from "components/CustomTable/CustomTable";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";

import React, { useEffect, useState } from "react";
import useMessage from "hooks/useMessage";
import centralApi from "services/centralApi"
import TextInput from "components/Inputs/TextInput/TextInput";
import { Space } from "antd";

const DataTransformation: React.FC = () => {
  interface TableItem {
    templateName: string;
  }
  const { showError, showSuccess } = useMessage();
  const { appId } = useSelector((state: any) => state.activeApp);
  const loginUser = useSelector((state: any) => state.loginUser.data);
  
  const [loading, setLoading] = useState(false);
 
  const [tableData, setTableData] = useState<TableItem[]>([]);
 
  
  async function getCustomer360mapping(payload: {

    app_id: string;
    api_key: string;
  }) {
    const url='/o/customer360/getCustomer360Mapping'
    try {
      setLoading(true);
      // await centralApi("GET", API_ENDPOINTS.DELETE_TEMPLATE, null, payload);
 const res=     await centralApi("GET", url, null, payload);
 setTableData(res);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  
  useEffect(() => {
    if (loginUser?.api_key) {
     
      getCustomer360mapping({
        app_id: appId,
        api_key: loginUser.api_key});

    }
  }, [loginUser?.api_key]);
 
  
  

  const handleDeleteRow = (id: number) => {
    deleteRowById(id)
  };
  const handleActionChange = (key: string, index: number, value: string) => {
    const data = [...tableData];
    data[index] = { ...data[index], [key]: value };
    setTableData(data);
  };

  const columns = [
    {
      title: t("sourceTableLabel"),
      dataIndex: "source_table",
      key: "source_table",
      width: 150,
 
      render: (_: any, record: any, index: number) => (
        <TextInput
          value={record?.source_table}
          style={{fontSize:10}}
          onChange={(e: any) => {
            handleActionChange("source_table", index, e.target.value);
          }}
        />
      ),
    },
    {
      title: t("sourceColLabel"),
      dataIndex: "source_col",
      key: "source_col",
      width: 150,
      render: (_: any, record: any, index: number) => (
        <TextInput
          value={record?.source_col}
          onChange={(e: any) => {
            handleActionChange("source_col", index, e.target.value);
          }}
          style={{fontSize:10}}
        />
      ),
    },
    {
      title: t("sourceFieldsLabel"),
      width: 150,
 
      dataIndex: "source_fields",
      key: "source_fields",
      render: (_: any, record: any, index: number) => (
        <TextInput
          value={record?.source_fields}
          onChange={(e: any) => {
            handleActionChange("source_fields", index, e.target.value);
          }}
          style={{fontSize:10}}
          // style={{fostyle={{fontSize:10}}ntSize:10}}
        />
      ),
    },
    {
      title: t("sourceColDataTypeLabel"),
      dataIndex: "source_col_data_type",  width: 120,
 
      key: "source_col_data_type",
      render: (_: any, record: any, index: number) => (
        <TextInput
          value={record?.source_col_data_type}
          onChange={(e: any) => {
            handleActionChange("source_col_data_type", index, e.target.value);
          }}
          style={{fontSize:13}}
        />
      ),
    },
    {
      title: t("destinationColLabel"),
      dataIndex: "destination_col",
      key: "destination_col",  width: 120,
 
      render: (_: any, record: any, index: number) => (
        <TextInput
          value={record?.destination_col}
          onChange={(e: any) => {
            handleActionChange("destination_col", index, e.target.value);
          }}
          style={{fontSize:13}}

          // placeholder="Destination Value"
        />
      ),
    },
    {
      title: t("destinationColDataTypeLabel"),
      dataIndex: "destination_col_data_type",
      key: "destination_col_data_type",  width: 120,
 
      render: (_: any, record: any, index: number) => (
        <TextInput
          value={record?.destination_col_data_type}
          onChange={(e: any) => {
            handleActionChange(
              "destination_col_data_type",
              index,
              e.target.value
            );
          }}
          style={{fontSize:13}}

        />
      ),
    },
    {
      title: t("destinationColRootKeyLabel"),
      dataIndex: "destination_col_key",
      key: "destination_col_key",  width: 120,
 
      render: (_: any, record: any, index: number) => (
        <TextInput
          value={record?.destination_col_key}
          onChange={(e: any) => {
            handleActionChange(
              "destination_col_key",
              index,
              e.target.value
            );
          }}
          style={{fontSize:13}}

        />
      ),
    },
    {
      title: t("destinationColChildKeyLabel"),
      dataIndex: "destination_col_child_key",
      key: "destination_col_child_key",  width: 120,
 
      render: (_: any, record: any, index: number) => (
        <TextInput
          value={record?.destination_col_child_key}
          onChange={(e: any) => {
            handleActionChange(
              "destination_col_child_key",
              index,
              e.target.value
            );
          }}
          style={{fontSize:13}}

        />
      ),
    },
    {
      title: t("destinationColKeyDataTypeLabel"),
      dataIndex: "destination_col_key_data_type",
      key: "destination_col_key_data_type",  width: 120,
 
      render: (_: any, record: any, index: number) => (
        <TextInput
          value={record?.destination_col_key_data_type}
          onChange={(e: any) => {
            handleActionChange(
              "destination_col_key_data_type",
              index,
              e.target.value
            );
          }}
          style={{fontSize:13}}


        />
      ),
    },
    {
      title: t("displayNameLabel"),
      dataIndex: "display_name",
      key: "display_name",  width: 120,
 
      render: (_: any, record: any, index: number) => (
        <TextInput
          value={record?.display_name}
          onChange={(e: any) => {
            handleActionChange("display_name", index, e.target.value);
          }}
          style={{fontSize:13}}

        />
      ),
    },
    {
      title: t("identityColLabel"),
      dataIndex: "identity_col",
      key: "identity_col",  width: 120,
 
      render: (_: any, record: any, index: number) => (
        <TextInput
          value={record?.identity_col}
          onChange={(e: any) => {
            handleActionChange("identity_col", index, e.target.value);
          }}
          style={{fontSize:13}}

        />
      ),
    },
    {
      title: t("actionLabel"),
      key: "action",
      width: 10,
      render: (_: any, record: any) => {

        return (
          <Space>
            <PrimaryButton
              className="action-btn mx-1"
            >
              <img
                src={deleteIcon}
                alt={"close"}
                style={{ width: 18 }}
              ></img>
              {/* <CloseOutlined /> */}
            </PrimaryButton>

          <PrimaryButton
            className="action-btn mx-1"
           >
          Update
            {/* <CloseOutlined /> */}
          </PrimaryButton>
          </Space>
        );
      },
    }
    
  ];
  async function deleteRowById(id:number) {
  const payload = {
    args: JSON.stringify({
     id:id
    }),
    app_id: appId,
    api_key: loginUser?.api_key,
  };
  
const url='/o/customer360/deleteCustomer360MappingRowById'
    try {
      setLoading(true);
      await centralApi("GET",url, null, payload);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
 
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap my-3">
        <h4 className="fw-semi-bold">{t("dataTransformationLabel")}</h4>
      </div>
      <div className=" template-container">
        <div className="template-body template-container-table p-3 mt-3 ">
          <CustomTable
            dataSource={tableData}
            columns={columns}
            scroll={{ x: 1500 }}
            loading={loading}
            size="small"
          />
        </div>
      </div>
    </div>
  );
};
export default DataTransformation;
