import { FC} from "react";
import CustomTextArea from "../../../../../../components/Inputs/CustomTextArea/CustomTextArea";
import "./NativeDisplayContainer.css";
interface NativeDisplayContainerProps {
  type?: "IOS" | "Android";
  message: string;
}
const NativeDisplayContainer : FC<NativeDisplayContainerProps> = ({
  type,
  message,
}) => {
  return (
    <div className="native-display-parent-container mx-2 mt-2 ">
    <div className="row py-2 native-display-main-container g-0 ">
      <div className=" mx-1 d-flex ">
        <CustomTextArea
          disabled={true}
          value={message}

          style={{
            height: 100,
            resize: "none",
          }}
        />
      </div>
    </div>
  </div>
  );
};

export default NativeDisplayContainer;
