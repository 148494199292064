import { t } from "i18next";

export const validateNoSpecialChars = (_: any, value: any) => {
    const specialCharsRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
    if (specialCharsRegex.test(value)) {
      const label=t("specialChrNotAllowedMsg")
      return Promise.reject(
        new Error(label)
      );
    }
    return Promise.resolve();
  };
  //check field empty
  export const validateNotEmpty = (_: any, value: string) => {
    if (!value) {
      return Promise.reject(
        new Error("")
      );
    }
    return Promise.resolve();
  };
  // check email is valid or not 
  export const validateEmail = (_:any, value:string) => {
    if (!value || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      return Promise.resolve();
    }
    const label=t("enterValidMsg")
    return Promise.reject(label);
  };
  //check password is not empty and must be at least 5 characters
  export const validatePassword = (_: any, value: string) => {
    if (!value) {
      const label = t("invalidPasswordMsg");
      return Promise.reject(new Error(label));
    } else if (value.length < 5) {
      const label = t("passwordMin5Msg");
      return Promise.reject(new Error(label));
    } else if (!/[A-Z]/.test(value)) {
      const label = t("passwordUpperCaseMsg");
      return Promise.reject(new Error(label));
    } else if (!/\d/.test(value)) {
      const label = t("passwordNumberMsg");
      return Promise.reject(new Error(label));
    } else if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value)) {
      const label = t("passwordSpecialCharMsg");
      return Promise.reject(new Error(label));
    } else {
      return Promise.resolve();
    }
  };
  
  //check password and confirm password are same
  export const validateConfirmPassword = (_:any, value:any, passwordFieldValue:any) => {
    if (!value) {
      const label=t("plzConfirmPasswordMsg")
      return Promise.reject(
        new Error(label)
      );}
    if (value && value !== passwordFieldValue) {
      const label=t("passwordNotMatchMsg")
      return Promise.reject(
        new Error(label)
      );
      
    } else {
      return Promise.resolve();
    }
  };
  export const validateCampaignId = (_:any, value:string) => {
    if (!value || value.length!=24) {
      const label=t("enterValidCampaignIdMsg")
      return Promise.reject(
        new Error(label)
      );
    }
    else {
      return Promise.resolve();
    }
  };
  export const validateLetters = (_:any, value:any) => {
    if (value && !/^[a-zA-Z]+$/.test(value)) {
      return Promise.reject(new Error(`${t("plzLettersOnlyMsg")}`));
    } else {
      return Promise.resolve();
    }
  };
 /**
 * Validates a name field to ensure it meets specific criteria.
 *
 * @param _ - Placeholder for the rule parameter (not used in this validation).
 * @param value - The value of the name field to be validated.
 * @returns A promise that either resolves if the validation passes or rejects with an error message if it fails.
 */
export const validateName = (_: any, value: string) => {
  // Check if the value is empty, contains only a space, or does not match the allowed pattern
  if (value.length === 0 || value === " " || !/^[0-9A-Za-z\s\-]+$/.test(value)) {
    // Reject the promise with a custom error message if validation fails
    return Promise.reject(new Error(`${t("plzEnterValidNameMsg")}`));
  } else {
    // Resolve the promise if the validation passes
    return Promise.resolve();
  }
};

  
