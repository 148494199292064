import { FC, useState, useEffect } from "react";
import { Col, Form, Row, Space } from "antd";
import FormItem from "antd/es/form/FormItem";
import { t } from "i18next";
import Selector from "../../../../../../components/Selector/Selector";
import PrimaryButton from "../../../../../../components/Buttons/PrimaryButton/PrimaryButton";
import CampaignDevice from "../../../../../../components/CampaignDevice/CampaignDevice";
import TextInput from "../../../../../../components/Inputs/TextInput/TextInput";
import "./InAppNotification.css";
import CustomTextArea from "../../../../../../components/Inputs/CustomTextArea/CustomTextArea";
import { MoreOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import centralApi from "../../../../../../services/centralApi";
import { getToken } from "../../../../../../services/getToken";
import TemplateLayout from "../../../layout/TemplateLayout/TemplateLayout";
import { setCampaignData } from "../../../../../../redux/features/campaign/campaignDataSlice";
import { setCurrentStep } from "../../../../../../redux/features/campaign/campaignCreateCurrentStepSlice";
import CustomDrawer from "../../../../../../components/CustomDrawer/CustomDrawer";
import { useForm } from "antd/es/form/Form";
import fullImageOn from "../../../../../../images/fullscreen_on.png";
import miniOn from "../../../../../../images/mini_on.png";
import headerOn from "../../../../../../images/header_on.png";
import footerOn from "../../../../../../images/footer_on.png";
import fullImageOff from "../../../../../../images/fullscreen.png";
import miniOff from "../../../../../../images/mini.png";
import headerOff from "../../../../../../images/header.png";
import footerOff from "../../../../../../images/footer.png";
import CustomCheckbox from "../../../../../../components/CustomCheckbox/CustomCheckbox";
import SegmentedCustom from "../../../../../../components/SegmentedCustom/SegmentedCustom";
import { validateImageDimensions } from "../../../../../../helperFunctions/file";
import { ColumnsType } from "antd/es/table";
import { v4 as uuid } from "uuid";
import ShowMessage from "../../../../../../components/ShowMessage/ShowMessage";
import CustomTable from "../../../../../../components/CustomTable/CustomTable";
import useMessage from "../../../../../../hooks/useMessage";
import InAppContainer from "../../NotificationContainer/InAppContainer/InAppContainer";
import { getInAppBazelInsetValues } from "../../../../../../helperFunctions/common";
import { fetchTemplatesList } from "../../../../../../redux/features/templates_/templatesByTypeSlice";
import {
  fetchTemplateById,
  setSelectedTemplate,
} from "../../../../../../redux/features/templates_/templateByIdSlice";
import Personalization from "../../Personalization/Personalization";
import { validateName } from "services/validateName";
import { CustomData } from "../../CustomData/CustomData";
import { formatDate, getCurrentDate } from "helperFunctions/date";
import { CustomDataEnum } from "utils/enums";

interface InAppNotificationProps {}
interface selectedCustomFieldsType {
  externalUrl: string;
  notificationHeader: string;
  notificationDes: string;
}
const InAppNotification: FC<InAppNotificationProps> = () => {
  const dispatch: any = useDispatch();
  const [form] = useForm();
  const { DEVICE, IMAGE } = CustomDataEnum;

  const { showError } = useMessage();

  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const currentStep = useSelector(
    (state: any) => state.campaignCreateCurrentStep
  );
  const templates = useSelector((state: any) => state.templatesList.data);
  const selectedTemplate = useSelector((state: any) => state.templateById.data);
  const [personalization, setPersonalization] = useState({
    open: false,
    field: "",
  });
  const campaignData = useSelector((state: any) => state.campaignData.data);
  const [showDrawer, setShowDrawer] = useState({ value: false, type: "" });
  const [customDataState, setCustomDataState] = useState<any>({});
  const [customClickType, setCustomClickType] =
    useState<"externalUrl" | "notificationHeader" | "notificationDes">();
  const [image, setImage] = useState({
    miniScreen: { loading: false },
    notificationImage: { loading: false },
    iconImage: { loading: false },
    footerNotificatonImage: { loading: false },
  });
  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  });
  const [template, setTemplate] = useState({
    template_name: "",
    template_type: "IN-APP",
    isTemplateDeleted: "false",
  });
  const [oldTemplateName, setOldTemplateName] = useState(""); //old template name store to avoid validation if remplate name does not change
  const [selectedCustomFieldsString, setSelectedCustomFieldsString] =
    useState<selectedCustomFieldsType>();
  const [formData, setFormData] = useState<any>({
    view_mode: "Fullscreen",
    notificationImage: "",
    iconImage: "",
    notificationType: "dl",
    actionTitle: "Close",
    showAction: "",
    headerTextClr: "",
    descriptionTextClr: "",
    submitBtnTextClr: "",
    backgroundColor: "",
    cdata: [],
    actionUrl: "",
    notificationDescription: "",
    externalUrl: "",
    expandedNotificationDescription: "",
    variableInfo: undefined,
  });
  const [selectedImage, setSelectedImage] = useState(
    formData?.view_mode || "Fullscreen"
  );
  const [blobContainerCDN, setBlobContainerCDN] = useState("");
  useEffect(() => {
    dispatch(
      fetchTemplatesList({
        app_id: appId,
        api_key: loginUser.api_key,
        template_type: "IN-APP",
      })
    );
    getToken(appId, loginUser.api_key);
    if (campaignData.data) {
      dispatch(setSelectedTemplate(campaignData.data));
    }
  }, []);
  const handleTemplateUpdate = async () => {
    setFormData((prevState: any) => ({
      ...prevState,
      view_mode: selectedImage,
    }));

    try {
      const payload = {
        api_key: loginUser.api_key,
        app_id: appId,
        template_id: selectedTemplate._id,
        args: JSON.stringify({
          ...template,
          template: formData,
          modifiedOn: formatDate(getCurrentDate(), "MM/DD/YYYY"),
        }),
      };
      const res = await centralApi("POST", "i/templates/update", payload);

      dispatch(
        setCampaignData({
          ...campaignData,
          data: { ...res, blobContainerCDN },
          tid: res._id,
          t: res.template_type,
        })
      );
      dispatch(setCurrentStep(currentStep + 1));
    } catch (error) {}
  };

  const handleTemplateSave = async () => {
    setFormData((prevState: any) => ({
      ...prevState,
      view_mode: selectedImage,
    }));
    try {
      const payload = {
        api_key: loginUser?.api_key,
        app_id: appId,
        args: JSON.stringify({
          ...template,
          template: formData,
          createdOn: formatDate(getCurrentDate(), "MM/DD/YYYY"),
          modifiedOn: "",
        }),
      };
      const res = await centralApi("POST", "i/templates/create", payload);
      dispatch(
        setCampaignData({
          ...campaignData,
          data: { ...res, blobContainerCDN },
          tid: res._id,
          t: res.template_type,
        })
      );
      dispatch(setCurrentStep(currentStep + 1));
    } catch (error) {
      showError(t("somethingWrongLabel"));
    }
  };

  const handleCustomClick = (type: any) => {
    if (loginUser.api_key && appId) {
      setShowDrawer(() => ({ type, value: true }));
    }
  };

  const andIntial = {
    b: "",
    i: [],
    d: 0,
    l: 0,
    g: 0,
    f: "",
    m: "",
  };
  //upload image and validate dimensions
  const handleImageUpload = async (
    value: any,
    type:
      | "miniScreen"
      | "notificationImage"
      | "iconImage"
      | "footerNotificatonImage"
  ) => {
    try {
      const { valid, message } = await validateImageDimensions(
        type == "notificationImage"
          ? "inAppNotificationFull"
          : type == "miniScreen"
          ? "inAppNotificationMini"
          : type == "iconImage"
          ? "inAppNotificationSmall"
          : "inAppNotificationSmall",
        value.file
      );

      if (valid && appId) {
        //upload image
        setImage((prevState: any) => ({
          ...prevState,
          [type === "miniScreen" ? "iconImage" : type]: {
            loading: true,
          },
        }));
        const formData = new FormData();
        formData.append("image", value.file);
        formData.append("image_id", value.file.name);
        formData.append("appid", appId);
        const res = await centralApi(
          "POST",
          "/i/campaignFiles/campaignimages",
          formData,
          null
        );
        setTimeout(() => {
          setBlobContainerCDN(res.baseURL);
          setFormData((prevState: any) => ({
            ...prevState,
            [type === "miniScreen" ? "iconImage" : type]: "/" + res.url,
            view_mode: selectedImage,
          }));
          if (res.type == "gif" && type == "notificationImage") {
            setFormData((prevState: any) => ({
              ...prevState,
              and: {
                ...prevState?.and,
                i: res.imgs,
                b: `${res.baseURL}`,
                m: `${res.baseURL + res.url}`,
                f: res.fe,
              },
            }));
          } else if (type !== "miniScreen" && res.type !== "gif") {
            setFormData((prevState: any) => ({
              ...prevState,
              and: andIntial,
            }));
          }
          setImage((prevState: any) => ({
            ...prevState,
            [type === "miniScreen" ? "iconImage" : type]: {
              loading: false,
            },
          }));
        }, 600);
      } else {
        showError(message);
      }
    } catch (error) {
      setImage((prevState: any) => ({
        ...prevState,
        [type === "miniScreen" ? "iconImage" : type]: {
          loading: false,
        },
      }));
    }
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false });
    }, 2500);
  };

  useEffect(() => {
    if (formData?.view_mode) {
      setSelectedImage(formData?.view_mode);
    }
  }, [formData?.view_mode]);
  useEffect(() => {
    const { template, template_name, template_type, blobContainerCDN } =
      selectedTemplate || campaignData.data || formData;
    if (blobContainerCDN) {
      setBlobContainerCDN(blobContainerCDN);
    }
    if (template) {
      const {
        view_mode,
        notificationImage,
        iconImage,
        notificationType,
        actionTitle,
        showAction,
        headerTextClr,
        descriptionTextClr,
        submitBtnTextClr,
        backgroundColor,
        actionUrl,
        cdata,
        notificationDescription,
        externalUrl,
        variableInfo,

        expandedNotificationDescription,
      } = template;

      setFormData((prevState: any) => ({
        ...prevState,
        view_mode,
        notificationImage,
        iconImage,
        notificationType,
        actionTitle,
        showAction,
        headerTextClr,
        descriptionTextClr,
        submitBtnTextClr,
        backgroundColor,
        cdata,
        actionUrl,
        notificationDescription,
        externalUrl,
        expandedNotificationDescription,
        variableInfo,
      }));
      if (template_name) {
        setTemplate((prevState) => ({
          ...prevState,
          template_name,
          template_type,
        }));
      }
      if (view_mode) {
        setSelectedImage(view_mode);
      }

      if (cdata) {
        const custom_data: any = {};
        if (Array.isArray(cdata)) {
          // Handle array of objects
          cdata.forEach((obj: any) => {
            const [key, value] = Object.entries(obj)[0]; // Destructuring to get the first key-value pair
            custom_data[uuid()] = {
              key: key, // Key obtained from destructuring
              value: value, // Value obtained from destructuring
              type: obj?.type || "", // Assuming obj has a property called 'type'
            };
          });
        } else if (typeof cdata === "object" && cdata !== null) {
          // Handle plain object
          Object.keys(cdata).forEach((key: string) => {
            custom_data[uuid()] = { key, value: cdata[key] };
          });
        }
        setCustomDataState(custom_data);
        // setCustomDataState(cdata);
      }
    }
    setOldTemplateName(template_name); // save template name
  }, [campaignData, selectedTemplate]);
  // useEffect(() => {
  //   if (campaignData.data) {
  //     dispatch(setSelectedTemplate(campaignData.data))
  //   }
  // }, [campaignData?.data?._id])
  useEffect(() => {
    if (selectedTemplate) {
      dispatch(
        setCampaignData({
          ...campaignData,
          data: selectedTemplate,
          tid: selectedTemplate._id,
          t: selectedTemplate.template_type,
        })
      );
    }
  }, [selectedTemplate]);
  const saveCdata = () => {
    const cdata: any = [];

    Object.values(customDataState).forEach((value: any) => {
      if (value.value && value.key) {
        const dataObject = {
          [value.key]: value.value,
          type: value.type === DEVICE ? IMAGE : value?.type,
        };
        cdata.push(dataObject);
      }
    });
    setFormData((prevState: any) => ({ ...prevState, cdata }));
  };
  const handleSaveOrUpdate = async () => {
    try {
      // Perform name validation
      let isValidTemplateName;
      // Call validateName only when template name is changed
      if (template.template_name !== oldTemplateName) {
        try {
          isValidTemplateName = await validateName(
            template.template_name,
            "IN-APP",
            loginUser.api_key,
            appId
          );
          // Proceed with form validation and submission
        } catch (error) {
          showError(t("somethingWrongLabel"));
        }
      }

      // Validate the form fields
      if (isValidTemplateName) {
        form.setFields([
          {
            name: "templateName",
            errors: [t("templateNameExistsErrorMessage")],
          },
        ]);
      } else {
        //check other fields validation
        await form.validateFields();
        // Form is valid, proceed with saving or updating
        selectedTemplate ? handleTemplateUpdate() : handleTemplateSave();
      }
    } catch (error) {
      showError((error as Error).message);
    }
  };

  useEffect(() => {
    // Set initial values when template changes
    if (selectedTemplate) {
      form.setFieldsValue({
        templateName: template.template_name,
        notificationDescription: formData?.notificationDescription,
      });
    }
  }, [template, formData]);
  return (
    <div className=" in-app-notification-parent-container container-fluid">
      {showMessage.show && (
        <ShowMessage type={showMessage.type} content={showMessage.message} />
      )}
      <Personalization
        personalizationState={personalization}
        setFormData={setFormData}
        setPersonalizationState={setPersonalization}
      />
      <CustomDrawer
        open={showDrawer.value}
        width={540}
        placement="right"
        onClose={() => {
          setShowDrawer((prevState) => ({ ...prevState, value: false }));
        }}
        className="p-0  "
        closable={false}
        footer={
          <Space className="d-flex justify-content-end">
            <PrimaryButton
              onClick={() => {
                setShowDrawer((prevState) => ({ ...prevState, value: false }));
              }}
            >
              {t("cancelLabel")}
            </PrimaryButton>
            <PrimaryButton
              type="primary"
              onClick={() => {
                showDrawer.type == "customFields" ? (
                  setFormData((prevState: any) => ({
                    ...prevState,

                    notificationDescription:
                      selectedCustomFieldsString?.notificationDes,
                    actionUrl: selectedCustomFieldsString?.externalUrl,
                  }))
                ) : showDrawer.type == "cdata" ? (
                  saveCdata()
                ) : (
                  <></>
                );
                setShowDrawer((prevState) => ({ ...prevState, value: false }));
              }}
            >
              {t("saveLabel")}
            </PrimaryButton>
          </Space>
        }
        height={"90%"}
      >
        {showDrawer.type == "customFields" ? (
          customClickType && (
            <CustomFields
              type={customClickType}
              setSelectedCustomFieldsString={setSelectedCustomFieldsString}
            />
          )
        ) : showDrawer.type == "cdata" ? (
          <CustomData
            customDataState={customDataState}
            setCustomDataState={setCustomDataState}
          />
        ) : null}

      </CustomDrawer>
      <TemplateLayout
        leftItems={
          <CampaignDevice
            height={550}
            // width={300}
            bazel={getInAppBazelInsetValues(selectedImage)}
          >
            <div className="h-100">
              <InAppContainer
                descriptionText={formData?.notificationDescription}
                uploadImage={handleImageUpload}
                headerImage={
                  formData?.view_mode == selectedImage
                    ? `${blobContainerCDN + formData?.iconImage}`
                    : ""
                }
                miniScreenImage={
                  formData?.view_mode == selectedImage
                    ? `${blobContainerCDN + formData?.iconImage}`
                    : ""
                }
                fullScreenImage={
                  formData?.notificationImage
                    ? `${blobContainerCDN + formData?.notificationImage}`
                    : ""
                }
                textColor={formData?.descriptionTextClr}
                background={formData?.backgroundColor}
                imageLoading={image}
                selectedImage={selectedImage}
                actionTitle={formData?.actionTitle || "Close"}
                actionTitleBackground={formData?.backgroundColor}
                actionTitleColor={formData?.submitBtnTextClr}
                callToAction={formData?.showAction}
                variableInfo={formData?.variableInfo}
              />
            </div>
          </CampaignDevice>
        }
        // </div>
        rightItems={
          <div className="w-100 in-app-notification-parent-container-right-items">
            <Form
              colon={false}
              labelAlign="left"
              labelCol={{ flex: "170px" }}
              layout="horizontal"
              form={form}
            >
              <Row className="pt-3">
                <h5> {t("inAppLabel")}</h5>
              </Row>

              <Row className="pt-3">
                <Col span={24}>
                  <FormItem className="" name={"template_name"} label="">
                    <Selector
                      // showSearch
                      onChange={(tempString) =>
                        dispatch(
                          fetchTemplateById({
                            app_id: appId,
                            api_key: loginUser.api_key,
                            template_id: JSON.parse(tempString)._id,
                          })
                        )
                      }
                      options={templates.map(
                        ({
                          template_name,
                          _id,
                        }: {
                          template_name: string;
                          _id: string;
                        }) => ({
                          label: template_name,
                          value: JSON.stringify({ _id, template_name }),
                        })
                      )}
                      placeholder={t("selectingExistingTemplateLabel")}
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row className="pb-2 position-relative d-flex justify-content-center">
                <Col className="d-flex  " span={24}>
                  <FormItem
                    initialValue={template?.template_name}
                    className="w-100 m-0  "
                    label={t("templateNameLabel")}
                    name={"templateName"}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <TextInput
                      onChange={(e: any) => {
                        setTemplate((prevState) => ({
                          ...prevState,
                          template_name: e.target.value,
                        }));
                      }}
                      placeholder={t("templateNameLabel")}
                      value={template.template_name}
                    ></TextInput>
                  </FormItem>
                </Col>

                <Col className="d-flex mt-3 " span={24}>
                  <FormItem className="w-100 m-0  " label={t("viewLabel")}>
                    <div className="d-flex gap-4">
                      <div className="d-flex flex-column align-items-center in-app-view-img">
                        <img
                          src={
                            selectedImage === "Fullscreen"
                              ? fullImageOn
                              : fullImageOff
                          }
                          alt="full-image"
                          className="mr-2 image-hover"
                          // onClick={() => setFormData((prevState: any) => ({
                          //   ...prevState,
                          //   view_mode: "Fullscreen",
                          // }))
                          // setSelectedImage("Fullscreen")}
                          onClick={() => {
                            setSelectedImage("Fullscreen");
                          }}
                        />
                        <span style={{ fontSize: "12px" }}>
                          {t("intersitialLabel")}
                        </span>
                      </div>
                      <div className="d-flex align-items-center flex-column">
                        <img
                          src={selectedImage === "Mini" ? miniOn : miniOff}
                          alt="full-image"
                          className="mr-2 image-hover"
                          onClick={() => {
                            setSelectedImage("Mini");
                          }}
                        />
                        <span style={{ fontSize: "12px" }}>
                          {t("miniLabel")}
                        </span>
                      </div>
                      <div className="d-flex align-items-center flex-column">
                        <img
                          src={
                            selectedImage === "Header" ? headerOn : headerOff
                          }
                          alt="full-image"
                          className="mr-2 image-hover"
                          onClick={() => {
                            setSelectedImage("Header");
                          }}
                        />
                        <span style={{ fontSize: "12px" }}>
                          {t("headerLabel")}
                        </span>
                      </div>
                      <div className="d-flex align-items-center flex-column">
                        <img
                          src={
                            selectedImage === "Footer" ? footerOn : footerOff
                          }
                          alt="full-image"
                          className="mr-2 image-hover"
                          onClick={() => {
                            setSelectedImage("Footer");
                          }}
                        />
                        <span style={{ fontSize: "12px" }}>
                          {t("footerLabel")}
                        </span>
                      </div>
                    </div>
                  </FormItem>
                </Col>

                {(selectedImage == "Header" || selectedImage == "Footer") && (
                  <>
                    <div className="d-flex mt-2   w-100 justify-content-end">
                      <MoreOutlined
                        onClick={() => {
                          setPersonalization({
                            open: true,
                            field: "notificationDescription",
                          });
                        }}
                      />
                    </div>
                    <Col className=" d-flex gap-1  mt-1 mb-3" span={24}>
                      <FormItem
                        initialValue={formData?.notificationDescription}
                        className="w-100 m-0  "
                        label={t("inAppDescriptionLabel")}
                        name={"notificationDescription"}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <CustomTextArea
                          autoSize
                          value={formData?.notificationDescription}
                          onChange={(e: any) => {
                            setFormData((prevState: any) => ({
                              ...prevState,
                              notificationDescription: e.target.value,
                            }));
                          }}
                          maxLength={80}
                          showCount
                          className="push-campaign-input"
                        />
                      </FormItem>
                    </Col>
                  </>
                )}

                {(selectedImage == "Fullscreen" || selectedImage == "Mini") && (
                  <Col className="d-flex mt-3 " span={24}>
                    <FormItem
                      className="w-100 m-0  "
                      label={t("setCallToActionLabel")}
                    >
                      <CustomCheckbox
                        checked={formData?.showAction}
                        onClick={(e: any) =>
                          setFormData((prevState: any) => ({
                            ...prevState,
                            showAction: e.target.checked,
                          }))
                        }
                      />
                    </FormItem>
                  </Col>
                )}

                {selectedImage !== "Fullscreen" && (
                  <>
                    <div className="d-flex mb-2 mt-2 w-100 justify-content-end">
                      <MoreOutlined
                        onClick={() => {
                          setPersonalization({
                            open: true,
                            field: "actionUrl",
                          });
                        }}
                      />
                    </div>
                    <Col className="d-flex gap-1  " span={24}>
                      <FormItem
                        className="w-100 m-0"
                        label={t("inAppActionLabel")}
                      >
                        <TextInput
                          onChange={(e: any) =>
                            setFormData((prevState: any) => ({
                              ...prevState,

                              actionUrl: e.target.value,
                            }))
                          }
                          value={formData?.actionUrl}
                        />
                      </FormItem>
                    </Col>
                  </>
                )}
                <Col className="d-flex justify-content-between" span={24}>
                  <FormItem
                    className="my-0 mt-3 w-50"
                    label={t("customDataLabel")}
                  >
                    <SegmentedCustom
                      value={Object.keys(customDataState)?.length > 0}
                      onChange={(value: boolean) => {
                        setCustomDataState(() =>
                          value
                            ? {
                                [uuid()]: {
                                  key: "",
                                  type: "string",
                                  value: "",
                                },
                              }
                            : {}
                        );
                        !value &&
                          setFormData((prevState: any) => {
                            return { ...prevState, cdata: {} };
                          });
                      }}
                    />
                  </FormItem>
                  {Object.keys(customDataState).length > 0 && (
                    <PrimaryButton
                      className="mt-3"
                      onClick={() => {
                        handleCustomClick("cdata");
                        setShowDrawer((prevState) => ({
                          ...prevState,
                          value: true,
                        }));
                      }}
                      style={{
                        color: "white",
                        background: "var( --color-red-main)",
                      }}
                    >
                      Add
                    </PrimaryButton>
                  )}
                </Col>
                {formData?.showAction &&
                  (selectedImage == "Fullscreen" ||
                    selectedImage == "Mini") && (
                    <>
                      <Col className="d-flex mt-3 " span={24}>
                        <FormItem
                          className="w-100 m-0  "
                          label={t("actionTitleLabel")}
                        >
                          <TextInput
                            onChange={(e: any) => {
                              setFormData((prevState: any) => ({
                                ...prevState,

                                actionTitle: e.target.value,
                              }));
                            }}
                            value={formData?.actionTitle}
                          ></TextInput>
                        </FormItem>
                      </Col>
                      <Col className="" span={24}>
                        <div className="d-flex flex-column justify-content-between w-75">
                          <FormItem
                            className="m-0 mt-3"
                            label={t("urlTypeLabel")}
                          >
                            <SegmentedCustom
                              value={formData?.notificationType}
                              onChange={(value: "lp" | "dl") => {
                                setFormData((prevState: any) => ({
                                  ...prevState,

                                  notificationType: value,
                                }));
                              }}
                              options={[
                                { label: t("landingPageLabel"), value: "lp" },
                                { label: t("deepLinkLabel"), value: "dl" },
                              ]}
                            />
                          </FormItem>
                        </div>
                      </Col>
                      {formData?.notificationType == "lp" && (
                        <Col className="d-flex mt-3 " span={24}>
                          <FormItem label={t("openInLabel")} className=" mt-3 ">
                            <SegmentedCustom
                              options={[
                                { label: t("browserLabel"), value: "browser" },
                                { label: t("webViewLabel"), value: "webview" },
                              ]}
                            />
                          </FormItem>
                        </Col>
                      )}
                      <>
                        <div className="d-flex mb-2 mt-2 w-100 justify-content-end">
                          <MoreOutlined
                         
                            onClick={() => {
                              setPersonalization({
                                open: true,
                                field: "externalUrl",
                              });
                            }}
                          />
                        </div>
                        <Col className="d-flex gap-1  " span={24}>
                          <FormItem
                            className="w-100 m-0"
                            label={t("externalURL/deepLinkURLLabel")}
                          >
                            <TextInput
                              onChange={(e: any) =>
                                setFormData((prevState: any) => ({
                                  ...prevState,

                                  externalUrl: e.target.value,
                                }))
                              }
                              value={formData?.externalUrl}
                            />
                          </FormItem>
                        </Col>
                      </>
                    </>
                  )}

                <Col className="d-flex mt-3 " span={24}>
                  <FormItem
                    className="w-100 m-0  in-app-notification-container-text-field-color"
                    label={t("colorsLabel")}
                  >
                    <div className="d-flex  justify-content-between">
                      <FormItem className="text-center">
                        <TextInput
                          onChange={(e: any) => {
                            setFormData((prevState: any) => ({
                              ...prevState,

                              headerTextClr: e.target.value,
                            }));
                          }}
                          type="color"
                          className=""
                          style={{ maxWidth: "60px" }}
                          value={formData?.headerTextClr}
                        ></TextInput>
                        <p
                          style={{ fontSize: "12px" }}
                          className="m-0 p-0 mt-2"
                        >
                          Header text
                        </p>
                      </FormItem>
                      <FormItem className="text-center">
                        <TextInput
                          onChange={(e: any) => {
                            setFormData((prevState: any) => ({
                              ...prevState,

                              descriptionTextClr: e.target.value,
                            }));
                          }}
                          type="color"
                          className=""
                          value={formData?.descriptionTextClr}
                          style={{ maxWidth: "60px" }}
                        ></TextInput>
                        <p
                          style={{ fontSize: "12px" }}
                          className="m-0 p-0 mt-2"
                        >
                          {t("descriptionTextLabel")}
                        </p>
                      </FormItem>
                      <FormItem className="text-center">
                        <TextInput
                          onChange={(e: any) => {
                            setFormData((prevState: any) => ({
                              ...prevState,

                              backgroundColor: e.target.value,
                            }));
                          }}
                          type="color"
                          className=""
                          style={{ maxWidth: "60px" }}
                          value={formData?.backgroundColor}
                        ></TextInput>
                        <p
                          style={{ fontSize: "12px" }}
                          className="m-0 p-0 mt-2"
                        >
                          {t("backgroundLabel")}
                        </p>
                      </FormItem>
                      {formData?.showAction && (
                        <FormItem className="text-center">
                          <TextInput
                            onChange={(e: any) => {
                              setFormData((prevState: any) => ({
                                ...prevState,

                                submitBtnTextClr: e.target.value,
                              }));
                            }}
                            type="color"
                            className=""
                            style={{ maxWidth: "60px" }}
                            value={formData?.submitBtnTextClr}
                          ></TextInput>
                          <p
                            style={{ fontSize: "12px" }}
                            className="m-0 p-0 mt-2"
                          >
                            {t("actionTitleTextLabel")}
                          </p>
                        </FormItem>
                      )}
                    </div>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </div>
        }
        footerItem={
          <div>
            <Row className="gap-2  d-flex justify-content-end">
              <PrimaryButton
                disabled={!template.template_name}
                type="primary"
                htmlType="submit"
                onClick={handleSaveOrUpdate}
              >
                {selectedTemplate ? "Update" : "Save"}
              </PrimaryButton>
              <PrimaryButton
                style={{
                  color: "white",
                  background: "var( --color-red-main)",
                }}
                onClick={() => {
                  dispatch(setCampaignData({}));
                  dispatch(setCurrentStep(0));
                }}
              >
                {t("closeLabel")}
              </PrimaryButton>
            </Row>
          </div>
        }
      />
    </div>
  );
};

export default InAppNotification;
function CustomFields({
  setSelectedCustomFieldsString,
  type,
}: {
  setSelectedCustomFieldsString: any;
  type: "externalUrl" | "notificationHeader" | "notificationDes";
}) {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [defaultValue, setDefaultValue] = useState<any>({});
  const [selectedWithValue, setSelectedWithValue] = useState<any>({});
  const custom_vars = useSelector((state: any) => state.customMetaVars);
  const columns: ColumnsType<any> = [
    {
      title: "Feild Name",
      dataIndex: "name",
    },
    {
      title: "Default value",
      dataIndex: "dv",
      render: (key) => {
        const handleChange = (value: string) => {
          setDefaultValue((prevState: any) => ({ ...prevState, [key]: value }));
          if (selectedWithValue[key] !== undefined) {
            setSelectedWithValue((prevState: any) => ({
              ...prevState,
              [key]: value,
            }));
          }
        };
        return (
          <TextInput
            placeholder="Default value"
            onChange={(e: any) => {
              handleChange(e.target.value);
            }}
            style={{ background: "transparent" }}
          />
        );
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    const selected_with_value: any = {};
    newSelectedRowKeys.forEach((key) => {
      selected_with_value[key] = defaultValue[key] || "";
    });
    setSelectedWithValue(selected_with_value);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  useEffect(() => {
    let valueString: string = "";
    Object.keys(selectedWithValue).forEach((key) => {
      valueString = valueString.concat(`<<${key}:${selectedWithValue[key]}>>`);
    });
    setSelectedCustomFieldsString((prevState: selectedCustomFieldsType) => ({
      ...prevState,
      [type]: valueString,
    }));
  }, [selectedWithValue]);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <CustomTable
      loading={custom_vars.loading == "loading"}
      rowSelection={rowSelection}
      columns={columns}
      pagination={false}
      dataSource={custom_vars?.data?.map(({ label, value }: any) => ({
        name: label,
        key: value,
        dv: value,
      }))}
      scroll={{ y: "83vh" }}
    />
  );
}
