import { useEffect, useState, Fragment } from "react"
import netcore from "../../../../../images/netcore_email.png"
import aws from "../../../../../images/aws-ses-logo.png"
import "./EmailSetting.css"
import InnerNav from "../../../../../components/InnerNav/InnerNav"
import { Button, MenuProps, Space } from "antd"
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton/PrimaryButton"
import { Form, Input } from "antd"
import { useSelector } from "react-redux"
import centralApi from "../../../../../services/centralApi"
import ShowMessage from "../../../../../components/ShowMessage/ShowMessage"
import { Divider } from "antd"
import Spinner from "../../../../../components/Spinner/Spinner"
import TextInput from "../../../../../components/Inputs/TextInput/TextInput"
import { getToken } from "../../../../../services/getToken"
import Selector from "components/Selector/Selector"
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import { t } from "i18next"
interface EmailSettingProps {
  currentAppKey: any
  updateCurrentAppKey: () => void
}

const EmailSetting: React.FC<EmailSettingProps> = ({
  currentAppKey,
  updateCurrentAppKey,
}) => {
  const [form] = Form.useForm()

  const emailValues = currentAppKey[0]?.emailCfg
  const loginUser = useSelector((state: any) => state.loginUser)
  const activeApp = useSelector((state: any) => state.activeApp)
  const appKey = useSelector((state: any) => state.app_keys)
  const [appTabFilter, setAppTabFilter] = useState("email")
  const [loading, setLoading] = useState(false)
  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  })
  const appId = activeApp.appId
  const api_key = loginUser.data.api_key

  const onTabClick: MenuProps["onClick"] = (e) => {
    setAppTabFilter(e.key)
  }
  const onFormSubmit = (values: any) => {
    const { partner, smtp_port, ...rest } = values
    const currentDateTime = new Date()
    const isoDateTime = currentDateTime.toISOString()
    const port = parseInt(smtp_port, 10) // Assuming base 10
    const payload = {
      args: JSON.stringify({
        // ...values,
        ...(appTabFilter === "email" && { smtp_port: port }),
        ...rest,
        app_id: appId,
        partner: partner || "Netcore",

        api_key: api_key,
        appKey: api_key,
        date: isoDateTime,
        active:
          appTabFilter == "generic"
            ? "generic"
            : appTabFilter == "email"
            ? "smtp"
            : "aws_ses",
      }),
      app_id: appId,
      api_key: api_key,
    }

    sendEmailData(payload)
    updateCurrentAppKey()
    getToken(appId, api_key)
  }
  async function sendEmailData(formData: any) {
    const url: string = `/i/apps/saveEmailConfiguration`

    try {
      setLoading(true)
      const data = await centralApi("POST", url, formData, null)
      setShowMessage({
        type: "success",
        message: "Successfully Saved",
        show: true,
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setShowMessage({
        type: "error",
        message: "Something went wrong",
        show: true,
      })
    }
    hideMessage()
  }
  const hideMessage = () => {
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false })
    }, 2000)
  }
  useEffect(() => {
    getToken(appId, api_key)
  }, [])

  useEffect(() => {
    // Set initial form values when currentAppKey changes
    const updatedFormValues = {
      smtp_username: emailValues?.smtp?.smtp_un,
      smtp_host: emailValues?.smtp?.smtp_un,
      smtp_password: emailValues?.smtp?.smtp_h,
      smtp_port: emailValues?.smtp?.smtp_po,
      smtp_fromAddress: emailValues?.smtp?.smtp_fromAddress,
      smtp_fromName: emailValues?.smtp?.smtp_fromName,
    }
    form.setFieldsValue(updatedFormValues)
  }, [currentAppKey, form])
  useEffect(() => {
    // Set initial form values when currentAppKey changes
    const updatedFormValues = {
      aws_accessKeyId: emailValues?.aws_ses?.aws_accessKeyId,
      aws_secretAccessKey: emailValues?.aws_ses?.aws_secretAccessKey,
      aws_region: emailValues?.aws_ses?.aws_region,
      aws_sourceEmail: emailValues?.aws_ses?.aws_sourceEmail,
    }
    form.setFieldsValue(updatedFormValues)
  }, [currentAppKey, form])
  useEffect(() => {
    // Set initial form values when currentAppKey changes
    const updatedFormValues = {
      httpRequest: emailValues?.generic?.Verb,
      callBackUrl: emailValues?.generic?.callBackUrl,
      url: emailValues?.generic?.URL,
      emailProviderType: emailValues?.generic?.emailProviderType,
      partner: emailValues?.generic?.partner,
      keyValue: emailValues?.generic?.key_value,
    }
    form.setFieldsValue(updatedFormValues)
  }, [currentAppKey, form])
  return (
    <Fragment>
      <div className="email-setting-container ">
        <div className="mt-4 mb-4  ">
          <h6 className="email-setting-container-heading">Email Settings</h6>
          <Divider className="email-setting-custom-divider" />
        </div>

        <div className="email-setting-nav mt-4">
          <InnerNav
            theme="light"
            onTabClick={onTabClick}
            selected={appTabFilter}
            style={{
              alignItems: "center",
              marginLeft: "-16px",
              border: "none",
            }}
            menu={[
              {
                label: (
                  <Fragment>
                    <img
                      src={netcore}
                      alt="NetCore Icon"
                      className={`email-setting-img ${
                        appTabFilter === "email" ? "email-selected-tab" : ""
                      }`}
                    />
                  </Fragment>
                ),
                key: "email",
              },
              {
                label: (
                  <Fragment>
                    <img
                      src={aws}
                      alt="AWS Icon"
                      className={`email-setting-img ${
                        appTabFilter === "budget" ? "email-selected-tab" : ""
                      }`}
                    />
                  </Fragment>
                ),
                key: "budget",
              },
              {
                label: (
                  <Fragment>
                    <img
                      src={aws}
                      alt="AWS Icon"
                      className={`email-setting-img ${
                        appTabFilter === "budget" ? "email-selected-tab" : ""
                      }`}
                    />
                  </Fragment>
                ),
                key: "generic",
              },
            ]}
          />
        </div>
        {showMessage.show && (
          <ShowMessage type={showMessage.type} content={showMessage.message} />
        )}
        {appTabFilter === "email" && (
          <div className="d-flex flex-column gap-4 mt-4 mb-4">
            <div className=" col-sm-8  align-items-center email-setting-form ">
              {loading ? (
                <Spinner />
              ) : (
                <Form
                  form={form}
                  layout="horizontal"
                  // requiredMark={journeyData}
                  onFinish={onFormSubmit}
                >
                  <Form.Item
                    label="SMTP User Name"
                    name={"smtp_username"}
                    rules={[
                      {
                        required: true,
                        message: "Please input SMTP User name!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="SMTP Password"
                    name={"smtp_password"}
                    rules={[
                      { required: true, message: "Please input SMTP Password" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Host"
                    name={"smtp_host"}
                    rules={[{ required: true, message: "Please input Host" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Port"
                    name={"smtp_port"}
                    rules={[{ required: true, message: "Please input Port" }]}
                  >
                    <TextInput type="number" />
                  </Form.Item>
                  <Form.Item
                    label="From Name"
                    name={"smtp_fromName"}
                    rules={[
                      { required: true, message: "Please input From Name" },
                    ]}
                  >
                    <TextInput />
                  </Form.Item>
                  <Form.Item
                    label="From Address"
                    name={"smtp_fromAddress"}
                    rules={[
                      {
                        required: true,
                        message: "Please input From Address",
                        type: "email",
                      },
                    ]}
                  >
                    <TextInput />
                  </Form.Item>

                  <div className="">
                    <PrimaryButton
                      size="middle"
                      type="primary"
                      htmlType="submit"
                      style={{ border: "none", width: "90px" }}
                    >
                      Save
                    </PrimaryButton>
                  </div>
                </Form>
              )}
            </div>
          </div>
        )}
        {appTabFilter === "budget" && (
          <div className="d-flex flex-column gap-4 mt-4 mb-4">
            <div className=" col-sm-8  align-items-center email-setting-form ">
              {loading ? (
                <Spinner />
              ) : (
                <Form form={form} layout="horizontal" onFinish={onFormSubmit}>
                  <Form.Item
                    label="Access key ID"
                    name={"aws_accessKeyId"}
                    rules={[
                      {
                        required: true,
                        message: "Please input Access Key ID!",
                      },
                    ]}
                  >
                    <TextInput />
                  </Form.Item>
                  <Form.Item
                    label="Secret Access KeyID"
                    name={"aws_secretAccessKey"}
                    rules={[
                      {
                        required: true,
                        message: "Please input Secret Access KeyID",
                      },
                    ]}
                  >
                    <TextInput />
                  </Form.Item>
                  <Form.Item
                    label="Region"
                    name={"aws_region"}
                    rules={[{ required: true, message: "Please input Region" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Source Email"
                    name={"aws_sourceEmail"}
                    rules={[
                      {
                        required: true,
                        message: "Please input Source Email",
                        type: "email",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <div className="">
                    <PrimaryButton
                      size="middle"
                      type="primary"
                      htmlType="submit"
                      style={{ border: "none", width: "90px" }}
                    >
                      Save
                    </PrimaryButton>
                  </div>
                </Form>
              )}
            </div>
          </div>
        )}
        {appTabFilter === "generic" && (
          <div className="d-flex flex-column gap-4 mt-4 mb-4">
            <div className=" col-sm-8  align-items-center email-setting-form ">
              {loading ? (
                <Spinner />
              ) : (
                <Form form={form} layout="horizontal" onFinish={onFormSubmit}>
                  <Form.Item
                    label="Destination"
                    name={"callBackUrl"}
                    rules={[
                      {
                        required: true,
                        message: "Please input Destination ",
                      },
                    ]}
                  >
                    <TextInput />
                  </Form.Item>
                  <Form.Item
                    label="Url"
                    name={"url"}
                    rules={[
                      {
                        required: true,
                        message: "Please input url",
                      },
                    ]}
                  >
                    <TextInput />
                  </Form.Item>

                  <Form.Item
                    label="HTTP method"
                    name={"httpRequest"}
                    rules={[{ required: true, message: "Select method" }]}
                  >
                    <Selector
                      // onChange={handleDisableButton}
                      size="middle"
                      style={{ width: "100%" }}
                      // defaultValue={"NONE"}
                      options={[
                        {
                          value: "GET",
                          label: "GET",
                        },
                        {
                          value: "POST",
                          label: "POST",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Partner"
                    name={"partner"}
                    rules={[{ required: true, message: "Select method" }]}
                  >
                    <Selector
                      // onChange={handleDisableButton}
                      size="middle"
                      style={{ width: "100%" }}
                      // defaultValue={"NONE"}
                      options={[
                        {
                          value: "Netcore",
                          label: "NETCORE",
                        },
                        {
                          value: "Gupshup",
                          label: "GUPSHUP",
                        },
                        {
                          value: "Sinch",
                          label: "SINCH",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Email provider type"
                    name={"emailProviderType"}
                    rules={[{ required: true, message: "Select method" }]}
                  >
                    <Selector
                      // onChange={handleDisableButton}
                      size="middle"
                      style={{ width: "100%" }}
                      // defaultValue={"NONE"}
                      options={[
                        {
                          value: "smtp",
                          label: "SMTP",
                        },
                        {
                          value: "ses",
                          label: "SES",
                        },
                        {
                          value: "generic",
                          label: "GENERIC",
                        },
                        {
                          value: "cns",
                          label: "CNS",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item label={t("valuesLabel") as string}>
                    <Form.List
                      name="keyValue"
                      initialValue={
                        emailValues?.generic?.key_value || [
                          { key: "", value: "" },
                        ]
                      }
                    >
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space
                              key={key}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              align="baseline"
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "key"]}
                                rules={[
                                  {
                                    required:
                                      form.getFieldValue("httpMethod") ===
                                        "GET" ||
                                      form.getFieldValue("httpMethod") ===
                                        "POST",
                                    message: t(
                                      "missingKeyValueLabel"
                                    ) as string,
                                  },
                                ]}
                              >
                                <TextInput maxLength={100} placeholder="Key" />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "value"]}
                                rules={[
                                  {
                                    required:
                                      form.getFieldValue("httpMethod") ===
                                        "GET" ||
                                      form.getFieldValue("httpMethod") ===
                                        "POST",
                                    message: t("plzInputLabel") as string,
                                  },
                                ]}
                              >
                                <TextInput
                                  maxLength={100}
                                  placeholder="Value"
                                />
                              </Form.Item>
                              {fields.length > 1 && (
                                <MinusCircleOutlined
                                  onClick={() => remove(name)}
                                />
                              )}
                            </Space>
                          ))}
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              {t("addFieldLabel")}
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Form.Item>

                  <div className="">
                    <PrimaryButton
                      size="middle"
                      type="primary"
                      htmlType="submit"
                      style={{ border: "none", width: "90px" }}
                    >
                      Save
                    </PrimaryButton>
                  </div>
                </Form>
              )}
            </div>
          </div>
        )}
      </div>
    </Fragment>
  )
}
export default EmailSetting
