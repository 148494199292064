import {  useState, Fragment } from "react";

import "./EmailNav.css";
import InnerNav from "../../../../../components/InnerNav/InnerNav";
import type { MenuProps } from "antd";
import BudgetSetting from "../BudgetSetting/BudgetSetting";
import EmailSetting from "../EmailSetting/EmailSetting";
import React from 'react';
interface EmailNavProps {
  currentAppKey: any;
  updateCurrentAppKey: () => void;

}

const EmailNav: React.FC<EmailNavProps> = ({ currentAppKey,updateCurrentAppKey }) => {
  const [appTabFilter, setAppTabFilter] = useState("email");
  const [selectedComponent, setSelectedComponent] = useState(<EmailSetting currentAppKey={currentAppKey}  updateCurrentAppKey={updateCurrentAppKey}/>);

  const onTabClick: MenuProps["onClick"] = (e) => {
    setAppTabFilter(e.key);
    switch (e.key) {
      case "email":
        setSelectedComponent(<EmailSetting currentAppKey={currentAppKey} updateCurrentAppKey={updateCurrentAppKey}/>);
        break;
      case "budget":
        setSelectedComponent(<BudgetSetting currentAppKey={currentAppKey} updateCurrentAppKey={updateCurrentAppKey} />);
        break;

      default:
        setSelectedComponent(<div></div>); // Default empty component
    }
  };
  return (
    <Fragment>
      <div className="app-setting-container ">
        <div className="app-setting-nav">
          <InnerNav
            theme="light"
            onTabClick={onTabClick}
            selected={appTabFilter}
            style={{ border: "none" }}
            menu={[
              {
                label: "Email Settings",
                key: "email",
              },
              {
                label: "Budget",
                key: "budget",
              },
            ]}
          />
        </div>
        {selectedComponent}
      </div>
    </Fragment>
  );
};
export default EmailNav;
