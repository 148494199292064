"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceInfo = void 0;
const typescript_json_serializer_1 = require("typescript-json-serializer");
let DeviceInfo = class DeviceInfo {
    constructor(sdkv, sdkiv, p, pv, av, ac, res, l, tz, did, _app_pack) {
        this.sdkv = sdkv;
        this.sdkiv = sdkiv;
        this.p = p;
        this.pv = pv;
        this.av = av;
        this.ac = ac;
        this.res = res;
        this.l = l;
        this.tz = tz;
        this.did = did;
        this._app_pack = _app_pack;
    }
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], DeviceInfo.prototype, "sdkv", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], DeviceInfo.prototype, "sdkiv", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], DeviceInfo.prototype, "p", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], DeviceInfo.prototype, "pv", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], DeviceInfo.prototype, "av", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], DeviceInfo.prototype, "ac", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], DeviceInfo.prototype, "res", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], DeviceInfo.prototype, "l", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], DeviceInfo.prototype, "tz", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], DeviceInfo.prototype, "did", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], DeviceInfo.prototype, "_app_pack", void 0);
DeviceInfo = __decorate([
    (0, typescript_json_serializer_1.JsonObject)(),
    __metadata("design:paramtypes", [String, Number, String, String, String, String, String, String, Number, String, String])
], DeviceInfo);
exports.DeviceInfo = DeviceInfo;
