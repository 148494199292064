import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { FC } from "react";
import "./Dashboard.css";
import stars from "../../../../images/starsPen.svg";
import pen from "../../../../images/Pen2.svg";
import insights from "../../../../images/insights.svg";
import campaigns from "../../../../images/campaigns.svg";
import workflow from "../../../../images/workflow.svg";
import segemnets from "../../../../images/segments.svg";
import insta from "../../../../images/insta.svg";
import campaignPush from "../../../../images/mobilepush.svg";
import threeStar from "../../../../images/threestar.svg";
import {  useNavigate } from "react-router-dom";

import templates from "../../../../images/alltemplates.svg";
import sentimentanalysis from "../../../../images/sentimentanalysis.svg";
import TextInput from "components/Inputs/TextInput/TextInput";
import CustomTable from "components/CustomTable/CustomTable";
import PushGenAi from "../PushGenAi/PushGenAi";


interface DashboardProps {}

const Dashboard: FC<DashboardProps> = ({}) => {
  const navigate = useNavigate();

  const columns = [
    {
      title: "File name",
      dataIndex: "name",
      key: "name",
      render: (text:any, record:any) => (
        <div>
          <img src={record.image} alt={text} style={{ marginRight: 8, width: 24, height: 24 }} /> {/* Assuming there's an "image" property in your data */}
          {text}
        </div>
      ),
      // render: (text) => <a>{text}</a>,
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
    },
    {
      title: "Last modified",
      dataIndex: "date",
      key: "date",
    },
  ];
  const data = [
    {
      // key: 'name',
      name: "Instagram captions",
      date: "Apr 24 2024",
      owner: "ashish luthra",
      image: insta     // Image URL for the row

    },
    {
      // key: 'name',
      name: "Mobile push campaigns",
      date: "Apr 24 2024",
      owner: "ashish luthra",
      image: campaignPush     // Image URL for the row

    },
  ];
  function Cards({ icon, name,navigateTo }: any) {
  
    return (
      // <PrimaryButton type="link">
      <div className="ai-dashboard-card-container" onClick={navigateTo}>
      <img
          src={icon}
          alt={"icon"}

          // style={{ width: 16 }}
        ></img>
        <div className="mt-2 ">
          <p className=" ai-dashboard-card-container-text">{name} </p>
        </div>
      </div>
      // </PrimaryButton>
    );
  }
  return (
    <div className=" m-2 ai-dashboard-container">
      <div className=" d-flex gap-4 align-items-center justify-content-center ai-dashboard-container-heading">
        <img
          src={stars}
          alt={"stars"}
          style={{ width: 28 }}
        ></img>
        <p className="dashboard-container-heading-content">What do you want to create?</p>
      </div>
      <div className=" d-flex justify-content-center " >
      <div className="d-flex  align-items-center justify-content-center position-relative dashboard-container-button-container">
        <TextInput
          placeholder="Build a reengagement campaign"
          size="middle"
          className="ai-dashboard-container-heading-search"
        />
        <PrimaryButton  className="position-absolute ai-dashboard-container-heading-search-button " type="primary">Create with Xmit</PrimaryButton>
        <img
        className="position-absolute ai-dashboard-container-heading-search-button-img" 
          src={threeStar}
          alt={"stars"}
        ></img>
      </div>
    </div> 
      <div className="d-flex mt-4 mb-3 gap-1 align-items-center justify-content-center">
        <img
          src={pen}
          alt={"pen"}
          // style={{ width: 16 }}
        ></img>
        <p className="mb-0" >Quick starts</p>
      </div>
      <div className="d-flex gap-4 align-items-center justify-content-center">
        <Cards name="Campaigns" icon={campaigns}   navigateTo={() => navigate("/dashboard/push/genai")}
/>
        <Cards name="Sentiment analysis" icon={sentimentanalysis}/>
        <Cards name="Segments" icon={segemnets}/>
        <Cards name="Work flow" icon={workflow}/>
        <Cards name="Insights" icon={insights}/>
        <Cards name="Templates" icon={templates}/>

      </div>
      <div>
      <p className=" mt-3 mb-3 dashboard-container-recents-heading-content">Recents</p>

      </div>
      <div className="dashboard-container-table-container" >
      <CustomTable
              columns={columns}
              dataSource={data}
              pagination={false}
            />
      </div>
    </div>
  );
};
export default Dashboard;
