import React, { useState, useEffect } from "react";
import { t } from "i18next";
import { Helmet } from "react-helmet";
import "./FunnelDetails.css";
import CustomBreadCrumb from "../../../components/CustomBreadCrumb/CustomBreadCrumb";
import { Link, useParams } from "react-router-dom";
import useMessage from "../../../hooks/useMessage";
import centralApi from "services/centralApi";
import { useSelector } from "react-redux";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import FunnelGraph from "funnel-graph-js";
import LaunchCampaign from "./Components/LaunchCampaign";
import NoData from "components/NoData/NoData";
import { API_ENDPOINTS } from "utils/constants";
import { getObjectId } from "services/getObjectId";
import { getRandomNumber } from "helperFunctions/number";
import { getToken } from "services/getToken";
import FunnelExport from "./Components/FunnelExport";
import { addOffSet, currentEpochTime } from "helperFunctions/date";
import { useNavigate } from "react-router-dom";
import {
  removeTrailingNumber,
} from "helperFunctions/common";

type propTypes = {
  pageSize?: 10;
  tableSize?: 5;
};
const FunnelDetails: React.FC<propTypes> = () => {
  interface RootState {
    loginUser: {
      data: {
        api_key: string;
      };
    };
  }
  interface ActiveAppState {
    activeApp: {
      appName: string;
      appId: string;
    };
  }
  interface Event {
    eventName: string;
    list: any[];
    selectedJourney: string;
  }

  interface Funnel {
    _id: string;
    events: Event[];
    name: string;
    description: string;
    duration: string;
    unit: string;
    customunit: string;
    platform: string[];
    customDate: Record<string, any>;
    id: string | null;
    createdOn: number;
    modifiedOn: number;
    deleted: boolean;
  }
  interface Attribute {
    attributeName: string;
    type: string;
    operator: string;
    value: string;
    e_operator: string;
  }
  interface StatsData {
    name: string;
    count: number;
    timeStamp: string;
    lastTimeStamp: string;
    customUnit: string;
    events: {
      eventName: string;
      operator: string;
      value: string;
      attributes: Attribute[];

      list: {
        name: string;
        type: string;
      }[];
    };
    p: string[];
    result:string;
  }

  interface SelectedSteps {
    [index: number]: "h" | "hn";
  }

  const { id } = useParams();
  const navigate = useNavigate();

  const loginUser = useSelector((state: RootState) => state.loginUser);
  const { appId } = useSelector((state: ActiveAppState) => state.activeApp);
  const [funnels, setFunnels] = useState<Funnel | null>(null);

  const [objectId, setObjectId] = useState("");

  const [statsData, setStatsData] = useState<StatsData[]>([]);

  const api_key = loginUser?.data.api_key;
  const { showSuccess, showError } = useMessage();

  const [launchCampaign, setLaunchCampaign] = useState<boolean>(false);
  const [funnelExport, setFunnelExport] = useState<boolean>(false);

  const getObject = async () => {
    try {
      const objectid = await getObjectId(appId, api_key);
      setObjectId(objectid);
    } catch (error) {
      showError(t("somethingWrongLabel"));
    }
  };
  const startExport = async (q: string) => {
    const payload = {
      api_key: api_key,
      app_id: appId,

      args: JSON.stringify({ q: q }),
    };

    try {
      await centralApi("GET", API_ENDPOINTS.START_EXPORT, null, payload);
    } catch (error: any) {
      showError(error.message);
    }
  };

  const getFunnel = async () => {
    const payload = {
      api_key: api_key,
      app_id: appId,
      id: id,
    };

    try {
      const response = await centralApi(
        "GET",
        API_ENDPOINTS.GETFUNNEL,
        null,
        payload
      );
      setFunnels(response);
    } catch (error: any) {
      showError(error.message);
    }
  };

  const getStats = async () => {
    const offset = addOffSet();
    const d = currentEpochTime();

    const payload = {
      api_key: api_key,
      app_id: appId,
      actor_property: "did",
      // args: serializedData,
      args: JSON.stringify(funnels),
      d,
      offset,
      platform: "all",
    };

    try {
      const response = await centralApi(
        "GET",
        API_ENDPOINTS.STATS,
        null,
        payload
      );
      setStatsData(response);
    } catch (error: any) {
      showError(error.message);
    }
  };
  // const totalFunnelSteps = statsData?.length;
  const totalFunnelSteps = (statsData && statsData?.result !== "Failed" && statsData.length > 0) ? statsData.length : 0;
  const generateSortedAttribute = (selectedSortAttribute: any) => {
    //   // check if there is event containing the selected attribute
    const event = statsData[parseInt(selectedSortAttribute.index)];
    if (event) {
      //check if that event has attributess
      const attribute = event.events.list.find(
        (attr) => attr.name === selectedSortAttribute.label
      );

      if (attribute) {
        return {
          index: selectedSortAttribute.index, // selected attribute index of its event
          name: selectedSortAttribute.name,
          value: selectedSortAttribute.label,
          step: `step${parseInt(selectedSortAttribute.index) + 1}Segment -> '${
            selectedSortAttribute.label
          }'`, //  step format
        };
      } else {
        // If event not found, handle as a generic column
        return {
          name: "column",
          value: selectedSortAttribute.label, // sortAttribute selected from dropdown
          col: "column",
        };
      }
    }
  };

  const handleCreateAudience = async (
    selectedSteps: SelectedSteps,
    name: string,
    description: string
  ) => {
    const what = statsData?.map((event, index) => ({
      operand: removeTrailingNumber(event.name), //remove _2 3 or anything appended to event name
      operator: event?.events?.operator || "", // Use default value if operator is not present
      value: event?.events?.value || "", // Use default value if value is not present
      category: "Events", // Category"
      attributes: event?.events?.attributes?.map(attr => ({
        attributeName: attr?.attributeName || "",
        type: attr?.type || "",
        operator: attr?.operator || "",
        value: attr?.value || "",
        e_operator: attr?.e_operator || ""
      })) || "", // Map attributes or use default value if not present
      since: { have: selectedSteps[index + 1] || "" }, // Use selectedSteps value
    }));
    const segmentInfo = {
      who: [],
      what: [...(what || [])], // Use default empty array if whatSegment is undefined
      where: [],
      when: [],
    };

    const uniqueNumber = getRandomNumber();
    try {
      const params: any = {
        api_key: api_key,
        app_id: appId,
        uniqueNumber: uniqueNumber,
      };
      const data = new FormData();
      data.append("objectid", objectId);
      data.append("name", name);
      data.append("description", description);
      data.append("range", "0");
      data.append("segmentinfo", JSON.stringify(segmentInfo));
      data.append("api_key", api_key);
      data.append("app_id", appId);

      const resAud = await centralApi(
        "POST",
        API_ENDPOINTS.CREATE_AUDIENCE_URL,
        data,
        params
      );
      if (resAud?.name) {
        showSuccess(t("segmentCreatedSuccessfullyMsg"));
        navigate("/dashboard/campaign/create");
      } else {
        throw new Error();
      }
    } catch (error) {
      showError(t("somethingWrongLabel"));
    }
    getToken(appId, api_key);
  };
  const handleCountAudienceReach = async (
    criteria: any,
    selectedSortAttribute: any,
    sortBy: string,
    attrColumns: any
  ) => {
    //chceck if it is not empty
    const sortedAttribute =
  Object.keys(selectedSortAttribute).length > 0
      && generateSortedAttribute(selectedSortAttribute);
    // events data for payload from Stats Api
    
const events = statsData?.map((events) => ({
      users: events?.count,
      key: events?.name,
      timeStamp: events?.timeStamp,
      lastTimeStamp: events?.lastTimeStamp,
      customUnit: events?.customUnit,
      events: events?.events,
      p: events?.p,
      attribute: events?.events?.attributes || "",
    }));

    const userProperties = [
      {
        col: "a.did",
        alias: "deviceId",
      },
      {
        col: "a.user_info->>'e'",
        alias: "email",
      },
      {
        col: "a.user_info->>'p'",
        alias: "phone",
      },
      {
        col: "a._custom_userid",
        alias: "userid",
      },
    ];
    const column = {
      sortedAttribute,
      userProperties,
      attributeColumns: attrColumns,
    };

    const { timeStamp, lastTimeStamp, p, customUnit } = statsData[0] || {};

    const method = "countAudienceReach";
    const selectors = {
      column,
      countOrData: false,
      customUnit,
      events,
      execute: false,
      export: true,
      generate: true,
      lastTimeStamp,
      p,
      sortBy,
      timeStamp,
    };

    try {
      const payload: any = {
        api_key: api_key,
        app_id: appId,
        criteria: JSON.stringify(criteria),
        method,

        selectors,
      };
      const countAudience = await centralApi(
        "POST",
        API_ENDPOINTS.COUNT_AUDIENCE_REACH_URL,
        payload,
        null
      );
      if (countAudience?.result?.q) {
        startExport(countAudience?.result?.q);
      }
    } catch (error) {
      showError(t("somethingWrongLabel"));
    }
    getToken(appId, api_key);
  };
  
  
  

  const nameWithAttributes =totalFunnelSteps>0?
   statsData?.map((item) => {
    if (!item.name || !item.events.attributes) {
      return {
        name:"",
        count:""
      }; 
    }
    const strippedName = removeTrailingNumber(item.name); //remove trailing number
    const attributes = item?.events?.attributes
      .map((attr: any) => `${attr.attributeName}`)
      .join(", ");
    const name = attributes
      ? `${strippedName} (${attributes})`
      : strippedName;
    return {
      name: name,
      count: item.count,
    };
  }):[]
  const validNameWithAttributes = Array.isArray(nameWithAttributes) && nameWithAttributes.length > 0
  ? nameWithAttributes.map((item) => ({
      name: item.name || "",
      count: item.count || 0
    }))
  : [];
  // Ensure that values are converted to numbers
const values = validNameWithAttributes.map((item) => Number(item.count)); //check for eventname has attributes
  useEffect(() => {
    if (totalFunnelSteps > 0) {
      //map graph only if data present
      const graph = new FunnelGraph({
        container: ".funnelContainer ",
        gradientDirection: "vertical",
        data: {
          labels: validNameWithAttributes?.map((item) => item.name),
    colors: ["orange", "red"], // Ensure you have enough colors if the number of labels exceeds the colors array
    values: values, //  the array of numbers for values

        },
        displayPercent: true,
        direction: "horizontal",
        width: 800,
        height: 350,
        subLabelValue: "raw",
      });

      graph.draw();
    }
  }, [statsData]);

  useEffect(() => {
    getFunnel();
    getObject();
    getToken(appId, api_key);
  }, []);
  useEffect(() => {
    if (funnels !== null) {
      getStats();
    }
  }, [funnels]);

  return (
    <div className="container-fluid p-sm-2 p-2 ">
      <Helmet>
        <title>Appice | Funnel detail</title>
      </Helmet>
      <div className="d-flex justify-content-between align-items-center py-3 flex-wrap">
        <div>
          <CustomBreadCrumb
            items={[
              {
                title: <Link to="/dashboard/funnels">{t("funnelLabel")}</Link>,
              },
              {
                title: <Link to="">{t("detailLabel")}</Link>,
              },
            ]}
          />
        </div>
      </div>

      <div className="p-sm-3 px-1 funnel-detail-container">
        <div className="d-flex  justify-content-between mb-4">
          <h6>{funnels?.name}</h6>
          {totalFunnelSteps > 0 && (
            <div className="d-flex gap-2">
              <PrimaryButton
                onClick={() => {
                  setLaunchCampaign(true);
                }}
                type="primary"
              >
                {t("launchCampaignLabel")}
              </PrimaryButton>
              <PrimaryButton
                onClick={() => {
                  setFunnelExport(true);
                }}
                type="primary"
              >
                {t("funnelExportLabel")}
              </PrimaryButton>
            </div>
          )}
        </div>
        {totalFunnelSteps > 0 ? (
          <div>
            <div className="funnelContainer" />
          </div>
        ) : (
          <NoData />
        )}
                {totalFunnelSteps > 0 ? (
                  <>

        <LaunchCampaign
          launchCampaignState={launchCampaign}
          handleCreateAudience={handleCreateAudience} // Pass the handleSubmit function as the onSubmit prop
          totalSteps={totalFunnelSteps}
          setLaunchCampaignState={setLaunchCampaign}
        />

        <FunnelExport
          funnelExportState={funnelExport}
          handleCountAudienceReach={handleCountAudienceReach} // Pass the handleSubmit function as the onSubmit prop
          statsData={statsData}
          totalSteps={totalFunnelSteps}
          setFunnelExportState={setFunnelExport}
        /></>):null}
      </div>
    </div>
  );
};
export default FunnelDetails;
