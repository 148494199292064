import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";
import "./ToggleBtn.css";
import "../../../global.css";
import { t } from "i18next";
type propTypes = {
  isApproved: boolean;
  onChange: any;
  trueText?:string
  falseText?:string
  style?:React.CSSProperties

};
function ToggleBtn({ isApproved, onChange,falseText,trueText,style }: propTypes) {
  const btnStyle={
    width: "85px",
    fontSize: "var(--font-size-xsm)",
    padding: "6px 2px",
  }
  return (
    <ToggleButtonGroup
      onChange={onChange}
      value={isApproved}
      exclusive
      aria-label="Platform"
      size="small"
    >
      <ToggleButton
        value={true}
        style={style?style:btnStyle}
      >
       {!trueText?t("approvedLabel"):trueText}
      </ToggleButton>
      <ToggleButton
        value={false}
        style={style?style:btnStyle}
      >
       {!falseText?t("notApprovedLabel"):falseText}
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default ToggleBtn;
