import { message } from 'antd';
import { useCallback } from 'react';

const useMessage = () => {
  const showError = useCallback((errorMessage:string, duration = 2000) => {
    message.destroy()
    message.error({
      content: errorMessage,
      style: {
        marginTop: '10vh',
      },
      duration:duration/1000
    });
  }, []);

  const showSuccess = useCallback((successMessage:string, duration = 2000) => {
    message.destroy()
    message.success({
      content: successMessage,
      style: {
        marginTop: '10vh',
      },
      duration:duration/1000
    });
  }, []);
  const showWarning = useCallback((warningMessage:string, duration = 2000) => {
    message.destroy()
    message.warning({
      content: warningMessage,
      style: {
        marginTop: '10vh',
      },
      duration:duration/1000
    });
  }, []);

  return {
    showError,
    showSuccess,
    showWarning
  };
};

export default useMessage;
