import { FC, useState, useEffect, useRef } from "react";
import {  Col, Form, Row } from "antd";
import FormItem from "antd/es/form/FormItem";
import Selector from "components/Selector/Selector";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import CampaignDevice from "components/CampaignDevice/CampaignDevice";
import TextInput from "components/Inputs/TextInput/TextInput";
import "./WhatsApp.css";
import CustomTextArea from "components/Inputs/CustomTextArea/CustomTextArea";
import EmojiPicker from "components/EmojiPicker/EmojiPicker";
import { SmileOutlined, MoreOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import centralApi from "services/centralApi";
import { getToken } from "services/getToken";
import TemplateLayout from "../../../layout/TemplateLayout/TemplateLayout";
import { setCurrentStep } from "redux/features/campaign/campaignCreateCurrentStepSlice";
import { useForm } from "antd/es/form/Form";
import fileIcon from "images/File.svg";
import videoIcon from "images/video.svg";
import textIcon from "images/Text.svg";
import imageIcon from "images/image.svg";
import { t } from "i18next";
import WhatsAppContainer from "../../NotificationContainer/WhatsAppContainer/WhatsAppContainer";
import Personalization from "../../Personalization/Personalization";
import { fetchTemplatesList } from "redux/features/templates_/templatesByTypeSlice";
import {
  fetchTemplateById,
  setSelectedTemplate,
} from "redux/features/templates_/templateByIdSlice";
import { setCampaignData } from "redux/features/campaign/campaignDataSlice";
import { ActionButtons } from "components/Buttons/ActionButtons/ActionsButtons";
import { formatDate, getCurrentDate } from "helperFunctions/date";
import useMessage from "hooks/useMessage";
import { validateImageDimensions } from "helperFunctions/file";
import { validateName } from "services/validateName";

interface WhatsAppProps {}
const WhatsApp: FC<WhatsAppProps> = () => {
  const dispatch: any = useDispatch();
  const [form] = useForm();

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const [personalization, setPersonalization] = useState({
    open: false,
    field: "",
  });
  const [template, setTemplate] = useState({
    template_name: "",
    template_type: "WHATSAPP",
    isTemplateDeleted: "false",
  });
  const { showError } = useMessage();
  const currentStep = useSelector(
    (state: any) => state.campaignCreateCurrentStep
  );
  const [formData, setFormData] = useState<any>({
    language: "",
    headerType: "text",
    category: "",
    headerTitle: "",
    description: "",
    templateId: "",
    imageUrl: "",
    videoUrl: "",
    fileUrl: { name: "", url: "" },
    actions: [
      {
        actionName: "",
        actionExternalUrl: "",
        actionType: "",
        urlType: "",
        countryCode: "",
      },
    ],
    variableInfo: undefined,
    // actions={},
  });
  const [image, setImage] = useState({
    imageUrl: { loading: false },
    fileUrl: { loading: false },
  });
  // const [selectedLanguage, setSelectedLanguage] = useState<any>({});
  const [oldTemplateName, setOldTemplateName] = useState(""); //old template name store to avoid validation if remplate name does not change

  const templates = useSelector((state: any) => state.templatesList.data);
  const selectedTemplate = useSelector((state: any) => state.templateById.data);
  const campaignData = useSelector((state: any) => state.campaignData.data);
  const [blobContainerCDN, setBlobContainerCDN] = useState(
    campaignData?.data?.blobContainerCDN || ""
  );
  const emojiPickerRef = useRef(null);

  const handleToggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };
 
  const handleEmojiClick = (emojiObject: any) => {
    setFormData((prevState: any) => ({
      ...prevState,
      description: `${prevState?.description || ""}${emojiObject?.emoji}`,
    }));
    setShowEmojiPicker(false);
  };

  // Determine the text direction based on the language
  // const direction = selectedLanguage === "ar" ? "rtl" : "ltr";
  // const langAttributes = langAttribute === "ar" ? "ar" : "en"

  useEffect(() => {
    dispatch(
      fetchTemplatesList({
        app_id: appId,
        api_key: loginUser.api_key,
        template_type: "WHATSAPP",
      })
    );

    getToken(appId, loginUser.api_key);
    if (campaignData.data) {
      dispatch(setSelectedTemplate(campaignData.data));
    }
  }, []);
  useEffect(() => {
    if (selectedTemplate) {
      dispatch(
        setCampaignData({
          ...campaignData,
          data: selectedTemplate,
          tid: selectedTemplate._id,
          t: selectedTemplate.template_type,
        })
      );
      
    }
  }, [selectedTemplate]);
  useEffect(() => {
    const { template, template_name, template_type, blobContainerCDN } =
      selectedTemplate || campaignData.data || formData;
    if (blobContainerCDN) {
      setBlobContainerCDN(blobContainerCDN);
    }
    if (template_name) {
      setTemplate((prevState) => ({
        ...prevState,
        template_name,
        template_type,
      }));
    }
    if (template) {
      const {
        language,
        headerType,
        category,
        headerTitle,
        description,
        templateId,
        imageUrl,
        videoUrl,
        actions,
        fileUrl,
        variableInfo,
      } = template;
      setFormData((prevState: any) => ({
        ...prevState,
        language,
        headerType,
        category,
        headerTitle,
        description,
        templateId,
        imageUrl,
        videoUrl,
        actions,
        fileUrl,
        variableInfo,
      }));
     
    }
    setOldTemplateName(template_name); // save template name

  }, [selectedTemplate, campaignData]);
  const handleTemplateUpdate = async () => {
    try {
      const payload = {
        api_key: loginUser.api_key,
        app_id: appId,
        template_id: selectedTemplate._id,
        args: JSON.stringify({
          ...template,
          template: formData,
          modifiedOn: formatDate(getCurrentDate(), "MM/DD/YYYY"),
        }),
      };
      const res = await centralApi("POST", "i/templates/update", payload);
      dispatch(
        setCampaignData({
          ...campaignData,
          data: { ...res, blobContainerCDN },
          tid: res._id,
          t: res.template_type,
        })
      );
      dispatch(setCurrentStep(currentStep + 1));
    } catch (error) {console.log("error")}
  };
  const handleTemplateSave = async () => {
    try {
      const payload = {
        api_key: loginUser.api_key,
        app_id: appId,
        args: JSON.stringify({
          ...template,
          template: formData,
          createdOn: formatDate(getCurrentDate(), "MM/DD/YYYY"),
          modifiedOn: "",
        }),
      };
      const res = await centralApi("POST", "i/templates/create", payload);
      dispatch(
        setCampaignData({
          ...campaignData,
          data: { ...res, blobContainerCDN },
          tid: res._id,
          t: res.template_type,
        })
      );
      dispatch(setCurrentStep(currentStep + 1));
    } catch (error) {
      showError((error as Error).message);

    }
  };
  const handleSaveOrUpdate = async () => {
    try {
      // Perform name validation
      let isValidTemplateName;
      // Call validateName only when template name is changed
      if (template.template_name !== oldTemplateName) {
        try {
          isValidTemplateName = await validateName(
            template.template_name,
            "WHATSAPP",
            loginUser.api_key,
            appId
          );
          // Proceed with form validation and submission
        } catch (error) {
          showError((error as Error).message);
        }
      }

      // Validate the form fields
      if (isValidTemplateName) {
        form.setFields([
          {
            name: "templateName",
            errors: [t("templateNameExistsErrorMessage")],
          },
        ]);
      } else {
        //check other fields validation
        await form.validateFields();
        // Form is valid, proceed with saving or updating
        selectedTemplate ? handleTemplateUpdate() : handleTemplateSave();
      }
    } catch (errors) {
      console.log("Validation errors:", errors);
    }
  };
 
 
  console.log("template", template);
  const handleImageUpload = async (
    value: any,
    // type: "imageUrl | fileUrl "
    type: "imageUrl" | "fileUrl" | "videoUrl"
  ) => {
    try {
      // const { valid, message } = await validateImageDimensions(
      //  type== "imageUrl"?"imageWhatsApp":type,
      //   value.file
      // );
      const { valid, message } =
        type === "imageUrl"
          ? await validateImageDimensions("imageWhatsApp", value.file)
          : { valid: true, message: "" }; // Skip validation for non-image types

      if (valid && appId) {
        //upload image
        setImage((prevState: any) => ({
          ...prevState,

          [type]: {
            loading: true,
          },
        }));

        const formData = new FormData();
        formData.append("image", value.file);
        formData.append("image_id", value.file.name);
        formData.append("appid", appId);
        console.log("form Data", value.file.name);
        const res = await centralApi(
          "POST",
          "/i/campaignFiles/campaignimages",
          formData,
          null
        );
        setTimeout(() => {
          setBlobContainerCDN(res.baseURL);
          const imgUrl = `${res.baseURL + res.url}`;
          setFormData((prevState: any) => ({
            ...prevState,
            [type]:
              type == "fileUrl"
                ? { url: imgUrl, name: value.file.name }
                : imgUrl,
          }));

          setImage((prevState: any) => ({
            ...prevState,
            [type]: {
              loading: false,
            },
          }));
        }, 600);
      } else {
        showError(message);
      }
    } catch (error) {
      setImage((prevState: any) => ({
        ...prevState,
        [type]: {
          loading: false,
        },
      }));
    }
  };

 
  const HeaderButtons = ({ name, imgSrc, type, disabled, ghost }: any) => {
    return (
      <PrimaryButton
        // type="link"
        // ghost={ghost}
        className={`${ghost ? "whatsapp-device-headers-button" : ""}`}
        // type= {ghost?"link":undefined}
        disabled={selectedTemplate !== null ? disabled : false}
        onClick={() => {
          setFormData((prevState: any) => ({ ...prevState, headerType: type }));
        }}
      >
        <div
          className={`d-flex flex-wrap flex-column align-items-center whatsapp-header-buttons `}
        >
          <img
            className="engagement-tool-comp-icon"
            height={24}
            width={24}
            src={imgSrc}
          ></img>
          <span className="mt-1" style={{ fontSize: 12 }}>
            {name}
          </span>
        </div>
      </PrimaryButton>
    );
  };
  console.log("form data", formData);
  useEffect(() => {
    getToken(appId, loginUser.api_key);
  }, []);

  useEffect(() => {
    // Set initial values when template changes
    if (selectedTemplate) {
      form.setFieldsValue({
        template_name: template.template_name,
        });
    }
  }, [template, form]);
  return (
    <div className="d-flex gap-md-5 mx-3">
      {/* <div className="mx-3"> */}
      <TemplateLayout
        leftItems={
          <CampaignDevice
            campType="WHATSAPP"
            bazel={{ top: 88, left: 30, bottom: 100, right: 32 }}
          >
            <WhatsAppContainer
              headerTitle={formData?.headerTitle}
              description={formData?.description}
              imageUrl={formData?.imageUrl}
              videoUrl={formData?.videoUrl}
              fileUrl={formData?.fileUrl}
              headerType={formData.headerType}
              actions={formData?.actions}
              variableInfo={formData?.variableInfo}
              uploadImage={handleImageUpload}
              hideUpload={false}
              imageLoading={image}
            />
          </CampaignDevice>
        }
        // </div>
        rightItems={
          <div className="w-100 whatsapp-device-container">
            <Personalization
              personalizationState={personalization}
              setFormData={setFormData}
              setPersonalizationState={setPersonalization}
            />

            <Form colon={false} labelAlign="left" layout="vertical" form={form}>
              <Row className="pt-3  justify-content-between">
                <h5> WhatsApp campaign</h5>
              </Row>
              <Row className="pt-3">
                <Col span={24}>
                  <FormItem
                    className="my-0 w-100"
                    // name={"template_names"}
                    label=""
                  >
                    <Selector
                      // showSearch
                      onChange={(tempString) =>
                        dispatch(
                          fetchTemplateById({
                            app_id: appId,
                            api_key: loginUser.api_key,
                            template_id: JSON.parse(tempString)._id,
                          })
                        )
                      }
                      options={templates.map(
                        ({
                          template_name,
                          _id,
                        }: {
                          template_name: string;
                          _id: string;
                        }) => ({
                          label: template_name,
                          value: JSON.stringify({ _id, template_name }),
                        })
                      )}
                      placeholder={t("selectingExistingTemplateLabel")}
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row className="w-100 mt-3">
                <div className="d-flex w-100 gap-2">
                  <Col className="d-flex w-100 ">
                    <FormItem
                      className="m-0  w-100 "
                      initialValue={formData.language}
                      label=" Language"
                    >
                      <Selector
                        className="w-100"
                        onChange={(selectedValue) => {
                          setFormData((prevState: any) => ({
                            ...prevState,
                            language: selectedValue,
                          }));
                        }}
                        disabled={
                          selectedTemplate !== null ? formData?.language : false
                        }
                        options={[
                          {
                            label: "English",
                            value: "english",
                          },
                          {
                            label: "Hindi",
                            value: "hindi",
                          },
                          {
                            label: "Arabic",
                            value: "arabic",
                          },
                        ]}
                        placeholder="Select Language"
                        value={formData?.language}
                      />
                    </FormItem>
                  </Col>
                  <Col className="d-flex w-100 ">
                    <FormItem
                      className="m-0 w-100 "
                      initialValue={formData.category}
                      label="Category"
                    >
                      <Selector
                        onChange={(selectedValue) => {
                          setFormData((prevState: any) => ({
                            ...prevState,
                            category: selectedValue,
                          }));
                        }}
                        options={[
                          {
                            label: "Marketing",
                            value: "marketing",
                          },
                          {
                            label: "Utility",
                            value: "utility",
                          },
                        ]}
                        value={formData?.category}
                        disabled={
                          selectedTemplate !== null ? formData?.category : false
                        }
                        // disabled={formData?.category}
                        placeholder="Select Category"
                      />
                    </FormItem>
                  </Col>
                </div>
                <Col className="d-flex gap-1 " span={24}>
                  <FormItem
                    initialValue={template?.template_name}
                    className="w-100 m-0 mt-2  "
                    label={t("templateNameLabel")}
                    name={"template_name"}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <TextInput
                      onChange={(e: any) => {
                        setTemplate((prevState) => ({
                          ...prevState,
                          template_name: e.target.value,
                        }));
                      }}
                      placeholder={t("templateNameLabel")}
                      value={template.template_name}

                    ></TextInput>
                  </FormItem>
                </Col>
                <Col className="d-flex w-100 mt-3">
                  <FormItem
                    className=" w-100  m-0 whatsapp-device-vertical-form-item"
                    label=" Template id"
                    initialValue={formData.templateId}
                  >
                    <TextInput
                      // disabled={
                      //   selectedTemplateNew !== null
                      //     ? formData?.template_name
                      //     : false
                      // }
                      onChange={(e: any) => {
                        setFormData((prevState: any) => ({
                          ...prevState,
                          templateId: e.target.value,
                        }));
                      }}
                      value={formData?.templateId}
                    ></TextInput>
                  </FormItem>
                </Col>
                {formData?.headerType == "text" && (
                  <Col className="d-flex mt-3 w-100">
                    <FormItem
                      initialValue={formData.headerTitle}
                      className=" w-100 m-0 whatsapp-device-vertical-form-item"
                      label={
                        <span>
                          Header Title
                          <span style={{ fontSize: "11px" }}>(optional)</span>
                        </span>
                      }
                    >
                      <TextInput
                        onChange={(e: any) => {
                          setFormData((prevState: any) => ({
                            ...prevState,
                            headerTitle: e.target.value,
                          }));
                        }}
                        value={formData?.headerTitle}
                        // disabled={
                        //   selectedTemplate !== null
                        //     ? formData?.headerTitle
                        //     : false
                        // }
                      ></TextInput>
                    </FormItem>
                  </Col>
                )}

                <Col className="d-flex " span={24}>
                  <FormItem
                    className="w-100 m-0  mt-3"
                    label="Header"
                    initialValue={formData?.headerType}
                  >
                    <div className="d-flex gap-4 whatsapp-header-buttons-primary">
                      <HeaderButtons
                        type="file"
                        name="File"
                        imgSrc={fileIcon}
                        disabled={formData?.headerType == "file" ? false : true}
                        ghost={formData?.headerType == "file"}
                      />
                      <HeaderButtons
                        type="video"
                        name="video"
                        imgSrc={videoIcon}
                        disabled={
                          formData?.headerType == "video" ? false : true
                        }
                        ghost={formData?.headerType == "video"}
                      />
                      <HeaderButtons
                        type="text"
                        name="Text"
                        imgSrc={textIcon}
                        disabled={formData?.headerType == "text" ? false : true}
                        ghost={formData?.headerType == "text"}
                      />
                      <HeaderButtons
                        type="media"
                        name="Image"
                        imgSrc={imageIcon}
                        disabled={
                          formData?.headerType == "media" ? false : true
                        }
                        ghost={formData?.headerType == "media"}
                      />
                    </div>
                  </FormItem>
                </Col>

                <Col className="d-flex mt-3 " span={24}>
                  <FormItem
                    className="w-100 m-0 "
                    label=""
                    initialValue={formData?.description}
                  >
                    <div className=" whatsapp-device-emoji-icon d-flex align-items-center">
                      <span
                        className=""
                        style={{ fontSize: "13px", fontWeight: 600 }}
                      >
                        Body
                      </span>
                      <div className="ms-auto">
                        <PrimaryButton onClick={handleToggleEmojiPicker}>
                          <SmileOutlined />
                        </PrimaryButton>

                        <MoreOutlined
                          onClick={() => {
                            setPersonalization({
                              open: true,
                              field: "description",
                            });
                          }}
                        />
                        {/* </PrimaryButton> */}
                      </div>
                    </div>
                    <div className="position-relative ">
                      <CustomTextArea
                        rows={4}
                        value={formData?.description}
                        onChange={(e: any) => {
                          setFormData((prevState: any) => ({
                            ...prevState,
                            description: e.target.value,
                          }));
                        }}
                        style={{
                          resize: "none",
                          // direction,
                          background: "#F4F8FF", // Change this to the desired background color
                        }}
                      />
                    </div>
                    {showEmojiPicker && (
                      <div
                        className="emoji-picker-dropdown"
                        ref={emojiPickerRef}
                      >
                        <EmojiPicker
                          onEmojiClick={handleEmojiClick}
                          defaultSkinTone={undefined}
                          // style={{fontSize:"1px"}}
                        />
                      </div>
                    )}
                  </FormItem>
                </Col>

                <Col className=" mt-3 " span={24}>
                  <FormItem>
                    <div className={"my-2"}>
                      <ActionButtons
                        maxButtons={2}
                        type="WHATSAPP"
                        buttonsState={formData.actions}
                        setButtonsState={(value: any) =>
                          setFormData((prevState: any) => ({
                            ...prevState,
                            actions: value,
                          }))
                        }
                      />
                    </div>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </div>
        }
        footerItem={
          <Row className="gap-2  d-flex justify-content-end">
            <PrimaryButton
              // disabled={!template.template_name}
              type="primary"
              htmlType="submit"
              onClick={handleSaveOrUpdate}
            >
              {selectedTemplate ? t("updateLabel") : t("saveLabel")}
            </PrimaryButton>
            <PrimaryButton
              style={{
                color: "white",
                background: "var( --color-red-main)",
              }}
              onClick={() => {
                dispatch(setCampaignData({}));
                dispatch(setCurrentStep(0));
              }}
            >
              {t("closeLabel")}
            </PrimaryButton>
          </Row>
        }
      />
    </div>
  );
};

export default WhatsApp;
