import React, { FC, useState } from 'react'
import PrimaryButton from '../../../components/Buttons/PrimaryButton/PrimaryButton';
import { PlusOutlined } from '@ant-design/icons';
import Search from '../../../components/Inputs/Search/Search';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { columns, data } from './data';
import { Tag } from 'antd';

interface SegmentsProps {
  
}

const Segments: FC<SegmentsProps> = ({  }) => {
    const [dataCopy,setDataCopy]=useState<typeof data>(data)
 
    const handleSearch = (value: string) => {
      const regex = new RegExp(value, "i");
      const temp: typeof data = data.filter((user: any) => {
        return user.name ? regex.test(user.name) : false;
      });
      setDataCopy(temp);
    };
  return (
    <div className="p-4 mt-2" style={{ background: "white" }}>
      <div className="d-flex justify-content-between">
        <h5>Segments</h5>
        <div className="d-flex gap-3">
          <PrimaryButton type="primary"><PlusOutlined className='mx-1' /> <span className='mx-1'>Segment</span></PrimaryButton>
        </div>
      </div>
      <div className="d-flex py-3 justify-content-between">
       <div className='d-flex gap-2'>
       <Tag color='blue' className='d-flex align-items-center' closable>
        Members: greater than 15
      </Tag>
        <PrimaryButton >
          <span className="px-3 d-flex align-items-center">

          <PlusOutlined /> <span className="px-2">Filter</span>
          </span>
        </PrimaryButton>
       </div>
        <Search onChange={(e)=>handleSearch(e.target.value)} style={{width:"225px"}} />
      </div>
      <div className="w-100 py-3">
        <CustomTable
        tableSize= "small"
          columns={columns}
          dataSource={dataCopy}
          pagination={{ pageSize: 12, simple: false ,size:"small"}}
        />
      </div>
    </div>
  );
}

export default Segments;