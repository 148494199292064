import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css'; // Make sure to create and import a CSS file for styling
import { useSelector } from 'react-redux';
interface NotFoundProps {
  setShowSideNav: (show: boolean) => void;
}

const NotFound: React.FC<NotFoundProps> = ({ setShowSideNav }) => {
  const activeApp = useSelector((state: any) => state.activeApp);
  const linkTo = activeApp.appId && activeApp.appName 
  ? `/dashboard/app?id=${activeApp.appId}` 
  : '/dashboard';
  useEffect(() => {
  setShowSideNav(false);
  }, [])
  
  return (
    <div className="not-found-container">
      <Link to={linkTo} className="home-link">Go to Home</Link>
    </div>
  );
}

export default NotFound;
