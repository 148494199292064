import React, { useEffect, useState } from "react";
import Selector from "../../components/Selector/Selector";
import "./Events.css";
import PrimaryButton from "../../components/Buttons/PrimaryButton/PrimaryButton";
import { Form } from "antd";
import { t } from "i18next";
import Spinner from "../../components/Spinner/Spinner";
import CustomHighChart from "components/Charts/CustomHighChart/CustomHighChart";
import centralApi from "services/centralApi";
import { downloadCsv } from "helperFunctions/common";
import useMessage from "../../hooks/useMessage";
import { useSelector } from "react-redux";
import {
  aggregateDataByPlatform,
  selectedPlatform,
} from "helperFunctions/events";

interface WhatDoUserDoProps {
  eventStatsData: any;
  platformVersion: any;
  excludedKeys: any;
  loading: any;
  dateRange: any;
}
const WhatDoUserDo: React.FC<WhatDoUserDoProps> = ({
  eventStatsData,
  platformVersion,
  excludedKeys,
  loading,
  dateRange,
}) => {
  interface RootState {
    loginUser: {
      data: {
        api_key: string;
      };
    };
  }
  interface ActiveAppState {
    activeApp: {
      appName: string;
      appId: string;
    };
  }
  const { endDate_C, startDate_C } = dateRange || {};
  const { showError, showSuccess } = useMessage();
  const loginUser = useSelector((state: RootState) => state.loginUser);
  const { appId } = useSelector((state: ActiveAppState) => state.activeApp);
  const api_key = loginUser.data.api_key;
  // const { endDate_C, startDate_C } = useSelector(
  // (state: any) => state.dateRange
  // );

  const [statsData, setStatsData] = useState(eventStatsData);

  function labelFormatter(this: any) {
    const legendLabel: string = this.name; // Explicitly specify the type as string
    const total =
      totalsByProperty.find((item) => item.name === legendLabel)?.total || 0;
    const formattedLabel = `${legendLabel} (${total})`; // Display legend label with total count

    return formattedLabel;
  }
  const styleWidth = {
    width: 250,
  };
  const allDates = selectedPlatform(platformVersion, statsData);
  const aggregatedData = aggregateDataByPlatform(
    platformVersion,
    statsData,
    allDates,
    excludedKeys
  );
  const categories2 = aggregatedData.map((item) => item.date);

  const seriesData2 =
    (aggregatedData || []).length > 0
      ? Object.keys(
          aggregatedData.reduce((acc, obj) => {
            Object.keys(obj).forEach((key) => {
              if (key !== "date") acc[key] = true;
            });
            return acc;
          }, {} as { [key: string]: boolean })
        ).map((category) => {
          const data = aggregatedData.map((item) => item[category] || 0);
          const total = data.reduce((acc, curr) => acc + (curr || 0), 0);
          return {
            name: category,
            data: data,
            total: total,
          };
        })
      : [];
  const totalsByProperty = seriesData2.map((series: any) => ({
    name: series.name,
    total: series.data.reduce((acc: number, value: number) => acc + value, 0),
  }));

  const options = {
    title: {
      text: "",
    },
    xAxis: {
      categories: categories2,
      title: {
        text: "Date",
      },
      labels: {
        style: {
          fontSize: "10px", // Adjust the font size as needed
        },
      },
    },
    yAxis: {
      title: {
        text: "Value",
      },
      gridLineWidth: 0, // Remove horizontal grid lines

      stackLabels: {
        enabled: false,
      },
    },
    // plotOptions: {
    // column: {
    // stacking: "normal", // Stack the columns
    // },
    // },
    plotOptions: {
      column: {
        stacking: "normal", // Set stacking to 'normal' or 'percent' for a stacked column chart
        groupPadding: 0, // Set groupPadding to 0 to remove the gap between groups of bars
        pointPadding: 0, // Set pointPadding to 0 to remove the gap between individual bars
        // Set to false to remove the gap between bars
      },
    },
    series: seriesData2.map((series: any, index: number) => ({
      name: series.name,
      data: series.data,
      visible: index % 2 === 0, // Set every other series to be initially visible or invisible

      type: "column", // Specify column type
      // point: {
      // events: {
      // click: function () {
      // // Handle click event
      // const category = this.category; // Get the category (x-axis value) of the clicked bar
      // // Filter data for the clicked category
      // const clickedData = this.series.chart.series.map((series) => {
      // return {
      // name: series.name,
      // value: series.data.find((dataPoint) => dataPoint.category === category).y
      // };
      // });
      // // Log the data for the clicked category
      // console.log('Data for the clicked category:', clickedData);
      // }
      // }
      // }
    })),

    legend: {
      align: "right", // Position legends on the right side
      verticalAlign: "middle", // Align vertically in the middle
      layout: "vertical", // Display legends vertically
      scrollable: true, // Enable legend scrolling
      itemStyle: {
        cursor: "pointer",
      },
      labelFormatter: labelFormatter, // Reference the labelFormatter function here
      series: {
        events: {
          legendItemClick: function (this: any) {
            const seriesIndex = this.index;
            const series = this.chart.series[seriesIndex];
            series.setVisible(!series.visible); // Toggle series visibility in the chart
            return false; // Prevent default behavior of toggling series visibility in the legend
          },
        },
      },
    },
  };

  const optionEvents = totalsByProperty.map((item) => item.name);
  useEffect(() => {
    setStatsData(eventStatsData);
  }, [eventStatsData, platformVersion]);

  const onFormSubmit = async (values: any) => {
    const payload = {
      api_key: api_key,
      app_id: appId,
      offset: 19800,
      args: JSON.stringify({
        filter: {
          dateRange: {
            sd: startDate_C,
            ed: endDate_C,
            dates: categories2,
          },
          platform: platformVersion,
          selectAll: "false",
          events: values.selectedEvents,
          eventsSegments: [],
        },
      }),
      filename: "events",
    };

    const url: string = `/o/metrices/downloadEventsStats`;

    try {
      const response = await centralApi("GET", url, null, payload, true);
      downloadCsv(response, "events");
      showSuccess(t("downloadedSuccessfullyLabel"));
    } catch (error: any) {
      showError(error.message);
    }
  };

  function EventsCard({ title, className }: any) {
    return (
      <div className={`Events-card-container ${className} `}>
        <div>
          <div className="d-flex justify-content-between flex-wrap mt-4">
            <div className="Events-card-heading d-flex justify-content-between align-items-center p-2">
              <strong>{title}</strong>
            </div>
            <div>
              <Form layout="inline" onFinish={onFormSubmit}>
                <Form.Item
                  label="Events"
                  name="selectedEvents"
                  rules={[
                    { required: true, message: "Please select an option" },
                  ]}
                >
                  <Selector
                    // style={{ minWidth: 140 }}
                    style={styleWidth}
                    // onChange={handleOptionChange}
                    mode="multiple"
                    placeholder="Select Events"
                    maxTagCount="responsive"
                    options={optionEvents.map((label) => ({
                      value: label,
                      label,
                    }))}
                  />
                </Form.Item>
                <Form.Item>
                  <PrimaryButton
                    size="middle"
                    type="primary"
                    htmlType="submit"
                    style={{ border: "none", width: "90px" }}
                  >
                    Download
                  </PrimaryButton>
                </Form.Item>
              </Form>
            </div>
          </div>
          {/* <div className="Events-card-body px-3 py-2">{children}</div> */}
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid p-sm-2 p-1 ">
      <div className="px-sm-3 px-1 events-container">
        <div>
          <EventsCard
            title={t("eventsChartLabel")}
            className="mx-1"
          ></EventsCard>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <div>
            <CustomHighChart options={options} />
          </div>
        )}
      </div>
    </div>
  );
};

export default WhatDoUserDo;
