import { Button } from "antd";
function PrimaryButton({ style, size,children, ...props }: any) {
  return (
    <Button
      size={size ? size : "middle"}
      {...props}
      style={{ paddingLeft: "7px", paddingRight: "7px", ...style }}
    >
      <span className="d-flex  align-items-center w-100 justify-content-center ">
        {children}
      </span>
    </Button>
  );
}

export default PrimaryButton;
