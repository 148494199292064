import React, { FC, useState } from "react";

interface CreateTierProps {}
import filterIcon from "../../../images/_filter.svg";
import PrimaryButton from "../../../components/Buttons/PrimaryButton/PrimaryButton";
import Counter from "../../../components/Counter/Counter";
import { Form, Tag } from "antd";
import downloadIcon from "../../../images/downloadIcon.svg";
import { SyncOutlined } from "@ant-design/icons";
import TextInput from "../../../components/Inputs/TextInput/TextInput";
import { validateNotEmpty } from "../../../validator/antdValidator";
import Selector from "../../../components/Selector/Selector";
import SegmentedCustom from "../../../components/SegmentedCustom/SegmentedCustom";
import { audienceItems } from "../../../helperFunctions/audience";
import { useNavigate } from "react-router-dom";

const CreateTier: FC<CreateTierProps> = ({}) => {
 const navigate=useNavigate()

  return (
    <div className="p-4 mt-2 " style={{ background: "white", height: "88vh" }}>
      <Form
        onFinish={(value) => {
            let dataArr:any[]=[]
            const stringData=localStorage.getItem("demoTiers") 
          if (stringData) {
            dataArr=JSON.parse(stringData)
            dataArr.unshift({name:value?.name,value:value?.value || 0})
          }
          else{
            dataArr=[{name:(value?.name || "bronze"),value:(value?.value || 0)}]
          }
          localStorage.setItem("demoTiers",JSON.stringify(dataArr))
          navigate("/dashboard/loyalty/tier")
        }}
      >
        <div
          className="d-flex justify-content-between w-100 gap-3  flex-wrap"
          style={{ marginRight: 10 }}
        >
          <div className="d-flex align-items-center w-100">
            <div className="d-flex justify-content-between w-100">
              <div
                className="d-flex   align-items-start"
                // style={{ marginTop: "-4px" }}
              >
                <span
                  className="d-flex   filter-icon-container justify-content-center "
                  style={{
                    height: 39,
                    width: 39,
                    borderRadius: 5,
                    padding: 4,
                    border: "1px solid var(--color-main-primary-medium)",
                    background: "var(--color-main-primary-varylight)",
                  }}
                >
                  <img
                    src={filterIcon}
                    height={"100%"}
                    width={"100%"}
                    alt="filter"
                  ></img>
                </span>

                <h5 className="mt-2 mx-2 audience-segment-heading">
                  Create Tier
                </h5>
              </div>
              <div className="d-flex flex-column justify-content-end gap-3">
                <div className="d-flex gap-3">
                  <PrimaryButton onClick={()=>navigate("/dashboard/loyalty/tier")}>Discard</PrimaryButton>
                  <PrimaryButton htmlType="submit" type="primary">Save Tier</PrimaryButton>
                </div>
                <div style={{ minWidth: "120px", marginLeft: "auto" }}>
                  <strong style={{ fontSize: "var(--font-size-md)" }}>
                    Size
                  </strong>
                  <div className="d-flex  mt-1">
                    <Counter style={{ minWidth: 50 }} end={8789} duration={2} />
                    {/* <strong>{segmentSizeInfo.count}</strong> */}
                    <Tag className="mx-1">
                      <img height={18} width={18} src={downloadIcon}></img>
                    </Tag>
                    <PrimaryButton
                      style={{ height: 20, padding: 10, margin: 0 }}
                      className="d-flex align-items-center"
                    >
                      <SyncOutlined spin={false} />
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column gap-4">
          <div
            className="d-flex align-items-center gap-2"
            style={{ width: 450 }}
          >
            <strong style={{ fontSize: "var(--font-size-md)", minWidth: 130 }}>
              Tier Name
            </strong>
            <Form.Item name={"name"} className="m-0 w-75">
              <TextInput className="mt-1" />
            </Form.Item>
          </div>
          <div
            className="d-flex align-items-center gap-2"
            style={{ width: 450 }}
          >
            <strong style={{ fontSize: "var(--font-size-md)", minWidth: 130 }}>
              Calculated with
            </strong>
            <Form.Item name={"cwith"} className="m-0 w-75">
             <Selector options={[{
                label:"Points",
                value:"points"
             },
             {
                label:"Coupon",
                value:"Coupon"
             }
             ]}/>
            </Form.Item>
          </div>
          <div
            className="d-flex align-items-center gap-2"
            style={{ width: 450 }}
          >
            <strong style={{ fontSize: "var(--font-size-md)", minWidth: 130 }}>
              Wallet
            </strong>
            <Form.Item name={"wallet"} className="m-0 w-75">
              <Selector options={[
                {
                    label:"1",value:"1",  

                },
                {
                    label:"2",value:"2",  

                },
                {
                    label:"3",value:"3",  

                }
              ]}/>
            </Form.Item>
          </div>
          <div className="py-2 trait-create-body container m-0 p-0">
            <div className="my-2">
              <strong>Add conditions to define this tier</strong>
            </div>
            <div
              className="d-flex flex-column gap-2 p-3"
              style={{ border: "1px solid lightgray", borderRadius: 10 }}
            >
              <div className="d-flex gap-2">
                <Form.Item className="m-0 w-25">
                  <Selector
                    defaultValue={"Transaction Value"}
                    
                    options={[{ label: "Transaction value", value: "Transaction value" },
                    { label: "Product purchased", value: "Product purchased" },
                    { label: "Reward signup", value: "Reward signup" },
                    { label: "Coupon", value: "Coupon" },



                    
                ]}
                  ></Selector>
                </Form.Item>
                <Form.Item className="m-0 w-25">
                  <Selector
                    defaultValue={"eq"}
                    options={audienceItems["number"]}
                  ></Selector>
                </Form.Item>
                <Form.Item name={"value"} className="m-0 w-25">
                  <TextInput />
                </Form.Item>
              </div>
              <div>
                <SegmentedCustom size="small" />
              </div>
              <div className="d-flex gap-2">
                <Form.Item className="m-0 w-25">
                  <Selector
                    defaultValue={"Product purchased"}
                    options={[{ label: "Product purchased", value: "Product purchased" },
                    { label: "Product purchased", value: "Product purchased" },
                    { label: "Reward signup", value: "Reward signup" },
                    { label: "Coupon", value: "Coupon" },

                ]}
                  ></Selector>
                </Form.Item>
                <Form.Item className="m-0 w-25">
                  <Selector
                    defaultValue={"eq"}
                    options={audienceItems["string"]}
                  ></Selector>
                </Form.Item>
                <Form.Item className="m-0 w-25">
                  <Selector
                    defaultValue={"Visa Travel Prepaid Card"}
                    options={[
                      {
                        label: "Visa Travel Prepaid Card",
                        value: "Visa Travel Prepaid Card",
                      },
                      {
                        label: "Visa Infinite Credit Card",
                        value: "Visa Infinite Credit Card",
                      },
                      {
                        label: "International SWIFT Transfers",
                        value: "International SWIFT Transfers",
                      },
                    ]}
                  ></Selector>
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CreateTier;
