import {  useState, Fragment } from "react";

import "./ApiGatewayNav.css";
import InnerNav from "../../../../../components/InnerNav/InnerNav";
import type { MenuProps } from "antd";
// import passthroughsettingSetting from "../passthroughsettingSetting/passthroughsettingSetting";
// import webhookSetting from "../webhookSetting/webhookSetting";
import WebHook from "../WebHook/WebHook";
import React from 'react';
import PassthroughSetting from "../PassthroughSetting/PassthroughSetting";
interface ApiGatewayNavProps {
  currentAppKey: any;
  updateCurrentAppKey: () => void;

}

const ApiGatewayNav: React.FC<ApiGatewayNavProps> = ({ currentAppKey,updateCurrentAppKey }) => {
  const [appTabFilter, setAppTabFilter] = useState("webhook");
  const [selectedComponent, setSelectedComponent] = useState(<WebHook currentAppKey={currentAppKey}  updateCurrentAppKey={updateCurrentAppKey}/>);

  const onTabClick: MenuProps["onClick"] = (e) => {
    setAppTabFilter(e.key);
    switch (e.key) {
      case "webhook":
        setSelectedComponent(<WebHook currentAppKey={currentAppKey} updateCurrentAppKey={updateCurrentAppKey}/>);
        break;
      case "passthroughsetting":
        setSelectedComponent(<PassthroughSetting currentAppKey={currentAppKey} updateCurrentAppKey={updateCurrentAppKey} />);
        break;

      default:
        setSelectedComponent(<div></div>); // Default empty component
    }
  };
  return (
    <Fragment>
      <div className="app-setting-container ">
        <div className="app-setting-nav">
          <InnerNav
            theme="light"
            onTabClick={onTabClick}
            selected={appTabFilter}
            style={{ border: "none" }}
            menu={[
              {
                label: "Webhook",
                key: "webhook",
              },
              {
                label: "Passthrough setting",
                key: "passthroughsetting",
              },
            ]}
          />
        </div>
        {selectedComponent}
      </div>
    </Fragment>
  );
};
export default ApiGatewayNav;
