import "./AppCard.css";

import PrimaryButton from "../../Buttons/PrimaryButton/PrimaryButton";
import andriodIcon from "../../../images/andriodIcon.png";
import appleIcon from "../../../images/appleIcon.png";
import internetIcon from "../../../images/webnew.svg";
import appStoreIcon from "../../../images/appstorenew.svg";
import googlePlayIcon from "../../../images/googleplaynew.svg";


import React from "react";
import SkeletonLoader from "components/SkeletonLoader/SkeletonLoader";
type valueType = {
  installs: {
    android: number;
    ios: number;
    global: number;
  };
  churned: {
    android: number;
    ios: number;
    global: number;
  };
};
type propType = {
  appName: string;
  dashboardClick: React.MouseEventHandler;
  deleteClick: React.MouseEventHandler;
  settingClick: React.MouseEventHandler;
  values:valueType
  metaDataLoading?:boolean
};
const AppCard: React.FC<propType> = ({
  appName,
  settingClick,
  deleteClick,
  dashboardClick,
  values,
  metaDataLoading
}) => {
  const btnStyle = {
    width: 90,
    background: "var(--color-main-primary-medium)",
  };
  const imgStyle = {
    width: "15px",
    height: "15px",
  };
  return (
    <div className="app-card  p-sm-2 p-1 m-sm-1 m-0">
      <div className="app-card-header p-2 d-flex align-items-center m-1">
        <div className="app-icon-wrapper p-1 d-flex ">
          <img src={appStoreIcon} alt="appstore-icon" height={19} width={19} />
          <img src={googlePlayIcon} alt="googleplay-icon" height={19} width={19} />
          <img src={internetIcon} alt="internet-icon" height={19} width={19} />
        </div>
        <h5 className="m-0 mx-2 p-0 text-overflow-ellipsis ">
          {" "}
          { appName}
        </h5>
      </div>
      <div className="mt-3 app-card-body m-1">
        <div className="d-flex justify-content-between flex-column w-100 my-3 align-items-center">
          <table className="w-100" style={{ height: 70, fontWeight: 600 }}>
            <tbody className="app-card-table-body">

            <tr className="text-center">
              <td></td>
              <td>
                <img src={andriodIcon} alt="android-icon" style={imgStyle} />
              </td>
              <td>
                <img src={appleIcon} alt="apple-icon" style={imgStyle} />
              </td>
              <td>
                <img src={internetIcon} alt="global-icon" style={imgStyle} />
              </td>
            </tr>
            <tr className="text-center">
              <td className="text-start px-1">INSTALLS</td>
            <td>{metaDataLoading?<SkeletonLoader className="app-card-skeleton-loader" type="button"/>:values.installs.android}</td>
            <td>{metaDataLoading?<SkeletonLoader className="app-card-skeleton-loader" type="button"/>:values.installs.ios}</td>
             <td>{metaDataLoading?<SkeletonLoader className="app-card-skeleton-loader" type="button"/>:values.installs.global}</td>
            </tr>
            <tr className="text-center">
              <td className="text-start px-1">CHURNED</td>
               <td>{metaDataLoading?<SkeletonLoader className="app-card-skeleton-loader" type="button"/>:values.churned.android}</td>
              <td>{metaDataLoading?<SkeletonLoader className="app-card-skeleton-loader" type="button"/>:values.churned.ios}</td>
           <td>{metaDataLoading?<SkeletonLoader className="app-card-skeleton-loader" type="button"/>:values.churned.global}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-between">
          <PrimaryButton
            type="primary"
            onClick={dashboardClick}
            style={btnStyle}
          >
            Dashboard
          </PrimaryButton>
          <PrimaryButton
            className="mx-1"
            type="primary"
            onClick={settingClick}
            style={{...btnStyle,background:"var( --color-secondary)"}}
          >
            Settings
          </PrimaryButton>

          <PrimaryButton
            type="primary"
            onClick={deleteClick}
            danger
            style={{...btnStyle,background:"var( --color-red-main)"}}

          >
            Delete
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default AppCard;
