import type { MenuProps } from "antd";
import { Menu } from "antd";
import "./InnerNav.css";
type propType = {
  theme?: "dark" | "light";
  onTabClick:(e:any)=>void;
  selected:any,
  style?:any,
  menu: MenuProps["items"]
};

const InnerNav = ({theme,onTabClick,selected,style,menu,...props}: propType) => {

  // const [current, setCurrent] = useState<any>(menu[0]);


  return (
    <div className="inner-nav-container ">

    <Menu
      theme={theme}
      className="inner-nav"
      onClick={onTabClick}
      {...props}
      selectedKeys={[selected]}
      mode="horizontal"
      items={menu}
      style={{...style,background:"none"}}
      />
      </div>
  );
};

export default InnerNav;
