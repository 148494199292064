import React from "react";
import { Checkbox } from "antd";
import "./CustomCheckbox.css";

const CustomCheckbox: React.FC<any> = ({ label, ...props }) => (
  <div>
    <Checkbox {...props}>{label}</Checkbox>
  </div>
);

export default CustomCheckbox;
