import { Line } from "@nivo/line";
import { type } from "os";
import React from "react";
type valueType = {
  x: string | number;
  y: number;
};
type propTypes = {
  values?: valueType[];
  lineColor?: string;
  lineWidth?: number;
  width?: number;
  height?: number;
};
const defaultValues = [
  {
    x: "FI",
    y: 0,
  },
  {
    x: "CM",
    y: 17,
  },
  {
    x: "AW",
    y: 0,
  },
  {
    x: "NL",
    y: 9,
  },
  {
    x: "TW",
    y: 0,
  },
  {
    x: "TL",
    y: 9,
  },
];
const SparkLine: React.FC<propTypes> = ({
  values,
  lineColor,
  lineWidth,
  width,
  height,
}) => {
  const data = [
    {
      id: "whatever",
      data: values ? values : defaultValues,
    },
  ];
  return (
    <Line
      lineWidth={lineWidth?lineWidth:3}
      width={width?width:100}
      height={height?height:60}
      margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
      curve="natural"
      colors={lineColor}
      layers={[
        "markers",
        "areas",
        "crosshair",
        "lines",
        "slices",
        "mesh",
        "legends",
      ]}
      data={data}
    />
  );
};

export default SparkLine;
