import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import centralApi from "../../../services/centralApi";
import { STATUS } from "../../constant";
const initialState:{data:any,status:any}={data:{},status:STATUS.IDLE}
export const fetchCampaignById = createAsyncThunk(
  "campaign/fetchCampaignById",
  async ({api_key, app_id,args,cid}:any) => {
    const params = {
      api_key,
      app_id,
      args,
      cid
    };
    return centralApi(
      "GET",
      "o/templates/getCampaignById",
      null,
      params
    ).then((data) => { 
      return data;
    });
  }
);
const campaignDataSlice = createSlice({
  name: "campaignData",
  initialState,
  reducers: {
    setCampaignData(state, action) {
      state.data=action.payload
    },

  },
  extraReducers: (builder) => {
    builder.addCase(fetchCampaignById.pending, (state) => {
      state.status = STATUS.LOADING;
    });
    builder.addCase(fetchCampaignById.fulfilled, (state,action) => {
      state.status = STATUS.IDLE;
      state.data=action.payload
    });
    builder.addCase(fetchCampaignById.rejected, (state) => {
      state.status = STATUS.ERROR;
    });
  },
});
export const {setCampaignData}=campaignDataSlice.actions
export default campaignDataSlice.reducer
