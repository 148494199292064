import { Modal, Input, Button } from "antd";
import { useEffect, useState } from "react";

const CopyModal = ({visibility,setVisibility,inputValue}:any) => {

  const handleCopy = () => {
    navigator.clipboard.writeText(inputValue); // copy the input value to the clipboard
    setVisibility(false)
  };
  return (
    <Modal
        title="Newly Generated Password"
        centered
        open={visibility}
        okText={"Copy"}
        onOk={handleCopy}
        onCancel={() => setVisibility(false)}
      >
      <Input
        value={inputValue}
        size="large"
        
      />
      </Modal>
      
   
  );
};

export default CopyModal;
