import { createSlice } from "@reduxjs/toolkit";
import { clearLocalStorage, getLocalStorage, setLocalStorage } from "../../../helperFunctions/localStorage";

const initialState: any = {
    appId:getLocalStorage("activeAppId"),
    appName:getLocalStorage("activeAppName")

};
const activeAppSlice = createSlice({
  name: "activeApp",
  initialState,
  reducers: {
    setActiveApp(state, action) {
        setLocalStorage("activeAppId",action.payload.appId)
        setLocalStorage("activeAppName",action.payload.appName)

      return action.payload;
    },
    removeActiveApp(state) {
      clearLocalStorage()

    return {};
  },
  },
});
export const {setActiveApp,removeActiveApp}=activeAppSlice.actions
export default activeAppSlice.reducer
