import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exportingModule from "highcharts/modules/exporting";
import dataModule from "highcharts/modules/data";
import ExportData from 'highcharts/modules/export-data';
import SkeletonLoader from "components/SkeletonLoader/SkeletonLoader";
import NoData from "components/NoData/NoData";
import funnelModule from "highcharts/modules/funnel";

funnelModule(Highcharts);
exportingModule(Highcharts);
dataModule(Highcharts);
ExportData(Highcharts);

interface CustomHighChartProps {
  options: any;
}

const CustomHighChart: React.FC<CustomHighChartProps> = ({ options }) => {
  const modifiedOptions = {
    ...options,
    credits: {
      enabled: false,
    },
   

  
  };
  const hasData = options && options.series && options.series.length > 0;
  return (
    <>
    {hasData ? (

    <div >
      {options?.loading?<SkeletonLoader paragraph={{rows:8}} type="default"/>:<HighchartsReact   highcharts={Highcharts} options={modifiedOptions} loading={true} />}
    </div>)
    :(
    <NoData/>)}
    </>
  );
};

export default CustomHighChart;
