import React from "react";
import { Input } from "antd";

const TextInput: React.FC<any> = (props) =>
  props?.type !== "password" ? (
    <Input {...props} maxLength={props.maxLength || 100} autoComplete="off" />
  ) : (
    <Input.Password {...props} maxLength={props.maxLength || 30} autoComplete="off" />
  );

export default TextInput;
