import { Steps, theme, message, Form } from "antd";
import React, { useState } from "react";
import PrimaryButton from "../../components/Buttons/PrimaryButton/PrimaryButton";
import "./SetupNewApp.css";
import { t } from "i18next";
import TextInput from "../../components/Inputs/TextInput/TextInput";
import internetIcon from "../../images/webnew.svg";
import appStoreIcon from "../../images/appstorenew.svg";
import googlePlayIcon from "../../images/googleplaynew.svg";
import hotStarIcon from "../../images/hotstar.png";

const SetupYourApp = () => {
  return (
    <div className="d-flex w-100 setup-your-app-container mt-3  gap-3 ">
      <div className="w-50 p-4 setup-your-app-container-child1 ">
        <strong style={{ color: "var(--color-text-disabled-strong)" }}>
          Give us the path to your application and we will start gathering data
          and churn insights for you to act upon.If your app is available on
          different platforms,please make sure you provide us the path of all
          the platforms below
        </strong>
        <Form
          layout="vertical"
          // onFinish={onFormSubmit}
          className="pt-3"
        >
          <Form.Item name={"url"} rules={[{ required: true }]}>
            <TextInput
              addonBefore={<img height={22} width={22} src={googlePlayIcon} />}
              placeholder={"Application Name / App Store URL"}
            />
          </Form.Item>
          <Form.Item rules={[{ required: true }]}>
            <TextInput
              addonBefore={<img height={22} width={22} src={appStoreIcon} />}
              placeholder={"Application Name / App Store URL"}
            />
          </Form.Item>
          <Form.Item name={"url"} rules={[{ required: true }]}>
            <TextInput
              addonBefore={<img height={22} width={22} src={internetIcon} />}
              placeholder={"Website URL"}
            />
          </Form.Item>
        </Form>
      </div>
      <div className="w-50 p-3 setup-your-app-container-child2">
        {/* <div className=" p-1 d-flex ">
          <img src={appStoreIcon} alt="appstore-icon" height={19} width={19} />
          <img src={googlePlayIcon} alt="googleplay-icon" height={19} width={19} />
          <img src={internetIcon} alt="internet-icon" height={19} width={19} />
        </div> */}
        <div >
          <h5 className="py-2 app-title">Example Entry of an App</h5>
        </div>
        <div className="">
          <div className="d-flex mt-4 gap-2 py-1 just align-items-center">
            <img
              src={hotStarIcon}
              style={{ borderRadius: 6 }}
              alt="appstore-icon"
              height={30}
              width={30}
            />
            <p >hotstar live TV movies cricket</p>
          </div>
          <div className="d-flex mt-3 gap-2  py-1">
            <img
              src={appStoreIcon}
              alt="appstore-icon"
              height={30}
              width={30}
            />
            <p>https://itunes.apple.com/in/app/hotstar/id934459219?mt=8</p>
          </div>
          <div className="d-flex mt-3  gap-2 py-1">
            <img
              src={googlePlayIcon}
              alt="appstore-icon"
              height={30}
              width={30}
            />
            <p>https://itunes.apple.com/in/app/hotstar/id934459219?mt=8</p>
          </div>
        </div>
      </div>
    </div>
  );
};
const IntegrateWithSDK = () => {
  return <h3>hi SDK</h3>;
};
const TestTheIntegration = () => {
  return <h3>hi test</h3>;
};
function SetupNewApp() {
  const [current, setCurrent] = useState(0);
  const { token } = theme.useToken();

  const steps = [
    {
      title: <strong>Setup Your App</strong>,
      content: <SetupYourApp />,
    },
    {
      title: <strong>Integrate With SDK</strong>,
      content: <IntegrateWithSDK />,
    },
    {
      title: <strong>Test the Integration</strong>,
      content: <TestTheIntegration />,
    },
  ];
  const items = steps.map((item) => ({ key: item.title, title: item.title }));
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  return (
    <div className="setup-new-app-container ">
      <h4 className="my-3">Dashboard</h4>
      <div className="setup-new-app-inner-container ">
        <div className="setup-new-app-inner-container-header p-3 d-flex align-items-center">
          <h5 className="m-0">Setup your App</h5>
        </div>
        <div className="setup-new-app-inner-container-body p-3">
          <Steps current={current} items={items} />
          <div className="setup-new-app-current  py-3">
            {steps[current].content}
          </div>
          <div className="d-flex py-3 justify-content-between">
            <PrimaryButton
              disabled={current > 0 ? false : true}
              onClick={() => prev()}
            >
              Previous
            </PrimaryButton>

            {current < steps.length - 1 && (
              <PrimaryButton type="primary" onClick={() => next()}>
                Next
              </PrimaryButton>
            )}
            {current === steps.length - 1 && (
              <PrimaryButton
                type="primary"
                onClick={() => message.success("Processing complete!")}
              >
                Done
              </PrimaryButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SetupNewApp;
