import { Form, FormInstance } from "antd";
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import "./UserTraitNested.css";
import { CloseOutlined } from "@ant-design/icons";
import { t } from "i18next";
import centralApi from "services/centralApi";
import { audienceItems, getDw } from "helperFunctions/audience";
import Selector from "components/Selector/Selector";
import TextInput from "components/Inputs/TextInput/TextInput";
import { generateNumbersInRange } from "helperFunctions/number";
import {
  removeSegment,
  setAudienceSegmentInfo,
} from "redux/features/audience/audienceSegmentDataSlice";
import SegmentedCustom from "components/SegmentedCustom/SegmentedCustom";
import { AudienceEnum } from "utils/enums";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import CustomDrawer from "components/CustomDrawer/CustomDrawer";
import GeoFencing from "../GeoFencing/GeoFencing";
import { fetchGeoFencingList } from "redux/features/audience/geoFencingListSlice";
import useMessage from "hooks/useMessage";
import { API_ENDPOINTS, METHODS } from "utils/constants";
import { GeoFencingModel, GeoNameModel } from "utils/models";
export type propType = {
  categoryContext: any;
  childIndex: number;
  form: FormInstance;
  category: string;
  childValue: any;
};
const UserTraitNested: React.FC<propType> = ({
  childValue,
  category,
  form,
  childIndex,
  categoryContext,
}) => {
  const [operand, setOperand] =
    useState<{
      value: string;
      type: string;
      multiple: boolean | undefined;
      operators: any;
    }>();
  const [metaVaribles, setMetaVariables] = useState([]);
  const [geoFencingDrawer, setGeoFencingDrawing] = useState(false);
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const geoFencing = useSelector((state: any) => state.geoFencingList.data);
  const { showError } = useMessage();
  const dispatch: any = useDispatch();
  const handleFormChange = (data: any) => {
    dispatch(
      setAudienceSegmentInfo({
        segmentType: AudienceEnum.WHO,
        category,
        data: data,
        indexToModify: childIndex,
      })
    );
  };
  //save geo fencing
  const handleGeoFencingSave = async (data: GeoFencingModel) => {
    try {
      //serialize data
      const geoFencing = new GeoFencingModel(
        data.name,
        data.desc,
        data.details
      );
      const jsonData = geoFencing.toString();
      //payload
      const params = {
        api_key: loginUser.api_key,
        app_id: appId,
        args: jsonData,
        method: METHODS.SAVE_GEO_FENCING,
      };
      //call api
      await centralApi("GET", API_ENDPOINTS.SAVE_GEO_FENCING_URL, null, params);
      //refetch geo fence data using redux
      dispatch(
        fetchGeoFencingList({ api_key: loginUser.api_key, app_id: appId })
      );
      //close geo fence drawer
      setGeoFencingDrawing(false);
    } catch (error) {
      //show message
      showError(t("somethingWentWrongMsg"));
      setGeoFencingDrawing(false);
    }
  };
  //fetch meta variables
  const fetchMetaVariables = async (variablename: string) => {
    //serialize data
    const variableName = new GeoNameModel(variablename);
    const params = {
      api_key: loginUser.api_key,
      app_id: appId,
      args: variableName.toString(),
      method: METHODS.GET_META_VARIABLES,
    };
    //call api
    const meta_var = await centralApi(
      "GET",
      API_ENDPOINTS.GET_META_VARIABLES_URL,
      null,
      params
    );
    setMetaVariables(meta_var.result);
  };

  useEffect(() => {
    //fetch variables if thr is operand and the user is loggedin
    if (operand?.value && loginUser.api_key) {
      fetchMetaVariables(operand?.value);
    }
  }, [operand, loginUser]);
  useEffect(() => {
    //reset form fields if operand changes
    form.resetFields([
      `operator[${childValue.id}]`,
      `value[${childValue.id}]`,
      `customtimefrom[${childValue.id}]`,
      `customtimeto[${childValue.id}]`,
      `timeunit[${childValue.id}]`,
      `dwell[${childValue.id}]`,
      `time[${childValue.id}]`,
    ]);
  }, [childValue?.operand]);
  useEffect(() => {
    if (childValue?.operand) {
      setOperand(
        categoryContext?.find(
          (catObj: any) => catObj.value == childValue.operand
        )
      );
    }
  }, [childValue?.operand, categoryContext]);
  return (
    <>
    {/* geofence drawer */}
      <CustomDrawer
        placement="right"
        onClose={() => setGeoFencingDrawing(false)}
        destroyOnClose
        size={"large"}
        open={geoFencingDrawer}
      >
        <GeoFencing setGeoInfo={handleGeoFencingSave} />
      </CustomDrawer>
      {childIndex !== 0 && (
        <div className="w-100" data-testid="user-trait-component">
          <SegmentedCustom
            size="small"
            value={childValue.e_operator}
            onChange={(value: string) => {
              handleFormChange({ e_operator: value });
            }}
            options={[
              { label: AudienceEnum.AND, value: AudienceEnum.AND },
              { label: AudienceEnum.OR, value: AudienceEnum.OR },
            ]}
          />
        </div>
      )}
      <div className="audience-usertrait-nested-container  align-items-center  gap-2 d-flex w-100   ">
        {t("whereLabel")}
        {/* operand field will be visiable if category  is selected */}
        {category && (
          <Form.Item
            rules={[{ required: true, message: `${t("selectOperandMsg")}` }]}
            name={`operand[${childValue.id}]`}
            className="m-0 user-trait-input user-trait-input-w250"
            initialValue={childValue.operand}
          >
            <Selector
              className="w-100"
              data-testid="operand-selector"
              onChange={(value) => {
                handleFormChange({
                  operand: value,
                  operator: undefined,
                  category: undefined,
                  value: undefined,
                  time: undefined,
                  timeunit: undefined,
                  category_type: undefined,
                  details: undefined,
                  dw: undefined,
                  dwell: undefined,
                });
              }}
              value={childValue.operand}
              placeholder={`${t("selectOperandMsg")}`}
              options={categoryContext}
            />
          </Form.Item>
        )}

        {/* operator field will be visiable if operand  is selected */}
        {operand?.value && (
          <Form.Item
            className="m-0 user-trait-input"
            rules={[{ required: true, message: `${t("selectOperatorMsg")}` }]}
            name={`operator[${childValue.id}]`}
            initialValue={childValue.operator}
          >
            <Selector
              className="w-100"
              value={childValue.operator}
              onChange={(value) => {
                handleFormChange({ operator: value });
              }}
              placeholder={`${t("selectOperatorMsg")}`}
              options={
                category == AudienceEnum.CUSTOM_VARS
                  ? audienceItems[operand.type]
                  : operand.operators
              }
            />
          </Form.Item>
        )}
        {/* input field it can be textInput,selector,multiple selector option based on operand multiple property  */}
        {category &&
          operand &&
          (operand?.value !== AudienceEnum.CUSTOM ? (
            <Form.Item
              initialValue={childValue.value}
              className="m-0 user-trait-input"
              rules={[{ required: true, message: `${t("valueLabel")}` }]}
              name={`value[${childValue.id}]`}
            >
              {operand && operand.multiple == true ? (
                <Selector
                  className="overflow-x-scroll w-100"
                  mode="multiple"
                  value={childValue.value}
                  onChange={(value) => {
                    handleFormChange({ value: value });
                  }}
                  options={
                    operand.type == AudienceEnum.PLACE_LIST ||
                    operand.type == AudienceEnum.TIME_LIST ||
                    operand.type == AudienceEnum.BOOLEAN_LIST ||
                    operand.type == AudienceEnum.NETWORK_LIST ||
                    operand.type == AudienceEnum.DEVICE_LANGUAGE_LIST
                      ? audienceItems[operand.type]
                      : metaVaribles.map((name) => ({
                          label: name,
                          value: name,
                        }))
                  }
                />
              ) : operand && operand.multiple == false ? (
                <Selector
                  className="w-100"
                  dropdownRender={(menu) => (
                    <>
                     {operand.value == AudienceEnum.GF && <PrimaryButton
                        onClick={() => setGeoFencingDrawing(true)}
                        type="link"
                      >
                        {t("createNewLabel")}
                      </PrimaryButton>}
                      {menu}
                    </>
                  )}
                  value={
                    operand.value !== AudienceEnum.GF
                      ? childValue.value
                      : geoFencing.find(
                          ({ name }: { name: string }) => name == childValue
                        )?._id
                  }
                  onChange={(value) => {
                    if (operand.value == AudienceEnum.GF) {
                      const id=value.split("-")[1]
                      const selected = geoFencing.find(
                        ({ _id }: { _id: string }) => _id == id
                      );
                      handleFormChange({
                        value: selected.name,
                        details: selected.details,
                      });
                    } else {
                      handleFormChange({ value: value });
                    }
                  }}
                  options={
                    operand.type == AudienceEnum.PLACE_LIST ||
                    operand.type == AudienceEnum.TIME_LIST ||
                    operand.type == AudienceEnum.BOOLEAN_LIST ||
                    operand.type == AudienceEnum.NETWORK_LIST ||
                    operand.type == AudienceEnum.DEVICE_LANGUAGE_LIST
                      ? audienceItems[operand.type]
                      : operand.value == AudienceEnum.GF
                      ? geoFencing.map(
                          ({ _id, name }: { _id: string; name: string }) => ({
                            label: name,
                            value: name+"-"+_id,
                          })
                        )
                      : metaVaribles.map((name) => ({
                          label: name,
                          value: name,
                        }))
                  }
                />
              ) : operand &&
                operand.multiple !== true &&
                operand.multiple !== false ? (
                <TextInput
                maxLength={2000}
                  className="w-100"
                  value={childValue.value}
                  onChange={(e: any) => {
                    handleFormChange({ value: e.target.value });
                  }}
                />
              ) : (
                <></>
              )}
            </Form.Item>
          ) : (
            <div className="d-flex">
              {/* for custom time */}
              <Form.Item
                className="m-0 user-trait-input-w90px"
                rules={[
                  { required: true, message: `${t("selectTimeUnitMsg")}` },
                ]}
                initialValue={childValue.customtimeto}
                name={`customtimefrom[${childValue.id}]`}
              >
                <Selector
                  className="w-100"
                  placeholder={`${t("fromLabel")}`}
                  value={childValue.customtimefrom}
                  onChange={(value) => {
                    handleFormChange({ customtimefrom: value });
                  }}
                  options={generateNumbersInRange(0, 23).map((num) => ({
                    label: num,
                    value: num,
                  }))}
                />
              </Form.Item>
              <Form.Item
                className="m-0 user-trait-input-w90px"
                rules={[
                  { required: true, message: `${t("selectTimeUnitMsg")}` },
                ]}
                name={`customtimeto[${childValue.id}]`}
                initialValue={childValue.customtimeto}
              >
                <Selector
                  className="w-100"
                  value={childValue.customtimeto}
                  placeholder={`${t("toLabel")}`}
                  onChange={(value) => {
                    handleFormChange({ customtimeto: value });
                  }}
                  options={generateNumbersInRange(0, 24).map((num) => ({
                    label: num,
                    value: num,
                  }))}
                />
              </Form.Item>
            </div>
          ))}

        {(operand?.value == AudienceEnum.FS ||
          operand?.value == AudienceEnum.LS) && (
          <Form.Item
            className="m-0 user-trait-input"
            rules={[{ required: true, message: `${t("selectTimeUnitMsg")}` }]}
            name={`timeunit[${childValue.id}]`}
            initialValue={childValue.timeunit}
          >
            <Selector
              className="w-100"
              value={childValue.timeunit}
              onChange={(value) => {
                handleFormChange({ timeunit: value });
              }}
              placeholder={`${t("selectTimeUnitMsg")}`}
              options={audienceItems["timeunit"]}
            />
          </Form.Item>
        )}
        {/* for geo fancing */}
        {operand?.value == AudienceEnum.GF && (
          <>
            <Form.Item
              className="m-0 user-trait-input"
              rules={[{ required: true, message: `${t("selectValueLabel")}` }]}
              name={`dwell[${childValue.id}]`}
              initialValue={childValue.dwell}
            >
              <TextInput
                className="w-100"
                value={childValue.dwell}
                onChange={(e: any) => {
                  if (childValue.time) {
                    handleFormChange({
                      dw: getDw(childValue.time, e.target.value),
                      dwell: parseInt(e.target.value),
                    });
                  } else {
                    handleFormChange({ dwell: parseInt(e.target.value) });
                  }
                }}
                type="number"
                min={0}
              ></TextInput>
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: `${t("selectTimeMsg")}` }]}
              name={`time[${childValue.id}]`}
              className="m-0 user-trait-input"
              initialValue={childValue.time}
            >
              <Selector
                className="w-100"
                placeholder={`${t("selectTimeMsg")}`}
                value={childValue.time}
                onChange={(value) => {
                  if (childValue.dwell) {
                    handleFormChange({
                      dw: getDw(value, childValue.dwell),
                      time: value,
                    });
                  } else {
                    handleFormChange({ time: value });
                  }
                }}
                options={audienceItems["time"]}
              />
            </Form.Item>
          </>
        )}

        {childIndex !== 0 && (
          <CloseOutlined
            data-testid="close-remove-segment"
            onClick={() =>
              dispatch(
                removeSegment({
                  segmentType: AudienceEnum.WHO,
                  isChild: true,
                  category,
                  indexToRemove: childIndex,
                })
              )
            }
          />
        )}
      </div>
    </>
  );
};

export default React.memo(UserTraitNested);
