import React, { FC } from "react";

interface TemplateLayoutProps {
  leftItems: React.ReactNode;
  rightItems: React.ReactNode;
  footerItem: React.ReactNode;
  leftRatio?:number;
  rightRatio?:number;
}

const TemplateLayout: FC<TemplateLayoutProps> = ({
  leftItems,
  rightItems,
  leftRatio,
  rightRatio,
  footerItem,
}) => {
  return (
    <div className="container-fluid h-100" >
      <div className="row" >
        <div
          className={`col-md-${rightRatio || 7} d-flex flex-column justify-content-between`}
          style={{ minHeight: 400,  }}
        >
         <div >
          {rightItems}
          
          </div> 

          <footer >
            {footerItem}
          </footer>
        </div>
        <div className={`col-md-${leftRatio || 5}`}>{leftItems}</div>
      </div>
    </div>
  );
};

export default TemplateLayout;
