import { t } from "i18next";

export function generateStrongRandomString(length: number) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$%&";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength);
    result += characters.charAt(randomIndex);
  }

  return result;
}
export function copyText(value: string) {
 return navigator.clipboard
    .writeText(value)
    .then(() => {
      return { success: true, message: t("copiedMsg") };
    })
    .catch((err) => {
      return { success: false, message: t("somethingWrongLabel") };
    });
}
export function textOverflowThreeDot(value:string,maxChar:number){
  return value?.length>maxChar?value?.slice(0,maxChar-3)+"...":value
 }
 /**
 * Function to process a given string by shifting each character by a fixed number of positions.
 * @param {string} str - The input string to be processed.
 * @returns {string} - The processed string with each character shifted.
 */
export function processString(str: string): string {
  // Initialize an empty string to store the decoded characters
  let deCodedStr = '';
  
  // Loop through each character in the input string
  for (let i = 0; i < str.length; i++) {
    // Get the character code of the current character
    let charCode = str.charCodeAt(i);
    
    // Shift the character code by a fixed number of positions (in this case, +2)
    // and convert it back to a character
    deCodedStr += String.fromCharCode(charCode + 2);
  }
  
  // Return the processed (decoded) string
  return deCodedStr;
}
