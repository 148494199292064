import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../../constant";
import centralApi from "../../../services/centralApi";
import { GeoFencingModel } from "utils/models";
import { API_ENDPOINTS, METHODS } from "utils/constants";
export const fetchGeoFencingList = createAsyncThunk(
  "geoFencing/geoFencingList",
  async ({api_key, app_id}:{api_key:string, app_id:string}) => {
    const params = {
      api_key,
      app_id,
      method: METHODS.GET_GEO_FENCING
    };
    return centralApi(
      "GET",
      API_ENDPOINTS.GET_GEO_FENCING_URL,
      null,
      params
    ).then((data) => { 
      return data;
    });
  }
);
const initialState:{data:GeoFencingModel[],status:any}= {
  data: [],
  status: STATUS.IDLE,
}
const geoFencingListSlice = createSlice({
  name: "geoFencingList",
 initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchGeoFencingList.pending, (state) => {
      state.status = STATUS.LOADING;
    });
    builder.addCase(fetchGeoFencingList.fulfilled, (state,action) => {
      state.status = STATUS.IDLE;
      state.data=action.payload
    });
    builder.addCase(fetchGeoFencingList.rejected, (state) => {
      state.status = STATUS.ERROR;
    });
  },
  reducers: {
  },
});
export default geoFencingListSlice.reducer;

