import { Divider } from "antd";
import "./KillSwitch.css";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { Form } from "antd";
import Selector from "../../../../components/Selector/Selector";
import TextInput from "../../../../components/Inputs/TextInput/TextInput";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import centralApi from "../../../../services/centralApi";
import ShowMessage from "../../../../components/ShowMessage/ShowMessage";
import Spinner from "../../../../components/Spinner/Spinner";

import { getToken } from "../../../../services/getToken";
import { t } from "i18next";

const KillSwitch: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [killSwitchData, setKillSwitchData] = useState({
    state: "0",
    android: [{ v: "" }],
    ios: [{ v: "" }],
    web: [{ v: "" }],
  });

  const [labelsHidden, setlabelsHidden] = useState(true);
  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  });
  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;
  const onFormSubmit = (values: any) => {
    const { android, ios, web, state } = values;

    const payload = {
      args: JSON.stringify({
        ...(android && android !== "" ? { android: [{ v: android }] } : {}),
        ...(ios && ios !== "" ? { ios: [{ v: ios }] } : {}),
        ...(web && web !== "" ? { web: [{ v: web }] } : {}),
        baseurl: "https://a.appice.io",
        // state: hasValue ? "1" : "0",
        state,
        app_id: appId,
      }),

      app_id: appId,
      api_key: api_key,
    };
    saveKillSwitchSetting(payload);
    getToken(appId,api_key)
  };

  async function saveKillSwitchSetting(formData: any) {
    const url: string = `/i/apps/savekillSwitchSetting`;

    try {
      setLoading(true);
     await centralApi("POST", url, formData, null);
     await getKillSwitchState();
      setShowMessage({
        type: "success",
        message: "Sucessfully Saved",
        show: true,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setShowMessage({
        type: "error",
        message: "Something went wrong",
        show: true,
      });
    }
    hideMessage();
  }
  const hideMessage = () => {
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false });
    }, 2000);
  };
  async function getKillSwitchState() {
    const payload = {
      args: JSON.stringify({
        app_id: appId,
      }),
      app_id: appId,
      api_key: api_key,
    };
    const url: string = `/i/apps/getKillSwitchState`;

    try {
      setLoading(true);
      const data = await centralApi("GET", url, null, payload);
      setKillSwitchData(data?.kill_switch);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  useEffect(() => {
    getToken(appId, api_key);
  }, []);
  useEffect(() => {
    getKillSwitchState();
  }, []);
  useEffect(() => {
    handleDisableButton(killSwitchData?.state);
  }, [killSwitchData?.state]);
  const handleDisableButton = (value: string) => {
    if (value === "1") {
      setlabelsHidden(false);
    }
    if (value === "0") {
      setlabelsHidden(true);
    }
  };
  const androidValidationRules = labelsHidden
    ? []
    : [{ required: true, message: "Please Provide Version Details!" }];

  return (
    <div className="kill-switch-setting-container">
      <div className="mt-4 mb-4  ">
        <h6 className="kill-switch-setting-container-heading">
         {t("killSwitchSettingLabel")}
        </h6>
        <Divider className="kill-switch-setting-custom-divider" />

        <div>
          <div className=" col-sm-8 mt-4 align-items-center kill-switch-setting-form ">
            {showMessage.show && (
              <ShowMessage
                type={showMessage.type}
                content={showMessage.message}
              />
            )}
            {loading ? (
              <Spinner />
            ) : (
              <Form
                // form={form}
                layout="horizontal"
                onFinish={onFormSubmit}
                initialValues={{
                  state: killSwitchData?.state || "0",
                  android:
                    killSwitchData?.android?.length > 0
                      ? killSwitchData.android[0]?.v
                      : "",
                  ios:
                    killSwitchData?.ios?.length > 0
                      ? killSwitchData?.ios[0]?.v
                      : "",
                  web:
                    killSwitchData?.web?.length > 0
                      ? killSwitchData?.web[0]?.v
                      : "",
                }}
              >
                <Form.Item label="Kill Switch" name={"state"}>
                  <Selector
                    onChange={(value) => {
                      handleDisableButton(value);
                    }}
                    size="middle"
                    style={{ width: "100%" }}
                    defaultValue={"0"}
                    options={[
                      {
                        value: "0",
                        label: "Disable",
                      },
                      {
                        value: "1",
                        label: "Enable",
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  label="Android"
                  name={"android"}
                  rules={androidValidationRules}
                >
                  <TextInput disabled={labelsHidden} />
                </Form.Item>
                <Form.Item label="IOS" name={"ios"}>
                  <TextInput disabled={labelsHidden} />
                </Form.Item>
                <Form.Item label="Web" name={"web"}>
                  <TextInput disabled={labelsHidden} />
                </Form.Item>

                <div className="">
                  <PrimaryButton
                    size="middle"
                    type="primary"
                    htmlType="submit"
                    style={{ border: "none", width: "70px" }}
                  >
                    Save
                  </PrimaryButton>
                </div>
              </Form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default KillSwitch;
