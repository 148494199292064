import React, { useEffect } from "react";
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  MenuOutlined,
  StarTwoTone,
} from "@ant-design/icons";
import { MenuProps } from "antd";
import { Layout, Menu } from "antd";
import MenuItem from "antd/es/menu/MenuItem";
import homeIcon from "../../images/Home_svg.svg";
import activitiesIcon from "../../images/Analytics_svg.svg";
import usersIcon from "../../images/Audiences_svg.svg";
import churnIcon from "../../images/Churn1.svg";
import engagementIcon from "../../images/Models_svg.svg";
import dashboardIcon from "../../images/MLworkspace_svg.svg";
import documentationIcon from "../../images/Documents_svg.svg";
import acquisitionIcon from "../../images/Acquire4.svg";
import myappsIcon from "../../images/SetApp.svg";
import settingIcon from "../../images/Settings.svg";
import aiIcon from "../../images/ai.svg";
import "./PanelSideNav.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSideNavCollapsed } from "../../redux/features/layout/layoutInfoSlice";
import { getFeaturesSettings } from "redux/features/app/featuresSettingSlice"; //calling features setting from redux
import { ROUTES } from "utils/constants";

const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}
const panelItems = (
  is_app_active: boolean,
  active_app_id: string,
  featuresSetting: any
) => {
  const imgStyle = { height: 20, width: 20 };
   const items: MenuItem[] =  [
    
    getItem(
      <Link to={ROUTES.DASHBOARD}>Home</Link>,
      ROUTES.DASHBOARD,
      <img style={imgStyle} src={homeIcon} alt="home icon"></img>
    ),
    is_app_active
      ? getItem(
          <Link to={`${ROUTES.DASHBOARD_APP}?id=${active_app_id}`}>
            Dashboard
          </Link>,
          ROUTES.DASHBOARD_APP,
          <img style={imgStyle} src={dashboardIcon} alt="dashboard icon"></img>
        )
      : null,
    getItem(
      "Setup Apps",
      "Setup Apps",
      <img style={imgStyle} src={myappsIcon} alt="apps icon"></img>,
      [
        getItem("Setup New App", "Setup New App"),
        getItem("Setup New Chatbot", "Setup New Chatbot"),
      ]
    ),
    is_app_active
      ? getItem(
          "Users",
          "Users",
          <img style={imgStyle} src={usersIcon} alt="users icon"></img>,
          [
            getItem(
              <Link to={ROUTES.DASHBOARD_CUSTOMER}>Customer 360</Link>,
              ROUTES.DASHBOARD_CUSTOMER
            ),
            getItem(
              <Link to={ROUTES.DASHBOARD_AUDIENCE}>Audience segments</Link>,
              ROUTES.DASHBOARD_AUDIENCE
            ),
            featuresSetting?.traits == false?getItem(
              <Link to={ROUTES.DASHBOARD_AUDIENCE_ALLTRAITS}>Traits</Link>,
              ROUTES.DASHBOARD_AUDIENCE_ALLTRAITS
            ):null,
            featuresSetting?.demographics == false
              ? getItem(
                  <Link to={ROUTES.DASHBOARD_DEMOGRAPHICS}>Demographics</Link>, //hide if true
                  ROUTES.DASHBOARD_DEMOGRAPHICS
                )
              : null,
          ]
        )
      : null,
    is_app_active
      ? getItem(
          "Acquisition",
          "Acquisition",
          <img
            style={imgStyle}
            src={acquisitionIcon}
            alt="acquisition icon"
          ></img>,
          [
            getItem(
              <Link to={ROUTES.DASHBOARD_ACQUISITION}>App Acquisition</Link>,
              ROUTES.DASHBOARD_ACQUISITION
            ),
          ]
        )
      : null,
    is_app_active
      ? getItem(
          "Analytics",
          "Analytics",
          <img
            style={imgStyle}
            src={activitiesIcon}
            alt="activities icon"
          ></img>,
          [
            featuresSetting?.usage == false
              ? getItem(
                  <Link to={ROUTES.DASHBOARD_USAGE}>Usage</Link>, //hide if true
                  ROUTES.DASHBOARD_USAGE
                )
              : null,
            getItem(
              <Link to={ROUTES.DASHBOARD_EVENTS}>Events</Link>,
              ROUTES.DASHBOARD_EVENTS
            ),
            getItem(
              <Link to={ROUTES.DASHBOARD_FUNNELS}>Funnels</Link>,
              ROUTES.DASHBOARD_FUNNELS
            ),
            getItem(
              <Link to={ROUTES.DASHBOARD_JOURNEYS}>Journeys</Link>,
              ROUTES.DASHBOARD_JOURNEYS
            ),
            getItem(
              <Link to={ROUTES.DASHBOARD_TRENDS_ALLTRENDS}>Insights</Link>,
              ROUTES.DASHBOARD_TRENDS_ALLTRENDS
            ),
          ]
        )
      : null,
    is_app_active
      ? getItem(
          "Engagement",
          "Engagement",
          <img
            style={imgStyle}
            src={engagementIcon}
            alt="engagement icon"
          ></img>,
          [
            getItem(
              <Link to={ROUTES.DASHBOARD_CAMPAIGN}>Campaigns</Link>,
              ROUTES.DASHBOARD_CAMPAIGN
            ),
            featuresSetting?.transactionalCamp == false
              ? getItem(
                  <Link to={ROUTES.DASHBOARD_TRANSACTIONALCAMPAIGN}>
                    Transactional Campaigns
                  </Link>, //hide if true
                  ROUTES.DASHBOARD_TRANSACTIONALCAMPAIGN
                )
              : null,
            getItem(
              <Link to={ROUTES.DASHBOARD_CAMPAIGN_WEBPUSHREPORTS}>
                Reports
              </Link>,
              ROUTES.DASHBOARD_CAMPAIGN_WEBPUSHREPORTS
            ),
            getItem(
              <Link to={ROUTES.DASHBOARD_EXPORTS}>Funnel Reports</Link>,
              ROUTES.DASHBOARD_EXPORTS
            ),
            getItem(
              <Link to={ROUTES.DASHBOARD_CAMPAIGN_TEMPLATE}>Template </Link>,
              ROUTES.DASHBOARD_CAMPAIGN_TEMPLATE
            ),
            getItem(
              <Link to={ROUTES.DASHBOARD_UTMBUILDER}>UTM Builder</Link>,
              ROUTES.DASHBOARD_UTMBUILDER
            ),
          ]
        )
      : null,
    is_app_active
      ?       featuresSetting?.xmitAi == false ?
      getItem(
          <Link to={ROUTES.GENAI_DASHBOARD}>Xmit AI</Link>,
          ROUTES.GENAI_DASHBOARD,
          <img style={imgStyle} src={aiIcon} alt="ai icon"></img>
        )
      : null
      :null,
    is_app_active
      ? featuresSetting?.churn == false
        ? getItem(
            <Link to={ROUTES.DASHBOARD_CHURN}>Churn</Link>, //hide if true
            ROUTES.DASHBOARD_CHURN,
            <img style={imgStyle} src={churnIcon} alt="churn icon"></img>
          )
        : null
      : null,

      featuresSetting?.loyalty == false
      ?   getItem("Loyalty", "Loyalty", <StarTwoTone style={{ fontSize: 20 }} />, [
      getItem(
        <Link to={ROUTES.DASHBOARD_LOYALTY}>Dashboard</Link>,
        ROUTES.DASHBOARD_LOYALTY
      ),
      getItem(
        <Link to={ROUTES.DASHBOARD_LOYALTY_MEMBERS}>Members</Link>,
        ROUTES.DASHBOARD_LOYALTY_MEMBERS
      ),
      getItem(
        <Link to={ROUTES.DASHBOARD_LOYALTY_SEGMENTS}>Segments</Link>,
        ROUTES.DASHBOARD_LOYALTY_SEGMENTS
      ),
      getItem(
        <Link to={ROUTES.DASHBOARD_LOYALTY_TIER}>Tier</Link>,
        ROUTES.DASHBOARD_LOYALTY_TIER
      ),
      getItem(
        <Link to={ROUTES.DASHBOARD_LOYALTY_REWARDS}>Rewards</Link>,
        ROUTES.DASHBOARD_LOYALTY_REWARDS
      ),
    ]):null,

    is_app_active
        ? getItem(
            <Link to={ROUTES.DASHBOARD_DATA_TRANSFORMATION}>Data Transformation</Link>, //hide if true
            ROUTES.DASHBOARD_DATA_TRANSFORMATION,
            <img style={imgStyle} src={churnIcon} alt="churn icon"></img>
          )
      : null,
  ];


  return items;

};
type propTypes = {
  isStory?: boolean;
};
const PanelSideNav: React.FC<propTypes> = ({ isStory }) => {
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const activeApp = useSelector((state: any) => state.activeApp);
  const { sideNavCollapsed, contentDimensions } = useSelector(
    (state: any) => state.layoutInfo
  );
  const dispatch: any = useDispatch();
  const collapsedScreenWidth = 580;
  const toggleCollapsed = () => {
    dispatch(setSideNavCollapsed(!sideNavCollapsed));
  };

  const features = useSelector((state: any) => state.featuresSetting.data);
  const featuresSetting = features[0]?.features;
  //calling fetures setting
  useEffect(() => {
    if (activeApp.appId && loginUser.api_key) {
      dispatch(
        getFeaturesSettings({
          api_key: loginUser.api_key,
          app_id: activeApp?.appId,
        })
      );
    }
  }, [activeApp, loginUser]);
  return (
    // isStory will be true if the component is rendered through stroybook
    (loginUser.api_key || isStory) && (
      <Sider
        width={230}
        collapsed={
          contentDimensions.width > collapsedScreenWidth
            ? sideNavCollapsed
            : true
        }
        theme="light"
        className="panel-sidenav position-relative overflow-scroll"
      >
        <Menu
          defaultSelectedKeys={[location.pathname]}
          defaultOpenKeys={[location.pathname]}
          theme="light"
          mode={
            contentDimensions.width > collapsedScreenWidth
              ? "inline"
              : "horizontal"
          }
          overflowedIndicator={<MenuOutlined style={{ fontSize: 18 }} />}
          //calling function panelItems
          items={panelItems(
            activeApp.appId && activeApp.appName ? true : false,
            activeApp.appId,
            featuresSetting
          )}
        ></Menu>
        :{/* bottom menu */}
        <div style={{ position: "sticky", top: 560, minHeight: 120 }}>
          <Menu
            defaultSelectedKeys={[location.pathname]}
            defaultOpenKeys={[location.pathname]}
            theme="light"
            mode={
              contentDimensions.width > collapsedScreenWidth
                ? "inline"
                : "horizontal"
            }
            overflowedIndicator={<MenuOutlined style={{ fontSize: 18 }} />}
            items={[
              activeApp?.appId && activeApp?.appName
                ? getItem(
                    "Settings",
                    "Settings",
                    <img src={settingIcon} alt="settings icon"></img>,
                    [
                      getItem(<Link to={"#"}>Manage Modules</Link>, "sub200"),
                      getItem(
                        <Link to={ROUTES.DASHBOARD_ALLUSERS}>
                          User Management
                        </Link>,
                        ROUTES.DASHBOARD_ALLUSERS
                      ),
                      getItem(
                        <Link to={ROUTES.DASHBOARD_APPSETTING}>
                          App Setting
                        </Link>,
                        ROUTES.DASHBOARD_APPSETTING
                      ),
                      getItem(
                        <Link to={ROUTES.DASHBOARD_DISPLAY_PREFRENCE}>
                          Display Preference
                        </Link>,
                        ROUTES.DASHBOARD_DISPLAY_PREFRENCE
                      ),
                    ]
                  )
                : null,
              //hide if true
              featuresSetting?.documentation == false
                ? getItem(
                    "Documentation",
                    "Documentation",
                    <img
                      src={documentationIcon}
                      alt="documentation icon"
                    ></img>,
                    [getItem("IOS Integration", "IOS Integration")]
                  )
                : null,
            ]}
          ></Menu>
          {contentDimensions.width > collapsedScreenWidth && (
            <span className="collapser-span" onClick={toggleCollapsed}>
              {sideNavCollapsed ? (
                <DoubleRightOutlined />
              ) : (
                <DoubleLeftOutlined />
              )}
            </span>
          )}
        </div>
      </Sider>
    )
  );
};

export default PanelSideNav;
