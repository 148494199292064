import  { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Spinner from "components/Spinner/Spinner";
import { Form } from "antd";
import TextInput from "components/Inputs/TextInput/TextInput";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import "./ForgetPassword.css";
import { t } from "i18next";
import { validateEmail } from "../../../validator/antdValidator";
import centralApi from "../../../services/centralApi";
import { useNavigate } from "react-router-dom";
import { fetchLoginUser } from "../../../redux/features/user/loginUserSlice";
import { useDispatch } from "react-redux";
import useMessage from "hooks/useMessage";
import { API_ENDPOINTS } from "utils/constants";
import { EDStatusEnum } from "utils/enums";
import { useForm } from "antd/es/form/Form";
function ForgetPassword() {
  const [loading, setLoading] = useState(true);
  const [forgetPageInfo, setForgetPageInfo] = useState({csrfToken:"",captchaSvg:"",siteSettings:{captcha:""}});
  const [isCaptchaVisible, setIsCaptchaVisible] = useState(false);
  const [formValues, setFormValues] = useState<any>({});
  const navigate = useNavigate();
  const [form] = useForm();
  const dispatch: any = useDispatch();
  const { showError,showSuccess } = useMessage();
  const sendDataToApi = async ({ forget_email, _csrf,captcha }: any) => {
    setLoading(true)
    try {
      const payload = {
        email:forget_email,
        _csrf,
        captcha
      };
      const res = await centralApi(
        "POST",
        API_ENDPOINTS.FORGET_URL,
        payload
      );
      if (res.redirect) {
        navigate(res.redirectPath);
      }
      setForgetPageInfo(res)
      showSuccess(res.message)
      setIsCaptchaVisible(false)
    } catch (error: any) {
      const data = error?.response?.data;
      setIsCaptchaVisible(false)
      setForgetPageInfo(data || {})
      showError(data?.message || t("somethingWrongLabel"))     
    }
    form.resetFields(["captcha"])
    setLoading(false)
  };

  //handle input change
  const handleFieldChange=(data:any)=>{
    setFormValues((prevState:any)=>({...prevState,...data}))
  }
  const handleSubmit=()=>{
    const captchaStatus=forgetPageInfo.siteSettings?.captcha
    if (isCaptchaVisible || captchaStatus==EDStatusEnum.DISABLE || !captchaStatus){
      //Call API 
      sendDataToApi({...formValues,_csrf:forgetPageInfo.csrfToken});
    } 
    else{
      setIsCaptchaVisible(true);
    }
  }
  const fetchForgetPassword = async () => {
    try {
      const res = await centralApi("GET", API_ENDPOINTS.GET_FORGET_URL);
      setForgetPageInfo(res)
      setLoading(false);
      if (res.redirect) {
      dispatch(fetchLoginUser())
        
        navigate(res.redirectPath);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchForgetPassword();
  }, []);
  return (
    <div className="container-fluid p-0 forget-password-container d-flex justify-content-center align-items-center  h-100">
      <Helmet>
        <title>Appice | Reset Password</title>
      </Helmet>
      {loading ? (
        <Spinner />
      ) : (
        <div
          className="  p-5 forget-password-form-wrapper"
          style={{ background: "var(--color-other-white)", width: "500px" }}
        >
          <h4
            className="pb-2 text-center"
            style={{ fontWeight: "var(--font-weight-heading-strong)" }}
          >
            {t("signInTitle")}
          </h4>
          <Form
            onFinish={handleSubmit}
            layout="vertical"
            form={form}
            validateTrigger={["onBlur"]}
          >
           {!isCaptchaVisible? <Form.Item
              name="forget_email"
              rules={[
                { required: true, message: `${t("emptyEmailMsg")}` },
                { validator: validateEmail },
              ]}
              className="mt-2"
            >
              <TextInput
              onChange={(e:any)=>handleFieldChange({forget_email:e.target.value})}
                type="text"
                className="mt-2"
                placeholder={t("yourEmailLabel")}
              />
            </Form.Item>:<>
              <div className="mb-2">
                <img src={`data:image/svg+xml;base64,${forgetPageInfo.captchaSvg}`}/>
              </div>
              <Form.Item rules={[{required:true,message:`${t("captchaNotMatchLabel")}`}]} name="captcha">
                <TextInput onChange={(e:any)=>handleFieldChange({captcha:e.target.value})}/>
              </Form.Item>
              </>}
            <Form.Item>
              <PrimaryButton
                type="primary"
                className="w-100 mt-1 "
                style={{ height: 40 }}
                htmlType="submit"
              >
                {t("resetPasswordLabel")}
              </PrimaryButton>
            </Form.Item>
          </Form>
          <div className=" d-flex flex-column text-center pt-2">
            <span className="pt-2">
              {t("semusiPvtLabel")}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default ForgetPassword;
