export const pieData = (data: any) => {
 return Object.keys(data)?.map((key: string) => {
    return {
      id: key,
      label: key,
      value: data[key],
    };
  });
  
};
