"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueueSettings = exports.AzureQueueConfig = exports.PgQueueConfig = void 0;
const typescript_json_serializer_1 = require("typescript-json-serializer");
const base_1 = require("../common/base");
// PgQueue configuration class for queue settings
let PgQueueConfig = class PgQueueConfig {
    constructor(pgQueueUser, pgQueueHost, pgQueueDatabase, pgQueuePassword, pgQueuePort) {
        this.pgQueueUser = pgQueueUser;
        this.pgQueueHost = pgQueueHost;
        this.pgQueueDatabase = pgQueueDatabase;
        this.pgQueuePassword = pgQueuePassword;
        this.pgQueuePort = pgQueuePort;
    }
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], PgQueueConfig.prototype, "pgQueueUser", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], PgQueueConfig.prototype, "pgQueueHost", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], PgQueueConfig.prototype, "pgQueueDatabase", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], PgQueueConfig.prototype, "pgQueuePassword", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], PgQueueConfig.prototype, "pgQueuePort", void 0);
PgQueueConfig = __decorate([
    (0, typescript_json_serializer_1.JsonObject)(),
    __metadata("design:paramtypes", [String, String, String, String, Number])
], PgQueueConfig);
exports.PgQueueConfig = PgQueueConfig;
// Azure Queue configuration class for queue settings
let AzureQueueConfig = class AzureQueueConfig {
    constructor(azureApiQueueSasUrl, azureApiQueueSasToken) {
        this.azureApiQueueSasUrl = azureApiQueueSasUrl;
        this.azureApiQueueSasToken = azureApiQueueSasToken;
    }
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AzureQueueConfig.prototype, "azureApiQueueSasUrl", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AzureQueueConfig.prototype, "azureApiQueueSasToken", void 0);
AzureQueueConfig = __decorate([
    (0, typescript_json_serializer_1.JsonObject)(),
    __metadata("design:paramtypes", [String, String])
], AzureQueueConfig);
exports.AzureQueueConfig = AzureQueueConfig;
// Queue settings class including PgQueue and AzureQueue configurations
let QueueSettings = class QueueSettings extends base_1.BaseModel {
    constructor(pgQueueConfig, azureQueueConfig) {
        super();
        this.pgQueueConfig = pgQueueConfig;
        this.azureQueueConfig = azureQueueConfig;
    }
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ type: PgQueueConfig }),
    __metadata("design:type", PgQueueConfig)
], QueueSettings.prototype, "pgQueueConfig", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ type: AzureQueueConfig }),
    __metadata("design:type", AzureQueueConfig)
], QueueSettings.prototype, "azureQueueConfig", void 0);
QueueSettings = __decorate([
    (0, typescript_json_serializer_1.JsonObject)(),
    __metadata("design:paramtypes", [PgQueueConfig, AzureQueueConfig])
], QueueSettings);
exports.QueueSettings = QueueSettings;
