import { useEffect, useState, Fragment } from "react";
import { Row } from "antd";
import CustomTable from "../../components/CustomTable/CustomTable";
import { Typography } from "antd";
import "./TransactionalCampaigns.css";
type dataType = {
  key: number;
  Email: string;
  Name: string;
  Audience_Segment: string;
  Reachable_Audience: string;
  Sent: string;
  Clicked: string;
  CTR: string;
  Start_Date: string;
  End_Date: string;
  Action: string;
};

const generateDummyData = (count: number): dataType[] => {
  const data: dataType[] = [];
  for (let i = 1; i <= count; i++) {
    data.push({
      key: i,
      Email: `user${i}@example.com`,
      Name: `User ${i}`,
      Audience_Segment: `Segment ${i}`,
      Reachable_Audience: `${Math.floor(Math.random() * 10000)}`,
      Sent: `${Math.floor(Math.random() * 1000)}`,
      Clicked: `${Math.floor(Math.random() * 500)}`,
      CTR: `${((Math.random() * 100) / 100).toFixed(2)}%`,
      Start_Date: "2023-08-01",
      End_Date: "2023-08-10",
      Action: "Action",
    });
  }
  return data;
};

const { Title,  } = Typography;
const TransactionalCampaignsDetail: React.FC = () => {
  const [usersData, setUsersData] = useState<any>(generateDummyData(1));
  const [usersDataCopy, setUsersDataCopy] = useState<any>([]);

  // const pageSize = 3;
  const columns: any = [
    {
      title: "Type",
      dataIndex: "Email",
      className: "fix",
      width: 10,
    },
    {
      title: "Name",
      dataIndex: "Name",

      width: 10,
    },

    {
      title: "Audience Segment",
      dataIndex: "Audience_Segment",
      width: 20,
    },
    {
      title: "Reachable Audience",
      dataIndex: "Reachable_Audience",
      width: 20,
    },
    {
      title: "Sent",
      dataIndex: "Sent",
      width: 10,
    },

    {
      title: "Clicked",
      dataIndex: "Clicked",
      width: 10,
    },
    {
      title: "CTR",
      dataIndex: "CTR",
      width: 10,
    },

    {
      title: "Conversion",
      dataIndex: "Conversion",
      width: 10,
    },
    {
      title: "Start Date",
      dataIndex: "Start_Date",
      width: 10,
    },
    {
      title: "End Date",
      dataIndex: "End_Date",
      width: 10,
    },
    {
      title: "Maker",
      dataIndex: "maker",
      width: 10,
    },
    {
      title: "Checker",
      dataIndex: "Checker",
      width: 10,
    },
    {
      title: "Download",
      dataIndex: "Download",
      width: 10,
    },
  ];
  // const handleSearch = (e: any) => {
  //   const searchText = e.target.value.trim().toLowerCase();
  //   const filteredData = usersData.filter((user: dataType) => {
  //     return user.Email.toLowerCase().includes(searchText);
  //   });
  //   setUsersDataCopy(filteredData);
  // };
  useEffect(() => {
    setUsersDataCopy(usersData);
  }, [usersData]);
  return (
    <Fragment>
      <Row className="transactional-campaigns-header p-4">
        <Title level={3}> Campaign Detail</Title>
        
      </Row>
     

      <div>
        <CustomTable
          columns={columns}
          dataSource={usersDataCopy.map((user: any) => ({
            ...user,
            key: user.key,
          }))}
          responsive
          scroll={{ x: 1390 }}

          
        />
      </div>
    </Fragment>
  );
};
export default TransactionalCampaignsDetail;

