import { keys } from "@mui/system";
import axios from "axios";
import { json } from "stream/consumers";
import apiRequest from "../services/apiRequest";
import { getRandomNumber } from "helperFunctions/number";
// get journeys data
export const getJourneyList = ({ dateRange, appId, apiKey }: any) => {
  const url: string = `/o/metrices/getJourneyList`;
  return apiRequest
    .get(url, {
      params: {
        app_id: appId,
        api_key: apiKey,
        d: dateRange,
        uniqueNumber:getRandomNumber()
      },
    })
    .then((res) => {
      const data = res.data.map((jur: any) => {
        return {
          Key: jur._id,
          Journey_Name: jur.journeyName,
          Journey_Description: jur.journeyDescription,
          Start_Event: jur.startEvent,
          End_Event: jur.endEvent,
          Action: jur._id,
          PlatForm: jur.platform,
          createdOn: jur.createdOn,
          modifiedOn: jur.modifiedOn,
        };
      });
      return data;
    })
    .catch((e) => {
      throw new Error(e.message);
    });
};
//delete the Journey
export const deleteJourney = ({ id, appId, apiKey }: any) => {
  const url: string = `/o/metrices/deleteJourney`;
  return apiRequest
    .get(url, {
      params: {
        app_id: appId,
        api_key: apiKey,
        id: id,
        uniqueNumber:getRandomNumber()
      },
    })
    .then((res) => {
      return { status: res.data };
    })
    .catch((e) => {
      throw new Error(e.message);
    });
};
//copy Journey
export const copyJourney = ({ id, appId, apiKey }: any) => {
  const url: string = `/o/metrices/copyJourney`;
  return apiRequest
    .get(url, {
      params: {
        app_id: appId,
        api_key: apiKey,
        id: id,
      },
    })
    .then((res) => {
      return { status: res.data };
    })
    .catch((e) => {
      throw new Error(e.message);
    });
};

//get journey by id
export const getJourney = ({ id, apiKey, appId }: any) => {
  const url: string = `/o/metrices/getJourney`;
  return apiRequest
    .get(url, {
      params: {
        app_id: appId,
        api_key: apiKey,
        id: id,
        uniqueNumber:getRandomNumber()
      },
    })
    .then((res) => {
      return {
        id: res.data._id,
        journeyName: res.data.journeyName,
        journeyDescription: res.data.journeyDescription,
        period: res.data.period,
        platform: res.data.platform,
        startEvent: res.data.startEvent,
        endEvent: res.data.endEvent,
        paths: res.data.paths,
      };
    })
    .catch((e) => {
      throw new Error(e.message);
    });
};
export const updateJourney = ({ data, appId, apiKey, csrfToken }: any) => {
  const url: string = `/o/metrices/updateJourney`;
  return apiRequest
    .post(
      url,
      {
        
          app_id: appId,
          api_key: apiKey,
          args: JSON.stringify(data),
          
    
      },
      {
        params: {
          app_id: appId,
          api_key: apiKey,
        },
        headers: {
          "csrf-token": csrfToken,
        },
      }
    )

    .then((res) => {
      if (res.data.acknowledged && res.data.modifiedCount) {
        return { message: "Journey Updated Successfully", status: 1 };
      } else {
        return { message: "Something Went Wrong", status: 0 };
      }
    })
    .catch((e) => {
      throw new Error(e.response.data.result);
    });
};
//create new journey
export const saveJourney = ({ data, appId, apiKey, csrfToken }: any) => { 
  const url: string = `/o/metrices/saveJourney`;
  return apiRequest
    .post(
      url,
      {
        params: {
          app_id: appId,
          api_key: apiKey,
        },
        app_id: appId,
        api_key: apiKey,
        args: data,
      },
      {
        headers: {
          "csrf-token": csrfToken,
        },
      }
    )
    .then((res) => {
      if (res.data.acknowledged) {
        return { message: "Journey Created Successfully", status: 1 };
      } else {
        return { message: "Something Went Wrong", status: 0 };
      }
    })
    .catch((e) => {
      throw new Error(e.response.data.result);
    });
};
