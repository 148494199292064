import React from "react"
import CustomHighChart from "../../../components/Charts/CustomHighChart/CustomHighChart"
interface DonutPieChartProps {
  heading: string
  total: number
  valueIst?: number
  valueSecond?: number
  title:string
  data:any;
}

export const DonutPieChart: React.FC<DonutPieChartProps> = ({
  heading,
  total,
  valueIst,
  valueSecond,
  title,
  data,
}) => {
  const dataSeries1 = [1, 20, 13]
  const dataSeries2 = [2, 1, 4]

  // const options = {
  //   title: {
  //     text: "",
  //   },
  //   chart: {
  //     type: "pie",
  //   },
  //   navigation: {
  //     buttonOptions: {
  //       enabled: false, // Set to false to hide the hamburger icon
  //     },
  //   },
  //   subtitle: {
  //     useHTML: true,
  //     text: `<div style='text-align: center;'><span style='font-size:20px;font-weight:bold;'>${total}</span><br > <span style='font-size:18px;font-weight:bold;'>${heading}</span></div>`,
  //     floating: true,
  //     verticalAlign: "middle",
  //     y: 30,
  //     style: {
  //       fontSize: "24px", // Adjust the font size as needed
  //       fontWeight: "bold",
  //     },
  //   },
  //   plotOptions: {
  //     // series: {
  //     //     borderWidth: 0,
  //     //     colorByPoint: true,
  //     //     type: 'pie',
  //     //     size: '100%',
  //     //     innerSize: '80%',
  //     //     dataLabels: {
  //     //         enabled: true,
  //     //         // crop: false,
  //     //         distance: '-10%',
  //     //         // style: {
  //     //         //     fontWeight: 'bold',
  //     //         //     fontSize: '16px'
  //     //         // },
  //     //         connectorWidth: 0
  //     //     }
  //     pie: {
  //       allowPointSelect: true,
  //       cursor: "pointer",
  //       dataLabels: {
  //         enabled: true,
  //         format: "{point.name}: {y} %",
  //       },
  //     },
  //     showInLegend: true,
  //   },

  //   // colors: ['#4169E1','#0000FF', , ],
  //   // series: [
  //   //     {
  //   //         type: 'pie',
  //   //         name: "Data",
  //   //         data: [
  //   //           { name: "Active", y: valueIst },
  //   //           { name: "Pending", y: valueSecond },
  //   //           // { name: "Used Points", y: dataSeries1[2] + dataSeries2[2] },
  //   //         ],      }
  //   // ]
  //   series: [
  //     {
  //       name: "Percentage",
  //       colorByPoint: true,
  //       innerSize: "75%",
  //       data: [
  //         {
  //           name: "Nitrogen",
  //           y: 78,
  //         },
  //         {
  //           name: "Oxygen",
  //           y: 20.9,
  //         },
  //         {
  //           name: "Other gases",
  //           y: 1.1,
  //         },
  //       ],
  //     },
  //   ],
  // }
  const options = {
    colors: ['#01BAF2', '#71BF45', '#FAA74B', '#B37CD2'],
    chart: {
      type: 'pie',
      height: 400, // Set a fixed height for the container


    },
    title: {
      text: title,
    },
    navigation: {
          buttonOptions: {
            enabled: false, // Set to false to hide the hamburger icon
          },
        },
    tooltip: {
      valueSuffix: '%',
    },
    subtitle: {
          useHTML: true,
          text: `<div style='text-align: center;'><span style='font-size:20px;font-weight:bold;'>${total}</span><br > <span style='font-size:18px;font-weight:bold;'>${heading}</span></div>`,
          floating: true,
          verticalAlign: "middle",
          y: 5,
          style: {
            fontSize: "22px", // Adjust the font size as needed
            fontWeight: "bold",
          },
        },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
          format: '{point.name}: {y} %',
        },
        showInLegend: true,
        size: '75%', // Set a fixed size for the pie chart (diameter = 75% of the container width)

      },
    },
    legend: {
      layout: 'vertical',
      align: 'center',
      verticalAlign: 'bottom',
  //     itemMarginTop: 10,
  //  itemMarginBottom: 10,
      labelFormatter: function () {
        const point = this as any;
        return `${point.name}: ${point.y}`;
      },
  
    },
    series: [
      {
        name: 'Percentage',
        colorByPoint: true,
        size:'90%',
        innerSize: '75%',
        data: data?data:[
          {
            name: 'Nitrogen',
            y: 78,
          },
          {
            name: 'Oxygen',
            y: 20.9,
          },
          {
            name: 'Other gases',
            y: 1.1,
          },
        ],
      },
    ],
  };
  return (
    <div>
      <CustomHighChart options={options} />
    </div>
  )
}
