import { personalizationVariableInfoType } from "@customTypes/template";
import { Divider, Form, Radio } from "antd";
import { useForm } from "antd/es/form/Form";
import FormItem from "antd/es/form/FormItem";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import RadioButton from "components/Buttons/RadioButton/RadioButton";
import CustomDrawer from "components/CustomDrawer/CustomDrawer";
import TextInput from "components/Inputs/TextInput/TextInput";
import Selector from "components/Selector/Selector";
import { t } from "i18next";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchEventList } from "redux/features/event/eventListSlice";

interface PersonalizationProps {
  personalizationState: { open: boolean; field: string };
  setPersonalizationState: (value: { open: boolean; field: string }) => void;
  setFormData: (data: any) => void;
}

const Personalization: FC<PersonalizationProps> = ({
  personalizationState,
  setPersonalizationState,
  setFormData,
}) => {
  const [form] = useForm();
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const eventList = useSelector((state: any) => state.eventList.data);
  const [selectedEventAttributes, setSelectedEventAttributes] = useState([]);
  const [variable, setVariable] = useState("");
  const [data, setData] = useState<personalizationVariableInfoType>({
    source: "events",
    variableValue: undefined,
    attribute: undefined,
    defaultValue: undefined,
  });

  const handleFormChange = (key: string, value: string) => {
    setData((prevState) => ({ ...prevState, [key]: value }));
  };
  const dispatch: any = useDispatch();
  const userTraitsList = [
    { name: "First Name", value: "first_name" },
    { name: "Last Name", value: "last_name" },
    { name: "Address", value: "address" },
    { name: "City", value: "city" },
    { name: "Account Number", value: "account_number" },
    { name: "Age", value: "age" },
    { name: "Marital Status", value: "marital_status" },
    { name: "Education", value: "education" },
    { name: "Income Category", value: "income_category" },
    { name: "Date of Birth", value: "date_of_birth" },
    { name: "Anniversary", value: "anniversary" },
  ];
  useEffect(() => {
    dispatch(fetchEventList({ api_key: loginUser.api_key, app_id: appId }));
  }, [loginUser]);
  const onSubmit = () => {
    if(personalizationState?.field){
      setFormData((prevState: any) => ({
        ...prevState,
        variableInfo: { ...prevState.variableInfo, [variable]: data },
        [personalizationState.field]:
        prevState[personalizationState.field] + variable,
      }));
    }
    setPersonalizationState({ open: false, field: "" });
  };
  useEffect(() => {
    form.resetFields();
  }, [personalizationState]);
  return (
    <CustomDrawer
      footer={
        <div>
          <PrimaryButton onClick={() => form.submit()}>
            {t("saveLabel")}
          </PrimaryButton>
        </div>
      }
      open={personalizationState.open}
      placement="right"
      closable={false}
      onClose={() => setPersonalizationState({ open: false, field: "" })}
    >
      <div className=" mt-1 ">
        <h5 className="fw-semibold">Add variable</h5>
        <div className="mt-3">
          <Form
            colon={false}
            labelAlign="left"
            labelCol={{ flex: "110px" }}
            layout="horizontal"
            form={form}
            onFinish={onSubmit}
          >
            <FormItem
              name={"variable"}
              rules={[{ required: true, message: "Select variable" }]}
              label="Variable field"
            >
              <Selector
                showSearch
                onChange={(variable: string) => {
                  setVariable(variable);
                }}
                options={[
                  {
                    label: "{{1}}", // Placeholder for dynamic value 1
                    value: "{{1}}", // Unique value for the first option
                  },
                  {
                    label: "{{2}}", // Placeholder for dynamic value 2
                    value: "{{2}}", // Unique value for the second option
                  },
                  {
                    label: "{{3}}", // Placeholder for dynamic value 2
                    value: "{{3}}", // Unique value for the second option
                  },
                  {
                    label: "{{4}}", // Placeholder for dynamic value 2
                    value: "{{4}}", // Unique value for the second option
                  },
                  {
                    label: "{{5}}", // Placeholder for dynamic value 2
                    value: "{{5}}", // Unique value for the second option
                  },
                ]}
                placeholder="Select variable"
              />
            </FormItem>
            <FormItem label="Select source" >
              <Radio.Group
                onChange={(e) => handleFormChange("source", e.target.value)}
                value={data?.source}
              >
                <RadioButton name="Events" value="events"></RadioButton>
                <RadioButton name="User trait" value="traits"></RadioButton>
              </Radio.Group>
            </FormItem>
            <Divider />
            {data?.source == "events" ? (
              <>
                <FormItem
                  rules={[{ required: true, message: "Select event" }]}
                  name="variableValue"
                  label={`Select event`}
                >
                  <Selector
                    onChange={(event: string) => {
                      const eventObj = JSON.parse(event);
                      setSelectedEventAttributes(eventObj?.list); // Call handleEventChange function
                      handleFormChange("variableValue", eventObj.event);
                    }}
                    showSearch
                    options={eventList?.map((event: any) => ({
                      label: event.displayname,
                      value: JSON.stringify(event),
                    }))}
                  />
                </FormItem>
                <FormItem label="Where">
                  <Selector
                    onChange={(attribute: string) => {
                      handleFormChange("attribute", attribute);
                    }}
                    placeholder="Select attribute"
                    options={selectedEventAttributes?.map(({ name }) => ({
                      label: name,
                      value: name,
                    }))}
                  />
                </FormItem>
              </>
            ) : (
              <FormItem className="" label={`Select trait`} name={"variableValue"}  rules={[{ required: true, message: "Select trait" }]}>
                <Selector
                  onChange={(trait: string) => {
                    handleFormChange("variableValue", trait);
                  }}
                  showSearch
                  options={userTraitsList?.map(({ name, value }) => ({
                    label: name,
                    value: value,
                  }))}
                />
              </FormItem>
            )}
            <FormItem label="Default value">
              <TextInput
                onChange={(e: any) => {
                  handleFormChange("defaultValue", e.target.value);
                }}
                placeholder="Default value"
              />
            </FormItem>
          </Form>
        </div>
      </div>
    </CustomDrawer>
  );
};

export default Personalization;
export const replacePersonlizedVariable = (
  text: string,
  variableInfo: { [key: string]: personalizationVariableInfoType } | undefined
) => {
  const string = text?.replace(/{{(\d+)}}/g, (match: any, key: any) => {
    if (variableInfo) {
      return (
        ` {{${variableInfo[`{{${key}}}`]?.variableValue}:${
          variableInfo[`{{${key}}}`]?.defaultValue || ""
        }}} ` || match
      );
    }
    return match;
  });
  if (string) {
    return string;
  } else {
    return "";
  }
};
