import { useState, Fragment } from "react";
import InnerNav from "components/InnerNav/InnerNav";
import type { MenuProps } from "antd";
import ConfigureColumns from "../ConfigureColumns/ConfigureColumns";
import React from "react";
import "./CampaignAnalyticsNav.css"
import { t } from "i18next";
import ConfigureUrls from "../ConfigureUrls/ConfigureUrls";
interface CampaignAnalyticsNavProps {
  currentAppKey: any;
  updateCurrentAppKey: () => void;
}

const CampaignAnalyticsNav: React.FC<CampaignAnalyticsNavProps> = ({
  currentAppKey,
  updateCurrentAppKey,
}) => {
  const [appTabFilter, setAppTabFilter] = useState("columns");
  const [selectedComponent, setSelectedComponent] = useState(
    <ConfigureColumns
      currentAppKey={currentAppKey}
      updateCurrentAppKey={updateCurrentAppKey}
    />
  );

  const onTabClick: MenuProps["onClick"] = (e) => {
    setAppTabFilter(e.key);
    switch (e.key) {
      case "columns":
        setSelectedComponent(
          <ConfigureColumns
            currentAppKey={currentAppKey}
            updateCurrentAppKey={updateCurrentAppKey}
          />
        );
        break;
      case "urls":
        setSelectedComponent(<ConfigureUrls currentAppKey={currentAppKey} updateCurrentAppKey={updateCurrentAppKey} />);
        break;

      default:
        setSelectedComponent(<div></div>); // Default empty component
    }
  };
  return (
    <Fragment>
      <div className=" mt-4 campaign-analytics-container ">
        <div className=" col-sm-11 campaign-analytics-para ">
          <p>{t("appSettingCampaignAnalyticsDes")}</p>
        </div>
      <div className="campaign-analytics-nav-container  ">
        <div className="app-setting-nav">
          <InnerNav
            theme="light"
            onTabClick={onTabClick}
            selected={appTabFilter}
            menu={[
              {
                label: "Configure columns",
                key: "columns",
              },
              {
                label: "Configure urls",
                key: "urls",
              },
            ]}
          />
        </div>
        {selectedComponent}
      </div>
      </div>

    </Fragment>
  );
};
export default CampaignAnalyticsNav;
